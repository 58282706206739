.tw-bubble {
  position: relative;
  overflow: hidden;
  margin-left: auto;
  display:flex;
  flex:1;
  flex-direction:column;
  background: #97C02F;
  display:flex;
  padding:5px;
}
.tw-bubble::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-width: 0 15px 15px 0;
  border-style: solid;
  border-color: #fff #fff #658E15 #658E15;
  background: #658E15;
  display: block;
  width: 0;
  -webkit-box-shadow: 0 1px 1px rgba(0,0,0,0.3), -1px 1px 1px rgba(0,0,0,0.2);
  -moz-box-shadow: 0 1px 1px rgba(0,0,0,0.3), -1px 1px 1px rgba(0,0,0,0.2);
  box-shadow: 0 1px 1px rgba(0,0,0,0.3), -1px 1px 1px rgba(0,0,0,0.2);
  transition: all ease 0.5s;
}
.tw-bubble:hover::before {
  border-width: 0 25px 25px 0;
  border-color: #fff #fff #658E15 #658E15;
}
//--- new
.tw-bubble-new {
  position: relative;
  overflow: hidden;
  margin-left: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color:lightgray;
  display: flex;
  padding: 5px;
}

.tw-bubble-new::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-width: 0 15px 15px 0;
  border-style: solid;
  border-color: #fff #fff lightgray lightgray;
  background: lightgray;
  display: block;
  width: 0;
  -webkit-box-shadow: 0 1px 1px rgba(0,0,0,0.3), -1px 1px 1px rgba(0,0,0,0.2);
  -moz-box-shadow: 0 1px 1px rgba(0,0,0,0.3), -1px 1px 1px rgba(0,0,0,0.2);
  box-shadow: 0 1px 1px rgba(0,0,0,0.3), -1px 1px 1px rgba(0,0,0,0.2);
  transition: all ease 0.5s;
}

.tw-bubble-new:hover::before {
  border-width: 0 25px 25px 0;
  border-color: #fff #fff lightgray lightgray;
}

//-------------
.tw-list-notes {
  padding:10px;
  //background: linear-gradient(to bottom, #C0C0C0, #E0E0E0);
}


.tw-note-content {
  display: flex;
  margin-bottom: 10px;
}
.tw-note-delete {
  width: 26px;
  background-color: transparent;
  position:relative;
  z-index:3;
}
.tw-note-delete:hover {
  .tw-icon-note-clip {
    color: red;
    transform: rotate(0);
  }
}
.tw-icon-note-clip {
  position: absolute;
  transform: rotate(-90deg);
}

.tw-note-new {
  width: 26px;
  background-color: transparent;
  position: relative;
  z-index: 3;
}



.tw-autosize-textarea {
  position: absolute;
  height: 100%;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  min-height: 100px;
  overflow: hidden; /*overflow is set to auto at max height using JS */
  background-color: transparent;
  outline: none;
  font-family: 'Century Gothic';
  line-height: 15px;
  white-space: pre;
  font-size: 10.6px;
  min-height: 18px;
  padding: 5px;
  resize: none;
  white-space: pre-wrap;
}
.tw-autosize-divtextarea {
  width: 100%;
  height: 100%;
  max-width: 100%;
  min-width: 100%;
  min-height: 100px;
  font-family: 'Century Gothic';
  line-height: 15px;
  white-space: pre;
  font-size: 10.6px;
  min-height: 18px;
  background: red;
  padding: 5px;
  visibility: hidden;
  white-space: pre-wrap;
}
