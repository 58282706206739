.tw-autocomplete {
  border-bottom: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgba(0,0,0,0.12);
  

  md-autocomplete {
    height: auto;

    md-autocomplete-wrap {
      box-shadow: none;
      height: auto;
    }
  }
}
