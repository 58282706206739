.tw-contact-address-detail {
  display: flex;
  flex-direction:row
}
.tw-ligne-detail {
  display: flex;
  align-items: center;
  line-height: 20px;
}
.tw-contact-address-line {
  padding:2px 0;
  //border-top-width: 0;
  //border-right-width: 1px;
  //border-top-width: 0;
  //border-width: 1px;
  //border-style: solid;

}
