
#feedback {
    .dialog {
        .case1 {
            width: 30%;
        }

        .case2 {
            width: 75%;
        }
    }
}
