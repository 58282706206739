#tw-widget-orders-sliced {

  md-select {
    margin: 0;
    padding: 0;
  }

  table {
    width: 100%;
    border-spacing: 0;
    margin: 0;

    th {
      margin: 0;
      padding: 5px 15px;
    }

    td {
      max-height: 25px;
      height: 25px;
      border-bottom: solid;
      border-bottom-width: 1px;
      border-bottom-color: lightgray;
      padding: 5px 15px;
    }

    tbody > tr {
      cursor: pointer;
    }

    tbody > tr.danger {
      background-color: #ffb300;
    }
  }
}
