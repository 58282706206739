#pickinglists {

  .center {

    .header {
      height: 136px !important;
      min-height: 100px !important;
      max-height: 100px !important;
      padding: 24px 0;

      .h1 {
        md-icon {
          margin-right: 12px;
        }
      }

      .search {
        position: relative;
        padding-left: 24px;
        max-width: 480px;

        .search-icon {
          margin: 0 -24px 0 0;
        }

        .search-input {
          margin: 0 0 0 32px;
          background: none;
          font-size: 16px;
          line-height: 40px;
          height: 40px;
          color: #2d323e;
          border-bottom: 2px solid #2d323e;
          transition: border-color 300ms ease;

          &:focus {
            border-color: #2d323e;
          }

          &::-webkit-input-placeholder {
            color: #2d323e;
          }
        }
      }
    }

    .content-card {
      padding: 0;

      .tabs-button {
        max-height: 80px;
      }

      md-tabs {
        .md-tab-button {
          padding: 12px 6px 12px 12px;
        }
      }

      .md-small {
        margin: 0 6px;
        padding: 6px;
        line-height: 22px;
        min-height: 30px;
        height: 30px;
        min-width: 50px;
        font-size: 12px;
      }

      .tabs-icon-button {
        padding: 0;
        margin: 0;
        height: 24px;
        min-width: 40px;
      }

      .dataTables_wrapper {

        .dataTables_scrollHead {
          min-height: 50px;

          table {
            th {
              padding-top: 15px;
              padding-bottom: 16px;
            }
          }
        }

        .dataTables_scrollBody {
          td {
            padding-top: 5px;
            padding-bottom: 5px;
            min-height: 40px;
            height: 40px;

            .md-button {
              margin: 0 6px;
              padding: 6px;
              line-height: 22px;
              min-height: 30px;
              height: 30px;
              min-width: 50px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

#pickinglists-dialog {
  md-list {
    md-list-item.selected {
      background-color: rgb(255,171,0);
    }
  }

  .md-small-button {
    padding: 0px !important;
    margin: 0px !important;
    height: 35px !important;
    width: 35px !important;
    min-width: 35px !important;
    margin-top: 20px !important;
  }
}


#pickinglists-readytopick {

  .center {

    .header {
      height: 136px !important;
      min-height: 100px !important;
      max-height: 100px !important;
      padding: 24px 0;

      .h1 {
        md-icon {
          margin-right: 12px;
        }
      }

      .search {
        position: relative;
        padding-left: 24px;
        max-width: 480px;

        .search-icon {
          margin: 0 -24px 0 0;
        }

        .search-input {
          margin: 0 0 0 32px;
          background: none;
          font-size: 16px;
          line-height: 40px;
          height: 40px;
          color: #FFFFFF;
          border-bottom: 2px solid #2d323e;
          transition: border-color 300ms ease;

          &:focus {
            border-color: #2d323e;
          }

          &::-webkit-input-placeholder {
            color: #2d323e;
          }
        }
      }
    }

    .toolbar {
      min-height: 60px !important;
      max-height: 60px !important;
    }

    .content-card {
      padding: 0;

      .tabs-button {
        max-height: 80px;
      }

      md-tabs {
        .md-tab-button {
          padding: 12px 6px 12px 12px;
        }
      }

      .md-small {
        margin: 0 6px;
        padding: 6px;
        line-height: 22px;
        min-height: 30px;
        height: 30px;
        min-width: 50px;
        font-size: 12px;
      }

      .tabs-icon-button {
        padding: 0;
        margin: 0;
        height: 24px;
        min-width: 40px;
      }

      .dataTables_wrapper {

        .dataTables_scrollHead {
          min-height: 50px;

          table {
            th {
              padding-top: 15px;
              padding-bottom: 16px;
            }
          }
        }

        .dataTables_scrollBody {
          td {
            padding-top: 5px;
            padding-bottom: 5px;
            min-height: 40px;
            height: 40px;

            .md-button {
              margin: 0 6px;
              padding: 6px;
              line-height: 22px;
              min-height: 30px;
              height: 30px;
              min-width: 50px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  /*ADD CUSTOM CSS HERE*/
}
