
.tw-editable-control-list {
  width: 100%;
  height:100%;
  padding-left: 3px;
  padding-right: 3px;

  input {
    background-color: #E8E8E8;
    width: 100%;
    border: none;
    padding: 4px;
    height: 30px;
  }

  input[type="checkbox"] {
    background-color: red;
    margin-right: 4px;
    height: 20px;
  }

  select {
    background-color: #E8E8E8;
    border: none;
    padding: 4px;
    margin-right: 4px;
    height: 30px;
    width: 100%;
    // not working for option
  }
}
.tw-handle-drag-drop {
  position: absolute;
  width: 10px;
  height: 100%;
  display:none;
}

.tw-line-form-list {
  padding: 5px;
  display: flex;
  align-content: center;
  align-content: center;
  border-bottom: solid;
  border-bottom-color: lightgray;
  border-bottom-width: 1px;
  min-height: 30px;
  width: 100%;
  font-size: 12px;
  min-height: 35px;
}

.tw-list-container {
  position: relative;
  display:flex;
  flex-direction:column;
  //flex: 1 1 0%;
  //width: 100%;
  //flex:1;
  height:100%;
}

.tw-virtual-repete-container {
  position: relative;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}
.tw-virtual-repete-container::-webkit-scrollbar {
  width: 5px;
}
/* Track */
.tw-virtual-repete-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
}

/* Handle */
.tw-virtual-repete-container::-webkit-scrollbar-thumb {
  background-color: dimgrey;
}

.tw-header-list {
  display: flex;
  flex-direction: row;
  padding: 5px;
  background-color: #2d323e !important;
  width: 100%;
  min-height: 50px;
  max-height: 50px;
  color: white;
  width: 100%
} 
.tw-header-column-list {
  display: flex;
  align-items: center;
}
.tw-line-list {
  width: 100%;

  form input:invalid {
    background-color: #FA8258;
  }
}

.tw-line-list.ng-move,
.tw-line-list.ng-enter,
.tw-line-list.ng-leave {
  transition: all linear 0.5s;
}

.tw-line-list.ng-leave.ng-leave-active,
.tw-line-list.ng-move,
.tw-line-list.ng-enter {
  opacity: 0;
  max-height: 0;
}

.tw-line-list.ng-leave,
.tw-line-list.ng-move.ng-move-active,
.tw-line-list.ng-enter.ng-enter-active {
  opacity: 1;
  max-height: 30px;
}
.tw-line-list:hover {
  background-color: #F8F8F8;

  .tw-handle-drag-drop {
    display: flex;
   // background-color:gainsboro;
  }
}



.tw-list-case-content {
  display: flex;
  width: 100%;
  height:100%;
  align-items:center;
  padding-left: 2px;
  
  //text-transform: capitalize;
}
.tw-list-footer {
//  height: 70px;
  width: 100%;
  display: flex;
}
.tw-list-custum-footer {
  width: 100%;
  display: flex;
}
.tw-list-button {
  background: transparent;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  transition: inherit;
  cursor: pointer;
  height: 30px;
  width: 90px;
  display: flex;
  flex-direction: row;
  align-items:center;
  
}
.tw-list-button-Icon {
  width: 25px;
  margin: 0
}
.tw-list-button-IconImportant {
  width: 25px;
  margin: 0;
  color:red;
}
