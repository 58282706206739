.tw-border-g {
  margin: 0 5px;
  width: 30px;
  height: 30px;
  border: 3px solid;
  border-color: #FFD700;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  text-align: center;
  // line-height:24px;
  display: flex;
  position: relative;
  color: #FFD700;
  font-weight: bolder;
  font-size: 20px;

  .tw-border-g-description {
    color: #FFD700;

  }

  .tw-border-g-comlete {
    position: absolute;
    background-color: #FFD700;
    width: 2px;
    height: 6px;
    top: 18px;
    left: 16px;
  }
}
.tw-logo {
  display: flex;
  align-items: center;
  height: 64px;
  padding:10px
}
.tw-logo-description {
  font-family: unset;
  font-weight: bolder;
  font-size: 25px;
}
