.twHeader {
  padding: 8px 0;
  height: 100px;
  min-height: 100px;


  .twTitle {
    color: #2d323e;
    font-size: 24px;
    font-weight: bold;
  }

  .twSubTitle {
    color: #2d323e;
    font-size: 18px;
  }
}

.twToolbar {

  .md-button {
    padding:8px;
    margin:0;
    height: 70px;
    width: 70px;

    md-icon {
      border-color: black;
      border-width: 1px;
      border-radius: 50%;
      height: 35px
    }

    .label {
    }
  }
}
