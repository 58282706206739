#quick-panel {

  .presences-tab {

    #filterUser {
      padding:8px;
      width: 100%;
      height: 35px;
      border-bottom: 1px solid #a7a7a7;
    }

    .users {

      .user {
        position: relative;

        .avatar-wrapper {
          position: relative;

          .status {
            position: absolute;
            bottom: -3px;
            right: 5px;
          }
        }
      }
    }
  }
}
