.tw-navigation-button {
  padding: 0 16px 0 16px;
  background-color: #595959;
  border-left: solid 1px #404040;
  border-top: solid 1px #404040;
  //text-transform: uppercase;
  line-height: 50px;
  height: 100%;
  display: flex;
  text-align: center;
  color: white;
  width: 100%;
  height: 50px; 
}
.tw-navigation-button-selected {
  padding: 0 16px 0 16px;
  background-color: #404040;
  border-left: solid 1px #404040;
  border-top: solid 1px #404040;
  width: 100%;
  height: 50px;
  // text-transform: uppercase;
  display: flex;
  text-align: center;
  color: #EDB415;
  width: 100%;
  height: 50px;
}
.tw-navigation-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 0 16px 0 16px;
  height: 64px;
  min-height: 64px;
}
.tw-navigation-header-logo {
  padding: 5px;
  flex: 1;
  align-self: center;
}
.tw-sidenav {
  transition-property: width;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  height: 100%;
  //-webkit-box-shadow: 5px 0px 6px 0px rgba(0,0,0,0.75);
  //-moz-box-shadow: 5px 0px 6px 0px rgba(0,0,0,0.75);
  //box-shadow: 5px 0px 6px 0px rgba(0,0,0,0.75);
} 
/*.tw-sidenav:hover {
  width: 240px;
}*/
