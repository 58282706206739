.tw-group-info {
  position: absolute;
  z-index: 100;
  background-color:white;
  width:auto;
  border:solid;
  border-color:red;
  border-width:2px;
  height:auto;
}
