table {
  thead {
    background-color: rgb(45,50,62) !important;


    tr {
      background-color: rgb(45,50,62) !important;
    }
  }

  thead {
    tr {
      th {
        color: rgba(255,255,255,0.87) !important;
        background-color: rgb(45,50,62) !important;
        font-weight: normal !important;

        div {
          span {
            font-weight: normal !important;
          }

          md-input-container {
            md-select {
              md-select-value {
                color: rgba(255,255,255,0.87) !important;
                background-color: rgb(45,50,62) !important;
                font-weight: normal !important;
                border-bottom-color: rgba(255,255,255,0.87) !important;
              }
            }
          }
        }
      }
    }
  }
}

.row-success td {
  background-color: #d9e4be;
}

.row-warning td{
  background-color: #f8ea9c;
}

.row-danger td{
  background-color: #e49a9a;
}

.dataTables_scrollHead {
  background-color: rgb(45,50,62) !important;
}

.sulTop {
  color: rgba(255,255,255,0.87) !important;
  background-color: rgb(45,50,62) !important;
}

.inList {
  color: rgb(45,50,62) !important;
  background-color: white;
}

.md-select-icon {
  color: rgba(255,255,255,0.87) !important;
}


