#order {

  .md-medium-button {
    padding: 5px;
    margin: 5px;
    height: 50px;
    width: 50px;
    min-width: 50px !important;
    max-width: 50px;
  }

  md-list {
    margin: 0;
    padding: 0;

    .list-header {
      font-weight: bold;
      max-height: 35px;
      background-color: #808080;
    }

    .cancelled {
      text-decoration: line-through;
      color: lightgray;
    }
  }

  .search {
    position: relative;
    padding-left: 24px;
    max-width: 480px;

    .search-icon {
      margin: 0 -24px 0 0;
    }

    .search-input {
      margin: 0 0 0 32px;
      background: none;
      font-size: 16px;
      line-height: 40px;
      height: 40px;
      color: #2d323e;
      border-bottom: 2px solid #2d323e;
      transition: border-color 300ms ease;

      &:focus {
        border-color: #2d323e;
      }

      &::-webkit-input-placeholder {
        color: #2d323e;
      }
    }
  }


  .center {
    .header {
      min-height: 90px !important;
      max-height: 90px !important;
      padding: 24px 0;
    }

    md-toolbar {
      min-height: 60px !important;
      max-height: 60px !important;
    }

    .toolbar {
      min-height: 60px !important;
      max-height: 60px !important;
    }

    .content {
      padding: 0;
    }
  }



  .strike {
    text-decoration: line-through;
  }

  .action {
    margin: 0;
    padding: 6px;
  }
}

#inventory-order-forms {

    md-input-container, md-select {
        padding: 0 0 0 0;
        margin: 6px 12px 6px 0;
    }

    div.value{
        border-bottom: 1px solid rgba(0, 0, 0, 0.117647);
        margin: 6px 0 24px 0;
        min-height:25px;
    }
}
