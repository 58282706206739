treecontrol {
  /* prevent user selection */
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* default */
  font-family: Verdana, Helvetica, Arial, sans-serif;
  font-size: 12px;
  color: #555;
  text-decoration: none;
  width: 100%
}

treecontrol ul {
  margin: 0;
  padding: 0;
  list-style: none;
  border: none;
  overflow: hidden;
  width: 100%
}

treecontrol li {
  position: relative;
  padding: 0 0 0 15px;
  line-height: 20px;
  width:100%
}

treecontrol li.tree-expanded i.tree-leaf-head, treecontrol li.tree-collapsed i.tree-leaf-head {
  display: none;
}

treecontrol li.tree-expanded i.tree-branch-head, treecontrol li.tree-collapsed i.tree-branch-head {
  display: inline;
}

treecontrol li.tree-leaf i.tree-branch-head {
  display: none;
}

treecontrol li.tree-leaf i.tree-leaf-head {
  display: inline;
}

treecontrol li i.tree-branch-head {
  cursor: pointer;
}

treecontrol li .tree-label {
  cursor: pointer;
  padding: 5px 5px 5px 10px;
  display: inline;
}

treecontrol li .tree-unselectable {
  cursor: not-allowed;
}

treecontrol.tree-classic li.tree-expanded i.tree-branch-head {
  background: url("../assets/images/treeControl/node-opened-light.png") no-repeat;
  background-position-y: 10px;
}

treecontrol.tree-classic li.tree-collapsed i.tree-branch-head {
  background: url("../assets/images/treeControl/node-closed-light.png") no-repeat;
  background-position-y: 10px;
}

treecontrol.tree-classic li.tree-leaf i.tree-leaf-head {
  background: none; // url("../assets/images/treeControl/project.png") no-repeat;
  background-position-y: 10px;
}

treecontrol.tree-classic li .tree-selected {
  background-color: #ffd800;
  font-weight: bold;
}

treecontrol.tree-classic li .tree-unselectable {
  color: #ddd;
}
