.tw-datepiker-input {
  border: none;
  color: rgba(0,0,0,0.87);
  border-color: rgba(0,0,0,0.12);

  /* order: 2; */
  display: block;
  margin-top: 0;
  background: none;
  padding-top: 2px;
  padding-bottom: 1px;
  padding-left: 2px;
  padding-right: 2px;
  border-width: 0 0 1px 0;
  line-height: 26px;
  height: 30px;

  border-radius: 0;
  border-style: solid;
  width: 100%;
  box-sizing: border-box;
  float: left;
}
