#tw-states-menu {
  height: 100%;

  .group {
    margin-top: 20px;
    margin-left: 20px;

    .group-title {
      margin: 0 7px;
      font-weight: bold;
      font-size: 20px;
      color: rgba(128, 128, 128, 0.63);
      text-transform: capitalize;
      border-bottom:2px dotted rgba(128, 128, 128, 0.63);
    }

    .group-content {
      .md-button {
        min-height: 150px;
        min-width: 150px;
        border-radius: 15px;
        margin-left: 7px;
        margin-bottom: 7px;
        font-size: 10px;

        .md-icon {
        }
      }
    }
  }
}
