.tw-filters {
  max-width:600px;

  .date-button {
    min-height: 40px !important;
    max-height: 40px !important;
    min-width: 40px !important;
    max-width: 40px !important;
    margin: 0;
    padding: 8px;
    cursor: pointer;
  }

  .middle {
      md-input-container {
        margin: 0;
        padding: 0;

        .moment-picker {
          max-height: 25px;
        }
      }
  }
}
