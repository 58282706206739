.academy-preview-modules {
  width: 100%;
  max-height: 100%;
  display:flex;
  flex-wrap:wrap;
}

.academy-preview-singlemodule {
  width: 400px;
  height: 250px;
  display: flex;
  padding: 15px;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: white;
  margin: 5px;
  overflow: hidden;
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);

}

.academy-preview-singlemodule:hover {
  .academy-preview-singlemodule-title{
    color:blue;
  }
}
.academy-xp {
  background: #e1f5fe;
  margin:0px;

  padding:5px;
  align-self:center;
  line-height:20px;
}
.academy-preview-singlemodule-title {
  width: 100%;
  height: 40px;
  font-size: 20px;
  font-weight: bold;
  padding: 15px;
  line-height: 1.3;
  text-decoration: none !important;
}
.academy-preview-singlemodule-info {
  width: 100%;
  font-size: 15px;
  padding: 5px;
}
.academy-preview-singlemodule-tags {
  flex: 1;
  overflow: hidden;
  padding: 5px;
  display: flex;
  flex-wrap: wrap
}
.academy-preview-detail-page {
  width: 900px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 5px;
  padding:15px;
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.academy-unit-line {
  width: 100%;
  padding-left: 15px;
  padding-right:15px;

  margin-bottom: 15px
}
.academy-unit-separator {
  width: 5px;
  background-color: rgb(45,50,62);
  height: 40px
}
.academy-unit-line:hover {
  .academy-unit-separator {
    background-color: rgb(255,179,0)
  }
}


.step-container {
  display: flex;
  height: 80px;
  align-items: flex-start;
  position: relative;
  align-content: center;

  .selected-step {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    max-width: 250px;
    background: #e1f5fe;
    color: rgba(0,0,0,.87);
  }

  .step {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    max-width: 250px;
    color: rgba(0,0,0,.54);
  }

  .steptitle {
    padding: 4px;
    font-weight: bold;
    color: rgba(0,0,0,.54);
    flex: 1;
  }

  .steptitle-completed {
    padding: 4px;
    font-weight: bold;
    flex: 1;
  }


  .round-step {
    width: 25px;
    height: 25px;
    line-height: 22px;
    border-radius: 25px;
    background-color: white;
    border: 2px solid gray;
    color: gray;
    z-index: 3;
    left: 0;
    text-align: center;
    font-size: 14px;
  }

  .round-step-completed {
    width: 25px;
    height: 25px;
    line-height: 22px;
    border-radius: 25px;
    background-color: white;
    border: 2px solid green;
    color: green;
    z-index: 3;
    left: 0;
    text-align: center;
    font-size: 14px;
  }

  .round-step::after {
    content: '';
    position: absolute;
    background: gray;
    display: block;
    width: 4px;
    height: 100%;
    top: 50px;
    left: -2px;
    z-index: 1;
    margin-left: 12px
  }

  .round-step-completed::after {
    content: '';
    position: absolute;
    background: green;
    display: block;
    width: 4px;
    height: 100%;
    top: 50px;
    left: -2px;
    z-index: 1;
    margin-left: 12px
  }


  &:last-child {
    .round-step::after {
      display: none;
    }

    .round-step-completed::after {
      display: none;
    }
  }
}


