.tw-schedulegroup-container {
  display: flex;

  md-select {
    margin: 0;
    margin-bottom: 4px;
  }

  md-input-container {
    margin: 0;
    padding:0;
  }
}
