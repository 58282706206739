.tw-header-container {
  height: 100px;
  min-height: 100px;
  max-height: 100px;
  display: flex
}
.tw-header-content {
  height: 100%;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  margin-left: 0;
  margin-right: 0;
  flex: 1
}
.tw-header-additionalcontent {
  display: flex;
  //align-self: flex-end;
  // background-color: rgba(255, 255, 255, 0.75);
  background-color: rgba(255, 255, 255, 1);
  //background-color: #E4E4E4;
  align-items: center;
  //-moz-border-radius: 5px;
  //border-radius: 10px;
  //-webkit-border-radius: 10px;
  flex: 1;
  height: 75px;
  padding: 10px;
  margin-left: 10px;
  // margin-bottom: 10px;
  height: 50px;
  

  md-input-container {
    margin: 0;
    padding: 0;
    padding-right: 5px;
    height: 100%;
    max-width: 250px
  }

  md-select {
    margin: 0;
    padding: 0;
    padding-right: 5px;
    height: 100%;
    max-width: 250px
  }

  md-checkbox {
    margin: 0;
    padding: 0;
    padding-right: 5px;
    max-width: 250px;
    height: 100%;
  }
}
.tw-header-filtedbutton {
  height: 75px;
  display: flex;
  flex: 1
}
