@media print {
    .header {
        display: none;
    }

    .white-bg {
        border: 1px solid #ccc;
    }

    md-tab-item {
        display: none;
    }

    md-tab-item.md-active {
        display: block;
    }

    .tw-widget {
        margin: 0px;
        padding: 0px;
    }

    .layout-row > .flex-gt-md-25, .layout-gt-md-row > .flex-gt-md-25 {
        max-width: 25%;
    }

    .layout-row > .flex-gt-md-50, .layout-gt-md-row > .flex-gt-md-50 {
        max-width: 50%;
    }

    .layout-row > .flex-gt-md-75, .layout-gt-md-row > .flex-gt-md-75 {
        max-width: 75%;
    }

    .layout-row > .flex-gt-md-100, .layout-gt-md-row > .flex-gt-md-100 {
        max-width: 100%;
    }

    .page-layout {
        overflow: visible !important;
    }

    md-content {
        overflow: visible !important;
    }

    .content {
        overflow: visible !important;
    }

    .ms-scroll {
        overflow: visible !important;
    }

    .print-full {
        page-break-inside: avoid;
    }
}
