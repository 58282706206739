#tw-widget-resume {

  .segment {
    .data {
      font-size: 25px;
      font-weight: bold;
      color: #F4B700;
    }


    .danger {
      color: #af0000;
    }

    .label {
      font-size: 12px;
      font-weight: bold;
      color: #808080;
    }

    .ordered {
    }
  }
}
