/**
 *  Do not remove this comments bellow. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
/**
 *  Do not remove this comments bellow. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */
/*----------------------------------------------------------------*/
/*  Reset
/*----------------------------------------------------------------*/
* {
  text-rendering: optimizeLegibility;
  -o-text-rendering: optimizeLegibility;
  -ms-text-rendering: optimizeLegibility;
  -moz-text-rendering: optimizeLegibility;
  -webkit-text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box; }
  *:before, *:after {
    box-sizing: border-box; }
  *:focus {
    outline: none; }

*:not(md-input-container) > input[type="text"],
*:not(md-input-container) > input[type="tel"],
*:not(md-input-container) > input[type="email"],
*:not(md-input-container) > input[type="search"],
*:not(md-input-container) > input[type="password"],
*:not(md-input-container) > input[type="button"],
*:not(md-input-container) > button,
*:not(md-input-container) > input[type="submit"],
*:not(md-input-container) > input[type="image"],
*:not(md-input-container) > textarea {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: none;
  padding: 0;
  margin: 0; }

*:not(md-input-container) > input[type="button"],
*:not(md-input-container) > button,
*:not(md-input-container) > input[type="submit"] {
  background: none; }

button {
  border-radius: 0;
  font-family: inherit;
  font-size: inherit;
  padding: 0;
  margin: 0;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none; }

/*----------------------------------------------------------------*/
/*  Variables
/*----------------------------------------------------------------*/
/*----------------------------------------------------------------*/
/*  Mixins
/*----------------------------------------------------------------*/
/*----------------------------------------------------------------*/
/*  Angular Material Extend
/*  Adds and/or modifies Angular Material styles
/*----------------------------------------------------------------*/
/* --- FIXES --- */
[layout] > * {
  min-height: auto;
  min-height: initial; }

md-card > img,
md-card > :not(md-card-content) img {
  height: inherit;
  height: initial; }

.md-datepicker-calendar-pane * {
  box-sizing: initial; }

/* --- MODIFICATIONS --- */
md-autocomplete-wrap > input[type="search"] {
  background: #FFFFFF;
  padding: 0 15px !important; }

md-backdrop.md-opaque {
  background-color: rgba(33, 33, 33, 0.48) !important; }

md-card md-card-header md-card-avatar md-icon {
  width: 40px;
  height: 40px; }

md-checkbox.no-label {
  margin: 0; }

md-chip .md-chip-remove md-icon {
  margin-left: 2px; }
  md-chip .md-chip-remove md-icon svg {
    width: 18px;
    height: 18px;
    max-width: 18px;
    max-height: 18px;
    margin: 4px; }

md-datepicker .md-datepicker-button {
  padding: 0 !important;
  margin: 0 !important;
  vertical-align: middle; }

md-datepicker .md-datepicker-input-container {
  margin-left: 0; }

.md-inline-form md-datepicker {
  margin-top: 14px; }

md-dialog md-dialog-content {
  padding: 32px 24px 24px 24px; }

md-dialog md-dialog-actions {
  min-height: 64px;
  padding-top: 8px;
  padding-bottom: 8px; }
  md-dialog md-dialog-actions .md-button {
    margin: 0 8px; }

md-input-container.no-errors-spacer .md-errors-spacer {
  display: none; }

md-input-container .input-hint {
  position: absolute;
  right: auto;
  bottom: 7px;
  left: 2px;
  font-size: 12px;
  line-height: 14px;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  color: rgba(0, 0, 0, 0.54); }

md-input-container.md-icon-left .input-hint {
  left: 38px; }

md-list-item .md-list-item-text p {
  font-size: 13px !important; }

md-menu,
.md-menu {
  padding: 0; }

md-menu-content.md-menu-bar-menu.md-dense {
  padding: 8px 0; }
  md-menu-content.md-menu-bar-menu.md-dense .md-button,
  md-menu-content.md-menu-bar-menu.md-dense md-menu-item {
    height: 40px;
    line-height: 40px; }
    md-menu-content.md-menu-bar-menu.md-dense .md-button.md-indent > md-icon,
    md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent > md-icon {
      top: 8px; }

.md-open-menu-container md-menu-content md-menu-item.selected {
  opacity: 0.4; }

md-menu-content md-menu-item .md-button {
  text-align: left; }

md-select.simplified {
  margin: 0 8px; }
  md-select.simplified .md-select-value {
    border: none !important;
    box-sizing: border-box;
    padding: 0; }
    md-select.simplified .md-select-value *:first-child {
      transform: none;
      height: auto; }
    md-select.simplified .md-select-value .md-select-icon {
      transform: none;
      font-size: 16px; }
      md-select.simplified .md-select-value .md-select-icon:after {
        top: 1px; }

md-select[multiple] md-select-value > *:first-child {
  display: flex; }
  md-select[multiple] md-select-value > *:first-child > *:first-child {
    flex: 0; }
  md-select[multiple] md-select-value > *:first-child .md-container {
    padding-right: 6px; }
    md-select[multiple] md-select-value > *:first-child .md-container:first-of-type {
      padding-right: 0; }

md-select-menu md-select-header {
  position: relative;
  display: flex;
  align-items: center;
  width: auto;
  height: 48px;
  padding-left: 10.667px;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
  cursor: pointer; }
  md-select-menu md-select-header input[type="search"] {
    border: none;
    outline: none;
    height: 100%;
    width: 100%;
    padding: 0; }

md-sidenav {
  -webkit-overflow-scrolling: touch; }

md-tab-content {
  touch-action: initial !important; }

md-toolbar.colored-toolbar {
  color: rgba(255, 255, 255, 0.87); }
  md-toolbar.colored-toolbar md-menu-bar md-menu._md-open > button {
    background: rgba(0, 0, 0, 0.12); }
  md-toolbar.colored-toolbar md-menu._md-open > button {
    background: rgba(0, 0, 0, 0.12); }
  md-toolbar.colored-toolbar input {
    color: rgba(255, 255, 255, 0.87) !important; }

md-progress-circular[disabled] {
  visibility: hidden; }

/*----------------------------------------------------------------*/
/*  Template Layouts
/*----------------------------------------------------------------*/
html,
body {
  overflow: hidden !important; }

#main {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

body.boxed {
  background: #3F4450; }
  body.boxed #main {
    overflow: hidden !important;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.45); }

#layout-content-only {
  position: relative;
  height: 100%;
  max-height: 100%; }
  #layout-content-only #content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }

#layout-content-with-toolbar {
  position: relative;
  height: 100%;
  max-height: 100%; }
  #layout-content-with-toolbar #content {
    position: absolute;
    top: 64px;
    right: 0;
    bottom: 0;
    left: 0; }

#layout-vertical-navigation {
  height: 100%;
  max-height: 100%; }
  #layout-vertical-navigation #content-container {
    position: relative;
    overflow: hidden; }
    #layout-vertical-navigation #content-container #content {
      position: absolute;
      top: 64px;
      right: 0;
      bottom: 0;
      left: 0; }

#layout-vertical-navigation-fullwidth-toolbar {
  height: 100%;
  max-height: 100%; }
  #layout-vertical-navigation-fullwidth-toolbar #main-container {
    position: relative; }
    #layout-vertical-navigation-fullwidth-toolbar #main-container #content-container {
      position: relative;
      overflow: hidden; }
      #layout-vertical-navigation-fullwidth-toolbar #main-container #content-container #content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0; }

#layout-vertical-navigation-fullwidth-toolbar-2 {
  height: 100%;
  max-height: 100%; }
  #layout-vertical-navigation-fullwidth-toolbar-2 #main-container {
    position: relative; }
    #layout-vertical-navigation-fullwidth-toolbar-2 #main-container #content-container {
      position: relative;
      overflow: hidden; }
      #layout-vertical-navigation-fullwidth-toolbar-2 #main-container #content-container #content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0; }

#layout-horizontal-navigation {
  height: 100%;
  max-height: 100%; }
  #layout-horizontal-navigation #content-container {
    position: relative;
    overflow: hidden; }
    #layout-horizontal-navigation #content-container #content {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0; }

/*----------------------------------------------------------------*/
/*  Page Layouts
/*----------------------------------------------------------------*/
.page-layout {
  position: relative;
  overflow: hidden; }
  .page-layout .top-bg {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    left: 0;
    height: 150px;
    background-image: url("/assets/images/backgrounds/header-bg.png");
    background-size: cover; }
  .page-layout md-backdrop {
    z-index: 50; }
  .page-layout > .header .breadcrumb {
    margin-bottom: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.54); }
    .page-layout > .header .breadcrumb md-icon {
      margin: 0; }
    .page-layout > .header .breadcrumb .separator {
      margin: 0 8px; }
  .page-layout > .header .title {
    font-size: 34px; }
  .page-layout.carded {
    min-height: 100%;
    height: 100%; }
    .page-layout.carded.fullwidth.single-scroll {
      height: auto; }
      .page-layout.carded.fullwidth.single-scroll > .center .content-card .content {
        overflow: hidden; }
    .page-layout.carded.fullwidth > .center {
      position: relative;
      z-index: 2;
      margin-left: 32px;
      margin-right: 32px; }
      .page-layout.carded.fullwidth > .center .header {
        height: 86px;
        min-height: 86px;
        max-height: 86px;
        padding: 24px; }
      .page-layout.carded.fullwidth > .center .content-card {
        background: #FFFFFF;
        box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
        overflow: hidden; }
        .page-layout.carded.fullwidth > .center .content-card .toolbar {
          padding: 8px 24px;
          height: 64px;
          min-height: 64px;
          max-height: 64px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
        .page-layout.carded.fullwidth > .center .content-card .content {
          padding: 24px;
          background: #FFFFFF; }
    .page-layout.carded.left-sidenav.single-scroll {
      height: auto; }
      .page-layout.carded.left-sidenav.single-scroll > .center .content-card .content {
        overflow: hidden; }
    .page-layout.carded.left-sidenav > .sidenav {
      width: 240px;
      min-width: 240px;
      max-width: 240px;
      box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
      z-index: 51; }
      .page-layout.carded.left-sidenav > .sidenav .header {
        height: 150px;
        min-height: 150px;
        max-height: 150px;
        padding: 24px; }
      .page-layout.carded.left-sidenav > .sidenav .content {
        background: transparent;
        padding: 24px; }
      .page-layout.carded.left-sidenav > .sidenav.md-locked-open {
        width: 220px;
        min-width: 220px;
        max-width: 220px;
        z-index: 2;
        background: transparent;
        box-shadow: none; }
        .page-layout.carded.left-sidenav > .sidenav.md-locked-open + .center {
          margin-left: 0; }
      .page-layout.carded.left-sidenav > .sidenav:not(.md-locked-open) .header {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
      .page-layout.carded.left-sidenav > .sidenav:not(.md-locked-open) .content {
        overflow: hidden; }
    .page-layout.carded.left-sidenav > .center {
      position: relative;
      z-index: 2;
      margin-left: 32px;
      margin-right: 32px; }
      .page-layout.carded.left-sidenav > .center .header {
        height: 86px;
        min-height: 86px;
        max-height: 86px;
        padding: 24px; }
      .page-layout.carded.left-sidenav > .center .content-card {
        background: #FFFFFF;
        box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
        overflow: hidden; }
        .page-layout.carded.left-sidenav > .center .content-card .toolbar {
          padding: 8px 24px;
          height: 64px;
          min-height: 64px;
          max-height: 64px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
          .page-layout.carded.left-sidenav > .center .content-card .toolbar .sidenav-toggle {
            margin: 0 8px 0 0 !important;
            padding: 0 !important;
            border-radius: 0; }
        .page-layout.carded.left-sidenav > .center .content-card .content {
          padding: 24px;
          background: #FFFFFF; }
    .page-layout.carded.right-sidenav.single-scroll {
      height: auto; }
      .page-layout.carded.right-sidenav.single-scroll > .center .content-card .content {
        overflow: hidden; }
    .page-layout.carded.right-sidenav > .sidenav {
      width: 240px;
      min-width: 240px;
      max-width: 240px;
      box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
      z-index: 51; }
      .page-layout.carded.right-sidenav > .sidenav .header {
        height: 150px;
        min-height: 150px;
        max-height: 150px;
        padding: 24px 24px 24px 0; }
      .page-layout.carded.right-sidenav > .sidenav .content {
        background: transparent;
        padding: 24px 24px 24px 0; }
      .page-layout.carded.right-sidenav > .sidenav.md-locked-open {
        width: 196px;
        min-width: 196px;
        max-width: 196px;
        z-index: 2;
        background: transparent;
        box-shadow: none; }
      .page-layout.carded.right-sidenav > .sidenav:not(.md-locked-open) .header {
        padding: 24px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
      .page-layout.carded.right-sidenav > .sidenav:not(.md-locked-open) .content {
        overflow: hidden;
        padding: 24px; }
    .page-layout.carded.right-sidenav > .center {
      position: relative;
      z-index: 2;
      margin-left: 32px;
      margin-right: 32px; }
      .page-layout.carded.right-sidenav > .center .header {
        height: 86px;
        min-height: 86px;
        max-height: 86px;
        padding: 24px; }
      .page-layout.carded.right-sidenav > .center .content-card {
        background: #FFFFFF;
        box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
        overflow: hidden; }
        .page-layout.carded.right-sidenav > .center .content-card .toolbar {
          padding: 8px 24px;
          height: 64px;
          min-height: 64px;
          max-height: 64px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
          .page-layout.carded.right-sidenav > .center .content-card .toolbar .sidenav-toggle {
            margin: 0 0 0 8px !important;
            padding: 0 !important;
            border-radius: 0; }
        .page-layout.carded.right-sidenav > .center .content-card .content {
          padding: 24px;
          background: #FFFFFF; }
  .page-layout.simple.fullwidth, .page-layout.simple.inner-sidenav {
    min-height: 100%; }
    .page-layout.simple.fullwidth > .header, .page-layout.simple.inner-sidenav > .header {
      height: 150px;
      min-height: 150px;
      max-height: 150px;
      padding: 24px;
      background-image: url("/assets/images/backgrounds/header-bg.png");
      background-size: cover; }
    .page-layout.simple.fullwidth > .content, .page-layout.simple.inner-sidenav > .content {
      padding: 24px; }
  .page-layout.simple.left-sidenav {
    min-height: 100%;
    height: 100%; }
    .page-layout.simple.left-sidenav.single-scroll {
      height: auto; }
      .page-layout.simple.left-sidenav.single-scroll > .center {
        overflow: hidden; }
    .page-layout.simple.left-sidenav.inner-sidenav > .content > md-sidenav {
      margin-right: 24px; }
    .page-layout.simple.left-sidenav .sidenav {
      width: 240px;
      min-width: 240px;
      max-width: 240px;
      padding: 24px;
      z-index: 51;
      box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12); }
      .page-layout.simple.left-sidenav .sidenav.md-locked-open {
        width: 220px;
        min-width: 220px;
        max-width: 220px;
        z-index: 2;
        box-shadow: none;
        background: transparent; }
    .page-layout.simple.left-sidenav > .center {
      position: relative;
      overflow: auto;
      z-index: 3;
      box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12); }
      .page-layout.simple.left-sidenav > .center .header {
        height: 150px;
        min-height: 150px;
        max-height: 150px;
        padding: 24px;
        background-image: url("/assets/images/backgrounds/header-bg.png");
        background-size: cover; }
      .page-layout.simple.left-sidenav > .center .content {
        padding: 24px;
        background: #FFFFFF; }
  .page-layout.simple.right-sidenav {
    min-height: 100%;
    height: 100%; }
    .page-layout.simple.right-sidenav.single-scroll {
      height: auto; }
      .page-layout.simple.right-sidenav.single-scroll > .center {
        overflow: hidden; }
    .page-layout.simple.right-sidenav.inner-sidenav > .content > md-sidenav {
      margin-left: 24px; }
    .page-layout.simple.right-sidenav .sidenav {
      width: 240px;
      min-width: 240px;
      max-width: 240px;
      padding: 24px;
      z-index: 51;
      box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12); }
      .page-layout.simple.right-sidenav .sidenav.md-locked-open {
        width: 220px;
        min-width: 220px;
        max-width: 220px;
        z-index: 2;
        box-shadow: none;
        background: transparent; }
    .page-layout.simple.right-sidenav > .center {
      position: relative;
      overflow: auto;
      z-index: 3;
      box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12); }
      .page-layout.simple.right-sidenav > .center .header {
        height: 150px;
        min-height: 150px;
        max-height: 150px;
        padding: 24px;
        background-image: url("/assets/images/backgrounds/header-bg.png");
        background-size: cover; }
      .page-layout.simple.right-sidenav > .center .content {
        padding: 24px;
        background: #FFFFFF; }
  .page-layout.simple.inner-sidenav {
    height: auto; }
    .page-layout.simple.inner-sidenav > .content > md-sidenav.md-locked-open {
      padding: 0; }
  .page-layout.simple.tabbed {
    min-height: 100%; }
    .page-layout.simple.tabbed > .header {
      height: 150px;
      min-height: 150px;
      max-height: 150px;
      padding: 24px;
      background-image: url("/assets/images/backgrounds/header-bg.png");
      background-size: cover; }
    .page-layout.simple.tabbed > .content > md-tabs > md-tabs-wrapper {
      background: #FFFFFF;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
      padding: 0 24px; }
      .page-layout.simple.tabbed > .content > md-tabs > md-tabs-wrapper md-tabs-canvas {
        height: 56px; }
        .page-layout.simple.tabbed > .content > md-tabs > md-tabs-wrapper md-tabs-canvas md-pagination-wrapper {
          height: 56px; }
        .page-layout.simple.tabbed > .content > md-tabs > md-tabs-wrapper md-tabs-canvas .md-tab {
          padding: 16px 24px;
          text-transform: none; }
    .page-layout.simple.tabbed > .content > md-tabs:not(.md-dynamic-height) md-tabs-content-wrapper {
      top: 56px; }
    .page-layout.simple.tabbed > .content > md-tabs > md-tabs-content-wrapper > md-tab-content {
      padding: 24px; }
    .page-layout.simple.tabbed > .content > md-nav-bar .md-nav-bar {
      background: #FFFFFF;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
      border: none;
      height: 56px; }
      .page-layout.simple.tabbed > .content > md-nav-bar .md-nav-bar nav {
        margin: 0 12px; }
        .page-layout.simple.tabbed > .content > md-nav-bar .md-nav-bar nav .md-nav-item {
          height: 56px; }
          .page-layout.simple.tabbed > .content > md-nav-bar .md-nav-bar nav .md-nav-item ._md-nav-button {
            height: 56px;
            line-height: 32px; }
          .page-layout.simple.tabbed > .content > md-nav-bar .md-nav-bar nav .md-nav-item ._md-nav-button-text {
            text-transform: none; }
  .page-layout.blank {
    min-height: 100%;
    padding: 24px; }

.single-scroll .carded.fullwidth {
  height: auto; }
  .single-scroll .carded.fullwidth > .center .content-card .content {
    overflow: hidden; }

.single-scroll .carded.left-sidenav {
  height: auto; }
  .single-scroll .carded.left-sidenav > .center .content-card .content {
    overflow: hidden; }

.single-scroll .carded.right-sidenav {
  height: auto; }
  .single-scroll .carded.right-sidenav > .center .content-card .content {
    overflow: hidden; }

.single-scroll .simple.left-sidenav {
  height: auto; }
  .single-scroll .simple.left-sidenav > .center {
    overflow: hidden; }

.single-scroll .simple.right-sidenav {
  height: auto; }
  .single-scroll .simple.right-sidenav > .center {
    overflow: hidden; }

@media screen and (max-width: 1280px) {
  .page-layout.simple.inner-sidenav.right-sidenav > .content, .page-layout.simple.inner-sidenav.left-sidenav > .content {
    height: auto !important; }
    .page-layout.simple.inner-sidenav.right-sidenav > .content > md-sidenav, .page-layout.simple.inner-sidenav.left-sidenav > .content > md-sidenav {
      margin-left: 0;
      margin-right: 0; }
  .sidenav-open .page-layout.simple.inner-sidenav {
    height: 100%; } }

@media screen and (max-width: 600px) {
  .page-layout .top-bg {
    height: 160px; }
  .page-layout.carded.right-sidenav > .center, .page-layout.carded.left-sidenav > .center, .page-layout.carded.fullwidth > .center {
    margin-left: 16px;
    margin-right: 16px; }
    .page-layout.carded.right-sidenav > .center .header, .page-layout.carded.left-sidenav > .center .header, .page-layout.carded.fullwidth > .center .header {
      height: 96px;
      min-height: 96px;
      max-height: 96px;
      padding: 16px; }
  .page-layout.carded.fullwidth {
    height: auto; }
    .page-layout.carded.fullwidth > .center .content-card .content {
      overflow: hidden; }
  .page-layout.carded.right-sidenav, .page-layout.carded.left-sidenav {
    height: auto; }
    .page-layout.carded.right-sidenav > .center .content-card .content, .page-layout.carded.left-sidenav > .center .content-card .content {
      overflow: hidden; }
  .page-layout.simple.fullwidth > .header, .page-layout.simple.inner-sidenav > .header {
    height: 160px;
    min-height: 160px;
    max-height: 160px;
    padding: 16px; }
  .page-layout.simple.right-sidenav > .center .header, .page-layout.simple.left-sidenav > .center .header {
    height: 160px;
    min-height: 160px;
    max-height: 160px;
    padding: 16px; }
  .page-layout.simple.left-sidenav, .page-layout.simple.right-sidenav {
    height: auto; }
    .page-layout.simple.left-sidenav > .center, .page-layout.simple.right-sidenav > .center {
      overflow: hidden; } }

/*----------------------------------------------------------------*/
/*  Animations
/*----------------------------------------------------------------*/
.animate-slide-up {
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000px;
  transition-property: none;
  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1; }
  .animate-slide-up.ng-enter {
    transform: translateY(100%);
    opacity: 0; }
    .animate-slide-up.ng-enter.ng-enter-active {
      transition-property: all;
      transform: translateY(0);
      opacity: 1; }
  .animate-slide-up.ng-leave {
    transform: translateY(0);
    opacity: 1; }
    .animate-slide-up.ng-leave.ng-leave-active {
      transition-property: all;
      transform: translateY(-100%);
      opacity: 0; }
  .animate-slide-up.ng-hide {
    transform: translateY(-100%);
    opacity: 0; }
  .animate-slide-up.ng-hide-add-active, .animate-slide-up.ng-hide-remove-active {
    transition-property: all; }

.animate-slide-down {
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000px;
  transition-property: none;
  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1; }
  .animate-slide-down.ng-enter {
    transform: translateY(-100%);
    opacity: 0; }
    .animate-slide-down.ng-enter.ng-enter-active {
      transition-property: all;
      transform: translateY(0);
      opacity: 1; }
  .animate-slide-down.ng-leave {
    transform: translateY(0);
    opacity: 1; }
    .animate-slide-down.ng-leave.ng-leave-active {
      transition-property: all;
      transform: translateY(100%);
      opacity: 0; }
  .animate-slide-down.ng-hide {
    transform: translateY(-100%);
    opacity: 0; }
  .animate-slide-down.ng-hide-add-active, .animate-slide-down.ng-hide-remove-active {
    transition-property: all; }

.animate-slide-left {
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000px;
  transition-property: none;
  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1; }
  .animate-slide-left.ng-enter {
    transform: translateX(100%);
    opacity: 0; }
    .animate-slide-left.ng-enter.ng-enter-active {
      transition-property: all;
      transform: translateX(0);
      opacity: 1; }
  .animate-slide-left.ng-leave {
    transform: translateX(0);
    opacity: 1; }
    .animate-slide-left.ng-leave.ng-leave-active {
      transition-property: all;
      transform: translateX(-100%);
      opacity: 0; }
  .animate-slide-left.ng-hide {
    transform: translateX(-100%);
    opacity: 0; }
  .animate-slide-left.ng-hide-add-active, .animate-slide-left.ng-hide-remove-active {
    transition-property: all; }

.animate-slide-right {
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000px;
  transition-property: none;
  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1; }
  .animate-slide-right.ng-enter {
    transform: translateX(-100%);
    opacity: 0; }
    .animate-slide-right.ng-enter.ng-enter-active {
      transition-property: all;
      transform: translateX(0);
      opacity: 1; }
  .animate-slide-right.ng-leave {
    transform: translateX(0);
    opacity: 1; }
    .animate-slide-right.ng-leave.ng-leave-active {
      transition-property: all;
      transform: translateX(100%);
      opacity: 0; }
  .animate-slide-right.ng-hide {
    transform: translateX(100%);
    opacity: 0; }
  .animate-slide-right.ng-hide-add-active, .animate-slide-right.ng-hide-remove-active {
    transition-property: all; }

.animate-fade-in-out {
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000px;
  transition-property: none;
  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1; }
  .animate-fade-in-out.ng-enter {
    opacity: 0; }
    .animate-fade-in-out.ng-enter.ng-enter-active {
      transition-property: all;
      opacity: 1; }
  .animate-fade-in-out.ng-leave {
    opacity: 1; }
    .animate-fade-in-out.ng-leave.ng-leave-active {
      transition-property: all;
      opacity: 0; }
  .animate-fade-in-out.ng-hide {
    opacity: 0; }
  .animate-fade-in-out.ng-hide-add-active, .animate-fade-in-out.ng-hide-remove-active {
    transition-property: all; }

/*----------------------------------------------------------------*/
/*  @ Custom Animation Keyframes
/*----------------------------------------------------------------*/
@keyframes slide-in-left {
  0% {
    opacity: 0;
    transform: translateX(-500px); }
  65% {
    opacity: 1;
    transform: translateX(0); }
  100% {
    transform: translateX(0); } }

@keyframes slide-in-right {
  0% {
    opacity: 0;
    transform: translateX(500px); }
  65% {
    opacity: 1;
    transform: translateX(0); }
  100% {
    transform: translateX(0); } }

@keyframes slide-in-bottom {
  0% {
    opacity: 0;
    transform: translateY(200px); }
  65% {
    opacity: 1;
    transform: translateY(0); }
  100% {
    transform: translateY(0); } }

.animate-rotate {
  animation: rotate 1.75s linear infinite; }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/*----------------------------------------------------------------*/
/*  Colors
/*----------------------------------------------------------------*/
.black-fg,
.black-text {
  color: rgba(0, 0, 0, 0.87) !important; }
  .black-fg.secondary-text,
  .black-fg .secondary-text, .black-fg.icon,
  .black-fg .icon,
  .black-text.secondary-text,
  .black-text .secondary-text,
  .black-text.icon,
  .black-text .icon {
    color: rgba(0, 0, 0, 0.54) !important; }
  .black-fg.hint-text,
  .black-fg .hint-text, .black-fg.disabled-text,
  .black-fg .disabled-text,
  .black-text.hint-text,
  .black-text .hint-text,
  .black-text.disabled-text,
  .black-text .disabled-text {
    color: rgba(0, 0, 0, 0.26) !important; }
  .black-fg.divider,
  .black-fg .divider, .black-fg.faint-text,
  .black-fg .faint-text,
  .black-text.divider,
  .black-text .divider,
  .black-text.faint-text,
  .black-text .faint-text {
    color: rgba(0, 0, 0, 0.12) !important; }

.white-fg,
.white-text {
  color: white !important; }
  .white-fg.secondary-text,
  .white-fg .secondary-text, .white-fg.icon,
  .white-fg .icon,
  .white-text.secondary-text,
  .white-text .secondary-text,
  .white-text.icon,
  .white-text .icon {
    color: rgba(255, 255, 255, 0.7) !important; }
  .white-fg.hint-text,
  .white-fg .hint-text, .white-fg.disabled-text,
  .white-fg .disabled-text,
  .white-text.hint-text,
  .white-text .hint-text,
  .white-text.disabled-text,
  .white-text .disabled-text {
    color: rgba(255, 255, 255, 0.3) !important; }
  .white-fg.divider,
  .white-fg .divider, .white-fg.faint-text,
  .white-fg .faint-text,
  .white-text.divider,
  .white-text .divider,
  .white-text.faint-text,
  .white-text .faint-text {
    color: rgba(255, 255, 255, 0.12) !important; }
  .white-fg md-icon,
  .white-text md-icon {
    color: #FFFFFF; }
  .white-fg md-progress-linear .md-container,
  .white-text md-progress-linear .md-container {
    background: rgba(255, 255, 255, 0.3); }
  .white-fg md-progress-linear .md-bar,
  .white-text md-progress-linear .md-bar {
    background: rgba(255, 255, 255, 0.7); }

.red-50-bg {
  background-color: #FFEBEE !important; }

.md-red-50-bg {
  background-color: #FFEBEE !important;
  color: rgba(0, 0, 0, 0.87); }

.red-50-fg {
  color: #FFEBEE !important; }

.red-50-border {
  border-color: #FFEBEE !important; }

.red-50-border-top {
  border-top-color: #FFEBEE !important; }

.red-50-border-right {
  border-right-color: #FFEBEE !important; }

.red-50-border-bottom {
  border-bottom-color: #FFEBEE !important; }

.red-50-border-left {
  border-left-color: #FFEBEE !important; }

.red-100-bg {
  background-color: #FFCDD2 !important; }

.md-red-100-bg {
  background-color: #FFCDD2 !important;
  color: rgba(0, 0, 0, 0.87); }

.red-100-fg {
  color: #FFCDD2 !important; }

.red-100-border {
  border-color: #FFCDD2 !important; }

.red-100-border-top {
  border-top-color: #FFCDD2 !important; }

.red-100-border-right {
  border-right-color: #FFCDD2 !important; }

.red-100-border-bottom {
  border-bottom-color: #FFCDD2 !important; }

.red-100-border-left {
  border-left-color: #FFCDD2 !important; }

.red-200-bg {
  background-color: #EF9A9A !important; }

.md-red-200-bg {
  background-color: #EF9A9A !important;
  color: rgba(0, 0, 0, 0.87); }

.red-200-fg {
  color: #EF9A9A !important; }

.red-200-border {
  border-color: #EF9A9A !important; }

.red-200-border-top {
  border-top-color: #EF9A9A !important; }

.red-200-border-right {
  border-right-color: #EF9A9A !important; }

.red-200-border-bottom {
  border-bottom-color: #EF9A9A !important; }

.red-200-border-left {
  border-left-color: #EF9A9A !important; }

.red-300-bg {
  background-color: #E57373 !important; }

.md-red-300-bg {
  background-color: #E57373 !important;
  color: rgba(0, 0, 0, 0.87); }

.red-300-fg {
  color: #E57373 !important; }

.red-300-border {
  border-color: #E57373 !important; }

.red-300-border-top {
  border-top-color: #E57373 !important; }

.red-300-border-right {
  border-right-color: #E57373 !important; }

.red-300-border-bottom {
  border-bottom-color: #E57373 !important; }

.red-300-border-left {
  border-left-color: #E57373 !important; }

.red-400-bg {
  background-color: #EF5350 !important; }

.md-red-400-bg {
  background-color: #EF5350 !important;
  color: white; }

.red-400-fg {
  color: #EF5350 !important; }

.red-400-border {
  border-color: #EF5350 !important; }

.red-400-border-top {
  border-top-color: #EF5350 !important; }

.red-400-border-right {
  border-right-color: #EF5350 !important; }

.red-400-border-bottom {
  border-bottom-color: #EF5350 !important; }

.red-400-border-left {
  border-left-color: #EF5350 !important; }

.red-500-bg {
  background-color: #F44336 !important; }

.md-red-500-bg {
  background-color: #F44336 !important;
  color: white; }

.red-500-fg {
  color: #F44336 !important; }

.red-500-border {
  border-color: #F44336 !important; }

.red-500-border-top {
  border-top-color: #F44336 !important; }

.red-500-border-right {
  border-right-color: #F44336 !important; }

.red-500-border-bottom {
  border-bottom-color: #F44336 !important; }

.red-500-border-left {
  border-left-color: #F44336 !important; }

.md-red-bg {
  background-color: #F44336 !important;
  color: white; }

.red-bg {
  background-color: #F44336 !important; }

.red-fg {
  color: #F44336 !important; }

.red-border {
  border-color: #F44336 !important; }

.red-border-top {
  border-top-color: #F44336 !important; }

.red-border-right {
  border-right-color: #F44336 !important; }

.red-border-bottom {
  border-bottom-color: #F44336 !important; }

.red-border-left {
  border-left-color: #F44336 !important; }

.red-600-bg {
  background-color: #E53935 !important; }

.md-red-600-bg {
  background-color: #E53935 !important;
  color: white; }

.red-600-fg {
  color: #E53935 !important; }

.red-600-border {
  border-color: #E53935 !important; }

.red-600-border-top {
  border-top-color: #E53935 !important; }

.red-600-border-right {
  border-right-color: #E53935 !important; }

.red-600-border-bottom {
  border-bottom-color: #E53935 !important; }

.red-600-border-left {
  border-left-color: #E53935 !important; }

.red-700-bg {
  background-color: #D32F2F !important; }

.md-red-700-bg {
  background-color: #D32F2F !important;
  color: white; }

.red-700-fg {
  color: #D32F2F !important; }

.red-700-border {
  border-color: #D32F2F !important; }

.red-700-border-top {
  border-top-color: #D32F2F !important; }

.red-700-border-right {
  border-right-color: #D32F2F !important; }

.red-700-border-bottom {
  border-bottom-color: #D32F2F !important; }

.red-700-border-left {
  border-left-color: #D32F2F !important; }

.red-800-bg {
  background-color: #C62828 !important; }

.md-red-800-bg {
  background-color: #C62828 !important;
  color: rgba(255, 255, 255, 0.87); }

.red-800-fg {
  color: #C62828 !important; }

.red-800-border {
  border-color: #C62828 !important; }

.red-800-border-top {
  border-top-color: #C62828 !important; }

.red-800-border-right {
  border-right-color: #C62828 !important; }

.red-800-border-bottom {
  border-bottom-color: #C62828 !important; }

.red-800-border-left {
  border-left-color: #C62828 !important; }

.red-900-bg {
  background-color: #B71C1C !important; }

.md-red-900-bg {
  background-color: #B71C1C !important;
  color: rgba(255, 255, 255, 0.87); }

.red-900-fg {
  color: #B71C1C !important; }

.red-900-border {
  border-color: #B71C1C !important; }

.red-900-border-top {
  border-top-color: #B71C1C !important; }

.red-900-border-right {
  border-right-color: #B71C1C !important; }

.red-900-border-bottom {
  border-bottom-color: #B71C1C !important; }

.red-900-border-left {
  border-left-color: #B71C1C !important; }

.red-A100-bg {
  background-color: #FF8A80 !important; }

.md-red-A100-bg {
  background-color: #FF8A80 !important;
  color: rgba(0, 0, 0, 0.87); }

.red-A100-fg {
  color: #FF8A80 !important; }

.red-A100-border {
  border-color: #FF8A80 !important; }

.red-A100-border-top {
  border-top-color: #FF8A80 !important; }

.red-A100-border-right {
  border-right-color: #FF8A80 !important; }

.red-A100-border-bottom {
  border-bottom-color: #FF8A80 !important; }

.red-A100-border-left {
  border-left-color: #FF8A80 !important; }

.red-A200-bg {
  background-color: #FF5252 !important; }

.md-red-A200-bg {
  background-color: #FF5252 !important;
  color: white; }

.red-A200-fg {
  color: #FF5252 !important; }

.red-A200-border {
  border-color: #FF5252 !important; }

.red-A200-border-top {
  border-top-color: #FF5252 !important; }

.red-A200-border-right {
  border-right-color: #FF5252 !important; }

.red-A200-border-bottom {
  border-bottom-color: #FF5252 !important; }

.red-A200-border-left {
  border-left-color: #FF5252 !important; }

.red-A400-bg {
  background-color: #FF1744 !important; }

.md-red-A400-bg {
  background-color: #FF1744 !important;
  color: white; }

.red-A400-fg {
  color: #FF1744 !important; }

.red-A400-border {
  border-color: #FF1744 !important; }

.red-A400-border-top {
  border-top-color: #FF1744 !important; }

.red-A400-border-right {
  border-right-color: #FF1744 !important; }

.red-A400-border-bottom {
  border-bottom-color: #FF1744 !important; }

.red-A400-border-left {
  border-left-color: #FF1744 !important; }

.red-A700-bg {
  background-color: #D50000 !important; }

.md-red-A700-bg {
  background-color: #D50000 !important;
  color: white; }

.red-A700-fg {
  color: #D50000 !important; }

.red-A700-border {
  border-color: #D50000 !important; }

.red-A700-border-top {
  border-top-color: #D50000 !important; }

.red-A700-border-right {
  border-right-color: #D50000 !important; }

.red-A700-border-bottom {
  border-bottom-color: #D50000 !important; }

.red-A700-border-left {
  border-left-color: #D50000 !important; }

.pink-50-bg {
  background-color: #FCE4EC !important; }

.md-pink-50-bg {
  background-color: #FCE4EC !important;
  color: rgba(0, 0, 0, 0.87); }

.pink-50-fg {
  color: #FCE4EC !important; }

.pink-50-border {
  border-color: #FCE4EC !important; }

.pink-50-border-top {
  border-top-color: #FCE4EC !important; }

.pink-50-border-right {
  border-right-color: #FCE4EC !important; }

.pink-50-border-bottom {
  border-bottom-color: #FCE4EC !important; }

.pink-50-border-left {
  border-left-color: #FCE4EC !important; }

.pink-100-bg {
  background-color: #F8BBD0 !important; }

.md-pink-100-bg {
  background-color: #F8BBD0 !important;
  color: rgba(0, 0, 0, 0.87); }

.pink-100-fg {
  color: #F8BBD0 !important; }

.pink-100-border {
  border-color: #F8BBD0 !important; }

.pink-100-border-top {
  border-top-color: #F8BBD0 !important; }

.pink-100-border-right {
  border-right-color: #F8BBD0 !important; }

.pink-100-border-bottom {
  border-bottom-color: #F8BBD0 !important; }

.pink-100-border-left {
  border-left-color: #F8BBD0 !important; }

.pink-200-bg {
  background-color: #F48FB1 !important; }

.md-pink-200-bg {
  background-color: #F48FB1 !important;
  color: rgba(0, 0, 0, 0.87); }

.pink-200-fg {
  color: #F48FB1 !important; }

.pink-200-border {
  border-color: #F48FB1 !important; }

.pink-200-border-top {
  border-top-color: #F48FB1 !important; }

.pink-200-border-right {
  border-right-color: #F48FB1 !important; }

.pink-200-border-bottom {
  border-bottom-color: #F48FB1 !important; }

.pink-200-border-left {
  border-left-color: #F48FB1 !important; }

.pink-300-bg {
  background-color: #F06292 !important; }

.md-pink-300-bg {
  background-color: #F06292 !important;
  color: rgba(255, 255, 255, 0.87); }

.pink-300-fg {
  color: #F06292 !important; }

.pink-300-border {
  border-color: #F06292 !important; }

.pink-300-border-top {
  border-top-color: #F06292 !important; }

.pink-300-border-right {
  border-right-color: #F06292 !important; }

.pink-300-border-bottom {
  border-bottom-color: #F06292 !important; }

.pink-300-border-left {
  border-left-color: #F06292 !important; }

.pink-400-bg {
  background-color: #EC407A !important; }

.md-pink-400-bg {
  background-color: #EC407A !important;
  color: rgba(255, 255, 255, 0.87); }

.pink-400-fg {
  color: #EC407A !important; }

.pink-400-border {
  border-color: #EC407A !important; }

.pink-400-border-top {
  border-top-color: #EC407A !important; }

.pink-400-border-right {
  border-right-color: #EC407A !important; }

.pink-400-border-bottom {
  border-bottom-color: #EC407A !important; }

.pink-400-border-left {
  border-left-color: #EC407A !important; }

.pink-500-bg {
  background-color: #E91E63 !important; }

.md-pink-500-bg {
  background-color: #E91E63 !important;
  color: white; }

.pink-500-fg {
  color: #E91E63 !important; }

.pink-500-border {
  border-color: #E91E63 !important; }

.pink-500-border-top {
  border-top-color: #E91E63 !important; }

.pink-500-border-right {
  border-right-color: #E91E63 !important; }

.pink-500-border-bottom {
  border-bottom-color: #E91E63 !important; }

.pink-500-border-left {
  border-left-color: #E91E63 !important; }

.md-pink-bg {
  background-color: #E91E63 !important;
  color: white; }

.pink-bg {
  background-color: #E91E63 !important; }

.pink-fg {
  color: #E91E63 !important; }

.pink-border {
  border-color: #E91E63 !important; }

.pink-border-top {
  border-top-color: #E91E63 !important; }

.pink-border-right {
  border-right-color: #E91E63 !important; }

.pink-border-bottom {
  border-bottom-color: #E91E63 !important; }

.pink-border-left {
  border-left-color: #E91E63 !important; }

.pink-600-bg {
  background-color: #D81B60 !important; }

.md-pink-600-bg {
  background-color: #D81B60 !important;
  color: white; }

.pink-600-fg {
  color: #D81B60 !important; }

.pink-600-border {
  border-color: #D81B60 !important; }

.pink-600-border-top {
  border-top-color: #D81B60 !important; }

.pink-600-border-right {
  border-right-color: #D81B60 !important; }

.pink-600-border-bottom {
  border-bottom-color: #D81B60 !important; }

.pink-600-border-left {
  border-left-color: #D81B60 !important; }

.pink-700-bg {
  background-color: #C2185B !important; }

.md-pink-700-bg {
  background-color: #C2185B !important;
  color: rgba(255, 255, 255, 0.87); }

.pink-700-fg {
  color: #C2185B !important; }

.pink-700-border {
  border-color: #C2185B !important; }

.pink-700-border-top {
  border-top-color: #C2185B !important; }

.pink-700-border-right {
  border-right-color: #C2185B !important; }

.pink-700-border-bottom {
  border-bottom-color: #C2185B !important; }

.pink-700-border-left {
  border-left-color: #C2185B !important; }

.pink-800-bg {
  background-color: #AD1457 !important; }

.md-pink-800-bg {
  background-color: #AD1457 !important;
  color: rgba(255, 255, 255, 0.87); }

.pink-800-fg {
  color: #AD1457 !important; }

.pink-800-border {
  border-color: #AD1457 !important; }

.pink-800-border-top {
  border-top-color: #AD1457 !important; }

.pink-800-border-right {
  border-right-color: #AD1457 !important; }

.pink-800-border-bottom {
  border-bottom-color: #AD1457 !important; }

.pink-800-border-left {
  border-left-color: #AD1457 !important; }

.pink-900-bg {
  background-color: #880E4F !important; }

.md-pink-900-bg {
  background-color: #880E4F !important;
  color: rgba(255, 255, 255, 0.87); }

.pink-900-fg {
  color: #880E4F !important; }

.pink-900-border {
  border-color: #880E4F !important; }

.pink-900-border-top {
  border-top-color: #880E4F !important; }

.pink-900-border-right {
  border-right-color: #880E4F !important; }

.pink-900-border-bottom {
  border-bottom-color: #880E4F !important; }

.pink-900-border-left {
  border-left-color: #880E4F !important; }

.pink-A100-bg {
  background-color: #FF80AB !important; }

.md-pink-A100-bg {
  background-color: #FF80AB !important;
  color: rgba(0, 0, 0, 0.87); }

.pink-A100-fg {
  color: #FF80AB !important; }

.pink-A100-border {
  border-color: #FF80AB !important; }

.pink-A100-border-top {
  border-top-color: #FF80AB !important; }

.pink-A100-border-right {
  border-right-color: #FF80AB !important; }

.pink-A100-border-bottom {
  border-bottom-color: #FF80AB !important; }

.pink-A100-border-left {
  border-left-color: #FF80AB !important; }

.pink-A200-bg {
  background-color: #FF4081 !important; }

.md-pink-A200-bg {
  background-color: #FF4081 !important;
  color: white; }

.pink-A200-fg {
  color: #FF4081 !important; }

.pink-A200-border {
  border-color: #FF4081 !important; }

.pink-A200-border-top {
  border-top-color: #FF4081 !important; }

.pink-A200-border-right {
  border-right-color: #FF4081 !important; }

.pink-A200-border-bottom {
  border-bottom-color: #FF4081 !important; }

.pink-A200-border-left {
  border-left-color: #FF4081 !important; }

.pink-A400-bg {
  background-color: #F50057 !important; }

.md-pink-A400-bg {
  background-color: #F50057 !important;
  color: white; }

.pink-A400-fg {
  color: #F50057 !important; }

.pink-A400-border {
  border-color: #F50057 !important; }

.pink-A400-border-top {
  border-top-color: #F50057 !important; }

.pink-A400-border-right {
  border-right-color: #F50057 !important; }

.pink-A400-border-bottom {
  border-bottom-color: #F50057 !important; }

.pink-A400-border-left {
  border-left-color: #F50057 !important; }

.pink-A700-bg {
  background-color: #C51162 !important; }

.md-pink-A700-bg {
  background-color: #C51162 !important;
  color: white; }

.pink-A700-fg {
  color: #C51162 !important; }

.pink-A700-border {
  border-color: #C51162 !important; }

.pink-A700-border-top {
  border-top-color: #C51162 !important; }

.pink-A700-border-right {
  border-right-color: #C51162 !important; }

.pink-A700-border-bottom {
  border-bottom-color: #C51162 !important; }

.pink-A700-border-left {
  border-left-color: #C51162 !important; }

.purple-50-bg {
  background-color: #F3E5F5 !important; }

.md-purple-50-bg {
  background-color: #F3E5F5 !important;
  color: rgba(0, 0, 0, 0.87); }

.purple-50-fg {
  color: #F3E5F5 !important; }

.purple-50-border {
  border-color: #F3E5F5 !important; }

.purple-50-border-top {
  border-top-color: #F3E5F5 !important; }

.purple-50-border-right {
  border-right-color: #F3E5F5 !important; }

.purple-50-border-bottom {
  border-bottom-color: #F3E5F5 !important; }

.purple-50-border-left {
  border-left-color: #F3E5F5 !important; }

.purple-100-bg {
  background-color: #E1BEE7 !important; }

.md-purple-100-bg {
  background-color: #E1BEE7 !important;
  color: rgba(0, 0, 0, 0.87); }

.purple-100-fg {
  color: #E1BEE7 !important; }

.purple-100-border {
  border-color: #E1BEE7 !important; }

.purple-100-border-top {
  border-top-color: #E1BEE7 !important; }

.purple-100-border-right {
  border-right-color: #E1BEE7 !important; }

.purple-100-border-bottom {
  border-bottom-color: #E1BEE7 !important; }

.purple-100-border-left {
  border-left-color: #E1BEE7 !important; }

.purple-200-bg {
  background-color: #CE93D8 !important; }

.md-purple-200-bg {
  background-color: #CE93D8 !important;
  color: rgba(0, 0, 0, 0.87); }

.purple-200-fg {
  color: #CE93D8 !important; }

.purple-200-border {
  border-color: #CE93D8 !important; }

.purple-200-border-top {
  border-top-color: #CE93D8 !important; }

.purple-200-border-right {
  border-right-color: #CE93D8 !important; }

.purple-200-border-bottom {
  border-bottom-color: #CE93D8 !important; }

.purple-200-border-left {
  border-left-color: #CE93D8 !important; }

.purple-300-bg {
  background-color: #BA68C8 !important; }

.md-purple-300-bg {
  background-color: #BA68C8 !important;
  color: white; }

.purple-300-fg {
  color: #BA68C8 !important; }

.purple-300-border {
  border-color: #BA68C8 !important; }

.purple-300-border-top {
  border-top-color: #BA68C8 !important; }

.purple-300-border-right {
  border-right-color: #BA68C8 !important; }

.purple-300-border-bottom {
  border-bottom-color: #BA68C8 !important; }

.purple-300-border-left {
  border-left-color: #BA68C8 !important; }

.purple-400-bg {
  background-color: #AB47BC !important; }

.md-purple-400-bg {
  background-color: #AB47BC !important;
  color: white; }

.purple-400-fg {
  color: #AB47BC !important; }

.purple-400-border {
  border-color: #AB47BC !important; }

.purple-400-border-top {
  border-top-color: #AB47BC !important; }

.purple-400-border-right {
  border-right-color: #AB47BC !important; }

.purple-400-border-bottom {
  border-bottom-color: #AB47BC !important; }

.purple-400-border-left {
  border-left-color: #AB47BC !important; }

.purple-500-bg {
  background-color: #9C27B0 !important; }

.md-purple-500-bg {
  background-color: #9C27B0 !important;
  color: rgba(255, 255, 255, 0.87); }

.purple-500-fg {
  color: #9C27B0 !important; }

.purple-500-border {
  border-color: #9C27B0 !important; }

.purple-500-border-top {
  border-top-color: #9C27B0 !important; }

.purple-500-border-right {
  border-right-color: #9C27B0 !important; }

.purple-500-border-bottom {
  border-bottom-color: #9C27B0 !important; }

.purple-500-border-left {
  border-left-color: #9C27B0 !important; }

.md-purple-bg {
  background-color: #9C27B0 !important;
  color: rgba(255, 255, 255, 0.87); }

.purple-bg {
  background-color: #9C27B0 !important; }

.purple-fg {
  color: #9C27B0 !important; }

.purple-border {
  border-color: #9C27B0 !important; }

.purple-border-top {
  border-top-color: #9C27B0 !important; }

.purple-border-right {
  border-right-color: #9C27B0 !important; }

.purple-border-bottom {
  border-bottom-color: #9C27B0 !important; }

.purple-border-left {
  border-left-color: #9C27B0 !important; }

.purple-600-bg {
  background-color: #8E24AA !important; }

.md-purple-600-bg {
  background-color: #8E24AA !important;
  color: rgba(255, 255, 255, 0.87); }

.purple-600-fg {
  color: #8E24AA !important; }

.purple-600-border {
  border-color: #8E24AA !important; }

.purple-600-border-top {
  border-top-color: #8E24AA !important; }

.purple-600-border-right {
  border-right-color: #8E24AA !important; }

.purple-600-border-bottom {
  border-bottom-color: #8E24AA !important; }

.purple-600-border-left {
  border-left-color: #8E24AA !important; }

.purple-700-bg {
  background-color: #7B1FA2 !important; }

.md-purple-700-bg {
  background-color: #7B1FA2 !important;
  color: rgba(255, 255, 255, 0.87); }

.purple-700-fg {
  color: #7B1FA2 !important; }

.purple-700-border {
  border-color: #7B1FA2 !important; }

.purple-700-border-top {
  border-top-color: #7B1FA2 !important; }

.purple-700-border-right {
  border-right-color: #7B1FA2 !important; }

.purple-700-border-bottom {
  border-bottom-color: #7B1FA2 !important; }

.purple-700-border-left {
  border-left-color: #7B1FA2 !important; }

.purple-800-bg {
  background-color: #6A1B9A !important; }

.md-purple-800-bg {
  background-color: #6A1B9A !important;
  color: rgba(255, 255, 255, 0.87); }

.purple-800-fg {
  color: #6A1B9A !important; }

.purple-800-border {
  border-color: #6A1B9A !important; }

.purple-800-border-top {
  border-top-color: #6A1B9A !important; }

.purple-800-border-right {
  border-right-color: #6A1B9A !important; }

.purple-800-border-bottom {
  border-bottom-color: #6A1B9A !important; }

.purple-800-border-left {
  border-left-color: #6A1B9A !important; }

.purple-900-bg {
  background-color: #4A148C !important; }

.md-purple-900-bg {
  background-color: #4A148C !important;
  color: rgba(255, 255, 255, 0.87); }

.purple-900-fg {
  color: #4A148C !important; }

.purple-900-border {
  border-color: #4A148C !important; }

.purple-900-border-top {
  border-top-color: #4A148C !important; }

.purple-900-border-right {
  border-right-color: #4A148C !important; }

.purple-900-border-bottom {
  border-bottom-color: #4A148C !important; }

.purple-900-border-left {
  border-left-color: #4A148C !important; }

.purple-A100-bg {
  background-color: #EA80FC !important; }

.md-purple-A100-bg {
  background-color: #EA80FC !important;
  color: rgba(0, 0, 0, 0.87); }

.purple-A100-fg {
  color: #EA80FC !important; }

.purple-A100-border {
  border-color: #EA80FC !important; }

.purple-A100-border-top {
  border-top-color: #EA80FC !important; }

.purple-A100-border-right {
  border-right-color: #EA80FC !important; }

.purple-A100-border-bottom {
  border-bottom-color: #EA80FC !important; }

.purple-A100-border-left {
  border-left-color: #EA80FC !important; }

.purple-A200-bg {
  background-color: #E040FB !important; }

.md-purple-A200-bg {
  background-color: #E040FB !important;
  color: white; }

.purple-A200-fg {
  color: #E040FB !important; }

.purple-A200-border {
  border-color: #E040FB !important; }

.purple-A200-border-top {
  border-top-color: #E040FB !important; }

.purple-A200-border-right {
  border-right-color: #E040FB !important; }

.purple-A200-border-bottom {
  border-bottom-color: #E040FB !important; }

.purple-A200-border-left {
  border-left-color: #E040FB !important; }

.purple-A400-bg {
  background-color: #D500F9 !important; }

.md-purple-A400-bg {
  background-color: #D500F9 !important;
  color: white; }

.purple-A400-fg {
  color: #D500F9 !important; }

.purple-A400-border {
  border-color: #D500F9 !important; }

.purple-A400-border-top {
  border-top-color: #D500F9 !important; }

.purple-A400-border-right {
  border-right-color: #D500F9 !important; }

.purple-A400-border-bottom {
  border-bottom-color: #D500F9 !important; }

.purple-A400-border-left {
  border-left-color: #D500F9 !important; }

.purple-A700-bg {
  background-color: #AA00FF !important; }

.md-purple-A700-bg {
  background-color: #AA00FF !important;
  color: white; }

.purple-A700-fg {
  color: #AA00FF !important; }

.purple-A700-border {
  border-color: #AA00FF !important; }

.purple-A700-border-top {
  border-top-color: #AA00FF !important; }

.purple-A700-border-right {
  border-right-color: #AA00FF !important; }

.purple-A700-border-bottom {
  border-bottom-color: #AA00FF !important; }

.purple-A700-border-left {
  border-left-color: #AA00FF !important; }

.deep-purple-50-bg {
  background-color: #EDE7F6 !important; }

.md-deep-purple-50-bg {
  background-color: #EDE7F6 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-purple-50-fg {
  color: #EDE7F6 !important; }

.deep-purple-50-border {
  border-color: #EDE7F6 !important; }

.deep-purple-50-border-top {
  border-top-color: #EDE7F6 !important; }

.deep-purple-50-border-right {
  border-right-color: #EDE7F6 !important; }

.deep-purple-50-border-bottom {
  border-bottom-color: #EDE7F6 !important; }

.deep-purple-50-border-left {
  border-left-color: #EDE7F6 !important; }

.deep-purple-100-bg {
  background-color: #D1C4E9 !important; }

.md-deep-purple-100-bg {
  background-color: #D1C4E9 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-purple-100-fg {
  color: #D1C4E9 !important; }

.deep-purple-100-border {
  border-color: #D1C4E9 !important; }

.deep-purple-100-border-top {
  border-top-color: #D1C4E9 !important; }

.deep-purple-100-border-right {
  border-right-color: #D1C4E9 !important; }

.deep-purple-100-border-bottom {
  border-bottom-color: #D1C4E9 !important; }

.deep-purple-100-border-left {
  border-left-color: #D1C4E9 !important; }

.deep-purple-200-bg {
  background-color: #B39DDB !important; }

.md-deep-purple-200-bg {
  background-color: #B39DDB !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-purple-200-fg {
  color: #B39DDB !important; }

.deep-purple-200-border {
  border-color: #B39DDB !important; }

.deep-purple-200-border-top {
  border-top-color: #B39DDB !important; }

.deep-purple-200-border-right {
  border-right-color: #B39DDB !important; }

.deep-purple-200-border-bottom {
  border-bottom-color: #B39DDB !important; }

.deep-purple-200-border-left {
  border-left-color: #B39DDB !important; }

.deep-purple-300-bg {
  background-color: #9575CD !important; }

.md-deep-purple-300-bg {
  background-color: #9575CD !important;
  color: white; }

.deep-purple-300-fg {
  color: #9575CD !important; }

.deep-purple-300-border {
  border-color: #9575CD !important; }

.deep-purple-300-border-top {
  border-top-color: #9575CD !important; }

.deep-purple-300-border-right {
  border-right-color: #9575CD !important; }

.deep-purple-300-border-bottom {
  border-bottom-color: #9575CD !important; }

.deep-purple-300-border-left {
  border-left-color: #9575CD !important; }

.deep-purple-400-bg {
  background-color: #7E57C2 !important; }

.md-deep-purple-400-bg {
  background-color: #7E57C2 !important;
  color: white; }

.deep-purple-400-fg {
  color: #7E57C2 !important; }

.deep-purple-400-border {
  border-color: #7E57C2 !important; }

.deep-purple-400-border-top {
  border-top-color: #7E57C2 !important; }

.deep-purple-400-border-right {
  border-right-color: #7E57C2 !important; }

.deep-purple-400-border-bottom {
  border-bottom-color: #7E57C2 !important; }

.deep-purple-400-border-left {
  border-left-color: #7E57C2 !important; }

.deep-purple-500-bg {
  background-color: #673AB7 !important; }

.md-deep-purple-500-bg {
  background-color: #673AB7 !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-500-fg {
  color: #673AB7 !important; }

.deep-purple-500-border {
  border-color: #673AB7 !important; }

.deep-purple-500-border-top {
  border-top-color: #673AB7 !important; }

.deep-purple-500-border-right {
  border-right-color: #673AB7 !important; }

.deep-purple-500-border-bottom {
  border-bottom-color: #673AB7 !important; }

.deep-purple-500-border-left {
  border-left-color: #673AB7 !important; }

.md-deep-purple-bg {
  background-color: #673AB7 !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-bg {
  background-color: #673AB7 !important; }

.deep-purple-fg {
  color: #673AB7 !important; }

.deep-purple-border {
  border-color: #673AB7 !important; }

.deep-purple-border-top {
  border-top-color: #673AB7 !important; }

.deep-purple-border-right {
  border-right-color: #673AB7 !important; }

.deep-purple-border-bottom {
  border-bottom-color: #673AB7 !important; }

.deep-purple-border-left {
  border-left-color: #673AB7 !important; }

.deep-purple-600-bg {
  background-color: #5E35B1 !important; }

.md-deep-purple-600-bg {
  background-color: #5E35B1 !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-600-fg {
  color: #5E35B1 !important; }

.deep-purple-600-border {
  border-color: #5E35B1 !important; }

.deep-purple-600-border-top {
  border-top-color: #5E35B1 !important; }

.deep-purple-600-border-right {
  border-right-color: #5E35B1 !important; }

.deep-purple-600-border-bottom {
  border-bottom-color: #5E35B1 !important; }

.deep-purple-600-border-left {
  border-left-color: #5E35B1 !important; }

.deep-purple-700-bg {
  background-color: #512DA8 !important; }

.md-deep-purple-700-bg {
  background-color: #512DA8 !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-700-fg {
  color: #512DA8 !important; }

.deep-purple-700-border {
  border-color: #512DA8 !important; }

.deep-purple-700-border-top {
  border-top-color: #512DA8 !important; }

.deep-purple-700-border-right {
  border-right-color: #512DA8 !important; }

.deep-purple-700-border-bottom {
  border-bottom-color: #512DA8 !important; }

.deep-purple-700-border-left {
  border-left-color: #512DA8 !important; }

.deep-purple-800-bg {
  background-color: #4527A0 !important; }

.md-deep-purple-800-bg {
  background-color: #4527A0 !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-800-fg {
  color: #4527A0 !important; }

.deep-purple-800-border {
  border-color: #4527A0 !important; }

.deep-purple-800-border-top {
  border-top-color: #4527A0 !important; }

.deep-purple-800-border-right {
  border-right-color: #4527A0 !important; }

.deep-purple-800-border-bottom {
  border-bottom-color: #4527A0 !important; }

.deep-purple-800-border-left {
  border-left-color: #4527A0 !important; }

.deep-purple-900-bg {
  background-color: #311B92 !important; }

.md-deep-purple-900-bg {
  background-color: #311B92 !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-900-fg {
  color: #311B92 !important; }

.deep-purple-900-border {
  border-color: #311B92 !important; }

.deep-purple-900-border-top {
  border-top-color: #311B92 !important; }

.deep-purple-900-border-right {
  border-right-color: #311B92 !important; }

.deep-purple-900-border-bottom {
  border-bottom-color: #311B92 !important; }

.deep-purple-900-border-left {
  border-left-color: #311B92 !important; }

.deep-purple-A100-bg {
  background-color: #B388FF !important; }

.md-deep-purple-A100-bg {
  background-color: #B388FF !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-purple-A100-fg {
  color: #B388FF !important; }

.deep-purple-A100-border {
  border-color: #B388FF !important; }

.deep-purple-A100-border-top {
  border-top-color: #B388FF !important; }

.deep-purple-A100-border-right {
  border-right-color: #B388FF !important; }

.deep-purple-A100-border-bottom {
  border-bottom-color: #B388FF !important; }

.deep-purple-A100-border-left {
  border-left-color: #B388FF !important; }

.deep-purple-A200-bg {
  background-color: #7C4DFF !important; }

.md-deep-purple-A200-bg {
  background-color: #7C4DFF !important;
  color: white; }

.deep-purple-A200-fg {
  color: #7C4DFF !important; }

.deep-purple-A200-border {
  border-color: #7C4DFF !important; }

.deep-purple-A200-border-top {
  border-top-color: #7C4DFF !important; }

.deep-purple-A200-border-right {
  border-right-color: #7C4DFF !important; }

.deep-purple-A200-border-bottom {
  border-bottom-color: #7C4DFF !important; }

.deep-purple-A200-border-left {
  border-left-color: #7C4DFF !important; }

.deep-purple-A400-bg {
  background-color: #651FFF !important; }

.md-deep-purple-A400-bg {
  background-color: #651FFF !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-A400-fg {
  color: #651FFF !important; }

.deep-purple-A400-border {
  border-color: #651FFF !important; }

.deep-purple-A400-border-top {
  border-top-color: #651FFF !important; }

.deep-purple-A400-border-right {
  border-right-color: #651FFF !important; }

.deep-purple-A400-border-bottom {
  border-bottom-color: #651FFF !important; }

.deep-purple-A400-border-left {
  border-left-color: #651FFF !important; }

.deep-purple-A700-bg {
  background-color: #6200EA !important; }

.md-deep-purple-A700-bg {
  background-color: #6200EA !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-A700-fg {
  color: #6200EA !important; }

.deep-purple-A700-border {
  border-color: #6200EA !important; }

.deep-purple-A700-border-top {
  border-top-color: #6200EA !important; }

.deep-purple-A700-border-right {
  border-right-color: #6200EA !important; }

.deep-purple-A700-border-bottom {
  border-bottom-color: #6200EA !important; }

.deep-purple-A700-border-left {
  border-left-color: #6200EA !important; }

.indigo-50-bg {
  background-color: #E8EAF6 !important; }

.md-indigo-50-bg {
  background-color: #E8EAF6 !important;
  color: rgba(0, 0, 0, 0.87); }

.indigo-50-fg {
  color: #E8EAF6 !important; }

.indigo-50-border {
  border-color: #E8EAF6 !important; }

.indigo-50-border-top {
  border-top-color: #E8EAF6 !important; }

.indigo-50-border-right {
  border-right-color: #E8EAF6 !important; }

.indigo-50-border-bottom {
  border-bottom-color: #E8EAF6 !important; }

.indigo-50-border-left {
  border-left-color: #E8EAF6 !important; }

.indigo-100-bg {
  background-color: #C5CAE9 !important; }

.md-indigo-100-bg {
  background-color: #C5CAE9 !important;
  color: rgba(0, 0, 0, 0.87); }

.indigo-100-fg {
  color: #C5CAE9 !important; }

.indigo-100-border {
  border-color: #C5CAE9 !important; }

.indigo-100-border-top {
  border-top-color: #C5CAE9 !important; }

.indigo-100-border-right {
  border-right-color: #C5CAE9 !important; }

.indigo-100-border-bottom {
  border-bottom-color: #C5CAE9 !important; }

.indigo-100-border-left {
  border-left-color: #C5CAE9 !important; }

.indigo-200-bg {
  background-color: #9FA8DA !important; }

.md-indigo-200-bg {
  background-color: #9FA8DA !important;
  color: rgba(0, 0, 0, 0.87); }

.indigo-200-fg {
  color: #9FA8DA !important; }

.indigo-200-border {
  border-color: #9FA8DA !important; }

.indigo-200-border-top {
  border-top-color: #9FA8DA !important; }

.indigo-200-border-right {
  border-right-color: #9FA8DA !important; }

.indigo-200-border-bottom {
  border-bottom-color: #9FA8DA !important; }

.indigo-200-border-left {
  border-left-color: #9FA8DA !important; }

.indigo-300-bg {
  background-color: #7986CB !important; }

.md-indigo-300-bg {
  background-color: #7986CB !important;
  color: white; }

.indigo-300-fg {
  color: #7986CB !important; }

.indigo-300-border {
  border-color: #7986CB !important; }

.indigo-300-border-top {
  border-top-color: #7986CB !important; }

.indigo-300-border-right {
  border-right-color: #7986CB !important; }

.indigo-300-border-bottom {
  border-bottom-color: #7986CB !important; }

.indigo-300-border-left {
  border-left-color: #7986CB !important; }

.indigo-400-bg {
  background-color: #5C6BC0 !important; }

.md-indigo-400-bg {
  background-color: #5C6BC0 !important;
  color: white; }

.indigo-400-fg {
  color: #5C6BC0 !important; }

.indigo-400-border {
  border-color: #5C6BC0 !important; }

.indigo-400-border-top {
  border-top-color: #5C6BC0 !important; }

.indigo-400-border-right {
  border-right-color: #5C6BC0 !important; }

.indigo-400-border-bottom {
  border-bottom-color: #5C6BC0 !important; }

.indigo-400-border-left {
  border-left-color: #5C6BC0 !important; }

.indigo-500-bg {
  background-color: #3F51B5 !important; }

.md-indigo-500-bg {
  background-color: #3F51B5 !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-500-fg {
  color: #3F51B5 !important; }

.indigo-500-border {
  border-color: #3F51B5 !important; }

.indigo-500-border-top {
  border-top-color: #3F51B5 !important; }

.indigo-500-border-right {
  border-right-color: #3F51B5 !important; }

.indigo-500-border-bottom {
  border-bottom-color: #3F51B5 !important; }

.indigo-500-border-left {
  border-left-color: #3F51B5 !important; }

.md-indigo-bg {
  background-color: #3F51B5 !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-bg {
  background-color: #3F51B5 !important; }

.indigo-fg {
  color: #3F51B5 !important; }

.indigo-border {
  border-color: #3F51B5 !important; }

.indigo-border-top {
  border-top-color: #3F51B5 !important; }

.indigo-border-right {
  border-right-color: #3F51B5 !important; }

.indigo-border-bottom {
  border-bottom-color: #3F51B5 !important; }

.indigo-border-left {
  border-left-color: #3F51B5 !important; }

.indigo-600-bg {
  background-color: #3949AB !important; }

.md-indigo-600-bg {
  background-color: #3949AB !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-600-fg {
  color: #3949AB !important; }

.indigo-600-border {
  border-color: #3949AB !important; }

.indigo-600-border-top {
  border-top-color: #3949AB !important; }

.indigo-600-border-right {
  border-right-color: #3949AB !important; }

.indigo-600-border-bottom {
  border-bottom-color: #3949AB !important; }

.indigo-600-border-left {
  border-left-color: #3949AB !important; }

.indigo-700-bg {
  background-color: #303F9F !important; }

.md-indigo-700-bg {
  background-color: #303F9F !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-700-fg {
  color: #303F9F !important; }

.indigo-700-border {
  border-color: #303F9F !important; }

.indigo-700-border-top {
  border-top-color: #303F9F !important; }

.indigo-700-border-right {
  border-right-color: #303F9F !important; }

.indigo-700-border-bottom {
  border-bottom-color: #303F9F !important; }

.indigo-700-border-left {
  border-left-color: #303F9F !important; }

.indigo-800-bg {
  background-color: #283593 !important; }

.md-indigo-800-bg {
  background-color: #283593 !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-800-fg {
  color: #283593 !important; }

.indigo-800-border {
  border-color: #283593 !important; }

.indigo-800-border-top {
  border-top-color: #283593 !important; }

.indigo-800-border-right {
  border-right-color: #283593 !important; }

.indigo-800-border-bottom {
  border-bottom-color: #283593 !important; }

.indigo-800-border-left {
  border-left-color: #283593 !important; }

.indigo-900-bg {
  background-color: #1A237E !important; }

.md-indigo-900-bg {
  background-color: #1A237E !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-900-fg {
  color: #1A237E !important; }

.indigo-900-border {
  border-color: #1A237E !important; }

.indigo-900-border-top {
  border-top-color: #1A237E !important; }

.indigo-900-border-right {
  border-right-color: #1A237E !important; }

.indigo-900-border-bottom {
  border-bottom-color: #1A237E !important; }

.indigo-900-border-left {
  border-left-color: #1A237E !important; }

.indigo-A100-bg {
  background-color: #8C9EFF !important; }

.md-indigo-A100-bg {
  background-color: #8C9EFF !important;
  color: rgba(0, 0, 0, 0.87); }

.indigo-A100-fg {
  color: #8C9EFF !important; }

.indigo-A100-border {
  border-color: #8C9EFF !important; }

.indigo-A100-border-top {
  border-top-color: #8C9EFF !important; }

.indigo-A100-border-right {
  border-right-color: #8C9EFF !important; }

.indigo-A100-border-bottom {
  border-bottom-color: #8C9EFF !important; }

.indigo-A100-border-left {
  border-left-color: #8C9EFF !important; }

.indigo-A200-bg {
  background-color: #536DFE !important; }

.md-indigo-A200-bg {
  background-color: #536DFE !important;
  color: white; }

.indigo-A200-fg {
  color: #536DFE !important; }

.indigo-A200-border {
  border-color: #536DFE !important; }

.indigo-A200-border-top {
  border-top-color: #536DFE !important; }

.indigo-A200-border-right {
  border-right-color: #536DFE !important; }

.indigo-A200-border-bottom {
  border-bottom-color: #536DFE !important; }

.indigo-A200-border-left {
  border-left-color: #536DFE !important; }

.indigo-A400-bg {
  background-color: #3D5AFE !important; }

.md-indigo-A400-bg {
  background-color: #3D5AFE !important;
  color: white; }

.indigo-A400-fg {
  color: #3D5AFE !important; }

.indigo-A400-border {
  border-color: #3D5AFE !important; }

.indigo-A400-border-top {
  border-top-color: #3D5AFE !important; }

.indigo-A400-border-right {
  border-right-color: #3D5AFE !important; }

.indigo-A400-border-bottom {
  border-bottom-color: #3D5AFE !important; }

.indigo-A400-border-left {
  border-left-color: #3D5AFE !important; }

.indigo-A700-bg {
  background-color: #304FFE !important; }

.md-indigo-A700-bg {
  background-color: #304FFE !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-A700-fg {
  color: #304FFE !important; }

.indigo-A700-border {
  border-color: #304FFE !important; }

.indigo-A700-border-top {
  border-top-color: #304FFE !important; }

.indigo-A700-border-right {
  border-right-color: #304FFE !important; }

.indigo-A700-border-bottom {
  border-bottom-color: #304FFE !important; }

.indigo-A700-border-left {
  border-left-color: #304FFE !important; }

.blue-50-bg {
  background-color: #E3F2FD !important; }

.md-blue-50-bg {
  background-color: #E3F2FD !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-50-fg {
  color: #E3F2FD !important; }

.blue-50-border {
  border-color: #E3F2FD !important; }

.blue-50-border-top {
  border-top-color: #E3F2FD !important; }

.blue-50-border-right {
  border-right-color: #E3F2FD !important; }

.blue-50-border-bottom {
  border-bottom-color: #E3F2FD !important; }

.blue-50-border-left {
  border-left-color: #E3F2FD !important; }

.blue-100-bg {
  background-color: #BBDEFB !important; }

.md-blue-100-bg {
  background-color: #BBDEFB !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-100-fg {
  color: #BBDEFB !important; }

.blue-100-border {
  border-color: #BBDEFB !important; }

.blue-100-border-top {
  border-top-color: #BBDEFB !important; }

.blue-100-border-right {
  border-right-color: #BBDEFB !important; }

.blue-100-border-bottom {
  border-bottom-color: #BBDEFB !important; }

.blue-100-border-left {
  border-left-color: #BBDEFB !important; }

.blue-200-bg {
  background-color: #90CAF9 !important; }

.md-blue-200-bg {
  background-color: #90CAF9 !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-200-fg {
  color: #90CAF9 !important; }

.blue-200-border {
  border-color: #90CAF9 !important; }

.blue-200-border-top {
  border-top-color: #90CAF9 !important; }

.blue-200-border-right {
  border-right-color: #90CAF9 !important; }

.blue-200-border-bottom {
  border-bottom-color: #90CAF9 !important; }

.blue-200-border-left {
  border-left-color: #90CAF9 !important; }

.blue-300-bg {
  background-color: #64B5F6 !important; }

.md-blue-300-bg {
  background-color: #64B5F6 !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-300-fg {
  color: #64B5F6 !important; }

.blue-300-border {
  border-color: #64B5F6 !important; }

.blue-300-border-top {
  border-top-color: #64B5F6 !important; }

.blue-300-border-right {
  border-right-color: #64B5F6 !important; }

.blue-300-border-bottom {
  border-bottom-color: #64B5F6 !important; }

.blue-300-border-left {
  border-left-color: #64B5F6 !important; }

.blue-400-bg {
  background-color: #42A5F5 !important; }

.md-blue-400-bg {
  background-color: #42A5F5 !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-400-fg {
  color: #42A5F5 !important; }

.blue-400-border {
  border-color: #42A5F5 !important; }

.blue-400-border-top {
  border-top-color: #42A5F5 !important; }

.blue-400-border-right {
  border-right-color: #42A5F5 !important; }

.blue-400-border-bottom {
  border-bottom-color: #42A5F5 !important; }

.blue-400-border-left {
  border-left-color: #42A5F5 !important; }

.blue-500-bg {
  background-color: #2196F3 !important; }

.md-blue-500-bg {
  background-color: #2196F3 !important;
  color: white; }

.blue-500-fg {
  color: #2196F3 !important; }

.blue-500-border {
  border-color: #2196F3 !important; }

.blue-500-border-top {
  border-top-color: #2196F3 !important; }

.blue-500-border-right {
  border-right-color: #2196F3 !important; }

.blue-500-border-bottom {
  border-bottom-color: #2196F3 !important; }

.blue-500-border-left {
  border-left-color: #2196F3 !important; }

.md-blue-bg {
  background-color: #2196F3 !important;
  color: white; }

.blue-bg {
  background-color: #2196F3 !important; }

.blue-fg {
  color: #2196F3 !important; }

.blue-border {
  border-color: #2196F3 !important; }

.blue-border-top {
  border-top-color: #2196F3 !important; }

.blue-border-right {
  border-right-color: #2196F3 !important; }

.blue-border-bottom {
  border-bottom-color: #2196F3 !important; }

.blue-border-left {
  border-left-color: #2196F3 !important; }

.blue-600-bg {
  background-color: #1E88E5 !important; }

.md-blue-600-bg {
  background-color: #1E88E5 !important;
  color: white; }

.blue-600-fg {
  color: #1E88E5 !important; }

.blue-600-border {
  border-color: #1E88E5 !important; }

.blue-600-border-top {
  border-top-color: #1E88E5 !important; }

.blue-600-border-right {
  border-right-color: #1E88E5 !important; }

.blue-600-border-bottom {
  border-bottom-color: #1E88E5 !important; }

.blue-600-border-left {
  border-left-color: #1E88E5 !important; }

.blue-700-bg {
  background-color: #1976D2 !important; }

.md-blue-700-bg {
  background-color: #1976D2 !important;
  color: white; }

.blue-700-fg {
  color: #1976D2 !important; }

.blue-700-border {
  border-color: #1976D2 !important; }

.blue-700-border-top {
  border-top-color: #1976D2 !important; }

.blue-700-border-right {
  border-right-color: #1976D2 !important; }

.blue-700-border-bottom {
  border-bottom-color: #1976D2 !important; }

.blue-700-border-left {
  border-left-color: #1976D2 !important; }

.blue-800-bg {
  background-color: #1565C0 !important; }

.md-blue-800-bg {
  background-color: #1565C0 !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-800-fg {
  color: #1565C0 !important; }

.blue-800-border {
  border-color: #1565C0 !important; }

.blue-800-border-top {
  border-top-color: #1565C0 !important; }

.blue-800-border-right {
  border-right-color: #1565C0 !important; }

.blue-800-border-bottom {
  border-bottom-color: #1565C0 !important; }

.blue-800-border-left {
  border-left-color: #1565C0 !important; }

.blue-900-bg {
  background-color: #0D47A1 !important; }

.md-blue-900-bg {
  background-color: #0D47A1 !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-900-fg {
  color: #0D47A1 !important; }

.blue-900-border {
  border-color: #0D47A1 !important; }

.blue-900-border-top {
  border-top-color: #0D47A1 !important; }

.blue-900-border-right {
  border-right-color: #0D47A1 !important; }

.blue-900-border-bottom {
  border-bottom-color: #0D47A1 !important; }

.blue-900-border-left {
  border-left-color: #0D47A1 !important; }

.blue-A100-bg {
  background-color: #82B1FF !important; }

.md-blue-A100-bg {
  background-color: #82B1FF !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-A100-fg {
  color: #82B1FF !important; }

.blue-A100-border {
  border-color: #82B1FF !important; }

.blue-A100-border-top {
  border-top-color: #82B1FF !important; }

.blue-A100-border-right {
  border-right-color: #82B1FF !important; }

.blue-A100-border-bottom {
  border-bottom-color: #82B1FF !important; }

.blue-A100-border-left {
  border-left-color: #82B1FF !important; }

.blue-A200-bg {
  background-color: #448AFF !important; }

.md-blue-A200-bg {
  background-color: #448AFF !important;
  color: white; }

.blue-A200-fg {
  color: #448AFF !important; }

.blue-A200-border {
  border-color: #448AFF !important; }

.blue-A200-border-top {
  border-top-color: #448AFF !important; }

.blue-A200-border-right {
  border-right-color: #448AFF !important; }

.blue-A200-border-bottom {
  border-bottom-color: #448AFF !important; }

.blue-A200-border-left {
  border-left-color: #448AFF !important; }

.blue-A400-bg {
  background-color: #2979FF !important; }

.md-blue-A400-bg {
  background-color: #2979FF !important;
  color: white; }

.blue-A400-fg {
  color: #2979FF !important; }

.blue-A400-border {
  border-color: #2979FF !important; }

.blue-A400-border-top {
  border-top-color: #2979FF !important; }

.blue-A400-border-right {
  border-right-color: #2979FF !important; }

.blue-A400-border-bottom {
  border-bottom-color: #2979FF !important; }

.blue-A400-border-left {
  border-left-color: #2979FF !important; }

.blue-A700-bg {
  background-color: #2962FF !important; }

.md-blue-A700-bg {
  background-color: #2962FF !important;
  color: white; }

.blue-A700-fg {
  color: #2962FF !important; }

.blue-A700-border {
  border-color: #2962FF !important; }

.blue-A700-border-top {
  border-top-color: #2962FF !important; }

.blue-A700-border-right {
  border-right-color: #2962FF !important; }

.blue-A700-border-bottom {
  border-bottom-color: #2962FF !important; }

.blue-A700-border-left {
  border-left-color: #2962FF !important; }

.light-blue-50-bg {
  background-color: #E1F5FE !important; }

.md-light-blue-50-bg {
  background-color: #E1F5FE !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-50-fg {
  color: #E1F5FE !important; }

.light-blue-50-border {
  border-color: #E1F5FE !important; }

.light-blue-50-border-top {
  border-top-color: #E1F5FE !important; }

.light-blue-50-border-right {
  border-right-color: #E1F5FE !important; }

.light-blue-50-border-bottom {
  border-bottom-color: #E1F5FE !important; }

.light-blue-50-border-left {
  border-left-color: #E1F5FE !important; }

.light-blue-100-bg {
  background-color: #B3E5FC !important; }

.md-light-blue-100-bg {
  background-color: #B3E5FC !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-100-fg {
  color: #B3E5FC !important; }

.light-blue-100-border {
  border-color: #B3E5FC !important; }

.light-blue-100-border-top {
  border-top-color: #B3E5FC !important; }

.light-blue-100-border-right {
  border-right-color: #B3E5FC !important; }

.light-blue-100-border-bottom {
  border-bottom-color: #B3E5FC !important; }

.light-blue-100-border-left {
  border-left-color: #B3E5FC !important; }

.light-blue-200-bg {
  background-color: #81D4FA !important; }

.md-light-blue-200-bg {
  background-color: #81D4FA !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-200-fg {
  color: #81D4FA !important; }

.light-blue-200-border {
  border-color: #81D4FA !important; }

.light-blue-200-border-top {
  border-top-color: #81D4FA !important; }

.light-blue-200-border-right {
  border-right-color: #81D4FA !important; }

.light-blue-200-border-bottom {
  border-bottom-color: #81D4FA !important; }

.light-blue-200-border-left {
  border-left-color: #81D4FA !important; }

.light-blue-300-bg {
  background-color: #4FC3F7 !important; }

.md-light-blue-300-bg {
  background-color: #4FC3F7 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-300-fg {
  color: #4FC3F7 !important; }

.light-blue-300-border {
  border-color: #4FC3F7 !important; }

.light-blue-300-border-top {
  border-top-color: #4FC3F7 !important; }

.light-blue-300-border-right {
  border-right-color: #4FC3F7 !important; }

.light-blue-300-border-bottom {
  border-bottom-color: #4FC3F7 !important; }

.light-blue-300-border-left {
  border-left-color: #4FC3F7 !important; }

.light-blue-400-bg {
  background-color: #29B6F6 !important; }

.md-light-blue-400-bg {
  background-color: #29B6F6 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-400-fg {
  color: #29B6F6 !important; }

.light-blue-400-border {
  border-color: #29B6F6 !important; }

.light-blue-400-border-top {
  border-top-color: #29B6F6 !important; }

.light-blue-400-border-right {
  border-right-color: #29B6F6 !important; }

.light-blue-400-border-bottom {
  border-bottom-color: #29B6F6 !important; }

.light-blue-400-border-left {
  border-left-color: #29B6F6 !important; }

.light-blue-500-bg {
  background-color: #03A9F4 !important; }

.md-light-blue-500-bg {
  background-color: #03A9F4 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-500-fg {
  color: #03A9F4 !important; }

.light-blue-500-border {
  border-color: #03A9F4 !important; }

.light-blue-500-border-top {
  border-top-color: #03A9F4 !important; }

.light-blue-500-border-right {
  border-right-color: #03A9F4 !important; }

.light-blue-500-border-bottom {
  border-bottom-color: #03A9F4 !important; }

.light-blue-500-border-left {
  border-left-color: #03A9F4 !important; }

.md-light-blue-bg {
  background-color: #03A9F4 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-bg {
  background-color: #03A9F4 !important; }

.light-blue-fg {
  color: #03A9F4 !important; }

.light-blue-border {
  border-color: #03A9F4 !important; }

.light-blue-border-top {
  border-top-color: #03A9F4 !important; }

.light-blue-border-right {
  border-right-color: #03A9F4 !important; }

.light-blue-border-bottom {
  border-bottom-color: #03A9F4 !important; }

.light-blue-border-left {
  border-left-color: #03A9F4 !important; }

.light-blue-600-bg {
  background-color: #039BE5 !important; }

.md-light-blue-600-bg {
  background-color: #039BE5 !important;
  color: white; }

.light-blue-600-fg {
  color: #039BE5 !important; }

.light-blue-600-border {
  border-color: #039BE5 !important; }

.light-blue-600-border-top {
  border-top-color: #039BE5 !important; }

.light-blue-600-border-right {
  border-right-color: #039BE5 !important; }

.light-blue-600-border-bottom {
  border-bottom-color: #039BE5 !important; }

.light-blue-600-border-left {
  border-left-color: #039BE5 !important; }

.light-blue-700-bg {
  background-color: #0288D1 !important; }

.md-light-blue-700-bg {
  background-color: #0288D1 !important;
  color: white; }

.light-blue-700-fg {
  color: #0288D1 !important; }

.light-blue-700-border {
  border-color: #0288D1 !important; }

.light-blue-700-border-top {
  border-top-color: #0288D1 !important; }

.light-blue-700-border-right {
  border-right-color: #0288D1 !important; }

.light-blue-700-border-bottom {
  border-bottom-color: #0288D1 !important; }

.light-blue-700-border-left {
  border-left-color: #0288D1 !important; }

.light-blue-800-bg {
  background-color: #0277BD !important; }

.md-light-blue-800-bg {
  background-color: #0277BD !important;
  color: white; }

.light-blue-800-fg {
  color: #0277BD !important; }

.light-blue-800-border {
  border-color: #0277BD !important; }

.light-blue-800-border-top {
  border-top-color: #0277BD !important; }

.light-blue-800-border-right {
  border-right-color: #0277BD !important; }

.light-blue-800-border-bottom {
  border-bottom-color: #0277BD !important; }

.light-blue-800-border-left {
  border-left-color: #0277BD !important; }

.light-blue-900-bg {
  background-color: #01579B !important; }

.md-light-blue-900-bg {
  background-color: #01579B !important;
  color: white; }

.light-blue-900-fg {
  color: #01579B !important; }

.light-blue-900-border {
  border-color: #01579B !important; }

.light-blue-900-border-top {
  border-top-color: #01579B !important; }

.light-blue-900-border-right {
  border-right-color: #01579B !important; }

.light-blue-900-border-bottom {
  border-bottom-color: #01579B !important; }

.light-blue-900-border-left {
  border-left-color: #01579B !important; }

.light-blue-A100-bg {
  background-color: #80D8FF !important; }

.md-light-blue-A100-bg {
  background-color: #80D8FF !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-A100-fg {
  color: #80D8FF !important; }

.light-blue-A100-border {
  border-color: #80D8FF !important; }

.light-blue-A100-border-top {
  border-top-color: #80D8FF !important; }

.light-blue-A100-border-right {
  border-right-color: #80D8FF !important; }

.light-blue-A100-border-bottom {
  border-bottom-color: #80D8FF !important; }

.light-blue-A100-border-left {
  border-left-color: #80D8FF !important; }

.light-blue-A200-bg {
  background-color: #40C4FF !important; }

.md-light-blue-A200-bg {
  background-color: #40C4FF !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-A200-fg {
  color: #40C4FF !important; }

.light-blue-A200-border {
  border-color: #40C4FF !important; }

.light-blue-A200-border-top {
  border-top-color: #40C4FF !important; }

.light-blue-A200-border-right {
  border-right-color: #40C4FF !important; }

.light-blue-A200-border-bottom {
  border-bottom-color: #40C4FF !important; }

.light-blue-A200-border-left {
  border-left-color: #40C4FF !important; }

.light-blue-A400-bg {
  background-color: #00B0FF !important; }

.md-light-blue-A400-bg {
  background-color: #00B0FF !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-A400-fg {
  color: #00B0FF !important; }

.light-blue-A400-border {
  border-color: #00B0FF !important; }

.light-blue-A400-border-top {
  border-top-color: #00B0FF !important; }

.light-blue-A400-border-right {
  border-right-color: #00B0FF !important; }

.light-blue-A400-border-bottom {
  border-bottom-color: #00B0FF !important; }

.light-blue-A400-border-left {
  border-left-color: #00B0FF !important; }

.light-blue-A700-bg {
  background-color: #0091EA !important; }

.md-light-blue-A700-bg {
  background-color: #0091EA !important;
  color: white; }

.light-blue-A700-fg {
  color: #0091EA !important; }

.light-blue-A700-border {
  border-color: #0091EA !important; }

.light-blue-A700-border-top {
  border-top-color: #0091EA !important; }

.light-blue-A700-border-right {
  border-right-color: #0091EA !important; }

.light-blue-A700-border-bottom {
  border-bottom-color: #0091EA !important; }

.light-blue-A700-border-left {
  border-left-color: #0091EA !important; }

.cyan-50-bg {
  background-color: #E0F7FA !important; }

.md-cyan-50-bg {
  background-color: #E0F7FA !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-50-fg {
  color: #E0F7FA !important; }

.cyan-50-border {
  border-color: #E0F7FA !important; }

.cyan-50-border-top {
  border-top-color: #E0F7FA !important; }

.cyan-50-border-right {
  border-right-color: #E0F7FA !important; }

.cyan-50-border-bottom {
  border-bottom-color: #E0F7FA !important; }

.cyan-50-border-left {
  border-left-color: #E0F7FA !important; }

.cyan-100-bg {
  background-color: #B2EBF2 !important; }

.md-cyan-100-bg {
  background-color: #B2EBF2 !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-100-fg {
  color: #B2EBF2 !important; }

.cyan-100-border {
  border-color: #B2EBF2 !important; }

.cyan-100-border-top {
  border-top-color: #B2EBF2 !important; }

.cyan-100-border-right {
  border-right-color: #B2EBF2 !important; }

.cyan-100-border-bottom {
  border-bottom-color: #B2EBF2 !important; }

.cyan-100-border-left {
  border-left-color: #B2EBF2 !important; }

.cyan-200-bg {
  background-color: #80DEEA !important; }

.md-cyan-200-bg {
  background-color: #80DEEA !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-200-fg {
  color: #80DEEA !important; }

.cyan-200-border {
  border-color: #80DEEA !important; }

.cyan-200-border-top {
  border-top-color: #80DEEA !important; }

.cyan-200-border-right {
  border-right-color: #80DEEA !important; }

.cyan-200-border-bottom {
  border-bottom-color: #80DEEA !important; }

.cyan-200-border-left {
  border-left-color: #80DEEA !important; }

.cyan-300-bg {
  background-color: #4DD0E1 !important; }

.md-cyan-300-bg {
  background-color: #4DD0E1 !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-300-fg {
  color: #4DD0E1 !important; }

.cyan-300-border {
  border-color: #4DD0E1 !important; }

.cyan-300-border-top {
  border-top-color: #4DD0E1 !important; }

.cyan-300-border-right {
  border-right-color: #4DD0E1 !important; }

.cyan-300-border-bottom {
  border-bottom-color: #4DD0E1 !important; }

.cyan-300-border-left {
  border-left-color: #4DD0E1 !important; }

.cyan-400-bg {
  background-color: #26C6DA !important; }

.md-cyan-400-bg {
  background-color: #26C6DA !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-400-fg {
  color: #26C6DA !important; }

.cyan-400-border {
  border-color: #26C6DA !important; }

.cyan-400-border-top {
  border-top-color: #26C6DA !important; }

.cyan-400-border-right {
  border-right-color: #26C6DA !important; }

.cyan-400-border-bottom {
  border-bottom-color: #26C6DA !important; }

.cyan-400-border-left {
  border-left-color: #26C6DA !important; }

.cyan-500-bg {
  background-color: #00BCD4 !important; }

.md-cyan-500-bg {
  background-color: #00BCD4 !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-500-fg {
  color: #00BCD4 !important; }

.cyan-500-border {
  border-color: #00BCD4 !important; }

.cyan-500-border-top {
  border-top-color: #00BCD4 !important; }

.cyan-500-border-right {
  border-right-color: #00BCD4 !important; }

.cyan-500-border-bottom {
  border-bottom-color: #00BCD4 !important; }

.cyan-500-border-left {
  border-left-color: #00BCD4 !important; }

.md-cyan-bg {
  background-color: #00BCD4 !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-bg {
  background-color: #00BCD4 !important; }

.cyan-fg {
  color: #00BCD4 !important; }

.cyan-border {
  border-color: #00BCD4 !important; }

.cyan-border-top {
  border-top-color: #00BCD4 !important; }

.cyan-border-right {
  border-right-color: #00BCD4 !important; }

.cyan-border-bottom {
  border-bottom-color: #00BCD4 !important; }

.cyan-border-left {
  border-left-color: #00BCD4 !important; }

.cyan-600-bg {
  background-color: #00ACC1 !important; }

.md-cyan-600-bg {
  background-color: #00ACC1 !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-600-fg {
  color: #00ACC1 !important; }

.cyan-600-border {
  border-color: #00ACC1 !important; }

.cyan-600-border-top {
  border-top-color: #00ACC1 !important; }

.cyan-600-border-right {
  border-right-color: #00ACC1 !important; }

.cyan-600-border-bottom {
  border-bottom-color: #00ACC1 !important; }

.cyan-600-border-left {
  border-left-color: #00ACC1 !important; }

.cyan-700-bg {
  background-color: #0097A7 !important; }

.md-cyan-700-bg {
  background-color: #0097A7 !important;
  color: white; }

.cyan-700-fg {
  color: #0097A7 !important; }

.cyan-700-border {
  border-color: #0097A7 !important; }

.cyan-700-border-top {
  border-top-color: #0097A7 !important; }

.cyan-700-border-right {
  border-right-color: #0097A7 !important; }

.cyan-700-border-bottom {
  border-bottom-color: #0097A7 !important; }

.cyan-700-border-left {
  border-left-color: #0097A7 !important; }

.cyan-800-bg {
  background-color: #00838F !important; }

.md-cyan-800-bg {
  background-color: #00838F !important;
  color: white; }

.cyan-800-fg {
  color: #00838F !important; }

.cyan-800-border {
  border-color: #00838F !important; }

.cyan-800-border-top {
  border-top-color: #00838F !important; }

.cyan-800-border-right {
  border-right-color: #00838F !important; }

.cyan-800-border-bottom {
  border-bottom-color: #00838F !important; }

.cyan-800-border-left {
  border-left-color: #00838F !important; }

.cyan-900-bg {
  background-color: #006064 !important; }

.md-cyan-900-bg {
  background-color: #006064 !important;
  color: white; }

.cyan-900-fg {
  color: #006064 !important; }

.cyan-900-border {
  border-color: #006064 !important; }

.cyan-900-border-top {
  border-top-color: #006064 !important; }

.cyan-900-border-right {
  border-right-color: #006064 !important; }

.cyan-900-border-bottom {
  border-bottom-color: #006064 !important; }

.cyan-900-border-left {
  border-left-color: #006064 !important; }

.cyan-A100-bg {
  background-color: #84FFFF !important; }

.md-cyan-A100-bg {
  background-color: #84FFFF !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-A100-fg {
  color: #84FFFF !important; }

.cyan-A100-border {
  border-color: #84FFFF !important; }

.cyan-A100-border-top {
  border-top-color: #84FFFF !important; }

.cyan-A100-border-right {
  border-right-color: #84FFFF !important; }

.cyan-A100-border-bottom {
  border-bottom-color: #84FFFF !important; }

.cyan-A100-border-left {
  border-left-color: #84FFFF !important; }

.cyan-A200-bg {
  background-color: #18FFFF !important; }

.md-cyan-A200-bg {
  background-color: #18FFFF !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-A200-fg {
  color: #18FFFF !important; }

.cyan-A200-border {
  border-color: #18FFFF !important; }

.cyan-A200-border-top {
  border-top-color: #18FFFF !important; }

.cyan-A200-border-right {
  border-right-color: #18FFFF !important; }

.cyan-A200-border-bottom {
  border-bottom-color: #18FFFF !important; }

.cyan-A200-border-left {
  border-left-color: #18FFFF !important; }

.cyan-A400-bg {
  background-color: #00E5FF !important; }

.md-cyan-A400-bg {
  background-color: #00E5FF !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-A400-fg {
  color: #00E5FF !important; }

.cyan-A400-border {
  border-color: #00E5FF !important; }

.cyan-A400-border-top {
  border-top-color: #00E5FF !important; }

.cyan-A400-border-right {
  border-right-color: #00E5FF !important; }

.cyan-A400-border-bottom {
  border-bottom-color: #00E5FF !important; }

.cyan-A400-border-left {
  border-left-color: #00E5FF !important; }

.cyan-A700-bg {
  background-color: #00B8D4 !important; }

.md-cyan-A700-bg {
  background-color: #00B8D4 !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-A700-fg {
  color: #00B8D4 !important; }

.cyan-A700-border {
  border-color: #00B8D4 !important; }

.cyan-A700-border-top {
  border-top-color: #00B8D4 !important; }

.cyan-A700-border-right {
  border-right-color: #00B8D4 !important; }

.cyan-A700-border-bottom {
  border-bottom-color: #00B8D4 !important; }

.cyan-A700-border-left {
  border-left-color: #00B8D4 !important; }

.teal-50-bg {
  background-color: #E0F2F1 !important; }

.md-teal-50-bg {
  background-color: #E0F2F1 !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-50-fg {
  color: #E0F2F1 !important; }

.teal-50-border {
  border-color: #E0F2F1 !important; }

.teal-50-border-top {
  border-top-color: #E0F2F1 !important; }

.teal-50-border-right {
  border-right-color: #E0F2F1 !important; }

.teal-50-border-bottom {
  border-bottom-color: #E0F2F1 !important; }

.teal-50-border-left {
  border-left-color: #E0F2F1 !important; }

.teal-100-bg {
  background-color: #B2DFDB !important; }

.md-teal-100-bg {
  background-color: #B2DFDB !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-100-fg {
  color: #B2DFDB !important; }

.teal-100-border {
  border-color: #B2DFDB !important; }

.teal-100-border-top {
  border-top-color: #B2DFDB !important; }

.teal-100-border-right {
  border-right-color: #B2DFDB !important; }

.teal-100-border-bottom {
  border-bottom-color: #B2DFDB !important; }

.teal-100-border-left {
  border-left-color: #B2DFDB !important; }

.teal-200-bg {
  background-color: #80CBC4 !important; }

.md-teal-200-bg {
  background-color: #80CBC4 !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-200-fg {
  color: #80CBC4 !important; }

.teal-200-border {
  border-color: #80CBC4 !important; }

.teal-200-border-top {
  border-top-color: #80CBC4 !important; }

.teal-200-border-right {
  border-right-color: #80CBC4 !important; }

.teal-200-border-bottom {
  border-bottom-color: #80CBC4 !important; }

.teal-200-border-left {
  border-left-color: #80CBC4 !important; }

.teal-300-bg {
  background-color: #4DB6AC !important; }

.md-teal-300-bg {
  background-color: #4DB6AC !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-300-fg {
  color: #4DB6AC !important; }

.teal-300-border {
  border-color: #4DB6AC !important; }

.teal-300-border-top {
  border-top-color: #4DB6AC !important; }

.teal-300-border-right {
  border-right-color: #4DB6AC !important; }

.teal-300-border-bottom {
  border-bottom-color: #4DB6AC !important; }

.teal-300-border-left {
  border-left-color: #4DB6AC !important; }

.teal-400-bg {
  background-color: #26A69A !important; }

.md-teal-400-bg {
  background-color: #26A69A !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-400-fg {
  color: #26A69A !important; }

.teal-400-border {
  border-color: #26A69A !important; }

.teal-400-border-top {
  border-top-color: #26A69A !important; }

.teal-400-border-right {
  border-right-color: #26A69A !important; }

.teal-400-border-bottom {
  border-bottom-color: #26A69A !important; }

.teal-400-border-left {
  border-left-color: #26A69A !important; }

.teal-500-bg {
  background-color: #009688 !important; }

.md-teal-500-bg {
  background-color: #009688 !important;
  color: white; }

.teal-500-fg {
  color: #009688 !important; }

.teal-500-border {
  border-color: #009688 !important; }

.teal-500-border-top {
  border-top-color: #009688 !important; }

.teal-500-border-right {
  border-right-color: #009688 !important; }

.teal-500-border-bottom {
  border-bottom-color: #009688 !important; }

.teal-500-border-left {
  border-left-color: #009688 !important; }

.md-teal-bg {
  background-color: #009688 !important;
  color: white; }

.teal-bg {
  background-color: #009688 !important; }

.teal-fg {
  color: #009688 !important; }

.teal-border {
  border-color: #009688 !important; }

.teal-border-top {
  border-top-color: #009688 !important; }

.teal-border-right {
  border-right-color: #009688 !important; }

.teal-border-bottom {
  border-bottom-color: #009688 !important; }

.teal-border-left {
  border-left-color: #009688 !important; }

.teal-600-bg {
  background-color: #00897B !important; }

.md-teal-600-bg {
  background-color: #00897B !important;
  color: white; }

.teal-600-fg {
  color: #00897B !important; }

.teal-600-border {
  border-color: #00897B !important; }

.teal-600-border-top {
  border-top-color: #00897B !important; }

.teal-600-border-right {
  border-right-color: #00897B !important; }

.teal-600-border-bottom {
  border-bottom-color: #00897B !important; }

.teal-600-border-left {
  border-left-color: #00897B !important; }

.teal-700-bg {
  background-color: #00796B !important; }

.md-teal-700-bg {
  background-color: #00796B !important;
  color: white; }

.teal-700-fg {
  color: #00796B !important; }

.teal-700-border {
  border-color: #00796B !important; }

.teal-700-border-top {
  border-top-color: #00796B !important; }

.teal-700-border-right {
  border-right-color: #00796B !important; }

.teal-700-border-bottom {
  border-bottom-color: #00796B !important; }

.teal-700-border-left {
  border-left-color: #00796B !important; }

.teal-800-bg {
  background-color: #00695C !important; }

.md-teal-800-bg {
  background-color: #00695C !important;
  color: rgba(255, 255, 255, 0.87); }

.teal-800-fg {
  color: #00695C !important; }

.teal-800-border {
  border-color: #00695C !important; }

.teal-800-border-top {
  border-top-color: #00695C !important; }

.teal-800-border-right {
  border-right-color: #00695C !important; }

.teal-800-border-bottom {
  border-bottom-color: #00695C !important; }

.teal-800-border-left {
  border-left-color: #00695C !important; }

.teal-900-bg {
  background-color: #004D40 !important; }

.md-teal-900-bg {
  background-color: #004D40 !important;
  color: rgba(255, 255, 255, 0.87); }

.teal-900-fg {
  color: #004D40 !important; }

.teal-900-border {
  border-color: #004D40 !important; }

.teal-900-border-top {
  border-top-color: #004D40 !important; }

.teal-900-border-right {
  border-right-color: #004D40 !important; }

.teal-900-border-bottom {
  border-bottom-color: #004D40 !important; }

.teal-900-border-left {
  border-left-color: #004D40 !important; }

.teal-A100-bg {
  background-color: #A7FFEB !important; }

.md-teal-A100-bg {
  background-color: #A7FFEB !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-A100-fg {
  color: #A7FFEB !important; }

.teal-A100-border {
  border-color: #A7FFEB !important; }

.teal-A100-border-top {
  border-top-color: #A7FFEB !important; }

.teal-A100-border-right {
  border-right-color: #A7FFEB !important; }

.teal-A100-border-bottom {
  border-bottom-color: #A7FFEB !important; }

.teal-A100-border-left {
  border-left-color: #A7FFEB !important; }

.teal-A200-bg {
  background-color: #64FFDA !important; }

.md-teal-A200-bg {
  background-color: #64FFDA !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-A200-fg {
  color: #64FFDA !important; }

.teal-A200-border {
  border-color: #64FFDA !important; }

.teal-A200-border-top {
  border-top-color: #64FFDA !important; }

.teal-A200-border-right {
  border-right-color: #64FFDA !important; }

.teal-A200-border-bottom {
  border-bottom-color: #64FFDA !important; }

.teal-A200-border-left {
  border-left-color: #64FFDA !important; }

.teal-A400-bg {
  background-color: #1DE9B6 !important; }

.md-teal-A400-bg {
  background-color: #1DE9B6 !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-A400-fg {
  color: #1DE9B6 !important; }

.teal-A400-border {
  border-color: #1DE9B6 !important; }

.teal-A400-border-top {
  border-top-color: #1DE9B6 !important; }

.teal-A400-border-right {
  border-right-color: #1DE9B6 !important; }

.teal-A400-border-bottom {
  border-bottom-color: #1DE9B6 !important; }

.teal-A400-border-left {
  border-left-color: #1DE9B6 !important; }

.teal-A700-bg {
  background-color: #00BFA5 !important; }

.md-teal-A700-bg {
  background-color: #00BFA5 !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-A700-fg {
  color: #00BFA5 !important; }

.teal-A700-border {
  border-color: #00BFA5 !important; }

.teal-A700-border-top {
  border-top-color: #00BFA5 !important; }

.teal-A700-border-right {
  border-right-color: #00BFA5 !important; }

.teal-A700-border-bottom {
  border-bottom-color: #00BFA5 !important; }

.teal-A700-border-left {
  border-left-color: #00BFA5 !important; }

.green-50-bg {
  background-color: #E8F5E9 !important; }

.md-green-50-bg {
  background-color: #E8F5E9 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-50-fg {
  color: #E8F5E9 !important; }

.green-50-border {
  border-color: #E8F5E9 !important; }

.green-50-border-top {
  border-top-color: #E8F5E9 !important; }

.green-50-border-right {
  border-right-color: #E8F5E9 !important; }

.green-50-border-bottom {
  border-bottom-color: #E8F5E9 !important; }

.green-50-border-left {
  border-left-color: #E8F5E9 !important; }

.green-100-bg {
  background-color: #C8E6C9 !important; }

.md-green-100-bg {
  background-color: #C8E6C9 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-100-fg {
  color: #C8E6C9 !important; }

.green-100-border {
  border-color: #C8E6C9 !important; }

.green-100-border-top {
  border-top-color: #C8E6C9 !important; }

.green-100-border-right {
  border-right-color: #C8E6C9 !important; }

.green-100-border-bottom {
  border-bottom-color: #C8E6C9 !important; }

.green-100-border-left {
  border-left-color: #C8E6C9 !important; }

.green-200-bg {
  background-color: #A5D6A7 !important; }

.md-green-200-bg {
  background-color: #A5D6A7 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-200-fg {
  color: #A5D6A7 !important; }

.green-200-border {
  border-color: #A5D6A7 !important; }

.green-200-border-top {
  border-top-color: #A5D6A7 !important; }

.green-200-border-right {
  border-right-color: #A5D6A7 !important; }

.green-200-border-bottom {
  border-bottom-color: #A5D6A7 !important; }

.green-200-border-left {
  border-left-color: #A5D6A7 !important; }

.green-300-bg {
  background-color: #81C784 !important; }

.md-green-300-bg {
  background-color: #81C784 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-300-fg {
  color: #81C784 !important; }

.green-300-border {
  border-color: #81C784 !important; }

.green-300-border-top {
  border-top-color: #81C784 !important; }

.green-300-border-right {
  border-right-color: #81C784 !important; }

.green-300-border-bottom {
  border-bottom-color: #81C784 !important; }

.green-300-border-left {
  border-left-color: #81C784 !important; }

.green-400-bg {
  background-color: #66BB6A !important; }

.md-green-400-bg {
  background-color: #66BB6A !important;
  color: rgba(0, 0, 0, 0.87); }

.green-400-fg {
  color: #66BB6A !important; }

.green-400-border {
  border-color: #66BB6A !important; }

.green-400-border-top {
  border-top-color: #66BB6A !important; }

.green-400-border-right {
  border-right-color: #66BB6A !important; }

.green-400-border-bottom {
  border-bottom-color: #66BB6A !important; }

.green-400-border-left {
  border-left-color: #66BB6A !important; }

.green-500-bg {
  background-color: #4CAF50 !important; }

.md-green-500-bg {
  background-color: #4CAF50 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-500-fg {
  color: #4CAF50 !important; }

.green-500-border {
  border-color: #4CAF50 !important; }

.green-500-border-top {
  border-top-color: #4CAF50 !important; }

.green-500-border-right {
  border-right-color: #4CAF50 !important; }

.green-500-border-bottom {
  border-bottom-color: #4CAF50 !important; }

.green-500-border-left {
  border-left-color: #4CAF50 !important; }

.md-green-bg {
  background-color: #4CAF50 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-bg {
  background-color: #4CAF50 !important; }

.green-fg {
  color: #4CAF50 !important; }

.green-border {
  border-color: #4CAF50 !important; }

.green-border-top {
  border-top-color: #4CAF50 !important; }

.green-border-right {
  border-right-color: #4CAF50 !important; }

.green-border-bottom {
  border-bottom-color: #4CAF50 !important; }

.green-border-left {
  border-left-color: #4CAF50 !important; }

.green-600-bg {
  background-color: #43A047 !important; }

.md-green-600-bg {
  background-color: #43A047 !important;
  color: white; }

.green-600-fg {
  color: #43A047 !important; }

.green-600-border {
  border-color: #43A047 !important; }

.green-600-border-top {
  border-top-color: #43A047 !important; }

.green-600-border-right {
  border-right-color: #43A047 !important; }

.green-600-border-bottom {
  border-bottom-color: #43A047 !important; }

.green-600-border-left {
  border-left-color: #43A047 !important; }

.green-700-bg {
  background-color: #388E3C !important; }

.md-green-700-bg {
  background-color: #388E3C !important;
  color: white; }

.green-700-fg {
  color: #388E3C !important; }

.green-700-border {
  border-color: #388E3C !important; }

.green-700-border-top {
  border-top-color: #388E3C !important; }

.green-700-border-right {
  border-right-color: #388E3C !important; }

.green-700-border-bottom {
  border-bottom-color: #388E3C !important; }

.green-700-border-left {
  border-left-color: #388E3C !important; }

.green-800-bg {
  background-color: #2E7D32 !important; }

.md-green-800-bg {
  background-color: #2E7D32 !important;
  color: rgba(255, 255, 255, 0.87); }

.green-800-fg {
  color: #2E7D32 !important; }

.green-800-border {
  border-color: #2E7D32 !important; }

.green-800-border-top {
  border-top-color: #2E7D32 !important; }

.green-800-border-right {
  border-right-color: #2E7D32 !important; }

.green-800-border-bottom {
  border-bottom-color: #2E7D32 !important; }

.green-800-border-left {
  border-left-color: #2E7D32 !important; }

.green-900-bg {
  background-color: #1B5E20 !important; }

.md-green-900-bg {
  background-color: #1B5E20 !important;
  color: rgba(255, 255, 255, 0.87); }

.green-900-fg {
  color: #1B5E20 !important; }

.green-900-border {
  border-color: #1B5E20 !important; }

.green-900-border-top {
  border-top-color: #1B5E20 !important; }

.green-900-border-right {
  border-right-color: #1B5E20 !important; }

.green-900-border-bottom {
  border-bottom-color: #1B5E20 !important; }

.green-900-border-left {
  border-left-color: #1B5E20 !important; }

.green-A100-bg {
  background-color: #B9F6CA !important; }

.md-green-A100-bg {
  background-color: #B9F6CA !important;
  color: rgba(0, 0, 0, 0.87); }

.green-A100-fg {
  color: #B9F6CA !important; }

.green-A100-border {
  border-color: #B9F6CA !important; }

.green-A100-border-top {
  border-top-color: #B9F6CA !important; }

.green-A100-border-right {
  border-right-color: #B9F6CA !important; }

.green-A100-border-bottom {
  border-bottom-color: #B9F6CA !important; }

.green-A100-border-left {
  border-left-color: #B9F6CA !important; }

.green-A200-bg {
  background-color: #69F0AE !important; }

.md-green-A200-bg {
  background-color: #69F0AE !important;
  color: rgba(0, 0, 0, 0.87); }

.green-A200-fg {
  color: #69F0AE !important; }

.green-A200-border {
  border-color: #69F0AE !important; }

.green-A200-border-top {
  border-top-color: #69F0AE !important; }

.green-A200-border-right {
  border-right-color: #69F0AE !important; }

.green-A200-border-bottom {
  border-bottom-color: #69F0AE !important; }

.green-A200-border-left {
  border-left-color: #69F0AE !important; }

.green-A400-bg {
  background-color: #00E676 !important; }

.md-green-A400-bg {
  background-color: #00E676 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-A400-fg {
  color: #00E676 !important; }

.green-A400-border {
  border-color: #00E676 !important; }

.green-A400-border-top {
  border-top-color: #00E676 !important; }

.green-A400-border-right {
  border-right-color: #00E676 !important; }

.green-A400-border-bottom {
  border-bottom-color: #00E676 !important; }

.green-A400-border-left {
  border-left-color: #00E676 !important; }

.green-A700-bg {
  background-color: #00C853 !important; }

.md-green-A700-bg {
  background-color: #00C853 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-A700-fg {
  color: #00C853 !important; }

.green-A700-border {
  border-color: #00C853 !important; }

.green-A700-border-top {
  border-top-color: #00C853 !important; }

.green-A700-border-right {
  border-right-color: #00C853 !important; }

.green-A700-border-bottom {
  border-bottom-color: #00C853 !important; }

.green-A700-border-left {
  border-left-color: #00C853 !important; }

.light-green-50-bg {
  background-color: #F1F8E9 !important; }

.md-light-green-50-bg {
  background-color: #F1F8E9 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-50-fg {
  color: #F1F8E9 !important; }

.light-green-50-border {
  border-color: #F1F8E9 !important; }

.light-green-50-border-top {
  border-top-color: #F1F8E9 !important; }

.light-green-50-border-right {
  border-right-color: #F1F8E9 !important; }

.light-green-50-border-bottom {
  border-bottom-color: #F1F8E9 !important; }

.light-green-50-border-left {
  border-left-color: #F1F8E9 !important; }

.light-green-100-bg {
  background-color: #DCEDC8 !important; }

.md-light-green-100-bg {
  background-color: #DCEDC8 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-100-fg {
  color: #DCEDC8 !important; }

.light-green-100-border {
  border-color: #DCEDC8 !important; }

.light-green-100-border-top {
  border-top-color: #DCEDC8 !important; }

.light-green-100-border-right {
  border-right-color: #DCEDC8 !important; }

.light-green-100-border-bottom {
  border-bottom-color: #DCEDC8 !important; }

.light-green-100-border-left {
  border-left-color: #DCEDC8 !important; }

.light-green-200-bg {
  background-color: #C5E1A5 !important; }

.md-light-green-200-bg {
  background-color: #C5E1A5 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-200-fg {
  color: #C5E1A5 !important; }

.light-green-200-border {
  border-color: #C5E1A5 !important; }

.light-green-200-border-top {
  border-top-color: #C5E1A5 !important; }

.light-green-200-border-right {
  border-right-color: #C5E1A5 !important; }

.light-green-200-border-bottom {
  border-bottom-color: #C5E1A5 !important; }

.light-green-200-border-left {
  border-left-color: #C5E1A5 !important; }

.light-green-300-bg {
  background-color: #AED581 !important; }

.md-light-green-300-bg {
  background-color: #AED581 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-300-fg {
  color: #AED581 !important; }

.light-green-300-border {
  border-color: #AED581 !important; }

.light-green-300-border-top {
  border-top-color: #AED581 !important; }

.light-green-300-border-right {
  border-right-color: #AED581 !important; }

.light-green-300-border-bottom {
  border-bottom-color: #AED581 !important; }

.light-green-300-border-left {
  border-left-color: #AED581 !important; }

.light-green-400-bg {
  background-color: #9CCC65 !important; }

.md-light-green-400-bg {
  background-color: #9CCC65 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-400-fg {
  color: #9CCC65 !important; }

.light-green-400-border {
  border-color: #9CCC65 !important; }

.light-green-400-border-top {
  border-top-color: #9CCC65 !important; }

.light-green-400-border-right {
  border-right-color: #9CCC65 !important; }

.light-green-400-border-bottom {
  border-bottom-color: #9CCC65 !important; }

.light-green-400-border-left {
  border-left-color: #9CCC65 !important; }

.light-green-500-bg {
  background-color: #8BC34A !important; }

.md-light-green-500-bg {
  background-color: #8BC34A !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-500-fg {
  color: #8BC34A !important; }

.light-green-500-border {
  border-color: #8BC34A !important; }

.light-green-500-border-top {
  border-top-color: #8BC34A !important; }

.light-green-500-border-right {
  border-right-color: #8BC34A !important; }

.light-green-500-border-bottom {
  border-bottom-color: #8BC34A !important; }

.light-green-500-border-left {
  border-left-color: #8BC34A !important; }

.md-light-green-bg {
  background-color: #8BC34A !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-bg {
  background-color: #8BC34A !important; }

.light-green-fg {
  color: #8BC34A !important; }

.light-green-border {
  border-color: #8BC34A !important; }

.light-green-border-top {
  border-top-color: #8BC34A !important; }

.light-green-border-right {
  border-right-color: #8BC34A !important; }

.light-green-border-bottom {
  border-bottom-color: #8BC34A !important; }

.light-green-border-left {
  border-left-color: #8BC34A !important; }

.light-green-600-bg {
  background-color: #7CB342 !important; }

.md-light-green-600-bg {
  background-color: #7CB342 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-600-fg {
  color: #7CB342 !important; }

.light-green-600-border {
  border-color: #7CB342 !important; }

.light-green-600-border-top {
  border-top-color: #7CB342 !important; }

.light-green-600-border-right {
  border-right-color: #7CB342 !important; }

.light-green-600-border-bottom {
  border-bottom-color: #7CB342 !important; }

.light-green-600-border-left {
  border-left-color: #7CB342 !important; }

.light-green-700-bg {
  background-color: #689F38 !important; }

.md-light-green-700-bg {
  background-color: #689F38 !important;
  color: white; }

.light-green-700-fg {
  color: #689F38 !important; }

.light-green-700-border {
  border-color: #689F38 !important; }

.light-green-700-border-top {
  border-top-color: #689F38 !important; }

.light-green-700-border-right {
  border-right-color: #689F38 !important; }

.light-green-700-border-bottom {
  border-bottom-color: #689F38 !important; }

.light-green-700-border-left {
  border-left-color: #689F38 !important; }

.light-green-800-bg {
  background-color: #558B2F !important; }

.md-light-green-800-bg {
  background-color: #558B2F !important;
  color: white; }

.light-green-800-fg {
  color: #558B2F !important; }

.light-green-800-border {
  border-color: #558B2F !important; }

.light-green-800-border-top {
  border-top-color: #558B2F !important; }

.light-green-800-border-right {
  border-right-color: #558B2F !important; }

.light-green-800-border-bottom {
  border-bottom-color: #558B2F !important; }

.light-green-800-border-left {
  border-left-color: #558B2F !important; }

.light-green-900-bg {
  background-color: #33691E !important; }

.md-light-green-900-bg {
  background-color: #33691E !important;
  color: white; }

.light-green-900-fg {
  color: #33691E !important; }

.light-green-900-border {
  border-color: #33691E !important; }

.light-green-900-border-top {
  border-top-color: #33691E !important; }

.light-green-900-border-right {
  border-right-color: #33691E !important; }

.light-green-900-border-bottom {
  border-bottom-color: #33691E !important; }

.light-green-900-border-left {
  border-left-color: #33691E !important; }

.light-green-A100-bg {
  background-color: #CCFF90 !important; }

.md-light-green-A100-bg {
  background-color: #CCFF90 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-A100-fg {
  color: #CCFF90 !important; }

.light-green-A100-border {
  border-color: #CCFF90 !important; }

.light-green-A100-border-top {
  border-top-color: #CCFF90 !important; }

.light-green-A100-border-right {
  border-right-color: #CCFF90 !important; }

.light-green-A100-border-bottom {
  border-bottom-color: #CCFF90 !important; }

.light-green-A100-border-left {
  border-left-color: #CCFF90 !important; }

.light-green-A200-bg {
  background-color: #B2FF59 !important; }

.md-light-green-A200-bg {
  background-color: #B2FF59 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-A200-fg {
  color: #B2FF59 !important; }

.light-green-A200-border {
  border-color: #B2FF59 !important; }

.light-green-A200-border-top {
  border-top-color: #B2FF59 !important; }

.light-green-A200-border-right {
  border-right-color: #B2FF59 !important; }

.light-green-A200-border-bottom {
  border-bottom-color: #B2FF59 !important; }

.light-green-A200-border-left {
  border-left-color: #B2FF59 !important; }

.light-green-A400-bg {
  background-color: #76FF03 !important; }

.md-light-green-A400-bg {
  background-color: #76FF03 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-A400-fg {
  color: #76FF03 !important; }

.light-green-A400-border {
  border-color: #76FF03 !important; }

.light-green-A400-border-top {
  border-top-color: #76FF03 !important; }

.light-green-A400-border-right {
  border-right-color: #76FF03 !important; }

.light-green-A400-border-bottom {
  border-bottom-color: #76FF03 !important; }

.light-green-A400-border-left {
  border-left-color: #76FF03 !important; }

.light-green-A700-bg {
  background-color: #64DD17 !important; }

.md-light-green-A700-bg {
  background-color: #64DD17 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-A700-fg {
  color: #64DD17 !important; }

.light-green-A700-border {
  border-color: #64DD17 !important; }

.light-green-A700-border-top {
  border-top-color: #64DD17 !important; }

.light-green-A700-border-right {
  border-right-color: #64DD17 !important; }

.light-green-A700-border-bottom {
  border-bottom-color: #64DD17 !important; }

.light-green-A700-border-left {
  border-left-color: #64DD17 !important; }

.lime-50-bg {
  background-color: #F9FBE7 !important; }

.md-lime-50-bg {
  background-color: #F9FBE7 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-50-fg {
  color: #F9FBE7 !important; }

.lime-50-border {
  border-color: #F9FBE7 !important; }

.lime-50-border-top {
  border-top-color: #F9FBE7 !important; }

.lime-50-border-right {
  border-right-color: #F9FBE7 !important; }

.lime-50-border-bottom {
  border-bottom-color: #F9FBE7 !important; }

.lime-50-border-left {
  border-left-color: #F9FBE7 !important; }

.lime-100-bg {
  background-color: #F0F4C3 !important; }

.md-lime-100-bg {
  background-color: #F0F4C3 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-100-fg {
  color: #F0F4C3 !important; }

.lime-100-border {
  border-color: #F0F4C3 !important; }

.lime-100-border-top {
  border-top-color: #F0F4C3 !important; }

.lime-100-border-right {
  border-right-color: #F0F4C3 !important; }

.lime-100-border-bottom {
  border-bottom-color: #F0F4C3 !important; }

.lime-100-border-left {
  border-left-color: #F0F4C3 !important; }

.lime-200-bg {
  background-color: #E6EE9C !important; }

.md-lime-200-bg {
  background-color: #E6EE9C !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-200-fg {
  color: #E6EE9C !important; }

.lime-200-border {
  border-color: #E6EE9C !important; }

.lime-200-border-top {
  border-top-color: #E6EE9C !important; }

.lime-200-border-right {
  border-right-color: #E6EE9C !important; }

.lime-200-border-bottom {
  border-bottom-color: #E6EE9C !important; }

.lime-200-border-left {
  border-left-color: #E6EE9C !important; }

.lime-300-bg {
  background-color: #DCE775 !important; }

.md-lime-300-bg {
  background-color: #DCE775 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-300-fg {
  color: #DCE775 !important; }

.lime-300-border {
  border-color: #DCE775 !important; }

.lime-300-border-top {
  border-top-color: #DCE775 !important; }

.lime-300-border-right {
  border-right-color: #DCE775 !important; }

.lime-300-border-bottom {
  border-bottom-color: #DCE775 !important; }

.lime-300-border-left {
  border-left-color: #DCE775 !important; }

.lime-400-bg {
  background-color: #D4E157 !important; }

.md-lime-400-bg {
  background-color: #D4E157 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-400-fg {
  color: #D4E157 !important; }

.lime-400-border {
  border-color: #D4E157 !important; }

.lime-400-border-top {
  border-top-color: #D4E157 !important; }

.lime-400-border-right {
  border-right-color: #D4E157 !important; }

.lime-400-border-bottom {
  border-bottom-color: #D4E157 !important; }

.lime-400-border-left {
  border-left-color: #D4E157 !important; }

.lime-500-bg {
  background-color: #CDDC39 !important; }

.md-lime-500-bg {
  background-color: #CDDC39 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-500-fg {
  color: #CDDC39 !important; }

.lime-500-border {
  border-color: #CDDC39 !important; }

.lime-500-border-top {
  border-top-color: #CDDC39 !important; }

.lime-500-border-right {
  border-right-color: #CDDC39 !important; }

.lime-500-border-bottom {
  border-bottom-color: #CDDC39 !important; }

.lime-500-border-left {
  border-left-color: #CDDC39 !important; }

.md-lime-bg {
  background-color: #CDDC39 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-bg {
  background-color: #CDDC39 !important; }

.lime-fg {
  color: #CDDC39 !important; }

.lime-border {
  border-color: #CDDC39 !important; }

.lime-border-top {
  border-top-color: #CDDC39 !important; }

.lime-border-right {
  border-right-color: #CDDC39 !important; }

.lime-border-bottom {
  border-bottom-color: #CDDC39 !important; }

.lime-border-left {
  border-left-color: #CDDC39 !important; }

.lime-600-bg {
  background-color: #C0CA33 !important; }

.md-lime-600-bg {
  background-color: #C0CA33 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-600-fg {
  color: #C0CA33 !important; }

.lime-600-border {
  border-color: #C0CA33 !important; }

.lime-600-border-top {
  border-top-color: #C0CA33 !important; }

.lime-600-border-right {
  border-right-color: #C0CA33 !important; }

.lime-600-border-bottom {
  border-bottom-color: #C0CA33 !important; }

.lime-600-border-left {
  border-left-color: #C0CA33 !important; }

.lime-700-bg {
  background-color: #AFB42B !important; }

.md-lime-700-bg {
  background-color: #AFB42B !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-700-fg {
  color: #AFB42B !important; }

.lime-700-border {
  border-color: #AFB42B !important; }

.lime-700-border-top {
  border-top-color: #AFB42B !important; }

.lime-700-border-right {
  border-right-color: #AFB42B !important; }

.lime-700-border-bottom {
  border-bottom-color: #AFB42B !important; }

.lime-700-border-left {
  border-left-color: #AFB42B !important; }

.lime-800-bg {
  background-color: #9E9D24 !important; }

.md-lime-800-bg {
  background-color: #9E9D24 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-800-fg {
  color: #9E9D24 !important; }

.lime-800-border {
  border-color: #9E9D24 !important; }

.lime-800-border-top {
  border-top-color: #9E9D24 !important; }

.lime-800-border-right {
  border-right-color: #9E9D24 !important; }

.lime-800-border-bottom {
  border-bottom-color: #9E9D24 !important; }

.lime-800-border-left {
  border-left-color: #9E9D24 !important; }

.lime-900-bg {
  background-color: #827717 !important; }

.md-lime-900-bg {
  background-color: #827717 !important;
  color: white; }

.lime-900-fg {
  color: #827717 !important; }

.lime-900-border {
  border-color: #827717 !important; }

.lime-900-border-top {
  border-top-color: #827717 !important; }

.lime-900-border-right {
  border-right-color: #827717 !important; }

.lime-900-border-bottom {
  border-bottom-color: #827717 !important; }

.lime-900-border-left {
  border-left-color: #827717 !important; }

.lime-A100-bg {
  background-color: #F4FF81 !important; }

.md-lime-A100-bg {
  background-color: #F4FF81 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-A100-fg {
  color: #F4FF81 !important; }

.lime-A100-border {
  border-color: #F4FF81 !important; }

.lime-A100-border-top {
  border-top-color: #F4FF81 !important; }

.lime-A100-border-right {
  border-right-color: #F4FF81 !important; }

.lime-A100-border-bottom {
  border-bottom-color: #F4FF81 !important; }

.lime-A100-border-left {
  border-left-color: #F4FF81 !important; }

.lime-A200-bg {
  background-color: #EEFF41 !important; }

.md-lime-A200-bg {
  background-color: #EEFF41 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-A200-fg {
  color: #EEFF41 !important; }

.lime-A200-border {
  border-color: #EEFF41 !important; }

.lime-A200-border-top {
  border-top-color: #EEFF41 !important; }

.lime-A200-border-right {
  border-right-color: #EEFF41 !important; }

.lime-A200-border-bottom {
  border-bottom-color: #EEFF41 !important; }

.lime-A200-border-left {
  border-left-color: #EEFF41 !important; }

.lime-A400-bg {
  background-color: #C6FF00 !important; }

.md-lime-A400-bg {
  background-color: #C6FF00 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-A400-fg {
  color: #C6FF00 !important; }

.lime-A400-border {
  border-color: #C6FF00 !important; }

.lime-A400-border-top {
  border-top-color: #C6FF00 !important; }

.lime-A400-border-right {
  border-right-color: #C6FF00 !important; }

.lime-A400-border-bottom {
  border-bottom-color: #C6FF00 !important; }

.lime-A400-border-left {
  border-left-color: #C6FF00 !important; }

.lime-A700-bg {
  background-color: #AEEA00 !important; }

.md-lime-A700-bg {
  background-color: #AEEA00 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-A700-fg {
  color: #AEEA00 !important; }

.lime-A700-border {
  border-color: #AEEA00 !important; }

.lime-A700-border-top {
  border-top-color: #AEEA00 !important; }

.lime-A700-border-right {
  border-right-color: #AEEA00 !important; }

.lime-A700-border-bottom {
  border-bottom-color: #AEEA00 !important; }

.lime-A700-border-left {
  border-left-color: #AEEA00 !important; }

.yellow-50-bg {
  background-color: #FFFDE7 !important; }

.md-yellow-50-bg {
  background-color: #FFFDE7 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-50-fg {
  color: #FFFDE7 !important; }

.yellow-50-border {
  border-color: #FFFDE7 !important; }

.yellow-50-border-top {
  border-top-color: #FFFDE7 !important; }

.yellow-50-border-right {
  border-right-color: #FFFDE7 !important; }

.yellow-50-border-bottom {
  border-bottom-color: #FFFDE7 !important; }

.yellow-50-border-left {
  border-left-color: #FFFDE7 !important; }

.yellow-100-bg {
  background-color: #FFF9C4 !important; }

.md-yellow-100-bg {
  background-color: #FFF9C4 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-100-fg {
  color: #FFF9C4 !important; }

.yellow-100-border {
  border-color: #FFF9C4 !important; }

.yellow-100-border-top {
  border-top-color: #FFF9C4 !important; }

.yellow-100-border-right {
  border-right-color: #FFF9C4 !important; }

.yellow-100-border-bottom {
  border-bottom-color: #FFF9C4 !important; }

.yellow-100-border-left {
  border-left-color: #FFF9C4 !important; }

.yellow-200-bg {
  background-color: #FFF59D !important; }

.md-yellow-200-bg {
  background-color: #FFF59D !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-200-fg {
  color: #FFF59D !important; }

.yellow-200-border {
  border-color: #FFF59D !important; }

.yellow-200-border-top {
  border-top-color: #FFF59D !important; }

.yellow-200-border-right {
  border-right-color: #FFF59D !important; }

.yellow-200-border-bottom {
  border-bottom-color: #FFF59D !important; }

.yellow-200-border-left {
  border-left-color: #FFF59D !important; }

.yellow-300-bg {
  background-color: #FFF176 !important; }

.md-yellow-300-bg {
  background-color: #FFF176 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-300-fg {
  color: #FFF176 !important; }

.yellow-300-border {
  border-color: #FFF176 !important; }

.yellow-300-border-top {
  border-top-color: #FFF176 !important; }

.yellow-300-border-right {
  border-right-color: #FFF176 !important; }

.yellow-300-border-bottom {
  border-bottom-color: #FFF176 !important; }

.yellow-300-border-left {
  border-left-color: #FFF176 !important; }

.yellow-400-bg {
  background-color: #FFEE58 !important; }

.md-yellow-400-bg {
  background-color: #FFEE58 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-400-fg {
  color: #FFEE58 !important; }

.yellow-400-border {
  border-color: #FFEE58 !important; }

.yellow-400-border-top {
  border-top-color: #FFEE58 !important; }

.yellow-400-border-right {
  border-right-color: #FFEE58 !important; }

.yellow-400-border-bottom {
  border-bottom-color: #FFEE58 !important; }

.yellow-400-border-left {
  border-left-color: #FFEE58 !important; }

.yellow-500-bg {
  background-color: #FFEB3B !important; }

.md-yellow-500-bg {
  background-color: #FFEB3B !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-500-fg {
  color: #FFEB3B !important; }

.yellow-500-border {
  border-color: #FFEB3B !important; }

.yellow-500-border-top {
  border-top-color: #FFEB3B !important; }

.yellow-500-border-right {
  border-right-color: #FFEB3B !important; }

.yellow-500-border-bottom {
  border-bottom-color: #FFEB3B !important; }

.yellow-500-border-left {
  border-left-color: #FFEB3B !important; }

.md-yellow-bg {
  background-color: #FFEB3B !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-bg {
  background-color: #FFEB3B !important; }

.yellow-fg {
  color: #FFEB3B !important; }

.yellow-border {
  border-color: #FFEB3B !important; }

.yellow-border-top {
  border-top-color: #FFEB3B !important; }

.yellow-border-right {
  border-right-color: #FFEB3B !important; }

.yellow-border-bottom {
  border-bottom-color: #FFEB3B !important; }

.yellow-border-left {
  border-left-color: #FFEB3B !important; }

.yellow-600-bg {
  background-color: #FDD835 !important; }

.md-yellow-600-bg {
  background-color: #FDD835 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-600-fg {
  color: #FDD835 !important; }

.yellow-600-border {
  border-color: #FDD835 !important; }

.yellow-600-border-top {
  border-top-color: #FDD835 !important; }

.yellow-600-border-right {
  border-right-color: #FDD835 !important; }

.yellow-600-border-bottom {
  border-bottom-color: #FDD835 !important; }

.yellow-600-border-left {
  border-left-color: #FDD835 !important; }

.yellow-700-bg {
  background-color: #FBC02D !important; }

.md-yellow-700-bg {
  background-color: #FBC02D !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-700-fg {
  color: #FBC02D !important; }

.yellow-700-border {
  border-color: #FBC02D !important; }

.yellow-700-border-top {
  border-top-color: #FBC02D !important; }

.yellow-700-border-right {
  border-right-color: #FBC02D !important; }

.yellow-700-border-bottom {
  border-bottom-color: #FBC02D !important; }

.yellow-700-border-left {
  border-left-color: #FBC02D !important; }

.yellow-800-bg {
  background-color: #F9A825 !important; }

.md-yellow-800-bg {
  background-color: #F9A825 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-800-fg {
  color: #F9A825 !important; }

.yellow-800-border {
  border-color: #F9A825 !important; }

.yellow-800-border-top {
  border-top-color: #F9A825 !important; }

.yellow-800-border-right {
  border-right-color: #F9A825 !important; }

.yellow-800-border-bottom {
  border-bottom-color: #F9A825 !important; }

.yellow-800-border-left {
  border-left-color: #F9A825 !important; }

.yellow-900-bg {
  background-color: #F57F17 !important; }

.md-yellow-900-bg {
  background-color: #F57F17 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-900-fg {
  color: #F57F17 !important; }

.yellow-900-border {
  border-color: #F57F17 !important; }

.yellow-900-border-top {
  border-top-color: #F57F17 !important; }

.yellow-900-border-right {
  border-right-color: #F57F17 !important; }

.yellow-900-border-bottom {
  border-bottom-color: #F57F17 !important; }

.yellow-900-border-left {
  border-left-color: #F57F17 !important; }

.yellow-A100-bg {
  background-color: #FFFF8D !important; }

.md-yellow-A100-bg {
  background-color: #FFFF8D !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-A100-fg {
  color: #FFFF8D !important; }

.yellow-A100-border {
  border-color: #FFFF8D !important; }

.yellow-A100-border-top {
  border-top-color: #FFFF8D !important; }

.yellow-A100-border-right {
  border-right-color: #FFFF8D !important; }

.yellow-A100-border-bottom {
  border-bottom-color: #FFFF8D !important; }

.yellow-A100-border-left {
  border-left-color: #FFFF8D !important; }

.yellow-A200-bg {
  background-color: #FFFF00 !important; }

.md-yellow-A200-bg {
  background-color: #FFFF00 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-A200-fg {
  color: #FFFF00 !important; }

.yellow-A200-border {
  border-color: #FFFF00 !important; }

.yellow-A200-border-top {
  border-top-color: #FFFF00 !important; }

.yellow-A200-border-right {
  border-right-color: #FFFF00 !important; }

.yellow-A200-border-bottom {
  border-bottom-color: #FFFF00 !important; }

.yellow-A200-border-left {
  border-left-color: #FFFF00 !important; }

.yellow-A400-bg {
  background-color: #FFEA00 !important; }

.md-yellow-A400-bg {
  background-color: #FFEA00 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-A400-fg {
  color: #FFEA00 !important; }

.yellow-A400-border {
  border-color: #FFEA00 !important; }

.yellow-A400-border-top {
  border-top-color: #FFEA00 !important; }

.yellow-A400-border-right {
  border-right-color: #FFEA00 !important; }

.yellow-A400-border-bottom {
  border-bottom-color: #FFEA00 !important; }

.yellow-A400-border-left {
  border-left-color: #FFEA00 !important; }

.yellow-A700-bg {
  background-color: #FFD600 !important; }

.md-yellow-A700-bg {
  background-color: #FFD600 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-A700-fg {
  color: #FFD600 !important; }

.yellow-A700-border {
  border-color: #FFD600 !important; }

.yellow-A700-border-top {
  border-top-color: #FFD600 !important; }

.yellow-A700-border-right {
  border-right-color: #FFD600 !important; }

.yellow-A700-border-bottom {
  border-bottom-color: #FFD600 !important; }

.yellow-A700-border-left {
  border-left-color: #FFD600 !important; }

.amber-50-bg {
  background-color: #FFF8E1 !important; }

.md-amber-50-bg {
  background-color: #FFF8E1 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-50-fg {
  color: #FFF8E1 !important; }

.amber-50-border {
  border-color: #FFF8E1 !important; }

.amber-50-border-top {
  border-top-color: #FFF8E1 !important; }

.amber-50-border-right {
  border-right-color: #FFF8E1 !important; }

.amber-50-border-bottom {
  border-bottom-color: #FFF8E1 !important; }

.amber-50-border-left {
  border-left-color: #FFF8E1 !important; }

.amber-100-bg {
  background-color: #FFECB3 !important; }

.md-amber-100-bg {
  background-color: #FFECB3 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-100-fg {
  color: #FFECB3 !important; }

.amber-100-border {
  border-color: #FFECB3 !important; }

.amber-100-border-top {
  border-top-color: #FFECB3 !important; }

.amber-100-border-right {
  border-right-color: #FFECB3 !important; }

.amber-100-border-bottom {
  border-bottom-color: #FFECB3 !important; }

.amber-100-border-left {
  border-left-color: #FFECB3 !important; }

.amber-200-bg {
  background-color: #FFE082 !important; }

.md-amber-200-bg {
  background-color: #FFE082 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-200-fg {
  color: #FFE082 !important; }

.amber-200-border {
  border-color: #FFE082 !important; }

.amber-200-border-top {
  border-top-color: #FFE082 !important; }

.amber-200-border-right {
  border-right-color: #FFE082 !important; }

.amber-200-border-bottom {
  border-bottom-color: #FFE082 !important; }

.amber-200-border-left {
  border-left-color: #FFE082 !important; }

.amber-300-bg {
  background-color: #FFD54F !important; }

.md-amber-300-bg {
  background-color: #FFD54F !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-300-fg {
  color: #FFD54F !important; }

.amber-300-border {
  border-color: #FFD54F !important; }

.amber-300-border-top {
  border-top-color: #FFD54F !important; }

.amber-300-border-right {
  border-right-color: #FFD54F !important; }

.amber-300-border-bottom {
  border-bottom-color: #FFD54F !important; }

.amber-300-border-left {
  border-left-color: #FFD54F !important; }

.amber-400-bg {
  background-color: #FFCA28 !important; }

.md-amber-400-bg {
  background-color: #FFCA28 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-400-fg {
  color: #FFCA28 !important; }

.amber-400-border {
  border-color: #FFCA28 !important; }

.amber-400-border-top {
  border-top-color: #FFCA28 !important; }

.amber-400-border-right {
  border-right-color: #FFCA28 !important; }

.amber-400-border-bottom {
  border-bottom-color: #FFCA28 !important; }

.amber-400-border-left {
  border-left-color: #FFCA28 !important; }

.amber-500-bg {
  background-color: #FFC107 !important; }

.md-amber-500-bg {
  background-color: #FFC107 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-500-fg {
  color: #FFC107 !important; }

.amber-500-border {
  border-color: #FFC107 !important; }

.amber-500-border-top {
  border-top-color: #FFC107 !important; }

.amber-500-border-right {
  border-right-color: #FFC107 !important; }

.amber-500-border-bottom {
  border-bottom-color: #FFC107 !important; }

.amber-500-border-left {
  border-left-color: #FFC107 !important; }

.md-amber-bg {
  background-color: #FFC107 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-bg {
  background-color: #FFC107 !important; }

.amber-fg {
  color: #FFC107 !important; }

.amber-border {
  border-color: #FFC107 !important; }

.amber-border-top {
  border-top-color: #FFC107 !important; }

.amber-border-right {
  border-right-color: #FFC107 !important; }

.amber-border-bottom {
  border-bottom-color: #FFC107 !important; }

.amber-border-left {
  border-left-color: #FFC107 !important; }

.amber-600-bg {
  background-color: #FFB300 !important; }

.md-amber-600-bg {
  background-color: #FFB300 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-600-fg {
  color: #FFB300 !important; }

.amber-600-border {
  border-color: #FFB300 !important; }

.amber-600-border-top {
  border-top-color: #FFB300 !important; }

.amber-600-border-right {
  border-right-color: #FFB300 !important; }

.amber-600-border-bottom {
  border-bottom-color: #FFB300 !important; }

.amber-600-border-left {
  border-left-color: #FFB300 !important; }

.amber-700-bg {
  background-color: #FFA000 !important; }

.md-amber-700-bg {
  background-color: #FFA000 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-700-fg {
  color: #FFA000 !important; }

.amber-700-border {
  border-color: #FFA000 !important; }

.amber-700-border-top {
  border-top-color: #FFA000 !important; }

.amber-700-border-right {
  border-right-color: #FFA000 !important; }

.amber-700-border-bottom {
  border-bottom-color: #FFA000 !important; }

.amber-700-border-left {
  border-left-color: #FFA000 !important; }

.amber-800-bg {
  background-color: #FF8F00 !important; }

.md-amber-800-bg {
  background-color: #FF8F00 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-800-fg {
  color: #FF8F00 !important; }

.amber-800-border {
  border-color: #FF8F00 !important; }

.amber-800-border-top {
  border-top-color: #FF8F00 !important; }

.amber-800-border-right {
  border-right-color: #FF8F00 !important; }

.amber-800-border-bottom {
  border-bottom-color: #FF8F00 !important; }

.amber-800-border-left {
  border-left-color: #FF8F00 !important; }

.amber-900-bg {
  background-color: #FF6F00 !important; }

.md-amber-900-bg {
  background-color: #FF6F00 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-900-fg {
  color: #FF6F00 !important; }

.amber-900-border {
  border-color: #FF6F00 !important; }

.amber-900-border-top {
  border-top-color: #FF6F00 !important; }

.amber-900-border-right {
  border-right-color: #FF6F00 !important; }

.amber-900-border-bottom {
  border-bottom-color: #FF6F00 !important; }

.amber-900-border-left {
  border-left-color: #FF6F00 !important; }

.amber-A100-bg {
  background-color: #FFE57F !important; }

.md-amber-A100-bg {
  background-color: #FFE57F !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-A100-fg {
  color: #FFE57F !important; }

.amber-A100-border {
  border-color: #FFE57F !important; }

.amber-A100-border-top {
  border-top-color: #FFE57F !important; }

.amber-A100-border-right {
  border-right-color: #FFE57F !important; }

.amber-A100-border-bottom {
  border-bottom-color: #FFE57F !important; }

.amber-A100-border-left {
  border-left-color: #FFE57F !important; }

.amber-A200-bg {
  background-color: #FFD740 !important; }

.md-amber-A200-bg {
  background-color: #FFD740 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-A200-fg {
  color: #FFD740 !important; }

.amber-A200-border {
  border-color: #FFD740 !important; }

.amber-A200-border-top {
  border-top-color: #FFD740 !important; }

.amber-A200-border-right {
  border-right-color: #FFD740 !important; }

.amber-A200-border-bottom {
  border-bottom-color: #FFD740 !important; }

.amber-A200-border-left {
  border-left-color: #FFD740 !important; }

.amber-A400-bg {
  background-color: #FFC400 !important; }

.md-amber-A400-bg {
  background-color: #FFC400 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-A400-fg {
  color: #FFC400 !important; }

.amber-A400-border {
  border-color: #FFC400 !important; }

.amber-A400-border-top {
  border-top-color: #FFC400 !important; }

.amber-A400-border-right {
  border-right-color: #FFC400 !important; }

.amber-A400-border-bottom {
  border-bottom-color: #FFC400 !important; }

.amber-A400-border-left {
  border-left-color: #FFC400 !important; }

.amber-A700-bg {
  background-color: #FFAB00 !important; }

.md-amber-A700-bg {
  background-color: #FFAB00 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-A700-fg {
  color: #FFAB00 !important; }

.amber-A700-border {
  border-color: #FFAB00 !important; }

.amber-A700-border-top {
  border-top-color: #FFAB00 !important; }

.amber-A700-border-right {
  border-right-color: #FFAB00 !important; }

.amber-A700-border-bottom {
  border-bottom-color: #FFAB00 !important; }

.amber-A700-border-left {
  border-left-color: #FFAB00 !important; }

.orange-50-bg {
  background-color: #FFF3E0 !important; }

.md-orange-50-bg {
  background-color: #FFF3E0 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-50-fg {
  color: #FFF3E0 !important; }

.orange-50-border {
  border-color: #FFF3E0 !important; }

.orange-50-border-top {
  border-top-color: #FFF3E0 !important; }

.orange-50-border-right {
  border-right-color: #FFF3E0 !important; }

.orange-50-border-bottom {
  border-bottom-color: #FFF3E0 !important; }

.orange-50-border-left {
  border-left-color: #FFF3E0 !important; }

.orange-100-bg {
  background-color: #FFE0B2 !important; }

.md-orange-100-bg {
  background-color: #FFE0B2 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-100-fg {
  color: #FFE0B2 !important; }

.orange-100-border {
  border-color: #FFE0B2 !important; }

.orange-100-border-top {
  border-top-color: #FFE0B2 !important; }

.orange-100-border-right {
  border-right-color: #FFE0B2 !important; }

.orange-100-border-bottom {
  border-bottom-color: #FFE0B2 !important; }

.orange-100-border-left {
  border-left-color: #FFE0B2 !important; }

.orange-200-bg {
  background-color: #FFCC80 !important; }

.md-orange-200-bg {
  background-color: #FFCC80 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-200-fg {
  color: #FFCC80 !important; }

.orange-200-border {
  border-color: #FFCC80 !important; }

.orange-200-border-top {
  border-top-color: #FFCC80 !important; }

.orange-200-border-right {
  border-right-color: #FFCC80 !important; }

.orange-200-border-bottom {
  border-bottom-color: #FFCC80 !important; }

.orange-200-border-left {
  border-left-color: #FFCC80 !important; }

.orange-300-bg {
  background-color: #FFB74D !important; }

.md-orange-300-bg {
  background-color: #FFB74D !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-300-fg {
  color: #FFB74D !important; }

.orange-300-border {
  border-color: #FFB74D !important; }

.orange-300-border-top {
  border-top-color: #FFB74D !important; }

.orange-300-border-right {
  border-right-color: #FFB74D !important; }

.orange-300-border-bottom {
  border-bottom-color: #FFB74D !important; }

.orange-300-border-left {
  border-left-color: #FFB74D !important; }

.orange-400-bg {
  background-color: #FFA726 !important; }

.md-orange-400-bg {
  background-color: #FFA726 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-400-fg {
  color: #FFA726 !important; }

.orange-400-border {
  border-color: #FFA726 !important; }

.orange-400-border-top {
  border-top-color: #FFA726 !important; }

.orange-400-border-right {
  border-right-color: #FFA726 !important; }

.orange-400-border-bottom {
  border-bottom-color: #FFA726 !important; }

.orange-400-border-left {
  border-left-color: #FFA726 !important; }

.orange-500-bg {
  background-color: #FF9800 !important; }

.md-orange-500-bg {
  background-color: #FF9800 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-500-fg {
  color: #FF9800 !important; }

.orange-500-border {
  border-color: #FF9800 !important; }

.orange-500-border-top {
  border-top-color: #FF9800 !important; }

.orange-500-border-right {
  border-right-color: #FF9800 !important; }

.orange-500-border-bottom {
  border-bottom-color: #FF9800 !important; }

.orange-500-border-left {
  border-left-color: #FF9800 !important; }

.md-orange-bg {
  background-color: #FF9800 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-bg {
  background-color: #FF9800 !important; }

.orange-fg {
  color: #FF9800 !important; }

.orange-border {
  border-color: #FF9800 !important; }

.orange-border-top {
  border-top-color: #FF9800 !important; }

.orange-border-right {
  border-right-color: #FF9800 !important; }

.orange-border-bottom {
  border-bottom-color: #FF9800 !important; }

.orange-border-left {
  border-left-color: #FF9800 !important; }

.orange-600-bg {
  background-color: #FB8C00 !important; }

.md-orange-600-bg {
  background-color: #FB8C00 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-600-fg {
  color: #FB8C00 !important; }

.orange-600-border {
  border-color: #FB8C00 !important; }

.orange-600-border-top {
  border-top-color: #FB8C00 !important; }

.orange-600-border-right {
  border-right-color: #FB8C00 !important; }

.orange-600-border-bottom {
  border-bottom-color: #FB8C00 !important; }

.orange-600-border-left {
  border-left-color: #FB8C00 !important; }

.orange-700-bg {
  background-color: #F57C00 !important; }

.md-orange-700-bg {
  background-color: #F57C00 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-700-fg {
  color: #F57C00 !important; }

.orange-700-border {
  border-color: #F57C00 !important; }

.orange-700-border-top {
  border-top-color: #F57C00 !important; }

.orange-700-border-right {
  border-right-color: #F57C00 !important; }

.orange-700-border-bottom {
  border-bottom-color: #F57C00 !important; }

.orange-700-border-left {
  border-left-color: #F57C00 !important; }

.orange-800-bg {
  background-color: #EF6C00 !important; }

.md-orange-800-bg {
  background-color: #EF6C00 !important;
  color: white; }

.orange-800-fg {
  color: #EF6C00 !important; }

.orange-800-border {
  border-color: #EF6C00 !important; }

.orange-800-border-top {
  border-top-color: #EF6C00 !important; }

.orange-800-border-right {
  border-right-color: #EF6C00 !important; }

.orange-800-border-bottom {
  border-bottom-color: #EF6C00 !important; }

.orange-800-border-left {
  border-left-color: #EF6C00 !important; }

.orange-900-bg {
  background-color: #E65100 !important; }

.md-orange-900-bg {
  background-color: #E65100 !important;
  color: white; }

.orange-900-fg {
  color: #E65100 !important; }

.orange-900-border {
  border-color: #E65100 !important; }

.orange-900-border-top {
  border-top-color: #E65100 !important; }

.orange-900-border-right {
  border-right-color: #E65100 !important; }

.orange-900-border-bottom {
  border-bottom-color: #E65100 !important; }

.orange-900-border-left {
  border-left-color: #E65100 !important; }

.orange-A100-bg {
  background-color: #FFD180 !important; }

.md-orange-A100-bg {
  background-color: #FFD180 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-A100-fg {
  color: #FFD180 !important; }

.orange-A100-border {
  border-color: #FFD180 !important; }

.orange-A100-border-top {
  border-top-color: #FFD180 !important; }

.orange-A100-border-right {
  border-right-color: #FFD180 !important; }

.orange-A100-border-bottom {
  border-bottom-color: #FFD180 !important; }

.orange-A100-border-left {
  border-left-color: #FFD180 !important; }

.orange-A200-bg {
  background-color: #FFAB40 !important; }

.md-orange-A200-bg {
  background-color: #FFAB40 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-A200-fg {
  color: #FFAB40 !important; }

.orange-A200-border {
  border-color: #FFAB40 !important; }

.orange-A200-border-top {
  border-top-color: #FFAB40 !important; }

.orange-A200-border-right {
  border-right-color: #FFAB40 !important; }

.orange-A200-border-bottom {
  border-bottom-color: #FFAB40 !important; }

.orange-A200-border-left {
  border-left-color: #FFAB40 !important; }

.orange-A400-bg {
  background-color: #FF9100 !important; }

.md-orange-A400-bg {
  background-color: #FF9100 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-A400-fg {
  color: #FF9100 !important; }

.orange-A400-border {
  border-color: #FF9100 !important; }

.orange-A400-border-top {
  border-top-color: #FF9100 !important; }

.orange-A400-border-right {
  border-right-color: #FF9100 !important; }

.orange-A400-border-bottom {
  border-bottom-color: #FF9100 !important; }

.orange-A400-border-left {
  border-left-color: #FF9100 !important; }

.orange-A700-bg {
  background-color: #FF6D00 !important; }

.md-orange-A700-bg {
  background-color: #FF6D00 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-A700-fg {
  color: #FF6D00 !important; }

.orange-A700-border {
  border-color: #FF6D00 !important; }

.orange-A700-border-top {
  border-top-color: #FF6D00 !important; }

.orange-A700-border-right {
  border-right-color: #FF6D00 !important; }

.orange-A700-border-bottom {
  border-bottom-color: #FF6D00 !important; }

.orange-A700-border-left {
  border-left-color: #FF6D00 !important; }

.deep-orange-50-bg {
  background-color: #FBE9E7 !important; }

.md-deep-orange-50-bg {
  background-color: #FBE9E7 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-50-fg {
  color: #FBE9E7 !important; }

.deep-orange-50-border {
  border-color: #FBE9E7 !important; }

.deep-orange-50-border-top {
  border-top-color: #FBE9E7 !important; }

.deep-orange-50-border-right {
  border-right-color: #FBE9E7 !important; }

.deep-orange-50-border-bottom {
  border-bottom-color: #FBE9E7 !important; }

.deep-orange-50-border-left {
  border-left-color: #FBE9E7 !important; }

.deep-orange-100-bg {
  background-color: #FFCCBC !important; }

.md-deep-orange-100-bg {
  background-color: #FFCCBC !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-100-fg {
  color: #FFCCBC !important; }

.deep-orange-100-border {
  border-color: #FFCCBC !important; }

.deep-orange-100-border-top {
  border-top-color: #FFCCBC !important; }

.deep-orange-100-border-right {
  border-right-color: #FFCCBC !important; }

.deep-orange-100-border-bottom {
  border-bottom-color: #FFCCBC !important; }

.deep-orange-100-border-left {
  border-left-color: #FFCCBC !important; }

.deep-orange-200-bg {
  background-color: #FFAB91 !important; }

.md-deep-orange-200-bg {
  background-color: #FFAB91 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-200-fg {
  color: #FFAB91 !important; }

.deep-orange-200-border {
  border-color: #FFAB91 !important; }

.deep-orange-200-border-top {
  border-top-color: #FFAB91 !important; }

.deep-orange-200-border-right {
  border-right-color: #FFAB91 !important; }

.deep-orange-200-border-bottom {
  border-bottom-color: #FFAB91 !important; }

.deep-orange-200-border-left {
  border-left-color: #FFAB91 !important; }

.deep-orange-300-bg {
  background-color: #FF8A65 !important; }

.md-deep-orange-300-bg {
  background-color: #FF8A65 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-300-fg {
  color: #FF8A65 !important; }

.deep-orange-300-border {
  border-color: #FF8A65 !important; }

.deep-orange-300-border-top {
  border-top-color: #FF8A65 !important; }

.deep-orange-300-border-right {
  border-right-color: #FF8A65 !important; }

.deep-orange-300-border-bottom {
  border-bottom-color: #FF8A65 !important; }

.deep-orange-300-border-left {
  border-left-color: #FF8A65 !important; }

.deep-orange-400-bg {
  background-color: #FF7043 !important; }

.md-deep-orange-400-bg {
  background-color: #FF7043 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-400-fg {
  color: #FF7043 !important; }

.deep-orange-400-border {
  border-color: #FF7043 !important; }

.deep-orange-400-border-top {
  border-top-color: #FF7043 !important; }

.deep-orange-400-border-right {
  border-right-color: #FF7043 !important; }

.deep-orange-400-border-bottom {
  border-bottom-color: #FF7043 !important; }

.deep-orange-400-border-left {
  border-left-color: #FF7043 !important; }

.deep-orange-500-bg {
  background-color: #FF5722 !important; }

.md-deep-orange-500-bg {
  background-color: #FF5722 !important;
  color: white; }

.deep-orange-500-fg {
  color: #FF5722 !important; }

.deep-orange-500-border {
  border-color: #FF5722 !important; }

.deep-orange-500-border-top {
  border-top-color: #FF5722 !important; }

.deep-orange-500-border-right {
  border-right-color: #FF5722 !important; }

.deep-orange-500-border-bottom {
  border-bottom-color: #FF5722 !important; }

.deep-orange-500-border-left {
  border-left-color: #FF5722 !important; }

.md-deep-orange-bg {
  background-color: #FF5722 !important;
  color: white; }

.deep-orange-bg {
  background-color: #FF5722 !important; }

.deep-orange-fg {
  color: #FF5722 !important; }

.deep-orange-border {
  border-color: #FF5722 !important; }

.deep-orange-border-top {
  border-top-color: #FF5722 !important; }

.deep-orange-border-right {
  border-right-color: #FF5722 !important; }

.deep-orange-border-bottom {
  border-bottom-color: #FF5722 !important; }

.deep-orange-border-left {
  border-left-color: #FF5722 !important; }

.deep-orange-600-bg {
  background-color: #F4511E !important; }

.md-deep-orange-600-bg {
  background-color: #F4511E !important;
  color: white; }

.deep-orange-600-fg {
  color: #F4511E !important; }

.deep-orange-600-border {
  border-color: #F4511E !important; }

.deep-orange-600-border-top {
  border-top-color: #F4511E !important; }

.deep-orange-600-border-right {
  border-right-color: #F4511E !important; }

.deep-orange-600-border-bottom {
  border-bottom-color: #F4511E !important; }

.deep-orange-600-border-left {
  border-left-color: #F4511E !important; }

.deep-orange-700-bg {
  background-color: #E64A19 !important; }

.md-deep-orange-700-bg {
  background-color: #E64A19 !important;
  color: white; }

.deep-orange-700-fg {
  color: #E64A19 !important; }

.deep-orange-700-border {
  border-color: #E64A19 !important; }

.deep-orange-700-border-top {
  border-top-color: #E64A19 !important; }

.deep-orange-700-border-right {
  border-right-color: #E64A19 !important; }

.deep-orange-700-border-bottom {
  border-bottom-color: #E64A19 !important; }

.deep-orange-700-border-left {
  border-left-color: #E64A19 !important; }

.deep-orange-800-bg {
  background-color: #D84315 !important; }

.md-deep-orange-800-bg {
  background-color: #D84315 !important;
  color: white; }

.deep-orange-800-fg {
  color: #D84315 !important; }

.deep-orange-800-border {
  border-color: #D84315 !important; }

.deep-orange-800-border-top {
  border-top-color: #D84315 !important; }

.deep-orange-800-border-right {
  border-right-color: #D84315 !important; }

.deep-orange-800-border-bottom {
  border-bottom-color: #D84315 !important; }

.deep-orange-800-border-left {
  border-left-color: #D84315 !important; }

.deep-orange-900-bg {
  background-color: #BF360C !important; }

.md-deep-orange-900-bg {
  background-color: #BF360C !important;
  color: white; }

.deep-orange-900-fg {
  color: #BF360C !important; }

.deep-orange-900-border {
  border-color: #BF360C !important; }

.deep-orange-900-border-top {
  border-top-color: #BF360C !important; }

.deep-orange-900-border-right {
  border-right-color: #BF360C !important; }

.deep-orange-900-border-bottom {
  border-bottom-color: #BF360C !important; }

.deep-orange-900-border-left {
  border-left-color: #BF360C !important; }

.deep-orange-A100-bg {
  background-color: #FF9E80 !important; }

.md-deep-orange-A100-bg {
  background-color: #FF9E80 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-A100-fg {
  color: #FF9E80 !important; }

.deep-orange-A100-border {
  border-color: #FF9E80 !important; }

.deep-orange-A100-border-top {
  border-top-color: #FF9E80 !important; }

.deep-orange-A100-border-right {
  border-right-color: #FF9E80 !important; }

.deep-orange-A100-border-bottom {
  border-bottom-color: #FF9E80 !important; }

.deep-orange-A100-border-left {
  border-left-color: #FF9E80 !important; }

.deep-orange-A200-bg {
  background-color: #FF6E40 !important; }

.md-deep-orange-A200-bg {
  background-color: #FF6E40 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-A200-fg {
  color: #FF6E40 !important; }

.deep-orange-A200-border {
  border-color: #FF6E40 !important; }

.deep-orange-A200-border-top {
  border-top-color: #FF6E40 !important; }

.deep-orange-A200-border-right {
  border-right-color: #FF6E40 !important; }

.deep-orange-A200-border-bottom {
  border-bottom-color: #FF6E40 !important; }

.deep-orange-A200-border-left {
  border-left-color: #FF6E40 !important; }

.deep-orange-A400-bg {
  background-color: #FF3D00 !important; }

.md-deep-orange-A400-bg {
  background-color: #FF3D00 !important;
  color: white; }

.deep-orange-A400-fg {
  color: #FF3D00 !important; }

.deep-orange-A400-border {
  border-color: #FF3D00 !important; }

.deep-orange-A400-border-top {
  border-top-color: #FF3D00 !important; }

.deep-orange-A400-border-right {
  border-right-color: #FF3D00 !important; }

.deep-orange-A400-border-bottom {
  border-bottom-color: #FF3D00 !important; }

.deep-orange-A400-border-left {
  border-left-color: #FF3D00 !important; }

.deep-orange-A700-bg {
  background-color: #DD2C00 !important; }

.md-deep-orange-A700-bg {
  background-color: #DD2C00 !important;
  color: white; }

.deep-orange-A700-fg {
  color: #DD2C00 !important; }

.deep-orange-A700-border {
  border-color: #DD2C00 !important; }

.deep-orange-A700-border-top {
  border-top-color: #DD2C00 !important; }

.deep-orange-A700-border-right {
  border-right-color: #DD2C00 !important; }

.deep-orange-A700-border-bottom {
  border-bottom-color: #DD2C00 !important; }

.deep-orange-A700-border-left {
  border-left-color: #DD2C00 !important; }

.brown-50-bg {
  background-color: #EFEBE9 !important; }

.md-brown-50-bg {
  background-color: #EFEBE9 !important;
  color: rgba(0, 0, 0, 0.87); }

.brown-50-fg {
  color: #EFEBE9 !important; }

.brown-50-border {
  border-color: #EFEBE9 !important; }

.brown-50-border-top {
  border-top-color: #EFEBE9 !important; }

.brown-50-border-right {
  border-right-color: #EFEBE9 !important; }

.brown-50-border-bottom {
  border-bottom-color: #EFEBE9 !important; }

.brown-50-border-left {
  border-left-color: #EFEBE9 !important; }

.brown-100-bg {
  background-color: #D7CCC8 !important; }

.md-brown-100-bg {
  background-color: #D7CCC8 !important;
  color: rgba(0, 0, 0, 0.87); }

.brown-100-fg {
  color: #D7CCC8 !important; }

.brown-100-border {
  border-color: #D7CCC8 !important; }

.brown-100-border-top {
  border-top-color: #D7CCC8 !important; }

.brown-100-border-right {
  border-right-color: #D7CCC8 !important; }

.brown-100-border-bottom {
  border-bottom-color: #D7CCC8 !important; }

.brown-100-border-left {
  border-left-color: #D7CCC8 !important; }

.brown-200-bg {
  background-color: #BCAAA4 !important; }

.md-brown-200-bg {
  background-color: #BCAAA4 !important;
  color: rgba(0, 0, 0, 0.87); }

.brown-200-fg {
  color: #BCAAA4 !important; }

.brown-200-border {
  border-color: #BCAAA4 !important; }

.brown-200-border-top {
  border-top-color: #BCAAA4 !important; }

.brown-200-border-right {
  border-right-color: #BCAAA4 !important; }

.brown-200-border-bottom {
  border-bottom-color: #BCAAA4 !important; }

.brown-200-border-left {
  border-left-color: #BCAAA4 !important; }

.brown-300-bg {
  background-color: #A1887F !important; }

.md-brown-300-bg {
  background-color: #A1887F !important;
  color: white; }

.brown-300-fg {
  color: #A1887F !important; }

.brown-300-border {
  border-color: #A1887F !important; }

.brown-300-border-top {
  border-top-color: #A1887F !important; }

.brown-300-border-right {
  border-right-color: #A1887F !important; }

.brown-300-border-bottom {
  border-bottom-color: #A1887F !important; }

.brown-300-border-left {
  border-left-color: #A1887F !important; }

.brown-400-bg {
  background-color: #8D6E63 !important; }

.md-brown-400-bg {
  background-color: #8D6E63 !important;
  color: white; }

.brown-400-fg {
  color: #8D6E63 !important; }

.brown-400-border {
  border-color: #8D6E63 !important; }

.brown-400-border-top {
  border-top-color: #8D6E63 !important; }

.brown-400-border-right {
  border-right-color: #8D6E63 !important; }

.brown-400-border-bottom {
  border-bottom-color: #8D6E63 !important; }

.brown-400-border-left {
  border-left-color: #8D6E63 !important; }

.brown-500-bg {
  background-color: #795548 !important; }

.md-brown-500-bg {
  background-color: #795548 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-500-fg {
  color: #795548 !important; }

.brown-500-border {
  border-color: #795548 !important; }

.brown-500-border-top {
  border-top-color: #795548 !important; }

.brown-500-border-right {
  border-right-color: #795548 !important; }

.brown-500-border-bottom {
  border-bottom-color: #795548 !important; }

.brown-500-border-left {
  border-left-color: #795548 !important; }

.md-brown-bg {
  background-color: #795548 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-bg {
  background-color: #795548 !important; }

.brown-fg {
  color: #795548 !important; }

.brown-border {
  border-color: #795548 !important; }

.brown-border-top {
  border-top-color: #795548 !important; }

.brown-border-right {
  border-right-color: #795548 !important; }

.brown-border-bottom {
  border-bottom-color: #795548 !important; }

.brown-border-left {
  border-left-color: #795548 !important; }

.brown-600-bg {
  background-color: #6D4C41 !important; }

.md-brown-600-bg {
  background-color: #6D4C41 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-600-fg {
  color: #6D4C41 !important; }

.brown-600-border {
  border-color: #6D4C41 !important; }

.brown-600-border-top {
  border-top-color: #6D4C41 !important; }

.brown-600-border-right {
  border-right-color: #6D4C41 !important; }

.brown-600-border-bottom {
  border-bottom-color: #6D4C41 !important; }

.brown-600-border-left {
  border-left-color: #6D4C41 !important; }

.brown-700-bg {
  background-color: #5D4037 !important; }

.md-brown-700-bg {
  background-color: #5D4037 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-700-fg {
  color: #5D4037 !important; }

.brown-700-border {
  border-color: #5D4037 !important; }

.brown-700-border-top {
  border-top-color: #5D4037 !important; }

.brown-700-border-right {
  border-right-color: #5D4037 !important; }

.brown-700-border-bottom {
  border-bottom-color: #5D4037 !important; }

.brown-700-border-left {
  border-left-color: #5D4037 !important; }

.brown-800-bg {
  background-color: #4E342E !important; }

.md-brown-800-bg {
  background-color: #4E342E !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-800-fg {
  color: #4E342E !important; }

.brown-800-border {
  border-color: #4E342E !important; }

.brown-800-border-top {
  border-top-color: #4E342E !important; }

.brown-800-border-right {
  border-right-color: #4E342E !important; }

.brown-800-border-bottom {
  border-bottom-color: #4E342E !important; }

.brown-800-border-left {
  border-left-color: #4E342E !important; }

.brown-900-bg {
  background-color: #3E2723 !important; }

.md-brown-900-bg {
  background-color: #3E2723 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-900-fg {
  color: #3E2723 !important; }

.brown-900-border {
  border-color: #3E2723 !important; }

.brown-900-border-top {
  border-top-color: #3E2723 !important; }

.brown-900-border-right {
  border-right-color: #3E2723 !important; }

.brown-900-border-bottom {
  border-bottom-color: #3E2723 !important; }

.brown-900-border-left {
  border-left-color: #3E2723 !important; }

.brown-A100-bg {
  background-color: #D7CCC8 !important; }

.md-brown-A100-bg {
  background-color: #D7CCC8 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-A100-fg {
  color: #D7CCC8 !important; }

.brown-A100-border {
  border-color: #D7CCC8 !important; }

.brown-A100-border-top {
  border-top-color: #D7CCC8 !important; }

.brown-A100-border-right {
  border-right-color: #D7CCC8 !important; }

.brown-A100-border-bottom {
  border-bottom-color: #D7CCC8 !important; }

.brown-A100-border-left {
  border-left-color: #D7CCC8 !important; }

.brown-A200-bg {
  background-color: #BCAAA4 !important; }

.md-brown-A200-bg {
  background-color: #BCAAA4 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-A200-fg {
  color: #BCAAA4 !important; }

.brown-A200-border {
  border-color: #BCAAA4 !important; }

.brown-A200-border-top {
  border-top-color: #BCAAA4 !important; }

.brown-A200-border-right {
  border-right-color: #BCAAA4 !important; }

.brown-A200-border-bottom {
  border-bottom-color: #BCAAA4 !important; }

.brown-A200-border-left {
  border-left-color: #BCAAA4 !important; }

.brown-A400-bg {
  background-color: #8D6E63 !important; }

.md-brown-A400-bg {
  background-color: #8D6E63 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-A400-fg {
  color: #8D6E63 !important; }

.brown-A400-border {
  border-color: #8D6E63 !important; }

.brown-A400-border-top {
  border-top-color: #8D6E63 !important; }

.brown-A400-border-right {
  border-right-color: #8D6E63 !important; }

.brown-A400-border-bottom {
  border-bottom-color: #8D6E63 !important; }

.brown-A400-border-left {
  border-left-color: #8D6E63 !important; }

.brown-A700-bg {
  background-color: #5D4037 !important; }

.md-brown-A700-bg {
  background-color: #5D4037 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-A700-fg {
  color: #5D4037 !important; }

.brown-A700-border {
  border-color: #5D4037 !important; }

.brown-A700-border-top {
  border-top-color: #5D4037 !important; }

.brown-A700-border-right {
  border-right-color: #5D4037 !important; }

.brown-A700-border-bottom {
  border-bottom-color: #5D4037 !important; }

.brown-A700-border-left {
  border-left-color: #5D4037 !important; }

.grey-50-bg {
  background-color: #FAFAFA !important; }

.md-grey-50-bg {
  background-color: #FAFAFA !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-50-fg {
  color: #FAFAFA !important; }

.grey-50-border {
  border-color: #FAFAFA !important; }

.grey-50-border-top {
  border-top-color: #FAFAFA !important; }

.grey-50-border-right {
  border-right-color: #FAFAFA !important; }

.grey-50-border-bottom {
  border-bottom-color: #FAFAFA !important; }

.grey-50-border-left {
  border-left-color: #FAFAFA !important; }

.grey-100-bg {
  background-color: #F5F5F5 !important; }

.md-grey-100-bg {
  background-color: #F5F5F5 !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-100-fg {
  color: #F5F5F5 !important; }

.grey-100-border {
  border-color: #F5F5F5 !important; }

.grey-100-border-top {
  border-top-color: #F5F5F5 !important; }

.grey-100-border-right {
  border-right-color: #F5F5F5 !important; }

.grey-100-border-bottom {
  border-bottom-color: #F5F5F5 !important; }

.grey-100-border-left {
  border-left-color: #F5F5F5 !important; }

.grey-200-bg {
  background-color: #EEEEEE !important; }

.md-grey-200-bg {
  background-color: #EEEEEE !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-200-fg {
  color: #EEEEEE !important; }

.grey-200-border {
  border-color: #EEEEEE !important; }

.grey-200-border-top {
  border-top-color: #EEEEEE !important; }

.grey-200-border-right {
  border-right-color: #EEEEEE !important; }

.grey-200-border-bottom {
  border-bottom-color: #EEEEEE !important; }

.grey-200-border-left {
  border-left-color: #EEEEEE !important; }

.grey-300-bg {
  background-color: #E0E0E0 !important; }

.md-grey-300-bg {
  background-color: #E0E0E0 !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-300-fg {
  color: #E0E0E0 !important; }

.grey-300-border {
  border-color: #E0E0E0 !important; }

.grey-300-border-top {
  border-top-color: #E0E0E0 !important; }

.grey-300-border-right {
  border-right-color: #E0E0E0 !important; }

.grey-300-border-bottom {
  border-bottom-color: #E0E0E0 !important; }

.grey-300-border-left {
  border-left-color: #E0E0E0 !important; }

.grey-400-bg {
  background-color: #BDBDBD !important; }

.md-grey-400-bg {
  background-color: #BDBDBD !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-400-fg {
  color: #BDBDBD !important; }

.grey-400-border {
  border-color: #BDBDBD !important; }

.grey-400-border-top {
  border-top-color: #BDBDBD !important; }

.grey-400-border-right {
  border-right-color: #BDBDBD !important; }

.grey-400-border-bottom {
  border-bottom-color: #BDBDBD !important; }

.grey-400-border-left {
  border-left-color: #BDBDBD !important; }

.grey-500-bg {
  background-color: #9E9E9E !important; }

.md-grey-500-bg {
  background-color: #9E9E9E !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-500-fg {
  color: #9E9E9E !important; }

.grey-500-border {
  border-color: #9E9E9E !important; }

.grey-500-border-top {
  border-top-color: #9E9E9E !important; }

.grey-500-border-right {
  border-right-color: #9E9E9E !important; }

.grey-500-border-bottom {
  border-bottom-color: #9E9E9E !important; }

.grey-500-border-left {
  border-left-color: #9E9E9E !important; }

.md-grey-bg {
  background-color: #9E9E9E !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-bg {
  background-color: #9E9E9E !important; }

.grey-fg {
  color: #9E9E9E !important; }

.grey-border {
  border-color: #9E9E9E !important; }

.grey-border-top {
  border-top-color: #9E9E9E !important; }

.grey-border-right {
  border-right-color: #9E9E9E !important; }

.grey-border-bottom {
  border-bottom-color: #9E9E9E !important; }

.grey-border-left {
  border-left-color: #9E9E9E !important; }

.grey-600-bg {
  background-color: #757575 !important; }

.md-grey-600-bg {
  background-color: #757575 !important;
  color: rgba(255, 255, 255, 0.87); }

.grey-600-fg {
  color: #757575 !important; }

.grey-600-border {
  border-color: #757575 !important; }

.grey-600-border-top {
  border-top-color: #757575 !important; }

.grey-600-border-right {
  border-right-color: #757575 !important; }

.grey-600-border-bottom {
  border-bottom-color: #757575 !important; }

.grey-600-border-left {
  border-left-color: #757575 !important; }

.grey-700-bg {
  background-color: #616161 !important; }

.md-grey-700-bg {
  background-color: #616161 !important;
  color: rgba(255, 255, 255, 0.87); }

.grey-700-fg {
  color: #616161 !important; }

.grey-700-border {
  border-color: #616161 !important; }

.grey-700-border-top {
  border-top-color: #616161 !important; }

.grey-700-border-right {
  border-right-color: #616161 !important; }

.grey-700-border-bottom {
  border-bottom-color: #616161 !important; }

.grey-700-border-left {
  border-left-color: #616161 !important; }

.grey-800-bg {
  background-color: #424242 !important; }

.md-grey-800-bg {
  background-color: #424242 !important;
  color: rgba(255, 255, 255, 0.87); }

.grey-800-fg {
  color: #424242 !important; }

.grey-800-border {
  border-color: #424242 !important; }

.grey-800-border-top {
  border-top-color: #424242 !important; }

.grey-800-border-right {
  border-right-color: #424242 !important; }

.grey-800-border-bottom {
  border-bottom-color: #424242 !important; }

.grey-800-border-left {
  border-left-color: #424242 !important; }

.grey-900-bg {
  background-color: #212121 !important; }

.md-grey-900-bg {
  background-color: #212121 !important;
  color: rgba(255, 255, 255, 0.87); }

.grey-900-fg {
  color: #212121 !important; }

.grey-900-border {
  border-color: #212121 !important; }

.grey-900-border-top {
  border-top-color: #212121 !important; }

.grey-900-border-right {
  border-right-color: #212121 !important; }

.grey-900-border-bottom {
  border-bottom-color: #212121 !important; }

.grey-900-border-left {
  border-left-color: #212121 !important; }

.grey-1000-bg {
  background-color: #000000 !important; }

.md-grey-1000-bg {
  background-color: #000000 !important; }

.grey-1000-fg {
  color: #000000 !important; }

.grey-1000-border {
  border-color: #000000 !important; }

.grey-1000-border-top {
  border-top-color: #000000 !important; }

.grey-1000-border-right {
  border-right-color: #000000 !important; }

.grey-1000-border-bottom {
  border-bottom-color: #000000 !important; }

.grey-1000-border-left {
  border-left-color: #000000 !important; }

.grey-A100-bg {
  background-color: #FFFFFF !important; }

.md-grey-A100-bg {
  background-color: #FFFFFF !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-A100-fg {
  color: #FFFFFF !important; }

.grey-A100-border {
  border-color: #FFFFFF !important; }

.grey-A100-border-top {
  border-top-color: #FFFFFF !important; }

.grey-A100-border-right {
  border-right-color: #FFFFFF !important; }

.grey-A100-border-bottom {
  border-bottom-color: #FFFFFF !important; }

.grey-A100-border-left {
  border-left-color: #FFFFFF !important; }

.grey-A200-bg {
  background-color: #EEEEEE !important; }

.md-grey-A200-bg {
  background-color: #EEEEEE !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-A200-fg {
  color: #EEEEEE !important; }

.grey-A200-border {
  border-color: #EEEEEE !important; }

.grey-A200-border-top {
  border-top-color: #EEEEEE !important; }

.grey-A200-border-right {
  border-right-color: #EEEEEE !important; }

.grey-A200-border-bottom {
  border-bottom-color: #EEEEEE !important; }

.grey-A200-border-left {
  border-left-color: #EEEEEE !important; }

.grey-A400-bg {
  background-color: #BDBDBD !important; }

.md-grey-A400-bg {
  background-color: #BDBDBD !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-A400-fg {
  color: #BDBDBD !important; }

.grey-A400-border {
  border-color: #BDBDBD !important; }

.grey-A400-border-top {
  border-top-color: #BDBDBD !important; }

.grey-A400-border-right {
  border-right-color: #BDBDBD !important; }

.grey-A400-border-bottom {
  border-bottom-color: #BDBDBD !important; }

.grey-A400-border-left {
  border-left-color: #BDBDBD !important; }

.grey-A700-bg {
  background-color: #616161 !important; }

.md-grey-A700-bg {
  background-color: #616161 !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-A700-fg {
  color: #616161 !important; }

.grey-A700-border {
  border-color: #616161 !important; }

.grey-A700-border-top {
  border-top-color: #616161 !important; }

.grey-A700-border-right {
  border-right-color: #616161 !important; }

.grey-A700-border-bottom {
  border-bottom-color: #616161 !important; }

.grey-A700-border-left {
  border-left-color: #616161 !important; }

.blue-grey-50-bg {
  background-color: #ECEFF1 !important; }

.md-blue-grey-50-bg {
  background-color: #ECEFF1 !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-grey-50-fg {
  color: #ECEFF1 !important; }

.blue-grey-50-border {
  border-color: #ECEFF1 !important; }

.blue-grey-50-border-top {
  border-top-color: #ECEFF1 !important; }

.blue-grey-50-border-right {
  border-right-color: #ECEFF1 !important; }

.blue-grey-50-border-bottom {
  border-bottom-color: #ECEFF1 !important; }

.blue-grey-50-border-left {
  border-left-color: #ECEFF1 !important; }

.blue-grey-100-bg {
  background-color: #CFD8DC !important; }

.md-blue-grey-100-bg {
  background-color: #CFD8DC !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-grey-100-fg {
  color: #CFD8DC !important; }

.blue-grey-100-border {
  border-color: #CFD8DC !important; }

.blue-grey-100-border-top {
  border-top-color: #CFD8DC !important; }

.blue-grey-100-border-right {
  border-right-color: #CFD8DC !important; }

.blue-grey-100-border-bottom {
  border-bottom-color: #CFD8DC !important; }

.blue-grey-100-border-left {
  border-left-color: #CFD8DC !important; }

.blue-grey-200-bg {
  background-color: #B0BEC5 !important; }

.md-blue-grey-200-bg {
  background-color: #B0BEC5 !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-grey-200-fg {
  color: #B0BEC5 !important; }

.blue-grey-200-border {
  border-color: #B0BEC5 !important; }

.blue-grey-200-border-top {
  border-top-color: #B0BEC5 !important; }

.blue-grey-200-border-right {
  border-right-color: #B0BEC5 !important; }

.blue-grey-200-border-bottom {
  border-bottom-color: #B0BEC5 !important; }

.blue-grey-200-border-left {
  border-left-color: #B0BEC5 !important; }

.blue-grey-300-bg {
  background-color: #90A4AE !important; }

.md-blue-grey-300-bg {
  background-color: #90A4AE !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-grey-300-fg {
  color: #90A4AE !important; }

.blue-grey-300-border {
  border-color: #90A4AE !important; }

.blue-grey-300-border-top {
  border-top-color: #90A4AE !important; }

.blue-grey-300-border-right {
  border-right-color: #90A4AE !important; }

.blue-grey-300-border-bottom {
  border-bottom-color: #90A4AE !important; }

.blue-grey-300-border-left {
  border-left-color: #90A4AE !important; }

.blue-grey-400-bg {
  background-color: #78909C !important; }

.md-blue-grey-400-bg {
  background-color: #78909C !important;
  color: white; }

.blue-grey-400-fg {
  color: #78909C !important; }

.blue-grey-400-border {
  border-color: #78909C !important; }

.blue-grey-400-border-top {
  border-top-color: #78909C !important; }

.blue-grey-400-border-right {
  border-right-color: #78909C !important; }

.blue-grey-400-border-bottom {
  border-bottom-color: #78909C !important; }

.blue-grey-400-border-left {
  border-left-color: #78909C !important; }

.blue-grey-500-bg {
  background-color: #607D8B !important; }

.md-blue-grey-500-bg {
  background-color: #607D8B !important;
  color: white; }

.blue-grey-500-fg {
  color: #607D8B !important; }

.blue-grey-500-border {
  border-color: #607D8B !important; }

.blue-grey-500-border-top {
  border-top-color: #607D8B !important; }

.blue-grey-500-border-right {
  border-right-color: #607D8B !important; }

.blue-grey-500-border-bottom {
  border-bottom-color: #607D8B !important; }

.blue-grey-500-border-left {
  border-left-color: #607D8B !important; }

.md-blue-grey-bg {
  background-color: #607D8B !important;
  color: white; }

.blue-grey-bg {
  background-color: #607D8B !important; }

.blue-grey-fg {
  color: #607D8B !important; }

.blue-grey-border {
  border-color: #607D8B !important; }

.blue-grey-border-top {
  border-top-color: #607D8B !important; }

.blue-grey-border-right {
  border-right-color: #607D8B !important; }

.blue-grey-border-bottom {
  border-bottom-color: #607D8B !important; }

.blue-grey-border-left {
  border-left-color: #607D8B !important; }

.blue-grey-600-bg {
  background-color: #546E7A !important; }

.md-blue-grey-600-bg {
  background-color: #546E7A !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-600-fg {
  color: #546E7A !important; }

.blue-grey-600-border {
  border-color: #546E7A !important; }

.blue-grey-600-border-top {
  border-top-color: #546E7A !important; }

.blue-grey-600-border-right {
  border-right-color: #546E7A !important; }

.blue-grey-600-border-bottom {
  border-bottom-color: #546E7A !important; }

.blue-grey-600-border-left {
  border-left-color: #546E7A !important; }

.blue-grey-700-bg {
  background-color: #455A64 !important; }

.md-blue-grey-700-bg {
  background-color: #455A64 !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-700-fg {
  color: #455A64 !important; }

.blue-grey-700-border {
  border-color: #455A64 !important; }

.blue-grey-700-border-top {
  border-top-color: #455A64 !important; }

.blue-grey-700-border-right {
  border-right-color: #455A64 !important; }

.blue-grey-700-border-bottom {
  border-bottom-color: #455A64 !important; }

.blue-grey-700-border-left {
  border-left-color: #455A64 !important; }

.blue-grey-800-bg {
  background-color: #37474F !important; }

.md-blue-grey-800-bg {
  background-color: #37474F !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-800-fg {
  color: #37474F !important; }

.blue-grey-800-border {
  border-color: #37474F !important; }

.blue-grey-800-border-top {
  border-top-color: #37474F !important; }

.blue-grey-800-border-right {
  border-right-color: #37474F !important; }

.blue-grey-800-border-bottom {
  border-bottom-color: #37474F !important; }

.blue-grey-800-border-left {
  border-left-color: #37474F !important; }

.blue-grey-900-bg {
  background-color: #263238 !important; }

.md-blue-grey-900-bg {
  background-color: #263238 !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-900-fg {
  color: #263238 !important; }

.blue-grey-900-border {
  border-color: #263238 !important; }

.blue-grey-900-border-top {
  border-top-color: #263238 !important; }

.blue-grey-900-border-right {
  border-right-color: #263238 !important; }

.blue-grey-900-border-bottom {
  border-bottom-color: #263238 !important; }

.blue-grey-900-border-left {
  border-left-color: #263238 !important; }

.blue-grey-A100-bg {
  background-color: #CFD8DC !important; }

.md-blue-grey-A100-bg {
  background-color: #CFD8DC !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-A100-fg {
  color: #CFD8DC !important; }

.blue-grey-A100-border {
  border-color: #CFD8DC !important; }

.blue-grey-A100-border-top {
  border-top-color: #CFD8DC !important; }

.blue-grey-A100-border-right {
  border-right-color: #CFD8DC !important; }

.blue-grey-A100-border-bottom {
  border-bottom-color: #CFD8DC !important; }

.blue-grey-A100-border-left {
  border-left-color: #CFD8DC !important; }

.blue-grey-A200-bg {
  background-color: #B0BEC5 !important; }

.md-blue-grey-A200-bg {
  background-color: #B0BEC5 !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-A200-fg {
  color: #B0BEC5 !important; }

.blue-grey-A200-border {
  border-color: #B0BEC5 !important; }

.blue-grey-A200-border-top {
  border-top-color: #B0BEC5 !important; }

.blue-grey-A200-border-right {
  border-right-color: #B0BEC5 !important; }

.blue-grey-A200-border-bottom {
  border-bottom-color: #B0BEC5 !important; }

.blue-grey-A200-border-left {
  border-left-color: #B0BEC5 !important; }

.blue-grey-A400-bg {
  background-color: #78909C !important; }

.md-blue-grey-A400-bg {
  background-color: #78909C !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-A400-fg {
  color: #78909C !important; }

.blue-grey-A400-border {
  border-color: #78909C !important; }

.blue-grey-A400-border-top {
  border-top-color: #78909C !important; }

.blue-grey-A400-border-right {
  border-right-color: #78909C !important; }

.blue-grey-A400-border-bottom {
  border-bottom-color: #78909C !important; }

.blue-grey-A400-border-left {
  border-left-color: #78909C !important; }

.blue-grey-A700-bg {
  background-color: #455A64 !important; }

.md-blue-grey-A700-bg {
  background-color: #455A64 !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-A700-fg {
  color: #455A64 !important; }

.blue-grey-A700-border {
  border-color: #455A64 !important; }

.blue-grey-A700-border-top {
  border-top-color: #455A64 !important; }

.blue-grey-A700-border-right {
  border-right-color: #455A64 !important; }

.blue-grey-A700-border-bottom {
  border-bottom-color: #455A64 !important; }

.blue-grey-A700-border-left {
  border-left-color: #455A64 !important; }

.white-500-bg {
  background-color: #FFFFFF !important; }

.md-white-500-bg {
  background-color: #FFFFFF !important;
  color: rgba(0, 0, 0, 0.87); }

.white-500-fg {
  color: #FFFFFF !important; }

.white-500-border {
  border-color: #FFFFFF !important; }

.white-500-border-top {
  border-top-color: #FFFFFF !important; }

.white-500-border-right {
  border-right-color: #FFFFFF !important; }

.white-500-border-bottom {
  border-bottom-color: #FFFFFF !important; }

.white-500-border-left {
  border-left-color: #FFFFFF !important; }

.md-white-bg {
  background-color: #FFFFFF !important;
  color: rgba(0, 0, 0, 0.87); }

.white-bg {
  background-color: #FFFFFF !important; }

.white-fg {
  color: #FFFFFF !important; }

.white-border {
  border-color: #FFFFFF !important; }

.white-border-top {
  border-top-color: #FFFFFF !important; }

.white-border-right {
  border-right-color: #FFFFFF !important; }

.white-border-bottom {
  border-bottom-color: #FFFFFF !important; }

.white-border-left {
  border-left-color: #FFFFFF !important; }

.black-500-bg {
  background-color: #000000 !important; }

.md-black-500-bg {
  background-color: #000000 !important;
  color: rgba(255, 255, 255, 0.87); }

.black-500-fg {
  color: #000000 !important; }

.black-500-border {
  border-color: #000000 !important; }

.black-500-border-top {
  border-top-color: #000000 !important; }

.black-500-border-right {
  border-right-color: #000000 !important; }

.black-500-border-bottom {
  border-bottom-color: #000000 !important; }

.black-500-border-left {
  border-left-color: #000000 !important; }

.md-black-bg {
  background-color: #000000 !important;
  color: rgba(255, 255, 255, 0.87); }

.black-bg {
  background-color: #000000 !important; }

.black-fg {
  color: #000000 !important; }

.black-border {
  border-color: #000000 !important; }

.black-border-top {
  border-top-color: #000000 !important; }

.black-border-right {
  border-right-color: #000000 !important; }

.black-border-bottom {
  border-bottom-color: #000000 !important; }

.black-border-left {
  border-left-color: #000000 !important; }

/*----------------------------------------------------------------*/
/*  Icons
/*----------------------------------------------------------------*/
md-icon[md-font-icon],
i {
  font-size: 24px;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  line-height: 24px; }
  md-icon[md-font-icon].s4,
  i.s4 {
    font-size: 4px !important;
    width: 4px !important;
    height: 4px !important;
    min-width: 4px !important;
    min-height: 4px !important;
    line-height: 4px !important; }
  md-icon[md-font-icon].s6,
  i.s6 {
    font-size: 6px !important;
    width: 6px !important;
    height: 6px !important;
    min-width: 6px !important;
    min-height: 6px !important;
    line-height: 6px !important; }
  md-icon[md-font-icon].s8,
  i.s8 {
    font-size: 8px !important;
    width: 8px !important;
    height: 8px !important;
    min-width: 8px !important;
    min-height: 8px !important;
    line-height: 8px !important; }
  md-icon[md-font-icon].s10,
  i.s10 {
    font-size: 10px !important;
    width: 10px !important;
    height: 10px !important;
    min-width: 10px !important;
    min-height: 10px !important;
    line-height: 10px !important; }
  md-icon[md-font-icon].s12,
  i.s12 {
    font-size: 12px !important;
    width: 12px !important;
    height: 12px !important;
    min-width: 12px !important;
    min-height: 12px !important;
    line-height: 12px !important; }
  md-icon[md-font-icon].s14,
  i.s14 {
    font-size: 14px !important;
    width: 14px !important;
    height: 14px !important;
    min-width: 14px !important;
    min-height: 14px !important;
    line-height: 14px !important; }
  md-icon[md-font-icon].s16,
  i.s16 {
    font-size: 16px !important;
    width: 16px !important;
    height: 16px !important;
    min-width: 16px !important;
    min-height: 16px !important;
    line-height: 16px !important; }
  md-icon[md-font-icon].s18,
  i.s18 {
    font-size: 18px !important;
    width: 18px !important;
    height: 18px !important;
    min-width: 18px !important;
    min-height: 18px !important;
    line-height: 18px !important; }
  md-icon[md-font-icon].s20,
  i.s20 {
    font-size: 20px !important;
    width: 20px !important;
    height: 20px !important;
    min-width: 20px !important;
    min-height: 20px !important;
    line-height: 20px !important; }
  md-icon[md-font-icon].s22,
  i.s22 {
    font-size: 22px !important;
    width: 22px !important;
    height: 22px !important;
    min-width: 22px !important;
    min-height: 22px !important;
    line-height: 22px !important; }
  md-icon[md-font-icon].s24,
  i.s24 {
    font-size: 24px !important;
    width: 24px !important;
    height: 24px !important;
    min-width: 24px !important;
    min-height: 24px !important;
    line-height: 24px !important; }
  md-icon[md-font-icon].s26,
  i.s26 {
    font-size: 26px !important;
    width: 26px !important;
    height: 26px !important;
    min-width: 26px !important;
    min-height: 26px !important;
    line-height: 26px !important; }
  md-icon[md-font-icon].s28,
  i.s28 {
    font-size: 28px !important;
    width: 28px !important;
    height: 28px !important;
    min-width: 28px !important;
    min-height: 28px !important;
    line-height: 28px !important; }
  md-icon[md-font-icon].s30,
  i.s30 {
    font-size: 30px !important;
    width: 30px !important;
    height: 30px !important;
    min-width: 30px !important;
    min-height: 30px !important;
    line-height: 30px !important; }
  md-icon[md-font-icon].s32,
  i.s32 {
    font-size: 32px !important;
    width: 32px !important;
    height: 32px !important;
    min-width: 32px !important;
    min-height: 32px !important;
    line-height: 32px !important; }
  md-icon[md-font-icon].s34,
  i.s34 {
    font-size: 34px !important;
    width: 34px !important;
    height: 34px !important;
    min-width: 34px !important;
    min-height: 34px !important;
    line-height: 34px !important; }
  md-icon[md-font-icon].s36,
  i.s36 {
    font-size: 36px !important;
    width: 36px !important;
    height: 36px !important;
    min-width: 36px !important;
    min-height: 36px !important;
    line-height: 36px !important; }
  md-icon[md-font-icon].s38,
  i.s38 {
    font-size: 38px !important;
    width: 38px !important;
    height: 38px !important;
    min-width: 38px !important;
    min-height: 38px !important;
    line-height: 38px !important; }
  md-icon[md-font-icon].s40,
  i.s40 {
    font-size: 40px !important;
    width: 40px !important;
    height: 40px !important;
    min-width: 40px !important;
    min-height: 40px !important;
    line-height: 40px !important; }
  md-icon[md-font-icon].s42,
  i.s42 {
    font-size: 42px !important;
    width: 42px !important;
    height: 42px !important;
    min-width: 42px !important;
    min-height: 42px !important;
    line-height: 42px !important; }
  md-icon[md-font-icon].s44,
  i.s44 {
    font-size: 44px !important;
    width: 44px !important;
    height: 44px !important;
    min-width: 44px !important;
    min-height: 44px !important;
    line-height: 44px !important; }
  md-icon[md-font-icon].s46,
  i.s46 {
    font-size: 46px !important;
    width: 46px !important;
    height: 46px !important;
    min-width: 46px !important;
    min-height: 46px !important;
    line-height: 46px !important; }
  md-icon[md-font-icon].s48,
  i.s48 {
    font-size: 48px !important;
    width: 48px !important;
    height: 48px !important;
    min-width: 48px !important;
    min-height: 48px !important;
    line-height: 48px !important; }
  md-icon[md-font-icon].s50,
  i.s50 {
    font-size: 50px !important;
    width: 50px !important;
    height: 50px !important;
    min-width: 50px !important;
    min-height: 50px !important;
    line-height: 50px !important; }
  md-icon[md-font-icon].s52,
  i.s52 {
    font-size: 52px !important;
    width: 52px !important;
    height: 52px !important;
    min-width: 52px !important;
    min-height: 52px !important;
    line-height: 52px !important; }
  md-icon[md-font-icon].s54,
  i.s54 {
    font-size: 54px !important;
    width: 54px !important;
    height: 54px !important;
    min-width: 54px !important;
    min-height: 54px !important;
    line-height: 54px !important; }
  md-icon[md-font-icon].s56,
  i.s56 {
    font-size: 56px !important;
    width: 56px !important;
    height: 56px !important;
    min-width: 56px !important;
    min-height: 56px !important;
    line-height: 56px !important; }
  md-icon[md-font-icon].s58,
  i.s58 {
    font-size: 58px !important;
    width: 58px !important;
    height: 58px !important;
    min-width: 58px !important;
    min-height: 58px !important;
    line-height: 58px !important; }
  md-icon[md-font-icon].s60,
  i.s60 {
    font-size: 60px !important;
    width: 60px !important;
    height: 60px !important;
    min-width: 60px !important;
    min-height: 60px !important;
    line-height: 60px !important; }
  md-icon[md-font-icon].s62,
  i.s62 {
    font-size: 62px !important;
    width: 62px !important;
    height: 62px !important;
    min-width: 62px !important;
    min-height: 62px !important;
    line-height: 62px !important; }
  md-icon[md-font-icon].s64,
  i.s64 {
    font-size: 64px !important;
    width: 64px !important;
    height: 64px !important;
    min-width: 64px !important;
    min-height: 64px !important;
    line-height: 64px !important; }
  md-icon[md-font-icon].s66,
  i.s66 {
    font-size: 66px !important;
    width: 66px !important;
    height: 66px !important;
    min-width: 66px !important;
    min-height: 66px !important;
    line-height: 66px !important; }
  md-icon[md-font-icon].s68,
  i.s68 {
    font-size: 68px !important;
    width: 68px !important;
    height: 68px !important;
    min-width: 68px !important;
    min-height: 68px !important;
    line-height: 68px !important; }
  md-icon[md-font-icon].s70,
  i.s70 {
    font-size: 70px !important;
    width: 70px !important;
    height: 70px !important;
    min-width: 70px !important;
    min-height: 70px !important;
    line-height: 70px !important; }
  md-icon[md-font-icon].s72,
  i.s72 {
    font-size: 72px !important;
    width: 72px !important;
    height: 72px !important;
    min-width: 72px !important;
    min-height: 72px !important;
    line-height: 72px !important; }
  md-icon[md-font-icon].s74,
  i.s74 {
    font-size: 74px !important;
    width: 74px !important;
    height: 74px !important;
    min-width: 74px !important;
    min-height: 74px !important;
    line-height: 74px !important; }
  md-icon[md-font-icon].s76,
  i.s76 {
    font-size: 76px !important;
    width: 76px !important;
    height: 76px !important;
    min-width: 76px !important;
    min-height: 76px !important;
    line-height: 76px !important; }
  md-icon[md-font-icon].s78,
  i.s78 {
    font-size: 78px !important;
    width: 78px !important;
    height: 78px !important;
    min-width: 78px !important;
    min-height: 78px !important;
    line-height: 78px !important; }
  md-icon[md-font-icon].s80,
  i.s80 {
    font-size: 80px !important;
    width: 80px !important;
    height: 80px !important;
    min-width: 80px !important;
    min-height: 80px !important;
    line-height: 80px !important; }
  md-icon[md-font-icon].s82,
  i.s82 {
    font-size: 82px !important;
    width: 82px !important;
    height: 82px !important;
    min-width: 82px !important;
    min-height: 82px !important;
    line-height: 82px !important; }
  md-icon[md-font-icon].s84,
  i.s84 {
    font-size: 84px !important;
    width: 84px !important;
    height: 84px !important;
    min-width: 84px !important;
    min-height: 84px !important;
    line-height: 84px !important; }
  md-icon[md-font-icon].s86,
  i.s86 {
    font-size: 86px !important;
    width: 86px !important;
    height: 86px !important;
    min-width: 86px !important;
    min-height: 86px !important;
    line-height: 86px !important; }
  md-icon[md-font-icon].s88,
  i.s88 {
    font-size: 88px !important;
    width: 88px !important;
    height: 88px !important;
    min-width: 88px !important;
    min-height: 88px !important;
    line-height: 88px !important; }
  md-icon[md-font-icon].s90,
  i.s90 {
    font-size: 90px !important;
    width: 90px !important;
    height: 90px !important;
    min-width: 90px !important;
    min-height: 90px !important;
    line-height: 90px !important; }
  md-icon[md-font-icon].s92,
  i.s92 {
    font-size: 92px !important;
    width: 92px !important;
    height: 92px !important;
    min-width: 92px !important;
    min-height: 92px !important;
    line-height: 92px !important; }
  md-icon[md-font-icon].s94,
  i.s94 {
    font-size: 94px !important;
    width: 94px !important;
    height: 94px !important;
    min-width: 94px !important;
    min-height: 94px !important;
    line-height: 94px !important; }
  md-icon[md-font-icon].s96,
  i.s96 {
    font-size: 96px !important;
    width: 96px !important;
    height: 96px !important;
    min-width: 96px !important;
    min-height: 96px !important;
    line-height: 96px !important; }
  md-icon[md-font-icon].s98,
  i.s98 {
    font-size: 98px !important;
    width: 98px !important;
    height: 98px !important;
    min-width: 98px !important;
    min-height: 98px !important;
    line-height: 98px !important; }
  md-icon[md-font-icon].s100,
  i.s100 {
    font-size: 100px !important;
    width: 100px !important;
    height: 100px !important;
    min-width: 100px !important;
    min-height: 100px !important;
    line-height: 100px !important; }
  md-icon[md-font-icon].s102,
  i.s102 {
    font-size: 102px !important;
    width: 102px !important;
    height: 102px !important;
    min-width: 102px !important;
    min-height: 102px !important;
    line-height: 102px !important; }
  md-icon[md-font-icon].s104,
  i.s104 {
    font-size: 104px !important;
    width: 104px !important;
    height: 104px !important;
    min-width: 104px !important;
    min-height: 104px !important;
    line-height: 104px !important; }
  md-icon[md-font-icon].s106,
  i.s106 {
    font-size: 106px !important;
    width: 106px !important;
    height: 106px !important;
    min-width: 106px !important;
    min-height: 106px !important;
    line-height: 106px !important; }
  md-icon[md-font-icon].s108,
  i.s108 {
    font-size: 108px !important;
    width: 108px !important;
    height: 108px !important;
    min-width: 108px !important;
    min-height: 108px !important;
    line-height: 108px !important; }
  md-icon[md-font-icon].s110,
  i.s110 {
    font-size: 110px !important;
    width: 110px !important;
    height: 110px !important;
    min-width: 110px !important;
    min-height: 110px !important;
    line-height: 110px !important; }
  md-icon[md-font-icon].s112,
  i.s112 {
    font-size: 112px !important;
    width: 112px !important;
    height: 112px !important;
    min-width: 112px !important;
    min-height: 112px !important;
    line-height: 112px !important; }
  md-icon[md-font-icon].s114,
  i.s114 {
    font-size: 114px !important;
    width: 114px !important;
    height: 114px !important;
    min-width: 114px !important;
    min-height: 114px !important;
    line-height: 114px !important; }
  md-icon[md-font-icon].s116,
  i.s116 {
    font-size: 116px !important;
    width: 116px !important;
    height: 116px !important;
    min-width: 116px !important;
    min-height: 116px !important;
    line-height: 116px !important; }
  md-icon[md-font-icon].s118,
  i.s118 {
    font-size: 118px !important;
    width: 118px !important;
    height: 118px !important;
    min-width: 118px !important;
    min-height: 118px !important;
    line-height: 118px !important; }
  md-icon[md-font-icon].s120,
  i.s120 {
    font-size: 120px !important;
    width: 120px !important;
    height: 120px !important;
    min-width: 120px !important;
    min-height: 120px !important;
    line-height: 120px !important; }
  md-icon[md-font-icon].s122,
  i.s122 {
    font-size: 122px !important;
    width: 122px !important;
    height: 122px !important;
    min-width: 122px !important;
    min-height: 122px !important;
    line-height: 122px !important; }
  md-icon[md-font-icon].s124,
  i.s124 {
    font-size: 124px !important;
    width: 124px !important;
    height: 124px !important;
    min-width: 124px !important;
    min-height: 124px !important;
    line-height: 124px !important; }
  md-icon[md-font-icon].s126,
  i.s126 {
    font-size: 126px !important;
    width: 126px !important;
    height: 126px !important;
    min-width: 126px !important;
    min-height: 126px !important;
    line-height: 126px !important; }
  md-icon[md-font-icon].s128,
  i.s128 {
    font-size: 128px !important;
    width: 128px !important;
    height: 128px !important;
    min-width: 128px !important;
    min-height: 128px !important;
    line-height: 128px !important; }
  md-icon[md-font-icon].s130,
  i.s130 {
    font-size: 130px !important;
    width: 130px !important;
    height: 130px !important;
    min-width: 130px !important;
    min-height: 130px !important;
    line-height: 130px !important; }
  md-icon[md-font-icon].s132,
  i.s132 {
    font-size: 132px !important;
    width: 132px !important;
    height: 132px !important;
    min-width: 132px !important;
    min-height: 132px !important;
    line-height: 132px !important; }
  md-icon[md-font-icon].s134,
  i.s134 {
    font-size: 134px !important;
    width: 134px !important;
    height: 134px !important;
    min-width: 134px !important;
    min-height: 134px !important;
    line-height: 134px !important; }
  md-icon[md-font-icon].s136,
  i.s136 {
    font-size: 136px !important;
    width: 136px !important;
    height: 136px !important;
    min-width: 136px !important;
    min-height: 136px !important;
    line-height: 136px !important; }
  md-icon[md-font-icon].s138,
  i.s138 {
    font-size: 138px !important;
    width: 138px !important;
    height: 138px !important;
    min-width: 138px !important;
    min-height: 138px !important;
    line-height: 138px !important; }
  md-icon[md-font-icon].s140,
  i.s140 {
    font-size: 140px !important;
    width: 140px !important;
    height: 140px !important;
    min-width: 140px !important;
    min-height: 140px !important;
    line-height: 140px !important; }
  md-icon[md-font-icon].s142,
  i.s142 {
    font-size: 142px !important;
    width: 142px !important;
    height: 142px !important;
    min-width: 142px !important;
    min-height: 142px !important;
    line-height: 142px !important; }
  md-icon[md-font-icon].s144,
  i.s144 {
    font-size: 144px !important;
    width: 144px !important;
    height: 144px !important;
    min-width: 144px !important;
    min-height: 144px !important;
    line-height: 144px !important; }
  md-icon[md-font-icon].s146,
  i.s146 {
    font-size: 146px !important;
    width: 146px !important;
    height: 146px !important;
    min-width: 146px !important;
    min-height: 146px !important;
    line-height: 146px !important; }
  md-icon[md-font-icon].s148,
  i.s148 {
    font-size: 148px !important;
    width: 148px !important;
    height: 148px !important;
    min-width: 148px !important;
    min-height: 148px !important;
    line-height: 148px !important; }
  md-icon[md-font-icon].s150,
  i.s150 {
    font-size: 150px !important;
    width: 150px !important;
    height: 150px !important;
    min-width: 150px !important;
    min-height: 150px !important;
    line-height: 150px !important; }
  md-icon[md-font-icon].s152,
  i.s152 {
    font-size: 152px !important;
    width: 152px !important;
    height: 152px !important;
    min-width: 152px !important;
    min-height: 152px !important;
    line-height: 152px !important; }
  md-icon[md-font-icon].s154,
  i.s154 {
    font-size: 154px !important;
    width: 154px !important;
    height: 154px !important;
    min-width: 154px !important;
    min-height: 154px !important;
    line-height: 154px !important; }
  md-icon[md-font-icon].s156,
  i.s156 {
    font-size: 156px !important;
    width: 156px !important;
    height: 156px !important;
    min-width: 156px !important;
    min-height: 156px !important;
    line-height: 156px !important; }
  md-icon[md-font-icon].s158,
  i.s158 {
    font-size: 158px !important;
    width: 158px !important;
    height: 158px !important;
    min-width: 158px !important;
    min-height: 158px !important;
    line-height: 158px !important; }
  md-icon[md-font-icon].s160,
  i.s160 {
    font-size: 160px !important;
    width: 160px !important;
    height: 160px !important;
    min-width: 160px !important;
    min-height: 160px !important;
    line-height: 160px !important; }
  md-icon[md-font-icon].s162,
  i.s162 {
    font-size: 162px !important;
    width: 162px !important;
    height: 162px !important;
    min-width: 162px !important;
    min-height: 162px !important;
    line-height: 162px !important; }
  md-icon[md-font-icon].s164,
  i.s164 {
    font-size: 164px !important;
    width: 164px !important;
    height: 164px !important;
    min-width: 164px !important;
    min-height: 164px !important;
    line-height: 164px !important; }
  md-icon[md-font-icon].s166,
  i.s166 {
    font-size: 166px !important;
    width: 166px !important;
    height: 166px !important;
    min-width: 166px !important;
    min-height: 166px !important;
    line-height: 166px !important; }
  md-icon[md-font-icon].s168,
  i.s168 {
    font-size: 168px !important;
    width: 168px !important;
    height: 168px !important;
    min-width: 168px !important;
    min-height: 168px !important;
    line-height: 168px !important; }
  md-icon[md-font-icon].s170,
  i.s170 {
    font-size: 170px !important;
    width: 170px !important;
    height: 170px !important;
    min-width: 170px !important;
    min-height: 170px !important;
    line-height: 170px !important; }
  md-icon[md-font-icon].s172,
  i.s172 {
    font-size: 172px !important;
    width: 172px !important;
    height: 172px !important;
    min-width: 172px !important;
    min-height: 172px !important;
    line-height: 172px !important; }
  md-icon[md-font-icon].s174,
  i.s174 {
    font-size: 174px !important;
    width: 174px !important;
    height: 174px !important;
    min-width: 174px !important;
    min-height: 174px !important;
    line-height: 174px !important; }
  md-icon[md-font-icon].s176,
  i.s176 {
    font-size: 176px !important;
    width: 176px !important;
    height: 176px !important;
    min-width: 176px !important;
    min-height: 176px !important;
    line-height: 176px !important; }
  md-icon[md-font-icon].s178,
  i.s178 {
    font-size: 178px !important;
    width: 178px !important;
    height: 178px !important;
    min-width: 178px !important;
    min-height: 178px !important;
    line-height: 178px !important; }
  md-icon[md-font-icon].s180,
  i.s180 {
    font-size: 180px !important;
    width: 180px !important;
    height: 180px !important;
    min-width: 180px !important;
    min-height: 180px !important;
    line-height: 180px !important; }
  md-icon[md-font-icon].s182,
  i.s182 {
    font-size: 182px !important;
    width: 182px !important;
    height: 182px !important;
    min-width: 182px !important;
    min-height: 182px !important;
    line-height: 182px !important; }
  md-icon[md-font-icon].s184,
  i.s184 {
    font-size: 184px !important;
    width: 184px !important;
    height: 184px !important;
    min-width: 184px !important;
    min-height: 184px !important;
    line-height: 184px !important; }
  md-icon[md-font-icon].s186,
  i.s186 {
    font-size: 186px !important;
    width: 186px !important;
    height: 186px !important;
    min-width: 186px !important;
    min-height: 186px !important;
    line-height: 186px !important; }
  md-icon[md-font-icon].s188,
  i.s188 {
    font-size: 188px !important;
    width: 188px !important;
    height: 188px !important;
    min-width: 188px !important;
    min-height: 188px !important;
    line-height: 188px !important; }
  md-icon[md-font-icon].s190,
  i.s190 {
    font-size: 190px !important;
    width: 190px !important;
    height: 190px !important;
    min-width: 190px !important;
    min-height: 190px !important;
    line-height: 190px !important; }
  md-icon[md-font-icon].s192,
  i.s192 {
    font-size: 192px !important;
    width: 192px !important;
    height: 192px !important;
    min-width: 192px !important;
    min-height: 192px !important;
    line-height: 192px !important; }
  md-icon[md-font-icon].s194,
  i.s194 {
    font-size: 194px !important;
    width: 194px !important;
    height: 194px !important;
    min-width: 194px !important;
    min-height: 194px !important;
    line-height: 194px !important; }
  md-icon[md-font-icon].s196,
  i.s196 {
    font-size: 196px !important;
    width: 196px !important;
    height: 196px !important;
    min-width: 196px !important;
    min-height: 196px !important;
    line-height: 196px !important; }
  md-icon[md-font-icon].s198,
  i.s198 {
    font-size: 198px !important;
    width: 198px !important;
    height: 198px !important;
    min-width: 198px !important;
    min-height: 198px !important;
    line-height: 198px !important; }
  md-icon[md-font-icon].s200,
  i.s200 {
    font-size: 200px !important;
    width: 200px !important;
    height: 200px !important;
    min-width: 200px !important;
    min-height: 200px !important;
    line-height: 200px !important; }
  md-icon[md-font-icon].s202,
  i.s202 {
    font-size: 202px !important;
    width: 202px !important;
    height: 202px !important;
    min-width: 202px !important;
    min-height: 202px !important;
    line-height: 202px !important; }
  md-icon[md-font-icon].s204,
  i.s204 {
    font-size: 204px !important;
    width: 204px !important;
    height: 204px !important;
    min-width: 204px !important;
    min-height: 204px !important;
    line-height: 204px !important; }
  md-icon[md-font-icon].s206,
  i.s206 {
    font-size: 206px !important;
    width: 206px !important;
    height: 206px !important;
    min-width: 206px !important;
    min-height: 206px !important;
    line-height: 206px !important; }
  md-icon[md-font-icon].s208,
  i.s208 {
    font-size: 208px !important;
    width: 208px !important;
    height: 208px !important;
    min-width: 208px !important;
    min-height: 208px !important;
    line-height: 208px !important; }
  md-icon[md-font-icon].s210,
  i.s210 {
    font-size: 210px !important;
    width: 210px !important;
    height: 210px !important;
    min-width: 210px !important;
    min-height: 210px !important;
    line-height: 210px !important; }
  md-icon[md-font-icon].s212,
  i.s212 {
    font-size: 212px !important;
    width: 212px !important;
    height: 212px !important;
    min-width: 212px !important;
    min-height: 212px !important;
    line-height: 212px !important; }
  md-icon[md-font-icon].s214,
  i.s214 {
    font-size: 214px !important;
    width: 214px !important;
    height: 214px !important;
    min-width: 214px !important;
    min-height: 214px !important;
    line-height: 214px !important; }
  md-icon[md-font-icon].s216,
  i.s216 {
    font-size: 216px !important;
    width: 216px !important;
    height: 216px !important;
    min-width: 216px !important;
    min-height: 216px !important;
    line-height: 216px !important; }
  md-icon[md-font-icon].s218,
  i.s218 {
    font-size: 218px !important;
    width: 218px !important;
    height: 218px !important;
    min-width: 218px !important;
    min-height: 218px !important;
    line-height: 218px !important; }
  md-icon[md-font-icon].s220,
  i.s220 {
    font-size: 220px !important;
    width: 220px !important;
    height: 220px !important;
    min-width: 220px !important;
    min-height: 220px !important;
    line-height: 220px !important; }
  md-icon[md-font-icon].s222,
  i.s222 {
    font-size: 222px !important;
    width: 222px !important;
    height: 222px !important;
    min-width: 222px !important;
    min-height: 222px !important;
    line-height: 222px !important; }
  md-icon[md-font-icon].s224,
  i.s224 {
    font-size: 224px !important;
    width: 224px !important;
    height: 224px !important;
    min-width: 224px !important;
    min-height: 224px !important;
    line-height: 224px !important; }
  md-icon[md-font-icon].s226,
  i.s226 {
    font-size: 226px !important;
    width: 226px !important;
    height: 226px !important;
    min-width: 226px !important;
    min-height: 226px !important;
    line-height: 226px !important; }
  md-icon[md-font-icon].s228,
  i.s228 {
    font-size: 228px !important;
    width: 228px !important;
    height: 228px !important;
    min-width: 228px !important;
    min-height: 228px !important;
    line-height: 228px !important; }
  md-icon[md-font-icon].s230,
  i.s230 {
    font-size: 230px !important;
    width: 230px !important;
    height: 230px !important;
    min-width: 230px !important;
    min-height: 230px !important;
    line-height: 230px !important; }
  md-icon[md-font-icon].s232,
  i.s232 {
    font-size: 232px !important;
    width: 232px !important;
    height: 232px !important;
    min-width: 232px !important;
    min-height: 232px !important;
    line-height: 232px !important; }
  md-icon[md-font-icon].s234,
  i.s234 {
    font-size: 234px !important;
    width: 234px !important;
    height: 234px !important;
    min-width: 234px !important;
    min-height: 234px !important;
    line-height: 234px !important; }
  md-icon[md-font-icon].s236,
  i.s236 {
    font-size: 236px !important;
    width: 236px !important;
    height: 236px !important;
    min-width: 236px !important;
    min-height: 236px !important;
    line-height: 236px !important; }
  md-icon[md-font-icon].s238,
  i.s238 {
    font-size: 238px !important;
    width: 238px !important;
    height: 238px !important;
    min-width: 238px !important;
    min-height: 238px !important;
    line-height: 238px !important; }
  md-icon[md-font-icon].s240,
  i.s240 {
    font-size: 240px !important;
    width: 240px !important;
    height: 240px !important;
    min-width: 240px !important;
    min-height: 240px !important;
    line-height: 240px !important; }
  md-icon[md-font-icon].s242,
  i.s242 {
    font-size: 242px !important;
    width: 242px !important;
    height: 242px !important;
    min-width: 242px !important;
    min-height: 242px !important;
    line-height: 242px !important; }
  md-icon[md-font-icon].s244,
  i.s244 {
    font-size: 244px !important;
    width: 244px !important;
    height: 244px !important;
    min-width: 244px !important;
    min-height: 244px !important;
    line-height: 244px !important; }
  md-icon[md-font-icon].s246,
  i.s246 {
    font-size: 246px !important;
    width: 246px !important;
    height: 246px !important;
    min-width: 246px !important;
    min-height: 246px !important;
    line-height: 246px !important; }
  md-icon[md-font-icon].s248,
  i.s248 {
    font-size: 248px !important;
    width: 248px !important;
    height: 248px !important;
    min-width: 248px !important;
    min-height: 248px !important;
    line-height: 248px !important; }
  md-icon[md-font-icon].s250,
  i.s250 {
    font-size: 250px !important;
    width: 250px !important;
    height: 250px !important;
    min-width: 250px !important;
    min-height: 250px !important;
    line-height: 250px !important; }
  md-icon[md-font-icon].s252,
  i.s252 {
    font-size: 252px !important;
    width: 252px !important;
    height: 252px !important;
    min-width: 252px !important;
    min-height: 252px !important;
    line-height: 252px !important; }
  md-icon[md-font-icon].s254,
  i.s254 {
    font-size: 254px !important;
    width: 254px !important;
    height: 254px !important;
    min-width: 254px !important;
    min-height: 254px !important;
    line-height: 254px !important; }
  md-icon[md-font-icon].s256,
  i.s256 {
    font-size: 256px !important;
    width: 256px !important;
    height: 256px !important;
    min-width: 256px !important;
    min-height: 256px !important;
    line-height: 256px !important; }

@font-face {
  font-family: 'icomoon';
  src: url("../assets/icons/fonts/icomoon.eot?5km1tg");
  src: url("../assets/icons/fonts/icomoon.eot?#iefix5km1tg") format("embedded-opentype"), url("../assets/icons/fonts/icomoon.ttf?5km1tg") format("truetype"), url("../assets/icons/fonts/icomoon.woff?5km1tg") format("woff"), url("../assets/icons/fonts/icomoon.svg?5km1tg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/*.icon-wrench {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAAAZiS0dEAAAAAAAA+UO7fwAAAAlwSFlzAAAASAAAAEgARslrPgAAAddJREFUaN7t2D9rFFEUhvFfjEYbY6FgPoEQia1oIVELwQ8gaWxS2QhqlULEbQNircTGysLKUgttDBhMEwvxE0QU8U8hEaJZi5mFRXc3MztzvLsyL1x2GWb2Ps+cPffuDk3SZqLEudO4iJOYxaH8+Ee8w0s8x1ZqqV6Zx3e0dxmfcRdHUwP3SquAQGd8wZXUwL2yVEKijRXsSw1dVeKRcr1WKpNDXLOaA50teP6crLFXoySGTZlK/MDxaKAJnCt5TauExJNogXlsYyFI4heORQrczyf6ictBEjfrht7T9f50/jqJh1gMuEkXAj4T2fd/298lL7IZtRTvgw9RAgf7TLiDqzXBd8ZUhMD0gAl3cKMm+Db2R1Vhtx9rSzXAbwncldcLANyqAN/Gmyh4uFMBrOi4Vzd09zL6OPLu5HkaPcGa2Aps4EikwBnZqhMp8RYzkRIrwQLhlZjCi38gEVqJw7I/IGNdiQN4IL4nwhv7PF6NuwScwjJe42vX5N9ky+8yLskedI2sRHf29jl+Ypwk+mUW74eUCN8nGolGopFoJBqJ5PkvJKrs2M9Sw1eV2EwNXlXiWmroP1OmJ26nhq0iMbLwRSRGHn6QxNjAdzIne1b7CddTwzQZlN+1yJAoBY2iwwAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOS0wNS0yOFQxMTo1NToyMCswMDowMNh1x7UAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTktMDUtMjhUMTE6NTU6MjArMDA6MDCpKH8JAAAAKHRFWHRzdmc6YmFzZS11cmkAZmlsZTovLy90bXAvbWFnaWNrLTVsMGlpOVYycZSjUgAAAABJRU5ErkJggg==);
  display: inline-block; 
  background-repeat: no-repeat;
  background-size: contain;
}*/
.icon-account-alert:before {
  content: "\e600"; }

.icon-account-box-outline:before {
  content: "\e601"; }

.icon-account-box:before {
  content: "\e602"; }

.icon-account-check:before {
  content: "\e603"; }

.icon-account-circle:before {
  content: "\e604"; }

.icon-account-key:before {
  content: "\e605"; }

.icon-account-location:before {
  content: "\e606"; }

.icon-account-minus:before {
  content: "\e607"; }

.icon-account-multiple-outline:before {
  content: "\e608"; }

.icon-account-multiple-plus:before {
  content: "\e609"; }

.icon-account-multiple:before {
  content: "\e60a"; }

.icon-account-network:before {
  content: "\e60b"; }

.icon-account-outline:before {
  content: "\e60c"; }

.icon-account-plus:before {
  content: "\e60d"; }

.icon-account-remove:before {
  content: "\e60e"; }

.icon-account-search:before {
  content: "\e60f"; }

.icon-account-switch:before {
  content: "\e610"; }

.icon-account:before {
  content: "\e611"; }

.icon-airballoon:before {
  content: "\e612"; }

.icon-airplane-off:before {
  content: "\e613"; }

.icon-airplane:before {
  content: "\e614"; }

.icon-alarm-check:before {
  content: "\e615"; }

.icon-alarm-multiple:before {
  content: "\e616"; }

.icon-alarm-off:before {
  content: "\e617"; }

.icon-alarm-plus:before {
  content: "\e618"; }

.icon-alarm:before {
  content: "\e619"; }

.icon-album:before {
  content: "\e61a"; }

.icon-alert-box:before {
  content: "\e61b"; }

.icon-alert-circle:before {
  content: "\e61c"; }

.icon-alert-octagon:before {
  content: "\e61d"; }

.icon-alert:before {
  content: "\e61e"; }

.icon-alpha:before {
  content: "\e61f"; }

.icon-alphabetical:before {
  content: "\e620"; }

.icon-amazon-clouddrive:before {
  content: "\e621"; }

.icon-amazon:before {
  content: "\e622"; }

.icon-ambulance:before {
  content: "\e623"; }

.icon-android-debug-bridge:before {
  content: "\e624"; }

.icon-android-studio:before {
  content: "\e625"; }

.icon-android:before {
  content: "\e626"; }

.icon-apple-finder:before {
  content: "\e627"; }

.icon-apple-ios:before {
  content: "\e628"; }

.icon-apple-mobileme:before {
  content: "\e629"; }

.icon-apple-safari:before {
  content: "\e62a"; }

.icon-apple:before {
  content: "\e62b"; }

.icon-appnet:before {
  content: "\e62c"; }

.icon-apps:before {
  content: "\e62d"; }

.icon-archive:before {
  content: "\e62e"; }

.icon-arrange-bring-forward:before {
  content: "\e62f"; }

.icon-arrange-bring-to-front:before {
  content: "\e630"; }

.icon-arrange-send-backward:before {
  content: "\e631"; }

.icon-arrange-send-to-back:before {
  content: "\e632"; }

.icon-arrow-all:before {
  content: "\e633"; }

.icon-arrow-bottom-left:before {
  content: "\e634"; }

.icon-arrow-bottom-right:before {
  content: "\e635"; }

.icon-arrow-collapse:before {
  content: "\e636"; }

.icon-arrow-down-bold-circle-outline:before {
  content: "\e637"; }

.icon-arrow-down-bold-circle:before {
  content: "\e638"; }

.icon-arrow-down-bold-hexagon-outline:before {
  content: "\e639"; }

.icon-arrow-down-bold:before {
  content: "\e63a"; }

.icon-arrow-down:before {
  content: "\e63b"; }

.icon-arrow-expand:before {
  content: "\e63c"; }

.icon-arrow-left-bold-circle-outline:before {
  content: "\e63d"; }

.icon-arrow-left-bold-circle:before {
  content: "\e63e"; }

.icon-arrow-left-bold-hexagon-outline:before {
  content: "\e63f"; }

.icon-arrow-left-bold:before {
  content: "\e640"; }

.icon-arrow-left:before {
  content: "\e641"; }

.icon-arrow-right-bold-circle-outline:before {
  content: "\e642"; }

.icon-arrow-right-bold-circle:before {
  content: "\e643"; }

.icon-arrow-right-bold-hexagon-outline:before {
  content: "\e644"; }

.icon-arrow-right-bold:before {
  content: "\e645"; }

.icon-arrow-right:before {
  content: "\e646"; }

.icon-arrow-top-left:before {
  content: "\e647"; }

.icon-arrow-top-right:before {
  content: "\e648"; }

.icon-arrow-up-bold-circle-outline:before {
  content: "\e649"; }

.icon-arrow-up-bold-circle:before {
  content: "\e64a"; }

.icon-arrow-up-bold-hexagon-outline:before {
  content: "\e64b"; }

.icon-arrow-up-bold:before {
  content: "\e64c"; }

.icon-arrow-up:before {
  content: "\e64d"; }

.icon-at:before {
  content: "\e64e"; }

.icon-attachment:before {
  content: "\e64f"; }

.icon-audiobook:before {
  content: "\e650"; }

.icon-auto-fix:before {
  content: "\e651"; }

.icon-auto-upload:before {
  content: "\e652"; }

.icon-baby:before {
  content: "\e653"; }

.icon-backburger:before {
  content: "\e654"; }

.icon-backup-restore:before {
  content: "\e655"; }

.icon-bank:before {
  content: "\e656"; }

.icon-barcode:before {
  content: "\e657"; }

.icon-barley:before {
  content: "\e658"; }

.icon-barrel:before {
  content: "\e659"; }

.icon-basecamp:before {
  content: "\e65a"; }

.icon-basket-fill:before {
  content: "\e65b"; }

.icon-basket-unfill:before {
  content: "\e65c"; }

.icon-basket:before {
  content: "\e65d"; }

.icon-battery-10:before {
  content: "\e65e"; }

.icon-battery-20:before {
  content: "\e65f"; }

.icon-battery-30:before {
  content: "\e660"; }

.icon-battery-40:before {
  content: "\e661"; }

.icon-battery-50:before {
  content: "\e662"; }

.icon-battery-60:before {
  content: "\e663"; }

.icon-battery-70:before {
  content: "\e664"; }

.icon-battery-80:before {
  content: "\e665"; }

.icon-battery-90:before {
  content: "\e666"; }

.icon-battery-alert:before {
  content: "\e667"; }

.icon-battery-charging-20:before {
  content: "\e668"; }

.icon-battery-charging-30:before {
  content: "\e669"; }

.icon-battery-charging-40:before {
  content: "\e66a"; }

.icon-battery-charging-60:before {
  content: "\e66b"; }

.icon-battery-charging-80:before {
  content: "\e66c"; }

.icon-battery-charging-90:before {
  content: "\e66d"; }

.icon-battery-charging-100:before {
  content: "\e66e"; }

.icon-battery-minus:before {
  content: "\e66f"; }

.icon-battery-negative:before {
  content: "\e670"; }

.icon-battery-outline:before {
  content: "\e671"; }

.icon-battery-plus:before {
  content: "\e672"; }

.icon-battery-positive:before {
  content: "\e673"; }

.icon-battery-standard:before {
  content: "\e674"; }

.icon-battery-unknown:before {
  content: "\e675"; }

.icon-battery:before {
  content: "\e676"; }

.icon-beach:before {
  content: "\e677"; }

.icon-beaker-empty-outline:before {
  content: "\e678"; }

.icon-beaker-empty:before {
  content: "\e679"; }

.icon-beaker-outline:before {
  content: "\e67a"; }

.icon-beaker:before {
  content: "\e67b"; }

.icon-beats:before {
  content: "\e67c"; }

.icon-beer:before {
  content: "\e67d"; }

.icon-behance:before {
  content: "\e67e"; }

.icon-bell-off:before {
  content: "\e67f"; }

.icon-bell-outline:before {
  content: "\e680"; }

.icon-bell-ring-outline:before {
  content: "\e681"; }

.icon-bell-ring:before {
  content: "\e682"; }

.icon-bell-sleep:before {
  content: "\e683"; }

.icon-bell:before {
  content: "\e684"; }

.icon-beta:before {
  content: "\e685"; }

.icon-bike:before {
  content: "\e686"; }

.icon-bing:before {
  content: "\e687"; }

.icon-binoculars:before {
  content: "\e688"; }

.icon-bio:before {
  content: "\e689"; }

.icon-bitbucket:before {
  content: "\e68a"; }

.icon-black-mesa:before {
  content: "\e68b"; }

.icon-blackberry:before {
  content: "\e68c"; }

.icon-blinds:before {
  content: "\e68d"; }

.icon-block-helper:before {
  content: "\e68e"; }

.icon-blogger:before {
  content: "\e68f"; }

.icon-bluetooth-audio:before {
  content: "\e690"; }

.icon-bluetooth-connect:before {
  content: "\e691"; }

.icon-bluetooth-settings:before {
  content: "\e692"; }

.icon-bluetooth-transfer:before {
  content: "\e693"; }

.icon-bluetooth:before {
  content: "\e694"; }

.icon-blur-linear:before {
  content: "\e695"; }

.icon-blur-off:before {
  content: "\e696"; }

.icon-blur-radial:before {
  content: "\e697"; }

.icon-blur:before {
  content: "\e698"; }

.icon-bone:before {
  content: "\e699"; }

.icon-book-multiple-variant:before {
  content: "\e69a"; }

.icon-book-multiple:before {
  content: "\e69b"; }

.icon-book-open:before {
  content: "\e69c"; }

.icon-book-variant:before {
  content: "\e69d"; }

.icon-book:before {
  content: "\e69e"; }

.icon-bookmark-check:before {
  content: "\e69f"; }

.icon-bookmark-music:before {
  content: "\e6a0"; }

.icon-bookmark-outline-plus:before {
  content: "\e6a1"; }

.icon-bookmark-outline:before {
  content: "\e6a2"; }

.icon-bookmark-plus:before {
  content: "\e6a3"; }

.icon-bookmark-remove:before {
  content: "\e6a4"; }

.icon-bookmark:before {
  content: "\e6a5"; }

.icon-border-all:before {
  content: "\e6a6"; }

.icon-border-bottom:before {
  content: "\e6a7"; }

.icon-border-color:before {
  content: "\e6a8"; }

.icon-border-horizontal:before {
  content: "\e6a9"; }

.icon-border-inside:before {
  content: "\e6aa"; }

.icon-border-left:before {
  content: "\e6ab"; }

.icon-border-none:before {
  content: "\e6ac"; }

.icon-border-outside:before {
  content: "\e6ad"; }

.icon-border-right:before {
  content: "\e6ae"; }

.icon-border-top:before {
  content: "\e6af"; }

.icon-border-vertical:before {
  content: "\e6b0"; }

.icon-bowling:before {
  content: "\e6b1"; }

.icon-box-download:before {
  content: "\e6b2"; }

.icon-box-upload:before {
  content: "\e6b3"; }

.icon-box:before {
  content: "\e6b4"; }

.icon-briefcase-checked:before {
  content: "\e6b5"; }

.icon-briefcase-download:before {
  content: "\e6b6"; }

.icon-briefcase-upload:before {
  content: "\e6b7"; }

.icon-briefcase:before {
  content: "\e6b8"; }

.icon-brightness-1:before {
  content: "\e6b9"; }

.icon-brightness-2:before {
  content: "\e6ba"; }

.icon-brightness-3:before {
  content: "\e6bb"; }

.icon-brightness-4:before {
  content: "\e6bc"; }

.icon-brightness-5:before {
  content: "\e6bd"; }

.icon-brightness-6:before {
  content: "\e6be"; }

.icon-brightness-7:before {
  content: "\e6bf"; }

.icon-brightness-auto:before {
  content: "\e6c0"; }

.icon-brightness:before {
  content: "\e6c1"; }

.icon-broom:before {
  content: "\e6c2"; }

.icon-brush:before {
  content: "\e6c3"; }

.icon-bug:before {
  content: "\e6c4"; }

.icon-bulletin-board:before {
  content: "\e6c5"; }

.icon-bullhorn:before {
  content: "\e6c6"; }

.icon-bus:before {
  content: "\e6c7"; }

.icon-cake-variant:before {
  content: "\e6c8"; }

.icon-cake:before {
  content: "\e6c9"; }

.icon-calculator:before {
  content: "\e6ca"; }

.icon-calendar-blank:before {
  content: "\e6cb"; }

.icon-calendar-check-multiple:before {
  content: "\e6cc"; }

.icon-calendar-check:before {
  content: "\e6cd"; }

.icon-calendar-clock:before {
  content: "\e6ce"; }

.icon-calendar-multiple:before {
  content: "\e6cf"; }

.icon-calendar-plus:before {
  content: "\e6d0"; }

.icon-calendar-remove:before {
  content: "\e6d1"; }

.icon-calendar-select:before {
  content: "\e6d2"; }

.icon-calendar-text:before {
  content: "\e6d3"; }

.icon-calendar-today:before {
  content: "\e6d4"; }

.icon-calendar:before {
  content: "\e6d5"; }

.icon-camcorder-box-off:before {
  content: "\e6d6"; }

.icon-camcorder-box:before {
  content: "\e6d7"; }

.icon-camcorder-off:before {
  content: "\e6d8"; }

.icon-camcorder:before {
  content: "\e6d9"; }

.icon-camera-front-variant:before {
  content: "\e6da"; }

.icon-camera-front:before {
  content: "\e6db"; }

.icon-camera-iris:before {
  content: "\e6dc"; }

.icon-camera-party-mode:before {
  content: "\e6dd"; }

.icon-camera-rear-variant:before {
  content: "\e6de"; }

.icon-camera-rear:before {
  content: "\e6df"; }

.icon-camera-switch:before {
  content: "\e6e0"; }

.icon-camera-timer:before {
  content: "\e6e1"; }

.icon-camera:before {
  content: "\e6e2"; }

.icon-cancel:before {
  content: "\e6e3"; }

.icon-candycane:before {
  content: "\e6e4"; }

.icon-car-wash:before {
  content: "\e6e5"; }

.icon-car:before {
  content: "\e6e6"; }

.icon-carrot:before {
  content: "\e6e7"; }

.icon-cart-outline:before {
  content: "\e6e8"; }

.icon-cart:before {
  content: "\e6e9"; }

.icon-cash-100:before {
  content: "\e6ea"; }

.icon-cash-multiple:before {
  content: "\e6eb"; }

.icon-cash-usd:before {
  content: "\e6ec"; }

.icon-cash:before {
  content: "\e6ed"; }

.icon-cast-connected:before {
  content: "\e6ee"; }

.icon-cast:before {
  content: "\e6ef"; }

.icon-castle:before {
  content: "\e6f0"; }

.icon-cat:before {
  content: "\e6f1"; }

.icon-cellphone-android:before {
  content: "\e6f2"; }

.icon-cellphone-dock:before {
  content: "\e6f3"; }

.icon-cellphone-iphone:before {
  content: "\e6f4"; }

.icon-cellphone-link-off:before {
  content: "\e6f5"; }

.icon-cellphone-link:before {
  content: "\e6f6"; }

.icon-cellphone-settings:before {
  content: "\e6f7"; }

.icon-cellphone:before {
  content: "\e6f8"; }

.icon-chair-school:before {
  content: "\e6f9"; }

.icon-chart-arc:before {
  content: "\e6fa"; }

.icon-chart-areaspline:before {
  content: "\e6fb"; }

.icon-chart-bar:before {
  content: "\e6fc"; }

.icon-chart-histogram:before {
  content: "\e6fd"; }

.icon-chart-line:before {
  content: "\e6fe"; }

.icon-chart-pie:before {
  content: "\e6ff"; }

.icon-check-all:before {
  content: "\e700"; }

.icon-check-bookmark:before {
  content: "\e701"; }

.icon-check-circle:before {
  content: "\e702"; }

.icon-check:before {
  content: "\e703"; }

.icon-checkbox-blank-circle-outline:before {
  content: "\e704"; }

.icon-checkbox-blank-circle:before {
  content: "\e705"; }

.icon-checkbox-blank-outline:before {
  content: "\e706"; }

.icon-checkbox-blank:before {
  content: "\e707"; }

.icon-checkbox-marked-circle-outline:before {
  content: "\e708"; }

.icon-checkbox-marked-circle:before {
  content: "\e709"; }

.icon-checkbox-marked-outline:before {
  content: "\e70a"; }

.icon-checkbox-marked:before {
  content: "\e70b"; }

.icon-checkbox-multiple-blank-outline:before {
  content: "\e70c"; }

.icon-checkbox-multiple-blank:before {
  content: "\e70d"; }

.icon-checkbox-multiple-marked-outline:before {
  content: "\e70e"; }

.icon-checkbox-multiple-marked:before {
  content: "\e70f"; }

.icon-checkerboard:before {
  content: "\e710"; }

.icon-chevron-double-down:before {
  content: "\e711"; }

.icon-chevron-double-left:before {
  content: "\e712"; }

.icon-chevron-double-right:before {
  content: "\e713"; }

.icon-chevron-double-up:before {
  content: "\e714"; }

.icon-chevron-down:before {
  content: "\e715"; }

.icon-chevron-left:before {
  content: "\e716"; }

.icon-chevron-right:before {
  content: "\e717"; }

.icon-chevron-up:before {
  content: "\e718"; }

.icon-church:before {
  content: "\e719"; }

.icon-cisco-webex:before {
  content: "\e71a"; }

.icon-city:before {
  content: "\e71b"; }

.icon-clapperboard:before {
  content: "\e71c"; }

.icon-clipboard-account:before {
  content: "\e71d"; }

.icon-clipboard-alert:before {
  content: "\e71e"; }

.icon-clipboard-arrow-down:before {
  content: "\e71f"; }

.icon-clipboard-arrow-left:before {
  content: "\e720"; }

.icon-clipboard-check:before {
  content: "\e721"; }

.icon-clipboard-outline:before {
  content: "\e722"; }

.icon-clipboard-text:before {
  content: "\e723"; }

.icon-clipboard:before {
  content: "\e724"; }

.icon-clippy:before {
  content: "\e725"; }

.icon-clock-fast:before {
  content: "\e726"; }

.icon-clock:before {
  content: "\e727"; }

.icon-close-circle-outline:before {
  content: "\e728"; }

.icon-close-circle:before {
  content: "\e729"; }

.icon-close-network:before {
  content: "\e72a"; }

.icon-close:before {
  content: "\e72b"; }

.icon-closed-caption:before {
  content: "\e72c"; }

.icon-cloud-check:before {
  content: "\e72d"; }

.icon-cloud-circle:before {
  content: "\e72e"; }

.icon-cloud-download:before {
  content: "\e72f"; }

.icon-cloud-outline-off:before {
  content: "\e730"; }

.icon-cloud-outline:before {
  content: "\e731"; }

.icon-cloud-upload:before {
  content: "\e732"; }

.icon-cloud:before {
  content: "\e733"; }

.icon-code-array:before {
  content: "\e734"; }

.icon-code-braces:before {
  content: "\e735"; }

.icon-code-equal:before {
  content: "\e736"; }

.icon-code-greater-than-or-equal:before {
  content: "\e737"; }

.icon-code-greater-than:before {
  content: "\e738"; }

.icon-code-less-than-or-equal:before {
  content: "\e739"; }

.icon-code-less-than:before {
  content: "\e73a"; }

.icon-code-not-equal-variant:before {
  content: "\e73b"; }

.icon-code-not-equal:before {
  content: "\e73c"; }

.icon-code-string:before {
  content: "\e73d"; }

.icon-code-tags:before {
  content: "\e73e"; }

.icon-codepen:before {
  content: "\e73f"; }

.icon-coffee-to-go:before {
  content: "\e740"; }

.icon-coffee:before {
  content: "\e741"; }

.icon-cog-box:before {
  content: "\e742"; }

.icon-cog:before {
  content: "\e743"; }

.icon-coin:before {
  content: "\e744"; }

.icon-color-helper:before {
  content: "\e745"; }

.icon-comment-account-outline:before {
  content: "\e746"; }

.icon-comment-account:before {
  content: "\e747"; }

.icon-comment-alert-outline:before {
  content: "\e748"; }

.icon-comment-alert:before {
  content: "\e749"; }

.icon-comment-check-outline:before {
  content: "\e74a"; }

.icon-comment-check:before {
  content: "\e74b"; }

.icon-comment-multipe-outline:before {
  content: "\e74c"; }

.icon-comment-outline:before {
  content: "\e74d"; }

.icon-comment-plus-outline:before {
  content: "\e74e"; }

.icon-comment-processing-outline:before {
  content: "\e74f"; }

.icon-comment-processing:before {
  content: "\e750"; }

.icon-comment-remove-outline:before {
  content: "\e751"; }

.icon-comment-text-outline:before {
  content: "\e752"; }

.icon-comment-text:before {
  content: "\e753"; }

.icon-comment:before {
  content: "\e754"; }

.icon-compare:before {
  content: "\e755"; }

.icon-compass-outline:before {
  content: "\e756"; }

.icon-compass:before {
  content: "\e757"; }

.icon-console:before {
  content: "\e758"; }

.icon-content-copy:before {
  content: "\e759"; }

.icon-content-cut:before {
  content: "\e75a"; }

.icon-content-duplicate:before {
  content: "\e75b"; }

.icon-content-paste:before {
  content: "\e75c"; }

.icon-content-save-all:before {
  content: "\e75d"; }

.icon-content-save:before {
  content: "\e75e"; }

.icon-contrast-box:before {
  content: "\e75f"; }

.icon-contrast-circle:before {
  content: "\e760"; }

.icon-contrast:before {
  content: "\e761"; }

.icon-controller-xbox:before {
  content: "\e762"; }

.icon-cow:before {
  content: "\e763"; }

.icon-credit-card-multiple:before {
  content: "\e764"; }

.icon-credit-card:before {
  content: "\e765"; }

.icon-crop-free:before {
  content: "\e766"; }

.icon-crop-landscape:before {
  content: "\e767"; }

.icon-crop-portrait:before {
  content: "\e768"; }

.icon-crop-square:before {
  content: "\e769"; }

.icon-crop:before {
  content: "\e76a"; }

.icon-crosshairs-gps:before {
  content: "\e76b"; }

.icon-crosshairs:before {
  content: "\e76c"; }

.icon-crown:before {
  content: "\e76d"; }

.icon-cube-outline:before {
  content: "\e76e"; }

.icon-cube-unfolded:before {
  content: "\e76f"; }

.icon-cube:before {
  content: "\e770"; }

.icon-cup-water:before {
  content: "\e771"; }

.icon-cup:before {
  content: "\e772"; }

.icon-currency-btc:before {
  content: "\e773"; }

.icon-currency-eur:before {
  content: "\e774"; }

.icon-currency-gbp:before {
  content: "\e775"; }

.icon-currency-inr:before {
  content: "\e776"; }

.icon-currency-rub:before {
  content: "\e777"; }

.icon-currency-try:before {
  content: "\e778"; }

.icon-currency-usd:before {
  content: "\e779"; }

.icon-cursor-default-outline:before {
  content: "\e77a"; }

.icon-cursor-default:before {
  content: "\e77b"; }

.icon-cursor-move:before {
  content: "\e77c"; }

.icon-cursor-pointer:before {
  content: "\e77d"; }

.icon-data:before {
  content: "\e77e"; }

.icon-debug-step-into:before {
  content: "\e77f"; }

.icon-debug-step-out:before {
  content: "\e780"; }

.icon-debug-step-over:before {
  content: "\e781"; }

.icon-decimal-decrease:before {
  content: "\e782"; }

.icon-decimal-increase:before {
  content: "\e783"; }

.icon-delete-variant:before {
  content: "\e784"; }

.icon-delete:before {
  content: "\e785"; }

.icon-deskphone:before {
  content: "\e786"; }

.icon-desktop-mac:before {
  content: "\e787"; }

.icon-desktop-tower:before {
  content: "\e788"; }

.icon-details:before {
  content: "\e789"; }

.icon-deviantart:before {
  content: "\e78a"; }

.icon-diamond:before {
  content: "\e78b"; }

.icon-dice-1:before {
  content: "\e78c"; }

.icon-dice-2:before {
  content: "\e78d"; }

.icon-dice-3:before {
  content: "\e78e"; }

.icon-dice-4:before {
  content: "\e78f"; }

.icon-dice-5:before {
  content: "\e790"; }

.icon-dice-6:before {
  content: "\e791"; }

.icon-dice:before {
  content: "\e792"; }

.icon-directions:before {
  content: "\e793"; }

.icon-disk-alert:before {
  content: "\e794"; }

.icon-disqus-outline:before {
  content: "\e795"; }

.icon-disqus:before {
  content: "\e796"; }

.icon-division-box:before {
  content: "\e797"; }

.icon-division:before {
  content: "\e798"; }

.icon-dns:before {
  content: "\e799"; }

.icon-document:before {
  content: "\e79a"; }

.icon-domain:before {
  content: "\e79b"; }

.icon-dots-horizontal:before {
  content: "\e79c"; }

.icon-dots-vertical:before {
  content: "\e79d"; }

.icon-download:before {
  content: "\e79e"; }

.icon-drag-horizontal:before {
  content: "\e79f"; }

.icon-drag-vertical:before {
  content: "\e7a0"; }

.icon-drag:before {
  content: "\e7a1"; }

.icon-drawing-box:before {
  content: "\e7a2"; }

.icon-drawing:before {
  content: "\e7a3"; }

.icon-dribbble-box:before {
  content: "\e7a4"; }

.icon-dribbble:before {
  content: "\e7a5"; }

.icon-drone:before {
  content: "\e7a6"; }

.icon-dropbox:before {
  content: "\e7a7"; }

.icon-drupal:before {
  content: "\e7a8"; }

.icon-duck:before {
  content: "\e7a9"; }

.icon-dumbbell:before {
  content: "\e7aa"; }

.icon-earth-off:before {
  content: "\e7ab"; }

.icon-earth:before {
  content: "\e7ac"; }

.icon-ebay:before {
  content: "\e7ad"; }

.icon-edge:before {
  content: "\e7ae"; }

.icon-eject:before {
  content: "\e7af"; }

.icon-elevation-decline:before {
  content: "\e7b0"; }

.icon-elevation-rise:before {
  content: "\e7b1"; }

.icon-elevator:before {
  content: "\e7b2"; }

.icon-email-open:before {
  content: "\e7b3"; }

.icon-email-secure:before {
  content: "\e7b4"; }

.icon-email:before {
  content: "\e7b5"; }

.icon-emoticon-cool:before {
  content: "\e7b6"; }

.icon-emoticon-devil:before {
  content: "\e7b7"; }

.icon-emoticon-happy:before {
  content: "\e7b8"; }

.icon-emoticon-neutral:before {
  content: "\e7b9"; }

.icon-emoticon-poop:before {
  content: "\e7ba"; }

.icon-emoticon-sad:before {
  content: "\e7bb"; }

.icon-emoticon-tongue:before {
  content: "\e7bc"; }

.icon-emoticon:before {
  content: "\e7bd"; }

.icon-engine-outline:before {
  content: "\e7be"; }

.icon-engine:before {
  content: "\e7bf"; }

.icon-equal-box:before {
  content: "\e7c0"; }

.icon-equal:before {
  content: "\e7c1"; }

.icon-eraser:before {
  content: "\e7c2"; }

.icon-escalator:before {
  content: "\e7c3"; }

.icon-etsy:before {
  content: "\e7c4"; }

.icon-evernote:before {
  content: "\e7c5"; }

.icon-exclamation:before {
  content: "\e7c6"; }

.icon-exit-to-app:before {
  content: "\e7c7"; }

.icon-export:before {
  content: "\e7c8"; }

.icon-eye-off:before {
  content: "\e7c9"; }

.icon-eye:before {
  content: "\e7ca"; }

.icon-eyedropper-variant:before {
  content: "\e7cb"; }

.icon-eyedropper:before {
  content: "\e7cc"; }

.icon-facebook-box:before {
  content: "\e7cd"; }

.icon-facebook:before {
  content: "\e7ce"; }

.icon-factory:before {
  content: "\e7cf"; }

.icon-fan:before {
  content: "\e7d0"; }

.icon-fast-forward:before {
  content: "\e7d1"; }

.icon-ferry:before {
  content: "\e7d2"; }

.icon-file-cloud:before {
  content: "\e7d3"; }

.icon-file-delimited:before {
  content: "\e7d4"; }

.icon-file-document-box:before {
  content: "\e7d5"; }

.icon-file-document:before {
  content: "\e7d6"; }

.icon-file-excel-box:before {
  content: "\e7d7"; }

.icon-file-excel:before {
  content: "\e7d8"; }

.icon-file-find:before {
  content: "\e7d9"; }

.icon-file-hidden:before {
  content: "\e7da"; }

.icon-file-image-box:before {
  content: "\e7db"; }

.icon-file-image:before {
  content: "\e7dc"; }

.icon-file-multiple:before {
  content: "\e7dd"; }

.icon-file-music:before {
  content: "\e7de"; }

.icon-file-outline:before {
  content: "\e7df"; }

.icon-file-pdf-box:before {
  content: "\e7e0"; }

.icon-file-pdf:before {
  content: "\e7e1"; }

.icon-file-powerpoint-box:before {
  content: "\e7e2"; }

.icon-file-powerpoint:before {
  content: "\e7e3"; }

.icon-file-presentation-box:before {
  content: "\e7e4"; }

.icon-file-video:before {
  content: "\e7e5"; }

.icon-file-word-box:before {
  content: "\e7e6"; }

.icon-file-word:before {
  content: "\e7e7"; }

.icon-file-xml:before {
  content: "\e7e8"; }

.icon-file:before {
  content: "\e7e9"; }

.icon-fill:before {
  content: "\e7ea"; }

.icon-film:before {
  content: "\e7eb"; }

.icon-filmstrip-off:before {
  content: "\e7ec"; }

.icon-filmstrip:before {
  content: "\e7ed"; }

.icon-filter-outline:before {
  content: "\e7ee"; }

.icon-filter-remove-outline:before {
  content: "\e7ef"; }

.icon-filter-remove:before {
  content: "\e7f0"; }

.icon-filter-variant:before {
  content: "\e7f1"; }

.icon-filter:before {
  content: "\e7f2"; }

.icon-fire:before {
  content: "\e7f3"; }

.icon-fish:before {
  content: "\e7f4"; }

.icon-flag-checkered-variant:before {
  content: "\e7f5"; }

.icon-flag-checkered:before {
  content: "\e7f6"; }

.icon-flag-outline-variant:before {
  content: "\e7f7"; }

.icon-flag-outline:before {
  content: "\e7f8"; }

.icon-flag-triangle:before {
  content: "\e7f9"; }

.icon-flag-variant:before {
  content: "\e7fa"; }

.icon-flag:before {
  content: "\e7fb"; }

.icon-flash-auto:before {
  content: "\e7fc"; }

.icon-flash-off:before {
  content: "\e7fd"; }

.icon-flash:before {
  content: "\e7fe"; }

.icon-flashlight-off:before {
  content: "\e7ff"; }

.icon-flashlight:before {
  content: "\e800"; }

.icon-flattr:before {
  content: "\e801"; }

.icon-flickr-after:before {
  content: "\e802"; }

.icon-flickr-before:before {
  content: "\e803"; }

.icon-flip-to-back:before {
  content: "\e804"; }

.icon-flip-to-front:before {
  content: "\e805"; }

.icon-floppy:before {
  content: "\e806"; }

.icon-flower:before {
  content: "\e807"; }

.icon-folder-account:before {
  content: "\e808"; }

.icon-folder-download:before {
  content: "\e809"; }

.icon-folder-google-drive:before {
  content: "\e80a"; }

.icon-folder-image:before {
  content: "\e80b"; }

.icon-folder-lock-open:before {
  content: "\e80c"; }

.icon-folder-lock:before {
  content: "\e80d"; }

.icon-folder-move:before {
  content: "\e80e"; }

.icon-folder-multiple-image:before {
  content: "\e80f"; }

.icon-folder-multiple-outline:before {
  content: "\e810"; }

.icon-folder-multiple:before {
  content: "\e811"; }

.icon-folder-outline-lock:before {
  content: "\e812"; }

.icon-folder-outline:before {
  content: "\e813"; }

.icon-folder-plus:before {
  content: "\e814"; }

.icon-folder-remove:before {
  content: "\e815"; }

.icon-folder-upload:before {
  content: "\e816"; }

.icon-folder:before {
  content: "\e817"; }

.icon-food-apple:before {
  content: "\e818"; }

.icon-food-variant:before {
  content: "\e819"; }

.icon-food:before {
  content: "\e81a"; }

.icon-football-helmet:before {
  content: "\e81b"; }

.icon-football:before {
  content: "\e81c"; }

.icon-format-align-center:before {
  content: "\e81d"; }

.icon-format-align-justify:before {
  content: "\e81e"; }

.icon-format-align-left:before {
  content: "\e81f"; }

.icon-format-align-right:before {
  content: "\e820"; }

.icon-format-bold:before {
  content: "\e821"; }

.icon-format-clear:before {
  content: "\e822"; }

.icon-format-color-fill:before {
  content: "\e823"; }

.icon-format-color:before {
  content: "\e824"; }

.icon-format-float-center:before {
  content: "\e825"; }

.icon-format-float-left:before {
  content: "\e826"; }

.icon-format-float-none:before {
  content: "\e827"; }

.icon-format-float-right:before {
  content: "\e828"; }

.icon-format-header-1:before {
  content: "\e829"; }

.icon-format-header-2:before {
  content: "\e82a"; }

.icon-format-header-3:before {
  content: "\e82b"; }

.icon-format-header-4:before {
  content: "\e82c"; }

.icon-format-header-5:before {
  content: "\e82d"; }

.icon-format-header-6:before {
  content: "\e82e"; }

.icon-format-header-decrease:before {
  content: "\e82f"; }

.icon-format-header-down:before {
  content: "\e830"; }

.icon-format-header-equal:before {
  content: "\e831"; }

.icon-format-header-increase:before {
  content: "\e832"; }

.icon-format-header-pound:before {
  content: "\e833"; }

.icon-format-header-up:before {
  content: "\e834"; }

.icon-format-indent-decrease:before {
  content: "\e835"; }

.icon-format-indent-increase:before {
  content: "\e836"; }

.icon-format-italic:before {
  content: "\e837"; }

.icon-format-line-spacing:before {
  content: "\e838"; }

.icon-format-list-bulleted:before {
  content: "\e839"; }

.icon-format-list-numbers:before {
  content: "\e83a"; }

.icon-format-object-inline:before {
  content: "\e83b"; }

.icon-format-object-square:before {
  content: "\e83c"; }

.icon-format-object-tight:before {
  content: "\e83d"; }

.icon-format-object-top-bottom:before {
  content: "\e83e"; }

.icon-format-paint:before {
  content: "\e83f"; }

.icon-format-paragraph:before {
  content: "\e840"; }

.icon-format-quote:before {
  content: "\e841"; }

.icon-format-size:before {
  content: "\e842"; }

.icon-format-strikethrough:before {
  content: "\e843"; }

.icon-format-subscript:before {
  content: "\e844"; }

.icon-format-superscript:before {
  content: "\e845"; }

.icon-format-text:before {
  content: "\e846"; }

.icon-format-textdirection-l-to-r:before {
  content: "\e847"; }

.icon-format-textdirection-r-to-l:before {
  content: "\e848"; }

.icon-format-underline:before {
  content: "\e849"; }

.icon-forum:before {
  content: "\e84a"; }

.icon-forward:before {
  content: "\e84b"; }

.icon-foursquare:before {
  content: "\e84c"; }

.icon-fridge:before {
  content: "\e84d"; }

.icon-fullscreen-exit:before {
  content: "\e84e"; }

.icon-fullscreen:before {
  content: "\e84f"; }

.icon-function:before {
  content: "\e850"; }

.icon-gamepad-variant:before {
  content: "\e851"; }

.icon-gamepad:before {
  content: "\e852"; }

.icon-gas-station:before {
  content: "\e853"; }

.icon-gavel:before {
  content: "\e854"; }

.icon-gender-female:before {
  content: "\e855"; }

.icon-gender-male-female:before {
  content: "\e856"; }

.icon-gender-male:before {
  content: "\e857"; }

.icon-gender-transgender:before {
  content: "\e858"; }

.icon-gift:before {
  content: "\e859"; }

.icon-git:before {
  content: "\e85a"; }

.icon-github-box:before {
  content: "\e85b"; }

.icon-github:before {
  content: "\e85c"; }

.icon-glass-flute:before {
  content: "\e85d"; }

.icon-glass-mug:before {
  content: "\e85e"; }

.icon-glass-stange:before {
  content: "\e85f"; }

.icon-glass-tulip:before {
  content: "\e860"; }

.icon-glasses:before {
  content: "\e861"; }

.icon-gmail:before {
  content: "\e862"; }

.icon-google-chrome:before {
  content: "\e863"; }

.icon-google-circles-communities:before {
  content: "\e864"; }

.icon-google-circles-extended:before {
  content: "\e865"; }

.icon-google-circles-invite:before {
  content: "\e866"; }

.icon-google-circles:before {
  content: "\e867"; }

.icon-google-controller-off:before {
  content: "\e868"; }

.icon-google-controller:before {
  content: "\e869"; }

.icon-google-drive:before {
  content: "\e86a"; }

.icon-google-earth:before {
  content: "\e86b"; }

.icon-google-glass:before {
  content: "\e86c"; }

.icon-google-maps:before {
  content: "\e86d"; }

.icon-google-pages:before {
  content: "\e86e"; }

.icon-google-play:before {
  content: "\e86f"; }

.icon-google-plus-box:before {
  content: "\e870"; }

.icon-google-plus:before {
  content: "\e871"; }

.icon-google:before {
  content: "\e872"; }

.icon-grid-off:before {
  content: "\e873"; }

.icon-grid:before {
  content: "\e874"; }

.icon-group:before {
  content: "\e875"; }

.icon-hand-pointing-right:before {
  content: "\e876"; }

.icon-hanger:before {
  content: "\e877"; }

.icon-hangouts:before {
  content: "\e878"; }

.icon-harddisk:before {
  content: "\e879"; }

.icon-headphones-box:before {
  content: "\e87a"; }

.icon-headphones-dots:before {
  content: "\e87b"; }

.icon-headphones:before {
  content: "\e87c"; }

.icon-headset-dock:before {
  content: "\e87d"; }

.icon-headset-off:before {
  content: "\e87e"; }

.icon-headset:before {
  content: "\e87f"; }

.icon-heart-box-outline:before {
  content: "\e880"; }

.icon-heart-box:before {
  content: "\e881"; }

.icon-heart-broken:before {
  content: "\e882"; }

.icon-heart-outline:before {
  content: "\e883"; }

.icon-heart:before {
  content: "\e884"; }

.icon-help-circle:before {
  content: "\e885"; }

.icon-help:before {
  content: "\e886"; }

.icon-hexagon-outline:before {
  content: "\e887"; }

.icon-hexagon:before {
  content: "\e888"; }

.icon-history:before {
  content: "\e889"; }

.icon-hololens:before {
  content: "\e88a"; }

.icon-home-modern:before {
  content: "\e88b"; }

.icon-home-variant:before {
  content: "\e88c"; }

.icon-home:before {
  content: "\e88d"; }

.icon-hops:before {
  content: "\e88e"; }

.icon-hospital-building:before {
  content: "\e88f"; }

.icon-hospital-marker:before {
  content: "\e890"; }

.icon-hospital:before {
  content: "\e891"; }

.icon-hotel:before {
  content: "\e892"; }

.icon-houzz-box:before {
  content: "\e893"; }

.icon-human-child:before {
  content: "\e894"; }

.icon-human-male-female:before {
  content: "\e895"; }

.icon-human:before {
  content: "\e896"; }

.icon-image-album:before {
  content: "\e897"; }

.icon-image-area-close:before {
  content: "\e898"; }

.icon-image-area:before {
  content: "\e899"; }

.icon-image-broken:before {
  content: "\e89a"; }

.icon-image-filter-black-white:before {
  content: "\e89b"; }

.icon-image-filter-center-focus:before {
  content: "\e89c"; }

.icon-image-filter-drama:before {
  content: "\e89d"; }

.icon-image-filter-frames:before {
  content: "\e89e"; }

.icon-image-filter-hdr:before {
  content: "\e89f"; }

.icon-image-filter-none:before {
  content: "\e8a0"; }

.icon-image-filter-tilt-shift:before {
  content: "\e8a1"; }

.icon-image-filter-vintage:before {
  content: "\e8a2"; }

.icon-image-filter:before {
  content: "\e8a3"; }

.icon-image:before {
  content: "\e8a4"; }

.icon-import:before {
  content: "\e8a5"; }

.icon-inbox:before {
  content: "\e8a6"; }

.icon-indent:before {
  content: "\e8a7"; }

.icon-information-outline:before {
  content: "\e8a8"; }

.icon-information:before {
  content: "\e8a9"; }

.icon-instagram:before {
  content: "\e8aa"; }

.icon-instapaper:before {
  content: "\e8ab"; }

.icon-internet-explorer:before {
  content: "\e8ac"; }

.icon-invert-colors:before {
  content: "\e8ad"; }

.icon-jira:before {
  content: "\e8ae"; }

.icon-jsfiddle:before {
  content: "\e8af"; }

.icon-keg:before {
  content: "\e8b0"; }

.icon-key-change:before {
  content: "\e8b1"; }

.icon-key-minus:before {
  content: "\e8b2"; }

.icon-key-plus:before {
  content: "\e8b3"; }

.icon-key-remove:before {
  content: "\e8b4"; }

.icon-key-variant:before {
  content: "\e8b5"; }

.icon-key:before {
  content: "\e8b6"; }

.icon-keyboard-backspace:before {
  content: "\e8b7"; }

.icon-keyboard-caps:before {
  content: "\e8b8"; }

.icon-keyboard-close:before {
  content: "\e8b9"; }

.icon-keyboard-off:before {
  content: "\e8ba"; }

.icon-keyboard-return:before {
  content: "\e8bb"; }

.icon-keyboard-tab:before {
  content: "\e8bc"; }

.icon-keyboard-variant:before {
  content: "\e8bd"; }

.icon-keyboard:before {
  content: "\e8be"; }

.icon-label-outline:before {
  content: "\e8bf"; }

.icon-label:before {
  content: "\e8c0"; }

.icon-language-csharp:before {
  content: "\e8c1"; }

.icon-language-css3:before {
  content: "\e8c2"; }

.icon-language-html5:before {
  content: "\e8c3"; }

.icon-language-javascript:before {
  content: "\e8c4"; }

.icon-language-python-text:before {
  content: "\e8c5"; }

.icon-language-python:before {
  content: "\e8c6"; }

.icon-laptop-chromebook:before {
  content: "\e8c7"; }

.icon-laptop-mac:before {
  content: "\e8c8"; }

.icon-laptop-windows:before {
  content: "\e8c9"; }

.icon-laptop:before {
  content: "\e8ca"; }

.icon-lastfm:before {
  content: "\e8cb"; }

.icon-launch:before {
  content: "\e8cc"; }

.icon-layers-off:before {
  content: "\e8cd"; }

.icon-layers:before {
  content: "\e8ce"; }

.icon-leaf:before {
  content: "\e8cf"; }

.icon-library-books:before {
  content: "\e8d0"; }

.icon-library-music:before {
  content: "\e8d1"; }

.icon-library-plus:before {
  content: "\e8d2"; }

.icon-library:before {
  content: "\e8d3"; }

.icon-lightbulb-outline:before {
  content: "\e8d4"; }

.icon-lightbulb:before {
  content: "\e8d5"; }

.icon-link-off:before {
  content: "\e8d6"; }

.icon-link-variant-off:before {
  content: "\e8d7"; }

.icon-link-variant:before {
  content: "\e8d8"; }

.icon-link:before {
  content: "\e8d9"; }

.icon-linkedin-box:before {
  content: "\e8da"; }

.icon-linkedin:before {
  content: "\e8db"; }

.icon-linode:before {
  content: "\e8dc"; }

.icon-linux:before {
  content: "\e8dd"; }

.icon-lock-outline:before {
  content: "\e8de"; }

.icon-lock-unlocked-outline:before {
  content: "\e8df"; }

.icon-lock-unlocked:before {
  content: "\e8e0"; }

.icon-lock:before {
  content: "\e8e1"; }

.icon-login:before {
  content: "\e8e2"; }

.icon-logout:before {
  content: "\e8e3"; }

.icon-looks:before {
  content: "\e8e4"; }

.icon-loupe:before {
  content: "\e8e5"; }

.icon-lumx:before {
  content: "\e8e6"; }

.icon-magnet-on:before {
  content: "\e8e7"; }

.icon-magnet:before {
  content: "\e8e8"; }

.icon-magnify-minus:before {
  content: "\e8e9"; }

.icon-magnify-plus:before {
  content: "\e8ea"; }

.icon-magnify:before {
  content: "\e8eb"; }

.icon-mail-ru:before {
  content: "\e8ec"; }

.icon-map-marker-circle:before {
  content: "\e8ed"; }

.icon-map-marker-multiple:before {
  content: "\e8ee"; }

.icon-map-marker-off:before {
  content: "\e8ef"; }

.icon-map-marker-radius:before {
  content: "\e8f0"; }

.icon-map-marker:before {
  content: "\e8f1"; }

.icon-map:before {
  content: "\e8f2"; }

.icon-margin:before {
  content: "\e8f3"; }

.icon-markdown:before {
  content: "\e8f4"; }

.icon-marker-check:before {
  content: "\e8f5"; }

.icon-martini:before {
  content: "\e8f6"; }

.icon-material-ui:before {
  content: "\e8f7"; }

.icon-maxcdn:before {
  content: "\e8f8"; }

.icon-medium:before {
  content: "\e8f9"; }

.icon-memory:before {
  content: "\e8fa"; }

.icon-menu-down:before {
  content: "\e8fb"; }

.icon-menu-left:before {
  content: "\e8fc"; }

.icon-menu-right:before {
  content: "\e8fd"; }

.icon-menu-up:before {
  content: "\e8fe"; }

.icon-menu:before {
  content: "\e8ff"; }

.icon-message-alert:before {
  content: "\e900"; }

.icon-message-draw:before {
  content: "\e901"; }

.icon-message-image:before {
  content: "\e902"; }

.icon-message-processing:before {
  content: "\e903"; }

.icon-message-reply:before {
  content: "\e904"; }

.icon-message-text-outline:before {
  content: "\e905"; }

.icon-message-text:before {
  content: "\e906"; }

.icon-message-video:before {
  content: "\e907"; }

.icon-message:before {
  content: "\e908"; }

.icon-microphone-dots:before {
  content: "\e909"; }

.icon-microphone-off:before {
  content: "\e90a"; }

.icon-microphone-outline:before {
  content: "\e90b"; }

.icon-microphone-settings:before {
  content: "\e90c"; }

.icon-microphone-variant-off:before {
  content: "\e90d"; }

.icon-microphone-variant:before {
  content: "\e90e"; }

.icon-microphone:before {
  content: "\e90f"; }

.icon-minus-box:before {
  content: "\e910"; }

.icon-minus-circle-outline:before {
  content: "\e911"; }

.icon-minus-circle:before {
  content: "\e912"; }

.icon-minus-network:before {
  content: "\e913"; }

.icon-minus:before {
  content: "\e914"; }

.icon-monitor-multiple:before {
  content: "\e915"; }

.icon-monitor:before {
  content: "\e916"; }

.icon-more:before {
  content: "\e917"; }

.icon-mouse-off:before {
  content: "\e918"; }

.icon-mouse-variant-off:before {
  content: "\e919"; }

.icon-mouse-variant:before {
  content: "\e91a"; }

.icon-mouse:before {
  content: "\e91b"; }

.icon-movie:before {
  content: "\e91c"; }

.icon-multiplication-box:before {
  content: "\e91d"; }

.icon-multiplication:before {
  content: "\e91e"; }

.icon-music-box-outline:before {
  content: "\e91f"; }

.icon-music-box:before {
  content: "\e920"; }

.icon-music-circle:before {
  content: "\e921"; }

.icon-music-note-eighth:before {
  content: "\e922"; }

.icon-music-note-half:before {
  content: "\e923"; }

.icon-music-note-off:before {
  content: "\e924"; }

.icon-music-note-quarter:before {
  content: "\e925"; }

.icon-music-note-sixteenth:before {
  content: "\e926"; }

.icon-music-note-whole:before {
  content: "\e927"; }

.icon-music-note:before {
  content: "\e928"; }

.icon-nature-people:before {
  content: "\e929"; }

.icon-nature:before {
  content: "\e92a"; }

.icon-navigation:before {
  content: "\e92b"; }

.icon-needle:before {
  content: "\e92c"; }

.icon-nest-protect:before {
  content: "\e92d"; }

.icon-nest-thermostat:before {
  content: "\e92e"; }

.icon-newspaper:before {
  content: "\e92f"; }

.icon-nfc-tap:before {
  content: "\e930"; }

.icon-nfc-variant:before {
  content: "\e931"; }

.icon-nfc:before {
  content: "\e932"; }

.icon-no:before {
  content: "\e933"; }

.icon-not-equal:before {
  content: "\e934"; }

.icon-note-outline:before {
  content: "\e935"; }

.icon-note-text:before {
  content: "\e936"; }

.icon-note:before {
  content: "\e937"; }

.icon-numeric-0-box-multiple-outline:before {
  content: "\e938"; }

.icon-numeric-0-box-outline:before {
  content: "\e939"; }

.icon-numeric-0-box:before {
  content: "\e93a"; }

.icon-numeric-1-box-multiple-outline:before {
  content: "\e93b"; }

.icon-numeric-1-box-outline:before {
  content: "\e93c"; }

.icon-numeric-1-box:before {
  content: "\e93d"; }

.icon-numeric-2-box-multiple-outline:before {
  content: "\e93e"; }

.icon-numeric-2-box-outline:before {
  content: "\e93f"; }

.icon-numeric-2-box:before {
  content: "\e940"; }

.icon-numeric-3-box-multiple-outline:before {
  content: "\e941"; }

.icon-numeric-3-box-outline:before {
  content: "\e942"; }

.icon-numeric-3-box:before {
  content: "\e943"; }

.icon-numeric-4-box-multiple-outline:before {
  content: "\e944"; }

.icon-numeric-4-box-outline:before {
  content: "\e945"; }

.icon-numeric-4-box:before {
  content: "\e946"; }

.icon-numeric-5-box-multiple-outline:before {
  content: "\e947"; }

.icon-numeric-5-box-outline:before {
  content: "\e948"; }

.icon-numeric-5-box:before {
  content: "\e949"; }

.icon-numeric-6-box-multiple-outline:before {
  content: "\e94a"; }

.icon-numeric-6-box-outline:before {
  content: "\e94b"; }

.icon-numeric-6-box:before {
  content: "\e94c"; }

.icon-numeric-7-box-multiple-outline:before {
  content: "\e94d"; }

.icon-numeric-7-box-outline:before {
  content: "\e94e"; }

.icon-numeric-7-box:before {
  content: "\e94f"; }

.icon-numeric-8-box-multiple-outline:before {
  content: "\e950"; }

.icon-numeric-8-box-outline:before {
  content: "\e951"; }

.icon-numeric-8-box:before {
  content: "\e952"; }

.icon-numeric-9-box-multiple-outline:before {
  content: "\e953"; }

.icon-numeric-9-box-outline:before {
  content: "\e954"; }

.icon-numeric-9-box:before {
  content: "\e955"; }

.icon-numeric-9-plus-box-multiple-outline:before {
  content: "\e956"; }

.icon-numeric-9-plus-box-outline:before {
  content: "\e957"; }

.icon-numeric-9-plus-box:before {
  content: "\e958"; }

.icon-numeric:before {
  content: "\e959"; }

.icon-nutriton:before {
  content: "\e95a"; }

.icon-odnoklassniki:before {
  content: "\e95b"; }

.icon-office:before {
  content: "\e95c"; }

.icon-oil:before {
  content: "\e95d"; }

.icon-omega:before {
  content: "\e95e"; }

.icon-onedrive:before {
  content: "\e95f"; }

.icon-open-in-app:before {
  content: "\e960"; }

.icon-open-in-new:before {
  content: "\e961"; }

.icon-ornament-variant:before {
  content: "\e962"; }

.icon-ornament:before {
  content: "\e963"; }

.icon-outbox:before {
  content: "\e964"; }

.icon-outdent:before {
  content: "\e965"; }

.icon-owl:before {
  content: "\e966"; }

.icon-package-down:before {
  content: "\e967"; }

.icon-package-up:before {
  content: "\e968"; }

.icon-package-variant-closed:before {
  content: "\e969"; }

.icon-package-variant:before {
  content: "\e96a"; }

.icon-package:before {
  content: "\e96b"; }

.icon-palette-advanced:before {
  content: "\e96c"; }

.icon-palette:before {
  content: "\e96d"; }

.icon-panda:before {
  content: "\e96e"; }

.icon-pandora:before {
  content: "\e96f"; }

.icon-panorama-fisheye:before {
  content: "\e970"; }

.icon-panorama-horizontal:before {
  content: "\e971"; }

.icon-panorama-vertical:before {
  content: "\e972"; }

.icon-panorama-wide-angle:before {
  content: "\e973"; }

.icon-panorama:before {
  content: "\e974"; }

.icon-paper-cut-vertical:before {
  content: "\e975"; }

.icon-paperclip:before {
  content: "\e976"; }

.icon-parking:before {
  content: "\e977"; }

.icon-pause-circle-outline:before {
  content: "\e978"; }

.icon-pause-circle:before {
  content: "\e979"; }

.icon-pause-octagon-outline:before {
  content: "\e97a"; }

.icon-pause-octagon:before {
  content: "\e97b"; }

.icon-pause:before {
  content: "\e97c"; }

.icon-paw:before {
  content: "\e97d"; }

.icon-pen:before {
  content: "\e97e"; }

.icon-pencil-box-outline:before {
  content: "\e97f"; }

.icon-pencil-box:before {
  content: "\e980"; }

.icon-pencil:before {
  content: "\e981"; }

.icon-people:before {
  content: "\e982"; }

.icon-percent:before {
  content: "\e983"; }

.icon-person-box:before {
  content: "\e984"; }

.icon-person-minus:before {
  content: "\e985"; }

.icon-person-plus:before {
  content: "\e986"; }

.icon-pharmacy:before {
  content: "\e987"; }

.icon-phone-bluetooth:before {
  content: "\e988"; }

.icon-phone-dots:before {
  content: "\e989"; }

.icon-phone-forward:before {
  content: "\e98a"; }

.icon-phone-hangup:before {
  content: "\e98b"; }

.icon-phone-in-talk:before {
  content: "\e98c"; }

.icon-phone-incoming:before {
  content: "\e98d"; }

.icon-phone-locked:before {
  content: "\e98e"; }

.icon-phone-log:before {
  content: "\e98f"; }

.icon-phone-missed:before {
  content: "\e990"; }

.icon-phone-outgoing:before {
  content: "\e991"; }

.icon-phone-paused:before {
  content: "\e992"; }

.icon-phone:before {
  content: "\e993"; }

.icon-picture:before {
  content: "\e994"; }

.icon-pig:before {
  content: "\e995"; }

.icon-pill:before {
  content: "\e996"; }

.icon-pin-off:before {
  content: "\e997"; }

.icon-pin:before {
  content: "\e998"; }

.icon-pine-tree-box:before {
  content: "\e999"; }

.icon-pine-tree:before {
  content: "\e99a"; }

.icon-pinterest-box:before {
  content: "\e99b"; }

.icon-pinterest:before {
  content: "\e99c"; }

.icon-pizza:before {
  content: "\e99d"; }

.icon-plane:before {
  content: "\e99e"; }

.icon-play-box-outline:before {
  content: "\e99f"; }

.icon-play-circle-outline:before {
  content: "\e9a0"; }

.icon-play-circle:before {
  content: "\e9a1"; }

.icon-play:before {
  content: "\e9a2"; }

.icon-playlist-minus:before {
  content: "\e9a3"; }

.icon-playlist-plus:before {
  content: "\e9a4"; }

.icon-playstation:before {
  content: "\e9a5"; }

.icon-plus-box:before {
  content: "\e9a6"; }

.icon-plus-circle-outline:before {
  content: "\e9a7"; }

.icon-plus-circle:before {
  content: "\e9a8"; }

.icon-plus-network:before {
  content: "\e9a9"; }

.icon-plus-one:before {
  content: "\e9aa"; }

.icon-plus:before {
  content: "\e9ab"; }

.icon-pocket:before {
  content: "\e9ac"; }

.icon-poll-box:before {
  content: "\e9ad"; }

.icon-poll:before {
  content: "\e9ae"; }

.icon-polymer:before {
  content: "\e9af"; }

.icon-popcorn:before {
  content: "\e9b0"; }

.icon-pound-box:before {
  content: "\e9b1"; }

.icon-pound:before {
  content: "\e9b2"; }

.icon-power-settings:before {
  content: "\e9b3"; }

.icon-power-socket:before {
  content: "\e9b4"; }

.icon-power:before {
  content: "\e9b5"; }

.icon-presentation-play:before {
  content: "\e9b6"; }

.icon-presentation:before {
  content: "\e9b7"; }

.icon-printer-3d:before {
  content: "\e9b8"; }

.icon-printer:before {
  content: "\e9b9"; }

.icon-pulse:before {
  content: "\e9ba"; }

.icon-puzzle:before {
  content: "\e9bb"; }

.icon-qrcode:before {
  content: "\e9bc"; }

.icon-quadcopter:before {
  content: "\e9bd"; }

.icon-quality-high:before {
  content: "\e9be"; }

.icon-question-mark-circle:before {
  content: "\e9bf"; }

.icon-quick-reply:before {
  content: "\e9c0"; }

.icon-quicktime:before {
  content: "\e9c1"; }

.icon-rabbit:before {
  content: "\e9c2"; }

.icon-radiator:before {
  content: "\e9c3"; }

.icon-radio-tower:before {
  content: "\e9c4"; }

.icon-radio:before {
  content: "\e9c5"; }

.icon-radioactive:before {
  content: "\e9c6"; }

.icon-radiobox-blank:before {
  content: "\e9c7"; }

.icon-radiobox-marked:before {
  content: "\e9c8"; }

.icon-raspberrypi:before {
  content: "\e9c9"; }

.icon-rdio:before {
  content: "\e9ca"; }

.icon-read:before {
  content: "\e9cb"; }

.icon-readability:before {
  content: "\e9cc"; }

.icon-receipt:before {
  content: "\e9cd"; }

.icon-recycle:before {
  content: "\e9ce"; }

.icon-reddit:before {
  content: "\e9cf"; }

.icon-redo-variant:before {
  content: "\e9d0"; }

.icon-redo:before {
  content: "\e9d1"; }

.icon-refresh:before {
  content: "\e9d2"; }

.icon-relative-scale:before {
  content: "\e9d3"; }

.icon-reload:before {
  content: "\e9d4"; }

.icon-remote:before {
  content: "\e9d5"; }

.icon-rename-box:before {
  content: "\e9d6"; }

.icon-repeat-off:before {
  content: "\e9d7"; }

.icon-repeat-once:before {
  content: "\e9d8"; }

.icon-repeat:before {
  content: "\e9d9"; }

.icon-replay:before {
  content: "\e9da"; }

.icon-reply-all:before {
  content: "\e9db"; }

.icon-reply:before {
  content: "\e9dc"; }

.icon-reproduction:before {
  content: "\e9dd"; }

.icon-resize-bottom-right:before {
  content: "\e9de"; }

.icon-responsive:before {
  content: "\e9df"; }

.icon-restore:before {
  content: "\e9e0"; }

.icon-rewind:before {
  content: "\e9e1"; }

.icon-ribbon:before {
  content: "\e9e2"; }

.icon-road:before {
  content: "\e9e3"; }

.icon-rocket:before {
  content: "\e9e4"; }

.icon-rotate-3d:before {
  content: "\e9e5"; }

.icon-rotate-left-variant:before {
  content: "\e9e6"; }

.icon-rotate-left:before {
  content: "\e9e7"; }

.icon-rotate-right-variant:before {
  content: "\e9e8"; }

.icon-rotate-right:before {
  content: "\e9e9"; }

.icon-routes:before {
  content: "\e9ea"; }

.icon-rss-box:before {
  content: "\e9eb"; }

.icon-ruler:before {
  content: "\e9ec"; }

.icon-run:before {
  content: "\e9ed"; }

.icon-sale:before {
  content: "\e9ee"; }

.icon-satellite-variant:before {
  content: "\e9ef"; }

.icon-satellite:before {
  content: "\e9f0"; }

.icon-scale-bathroom:before {
  content: "\e9f1"; }

.icon-scale:before {
  content: "\e9f2"; }

.icon-school:before {
  content: "\e9f3"; }

.icon-screen-rotation-lock:before {
  content: "\e9f4"; }

.icon-screen-rotation:before {
  content: "\e9f5"; }

.icon-script:before {
  content: "\e9f6"; }

.icon-security-network:before {
  content: "\e9f7"; }

.icon-security:before {
  content: "\e9f8"; }

.icon-select-all:before {
  content: "\e9f9"; }

.icon-select-inverse:before {
  content: "\e9fa"; }

.icon-select-off:before {
  content: "\e9fb"; }

.icon-select:before {
  content: "\e9fc"; }

.icon-send:before {
  content: "\e9fd"; }

.icon-server-minus:before {
  content: "\e9fe"; }

.icon-server-network-off:before {
  content: "\e9ff"; }

.icon-server-network:before {
  content: "\ea00"; }

.icon-server-off:before {
  content: "\ea01"; }

.icon-server-plus:before {
  content: "\ea02"; }

.icon-server-remove:before {
  content: "\ea03"; }

.icon-server-security:before {
  content: "\ea04"; }

.icon-server:before {
  content: "\ea05"; }

.icon-shape-plus:before {
  content: "\ea06"; }

.icon-share-variant:before {
  content: "\ea07"; }

.icon-share:before {
  content: "\ea08"; }

.icon-shield-outline:before {
  content: "\ea09"; }

.icon-shield:before {
  content: "\ea0a"; }

.icon-shopping-music:before {
  content: "\ea0b"; }

.icon-shopping:before {
  content: "\ea0c"; }

.icon-shuffle:before {
  content: "\ea0d"; }

.icon-sigma:before {
  content: "\ea0e"; }

.icon-sign-caution:before {
  content: "\ea0f"; }

.icon-signal:before {
  content: "\ea10"; }

.icon-silverware-fork:before {
  content: "\ea11"; }

.icon-silverware-spoon:before {
  content: "\ea12"; }

.icon-silverware-variant:before {
  content: "\ea13"; }

.icon-silverware:before {
  content: "\ea14"; }

.icon-sim-alert:before {
  content: "\ea15"; }

.icon-sim:before {
  content: "\ea16"; }

.icon-sitemap:before {
  content: "\ea17"; }

.icon-skip-next:before {
  content: "\ea18"; }

.icon-skip-previous:before {
  content: "\ea19"; }

.icon-skype-business:before {
  content: "\ea1a"; }

.icon-skype:before {
  content: "\ea1b"; }

.icon-sleep-off:before {
  content: "\ea1c"; }

.icon-sleep:before {
  content: "\ea1d"; }

.icon-smoking-off:before {
  content: "\ea1e"; }

.icon-smoking:before {
  content: "\ea1f"; }

.icon-snapchat:before {
  content: "\ea20"; }

.icon-snowman:before {
  content: "\ea21"; }

.icon-sofa:before {
  content: "\ea22"; }

.icon-sort-alphabetical:before {
  content: "\ea23"; }

.icon-sort-ascending:before {
  content: "\ea24"; }

.icon-sort-descending:before {
  content: "\ea25"; }

.icon-sort-numeric:before {
  content: "\ea26"; }

.icon-sort-variant:before {
  content: "\ea27"; }

.icon-sort:before {
  content: "\ea28"; }

.icon-soundcloud:before {
  content: "\ea29"; }

.icon-source-fork:before {
  content: "\ea2a"; }

.icon-source-pull:before {
  content: "\ea2b"; }

.icon-speaker-off:before {
  content: "\ea2c"; }

.icon-speaker:before {
  content: "\ea2d"; }

.icon-speedometer:before {
  content: "\ea2e"; }

.icon-spellcheck:before {
  content: "\ea2f"; }

.icon-spotify:before {
  content: "\ea30"; }

.icon-spotlight-beam:before {
  content: "\ea31"; }

.icon-spotlight:before {
  content: "\ea32"; }

.icon-spreadsheet:before {
  content: "\ea33"; }

.icon-square-inc-cash:before {
  content: "\ea34"; }

.icon-square-inc:before {
  content: "\ea35"; }

.icon-stackoverflow:before {
  content: "\ea36"; }

.icon-star-circle:before {
  content: "\ea37"; }

.icon-star-half:before {
  content: "\ea38"; }

.icon-star-of-david:before {
  content: "\ea39"; }

.icon-star-outline:before {
  content: "\ea3a"; }

.icon-star:before {
  content: "\ea3b"; }

.icon-steam:before {
  content: "\ea3c"; }

.icon-stethoscope:before {
  content: "\ea3d"; }

.icon-stocking:before {
  content: "\ea3e"; }

.icon-stop:before {
  content: "\ea3f"; }

.icon-store-24-hour:before {
  content: "\ea40"; }

.icon-store:before {
  content: "\ea41"; }

.icon-stove:before {
  content: "\ea42"; }

.icon-subway:before {
  content: "\ea43"; }

.icon-sunglasses:before {
  content: "\ea44"; }

.icon-swap-horizontal:before {
  content: "\ea45"; }

.icon-swap-vertical:before {
  content: "\ea46"; }

.icon-swim:before {
  content: "\ea47"; }

.icon-sword:before {
  content: "\ea48"; }

.icon-sync-alert:before {
  content: "\ea49"; }

.icon-sync-off:before {
  content: "\ea4a"; }

.icon-sync:before {
  content: "\ea4b"; }

.icon-tab-unselected:before {
  content: "\ea4c"; }

.icon-tab:before {
  content: "\ea4d"; }

.icon-table-column-plus-after:before {
  content: "\ea4e"; }

.icon-table-column-plus-before:before {
  content: "\ea4f"; }

.icon-table-column-remove:before {
  content: "\ea50"; }

.icon-table-column-width:before {
  content: "\ea51"; }

.icon-table-edit:before {
  content: "\ea52"; }

.icon-table-large:before {
  content: "\ea53"; }

.icon-table-row-height:before {
  content: "\ea54"; }

.icon-table-row-plus-after:before {
  content: "\ea55"; }

.icon-table-row-plus-before:before {
  content: "\ea56"; }

.icon-table-row-remove:before {
  content: "\ea57"; }

.icon-table:before {
  content: "\ea58"; }

.icon-tablet-android:before {
  content: "\ea59"; }

.icon-tablet-ipad:before {
  content: "\ea5a"; }

.icon-tablet:before {
  content: "\ea5b"; }

.icon-tag-faces:before {
  content: "\ea5c"; }

.icon-tag-multiple:before {
  content: "\ea5d"; }

.icon-tag-outline:before {
  content: "\ea5e"; }

.icon-tag-text-outline:before {
  content: "\ea5f"; }

.icon-tag:before {
  content: "\ea60"; }

.icon-taxi:before {
  content: "\ea61"; }

.icon-teamviewer:before {
  content: "\ea62"; }

.icon-telegram:before {
  content: "\ea63"; }

.icon-television-guide:before {
  content: "\ea64"; }

.icon-television:before {
  content: "\ea65"; }

.icon-temperature-celsius:before {
  content: "\ea66"; }

.icon-temperature-fahrenheit:before {
  content: "\ea67"; }

.icon-temperature-kelvin:before {
  content: "\ea68"; }

.icon-tennis:before {
  content: "\ea69"; }

.icon-tent:before {
  content: "\ea6a"; }

.icon-terrain:before {
  content: "\ea6b"; }

.icon-text-to-speech-off:before {
  content: "\ea6c"; }

.icon-text-to-speech:before {
  content: "\ea6d"; }

.icon-texture:before {
  content: "\ea6e"; }

.icon-theater:before {
  content: "\ea6f"; }

.icon-theme-light-dark:before {
  content: "\ea70"; }

.icon-thermometer-lines:before {
  content: "\ea71"; }

.icon-thermometer:before {
  content: "\ea72"; }

.icon-thumb-down-outline:before {
  content: "\ea73"; }

.icon-thumb-down:before {
  content: "\ea74"; }

.icon-thumb-up-outline:before {
  content: "\ea75"; }

.icon-thumb-up:before {
  content: "\ea76"; }

.icon-thumbs-up-down:before {
  content: "\ea77"; }

.icon-ticket-account:before {
  content: "\ea78"; }

.icon-ticket:before {
  content: "\ea79"; }

.icon-tie:before {
  content: "\ea7a"; }

.icon-tile-four:before {
  content: "\ea7b"; }

.icon-timelapse:before {
  content: "\ea7c"; }

.icon-timer-3:before {
  content: "\ea7d"; }

.icon-timer-10:before {
  content: "\ea7e"; }

.icon-timer-off:before {
  content: "\ea7f"; }

.icon-timer-sand:before {
  content: "\ea80"; }

.icon-timer:before {
  content: "\ea81"; }

.icon-timetable:before {
  content: "\ea82"; }

.icon-toggle-switch-off:before {
  content: "\ea83"; }

.icon-toggle-switch-on:before {
  content: "\ea84"; }

.icon-tooltip-edit:before {
  content: "\ea85"; }

.icon-tooltip-image:before {
  content: "\ea86"; }

.icon-tooltip-outline-plus:before {
  content: "\ea87"; }

.icon-tooltip-outline:before {
  content: "\ea88"; }

.icon-tooltip-text:before {
  content: "\ea89"; }

.icon-tooltip:before {
  content: "\ea8a"; }

.icon-tor:before {
  content: "\ea8b"; }

.icon-traffic-light:before {
  content: "\ea8c"; }

.icon-train:before {
  content: "\ea8d"; }

.icon-tram:before {
  content: "\ea8e"; }

.icon-transcribe-close:before {
  content: "\ea8f"; }

.icon-transcribe:before {
  content: "\ea90"; }

.icon-transfer:before {
  content: "\ea91"; }

.icon-trash:before {
  content: "\ea92"; }

.icon-tree:before {
  content: "\ea93"; }

.icon-trello:before {
  content: "\ea94"; }

.icon-trending-down:before {
  content: "\ea95"; }

.icon-trending-neutral:before {
  content: "\ea96"; }

.icon-trending-up:before {
  content: "\ea97"; }

.icon-truck:before {
  content: "\ea98"; }

.icon-tshirt-crew:before {
  content: "\ea99"; }

.icon-tshirt-v:before {
  content: "\ea9a"; }

.icon-tumblr-reblog:before {
  content: "\ea9b"; }

.icon-tumblr:before {
  content: "\ea9c"; }

.icon-twitch:before {
  content: "\ea9d"; }

.icon-twitter-box:before {
  content: "\ea9e"; }

.icon-twitter-circle:before {
  content: "\ea9f"; }

.icon-twitter-retweet:before {
  content: "\eaa0"; }

.icon-twitter:before {
  content: "\eaa1"; }

.icon-ubuntu:before {
  content: "\eaa2"; }

.icon-umbrella-outline:before {
  content: "\eaa3"; }

.icon-umbrella:before {
  content: "\eaa4"; }

.icon-undo-variant:before {
  content: "\eaa5"; }

.icon-undo:before {
  content: "\eaa6"; }

.icon-unfold-less:before {
  content: "\eaa7"; }

.icon-unfold-more:before {
  content: "\eaa8"; }

.icon-ungroup:before {
  content: "\eaa9"; }

.icon-untappd:before {
  content: "\eaaa"; }

.icon-upload:before {
  content: "\eaab"; }

.icon-usb:before {
  content: "\eaac"; }

.icon-vector-curve:before {
  content: "\eaad"; }

.icon-vector-point:before {
  content: "\eaae"; }

.icon-vector-square:before {
  content: "\eaaf"; }

.icon-verified:before {
  content: "\eab0"; }

.icon-vibration:before {
  content: "\eab1"; }

.icon-video-off:before {
  content: "\eab2"; }

.icon-video-switch:before {
  content: "\eab3"; }

.icon-video:before {
  content: "\eab4"; }

.icon-view-agenda:before {
  content: "\eab5"; }

.icon-view-array:before {
  content: "\eab6"; }

.icon-view-carousel:before {
  content: "\eab7"; }

.icon-view-column:before {
  content: "\eab8"; }

.icon-view-dashboard:before {
  content: "\eab9"; }

.icon-view-day:before {
  content: "\eaba"; }

.icon-view-headline:before {
  content: "\eabb"; }

.icon-view-list:before {
  content: "\eabc"; }

.icon-view-module:before {
  content: "\eabd"; }

.icon-view-quilt:before {
  content: "\eabe"; }

.icon-view-stream:before {
  content: "\eabf"; }

.icon-view-week:before {
  content: "\eac0"; }

.icon-vimeo:before {
  content: "\eac1"; }

.icon-vine:before {
  content: "\eac2"; }

.icon-vk-box:before {
  content: "\eac3"; }

.icon-vk-circle:before {
  content: "\eac4"; }

.icon-vk:before {
  content: "\eac5"; }

.icon-voicemail:before {
  content: "\eac6"; }

.icon-volume-high:before {
  content: "\eac7"; }

.icon-volume-low:before {
  content: "\eac8"; }

.icon-volume-medium:before {
  content: "\eac9"; }

.icon-volume-off:before {
  content: "\eaca"; }

.icon-volume:before {
  content: "\eacb"; }

.icon-vpn:before {
  content: "\eacc"; }

.icon-walk:before {
  content: "\eacd"; }

.icon-wallet-giftcard:before {
  content: "\eace"; }

.icon-wallet-membership:before {
  content: "\eacf"; }

.icon-wallet-travel:before {
  content: "\ead0"; }

.icon-wallet:before {
  content: "\ead1"; }

.icon-washing-machine:before {
  content: "\ead2"; }

.icon-watch-export:before {
  content: "\ead3"; }

.icon-watch-import:before {
  content: "\ead4"; }

.icon-watch:before {
  content: "\ead5"; }

.icon-water-off:before {
  content: "\ead6"; }

.icon-water-pump:before {
  content: "\ead7"; }

.icon-water:before {
  content: "\ead8"; }

.icon-weather-cloudy:before {
  content: "\ead9"; }

.icon-weather-fog:before {
  content: "\eada"; }

.icon-weather-hail:before {
  content: "\eadb"; }

.icon-weather-lightning:before {
  content: "\eadc"; }

.icon-weather-night:before {
  content: "\eadd"; }

.icon-weather-partlycloudy:before {
  content: "\eade"; }

.icon-weather-pouring:before {
  content: "\eadf"; }

.icon-weather-rainy:before {
  content: "\eae0"; }

.icon-weather-snowy:before {
  content: "\eae1"; }

.icon-weather-sunny:before {
  content: "\eae2"; }

.icon-weather-sunset-down:before {
  content: "\eae3"; }

.icon-weather-sunset-up:before {
  content: "\eae4"; }

.icon-weather-sunset:before {
  content: "\eae5"; }

.icon-weather-windy-variant:before {
  content: "\eae6"; }

.icon-weather-windy:before {
  content: "\eae7"; }

.icon-web:before {
  content: "\eae8"; }

.icon-webcam:before {
  content: "\eae9"; }

.icon-weight-kilogram:before {
  content: "\eaea"; }

.icon-weight:before {
  content: "\eaeb"; }

.icon-whatsapp:before {
  content: "\eaec"; }

.icon-wheelchair-accessibility:before {
  content: "\eaed"; }

.icon-white-balance-auto:before {
  content: "\eaee"; }

.icon-white-balance-incandescent:before {
  content: "\eaef"; }

.icon-white-balance-irradescent:before {
  content: "\eaf0"; }

.icon-white-balance-sunny:before {
  content: "\eaf1"; }

.icon-wifi:before {
  content: "\eaf2"; }

.icon-wii:before {
  content: "\eaf3"; }

.icon-wikipedia:before {
  content: "\eaf4"; }

.icon-window-close:before {
  content: "\eaf5"; }

.icon-window-closed:before {
  content: "\eaf6"; }

.icon-window-maximize:before {
  content: "\eaf7"; }

.icon-window-minimize:before {
  content: "\eaf8"; }

.icon-window-open:before {
  content: "\eaf9"; }

.icon-window-restore:before {
  content: "\eafa"; }

.icon-windows:before {
  content: "\eafb"; }

.icon-wordpress:before {
  content: "\eafc"; }

.icon-worker:before {
  content: "\eafd"; }

.icon-wunderlist:before {
  content: "\eafe"; }

.icon-xbox-controller-off:before {
  content: "\eaff"; }

.icon-xbox-controller:before {
  content: "\eb00"; }

.icon-xbox:before {
  content: "\eb01"; }

.icon-xda:before {
  content: "\eb02"; }

.icon-xml:before {
  content: "\eb03"; }

.icon-yeast:before {
  content: "\eb04"; }

.icon-yelp:before {
  content: "\eb05"; }

.icon-youtube-play:before {
  content: "\eb06"; }

.icon-youtube:before {
  content: "\eb07"; }

.icon-zip-box:before {
  content: "\eb08"; }

@font-face {
  font-family: "icomoon";
  src: url("../assets/icons/fontmd/materialdesignicons-webfont.eot");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'icomoon';
  src: url("../assets/icons/fontmd/materialdesignicons-webfont.eot?-cmq1um");
  src: url("../assets/icons/fontmd/materialdesignicons-webfont.eot?#iefix-cmq1um") format("embedded-opentype"), url("../assets/icons/fontmd/materialdesignicons-webfont.woff?-cmq1um") format("woff"), url("../assets/icons/fontmd/materialdesignicons-webfont.ttf?-cmq1um") format("truetype"), url("../assets/icons/fontmd/materialdesignicons-webfont.svg?-cmq1um#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="mdi-"], [class*=" mdi-"] {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.mdi-access-point:before {
  content: "\F002"; }

.mdi-access-point-network:before {
  content: "\F003"; }

.mdi-access-point-network-off:before {
  content: "\FBBD"; }

.mdi-account:before {
  content: "\F004"; }

.mdi-account-alert:before {
  content: "\F005"; }

.mdi-account-alert-outline:before {
  content: "\FB2C"; }

.mdi-account-arrow-left:before {
  content: "\FB2D"; }

.mdi-account-arrow-left-outline:before {
  content: "\FB2E"; }

.mdi-account-arrow-right:before {
  content: "\FB2F"; }

.mdi-account-arrow-right-outline:before {
  content: "\FB30"; }

.mdi-account-badge:before {
  content: "\FD83"; }

.mdi-account-badge-alert:before {
  content: "\FD84"; }

.mdi-account-badge-alert-outline:before {
  content: "\FD85"; }

.mdi-account-badge-horizontal:before {
  content: "\FDF0"; }

.mdi-account-badge-horizontal-outline:before {
  content: "\FDF1"; }

.mdi-account-badge-outline:before {
  content: "\FD86"; }

.mdi-account-box:before {
  content: "\F006"; }

.mdi-account-box-multiple:before {
  content: "\F933"; }

.mdi-account-box-outline:before {
  content: "\F007"; }

.mdi-account-card-details:before {
  content: "\F5D2"; }

.mdi-account-card-details-outline:before {
  content: "\FD87"; }

.mdi-account-check:before {
  content: "\F008"; }

.mdi-account-check-outline:before {
  content: "\FBBE"; }

.mdi-account-child:before {
  content: "\FA88"; }

.mdi-account-child-circle:before {
  content: "\FA89"; }

.mdi-account-circle:before {
  content: "\F009"; }

.mdi-account-circle-outline:before {
  content: "\FB31"; }

.mdi-account-clock:before {
  content: "\FB32"; }

.mdi-account-clock-outline:before {
  content: "\FB33"; }

.mdi-account-convert:before {
  content: "\F00A"; }

.mdi-account-details:before {
  content: "\F631"; }

.mdi-account-edit:before {
  content: "\F6BB"; }

.mdi-account-group:before {
  content: "\F848"; }

.mdi-account-group-outline:before {
  content: "\FB34"; }

.mdi-account-heart:before {
  content: "\F898"; }

.mdi-account-heart-outline:before {
  content: "\FBBF"; }

.mdi-account-key:before {
  content: "\F00B"; }

.mdi-account-key-outline:before {
  content: "\FBC0"; }

.mdi-account-minus:before {
  content: "\F00D"; }

.mdi-account-minus-outline:before {
  content: "\FAEB"; }

.mdi-account-multiple:before {
  content: "\F00E"; }

.mdi-account-multiple-check:before {
  content: "\F8C4"; }

.mdi-account-multiple-minus:before {
  content: "\F5D3"; }

.mdi-account-multiple-minus-outline:before {
  content: "\FBC1"; }

.mdi-account-multiple-outline:before {
  content: "\F00F"; }

.mdi-account-multiple-plus:before {
  content: "\F010"; }

.mdi-account-multiple-plus-outline:before {
  content: "\F7FF"; }

.mdi-account-network:before {
  content: "\F011"; }

.mdi-account-network-outline:before {
  content: "\FBC2"; }

.mdi-account-off:before {
  content: "\F012"; }

.mdi-account-off-outline:before {
  content: "\FBC3"; }

.mdi-account-outline:before {
  content: "\F013"; }

.mdi-account-plus:before {
  content: "\F014"; }

.mdi-account-plus-outline:before {
  content: "\F800"; }

.mdi-account-question:before {
  content: "\FB35"; }

.mdi-account-question-outline:before {
  content: "\FB36"; }

.mdi-account-remove:before {
  content: "\F015"; }

.mdi-account-remove-outline:before {
  content: "\FAEC"; }

.mdi-account-search:before {
  content: "\F016"; }

.mdi-account-search-outline:before {
  content: "\F934"; }

.mdi-account-settings:before {
  content: "\F630"; }

.mdi-account-star:before {
  content: "\F017"; }

.mdi-account-star-outline:before {
  content: "\FBC4"; }

.mdi-account-supervisor:before {
  content: "\FA8A"; }

.mdi-account-supervisor-circle:before {
  content: "\FA8B"; }

.mdi-account-switch:before {
  content: "\F019"; }

.mdi-account-tie:before {
  content: "\FCBF"; }

.mdi-accusoft:before {
  content: "\F849"; }

.mdi-adchoices:before {
  content: "\FD1E"; }

.mdi-adjust:before {
  content: "\F01A"; }

.mdi-adobe:before {
  content: "\F935"; }

.mdi-air-conditioner:before {
  content: "\F01B"; }

.mdi-air-filter:before {
  content: "\FD1F"; }

.mdi-air-horn:before {
  content: "\FD88"; }

.mdi-air-purifier:before {
  content: "\FD20"; }

.mdi-airbag:before {
  content: "\FBC5"; }

.mdi-airballoon:before {
  content: "\F01C"; }

.mdi-airplane:before {
  content: "\F01D"; }

.mdi-airplane-landing:before {
  content: "\F5D4"; }

.mdi-airplane-off:before {
  content: "\F01E"; }

.mdi-airplane-takeoff:before {
  content: "\F5D5"; }

.mdi-airplay:before {
  content: "\F01F"; }

.mdi-airport:before {
  content: "\F84A"; }

.mdi-alarm:before {
  content: "\F020"; }

.mdi-alarm-bell:before {
  content: "\F78D"; }

.mdi-alarm-check:before {
  content: "\F021"; }

.mdi-alarm-light:before {
  content: "\F78E"; }

.mdi-alarm-light-outline:before {
  content: "\FBC6"; }

.mdi-alarm-multiple:before {
  content: "\F022"; }

.mdi-alarm-off:before {
  content: "\F023"; }

.mdi-alarm-plus:before {
  content: "\F024"; }

.mdi-alarm-snooze:before {
  content: "\F68D"; }

.mdi-album:before {
  content: "\F025"; }

.mdi-alert:before {
  content: "\F026"; }

.mdi-alert-box:before {
  content: "\F027"; }

.mdi-alert-box-outline:before {
  content: "\FCC0"; }

.mdi-alert-circle:before {
  content: "\F028"; }

.mdi-alert-circle-outline:before {
  content: "\F5D6"; }

.mdi-alert-decagram:before {
  content: "\F6BC"; }

.mdi-alert-decagram-outline:before {
  content: "\FCC1"; }

.mdi-alert-octagon:before {
  content: "\F029"; }

.mdi-alert-octagon-outline:before {
  content: "\FCC2"; }

.mdi-alert-octagram:before {
  content: "\F766"; }

.mdi-alert-octagram-outline:before {
  content: "\FCC3"; }

.mdi-alert-outline:before {
  content: "\F02A"; }

.mdi-alien:before {
  content: "\F899"; }

.mdi-all-inclusive:before {
  content: "\F6BD"; }

.mdi-alpha:before {
  content: "\F02B"; }

.mdi-alpha-a:before {
  content: "\41"; }

.mdi-alpha-a-box:before {
  content: "\FAED"; }

.mdi-alpha-a-box-outline:before {
  content: "\FBC7"; }

.mdi-alpha-a-circle:before {
  content: "\FBC8"; }

.mdi-alpha-a-circle-outline:before {
  content: "\FBC9"; }

.mdi-alpha-b:before {
  content: "\42"; }

.mdi-alpha-b-box:before {
  content: "\FAEE"; }

.mdi-alpha-b-box-outline:before {
  content: "\FBCA"; }

.mdi-alpha-b-circle:before {
  content: "\FBCB"; }

.mdi-alpha-b-circle-outline:before {
  content: "\FBCC"; }

.mdi-alpha-c:before {
  content: "\43"; }

.mdi-alpha-c-box:before {
  content: "\FAEF"; }

.mdi-alpha-c-box-outline:before {
  content: "\FBCD"; }

.mdi-alpha-c-circle:before {
  content: "\FBCE"; }

.mdi-alpha-c-circle-outline:before {
  content: "\FBCF"; }

.mdi-alpha-d:before {
  content: "\44"; }

.mdi-alpha-d-box:before {
  content: "\FAF0"; }

.mdi-alpha-d-box-outline:before {
  content: "\FBD0"; }

.mdi-alpha-d-circle:before {
  content: "\FBD1"; }

.mdi-alpha-d-circle-outline:before {
  content: "\FBD2"; }

.mdi-alpha-e:before {
  content: "\45"; }

.mdi-alpha-e-box:before {
  content: "\FAF1"; }

.mdi-alpha-e-box-outline:before {
  content: "\FBD3"; }

.mdi-alpha-e-circle:before {
  content: "\FBD4"; }

.mdi-alpha-e-circle-outline:before {
  content: "\FBD5"; }

.mdi-alpha-f:before {
  content: "\46"; }

.mdi-alpha-f-box:before {
  content: "\FAF2"; }

.mdi-alpha-f-box-outline:before {
  content: "\FBD6"; }

.mdi-alpha-f-circle:before {
  content: "\FBD7"; }

.mdi-alpha-f-circle-outline:before {
  content: "\FBD8"; }

.mdi-alpha-g:before {
  content: "\47"; }

.mdi-alpha-g-box:before {
  content: "\FAF3"; }

.mdi-alpha-g-box-outline:before {
  content: "\FBD9"; }

.mdi-alpha-g-circle:before {
  content: "\FBDA"; }

.mdi-alpha-g-circle-outline:before {
  content: "\FBDB"; }

.mdi-alpha-h:before {
  content: "\48"; }

.mdi-alpha-h-box:before {
  content: "\FAF4"; }

.mdi-alpha-h-box-outline:before {
  content: "\FBDC"; }

.mdi-alpha-h-circle:before {
  content: "\FBDD"; }

.mdi-alpha-h-circle-outline:before {
  content: "\FBDE"; }

.mdi-alpha-i:before {
  content: "\49"; }

.mdi-alpha-i-box:before {
  content: "\FAF5"; }

.mdi-alpha-i-box-outline:before {
  content: "\FBDF"; }

.mdi-alpha-i-circle:before {
  content: "\FBE0"; }

.mdi-alpha-i-circle-outline:before {
  content: "\FBE1"; }

.mdi-alpha-j:before {
  content: "\4A"; }

.mdi-alpha-j-box:before {
  content: "\FAF6"; }

.mdi-alpha-j-box-outline:before {
  content: "\FBE2"; }

.mdi-alpha-j-circle:before {
  content: "\FBE3"; }

.mdi-alpha-j-circle-outline:before {
  content: "\FBE4"; }

.mdi-alpha-k:before {
  content: "\4B"; }

.mdi-alpha-k-box:before {
  content: "\FAF7"; }

.mdi-alpha-k-box-outline:before {
  content: "\FBE5"; }

.mdi-alpha-k-circle:before {
  content: "\FBE6"; }

.mdi-alpha-k-circle-outline:before {
  content: "\FBE7"; }

.mdi-alpha-l:before {
  content: "\4C"; }

.mdi-alpha-l-box:before {
  content: "\FAF8"; }

.mdi-alpha-l-box-outline:before {
  content: "\FBE8"; }

.mdi-alpha-l-circle:before {
  content: "\FBE9"; }

.mdi-alpha-l-circle-outline:before {
  content: "\FBEA"; }

.mdi-alpha-m:before {
  content: "\4D"; }

.mdi-alpha-m-box:before {
  content: "\FAF9"; }

.mdi-alpha-m-box-outline:before {
  content: "\FBEB"; }

.mdi-alpha-m-circle:before {
  content: "\FBEC"; }

.mdi-alpha-m-circle-outline:before {
  content: "\FBED"; }

.mdi-alpha-n:before {
  content: "\4E"; }

.mdi-alpha-n-box:before {
  content: "\FAFA"; }

.mdi-alpha-n-box-outline:before {
  content: "\FBEE"; }

.mdi-alpha-n-circle:before {
  content: "\FBEF"; }

.mdi-alpha-n-circle-outline:before {
  content: "\FBF0"; }

.mdi-alpha-o:before {
  content: "\4F"; }

.mdi-alpha-o-box:before {
  content: "\FAFB"; }

.mdi-alpha-o-box-outline:before {
  content: "\FBF1"; }

.mdi-alpha-o-circle:before {
  content: "\FBF2"; }

.mdi-alpha-o-circle-outline:before {
  content: "\FBF3"; }

.mdi-alpha-p:before {
  content: "\50"; }

.mdi-alpha-p-box:before {
  content: "\FAFC"; }

.mdi-alpha-p-box-outline:before {
  content: "\FBF4"; }

.mdi-alpha-p-circle:before {
  content: "\FBF5"; }

.mdi-alpha-p-circle-outline:before {
  content: "\FBF6"; }

.mdi-alpha-q:before {
  content: "\51"; }

.mdi-alpha-q-box:before {
  content: "\FAFD"; }

.mdi-alpha-q-box-outline:before {
  content: "\FBF7"; }

.mdi-alpha-q-circle:before {
  content: "\FBF8"; }

.mdi-alpha-q-circle-outline:before {
  content: "\FBF9"; }

.mdi-alpha-r:before {
  content: "\52"; }

.mdi-alpha-r-box:before {
  content: "\FAFE"; }

.mdi-alpha-r-box-outline:before {
  content: "\FBFA"; }

.mdi-alpha-r-circle:before {
  content: "\FBFB"; }

.mdi-alpha-r-circle-outline:before {
  content: "\FBFC"; }

.mdi-alpha-s:before {
  content: "\53"; }

.mdi-alpha-s-box:before {
  content: "\FAFF"; }

.mdi-alpha-s-box-outline:before {
  content: "\FBFD"; }

.mdi-alpha-s-circle:before {
  content: "\FBFE"; }

.mdi-alpha-s-circle-outline:before {
  content: "\FBFF"; }

.mdi-alpha-t:before {
  content: "\54"; }

.mdi-alpha-t-box:before {
  content: "\FB00"; }

.mdi-alpha-t-box-outline:before {
  content: "\FC00"; }

.mdi-alpha-t-circle:before {
  content: "\FC01"; }

.mdi-alpha-t-circle-outline:before {
  content: "\FC02"; }

.mdi-alpha-u:before {
  content: "\55"; }

.mdi-alpha-u-box:before {
  content: "\FB01"; }

.mdi-alpha-u-box-outline:before {
  content: "\FC03"; }

.mdi-alpha-u-circle:before {
  content: "\FC04"; }

.mdi-alpha-u-circle-outline:before {
  content: "\FC05"; }

.mdi-alpha-v:before {
  content: "\56"; }

.mdi-alpha-v-box:before {
  content: "\FB02"; }

.mdi-alpha-v-box-outline:before {
  content: "\FC06"; }

.mdi-alpha-v-circle:before {
  content: "\FC07"; }

.mdi-alpha-v-circle-outline:before {
  content: "\FC08"; }

.mdi-alpha-w:before {
  content: "\57"; }

.mdi-alpha-w-box:before {
  content: "\FB03"; }

.mdi-alpha-w-box-outline:before {
  content: "\FC09"; }

.mdi-alpha-w-circle:before {
  content: "\FC0A"; }

.mdi-alpha-w-circle-outline:before {
  content: "\FC0B"; }

.mdi-alpha-x:before {
  content: "\58"; }

.mdi-alpha-x-box:before {
  content: "\FB04"; }

.mdi-alpha-x-box-outline:before {
  content: "\FC0C"; }

.mdi-alpha-x-circle:before {
  content: "\FC0D"; }

.mdi-alpha-x-circle-outline:before {
  content: "\FC0E"; }

.mdi-alpha-y:before {
  content: "\59"; }

.mdi-alpha-y-box:before {
  content: "\FB05"; }

.mdi-alpha-y-box-outline:before {
  content: "\FC0F"; }

.mdi-alpha-y-circle:before {
  content: "\FC10"; }

.mdi-alpha-y-circle-outline:before {
  content: "\FC11"; }

.mdi-alpha-z:before {
  content: "\5A"; }

.mdi-alpha-z-box:before {
  content: "\FB06"; }

.mdi-alpha-z-box-outline:before {
  content: "\FC12"; }

.mdi-alpha-z-circle:before {
  content: "\FC13"; }

.mdi-alpha-z-circle-outline:before {
  content: "\FC14"; }

.mdi-alphabetical:before {
  content: "\F02C"; }

.mdi-altimeter:before {
  content: "\F5D7"; }

.mdi-amazon:before {
  content: "\F02D"; }

.mdi-amazon-alexa:before {
  content: "\F8C5"; }

.mdi-amazon-drive:before {
  content: "\F02E"; }

.mdi-ambulance:before {
  content: "\F02F"; }

.mdi-ammunition:before {
  content: "\FCC4"; }

.mdi-ampersand:before {
  content: "\FA8C"; }

.mdi-amplifier:before {
  content: "\F030"; }

.mdi-anchor:before {
  content: "\F031"; }

.mdi-android:before {
  content: "\F032"; }

.mdi-android-auto:before {
  content: "\FA8D"; }

.mdi-android-debug-bridge:before {
  content: "\F033"; }

.mdi-android-head:before {
  content: "\F78F"; }

.mdi-android-messages:before {
  content: "\FD21"; }

.mdi-android-studio:before {
  content: "\F034"; }

.mdi-angle-acute:before {
  content: "\F936"; }

.mdi-angle-obtuse:before {
  content: "\F937"; }

.mdi-angle-right:before {
  content: "\F938"; }

.mdi-angular:before {
  content: "\F6B1"; }

.mdi-angularjs:before {
  content: "\F6BE"; }

.mdi-animation:before {
  content: "\F5D8"; }

.mdi-animation-outline:before {
  content: "\FA8E"; }

.mdi-animation-play:before {
  content: "\F939"; }

.mdi-animation-play-outline:before {
  content: "\FA8F"; }

.mdi-anvil:before {
  content: "\F89A"; }

.mdi-apple:before {
  content: "\F035"; }

.mdi-apple-finder:before {
  content: "\F036"; }

.mdi-apple-icloud:before {
  content: "\F038"; }

.mdi-apple-ios:before {
  content: "\F037"; }

.mdi-apple-keyboard-caps:before {
  content: "\F632"; }

.mdi-apple-keyboard-command:before {
  content: "\F633"; }

.mdi-apple-keyboard-control:before {
  content: "\F634"; }

.mdi-apple-keyboard-option:before {
  content: "\F635"; }

.mdi-apple-keyboard-shift:before {
  content: "\F636"; }

.mdi-apple-safari:before {
  content: "\F039"; }

.mdi-application:before {
  content: "\F614"; }

.mdi-application-export:before {
  content: "\FD89"; }

.mdi-application-import:before {
  content: "\FD8A"; }

.mdi-apps:before {
  content: "\F03B"; }

.mdi-apps-box:before {
  content: "\FD22"; }

.mdi-arch:before {
  content: "\F8C6"; }

.mdi-archive:before {
  content: "\F03C"; }

.mdi-arrange-bring-forward:before {
  content: "\F03D"; }

.mdi-arrange-bring-to-front:before {
  content: "\F03E"; }

.mdi-arrange-send-backward:before {
  content: "\F03F"; }

.mdi-arrange-send-to-back:before {
  content: "\F040"; }

.mdi-arrow-all:before {
  content: "\F041"; }

.mdi-arrow-bottom-left:before {
  content: "\F042"; }

.mdi-arrow-bottom-left-bold-outline:before {
  content: "\F9B6"; }

.mdi-arrow-bottom-left-thick:before {
  content: "\F9B7"; }

.mdi-arrow-bottom-right:before {
  content: "\F043"; }

.mdi-arrow-bottom-right-bold-outline:before {
  content: "\F9B8"; }

.mdi-arrow-bottom-right-thick:before {
  content: "\F9B9"; }

.mdi-arrow-collapse:before {
  content: "\F615"; }

.mdi-arrow-collapse-all:before {
  content: "\F044"; }

.mdi-arrow-collapse-down:before {
  content: "\F791"; }

.mdi-arrow-collapse-horizontal:before {
  content: "\F84B"; }

.mdi-arrow-collapse-left:before {
  content: "\F792"; }

.mdi-arrow-collapse-right:before {
  content: "\F793"; }

.mdi-arrow-collapse-up:before {
  content: "\F794"; }

.mdi-arrow-collapse-vertical:before {
  content: "\F84C"; }

.mdi-arrow-decision:before {
  content: "\F9BA"; }

.mdi-arrow-decision-auto:before {
  content: "\F9BB"; }

.mdi-arrow-decision-auto-outline:before {
  content: "\F9BC"; }

.mdi-arrow-decision-outline:before {
  content: "\F9BD"; }

.mdi-arrow-down:before {
  content: "\F045"; }

.mdi-arrow-down-bold:before {
  content: "\F72D"; }

.mdi-arrow-down-bold-box:before {
  content: "\F72E"; }

.mdi-arrow-down-bold-box-outline:before {
  content: "\F72F"; }

.mdi-arrow-down-bold-circle:before {
  content: "\F047"; }

.mdi-arrow-down-bold-circle-outline:before {
  content: "\F048"; }

.mdi-arrow-down-bold-hexagon-outline:before {
  content: "\F049"; }

.mdi-arrow-down-bold-outline:before {
  content: "\F9BE"; }

.mdi-arrow-down-box:before {
  content: "\F6BF"; }

.mdi-arrow-down-circle:before {
  content: "\FCB7"; }

.mdi-arrow-down-circle-outline:before {
  content: "\FCB8"; }

.mdi-arrow-down-drop-circle:before {
  content: "\F04A"; }

.mdi-arrow-down-drop-circle-outline:before {
  content: "\F04B"; }

.mdi-arrow-down-thick:before {
  content: "\F046"; }

.mdi-arrow-expand:before {
  content: "\F616"; }

.mdi-arrow-expand-all:before {
  content: "\F04C"; }

.mdi-arrow-expand-down:before {
  content: "\F795"; }

.mdi-arrow-expand-horizontal:before {
  content: "\F84D"; }

.mdi-arrow-expand-left:before {
  content: "\F796"; }

.mdi-arrow-expand-right:before {
  content: "\F797"; }

.mdi-arrow-expand-up:before {
  content: "\F798"; }

.mdi-arrow-expand-vertical:before {
  content: "\F84E"; }

.mdi-arrow-left:before {
  content: "\F04D"; }

.mdi-arrow-left-bold:before {
  content: "\F730"; }

.mdi-arrow-left-bold-box:before {
  content: "\F731"; }

.mdi-arrow-left-bold-box-outline:before {
  content: "\F732"; }

.mdi-arrow-left-bold-circle:before {
  content: "\F04F"; }

.mdi-arrow-left-bold-circle-outline:before {
  content: "\F050"; }

.mdi-arrow-left-bold-hexagon-outline:before {
  content: "\F051"; }

.mdi-arrow-left-bold-outline:before {
  content: "\F9BF"; }

.mdi-arrow-left-box:before {
  content: "\F6C0"; }

.mdi-arrow-left-circle:before {
  content: "\FCB9"; }

.mdi-arrow-left-circle-outline:before {
  content: "\FCBA"; }

.mdi-arrow-left-drop-circle:before {
  content: "\F052"; }

.mdi-arrow-left-drop-circle-outline:before {
  content: "\F053"; }

.mdi-arrow-left-right-bold-outline:before {
  content: "\F9C0"; }

.mdi-arrow-left-thick:before {
  content: "\F04E"; }

.mdi-arrow-right:before {
  content: "\F054"; }

.mdi-arrow-right-bold:before {
  content: "\F733"; }

.mdi-arrow-right-bold-box:before {
  content: "\F734"; }

.mdi-arrow-right-bold-box-outline:before {
  content: "\F735"; }

.mdi-arrow-right-bold-circle:before {
  content: "\F056"; }

.mdi-arrow-right-bold-circle-outline:before {
  content: "\F057"; }

.mdi-arrow-right-bold-hexagon-outline:before {
  content: "\F058"; }

.mdi-arrow-right-bold-outline:before {
  content: "\F9C1"; }

.mdi-arrow-right-box:before {
  content: "\F6C1"; }

.mdi-arrow-right-circle:before {
  content: "\FCBB"; }

.mdi-arrow-right-circle-outline:before {
  content: "\FCBC"; }

.mdi-arrow-right-drop-circle:before {
  content: "\F059"; }

.mdi-arrow-right-drop-circle-outline:before {
  content: "\F05A"; }

.mdi-arrow-right-thick:before {
  content: "\F055"; }

.mdi-arrow-split-horizontal:before {
  content: "\F93A"; }

.mdi-arrow-split-vertical:before {
  content: "\F93B"; }

.mdi-arrow-top-left:before {
  content: "\F05B"; }

.mdi-arrow-top-left-bold-outline:before {
  content: "\F9C2"; }

.mdi-arrow-top-left-thick:before {
  content: "\F9C3"; }

.mdi-arrow-top-right:before {
  content: "\F05C"; }

.mdi-arrow-top-right-bold-outline:before {
  content: "\F9C4"; }

.mdi-arrow-top-right-thick:before {
  content: "\F9C5"; }

.mdi-arrow-up:before {
  content: "\F05D"; }

.mdi-arrow-up-bold:before {
  content: "\F736"; }

.mdi-arrow-up-bold-box:before {
  content: "\F737"; }

.mdi-arrow-up-bold-box-outline:before {
  content: "\F738"; }

.mdi-arrow-up-bold-circle:before {
  content: "\F05F"; }

.mdi-arrow-up-bold-circle-outline:before {
  content: "\F060"; }

.mdi-arrow-up-bold-hexagon-outline:before {
  content: "\F061"; }

.mdi-arrow-up-bold-outline:before {
  content: "\F9C6"; }

.mdi-arrow-up-box:before {
  content: "\F6C2"; }

.mdi-arrow-up-circle:before {
  content: "\FCBD"; }

.mdi-arrow-up-circle-outline:before {
  content: "\FCBE"; }

.mdi-arrow-up-down-bold-outline:before {
  content: "\F9C7"; }

.mdi-arrow-up-drop-circle:before {
  content: "\F062"; }

.mdi-arrow-up-drop-circle-outline:before {
  content: "\F063"; }

.mdi-arrow-up-thick:before {
  content: "\F05E"; }

.mdi-artist:before {
  content: "\F802"; }

.mdi-artist-outline:before {
  content: "\FCC5"; }

.mdi-artstation:before {
  content: "\FB37"; }

.mdi-aspect-ratio:before {
  content: "\FA23"; }

.mdi-assistant:before {
  content: "\F064"; }

.mdi-asterisk:before {
  content: "\F6C3"; }

.mdi-at:before {
  content: "\F065"; }

.mdi-atlassian:before {
  content: "\F803"; }

.mdi-atm:before {
  content: "\FD23"; }

.mdi-atom:before {
  content: "\F767"; }

.mdi-attachment:before {
  content: "\F066"; }

.mdi-audio-video:before {
  content: "\F93C"; }

.mdi-audiobook:before {
  content: "\F067"; }

.mdi-augmented-reality:before {
  content: "\F84F"; }

.mdi-auto-fix:before {
  content: "\F068"; }

.mdi-auto-upload:before {
  content: "\F069"; }

.mdi-autorenew:before {
  content: "\F06A"; }

.mdi-av-timer:before {
  content: "\F06B"; }

.mdi-aws:before {
  content: "\FDF2"; }

.mdi-axe:before {
  content: "\F8C7"; }

.mdi-axis:before {
  content: "\FD24"; }

.mdi-axis-arrow:before {
  content: "\FD25"; }

.mdi-axis-arrow-lock:before {
  content: "\FD26"; }

.mdi-axis-lock:before {
  content: "\FD27"; }

.mdi-axis-x-arrow:before {
  content: "\FD28"; }

.mdi-axis-x-arrow-lock:before {
  content: "\FD29"; }

.mdi-axis-x-rotate-clockwise:before {
  content: "\FD2A"; }

.mdi-axis-x-rotate-counterclockwise:before {
  content: "\FD2B"; }

.mdi-axis-x-y-arrow-lock:before {
  content: "\FD2C"; }

.mdi-axis-y-arrow:before {
  content: "\FD2D"; }

.mdi-axis-y-arrow-lock:before {
  content: "\FD2E"; }

.mdi-axis-y-rotate-clockwise:before {
  content: "\FD2F"; }

.mdi-axis-y-rotate-counterclockwise:before {
  content: "\FD30"; }

.mdi-axis-z-arrow:before {
  content: "\FD31"; }

.mdi-axis-z-arrow-lock:before {
  content: "\FD32"; }

.mdi-axis-z-rotate-clockwise:before {
  content: "\FD33"; }

.mdi-axis-z-rotate-counterclockwise:before {
  content: "\FD34"; }

.mdi-azure:before {
  content: "\F804"; }

.mdi-babel:before {
  content: "\FA24"; }

.mdi-baby:before {
  content: "\F06C"; }

.mdi-baby-buggy:before {
  content: "\F68E"; }

.mdi-backburger:before {
  content: "\F06D"; }

.mdi-backspace:before {
  content: "\F06E"; }

.mdi-backspace-outline:before {
  content: "\FB38"; }

.mdi-backup-restore:before {
  content: "\F06F"; }

.mdi-badminton:before {
  content: "\F850"; }

.mdi-bag-personal:before {
  content: "\FDF3"; }

.mdi-bag-personal-off:before {
  content: "\FDF4"; }

.mdi-bag-personal-off-outline:before {
  content: "\FDF5"; }

.mdi-bag-personal-outline:before {
  content: "\FDF6"; }

.mdi-balloon:before {
  content: "\FA25"; }

.mdi-ballot:before {
  content: "\F9C8"; }

.mdi-ballot-outline:before {
  content: "\F9C9"; }

.mdi-ballot-recount:before {
  content: "\FC15"; }

.mdi-ballot-recount-outline:before {
  content: "\FC16"; }

.mdi-bandage:before {
  content: "\FD8B"; }

.mdi-bandcamp:before {
  content: "\F674"; }

.mdi-bank:before {
  content: "\F070"; }

.mdi-bank-minus:before {
  content: "\FD8C"; }

.mdi-bank-plus:before {
  content: "\FD8D"; }

.mdi-bank-remove:before {
  content: "\FD8E"; }

.mdi-bank-transfer:before {
  content: "\FA26"; }

.mdi-bank-transfer-in:before {
  content: "\FA27"; }

.mdi-bank-transfer-out:before {
  content: "\FA28"; }

.mdi-barcode:before {
  content: "\F071"; }

.mdi-barcode-scan:before {
  content: "\F072"; }

.mdi-barley:before {
  content: "\F073"; }

.mdi-barley-off:before {
  content: "\FB39"; }

.mdi-barn:before {
  content: "\FB3A"; }

.mdi-barrel:before {
  content: "\F074"; }

.mdi-baseball:before {
  content: "\F851"; }

.mdi-baseball-bat:before {
  content: "\F852"; }

.mdi-basecamp:before {
  content: "\F075"; }

.mdi-basket:before {
  content: "\F076"; }

.mdi-basket-fill:before {
  content: "\F077"; }

.mdi-basket-unfill:before {
  content: "\F078"; }

.mdi-basketball:before {
  content: "\F805"; }

.mdi-basketball-hoop:before {
  content: "\FC17"; }

.mdi-basketball-hoop-outline:before {
  content: "\FC18"; }

.mdi-bat:before {
  content: "\FB3B"; }

.mdi-battery:before {
  content: "\F079"; }

.mdi-battery-10:before {
  content: "\F07A"; }

.mdi-battery-10-bluetooth:before {
  content: "\F93D"; }

.mdi-battery-20:before {
  content: "\F07B"; }

.mdi-battery-20-bluetooth:before {
  content: "\F93E"; }

.mdi-battery-30:before {
  content: "\F07C"; }

.mdi-battery-30-bluetooth:before {
  content: "\F93F"; }

.mdi-battery-40:before {
  content: "\F07D"; }

.mdi-battery-40-bluetooth:before {
  content: "\F940"; }

.mdi-battery-50:before {
  content: "\F07E"; }

.mdi-battery-50-bluetooth:before {
  content: "\F941"; }

.mdi-battery-60:before {
  content: "\F07F"; }

.mdi-battery-60-bluetooth:before {
  content: "\F942"; }

.mdi-battery-70:before {
  content: "\F080"; }

.mdi-battery-70-bluetooth:before {
  content: "\F943"; }

.mdi-battery-80:before {
  content: "\F081"; }

.mdi-battery-80-bluetooth:before {
  content: "\F944"; }

.mdi-battery-90:before {
  content: "\F082"; }

.mdi-battery-90-bluetooth:before {
  content: "\F945"; }

.mdi-battery-alert:before {
  content: "\F083"; }

.mdi-battery-alert-bluetooth:before {
  content: "\F946"; }

.mdi-battery-bluetooth:before {
  content: "\F947"; }

.mdi-battery-bluetooth-variant:before {
  content: "\F948"; }

.mdi-battery-charging:before {
  content: "\F084"; }

.mdi-battery-charging-10:before {
  content: "\F89B"; }

.mdi-battery-charging-100:before {
  content: "\F085"; }

.mdi-battery-charging-20:before {
  content: "\F086"; }

.mdi-battery-charging-30:before {
  content: "\F087"; }

.mdi-battery-charging-40:before {
  content: "\F088"; }

.mdi-battery-charging-50:before {
  content: "\F89C"; }

.mdi-battery-charging-60:before {
  content: "\F089"; }

.mdi-battery-charging-70:before {
  content: "\F89D"; }

.mdi-battery-charging-80:before {
  content: "\F08A"; }

.mdi-battery-charging-90:before {
  content: "\F08B"; }

.mdi-battery-charging-outline:before {
  content: "\F89E"; }

.mdi-battery-charging-wireless:before {
  content: "\F806"; }

.mdi-battery-charging-wireless-10:before {
  content: "\F807"; }

.mdi-battery-charging-wireless-20:before {
  content: "\F808"; }

.mdi-battery-charging-wireless-30:before {
  content: "\F809"; }

.mdi-battery-charging-wireless-40:before {
  content: "\F80A"; }

.mdi-battery-charging-wireless-50:before {
  content: "\F80B"; }

.mdi-battery-charging-wireless-60:before {
  content: "\F80C"; }

.mdi-battery-charging-wireless-70:before {
  content: "\F80D"; }

.mdi-battery-charging-wireless-80:before {
  content: "\F80E"; }

.mdi-battery-charging-wireless-90:before {
  content: "\F80F"; }

.mdi-battery-charging-wireless-alert:before {
  content: "\F810"; }

.mdi-battery-charging-wireless-outline:before {
  content: "\F811"; }

.mdi-battery-minus:before {
  content: "\F08C"; }

.mdi-battery-negative:before {
  content: "\F08D"; }

.mdi-battery-outline:before {
  content: "\F08E"; }

.mdi-battery-plus:before {
  content: "\F08F"; }

.mdi-battery-positive:before {
  content: "\F090"; }

.mdi-battery-unknown:before {
  content: "\F091"; }

.mdi-battery-unknown-bluetooth:before {
  content: "\F949"; }

.mdi-battlenet:before {
  content: "\FB3C"; }

.mdi-beach:before {
  content: "\F092"; }

.mdi-beaker:before {
  content: "\FCC6"; }

.mdi-beaker-outline:before {
  content: "\F68F"; }

.mdi-beats:before {
  content: "\F097"; }

.mdi-bed-empty:before {
  content: "\F89F"; }

.mdi-beer:before {
  content: "\F098"; }

.mdi-behance:before {
  content: "\F099"; }

.mdi-bell:before {
  content: "\F09A"; }

.mdi-bell-alert:before {
  content: "\FD35"; }

.mdi-bell-circle:before {
  content: "\FD36"; }

.mdi-bell-circle-outline:before {
  content: "\FD37"; }

.mdi-bell-off:before {
  content: "\F09B"; }

.mdi-bell-off-outline:before {
  content: "\FA90"; }

.mdi-bell-outline:before {
  content: "\F09C"; }

.mdi-bell-plus:before {
  content: "\F09D"; }

.mdi-bell-plus-outline:before {
  content: "\FA91"; }

.mdi-bell-ring:before {
  content: "\F09E"; }

.mdi-bell-ring-outline:before {
  content: "\F09F"; }

.mdi-bell-sleep:before {
  content: "\F0A0"; }

.mdi-bell-sleep-outline:before {
  content: "\FA92"; }

.mdi-beta:before {
  content: "\F0A1"; }

.mdi-betamax:before {
  content: "\F9CA"; }

.mdi-biathlon:before {
  content: "\FDF7"; }

.mdi-bible:before {
  content: "\F0A2"; }

.mdi-bike:before {
  content: "\F0A3"; }

.mdi-billiards:before {
  content: "\FB3D"; }

.mdi-billiards-rack:before {
  content: "\FB3E"; }

.mdi-bing:before {
  content: "\F0A4"; }

.mdi-binoculars:before {
  content: "\F0A5"; }

.mdi-bio:before {
  content: "\F0A6"; }

.mdi-biohazard:before {
  content: "\F0A7"; }

.mdi-bitbucket:before {
  content: "\F0A8"; }

.mdi-bitcoin:before {
  content: "\F812"; }

.mdi-black-mesa:before {
  content: "\F0A9"; }

.mdi-blackberry:before {
  content: "\F0AA"; }

.mdi-blender:before {
  content: "\FCC7"; }

.mdi-blender-software:before {
  content: "\F0AB"; }

.mdi-blinds:before {
  content: "\F0AC"; }

.mdi-block-helper:before {
  content: "\F0AD"; }

.mdi-blogger:before {
  content: "\F0AE"; }

.mdi-blood-bag:before {
  content: "\FCC8"; }

.mdi-bluetooth:before {
  content: "\F0AF"; }

.mdi-bluetooth-audio:before {
  content: "\F0B0"; }

.mdi-bluetooth-connect:before {
  content: "\F0B1"; }

.mdi-bluetooth-off:before {
  content: "\F0B2"; }

.mdi-bluetooth-settings:before {
  content: "\F0B3"; }

.mdi-bluetooth-transfer:before {
  content: "\F0B4"; }

.mdi-blur:before {
  content: "\F0B5"; }

.mdi-blur-linear:before {
  content: "\F0B6"; }

.mdi-blur-off:before {
  content: "\F0B7"; }

.mdi-blur-radial:before {
  content: "\F0B8"; }

.mdi-bolnisi-cross:before {
  content: "\FCC9"; }

.mdi-bolt:before {
  content: "\FD8F"; }

.mdi-bomb:before {
  content: "\F690"; }

.mdi-bomb-off:before {
  content: "\F6C4"; }

.mdi-bone:before {
  content: "\F0B9"; }

.mdi-book:before {
  content: "\F0BA"; }

.mdi-book-lock:before {
  content: "\F799"; }

.mdi-book-lock-open:before {
  content: "\F79A"; }

.mdi-book-minus:before {
  content: "\F5D9"; }

.mdi-book-multiple:before {
  content: "\F0BB"; }

.mdi-book-multiple-minus:before {
  content: "\FA93"; }

.mdi-book-multiple-plus:before {
  content: "\FA94"; }

.mdi-book-multiple-remove:before {
  content: "\FA95"; }

.mdi-book-multiple-variant:before {
  content: "\F0BC"; }

.mdi-book-open:before {
  content: "\F0BD"; }

.mdi-book-open-outline:before {
  content: "\FB3F"; }

.mdi-book-open-page-variant:before {
  content: "\F5DA"; }

.mdi-book-open-variant:before {
  content: "\F0BE"; }

.mdi-book-outline:before {
  content: "\FB40"; }

.mdi-book-plus:before {
  content: "\F5DB"; }

.mdi-book-remove:before {
  content: "\FA96"; }

.mdi-book-variant:before {
  content: "\F0BF"; }

.mdi-bookmark:before {
  content: "\F0C0"; }

.mdi-bookmark-check:before {
  content: "\F0C1"; }

.mdi-bookmark-minus:before {
  content: "\F9CB"; }

.mdi-bookmark-minus-outline:before {
  content: "\F9CC"; }

.mdi-bookmark-multiple:before {
  content: "\FDF8"; }

.mdi-bookmark-multiple-outline:before {
  content: "\FDF9"; }

.mdi-bookmark-music:before {
  content: "\F0C2"; }

.mdi-bookmark-off:before {
  content: "\F9CD"; }

.mdi-bookmark-off-outline:before {
  content: "\F9CE"; }

.mdi-bookmark-outline:before {
  content: "\F0C3"; }

.mdi-bookmark-plus:before {
  content: "\F0C5"; }

.mdi-bookmark-plus-outline:before {
  content: "\F0C4"; }

.mdi-bookmark-remove:before {
  content: "\F0C6"; }

.mdi-boombox:before {
  content: "\F5DC"; }

.mdi-bootstrap:before {
  content: "\F6C5"; }

.mdi-border-all:before {
  content: "\F0C7"; }

.mdi-border-all-variant:before {
  content: "\F8A0"; }

.mdi-border-bottom:before {
  content: "\F0C8"; }

.mdi-border-bottom-variant:before {
  content: "\F8A1"; }

.mdi-border-color:before {
  content: "\F0C9"; }

.mdi-border-horizontal:before {
  content: "\F0CA"; }

.mdi-border-inside:before {
  content: "\F0CB"; }

.mdi-border-left:before {
  content: "\F0CC"; }

.mdi-border-left-variant:before {
  content: "\F8A2"; }

.mdi-border-none:before {
  content: "\F0CD"; }

.mdi-border-none-variant:before {
  content: "\F8A3"; }

.mdi-border-outside:before {
  content: "\F0CE"; }

.mdi-border-right:before {
  content: "\F0CF"; }

.mdi-border-right-variant:before {
  content: "\F8A4"; }

.mdi-border-style:before {
  content: "\F0D0"; }

.mdi-border-top:before {
  content: "\F0D1"; }

.mdi-border-top-variant:before {
  content: "\F8A5"; }

.mdi-border-vertical:before {
  content: "\F0D2"; }

.mdi-bottle-wine:before {
  content: "\F853"; }

.mdi-bow-tie:before {
  content: "\F677"; }

.mdi-bowl:before {
  content: "\F617"; }

.mdi-bowling:before {
  content: "\F0D3"; }

.mdi-box:before {
  content: "\F0D4"; }

.mdi-box-cutter:before {
  content: "\F0D5"; }

.mdi-box-shadow:before {
  content: "\F637"; }

.mdi-boxing-glove:before {
  content: "\FB41"; }

.mdi-braille:before {
  content: "\F9CF"; }

.mdi-brain:before {
  content: "\F9D0"; }

.mdi-bread-slice:before {
  content: "\FCCA"; }

.mdi-bread-slice-outline:before {
  content: "\FCCB"; }

.mdi-bridge:before {
  content: "\F618"; }

.mdi-briefcase:before {
  content: "\F0D6"; }

.mdi-briefcase-account:before {
  content: "\FCCC"; }

.mdi-briefcase-account-outline:before {
  content: "\FCCD"; }

.mdi-briefcase-check:before {
  content: "\F0D7"; }

.mdi-briefcase-download:before {
  content: "\F0D8"; }

.mdi-briefcase-download-outline:before {
  content: "\FC19"; }

.mdi-briefcase-edit:before {
  content: "\FA97"; }

.mdi-briefcase-edit-outline:before {
  content: "\FC1A"; }

.mdi-briefcase-minus:before {
  content: "\FA29"; }

.mdi-briefcase-minus-outline:before {
  content: "\FC1B"; }

.mdi-briefcase-outline:before {
  content: "\F813"; }

.mdi-briefcase-plus:before {
  content: "\FA2A"; }

.mdi-briefcase-plus-outline:before {
  content: "\FC1C"; }

.mdi-briefcase-remove:before {
  content: "\FA2B"; }

.mdi-briefcase-remove-outline:before {
  content: "\FC1D"; }

.mdi-briefcase-search:before {
  content: "\FA2C"; }

.mdi-briefcase-search-outline:before {
  content: "\FC1E"; }

.mdi-briefcase-upload:before {
  content: "\F0D9"; }

.mdi-briefcase-upload-outline:before {
  content: "\FC1F"; }

.mdi-brightness-1:before {
  content: "\F0DA"; }

.mdi-brightness-2:before {
  content: "\F0DB"; }

.mdi-brightness-3:before {
  content: "\F0DC"; }

.mdi-brightness-4:before {
  content: "\F0DD"; }

.mdi-brightness-5:before {
  content: "\F0DE"; }

.mdi-brightness-6:before {
  content: "\F0DF"; }

.mdi-brightness-7:before {
  content: "\F0E0"; }

.mdi-brightness-auto:before {
  content: "\F0E1"; }

.mdi-brightness-percent:before {
  content: "\FCCE"; }

.mdi-broom:before {
  content: "\F0E2"; }

.mdi-brush:before {
  content: "\F0E3"; }

.mdi-buddhism:before {
  content: "\F94A"; }

.mdi-buffer:before {
  content: "\F619"; }

.mdi-bug:before {
  content: "\F0E4"; }

.mdi-bug-check:before {
  content: "\FA2D"; }

.mdi-bug-check-outline:before {
  content: "\FA2E"; }

.mdi-bug-outline:before {
  content: "\FA2F"; }

.mdi-bugle:before {
  content: "\FD90"; }

.mdi-bulldozer:before {
  content: "\FB07"; }

.mdi-bullet:before {
  content: "\FCCF"; }

.mdi-bulletin-board:before {
  content: "\F0E5"; }

.mdi-bullhorn:before {
  content: "\F0E6"; }

.mdi-bullhorn-outline:before {
  content: "\FB08"; }

.mdi-bullseye:before {
  content: "\F5DD"; }

.mdi-bullseye-arrow:before {
  content: "\F8C8"; }

.mdi-bus:before {
  content: "\F0E7"; }

.mdi-bus-alert:before {
  content: "\FA98"; }

.mdi-bus-articulated-end:before {
  content: "\F79B"; }

.mdi-bus-articulated-front:before {
  content: "\F79C"; }

.mdi-bus-clock:before {
  content: "\F8C9"; }

.mdi-bus-double-decker:before {
  content: "\F79D"; }

.mdi-bus-school:before {
  content: "\F79E"; }

.mdi-bus-side:before {
  content: "\F79F"; }

.mdi-cached:before {
  content: "\F0E8"; }

.mdi-cactus:before {
  content: "\FD91"; }

.mdi-cake:before {
  content: "\F0E9"; }

.mdi-cake-layered:before {
  content: "\F0EA"; }

.mdi-cake-variant:before {
  content: "\F0EB"; }

.mdi-calculator:before {
  content: "\F0EC"; }

.mdi-calculator-variant:before {
  content: "\FA99"; }

.mdi-calendar:before {
  content: "\F0ED"; }

.mdi-calendar-alert:before {
  content: "\FA30"; }

.mdi-calendar-blank:before {
  content: "\F0EE"; }

.mdi-calendar-blank-outline:before {
  content: "\FB42"; }

.mdi-calendar-check:before {
  content: "\F0EF"; }

.mdi-calendar-check-outline:before {
  content: "\FC20"; }

.mdi-calendar-clock:before {
  content: "\F0F0"; }

.mdi-calendar-edit:before {
  content: "\F8A6"; }

.mdi-calendar-export:before {
  content: "\FB09"; }

.mdi-calendar-heart:before {
  content: "\F9D1"; }

.mdi-calendar-import:before {
  content: "\FB0A"; }

.mdi-calendar-minus:before {
  content: "\FD38"; }

.mdi-calendar-month:before {
  content: "\FDFA"; }

.mdi-calendar-month-outline:before {
  content: "\FDFB"; }

.mdi-calendar-multiple:before {
  content: "\F0F1"; }

.mdi-calendar-multiple-check:before {
  content: "\F0F2"; }

.mdi-calendar-multiselect:before {
  content: "\FA31"; }

.mdi-calendar-outline:before {
  content: "\FB43"; }

.mdi-calendar-plus:before {
  content: "\F0F3"; }

.mdi-calendar-question:before {
  content: "\F691"; }

.mdi-calendar-range:before {
  content: "\F678"; }

.mdi-calendar-range-outline:before {
  content: "\FB44"; }

.mdi-calendar-remove:before {
  content: "\F0F4"; }

.mdi-calendar-remove-outline:before {
  content: "\FC21"; }

.mdi-calendar-search:before {
  content: "\F94B"; }

.mdi-calendar-star:before {
  content: "\F9D2"; }

.mdi-calendar-text:before {
  content: "\F0F5"; }

.mdi-calendar-text-outline:before {
  content: "\FC22"; }

.mdi-calendar-today:before {
  content: "\F0F6"; }

.mdi-calendar-week:before {
  content: "\FA32"; }

.mdi-calendar-week-begin:before {
  content: "\FA33"; }

.mdi-call-made:before {
  content: "\F0F7"; }

.mdi-call-merge:before {
  content: "\F0F8"; }

.mdi-call-missed:before {
  content: "\F0F9"; }

.mdi-call-received:before {
  content: "\F0FA"; }

.mdi-call-split:before {
  content: "\F0FB"; }

.mdi-camcorder:before {
  content: "\F0FC"; }

.mdi-camcorder-box:before {
  content: "\F0FD"; }

.mdi-camcorder-box-off:before {
  content: "\F0FE"; }

.mdi-camcorder-off:before {
  content: "\F0FF"; }

.mdi-camera:before {
  content: "\F100"; }

.mdi-camera-account:before {
  content: "\F8CA"; }

.mdi-camera-burst:before {
  content: "\F692"; }

.mdi-camera-control:before {
  content: "\FB45"; }

.mdi-camera-enhance:before {
  content: "\F101"; }

.mdi-camera-enhance-outline:before {
  content: "\FB46"; }

.mdi-camera-front:before {
  content: "\F102"; }

.mdi-camera-front-variant:before {
  content: "\F103"; }

.mdi-camera-gopro:before {
  content: "\F7A0"; }

.mdi-camera-image:before {
  content: "\F8CB"; }

.mdi-camera-iris:before {
  content: "\F104"; }

.mdi-camera-metering-center:before {
  content: "\F7A1"; }

.mdi-camera-metering-matrix:before {
  content: "\F7A2"; }

.mdi-camera-metering-partial:before {
  content: "\F7A3"; }

.mdi-camera-metering-spot:before {
  content: "\F7A4"; }

.mdi-camera-off:before {
  content: "\F5DF"; }

.mdi-camera-outline:before {
  content: "\FD39"; }

.mdi-camera-party-mode:before {
  content: "\F105"; }

.mdi-camera-rear:before {
  content: "\F106"; }

.mdi-camera-rear-variant:before {
  content: "\F107"; }

.mdi-camera-retake:before {
  content: "\FDFC"; }

.mdi-camera-retake-outline:before {
  content: "\FDFD"; }

.mdi-camera-switch:before {
  content: "\F108"; }

.mdi-camera-timer:before {
  content: "\F109"; }

.mdi-camera-wireless:before {
  content: "\FD92"; }

.mdi-camera-wireless-outline:before {
  content: "\FD93"; }

.mdi-cancel:before {
  content: "\F739"; }

.mdi-candle:before {
  content: "\F5E2"; }

.mdi-candycane:before {
  content: "\F10A"; }

.mdi-cannabis:before {
  content: "\F7A5"; }

.mdi-caps-lock:before {
  content: "\FA9A"; }

.mdi-car:before {
  content: "\F10B"; }

.mdi-car-back:before {
  content: "\FDFE"; }

.mdi-car-battery:before {
  content: "\F10C"; }

.mdi-car-brake-abs:before {
  content: "\FC23"; }

.mdi-car-brake-alert:before {
  content: "\FC24"; }

.mdi-car-brake-hold:before {
  content: "\FD3A"; }

.mdi-car-brake-parking:before {
  content: "\FD3B"; }

.mdi-car-connected:before {
  content: "\F10D"; }

.mdi-car-convertible:before {
  content: "\F7A6"; }

.mdi-car-cruise-control:before {
  content: "\FD3C"; }

.mdi-car-defrost-front:before {
  content: "\FD3D"; }

.mdi-car-defrost-rear:before {
  content: "\FD3E"; }

.mdi-car-door:before {
  content: "\FB47"; }

.mdi-car-electric:before {
  content: "\FB48"; }

.mdi-car-esp:before {
  content: "\FC25"; }

.mdi-car-estate:before {
  content: "\F7A7"; }

.mdi-car-hatchback:before {
  content: "\F7A8"; }

.mdi-car-key:before {
  content: "\FB49"; }

.mdi-car-light-dimmed:before {
  content: "\FC26"; }

.mdi-car-light-fog:before {
  content: "\FC27"; }

.mdi-car-light-high:before {
  content: "\FC28"; }

.mdi-car-limousine:before {
  content: "\F8CC"; }

.mdi-car-multiple:before {
  content: "\FB4A"; }

.mdi-car-off:before {
  content: "\FDFF"; }

.mdi-car-parking-lights:before {
  content: "\FD3F"; }

.mdi-car-pickup:before {
  content: "\F7A9"; }

.mdi-car-side:before {
  content: "\F7AA"; }

.mdi-car-sports:before {
  content: "\F7AB"; }

.mdi-car-tire-alert:before {
  content: "\FC29"; }

.mdi-car-traction-control:before {
  content: "\FD40"; }

.mdi-car-wash:before {
  content: "\F10E"; }

.mdi-caravan:before {
  content: "\F7AC"; }

.mdi-card:before {
  content: "\FB4B"; }

.mdi-card-bulleted:before {
  content: "\FB4C"; }

.mdi-card-bulleted-off:before {
  content: "\FB4D"; }

.mdi-card-bulleted-off-outline:before {
  content: "\FB4E"; }

.mdi-card-bulleted-outline:before {
  content: "\FB4F"; }

.mdi-card-bulleted-settings:before {
  content: "\FB50"; }

.mdi-card-bulleted-settings-outline:before {
  content: "\FB51"; }

.mdi-card-outline:before {
  content: "\FB52"; }

.mdi-card-text:before {
  content: "\FB53"; }

.mdi-card-text-outline:before {
  content: "\FB54"; }

.mdi-cards:before {
  content: "\F638"; }

.mdi-cards-club:before {
  content: "\F8CD"; }

.mdi-cards-diamond:before {
  content: "\F8CE"; }

.mdi-cards-heart:before {
  content: "\F8CF"; }

.mdi-cards-outline:before {
  content: "\F639"; }

.mdi-cards-playing-outline:before {
  content: "\F63A"; }

.mdi-cards-spade:before {
  content: "\F8D0"; }

.mdi-cards-variant:before {
  content: "\F6C6"; }

.mdi-carrot:before {
  content: "\F10F"; }

.mdi-carry-on-bag-check:before {
  content: "\FD41"; }

.mdi-cart:before {
  content: "\F110"; }

.mdi-cart-arrow-down:before {
  content: "\FD42"; }

.mdi-cart-arrow-right:before {
  content: "\FC2A"; }

.mdi-cart-arrow-up:before {
  content: "\FD43"; }

.mdi-cart-minus:before {
  content: "\FD44"; }

.mdi-cart-off:before {
  content: "\F66B"; }

.mdi-cart-outline:before {
  content: "\F111"; }

.mdi-cart-plus:before {
  content: "\F112"; }

.mdi-cart-remove:before {
  content: "\FD45"; }

.mdi-case-sensitive-alt:before {
  content: "\F113"; }

.mdi-cash:before {
  content: "\F114"; }

.mdi-cash-100:before {
  content: "\F115"; }

.mdi-cash-marker:before {
  content: "\FD94"; }

.mdi-cash-multiple:before {
  content: "\F116"; }

.mdi-cash-refund:before {
  content: "\FA9B"; }

.mdi-cash-register:before {
  content: "\FCD0"; }

.mdi-cash-usd:before {
  content: "\F117"; }

.mdi-cassette:before {
  content: "\F9D3"; }

.mdi-cast:before {
  content: "\F118"; }

.mdi-cast-connected:before {
  content: "\F119"; }

.mdi-cast-education:before {
  content: "\FE6D"; }

.mdi-cast-off:before {
  content: "\F789"; }

.mdi-castle:before {
  content: "\F11A"; }

.mdi-cat:before {
  content: "\F11B"; }

.mdi-cctv:before {
  content: "\F7AD"; }

.mdi-ceiling-light:before {
  content: "\F768"; }

.mdi-cellphone:before {
  content: "\F11C"; }

.mdi-cellphone-android:before {
  content: "\F11D"; }

.mdi-cellphone-arrow-down:before {
  content: "\F9D4"; }

.mdi-cellphone-basic:before {
  content: "\F11E"; }

.mdi-cellphone-dock:before {
  content: "\F11F"; }

.mdi-cellphone-erase:before {
  content: "\F94C"; }

.mdi-cellphone-iphone:before {
  content: "\F120"; }

.mdi-cellphone-key:before {
  content: "\F94D"; }

.mdi-cellphone-link:before {
  content: "\F121"; }

.mdi-cellphone-link-off:before {
  content: "\F122"; }

.mdi-cellphone-lock:before {
  content: "\F94E"; }

.mdi-cellphone-message:before {
  content: "\F8D2"; }

.mdi-cellphone-off:before {
  content: "\F94F"; }

.mdi-cellphone-screenshot:before {
  content: "\FA34"; }

.mdi-cellphone-settings:before {
  content: "\F123"; }

.mdi-cellphone-settings-variant:before {
  content: "\F950"; }

.mdi-cellphone-sound:before {
  content: "\F951"; }

.mdi-cellphone-text:before {
  content: "\F8D1"; }

.mdi-cellphone-wireless:before {
  content: "\F814"; }

.mdi-celtic-cross:before {
  content: "\FCD1"; }

.mdi-certificate:before {
  content: "\F124"; }

.mdi-chair-school:before {
  content: "\F125"; }

.mdi-charity:before {
  content: "\FC2B"; }

.mdi-chart-arc:before {
  content: "\F126"; }

.mdi-chart-areaspline:before {
  content: "\F127"; }

.mdi-chart-bar:before {
  content: "\F128"; }

.mdi-chart-bar-stacked:before {
  content: "\F769"; }

.mdi-chart-bell-curve:before {
  content: "\FC2C"; }

.mdi-chart-bubble:before {
  content: "\F5E3"; }

.mdi-chart-donut:before {
  content: "\F7AE"; }

.mdi-chart-donut-variant:before {
  content: "\F7AF"; }

.mdi-chart-gantt:before {
  content: "\F66C"; }

.mdi-chart-histogram:before {
  content: "\F129"; }

.mdi-chart-line:before {
  content: "\F12A"; }

.mdi-chart-line-stacked:before {
  content: "\F76A"; }

.mdi-chart-line-variant:before {
  content: "\F7B0"; }

.mdi-chart-multiline:before {
  content: "\F8D3"; }

.mdi-chart-pie:before {
  content: "\F12B"; }

.mdi-chart-scatterplot-hexbin:before {
  content: "\F66D"; }

.mdi-chart-timeline:before {
  content: "\F66E"; }

.mdi-chat:before {
  content: "\FB55"; }

.mdi-chat-alert:before {
  content: "\FB56"; }

.mdi-chat-processing:before {
  content: "\FB57"; }

.mdi-check:before {
  content: "\F12C"; }

.mdi-check-all:before {
  content: "\F12D"; }

.mdi-check-bold:before {
  content: "\FE6E"; }

.mdi-check-box-multiple-outline:before {
  content: "\FC2D"; }

.mdi-check-box-outline:before {
  content: "\FC2E"; }

.mdi-check-circle:before {
  content: "\F5E0"; }

.mdi-check-circle-outline:before {
  content: "\F5E1"; }

.mdi-check-decagram:before {
  content: "\F790"; }

.mdi-check-network:before {
  content: "\FC2F"; }

.mdi-check-network-outline:before {
  content: "\FC30"; }

.mdi-check-outline:before {
  content: "\F854"; }

.mdi-check-underline:before {
  content: "\FE70"; }

.mdi-check-underline-circle:before {
  content: "\FE71"; }

.mdi-check-underline-circle-outline:before {
  content: "\FE72"; }

.mdi-checkbook:before {
  content: "\FA9C"; }

.mdi-checkbox-blank:before {
  content: "\F12E"; }

.mdi-checkbox-blank-circle:before {
  content: "\F12F"; }

.mdi-checkbox-blank-circle-outline:before {
  content: "\F130"; }

.mdi-checkbox-blank-outline:before {
  content: "\F131"; }

.mdi-checkbox-intermediate:before {
  content: "\F855"; }

.mdi-checkbox-marked:before {
  content: "\F132"; }

.mdi-checkbox-marked-circle:before {
  content: "\F133"; }

.mdi-checkbox-marked-circle-outline:before {
  content: "\F134"; }

.mdi-checkbox-marked-outline:before {
  content: "\F135"; }

.mdi-checkbox-multiple-blank:before {
  content: "\F136"; }

.mdi-checkbox-multiple-blank-circle:before {
  content: "\F63B"; }

.mdi-checkbox-multiple-blank-circle-outline:before {
  content: "\F63C"; }

.mdi-checkbox-multiple-blank-outline:before {
  content: "\F137"; }

.mdi-checkbox-multiple-marked:before {
  content: "\F138"; }

.mdi-checkbox-multiple-marked-circle:before {
  content: "\F63D"; }

.mdi-checkbox-multiple-marked-circle-outline:before {
  content: "\F63E"; }

.mdi-checkbox-multiple-marked-outline:before {
  content: "\F139"; }

.mdi-checkerboard:before {
  content: "\F13A"; }

.mdi-chef-hat:before {
  content: "\FB58"; }

.mdi-chemical-weapon:before {
  content: "\F13B"; }

.mdi-chess-bishop:before {
  content: "\F85B"; }

.mdi-chess-king:before {
  content: "\F856"; }

.mdi-chess-knight:before {
  content: "\F857"; }

.mdi-chess-pawn:before {
  content: "\F858"; }

.mdi-chess-queen:before {
  content: "\F859"; }

.mdi-chess-rook:before {
  content: "\F85A"; }

.mdi-chevron-double-down:before {
  content: "\F13C"; }

.mdi-chevron-double-left:before {
  content: "\F13D"; }

.mdi-chevron-double-right:before {
  content: "\F13E"; }

.mdi-chevron-double-up:before {
  content: "\F13F"; }

.mdi-chevron-down:before {
  content: "\F140"; }

.mdi-chevron-down-box:before {
  content: "\F9D5"; }

.mdi-chevron-down-box-outline:before {
  content: "\F9D6"; }

.mdi-chevron-down-circle:before {
  content: "\FB0B"; }

.mdi-chevron-down-circle-outline:before {
  content: "\FB0C"; }

.mdi-chevron-left:before {
  content: "\F141"; }

.mdi-chevron-left-box:before {
  content: "\F9D7"; }

.mdi-chevron-left-box-outline:before {
  content: "\F9D8"; }

.mdi-chevron-left-circle:before {
  content: "\FB0D"; }

.mdi-chevron-left-circle-outline:before {
  content: "\FB0E"; }

.mdi-chevron-right:before {
  content: "\F142"; }

.mdi-chevron-right-box:before {
  content: "\F9D9"; }

.mdi-chevron-right-box-outline:before {
  content: "\F9DA"; }

.mdi-chevron-right-circle:before {
  content: "\FB0F"; }

.mdi-chevron-right-circle-outline:before {
  content: "\FB10"; }

.mdi-chevron-triple-down:before {
  content: "\FD95"; }

.mdi-chevron-triple-left:before {
  content: "\FD96"; }

.mdi-chevron-triple-right:before {
  content: "\FD97"; }

.mdi-chevron-triple-up:before {
  content: "\FD98"; }

.mdi-chevron-up:before {
  content: "\F143"; }

.mdi-chevron-up-box:before {
  content: "\F9DB"; }

.mdi-chevron-up-box-outline:before {
  content: "\F9DC"; }

.mdi-chevron-up-circle:before {
  content: "\FB11"; }

.mdi-chevron-up-circle-outline:before {
  content: "\FB12"; }

.mdi-chili-hot:before {
  content: "\F7B1"; }

.mdi-chili-medium:before {
  content: "\F7B2"; }

.mdi-chili-mild:before {
  content: "\F7B3"; }

.mdi-chip:before {
  content: "\F61A"; }

.mdi-christianity:before {
  content: "\F952"; }

.mdi-christianity-outline:before {
  content: "\FCD2"; }

.mdi-church:before {
  content: "\F144"; }

.mdi-circle:before {
  content: "\F764"; }

.mdi-circle-edit-outline:before {
  content: "\F8D4"; }

.mdi-circle-medium:before {
  content: "\F9DD"; }

.mdi-circle-outline:before {
  content: "\F765"; }

.mdi-circle-slice-1:before {
  content: "\FA9D"; }

.mdi-circle-slice-2:before {
  content: "\FA9E"; }

.mdi-circle-slice-3:before {
  content: "\FA9F"; }

.mdi-circle-slice-4:before {
  content: "\FAA0"; }

.mdi-circle-slice-5:before {
  content: "\FAA1"; }

.mdi-circle-slice-6:before {
  content: "\FAA2"; }

.mdi-circle-slice-7:before {
  content: "\FAA3"; }

.mdi-circle-slice-8:before {
  content: "\FAA4"; }

.mdi-circle-small:before {
  content: "\F9DE"; }

.mdi-circular-saw:before {
  content: "\FE73"; }

.mdi-cisco-webex:before {
  content: "\F145"; }

.mdi-city:before {
  content: "\F146"; }

.mdi-city-variant:before {
  content: "\FA35"; }

.mdi-city-variant-outline:before {
  content: "\FA36"; }

.mdi-clipboard:before {
  content: "\F147"; }

.mdi-clipboard-account:before {
  content: "\F148"; }

.mdi-clipboard-account-outline:before {
  content: "\FC31"; }

.mdi-clipboard-alert:before {
  content: "\F149"; }

.mdi-clipboard-alert-outline:before {
  content: "\FCD3"; }

.mdi-clipboard-arrow-down:before {
  content: "\F14A"; }

.mdi-clipboard-arrow-down-outline:before {
  content: "\FC32"; }

.mdi-clipboard-arrow-left:before {
  content: "\F14B"; }

.mdi-clipboard-arrow-left-outline:before {
  content: "\FCD4"; }

.mdi-clipboard-arrow-right:before {
  content: "\FCD5"; }

.mdi-clipboard-arrow-right-outline:before {
  content: "\FCD6"; }

.mdi-clipboard-arrow-up:before {
  content: "\FC33"; }

.mdi-clipboard-arrow-up-outline:before {
  content: "\FC34"; }

.mdi-clipboard-check:before {
  content: "\F14C"; }

.mdi-clipboard-check-outline:before {
  content: "\F8A7"; }

.mdi-clipboard-flow:before {
  content: "\F6C7"; }

.mdi-clipboard-outline:before {
  content: "\F14D"; }

.mdi-clipboard-play:before {
  content: "\FC35"; }

.mdi-clipboard-play-outline:before {
  content: "\FC36"; }

.mdi-clipboard-plus:before {
  content: "\F750"; }

.mdi-clipboard-pulse:before {
  content: "\F85C"; }

.mdi-clipboard-pulse-outline:before {
  content: "\F85D"; }

.mdi-clipboard-text:before {
  content: "\F14E"; }

.mdi-clipboard-text-outline:before {
  content: "\FA37"; }

.mdi-clipboard-text-play:before {
  content: "\FC37"; }

.mdi-clipboard-text-play-outline:before {
  content: "\FC38"; }

.mdi-clippy:before {
  content: "\F14F"; }

.mdi-clock:before {
  content: "\F953"; }

.mdi-clock-alert:before {
  content: "\F954"; }

.mdi-clock-alert-outline:before {
  content: "\F5CE"; }

.mdi-clock-end:before {
  content: "\F151"; }

.mdi-clock-fast:before {
  content: "\F152"; }

.mdi-clock-in:before {
  content: "\F153"; }

.mdi-clock-out:before {
  content: "\F154"; }

.mdi-clock-outline:before {
  content: "\F150"; }

.mdi-clock-start:before {
  content: "\F155"; }

.mdi-close:before {
  content: "\F156"; }

.mdi-close-box:before {
  content: "\F157"; }

.mdi-close-box-multiple:before {
  content: "\FC39"; }

.mdi-close-box-multiple-outline:before {
  content: "\FC3A"; }

.mdi-close-box-outline:before {
  content: "\F158"; }

.mdi-close-circle:before {
  content: "\F159"; }

.mdi-close-circle-outline:before {
  content: "\F15A"; }

.mdi-close-network:before {
  content: "\F15B"; }

.mdi-close-network-outline:before {
  content: "\FC3B"; }

.mdi-close-octagon:before {
  content: "\F15C"; }

.mdi-close-octagon-outline:before {
  content: "\F15D"; }

.mdi-close-outline:before {
  content: "\F6C8"; }

.mdi-closed-caption:before {
  content: "\F15E"; }

.mdi-closed-caption-outline:before {
  content: "\FD99"; }

.mdi-cloud:before {
  content: "\F15F"; }

.mdi-cloud-alert:before {
  content: "\F9DF"; }

.mdi-cloud-braces:before {
  content: "\F7B4"; }

.mdi-cloud-check:before {
  content: "\F160"; }

.mdi-cloud-circle:before {
  content: "\F161"; }

.mdi-cloud-download:before {
  content: "\F162"; }

.mdi-cloud-download-outline:before {
  content: "\FB59"; }

.mdi-cloud-off-outline:before {
  content: "\F164"; }

.mdi-cloud-outline:before {
  content: "\F163"; }

.mdi-cloud-print:before {
  content: "\F165"; }

.mdi-cloud-print-outline:before {
  content: "\F166"; }

.mdi-cloud-question:before {
  content: "\FA38"; }

.mdi-cloud-search:before {
  content: "\F955"; }

.mdi-cloud-search-outline:before {
  content: "\F956"; }

.mdi-cloud-sync:before {
  content: "\F63F"; }

.mdi-cloud-tags:before {
  content: "\F7B5"; }

.mdi-cloud-upload:before {
  content: "\F167"; }

.mdi-cloud-upload-outline:before {
  content: "\FB5A"; }

.mdi-clover:before {
  content: "\F815"; }

.mdi-code-array:before {
  content: "\F168"; }

.mdi-code-braces:before {
  content: "\F169"; }

.mdi-code-brackets:before {
  content: "\F16A"; }

.mdi-code-equal:before {
  content: "\F16B"; }

.mdi-code-greater-than:before {
  content: "\F16C"; }

.mdi-code-greater-than-or-equal:before {
  content: "\F16D"; }

.mdi-code-less-than:before {
  content: "\F16E"; }

.mdi-code-less-than-or-equal:before {
  content: "\F16F"; }

.mdi-code-not-equal:before {
  content: "\F170"; }

.mdi-code-not-equal-variant:before {
  content: "\F171"; }

.mdi-code-parentheses:before {
  content: "\F172"; }

.mdi-code-string:before {
  content: "\F173"; }

.mdi-code-tags:before {
  content: "\F174"; }

.mdi-code-tags-check:before {
  content: "\F693"; }

.mdi-codepen:before {
  content: "\F175"; }

.mdi-coffee:before {
  content: "\F176"; }

.mdi-coffee-outline:before {
  content: "\F6C9"; }

.mdi-coffee-to-go:before {
  content: "\F177"; }

.mdi-coffin:before {
  content: "\FB5B"; }

.mdi-cogs:before {
  content: "\F8D5"; }

.mdi-coin:before {
  content: "\F178"; }

.mdi-coins:before {
  content: "\F694"; }

.mdi-collage:before {
  content: "\F640"; }

.mdi-collapse-all:before {
  content: "\FAA5"; }

.mdi-collapse-all-outline:before {
  content: "\FAA6"; }

.mdi-color-helper:before {
  content: "\F179"; }

.mdi-comma:before {
  content: "\FE74"; }

.mdi-comma-box:before {
  content: "\FE75"; }

.mdi-comma-box-outline:before {
  content: "\FE76"; }

.mdi-comma-circle:before {
  content: "\FE77"; }

.mdi-comma-circle-outline:before {
  content: "\FE78"; }

.mdi-comment:before {
  content: "\F17A"; }

.mdi-comment-account:before {
  content: "\F17B"; }

.mdi-comment-account-outline:before {
  content: "\F17C"; }

.mdi-comment-alert:before {
  content: "\F17D"; }

.mdi-comment-alert-outline:before {
  content: "\F17E"; }

.mdi-comment-arrow-left:before {
  content: "\F9E0"; }

.mdi-comment-arrow-left-outline:before {
  content: "\F9E1"; }

.mdi-comment-arrow-right:before {
  content: "\F9E2"; }

.mdi-comment-arrow-right-outline:before {
  content: "\F9E3"; }

.mdi-comment-check:before {
  content: "\F17F"; }

.mdi-comment-check-outline:before {
  content: "\F180"; }

.mdi-comment-eye:before {
  content: "\FA39"; }

.mdi-comment-eye-outline:before {
  content: "\FA3A"; }

.mdi-comment-multiple:before {
  content: "\F85E"; }

.mdi-comment-multiple-outline:before {
  content: "\F181"; }

.mdi-comment-outline:before {
  content: "\F182"; }

.mdi-comment-plus:before {
  content: "\F9E4"; }

.mdi-comment-plus-outline:before {
  content: "\F183"; }

.mdi-comment-processing:before {
  content: "\F184"; }

.mdi-comment-processing-outline:before {
  content: "\F185"; }

.mdi-comment-question:before {
  content: "\F816"; }

.mdi-comment-question-outline:before {
  content: "\F186"; }

.mdi-comment-remove:before {
  content: "\F5DE"; }

.mdi-comment-remove-outline:before {
  content: "\F187"; }

.mdi-comment-search:before {
  content: "\FA3B"; }

.mdi-comment-search-outline:before {
  content: "\FA3C"; }

.mdi-comment-text:before {
  content: "\F188"; }

.mdi-comment-text-multiple:before {
  content: "\F85F"; }

.mdi-comment-text-multiple-outline:before {
  content: "\F860"; }

.mdi-comment-text-outline:before {
  content: "\F189"; }

.mdi-compare:before {
  content: "\F18A"; }

.mdi-compass:before {
  content: "\F18B"; }

.mdi-compass-off:before {
  content: "\FB5C"; }

.mdi-compass-off-outline:before {
  content: "\FB5D"; }

.mdi-compass-outline:before {
  content: "\F18C"; }

.mdi-console:before {
  content: "\F18D"; }

.mdi-console-line:before {
  content: "\F7B6"; }

.mdi-console-network:before {
  content: "\F8A8"; }

.mdi-console-network-outline:before {
  content: "\FC3C"; }

.mdi-contact-mail:before {
  content: "\F18E"; }

.mdi-contactless-payment:before {
  content: "\FD46"; }

.mdi-contacts:before {
  content: "\F6CA"; }

.mdi-contain:before {
  content: "\FA3D"; }

.mdi-contain-end:before {
  content: "\FA3E"; }

.mdi-contain-start:before {
  content: "\FA3F"; }

.mdi-content-copy:before {
  content: "\F18F"; }

.mdi-content-cut:before {
  content: "\F190"; }

.mdi-content-duplicate:before {
  content: "\F191"; }

.mdi-content-paste:before {
  content: "\F192"; }

.mdi-content-save:before {
  content: "\F193"; }

.mdi-content-save-all:before {
  content: "\F194"; }

.mdi-content-save-edit:before {
  content: "\FCD7"; }

.mdi-content-save-edit-outline:before {
  content: "\FCD8"; }

.mdi-content-save-move:before {
  content: "\FE79"; }

.mdi-content-save-move-outline:before {
  content: "\FE7A"; }

.mdi-content-save-outline:before {
  content: "\F817"; }

.mdi-content-save-settings:before {
  content: "\F61B"; }

.mdi-content-save-settings-outline:before {
  content: "\FB13"; }

.mdi-contrast:before {
  content: "\F195"; }

.mdi-contrast-box:before {
  content: "\F196"; }

.mdi-contrast-circle:before {
  content: "\F197"; }

.mdi-controller-classic:before {
  content: "\FB5E"; }

.mdi-controller-classic-outline:before {
  content: "\FB5F"; }

.mdi-cookie:before {
  content: "\F198"; }

.mdi-copyright:before {
  content: "\F5E6"; }

.mdi-cordova:before {
  content: "\F957"; }

.mdi-corn:before {
  content: "\F7B7"; }

.mdi-counter:before {
  content: "\F199"; }

.mdi-cow:before {
  content: "\F19A"; }

.mdi-crane:before {
  content: "\F861"; }

.mdi-creation:before {
  content: "\F1C9"; }

.mdi-creative-commons:before {
  content: "\FD47"; }

.mdi-credit-card:before {
  content: "\F19B"; }

.mdi-credit-card-marker:before {
  content: "\FD9A"; }

.mdi-credit-card-multiple:before {
  content: "\F19C"; }

.mdi-credit-card-off:before {
  content: "\F5E4"; }

.mdi-credit-card-plus:before {
  content: "\F675"; }

.mdi-credit-card-refund:before {
  content: "\FAA7"; }

.mdi-credit-card-scan:before {
  content: "\F19D"; }

.mdi-credit-card-settings:before {
  content: "\F8D6"; }

.mdi-credit-card-wireless:before {
  content: "\FD48"; }

.mdi-cricket:before {
  content: "\FD49"; }

.mdi-crop:before {
  content: "\F19E"; }

.mdi-crop-free:before {
  content: "\F19F"; }

.mdi-crop-landscape:before {
  content: "\F1A0"; }

.mdi-crop-portrait:before {
  content: "\F1A1"; }

.mdi-crop-rotate:before {
  content: "\F695"; }

.mdi-crop-square:before {
  content: "\F1A2"; }

.mdi-crosshairs:before {
  content: "\F1A3"; }

.mdi-crosshairs-gps:before {
  content: "\F1A4"; }

.mdi-crown:before {
  content: "\F1A5"; }

.mdi-cryengine:before {
  content: "\F958"; }

.mdi-crystal-ball:before {
  content: "\FB14"; }

.mdi-cube:before {
  content: "\F1A6"; }

.mdi-cube-outline:before {
  content: "\F1A7"; }

.mdi-cube-scan:before {
  content: "\FB60"; }

.mdi-cube-send:before {
  content: "\F1A8"; }

.mdi-cube-unfolded:before {
  content: "\F1A9"; }

.mdi-cup:before {
  content: "\F1AA"; }

.mdi-cup-off:before {
  content: "\F5E5"; }

.mdi-cup-water:before {
  content: "\F1AB"; }

.mdi-cupcake:before {
  content: "\F959"; }

.mdi-curling:before {
  content: "\F862"; }

.mdi-currency-bdt:before {
  content: "\F863"; }

.mdi-currency-brl:before {
  content: "\FB61"; }

.mdi-currency-btc:before {
  content: "\F1AC"; }

.mdi-currency-chf:before {
  content: "\F7B8"; }

.mdi-currency-cny:before {
  content: "\F7B9"; }

.mdi-currency-eth:before {
  content: "\F7BA"; }

.mdi-currency-eur:before {
  content: "\F1AD"; }

.mdi-currency-gbp:before {
  content: "\F1AE"; }

.mdi-currency-ils:before {
  content: "\FC3D"; }

.mdi-currency-inr:before {
  content: "\F1AF"; }

.mdi-currency-jpy:before {
  content: "\F7BB"; }

.mdi-currency-krw:before {
  content: "\F7BC"; }

.mdi-currency-kzt:before {
  content: "\F864"; }

.mdi-currency-ngn:before {
  content: "\F1B0"; }

.mdi-currency-php:before {
  content: "\F9E5"; }

.mdi-currency-rub:before {
  content: "\F1B1"; }

.mdi-currency-sign:before {
  content: "\F7BD"; }

.mdi-currency-try:before {
  content: "\F1B2"; }

.mdi-currency-twd:before {
  content: "\F7BE"; }

.mdi-currency-usd:before {
  content: "\F1B3"; }

.mdi-currency-usd-off:before {
  content: "\F679"; }

.mdi-current-ac:before {
  content: "\F95A"; }

.mdi-current-dc:before {
  content: "\F95B"; }

.mdi-cursor-default:before {
  content: "\F1B4"; }

.mdi-cursor-default-click:before {
  content: "\FCD9"; }

.mdi-cursor-default-click-outline:before {
  content: "\FCDA"; }

.mdi-cursor-default-outline:before {
  content: "\F1B5"; }

.mdi-cursor-move:before {
  content: "\F1B6"; }

.mdi-cursor-pointer:before {
  content: "\F1B7"; }

.mdi-cursor-text:before {
  content: "\F5E7"; }

.mdi-database:before {
  content: "\F1B8"; }

.mdi-database-check:before {
  content: "\FAA8"; }

.mdi-database-edit:before {
  content: "\FB62"; }

.mdi-database-export:before {
  content: "\F95D"; }

.mdi-database-import:before {
  content: "\F95C"; }

.mdi-database-lock:before {
  content: "\FAA9"; }

.mdi-database-minus:before {
  content: "\F1B9"; }

.mdi-database-plus:before {
  content: "\F1BA"; }

.mdi-database-refresh:before {
  content: "\FCDB"; }

.mdi-database-remove:before {
  content: "\FCDC"; }

.mdi-database-search:before {
  content: "\F865"; }

.mdi-database-settings:before {
  content: "\FCDD"; }

.mdi-death-star:before {
  content: "\F8D7"; }

.mdi-death-star-variant:before {
  content: "\F8D8"; }

.mdi-deathly-hallows:before {
  content: "\FB63"; }

.mdi-debian:before {
  content: "\F8D9"; }

.mdi-debug-step-into:before {
  content: "\F1BB"; }

.mdi-debug-step-out:before {
  content: "\F1BC"; }

.mdi-debug-step-over:before {
  content: "\F1BD"; }

.mdi-decagram:before {
  content: "\F76B"; }

.mdi-decagram-outline:before {
  content: "\F76C"; }

.mdi-decimal-decrease:before {
  content: "\F1BE"; }

.mdi-decimal-increase:before {
  content: "\F1BF"; }

.mdi-delete:before {
  content: "\F1C0"; }

.mdi-delete-circle:before {
  content: "\F682"; }

.mdi-delete-circle-outline:before {
  content: "\FB64"; }

.mdi-delete-empty:before {
  content: "\F6CB"; }

.mdi-delete-forever:before {
  content: "\F5E8"; }

.mdi-delete-forever-outline:before {
  content: "\FB65"; }

.mdi-delete-outline:before {
  content: "\F9E6"; }

.mdi-delete-restore:before {
  content: "\F818"; }

.mdi-delete-sweep:before {
  content: "\F5E9"; }

.mdi-delete-sweep-outline:before {
  content: "\FC3E"; }

.mdi-delete-variant:before {
  content: "\F1C1"; }

.mdi-delta:before {
  content: "\F1C2"; }

.mdi-desk-lamp:before {
  content: "\F95E"; }

.mdi-deskphone:before {
  content: "\F1C3"; }

.mdi-desktop-classic:before {
  content: "\F7BF"; }

.mdi-desktop-mac:before {
  content: "\F1C4"; }

.mdi-desktop-mac-dashboard:before {
  content: "\F9E7"; }

.mdi-desktop-tower:before {
  content: "\F1C5"; }

.mdi-desktop-tower-monitor:before {
  content: "\FAAA"; }

.mdi-details:before {
  content: "\F1C6"; }

.mdi-dev-to:before {
  content: "\FD4A"; }

.mdi-developer-board:before {
  content: "\F696"; }

.mdi-deviantart:before {
  content: "\F1C7"; }

.mdi-dialpad:before {
  content: "\F61C"; }

.mdi-diameter:before {
  content: "\FC3F"; }

.mdi-diameter-outline:before {
  content: "\FC40"; }

.mdi-diameter-variant:before {
  content: "\FC41"; }

.mdi-diamond:before {
  content: "\FB66"; }

.mdi-diamond-outline:before {
  content: "\FB67"; }

.mdi-diamond-stone:before {
  content: "\F1C8"; }

.mdi-dice-1:before {
  content: "\F1CA"; }

.mdi-dice-2:before {
  content: "\F1CB"; }

.mdi-dice-3:before {
  content: "\F1CC"; }

.mdi-dice-4:before {
  content: "\F1CD"; }

.mdi-dice-5:before {
  content: "\F1CE"; }

.mdi-dice-6:before {
  content: "\F1CF"; }

.mdi-dice-d10:before {
  content: "\F76E"; }

.mdi-dice-d12:before {
  content: "\F866"; }

.mdi-dice-d20:before {
  content: "\F5EA"; }

.mdi-dice-d4:before {
  content: "\F5EB"; }

.mdi-dice-d6:before {
  content: "\F5EC"; }

.mdi-dice-d8:before {
  content: "\F5ED"; }

.mdi-dice-multiple:before {
  content: "\F76D"; }

.mdi-dictionary:before {
  content: "\F61D"; }

.mdi-dip-switch:before {
  content: "\F7C0"; }

.mdi-directions:before {
  content: "\F1D0"; }

.mdi-directions-fork:before {
  content: "\F641"; }

.mdi-disc:before {
  content: "\F5EE"; }

.mdi-disc-alert:before {
  content: "\F1D1"; }

.mdi-disc-player:before {
  content: "\F95F"; }

.mdi-discord:before {
  content: "\F66F"; }

.mdi-dishwasher:before {
  content: "\FAAB"; }

.mdi-disqus:before {
  content: "\F1D2"; }

.mdi-disqus-outline:before {
  content: "\F1D3"; }

.mdi-diving-flippers:before {
  content: "\FD9B"; }

.mdi-diving-helmet:before {
  content: "\FD9C"; }

.mdi-diving-scuba:before {
  content: "\FD9D"; }

.mdi-diving-scuba-flag:before {
  content: "\FD9E"; }

.mdi-diving-scuba-tank:before {
  content: "\FD9F"; }

.mdi-diving-scuba-tank-multiple:before {
  content: "\FDA0"; }

.mdi-diving-snorkel:before {
  content: "\FDA1"; }

.mdi-division:before {
  content: "\F1D4"; }

.mdi-division-box:before {
  content: "\F1D5"; }

.mdi-dlna:before {
  content: "\FA40"; }

.mdi-dna:before {
  content: "\F683"; }

.mdi-dns:before {
  content: "\F1D6"; }

.mdi-dns-outline:before {
  content: "\FB68"; }

.mdi-do-not-disturb:before {
  content: "\F697"; }

.mdi-do-not-disturb-off:before {
  content: "\F698"; }

.mdi-docker:before {
  content: "\F867"; }

.mdi-doctor:before {
  content: "\FA41"; }

.mdi-dog:before {
  content: "\FA42"; }

.mdi-dog-service:before {
  content: "\FAAC"; }

.mdi-dog-side:before {
  content: "\FA43"; }

.mdi-dolby:before {
  content: "\F6B2"; }

.mdi-domain:before {
  content: "\F1D7"; }

.mdi-domain-off:before {
  content: "\FD4B"; }

.mdi-donkey:before {
  content: "\F7C1"; }

.mdi-door:before {
  content: "\F819"; }

.mdi-door-closed:before {
  content: "\F81A"; }

.mdi-door-open:before {
  content: "\F81B"; }

.mdi-doorbell-video:before {
  content: "\F868"; }

.mdi-dot-net:before {
  content: "\FAAD"; }

.mdi-dots-horizontal:before {
  content: "\F1D8"; }

.mdi-dots-horizontal-circle:before {
  content: "\F7C2"; }

.mdi-dots-horizontal-circle-outline:before {
  content: "\FB69"; }

.mdi-dots-vertical:before {
  content: "\F1D9"; }

.mdi-dots-vertical-circle:before {
  content: "\F7C3"; }

.mdi-dots-vertical-circle-outline:before {
  content: "\FB6A"; }

.mdi-douban:before {
  content: "\F699"; }

.mdi-download:before {
  content: "\F1DA"; }

.mdi-download-multiple:before {
  content: "\F9E8"; }

.mdi-download-network:before {
  content: "\F6F3"; }

.mdi-download-network-outline:before {
  content: "\FC42"; }

.mdi-download-outline:before {
  content: "\FB6B"; }

.mdi-drag:before {
  content: "\F1DB"; }

.mdi-drag-horizontal:before {
  content: "\F1DC"; }

.mdi-drag-variant:before {
  content: "\FB6C"; }

.mdi-drag-vertical:before {
  content: "\F1DD"; }

.mdi-drama-masks:before {
  content: "\FCDE"; }

.mdi-drawing:before {
  content: "\F1DE"; }

.mdi-drawing-box:before {
  content: "\F1DF"; }

.mdi-dribbble:before {
  content: "\F1E0"; }

.mdi-dribbble-box:before {
  content: "\F1E1"; }

.mdi-drone:before {
  content: "\F1E2"; }

.mdi-dropbox:before {
  content: "\F1E3"; }

.mdi-drupal:before {
  content: "\F1E4"; }

.mdi-duck:before {
  content: "\F1E5"; }

.mdi-dumbbell:before {
  content: "\F1E6"; }

.mdi-dump-truck:before {
  content: "\FC43"; }

.mdi-ear-hearing:before {
  content: "\F7C4"; }

.mdi-ear-hearing-off:before {
  content: "\FA44"; }

.mdi-earth:before {
  content: "\F1E7"; }

.mdi-earth-box:before {
  content: "\F6CC"; }

.mdi-earth-box-off:before {
  content: "\F6CD"; }

.mdi-earth-off:before {
  content: "\F1E8"; }

.mdi-edge:before {
  content: "\F1E9"; }

.mdi-egg:before {
  content: "\FAAE"; }

.mdi-egg-easter:before {
  content: "\FAAF"; }

.mdi-eight-track:before {
  content: "\F9E9"; }

.mdi-eject:before {
  content: "\F1EA"; }

.mdi-eject-outline:before {
  content: "\FB6D"; }

.mdi-elephant:before {
  content: "\F7C5"; }

.mdi-elevation-decline:before {
  content: "\F1EB"; }

.mdi-elevation-rise:before {
  content: "\F1EC"; }

.mdi-elevator:before {
  content: "\F1ED"; }

.mdi-email:before {
  content: "\F1EE"; }

.mdi-email-alert:before {
  content: "\F6CE"; }

.mdi-email-box:before {
  content: "\FCDF"; }

.mdi-email-check:before {
  content: "\FAB0"; }

.mdi-email-check-outline:before {
  content: "\FAB1"; }

.mdi-email-lock:before {
  content: "\F1F1"; }

.mdi-email-mark-as-unread:before {
  content: "\FB6E"; }

.mdi-email-open:before {
  content: "\F1EF"; }

.mdi-email-open-outline:before {
  content: "\F5EF"; }

.mdi-email-outline:before {
  content: "\F1F0"; }

.mdi-email-plus:before {
  content: "\F9EA"; }

.mdi-email-plus-outline:before {
  content: "\F9EB"; }

.mdi-email-search:before {
  content: "\F960"; }

.mdi-email-search-outline:before {
  content: "\F961"; }

.mdi-email-variant:before {
  content: "\F5F0"; }

.mdi-ember:before {
  content: "\FB15"; }

.mdi-emby:before {
  content: "\F6B3"; }

.mdi-emoticon:before {
  content: "\FC44"; }

.mdi-emoticon-angry:before {
  content: "\FC45"; }

.mdi-emoticon-angry-outline:before {
  content: "\FC46"; }

.mdi-emoticon-cool:before {
  content: "\FC47"; }

.mdi-emoticon-cool-outline:before {
  content: "\F1F3"; }

.mdi-emoticon-cry:before {
  content: "\FC48"; }

.mdi-emoticon-cry-outline:before {
  content: "\FC49"; }

.mdi-emoticon-dead:before {
  content: "\FC4A"; }

.mdi-emoticon-dead-outline:before {
  content: "\F69A"; }

.mdi-emoticon-devil:before {
  content: "\FC4B"; }

.mdi-emoticon-devil-outline:before {
  content: "\F1F4"; }

.mdi-emoticon-excited:before {
  content: "\FC4C"; }

.mdi-emoticon-excited-outline:before {
  content: "\F69B"; }

.mdi-emoticon-happy:before {
  content: "\FC4D"; }

.mdi-emoticon-happy-outline:before {
  content: "\F1F5"; }

.mdi-emoticon-kiss:before {
  content: "\FC4E"; }

.mdi-emoticon-kiss-outline:before {
  content: "\FC4F"; }

.mdi-emoticon-neutral:before {
  content: "\FC50"; }

.mdi-emoticon-neutral-outline:before {
  content: "\F1F6"; }

.mdi-emoticon-outline:before {
  content: "\F1F2"; }

.mdi-emoticon-poop:before {
  content: "\F1F7"; }

.mdi-emoticon-poop-outline:before {
  content: "\FC51"; }

.mdi-emoticon-sad:before {
  content: "\FC52"; }

.mdi-emoticon-sad-outline:before {
  content: "\F1F8"; }

.mdi-emoticon-tongue:before {
  content: "\F1F9"; }

.mdi-emoticon-tongue-outline:before {
  content: "\FC53"; }

.mdi-emoticon-wink:before {
  content: "\FC54"; }

.mdi-emoticon-wink-outline:before {
  content: "\FC55"; }

.mdi-engine:before {
  content: "\F1FA"; }

.mdi-engine-off:before {
  content: "\FA45"; }

.mdi-engine-off-outline:before {
  content: "\FA46"; }

.mdi-engine-outline:before {
  content: "\F1FB"; }

.mdi-equal:before {
  content: "\F1FC"; }

.mdi-equal-box:before {
  content: "\F1FD"; }

.mdi-eraser:before {
  content: "\F1FE"; }

.mdi-eraser-variant:before {
  content: "\F642"; }

.mdi-escalator:before {
  content: "\F1FF"; }

.mdi-eslint:before {
  content: "\FC56"; }

.mdi-et:before {
  content: "\FAB2"; }

.mdi-ethereum:before {
  content: "\F869"; }

.mdi-ethernet:before {
  content: "\F200"; }

.mdi-ethernet-cable:before {
  content: "\F201"; }

.mdi-ethernet-cable-off:before {
  content: "\F202"; }

.mdi-etsy:before {
  content: "\F203"; }

.mdi-ev-station:before {
  content: "\F5F1"; }

.mdi-eventbrite:before {
  content: "\F7C6"; }

.mdi-evernote:before {
  content: "\F204"; }

.mdi-exclamation:before {
  content: "\F205"; }

.mdi-exit-run:before {
  content: "\FA47"; }

.mdi-exit-to-app:before {
  content: "\F206"; }

.mdi-expand-all:before {
  content: "\FAB3"; }

.mdi-expand-all-outline:before {
  content: "\FAB4"; }

.mdi-exponent:before {
  content: "\F962"; }

.mdi-exponent-box:before {
  content: "\F963"; }

.mdi-export:before {
  content: "\F207"; }

.mdi-export-variant:before {
  content: "\FB6F"; }

.mdi-eye:before {
  content: "\F208"; }

.mdi-eye-check:before {
  content: "\FCE0"; }

.mdi-eye-check-outline:before {
  content: "\FCE1"; }

.mdi-eye-circle:before {
  content: "\FB70"; }

.mdi-eye-circle-outline:before {
  content: "\FB71"; }

.mdi-eye-off:before {
  content: "\F209"; }

.mdi-eye-off-outline:before {
  content: "\F6D0"; }

.mdi-eye-outline:before {
  content: "\F6CF"; }

.mdi-eye-plus:before {
  content: "\F86A"; }

.mdi-eye-plus-outline:before {
  content: "\F86B"; }

.mdi-eye-settings:before {
  content: "\F86C"; }

.mdi-eye-settings-outline:before {
  content: "\F86D"; }

.mdi-eyedropper:before {
  content: "\F20A"; }

.mdi-eyedropper-variant:before {
  content: "\F20B"; }

.mdi-face:before {
  content: "\F643"; }

.mdi-face-agent:before {
  content: "\FD4C"; }

.mdi-face-outline:before {
  content: "\FB72"; }

.mdi-face-profile:before {
  content: "\F644"; }

.mdi-face-recognition:before {
  content: "\FC57"; }

.mdi-facebook:before {
  content: "\F20C"; }

.mdi-facebook-box:before {
  content: "\F20D"; }

.mdi-facebook-messenger:before {
  content: "\F20E"; }

.mdi-facebook-workplace:before {
  content: "\FB16"; }

.mdi-factory:before {
  content: "\F20F"; }

.mdi-fan:before {
  content: "\F210"; }

.mdi-fan-off:before {
  content: "\F81C"; }

.mdi-fast-forward:before {
  content: "\F211"; }

.mdi-fast-forward-10:before {
  content: "\FD4D"; }

.mdi-fast-forward-30:before {
  content: "\FCE2"; }

.mdi-fast-forward-outline:before {
  content: "\F6D1"; }

.mdi-fax:before {
  content: "\F212"; }

.mdi-feather:before {
  content: "\F6D2"; }

.mdi-feature-search:before {
  content: "\FA48"; }

.mdi-feature-search-outline:before {
  content: "\FA49"; }

.mdi-fedora:before {
  content: "\F8DA"; }

.mdi-ferry:before {
  content: "\F213"; }

.mdi-file:before {
  content: "\F214"; }

.mdi-file-account:before {
  content: "\F73A"; }

.mdi-file-alert:before {
  content: "\FA4A"; }

.mdi-file-alert-outline:before {
  content: "\FA4B"; }

.mdi-file-cabinet:before {
  content: "\FAB5"; }

.mdi-file-cancel:before {
  content: "\FDA2"; }

.mdi-file-cancel-outline:before {
  content: "\FDA3"; }

.mdi-file-chart:before {
  content: "\F215"; }

.mdi-file-check:before {
  content: "\F216"; }

.mdi-file-check-outline:before {
  content: "\FE7B"; }

.mdi-file-cloud:before {
  content: "\F217"; }

.mdi-file-compare:before {
  content: "\F8A9"; }

.mdi-file-delimited:before {
  content: "\F218"; }

.mdi-file-document:before {
  content: "\F219"; }

.mdi-file-document-box:before {
  content: "\F21A"; }

.mdi-file-document-box-multiple:before {
  content: "\FAB6"; }

.mdi-file-document-box-multiple-outline:before {
  content: "\FAB7"; }

.mdi-file-document-box-outline:before {
  content: "\F9EC"; }

.mdi-file-document-edit:before {
  content: "\FDA4"; }

.mdi-file-document-edit-outline:before {
  content: "\FDA5"; }

.mdi-file-document-outline:before {
  content: "\F9ED"; }

.mdi-file-download:before {
  content: "\F964"; }

.mdi-file-download-outline:before {
  content: "\F965"; }

.mdi-file-excel:before {
  content: "\F21B"; }

.mdi-file-excel-box:before {
  content: "\F21C"; }

.mdi-file-export:before {
  content: "\F21D"; }

.mdi-file-eye:before {
  content: "\FDA6"; }

.mdi-file-eye-outline:before {
  content: "\FDA7"; }

.mdi-file-find:before {
  content: "\F21E"; }

.mdi-file-find-outline:before {
  content: "\FB73"; }

.mdi-file-hidden:before {
  content: "\F613"; }

.mdi-file-image:before {
  content: "\F21F"; }

.mdi-file-import:before {
  content: "\F220"; }

.mdi-file-lock:before {
  content: "\F221"; }

.mdi-file-move:before {
  content: "\FAB8"; }

.mdi-file-multiple:before {
  content: "\F222"; }

.mdi-file-music:before {
  content: "\F223"; }

.mdi-file-music-outline:before {
  content: "\FE7C"; }

.mdi-file-outline:before {
  content: "\F224"; }

.mdi-file-pdf:before {
  content: "\F225"; }

.mdi-file-pdf-box:before {
  content: "\F226"; }

.mdi-file-pdf-outline:before {
  content: "\FE7D"; }

.mdi-file-percent:before {
  content: "\F81D"; }

.mdi-file-plus:before {
  content: "\F751"; }

.mdi-file-powerpoint:before {
  content: "\F227"; }

.mdi-file-powerpoint-box:before {
  content: "\F228"; }

.mdi-file-presentation-box:before {
  content: "\F229"; }

.mdi-file-question:before {
  content: "\F86E"; }

.mdi-file-remove:before {
  content: "\FB74"; }

.mdi-file-replace:before {
  content: "\FB17"; }

.mdi-file-replace-outline:before {
  content: "\FB18"; }

.mdi-file-restore:before {
  content: "\F670"; }

.mdi-file-search:before {
  content: "\FC58"; }

.mdi-file-search-outline:before {
  content: "\FC59"; }

.mdi-file-send:before {
  content: "\F22A"; }

.mdi-file-table:before {
  content: "\FC5A"; }

.mdi-file-table-outline:before {
  content: "\FC5B"; }

.mdi-file-tree:before {
  content: "\F645"; }

.mdi-file-undo:before {
  content: "\F8DB"; }

.mdi-file-upload:before {
  content: "\FA4C"; }

.mdi-file-upload-outline:before {
  content: "\FA4D"; }

.mdi-file-video:before {
  content: "\F22B"; }

.mdi-file-video-outline:before {
  content: "\FE10"; }

.mdi-file-word:before {
  content: "\F22C"; }

.mdi-file-word-box:before {
  content: "\F22D"; }

.mdi-file-xml:before {
  content: "\F22E"; }

.mdi-film:before {
  content: "\F22F"; }

.mdi-filmstrip:before {
  content: "\F230"; }

.mdi-filmstrip-off:before {
  content: "\F231"; }

.mdi-filter:before {
  content: "\F232"; }

.mdi-filter-outline:before {
  content: "\F233"; }

.mdi-filter-remove:before {
  content: "\F234"; }

.mdi-filter-remove-outline:before {
  content: "\F235"; }

.mdi-filter-variant:before {
  content: "\F236"; }

.mdi-finance:before {
  content: "\F81E"; }

.mdi-find-replace:before {
  content: "\F6D3"; }

.mdi-fingerprint:before {
  content: "\F237"; }

.mdi-fire:before {
  content: "\F238"; }

.mdi-fire-truck:before {
  content: "\F8AA"; }

.mdi-firebase:before {
  content: "\F966"; }

.mdi-firefox:before {
  content: "\F239"; }

.mdi-fireplace:before {
  content: "\FE11"; }

.mdi-fireplace-off:before {
  content: "\FE12"; }

.mdi-firework:before {
  content: "\FE13"; }

.mdi-fish:before {
  content: "\F23A"; }

.mdi-flag:before {
  content: "\F23B"; }

.mdi-flag-checkered:before {
  content: "\F23C"; }

.mdi-flag-minus:before {
  content: "\FB75"; }

.mdi-flag-outline:before {
  content: "\F23D"; }

.mdi-flag-plus:before {
  content: "\FB76"; }

.mdi-flag-remove:before {
  content: "\FB77"; }

.mdi-flag-triangle:before {
  content: "\F23F"; }

.mdi-flag-variant:before {
  content: "\F240"; }

.mdi-flag-variant-outline:before {
  content: "\F23E"; }

.mdi-flare:before {
  content: "\FD4E"; }

.mdi-flash:before {
  content: "\F241"; }

.mdi-flash-auto:before {
  content: "\F242"; }

.mdi-flash-circle:before {
  content: "\F81F"; }

.mdi-flash-off:before {
  content: "\F243"; }

.mdi-flash-outline:before {
  content: "\F6D4"; }

.mdi-flash-red-eye:before {
  content: "\F67A"; }

.mdi-flashlight:before {
  content: "\F244"; }

.mdi-flashlight-off:before {
  content: "\F245"; }

.mdi-flask:before {
  content: "\F093"; }

.mdi-flask-empty:before {
  content: "\F094"; }

.mdi-flask-empty-outline:before {
  content: "\F095"; }

.mdi-flask-outline:before {
  content: "\F096"; }

.mdi-flattr:before {
  content: "\F246"; }

.mdi-flickr:before {
  content: "\FCE3"; }

.mdi-flip-to-back:before {
  content: "\F247"; }

.mdi-flip-to-front:before {
  content: "\F248"; }

.mdi-floor-lamp:before {
  content: "\F8DC"; }

.mdi-floor-plan:before {
  content: "\F820"; }

.mdi-floppy:before {
  content: "\F249"; }

.mdi-floppy-variant:before {
  content: "\F9EE"; }

.mdi-flower:before {
  content: "\F24A"; }

.mdi-flower-outline:before {
  content: "\F9EF"; }

.mdi-flower-poppy:before {
  content: "\FCE4"; }

.mdi-flower-tulip:before {
  content: "\F9F0"; }

.mdi-flower-tulip-outline:before {
  content: "\F9F1"; }

.mdi-folder:before {
  content: "\F24B"; }

.mdi-folder-account:before {
  content: "\F24C"; }

.mdi-folder-account-outline:before {
  content: "\FB78"; }

.mdi-folder-alert:before {
  content: "\FDA8"; }

.mdi-folder-alert-outline:before {
  content: "\FDA9"; }

.mdi-folder-clock:before {
  content: "\FAB9"; }

.mdi-folder-clock-outline:before {
  content: "\FABA"; }

.mdi-folder-download:before {
  content: "\F24D"; }

.mdi-folder-edit:before {
  content: "\F8DD"; }

.mdi-folder-edit-outline:before {
  content: "\FDAA"; }

.mdi-folder-google-drive:before {
  content: "\F24E"; }

.mdi-folder-image:before {
  content: "\F24F"; }

.mdi-folder-key:before {
  content: "\F8AB"; }

.mdi-folder-key-network:before {
  content: "\F8AC"; }

.mdi-folder-key-network-outline:before {
  content: "\FC5C"; }

.mdi-folder-lock:before {
  content: "\F250"; }

.mdi-folder-lock-open:before {
  content: "\F251"; }

.mdi-folder-move:before {
  content: "\F252"; }

.mdi-folder-multiple:before {
  content: "\F253"; }

.mdi-folder-multiple-image:before {
  content: "\F254"; }

.mdi-folder-multiple-outline:before {
  content: "\F255"; }

.mdi-folder-network:before {
  content: "\F86F"; }

.mdi-folder-network-outline:before {
  content: "\FC5D"; }

.mdi-folder-open:before {
  content: "\F76F"; }

.mdi-folder-open-outline:before {
  content: "\FDAB"; }

.mdi-folder-outline:before {
  content: "\F256"; }

.mdi-folder-plus:before {
  content: "\F257"; }

.mdi-folder-plus-outline:before {
  content: "\FB79"; }

.mdi-folder-pound:before {
  content: "\FCE5"; }

.mdi-folder-pound-outline:before {
  content: "\FCE6"; }

.mdi-folder-remove:before {
  content: "\F258"; }

.mdi-folder-remove-outline:before {
  content: "\FB7A"; }

.mdi-folder-search:before {
  content: "\F967"; }

.mdi-folder-search-outline:before {
  content: "\F968"; }

.mdi-folder-star:before {
  content: "\F69C"; }

.mdi-folder-star-outline:before {
  content: "\FB7B"; }

.mdi-folder-sync:before {
  content: "\FCE7"; }

.mdi-folder-sync-outline:before {
  content: "\FCE8"; }

.mdi-folder-text:before {
  content: "\FC5E"; }

.mdi-folder-text-outline:before {
  content: "\FC5F"; }

.mdi-folder-upload:before {
  content: "\F259"; }

.mdi-font-awesome:before {
  content: "\F03A"; }

.mdi-food:before {
  content: "\F25A"; }

.mdi-food-apple:before {
  content: "\F25B"; }

.mdi-food-apple-outline:before {
  content: "\FC60"; }

.mdi-food-croissant:before {
  content: "\F7C7"; }

.mdi-food-fork-drink:before {
  content: "\F5F2"; }

.mdi-food-off:before {
  content: "\F5F3"; }

.mdi-food-variant:before {
  content: "\F25C"; }

.mdi-football:before {
  content: "\F25D"; }

.mdi-football-australian:before {
  content: "\F25E"; }

.mdi-football-helmet:before {
  content: "\F25F"; }

.mdi-forklift:before {
  content: "\F7C8"; }

.mdi-format-align-bottom:before {
  content: "\F752"; }

.mdi-format-align-center:before {
  content: "\F260"; }

.mdi-format-align-justify:before {
  content: "\F261"; }

.mdi-format-align-left:before {
  content: "\F262"; }

.mdi-format-align-middle:before {
  content: "\F753"; }

.mdi-format-align-right:before {
  content: "\F263"; }

.mdi-format-align-top:before {
  content: "\F754"; }

.mdi-format-annotation-minus:before {
  content: "\FABB"; }

.mdi-format-annotation-plus:before {
  content: "\F646"; }

.mdi-format-bold:before {
  content: "\F264"; }

.mdi-format-clear:before {
  content: "\F265"; }

.mdi-format-color-fill:before {
  content: "\F266"; }

.mdi-format-color-highlight:before {
  content: "\FE14"; }

.mdi-format-color-text:before {
  content: "\F69D"; }

.mdi-format-columns:before {
  content: "\F8DE"; }

.mdi-format-float-center:before {
  content: "\F267"; }

.mdi-format-float-left:before {
  content: "\F268"; }

.mdi-format-float-none:before {
  content: "\F269"; }

.mdi-format-float-right:before {
  content: "\F26A"; }

.mdi-format-font:before {
  content: "\F6D5"; }

.mdi-format-font-size-decrease:before {
  content: "\F9F2"; }

.mdi-format-font-size-increase:before {
  content: "\F9F3"; }

.mdi-format-header-1:before {
  content: "\F26B"; }

.mdi-format-header-2:before {
  content: "\F26C"; }

.mdi-format-header-3:before {
  content: "\F26D"; }

.mdi-format-header-4:before {
  content: "\F26E"; }

.mdi-format-header-5:before {
  content: "\F26F"; }

.mdi-format-header-6:before {
  content: "\F270"; }

.mdi-format-header-decrease:before {
  content: "\F271"; }

.mdi-format-header-equal:before {
  content: "\F272"; }

.mdi-format-header-increase:before {
  content: "\F273"; }

.mdi-format-header-pound:before {
  content: "\F274"; }

.mdi-format-horizontal-align-center:before {
  content: "\F61E"; }

.mdi-format-horizontal-align-left:before {
  content: "\F61F"; }

.mdi-format-horizontal-align-right:before {
  content: "\F620"; }

.mdi-format-indent-decrease:before {
  content: "\F275"; }

.mdi-format-indent-increase:before {
  content: "\F276"; }

.mdi-format-italic:before {
  content: "\F277"; }

.mdi-format-letter-case:before {
  content: "\FB19"; }

.mdi-format-letter-case-lower:before {
  content: "\FB1A"; }

.mdi-format-letter-case-upper:before {
  content: "\FB1B"; }

.mdi-format-line-spacing:before {
  content: "\F278"; }

.mdi-format-line-style:before {
  content: "\F5C8"; }

.mdi-format-line-weight:before {
  content: "\F5C9"; }

.mdi-format-list-bulleted:before {
  content: "\F279"; }

.mdi-format-list-bulleted-square:before {
  content: "\FDAC"; }

.mdi-format-list-bulleted-type:before {
  content: "\F27A"; }

.mdi-format-list-checkbox:before {
  content: "\F969"; }

.mdi-format-list-checks:before {
  content: "\F755"; }

.mdi-format-list-numbered:before {
  content: "\F27B"; }

.mdi-format-list-numbered-rtl:before {
  content: "\FCE9"; }

.mdi-format-page-break:before {
  content: "\F6D6"; }

.mdi-format-paint:before {
  content: "\F27C"; }

.mdi-format-paragraph:before {
  content: "\F27D"; }

.mdi-format-pilcrow:before {
  content: "\F6D7"; }

.mdi-format-quote-close:before {
  content: "\F27E"; }

.mdi-format-quote-open:before {
  content: "\F756"; }

.mdi-format-rotate-90:before {
  content: "\F6A9"; }

.mdi-format-section:before {
  content: "\F69E"; }

.mdi-format-size:before {
  content: "\F27F"; }

.mdi-format-strikethrough:before {
  content: "\F280"; }

.mdi-format-strikethrough-variant:before {
  content: "\F281"; }

.mdi-format-subscript:before {
  content: "\F282"; }

.mdi-format-superscript:before {
  content: "\F283"; }

.mdi-format-text:before {
  content: "\F284"; }

.mdi-format-text-rotation-down:before {
  content: "\FD4F"; }

.mdi-format-text-rotation-none:before {
  content: "\FD50"; }

.mdi-format-text-variant:before {
  content: "\FE15"; }

.mdi-format-text-wrapping-clip:before {
  content: "\FCEA"; }

.mdi-format-text-wrapping-overflow:before {
  content: "\FCEB"; }

.mdi-format-text-wrapping-wrap:before {
  content: "\FCEC"; }

.mdi-format-textbox:before {
  content: "\FCED"; }

.mdi-format-textdirection-l-to-r:before {
  content: "\F285"; }

.mdi-format-textdirection-r-to-l:before {
  content: "\F286"; }

.mdi-format-title:before {
  content: "\F5F4"; }

.mdi-format-underline:before {
  content: "\F287"; }

.mdi-format-vertical-align-bottom:before {
  content: "\F621"; }

.mdi-format-vertical-align-center:before {
  content: "\F622"; }

.mdi-format-vertical-align-top:before {
  content: "\F623"; }

.mdi-format-wrap-inline:before {
  content: "\F288"; }

.mdi-format-wrap-square:before {
  content: "\F289"; }

.mdi-format-wrap-tight:before {
  content: "\F28A"; }

.mdi-format-wrap-top-bottom:before {
  content: "\F28B"; }

.mdi-forum:before {
  content: "\F28C"; }

.mdi-forum-outline:before {
  content: "\F821"; }

.mdi-forward:before {
  content: "\F28D"; }

.mdi-forwardburger:before {
  content: "\FD51"; }

.mdi-fountain:before {
  content: "\F96A"; }

.mdi-fountain-pen:before {
  content: "\FCEE"; }

.mdi-fountain-pen-tip:before {
  content: "\FCEF"; }

.mdi-foursquare:before {
  content: "\F28E"; }

.mdi-freebsd:before {
  content: "\F8DF"; }

.mdi-fridge:before {
  content: "\F290"; }

.mdi-fridge-bottom:before {
  content: "\F292"; }

.mdi-fridge-outline:before {
  content: "\F28F"; }

.mdi-fridge-top:before {
  content: "\F291"; }

.mdi-fuel:before {
  content: "\F7C9"; }

.mdi-fullscreen:before {
  content: "\F293"; }

.mdi-fullscreen-exit:before {
  content: "\F294"; }

.mdi-function:before {
  content: "\F295"; }

.mdi-function-variant:before {
  content: "\F870"; }

.mdi-fuse:before {
  content: "\FC61"; }

.mdi-fuse-blade:before {
  content: "\FC62"; }

.mdi-gamepad:before {
  content: "\F296"; }

.mdi-gamepad-circle:before {
  content: "\FE16"; }

.mdi-gamepad-circle-down:before {
  content: "\FE17"; }

.mdi-gamepad-circle-left:before {
  content: "\FE18"; }

.mdi-gamepad-circle-outline:before {
  content: "\FE19"; }

.mdi-gamepad-circle-right:before {
  content: "\FE1A"; }

.mdi-gamepad-circle-up:before {
  content: "\FE1B"; }

.mdi-gamepad-down:before {
  content: "\FE1C"; }

.mdi-gamepad-left:before {
  content: "\FE1D"; }

.mdi-gamepad-right:before {
  content: "\FE1E"; }

.mdi-gamepad-round:before {
  content: "\FE1F"; }

.mdi-gamepad-round-down:before {
  content: "\FE20"; }

.mdi-gamepad-round-left:before {
  content: "\FE21"; }

.mdi-gamepad-round-outline:before {
  content: "\FE22"; }

.mdi-gamepad-round-right:before {
  content: "\FE23"; }

.mdi-gamepad-round-up:before {
  content: "\FE24"; }

.mdi-gamepad-up:before {
  content: "\FE25"; }

.mdi-gamepad-variant:before {
  content: "\F297"; }

.mdi-gantry-crane:before {
  content: "\FDAD"; }

.mdi-garage:before {
  content: "\F6D8"; }

.mdi-garage-alert:before {
  content: "\F871"; }

.mdi-garage-open:before {
  content: "\F6D9"; }

.mdi-gas-cylinder:before {
  content: "\F647"; }

.mdi-gas-station:before {
  content: "\F298"; }

.mdi-gate:before {
  content: "\F299"; }

.mdi-gate-and:before {
  content: "\F8E0"; }

.mdi-gate-nand:before {
  content: "\F8E1"; }

.mdi-gate-nor:before {
  content: "\F8E2"; }

.mdi-gate-not:before {
  content: "\F8E3"; }

.mdi-gate-or:before {
  content: "\F8E4"; }

.mdi-gate-xnor:before {
  content: "\F8E5"; }

.mdi-gate-xor:before {
  content: "\F8E6"; }

.mdi-gatsby:before {
  content: "\FE26"; }

.mdi-gauge:before {
  content: "\F29A"; }

.mdi-gauge-empty:before {
  content: "\F872"; }

.mdi-gauge-full:before {
  content: "\F873"; }

.mdi-gauge-low:before {
  content: "\F874"; }

.mdi-gavel:before {
  content: "\F29B"; }

.mdi-gender-female:before {
  content: "\F29C"; }

.mdi-gender-male:before {
  content: "\F29D"; }

.mdi-gender-male-female:before {
  content: "\F29E"; }

.mdi-gender-transgender:before {
  content: "\F29F"; }

.mdi-gentoo:before {
  content: "\F8E7"; }

.mdi-gesture:before {
  content: "\F7CA"; }

.mdi-gesture-double-tap:before {
  content: "\F73B"; }

.mdi-gesture-pinch:before {
  content: "\FABC"; }

.mdi-gesture-spread:before {
  content: "\FABD"; }

.mdi-gesture-swipe:before {
  content: "\FD52"; }

.mdi-gesture-swipe-down:before {
  content: "\F73C"; }

.mdi-gesture-swipe-horizontal:before {
  content: "\FABE"; }

.mdi-gesture-swipe-left:before {
  content: "\F73D"; }

.mdi-gesture-swipe-right:before {
  content: "\F73E"; }

.mdi-gesture-swipe-up:before {
  content: "\F73F"; }

.mdi-gesture-swipe-vertical:before {
  content: "\FABF"; }

.mdi-gesture-tap:before {
  content: "\F740"; }

.mdi-gesture-tap-hold:before {
  content: "\FD53"; }

.mdi-gesture-two-double-tap:before {
  content: "\F741"; }

.mdi-gesture-two-tap:before {
  content: "\F742"; }

.mdi-ghost:before {
  content: "\F2A0"; }

.mdi-ghost-off:before {
  content: "\F9F4"; }

.mdi-gif:before {
  content: "\FD54"; }

.mdi-gift:before {
  content: "\FE27"; }

.mdi-gift-outline:before {
  content: "\F2A1"; }

.mdi-git:before {
  content: "\F2A2"; }

.mdi-github-box:before {
  content: "\F2A3"; }

.mdi-github-circle:before {
  content: "\F2A4"; }

.mdi-github-face:before {
  content: "\F6DA"; }

.mdi-gitlab:before {
  content: "\FB7C"; }

.mdi-glass-cocktail:before {
  content: "\F356"; }

.mdi-glass-flute:before {
  content: "\F2A5"; }

.mdi-glass-mug:before {
  content: "\F2A6"; }

.mdi-glass-stange:before {
  content: "\F2A7"; }

.mdi-glass-tulip:before {
  content: "\F2A8"; }

.mdi-glass-wine:before {
  content: "\F875"; }

.mdi-glassdoor:before {
  content: "\F2A9"; }

.mdi-glasses:before {
  content: "\F2AA"; }

.mdi-globe-model:before {
  content: "\F8E8"; }

.mdi-gmail:before {
  content: "\F2AB"; }

.mdi-gnome:before {
  content: "\F2AC"; }

.mdi-go-kart:before {
  content: "\FD55"; }

.mdi-go-kart-track:before {
  content: "\FD56"; }

.mdi-gog:before {
  content: "\FB7D"; }

.mdi-golf:before {
  content: "\F822"; }

.mdi-gondola:before {
  content: "\F685"; }

.mdi-goodreads:before {
  content: "\FD57"; }

.mdi-google:before {
  content: "\F2AD"; }

.mdi-google-adwords:before {
  content: "\FC63"; }

.mdi-google-allo:before {
  content: "\F801"; }

.mdi-google-analytics:before {
  content: "\F7CB"; }

.mdi-google-assistant:before {
  content: "\F7CC"; }

.mdi-google-cardboard:before {
  content: "\F2AE"; }

.mdi-google-chrome:before {
  content: "\F2AF"; }

.mdi-google-circles:before {
  content: "\F2B0"; }

.mdi-google-circles-communities:before {
  content: "\F2B1"; }

.mdi-google-circles-extended:before {
  content: "\F2B2"; }

.mdi-google-circles-group:before {
  content: "\F2B3"; }

.mdi-google-classroom:before {
  content: "\F2C0"; }

.mdi-google-controller:before {
  content: "\F2B4"; }

.mdi-google-controller-off:before {
  content: "\F2B5"; }

.mdi-google-drive:before {
  content: "\F2B6"; }

.mdi-google-earth:before {
  content: "\F2B7"; }

.mdi-google-fit:before {
  content: "\F96B"; }

.mdi-google-glass:before {
  content: "\F2B8"; }

.mdi-google-hangouts:before {
  content: "\F2C9"; }

.mdi-google-home:before {
  content: "\F823"; }

.mdi-google-keep:before {
  content: "\F6DB"; }

.mdi-google-lens:before {
  content: "\F9F5"; }

.mdi-google-maps:before {
  content: "\F5F5"; }

.mdi-google-nearby:before {
  content: "\F2B9"; }

.mdi-google-pages:before {
  content: "\F2BA"; }

.mdi-google-photos:before {
  content: "\F6DC"; }

.mdi-google-physical-web:before {
  content: "\F2BB"; }

.mdi-google-play:before {
  content: "\F2BC"; }

.mdi-google-plus:before {
  content: "\F2BD"; }

.mdi-google-plus-box:before {
  content: "\F2BE"; }

.mdi-google-spreadsheet:before {
  content: "\F9F6"; }

.mdi-google-street-view:before {
  content: "\FC64"; }

.mdi-google-translate:before {
  content: "\F2BF"; }

.mdi-gpu:before {
  content: "\F8AD"; }

.mdi-gradient:before {
  content: "\F69F"; }

.mdi-grain:before {
  content: "\FD58"; }

.mdi-graphql:before {
  content: "\F876"; }

.mdi-grave-stone:before {
  content: "\FB7E"; }

.mdi-grease-pencil:before {
  content: "\F648"; }

.mdi-greater-than:before {
  content: "\F96C"; }

.mdi-greater-than-or-equal:before {
  content: "\F96D"; }

.mdi-grid:before {
  content: "\F2C1"; }

.mdi-grid-large:before {
  content: "\F757"; }

.mdi-grid-off:before {
  content: "\F2C2"; }

.mdi-grill:before {
  content: "\FE28"; }

.mdi-group:before {
  content: "\F2C3"; }

.mdi-guitar-acoustic:before {
  content: "\F770"; }

.mdi-guitar-electric:before {
  content: "\F2C4"; }

.mdi-guitar-pick:before {
  content: "\F2C5"; }

.mdi-guitar-pick-outline:before {
  content: "\F2C6"; }

.mdi-guy-fawkes-mask:before {
  content: "\F824"; }

.mdi-hackernews:before {
  content: "\F624"; }

.mdi-hail:before {
  content: "\FAC0"; }

.mdi-halloween:before {
  content: "\FB7F"; }

.mdi-hamburger:before {
  content: "\F684"; }

.mdi-hammer:before {
  content: "\F8E9"; }

.mdi-hand:before {
  content: "\FA4E"; }

.mdi-hand-left:before {
  content: "\FE29"; }

.mdi-hand-okay:before {
  content: "\FA4F"; }

.mdi-hand-peace:before {
  content: "\FA50"; }

.mdi-hand-peace-variant:before {
  content: "\FA51"; }

.mdi-hand-pointing-down:before {
  content: "\FA52"; }

.mdi-hand-pointing-left:before {
  content: "\FA53"; }

.mdi-hand-pointing-right:before {
  content: "\F2C7"; }

.mdi-hand-pointing-up:before {
  content: "\FA54"; }

.mdi-hand-right:before {
  content: "\FE2A"; }

.mdi-hand-saw:before {
  content: "\FE2B"; }

.mdi-hanger:before {
  content: "\F2C8"; }

.mdi-hard-hat:before {
  content: "\F96E"; }

.mdi-harddisk:before {
  content: "\F2CA"; }

.mdi-hat-fedora:before {
  content: "\FB80"; }

.mdi-hazard-lights:before {
  content: "\FC65"; }

.mdi-hdr:before {
  content: "\FD59"; }

.mdi-hdr-off:before {
  content: "\FD5A"; }

.mdi-headphones:before {
  content: "\F2CB"; }

.mdi-headphones-bluetooth:before {
  content: "\F96F"; }

.mdi-headphones-box:before {
  content: "\F2CC"; }

.mdi-headphones-off:before {
  content: "\F7CD"; }

.mdi-headphones-settings:before {
  content: "\F2CD"; }

.mdi-headset:before {
  content: "\F2CE"; }

.mdi-headset-dock:before {
  content: "\F2CF"; }

.mdi-headset-off:before {
  content: "\F2D0"; }

.mdi-heart:before {
  content: "\F2D1"; }

.mdi-heart-box:before {
  content: "\F2D2"; }

.mdi-heart-box-outline:before {
  content: "\F2D3"; }

.mdi-heart-broken:before {
  content: "\F2D4"; }

.mdi-heart-broken-outline:before {
  content: "\FCF0"; }

.mdi-heart-circle:before {
  content: "\F970"; }

.mdi-heart-circle-outline:before {
  content: "\F971"; }

.mdi-heart-half:before {
  content: "\F6DE"; }

.mdi-heart-half-full:before {
  content: "\F6DD"; }

.mdi-heart-half-outline:before {
  content: "\F6DF"; }

.mdi-heart-multiple:before {
  content: "\FA55"; }

.mdi-heart-multiple-outline:before {
  content: "\FA56"; }

.mdi-heart-off:before {
  content: "\F758"; }

.mdi-heart-outline:before {
  content: "\F2D5"; }

.mdi-heart-pulse:before {
  content: "\F5F6"; }

.mdi-helicopter:before {
  content: "\FAC1"; }

.mdi-help:before {
  content: "\F2D6"; }

.mdi-help-box:before {
  content: "\F78A"; }

.mdi-help-circle:before {
  content: "\F2D7"; }

.mdi-help-circle-outline:before {
  content: "\F625"; }

.mdi-help-network:before {
  content: "\F6F4"; }

.mdi-help-network-outline:before {
  content: "\FC66"; }

.mdi-help-rhombus:before {
  content: "\FB81"; }

.mdi-help-rhombus-outline:before {
  content: "\FB82"; }

.mdi-hexagon:before {
  content: "\F2D8"; }

.mdi-hexagon-multiple:before {
  content: "\F6E0"; }

.mdi-hexagon-outline:before {
  content: "\F2D9"; }

.mdi-hexagon-slice-1:before {
  content: "\FAC2"; }

.mdi-hexagon-slice-2:before {
  content: "\FAC3"; }

.mdi-hexagon-slice-3:before {
  content: "\FAC4"; }

.mdi-hexagon-slice-4:before {
  content: "\FAC5"; }

.mdi-hexagon-slice-5:before {
  content: "\FAC6"; }

.mdi-hexagon-slice-6:before {
  content: "\FAC7"; }

.mdi-hexagram:before {
  content: "\FAC8"; }

.mdi-hexagram-outline:before {
  content: "\FAC9"; }

.mdi-high-definition:before {
  content: "\F7CE"; }

.mdi-high-definition-box:before {
  content: "\F877"; }

.mdi-highway:before {
  content: "\F5F7"; }

.mdi-hiking:before {
  content: "\FD5B"; }

.mdi-hinduism:before {
  content: "\F972"; }

.mdi-history:before {
  content: "\F2DA"; }

.mdi-hockey-puck:before {
  content: "\F878"; }

.mdi-hockey-sticks:before {
  content: "\F879"; }

.mdi-hololens:before {
  content: "\F2DB"; }

.mdi-home:before {
  content: "\F2DC"; }

.mdi-home-account:before {
  content: "\F825"; }

.mdi-home-alert:before {
  content: "\F87A"; }

.mdi-home-assistant:before {
  content: "\F7CF"; }

.mdi-home-automation:before {
  content: "\F7D0"; }

.mdi-home-circle:before {
  content: "\F7D1"; }

.mdi-home-city:before {
  content: "\FCF1"; }

.mdi-home-city-outline:before {
  content: "\FCF2"; }

.mdi-home-currency-usd:before {
  content: "\F8AE"; }

.mdi-home-floor-0:before {
  content: "\FDAE"; }

.mdi-home-floor-1:before {
  content: "\FD5C"; }

.mdi-home-floor-2:before {
  content: "\FD5D"; }

.mdi-home-floor-3:before {
  content: "\FD5E"; }

.mdi-home-floor-a:before {
  content: "\FD5F"; }

.mdi-home-floor-b:before {
  content: "\FD60"; }

.mdi-home-floor-g:before {
  content: "\FD61"; }

.mdi-home-floor-l:before {
  content: "\FD62"; }

.mdi-home-floor-negative-1:before {
  content: "\FDAF"; }

.mdi-home-group:before {
  content: "\FDB0"; }

.mdi-home-heart:before {
  content: "\F826"; }

.mdi-home-lock:before {
  content: "\F8EA"; }

.mdi-home-lock-open:before {
  content: "\F8EB"; }

.mdi-home-map-marker:before {
  content: "\F5F8"; }

.mdi-home-minus:before {
  content: "\F973"; }

.mdi-home-modern:before {
  content: "\F2DD"; }

.mdi-home-outline:before {
  content: "\F6A0"; }

.mdi-home-plus:before {
  content: "\F974"; }

.mdi-home-variant:before {
  content: "\F2DE"; }

.mdi-home-variant-outline:before {
  content: "\FB83"; }

.mdi-hook:before {
  content: "\F6E1"; }

.mdi-hook-off:before {
  content: "\F6E2"; }

.mdi-hops:before {
  content: "\F2DF"; }

.mdi-horseshoe:before {
  content: "\FA57"; }

.mdi-hospital:before {
  content: "\F2E0"; }

.mdi-hospital-building:before {
  content: "\F2E1"; }

.mdi-hospital-marker:before {
  content: "\F2E2"; }

.mdi-hot-tub:before {
  content: "\F827"; }

.mdi-hotel:before {
  content: "\F2E3"; }

.mdi-houzz:before {
  content: "\F2E4"; }

.mdi-houzz-box:before {
  content: "\F2E5"; }

.mdi-hubspot:before {
  content: "\FCF3"; }

.mdi-hulu:before {
  content: "\F828"; }

.mdi-human:before {
  content: "\F2E6"; }

.mdi-human-child:before {
  content: "\F2E7"; }

.mdi-human-female:before {
  content: "\F649"; }

.mdi-human-female-boy:before {
  content: "\FA58"; }

.mdi-human-female-female:before {
  content: "\FA59"; }

.mdi-human-female-girl:before {
  content: "\FA5A"; }

.mdi-human-greeting:before {
  content: "\F64A"; }

.mdi-human-handsdown:before {
  content: "\F64B"; }

.mdi-human-handsup:before {
  content: "\F64C"; }

.mdi-human-male:before {
  content: "\F64D"; }

.mdi-human-male-boy:before {
  content: "\FA5B"; }

.mdi-human-male-female:before {
  content: "\F2E8"; }

.mdi-human-male-girl:before {
  content: "\FA5C"; }

.mdi-human-male-male:before {
  content: "\FA5D"; }

.mdi-human-pregnant:before {
  content: "\F5CF"; }

.mdi-humble-bundle:before {
  content: "\F743"; }

.mdi-ice-cream:before {
  content: "\F829"; }

.mdi-iframe:before {
  content: "\FC67"; }

.mdi-iframe-outline:before {
  content: "\FC68"; }

.mdi-image:before {
  content: "\F2E9"; }

.mdi-image-album:before {
  content: "\F2EA"; }

.mdi-image-area:before {
  content: "\F2EB"; }

.mdi-image-area-close:before {
  content: "\F2EC"; }

.mdi-image-broken:before {
  content: "\F2ED"; }

.mdi-image-broken-variant:before {
  content: "\F2EE"; }

.mdi-image-filter:before {
  content: "\F2EF"; }

.mdi-image-filter-black-white:before {
  content: "\F2F0"; }

.mdi-image-filter-center-focus:before {
  content: "\F2F1"; }

.mdi-image-filter-center-focus-weak:before {
  content: "\F2F2"; }

.mdi-image-filter-drama:before {
  content: "\F2F3"; }

.mdi-image-filter-frames:before {
  content: "\F2F4"; }

.mdi-image-filter-hdr:before {
  content: "\F2F5"; }

.mdi-image-filter-none:before {
  content: "\F2F6"; }

.mdi-image-filter-tilt-shift:before {
  content: "\F2F7"; }

.mdi-image-filter-vintage:before {
  content: "\F2F8"; }

.mdi-image-frame:before {
  content: "\FE2C"; }

.mdi-image-move:before {
  content: "\F9F7"; }

.mdi-image-multiple:before {
  content: "\F2F9"; }

.mdi-image-off:before {
  content: "\F82A"; }

.mdi-image-outline:before {
  content: "\F975"; }

.mdi-image-plus:before {
  content: "\F87B"; }

.mdi-image-search:before {
  content: "\F976"; }

.mdi-image-search-outline:before {
  content: "\F977"; }

.mdi-image-size-select-actual:before {
  content: "\FC69"; }

.mdi-image-size-select-large:before {
  content: "\FC6A"; }

.mdi-image-size-select-small:before {
  content: "\FC6B"; }

.mdi-import:before {
  content: "\F2FA"; }

.mdi-inbox:before {
  content: "\F686"; }

.mdi-inbox-arrow-down:before {
  content: "\F2FB"; }

.mdi-inbox-arrow-up:before {
  content: "\F3D1"; }

.mdi-inbox-multiple:before {
  content: "\F8AF"; }

.mdi-inbox-multiple-outline:before {
  content: "\FB84"; }

.mdi-incognito:before {
  content: "\F5F9"; }

.mdi-infinity:before {
  content: "\F6E3"; }

.mdi-information:before {
  content: "\F2FC"; }

.mdi-information-outline:before {
  content: "\F2FD"; }

.mdi-information-variant:before {
  content: "\F64E"; }

.mdi-instagram:before {
  content: "\F2FE"; }

.mdi-instapaper:before {
  content: "\F2FF"; }

.mdi-internet-explorer:before {
  content: "\F300"; }

.mdi-invert-colors:before {
  content: "\F301"; }

.mdi-invert-colors-off:before {
  content: "\FE2D"; }

.mdi-ip:before {
  content: "\FA5E"; }

.mdi-ip-network:before {
  content: "\FA5F"; }

.mdi-ip-network-outline:before {
  content: "\FC6C"; }

.mdi-ipod:before {
  content: "\FC6D"; }

.mdi-islam:before {
  content: "\F978"; }

.mdi-itunes:before {
  content: "\F676"; }

.mdi-jabber:before {
  content: "\FDB1"; }

.mdi-jeepney:before {
  content: "\F302"; }

.mdi-jira:before {
  content: "\F303"; }

.mdi-jquery:before {
  content: "\F87C"; }

.mdi-jsfiddle:before {
  content: "\F304"; }

.mdi-json:before {
  content: "\F626"; }

.mdi-judaism:before {
  content: "\F979"; }

.mdi-kabaddi:before {
  content: "\FD63"; }

.mdi-karate:before {
  content: "\F82B"; }

.mdi-keg:before {
  content: "\F305"; }

.mdi-kettle:before {
  content: "\F5FA"; }

.mdi-key:before {
  content: "\F306"; }

.mdi-key-change:before {
  content: "\F307"; }

.mdi-key-minus:before {
  content: "\F308"; }

.mdi-key-outline:before {
  content: "\FDB2"; }

.mdi-key-plus:before {
  content: "\F309"; }

.mdi-key-remove:before {
  content: "\F30A"; }

.mdi-key-variant:before {
  content: "\F30B"; }

.mdi-keyboard:before {
  content: "\F30C"; }

.mdi-keyboard-backspace:before {
  content: "\F30D"; }

.mdi-keyboard-caps:before {
  content: "\F30E"; }

.mdi-keyboard-close:before {
  content: "\F30F"; }

.mdi-keyboard-off:before {
  content: "\F310"; }

.mdi-keyboard-off-outline:before {
  content: "\FE2E"; }

.mdi-keyboard-outline:before {
  content: "\F97A"; }

.mdi-keyboard-return:before {
  content: "\F311"; }

.mdi-keyboard-settings:before {
  content: "\F9F8"; }

.mdi-keyboard-settings-outline:before {
  content: "\F9F9"; }

.mdi-keyboard-tab:before {
  content: "\F312"; }

.mdi-keyboard-variant:before {
  content: "\F313"; }

.mdi-kickstarter:before {
  content: "\F744"; }

.mdi-knife:before {
  content: "\F9FA"; }

.mdi-knife-military:before {
  content: "\F9FB"; }

.mdi-kodi:before {
  content: "\F314"; }

.mdi-label:before {
  content: "\F315"; }

.mdi-label-off:before {
  content: "\FACA"; }

.mdi-label-off-outline:before {
  content: "\FACB"; }

.mdi-label-outline:before {
  content: "\F316"; }

.mdi-label-variant:before {
  content: "\FACC"; }

.mdi-label-variant-outline:before {
  content: "\FACD"; }

.mdi-ladybug:before {
  content: "\F82C"; }

.mdi-lambda:before {
  content: "\F627"; }

.mdi-lamp:before {
  content: "\F6B4"; }

.mdi-lan:before {
  content: "\F317"; }

.mdi-lan-connect:before {
  content: "\F318"; }

.mdi-lan-disconnect:before {
  content: "\F319"; }

.mdi-lan-pending:before {
  content: "\F31A"; }

.mdi-language-c:before {
  content: "\F671"; }

.mdi-language-cpp:before {
  content: "\F672"; }

.mdi-language-csharp:before {
  content: "\F31B"; }

.mdi-language-css3:before {
  content: "\F31C"; }

.mdi-language-go:before {
  content: "\F7D2"; }

.mdi-language-haskell:before {
  content: "\FC6E"; }

.mdi-language-html5:before {
  content: "\F31D"; }

.mdi-language-java:before {
  content: "\FB1C"; }

.mdi-language-javascript:before {
  content: "\F31E"; }

.mdi-language-lua:before {
  content: "\F8B0"; }

.mdi-language-php:before {
  content: "\F31F"; }

.mdi-language-python:before {
  content: "\F320"; }

.mdi-language-python-text:before {
  content: "\F321"; }

.mdi-language-r:before {
  content: "\F7D3"; }

.mdi-language-ruby-on-rails:before {
  content: "\FACE"; }

.mdi-language-swift:before {
  content: "\F6E4"; }

.mdi-language-typescript:before {
  content: "\F6E5"; }

.mdi-laptop:before {
  content: "\F322"; }

.mdi-laptop-chromebook:before {
  content: "\F323"; }

.mdi-laptop-mac:before {
  content: "\F324"; }

.mdi-laptop-off:before {
  content: "\F6E6"; }

.mdi-laptop-windows:before {
  content: "\F325"; }

.mdi-laravel:before {
  content: "\FACF"; }

.mdi-lastfm:before {
  content: "\F326"; }

.mdi-lastpass:before {
  content: "\F446"; }

.mdi-launch:before {
  content: "\F327"; }

.mdi-lava-lamp:before {
  content: "\F7D4"; }

.mdi-layers:before {
  content: "\F328"; }

.mdi-layers-minus:before {
  content: "\FE2F"; }

.mdi-layers-off:before {
  content: "\F329"; }

.mdi-layers-off-outline:before {
  content: "\F9FC"; }

.mdi-layers-outline:before {
  content: "\F9FD"; }

.mdi-layers-plus:before {
  content: "\FE30"; }

.mdi-layers-remove:before {
  content: "\FE31"; }

.mdi-lead-pencil:before {
  content: "\F64F"; }

.mdi-leaf:before {
  content: "\F32A"; }

.mdi-leaf-maple:before {
  content: "\FC6F"; }

.mdi-leak:before {
  content: "\FDB3"; }

.mdi-leak-off:before {
  content: "\FDB4"; }

.mdi-led-off:before {
  content: "\F32B"; }

.mdi-led-on:before {
  content: "\F32C"; }

.mdi-led-outline:before {
  content: "\F32D"; }

.mdi-led-strip:before {
  content: "\F7D5"; }

.mdi-led-variant-off:before {
  content: "\F32E"; }

.mdi-led-variant-on:before {
  content: "\F32F"; }

.mdi-led-variant-outline:before {
  content: "\F330"; }

.mdi-less-than:before {
  content: "\F97B"; }

.mdi-less-than-or-equal:before {
  content: "\F97C"; }

.mdi-library:before {
  content: "\F331"; }

.mdi-library-books:before {
  content: "\F332"; }

.mdi-library-movie:before {
  content: "\FCF4"; }

.mdi-library-music:before {
  content: "\F333"; }

.mdi-library-plus:before {
  content: "\F334"; }

.mdi-library-shelves:before {
  content: "\FB85"; }

.mdi-library-video:before {
  content: "\FCF5"; }

.mdi-lifebuoy:before {
  content: "\F87D"; }

.mdi-light-switch:before {
  content: "\F97D"; }

.mdi-lightbulb:before {
  content: "\F335"; }

.mdi-lightbulb-off:before {
  content: "\FE32"; }

.mdi-lightbulb-off-outline:before {
  content: "\FE33"; }

.mdi-lightbulb-on:before {
  content: "\F6E7"; }

.mdi-lightbulb-on-outline:before {
  content: "\F6E8"; }

.mdi-lightbulb-outline:before {
  content: "\F336"; }

.mdi-lighthouse:before {
  content: "\F9FE"; }

.mdi-lighthouse-on:before {
  content: "\F9FF"; }

.mdi-link:before {
  content: "\F337"; }

.mdi-link-box:before {
  content: "\FCF6"; }

.mdi-link-box-outline:before {
  content: "\FCF7"; }

.mdi-link-box-variant:before {
  content: "\FCF8"; }

.mdi-link-box-variant-outline:before {
  content: "\FCF9"; }

.mdi-link-off:before {
  content: "\F338"; }

.mdi-link-plus:before {
  content: "\FC70"; }

.mdi-link-variant:before {
  content: "\F339"; }

.mdi-link-variant-off:before {
  content: "\F33A"; }

.mdi-linkedin:before {
  content: "\F33B"; }

.mdi-linkedin-box:before {
  content: "\F33C"; }

.mdi-linux:before {
  content: "\F33D"; }

.mdi-linux-mint:before {
  content: "\F8EC"; }

.mdi-litecoin:before {
  content: "\FA60"; }

.mdi-loading:before {
  content: "\F771"; }

.mdi-lock:before {
  content: "\F33E"; }

.mdi-lock-alert:before {
  content: "\F8ED"; }

.mdi-lock-clock:before {
  content: "\F97E"; }

.mdi-lock-open:before {
  content: "\F33F"; }

.mdi-lock-open-outline:before {
  content: "\F340"; }

.mdi-lock-outline:before {
  content: "\F341"; }

.mdi-lock-pattern:before {
  content: "\F6E9"; }

.mdi-lock-plus:before {
  content: "\F5FB"; }

.mdi-lock-question:before {
  content: "\F8EE"; }

.mdi-lock-reset:before {
  content: "\F772"; }

.mdi-lock-smart:before {
  content: "\F8B1"; }

.mdi-locker:before {
  content: "\F7D6"; }

.mdi-locker-multiple:before {
  content: "\F7D7"; }

.mdi-login:before {
  content: "\F342"; }

.mdi-login-variant:before {
  content: "\F5FC"; }

.mdi-logout:before {
  content: "\F343"; }

.mdi-logout-variant:before {
  content: "\F5FD"; }

.mdi-looks:before {
  content: "\F344"; }

.mdi-loop:before {
  content: "\F6EA"; }

.mdi-loupe:before {
  content: "\F345"; }

.mdi-lumx:before {
  content: "\F346"; }

.mdi-lyft:before {
  content: "\FB1D"; }

.mdi-magnet:before {
  content: "\F347"; }

.mdi-magnet-on:before {
  content: "\F348"; }

.mdi-magnify:before {
  content: "\F349"; }

.mdi-magnify-close:before {
  content: "\F97F"; }

.mdi-magnify-minus:before {
  content: "\F34A"; }

.mdi-magnify-minus-cursor:before {
  content: "\FA61"; }

.mdi-magnify-minus-outline:before {
  content: "\F6EB"; }

.mdi-magnify-plus:before {
  content: "\F34B"; }

.mdi-magnify-plus-cursor:before {
  content: "\FA62"; }

.mdi-magnify-plus-outline:before {
  content: "\F6EC"; }

.mdi-mail-ru:before {
  content: "\F34C"; }

.mdi-mailbox:before {
  content: "\F6ED"; }

.mdi-mailbox-open:before {
  content: "\FD64"; }

.mdi-mailbox-open-outline:before {
  content: "\FD65"; }

.mdi-mailbox-open-up:before {
  content: "\FD66"; }

.mdi-mailbox-open-up-outline:before {
  content: "\FD67"; }

.mdi-mailbox-outline:before {
  content: "\FD68"; }

.mdi-mailbox-up:before {
  content: "\FD69"; }

.mdi-mailbox-up-outline:before {
  content: "\FD6A"; }

.mdi-map:before {
  content: "\F34D"; }

.mdi-map-clock:before {
  content: "\FCFA"; }

.mdi-map-clock-outline:before {
  content: "\FCFB"; }

.mdi-map-legend:before {
  content: "\FA00"; }

.mdi-map-marker:before {
  content: "\F34E"; }

.mdi-map-marker-check:before {
  content: "\FC71"; }

.mdi-map-marker-circle:before {
  content: "\F34F"; }

.mdi-map-marker-distance:before {
  content: "\F8EF"; }

.mdi-map-marker-minus:before {
  content: "\F650"; }

.mdi-map-marker-multiple:before {
  content: "\F350"; }

.mdi-map-marker-off:before {
  content: "\F351"; }

.mdi-map-marker-outline:before {
  content: "\F7D8"; }

.mdi-map-marker-path:before {
  content: "\FCFC"; }

.mdi-map-marker-plus:before {
  content: "\F651"; }

.mdi-map-marker-radius:before {
  content: "\F352"; }

.mdi-map-minus:before {
  content: "\F980"; }

.mdi-map-outline:before {
  content: "\F981"; }

.mdi-map-plus:before {
  content: "\F982"; }

.mdi-map-search:before {
  content: "\F983"; }

.mdi-map-search-outline:before {
  content: "\F984"; }

.mdi-mapbox:before {
  content: "\FB86"; }

.mdi-margin:before {
  content: "\F353"; }

.mdi-markdown:before {
  content: "\F354"; }

.mdi-marker:before {
  content: "\F652"; }

.mdi-marker-cancel:before {
  content: "\FDB5"; }

.mdi-marker-check:before {
  content: "\F355"; }

.mdi-mastodon:before {
  content: "\FAD0"; }

.mdi-mastodon-variant:before {
  content: "\FAD1"; }

.mdi-material-design:before {
  content: "\F985"; }

.mdi-material-ui:before {
  content: "\F357"; }

.mdi-math-compass:before {
  content: "\F358"; }

.mdi-math-cos:before {
  content: "\FC72"; }

.mdi-math-sin:before {
  content: "\FC73"; }

.mdi-math-tan:before {
  content: "\FC74"; }

.mdi-matrix:before {
  content: "\F628"; }

.mdi-maxcdn:before {
  content: "\F359"; }

.mdi-medal:before {
  content: "\F986"; }

.mdi-medical-bag:before {
  content: "\F6EE"; }

.mdi-medium:before {
  content: "\F35A"; }

.mdi-meetup:before {
  content: "\FAD2"; }

.mdi-memory:before {
  content: "\F35B"; }

.mdi-menu:before {
  content: "\F35C"; }

.mdi-menu-down:before {
  content: "\F35D"; }

.mdi-menu-down-outline:before {
  content: "\F6B5"; }

.mdi-menu-left:before {
  content: "\F35E"; }

.mdi-menu-left-outline:before {
  content: "\FA01"; }

.mdi-menu-open:before {
  content: "\FB87"; }

.mdi-menu-right:before {
  content: "\F35F"; }

.mdi-menu-right-outline:before {
  content: "\FA02"; }

.mdi-menu-swap:before {
  content: "\FA63"; }

.mdi-menu-swap-outline:before {
  content: "\FA64"; }

.mdi-menu-up:before {
  content: "\F360"; }

.mdi-menu-up-outline:before {
  content: "\F6B6"; }

.mdi-message:before {
  content: "\F361"; }

.mdi-message-alert:before {
  content: "\F362"; }

.mdi-message-alert-outline:before {
  content: "\FA03"; }

.mdi-message-bulleted:before {
  content: "\F6A1"; }

.mdi-message-bulleted-off:before {
  content: "\F6A2"; }

.mdi-message-draw:before {
  content: "\F363"; }

.mdi-message-image:before {
  content: "\F364"; }

.mdi-message-outline:before {
  content: "\F365"; }

.mdi-message-plus:before {
  content: "\F653"; }

.mdi-message-processing:before {
  content: "\F366"; }

.mdi-message-reply:before {
  content: "\F367"; }

.mdi-message-reply-text:before {
  content: "\F368"; }

.mdi-message-settings:before {
  content: "\F6EF"; }

.mdi-message-settings-variant:before {
  content: "\F6F0"; }

.mdi-message-text:before {
  content: "\F369"; }

.mdi-message-text-outline:before {
  content: "\F36A"; }

.mdi-message-video:before {
  content: "\F36B"; }

.mdi-meteor:before {
  content: "\F629"; }

.mdi-metronome:before {
  content: "\F7D9"; }

.mdi-metronome-tick:before {
  content: "\F7DA"; }

.mdi-micro-sd:before {
  content: "\F7DB"; }

.mdi-microphone:before {
  content: "\F36C"; }

.mdi-microphone-minus:before {
  content: "\F8B2"; }

.mdi-microphone-off:before {
  content: "\F36D"; }

.mdi-microphone-outline:before {
  content: "\F36E"; }

.mdi-microphone-plus:before {
  content: "\F8B3"; }

.mdi-microphone-settings:before {
  content: "\F36F"; }

.mdi-microphone-variant:before {
  content: "\F370"; }

.mdi-microphone-variant-off:before {
  content: "\F371"; }

.mdi-microscope:before {
  content: "\F654"; }

.mdi-microsoft:before {
  content: "\F372"; }

.mdi-microsoft-dynamics:before {
  content: "\F987"; }

.mdi-microwave:before {
  content: "\FC75"; }

.mdi-midi:before {
  content: "\F8F0"; }

.mdi-midi-port:before {
  content: "\F8F1"; }

.mdi-mine:before {
  content: "\FDB6"; }

.mdi-minecraft:before {
  content: "\F373"; }

.mdi-mini-sd:before {
  content: "\FA04"; }

.mdi-minidisc:before {
  content: "\FA05"; }

.mdi-minus:before {
  content: "\F374"; }

.mdi-minus-box:before {
  content: "\F375"; }

.mdi-minus-box-outline:before {
  content: "\F6F1"; }

.mdi-minus-circle:before {
  content: "\F376"; }

.mdi-minus-circle-outline:before {
  content: "\F377"; }

.mdi-minus-network:before {
  content: "\F378"; }

.mdi-minus-network-outline:before {
  content: "\FC76"; }

.mdi-mixcloud:before {
  content: "\F62A"; }

.mdi-mixed-martial-arts:before {
  content: "\FD6B"; }

.mdi-mixed-reality:before {
  content: "\F87E"; }

.mdi-mixer:before {
  content: "\F7DC"; }

.mdi-molecule:before {
  content: "\FB88"; }

.mdi-monitor:before {
  content: "\F379"; }

.mdi-monitor-cellphone:before {
  content: "\F988"; }

.mdi-monitor-cellphone-star:before {
  content: "\F989"; }

.mdi-monitor-dashboard:before {
  content: "\FA06"; }

.mdi-monitor-lock:before {
  content: "\FDB7"; }

.mdi-monitor-multiple:before {
  content: "\F37A"; }

.mdi-monitor-off:before {
  content: "\FD6C"; }

.mdi-monitor-screenshot:before {
  content: "\FE34"; }

.mdi-monitor-star:before {
  content: "\FDB8"; }

.mdi-more:before {
  content: "\F37B"; }

.mdi-mother-nurse:before {
  content: "\FCFD"; }

.mdi-motion-sensor:before {
  content: "\FD6D"; }

.mdi-motorbike:before {
  content: "\F37C"; }

.mdi-mouse:before {
  content: "\F37D"; }

.mdi-mouse-bluetooth:before {
  content: "\F98A"; }

.mdi-mouse-off:before {
  content: "\F37E"; }

.mdi-mouse-variant:before {
  content: "\F37F"; }

.mdi-mouse-variant-off:before {
  content: "\F380"; }

.mdi-move-resize:before {
  content: "\F655"; }

.mdi-move-resize-variant:before {
  content: "\F656"; }

.mdi-movie:before {
  content: "\F381"; }

.mdi-movie-outline:before {
  content: "\FDB9"; }

.mdi-movie-roll:before {
  content: "\F7DD"; }

.mdi-muffin:before {
  content: "\F98B"; }

.mdi-multiplication:before {
  content: "\F382"; }

.mdi-multiplication-box:before {
  content: "\F383"; }

.mdi-mushroom:before {
  content: "\F7DE"; }

.mdi-mushroom-outline:before {
  content: "\F7DF"; }

.mdi-music:before {
  content: "\F759"; }

.mdi-music-box:before {
  content: "\F384"; }

.mdi-music-box-outline:before {
  content: "\F385"; }

.mdi-music-circle:before {
  content: "\F386"; }

.mdi-music-circle-outline:before {
  content: "\FAD3"; }

.mdi-music-note:before {
  content: "\F387"; }

.mdi-music-note-bluetooth:before {
  content: "\F5FE"; }

.mdi-music-note-bluetooth-off:before {
  content: "\F5FF"; }

.mdi-music-note-eighth:before {
  content: "\F388"; }

.mdi-music-note-half:before {
  content: "\F389"; }

.mdi-music-note-off:before {
  content: "\F38A"; }

.mdi-music-note-plus:before {
  content: "\FDBA"; }

.mdi-music-note-quarter:before {
  content: "\F38B"; }

.mdi-music-note-sixteenth:before {
  content: "\F38C"; }

.mdi-music-note-whole:before {
  content: "\F38D"; }

.mdi-music-off:before {
  content: "\F75A"; }

.mdi-nail:before {
  content: "\FDBB"; }

.mdi-nas:before {
  content: "\F8F2"; }

.mdi-nativescript:before {
  content: "\F87F"; }

.mdi-nature:before {
  content: "\F38E"; }

.mdi-nature-people:before {
  content: "\F38F"; }

.mdi-navigation:before {
  content: "\F390"; }

.mdi-near-me:before {
  content: "\F5CD"; }

.mdi-needle:before {
  content: "\F391"; }

.mdi-netflix:before {
  content: "\F745"; }

.mdi-network:before {
  content: "\F6F2"; }

.mdi-network-off:before {
  content: "\FC77"; }

.mdi-network-off-outline:before {
  content: "\FC78"; }

.mdi-network-outline:before {
  content: "\FC79"; }

.mdi-network-strength-1:before {
  content: "\F8F3"; }

.mdi-network-strength-1-alert:before {
  content: "\F8F4"; }

.mdi-network-strength-2:before {
  content: "\F8F5"; }

.mdi-network-strength-2-alert:before {
  content: "\F8F6"; }

.mdi-network-strength-3:before {
  content: "\F8F7"; }

.mdi-network-strength-3-alert:before {
  content: "\F8F8"; }

.mdi-network-strength-4:before {
  content: "\F8F9"; }

.mdi-network-strength-4-alert:before {
  content: "\F8FA"; }

.mdi-network-strength-off:before {
  content: "\F8FB"; }

.mdi-network-strength-off-outline:before {
  content: "\F8FC"; }

.mdi-network-strength-outline:before {
  content: "\F8FD"; }

.mdi-new-box:before {
  content: "\F394"; }

.mdi-newspaper:before {
  content: "\F395"; }

.mdi-nfc:before {
  content: "\F396"; }

.mdi-nfc-off:before {
  content: "\FE35"; }

.mdi-nfc-search-variant:before {
  content: "\FE36"; }

.mdi-nfc-tap:before {
  content: "\F397"; }

.mdi-nfc-variant:before {
  content: "\F398"; }

.mdi-nfc-variant-off:before {
  content: "\FE37"; }

.mdi-ninja:before {
  content: "\F773"; }

.mdi-nintendo-switch:before {
  content: "\F7E0"; }

.mdi-nodejs:before {
  content: "\F399"; }

.mdi-not-equal:before {
  content: "\F98C"; }

.mdi-not-equal-variant:before {
  content: "\F98D"; }

.mdi-note:before {
  content: "\F39A"; }

.mdi-note-multiple:before {
  content: "\F6B7"; }

.mdi-note-multiple-outline:before {
  content: "\F6B8"; }

.mdi-note-outline:before {
  content: "\F39B"; }

.mdi-note-plus:before {
  content: "\F39C"; }

.mdi-note-plus-outline:before {
  content: "\F39D"; }

.mdi-note-text:before {
  content: "\F39E"; }

.mdi-notebook:before {
  content: "\F82D"; }

.mdi-notebook-multiple:before {
  content: "\FE38"; }

.mdi-notification-clear-all:before {
  content: "\F39F"; }

.mdi-npm:before {
  content: "\F6F6"; }

.mdi-npm-variant:before {
  content: "\F98E"; }

.mdi-npm-variant-outline:before {
  content: "\F98F"; }

.mdi-nuke:before {
  content: "\F6A3"; }

.mdi-null:before {
  content: "\F7E1"; }

.mdi-numeric:before {
  content: "\F3A0"; }

.mdi-numeric-0:before {
  content: "\30"; }

.mdi-numeric-0-box:before {
  content: "\F3A1"; }

.mdi-numeric-0-box-multiple-outline:before {
  content: "\F3A2"; }

.mdi-numeric-0-box-outline:before {
  content: "\F3A3"; }

.mdi-numeric-0-circle:before {
  content: "\FC7A"; }

.mdi-numeric-0-circle-outline:before {
  content: "\FC7B"; }

.mdi-numeric-1:before {
  content: "\31"; }

.mdi-numeric-1-box:before {
  content: "\F3A4"; }

.mdi-numeric-1-box-multiple-outline:before {
  content: "\F3A5"; }

.mdi-numeric-1-box-outline:before {
  content: "\F3A6"; }

.mdi-numeric-1-circle:before {
  content: "\FC7C"; }

.mdi-numeric-1-circle-outline:before {
  content: "\FC7D"; }

.mdi-numeric-2:before {
  content: "\32"; }

.mdi-numeric-2-box:before {
  content: "\F3A7"; }

.mdi-numeric-2-box-multiple-outline:before {
  content: "\F3A8"; }

.mdi-numeric-2-box-outline:before {
  content: "\F3A9"; }

.mdi-numeric-2-circle:before {
  content: "\FC7E"; }

.mdi-numeric-2-circle-outline:before {
  content: "\FC7F"; }

.mdi-numeric-3:before {
  content: "\33"; }

.mdi-numeric-3-box:before {
  content: "\F3AA"; }

.mdi-numeric-3-box-multiple-outline:before {
  content: "\F3AB"; }

.mdi-numeric-3-box-outline:before {
  content: "\F3AC"; }

.mdi-numeric-3-circle:before {
  content: "\FC80"; }

.mdi-numeric-3-circle-outline:before {
  content: "\FC81"; }

.mdi-numeric-4:before {
  content: "\34"; }

.mdi-numeric-4-box:before {
  content: "\F3AD"; }

.mdi-numeric-4-box-multiple-outline:before {
  content: "\F3AE"; }

.mdi-numeric-4-box-outline:before {
  content: "\F3AF"; }

.mdi-numeric-4-circle:before {
  content: "\FC82"; }

.mdi-numeric-4-circle-outline:before {
  content: "\FC83"; }

.mdi-numeric-5:before {
  content: "\35"; }

.mdi-numeric-5-box:before {
  content: "\F3B0"; }

.mdi-numeric-5-box-multiple-outline:before {
  content: "\F3B1"; }

.mdi-numeric-5-box-outline:before {
  content: "\F3B2"; }

.mdi-numeric-5-circle:before {
  content: "\FC84"; }

.mdi-numeric-5-circle-outline:before {
  content: "\FC85"; }

.mdi-numeric-6:before {
  content: "\36"; }

.mdi-numeric-6-box:before {
  content: "\F3B3"; }

.mdi-numeric-6-box-multiple-outline:before {
  content: "\F3B4"; }

.mdi-numeric-6-box-outline:before {
  content: "\F3B5"; }

.mdi-numeric-6-circle:before {
  content: "\FC86"; }

.mdi-numeric-6-circle-outline:before {
  content: "\FC87"; }

.mdi-numeric-7:before {
  content: "\37"; }

.mdi-numeric-7-box:before {
  content: "\F3B6"; }

.mdi-numeric-7-box-multiple-outline:before {
  content: "\F3B7"; }

.mdi-numeric-7-box-outline:before {
  content: "\F3B8"; }

.mdi-numeric-7-circle:before {
  content: "\FC88"; }

.mdi-numeric-7-circle-outline:before {
  content: "\FC89"; }

.mdi-numeric-8:before {
  content: "\38"; }

.mdi-numeric-8-box:before {
  content: "\F3B9"; }

.mdi-numeric-8-box-multiple-outline:before {
  content: "\F3BA"; }

.mdi-numeric-8-box-outline:before {
  content: "\F3BB"; }

.mdi-numeric-8-circle:before {
  content: "\FC8A"; }

.mdi-numeric-8-circle-outline:before {
  content: "\FC8B"; }

.mdi-numeric-9:before {
  content: "\39"; }

.mdi-numeric-9-box:before {
  content: "\F3BC"; }

.mdi-numeric-9-box-multiple-outline:before {
  content: "\F3BD"; }

.mdi-numeric-9-box-outline:before {
  content: "\F3BE"; }

.mdi-numeric-9-circle:before {
  content: "\FC8C"; }

.mdi-numeric-9-circle-outline:before {
  content: "\FC8D"; }

.mdi-numeric-9-plus-box:before {
  content: "\F3BF"; }

.mdi-numeric-9-plus-box-multiple-outline:before {
  content: "\F3C0"; }

.mdi-numeric-9-plus-box-outline:before {
  content: "\F3C1"; }

.mdi-numeric-9-plus-circle:before {
  content: "\FC8E"; }

.mdi-numeric-9-plus-circle-outline:before {
  content: "\FC8F"; }

.mdi-nut:before {
  content: "\F6F7"; }

.mdi-nutrition:before {
  content: "\F3C2"; }

.mdi-oar:before {
  content: "\F67B"; }

.mdi-ocarina:before {
  content: "\FDBC"; }

.mdi-octagon:before {
  content: "\F3C3"; }

.mdi-octagon-outline:before {
  content: "\F3C4"; }

.mdi-octagram:before {
  content: "\F6F8"; }

.mdi-octagram-outline:before {
  content: "\F774"; }

.mdi-odnoklassniki:before {
  content: "\F3C5"; }

.mdi-office:before {
  content: "\F3C6"; }

.mdi-office-building:before {
  content: "\F990"; }

.mdi-oil:before {
  content: "\F3C7"; }

.mdi-oil-temperature:before {
  content: "\F3C8"; }

.mdi-omega:before {
  content: "\F3C9"; }

.mdi-one-up:before {
  content: "\FB89"; }

.mdi-onedrive:before {
  content: "\F3CA"; }

.mdi-onenote:before {
  content: "\F746"; }

.mdi-onepassword:before {
  content: "\F880"; }

.mdi-opacity:before {
  content: "\F5CC"; }

.mdi-open-in-app:before {
  content: "\F3CB"; }

.mdi-open-in-new:before {
  content: "\F3CC"; }

.mdi-open-source-initiative:before {
  content: "\FB8A"; }

.mdi-openid:before {
  content: "\F3CD"; }

.mdi-opera:before {
  content: "\F3CE"; }

.mdi-orbit:before {
  content: "\F018"; }

.mdi-origin:before {
  content: "\FB2B"; }

.mdi-ornament:before {
  content: "\F3CF"; }

.mdi-ornament-variant:before {
  content: "\F3D0"; }

.mdi-outlook:before {
  content: "\FCFE"; }

.mdi-owl:before {
  content: "\F3D2"; }

.mdi-pac-man:before {
  content: "\FB8B"; }

.mdi-package:before {
  content: "\F3D3"; }

.mdi-package-down:before {
  content: "\F3D4"; }

.mdi-package-up:before {
  content: "\F3D5"; }

.mdi-package-variant:before {
  content: "\F3D6"; }

.mdi-package-variant-closed:before {
  content: "\F3D7"; }

.mdi-page-first:before {
  content: "\F600"; }

.mdi-page-last:before {
  content: "\F601"; }

.mdi-page-layout-body:before {
  content: "\F6F9"; }

.mdi-page-layout-footer:before {
  content: "\F6FA"; }

.mdi-page-layout-header:before {
  content: "\F6FB"; }

.mdi-page-layout-sidebar-left:before {
  content: "\F6FC"; }

.mdi-page-layout-sidebar-right:before {
  content: "\F6FD"; }

.mdi-page-next:before {
  content: "\FB8C"; }

.mdi-page-next-outline:before {
  content: "\FB8D"; }

.mdi-page-previous:before {
  content: "\FB8E"; }

.mdi-page-previous-outline:before {
  content: "\FB8F"; }

.mdi-palette:before {
  content: "\F3D8"; }

.mdi-palette-advanced:before {
  content: "\F3D9"; }

.mdi-palette-outline:before {
  content: "\FE6C"; }

.mdi-palette-swatch:before {
  content: "\F8B4"; }

.mdi-pan:before {
  content: "\FB90"; }

.mdi-pan-bottom-left:before {
  content: "\FB91"; }

.mdi-pan-bottom-right:before {
  content: "\FB92"; }

.mdi-pan-down:before {
  content: "\FB93"; }

.mdi-pan-horizontal:before {
  content: "\FB94"; }

.mdi-pan-left:before {
  content: "\FB95"; }

.mdi-pan-right:before {
  content: "\FB96"; }

.mdi-pan-top-left:before {
  content: "\FB97"; }

.mdi-pan-top-right:before {
  content: "\FB98"; }

.mdi-pan-up:before {
  content: "\FB99"; }

.mdi-pan-vertical:before {
  content: "\FB9A"; }

.mdi-panda:before {
  content: "\F3DA"; }

.mdi-pandora:before {
  content: "\F3DB"; }

.mdi-panorama:before {
  content: "\F3DC"; }

.mdi-panorama-fisheye:before {
  content: "\F3DD"; }

.mdi-panorama-horizontal:before {
  content: "\F3DE"; }

.mdi-panorama-vertical:before {
  content: "\F3DF"; }

.mdi-panorama-wide-angle:before {
  content: "\F3E0"; }

.mdi-paper-cut-vertical:before {
  content: "\F3E1"; }

.mdi-paperclip:before {
  content: "\F3E2"; }

.mdi-parachute:before {
  content: "\FC90"; }

.mdi-parachute-outline:before {
  content: "\FC91"; }

.mdi-parking:before {
  content: "\F3E3"; }

.mdi-passport:before {
  content: "\F7E2"; }

.mdi-passport-biometric:before {
  content: "\FDBD"; }

.mdi-patreon:before {
  content: "\F881"; }

.mdi-pause:before {
  content: "\F3E4"; }

.mdi-pause-circle:before {
  content: "\F3E5"; }

.mdi-pause-circle-outline:before {
  content: "\F3E6"; }

.mdi-pause-octagon:before {
  content: "\F3E7"; }

.mdi-pause-octagon-outline:before {
  content: "\F3E8"; }

.mdi-paw:before {
  content: "\F3E9"; }

.mdi-paw-off:before {
  content: "\F657"; }

.mdi-paypal:before {
  content: "\F882"; }

.mdi-pdf-box:before {
  content: "\FE39"; }

.mdi-peace:before {
  content: "\F883"; }

.mdi-pen:before {
  content: "\F3EA"; }

.mdi-pen-lock:before {
  content: "\FDBE"; }

.mdi-pen-minus:before {
  content: "\FDBF"; }

.mdi-pen-off:before {
  content: "\FDC0"; }

.mdi-pen-plus:before {
  content: "\FDC1"; }

.mdi-pen-remove:before {
  content: "\FDC2"; }

.mdi-pencil:before {
  content: "\F3EB"; }

.mdi-pencil-box:before {
  content: "\F3EC"; }

.mdi-pencil-box-outline:before {
  content: "\F3ED"; }

.mdi-pencil-circle:before {
  content: "\F6FE"; }

.mdi-pencil-circle-outline:before {
  content: "\F775"; }

.mdi-pencil-lock:before {
  content: "\F3EE"; }

.mdi-pencil-lock-outline:before {
  content: "\FDC3"; }

.mdi-pencil-minus:before {
  content: "\FDC4"; }

.mdi-pencil-minus-outline:before {
  content: "\FDC5"; }

.mdi-pencil-off:before {
  content: "\F3EF"; }

.mdi-pencil-off-outline:before {
  content: "\FDC6"; }

.mdi-pencil-outline:before {
  content: "\FC92"; }

.mdi-pencil-plus:before {
  content: "\FDC7"; }

.mdi-pencil-plus-outline:before {
  content: "\FDC8"; }

.mdi-pencil-remove:before {
  content: "\FDC9"; }

.mdi-pencil-remove-outline:before {
  content: "\FDCA"; }

.mdi-pentagon:before {
  content: "\F6FF"; }

.mdi-pentagon-outline:before {
  content: "\F700"; }

.mdi-percent:before {
  content: "\F3F0"; }

.mdi-periodic-table:before {
  content: "\F8B5"; }

.mdi-periodic-table-co2:before {
  content: "\F7E3"; }

.mdi-periscope:before {
  content: "\F747"; }

.mdi-perspective-less:before {
  content: "\FCFF"; }

.mdi-perspective-more:before {
  content: "\FD00"; }

.mdi-pharmacy:before {
  content: "\F3F1"; }

.mdi-phone:before {
  content: "\F3F2"; }

.mdi-phone-bluetooth:before {
  content: "\F3F3"; }

.mdi-phone-classic:before {
  content: "\F602"; }

.mdi-phone-forward:before {
  content: "\F3F4"; }

.mdi-phone-hangup:before {
  content: "\F3F5"; }

.mdi-phone-in-talk:before {
  content: "\F3F6"; }

.mdi-phone-incoming:before {
  content: "\F3F7"; }

.mdi-phone-lock:before {
  content: "\F3F8"; }

.mdi-phone-log:before {
  content: "\F3F9"; }

.mdi-phone-minus:before {
  content: "\F658"; }

.mdi-phone-missed:before {
  content: "\F3FA"; }

.mdi-phone-off:before {
  content: "\FDCB"; }

.mdi-phone-outgoing:before {
  content: "\F3FB"; }

.mdi-phone-outline:before {
  content: "\FDCC"; }

.mdi-phone-paused:before {
  content: "\F3FC"; }

.mdi-phone-plus:before {
  content: "\F659"; }

.mdi-phone-return:before {
  content: "\F82E"; }

.mdi-phone-rotate-landscape:before {
  content: "\F884"; }

.mdi-phone-rotate-portrait:before {
  content: "\F885"; }

.mdi-phone-settings:before {
  content: "\F3FD"; }

.mdi-phone-voip:before {
  content: "\F3FE"; }

.mdi-pi:before {
  content: "\F3FF"; }

.mdi-pi-box:before {
  content: "\F400"; }

.mdi-pi-hole:before {
  content: "\FDCD"; }

.mdi-piano:before {
  content: "\F67C"; }

.mdi-pickaxe:before {
  content: "\F8B6"; }

.mdi-picture-in-picture-bottom-right:before {
  content: "\FE3A"; }

.mdi-picture-in-picture-bottom-right-outline:before {
  content: "\FE3B"; }

.mdi-picture-in-picture-top-right:before {
  content: "\FE3C"; }

.mdi-picture-in-picture-top-right-outline:before {
  content: "\FE3D"; }

.mdi-pier:before {
  content: "\F886"; }

.mdi-pier-crane:before {
  content: "\F887"; }

.mdi-pig:before {
  content: "\F401"; }

.mdi-pill:before {
  content: "\F402"; }

.mdi-pillar:before {
  content: "\F701"; }

.mdi-pin:before {
  content: "\F403"; }

.mdi-pin-off:before {
  content: "\F404"; }

.mdi-pin-off-outline:before {
  content: "\F92F"; }

.mdi-pin-outline:before {
  content: "\F930"; }

.mdi-pine-tree:before {
  content: "\F405"; }

.mdi-pine-tree-box:before {
  content: "\F406"; }

.mdi-pinterest:before {
  content: "\F407"; }

.mdi-pinterest-box:before {
  content: "\F408"; }

.mdi-pinwheel:before {
  content: "\FAD4"; }

.mdi-pinwheel-outline:before {
  content: "\FAD5"; }

.mdi-pipe:before {
  content: "\F7E4"; }

.mdi-pipe-disconnected:before {
  content: "\F7E5"; }

.mdi-pipe-leak:before {
  content: "\F888"; }

.mdi-pirate:before {
  content: "\FA07"; }

.mdi-pistol:before {
  content: "\F702"; }

.mdi-piston:before {
  content: "\F889"; }

.mdi-pizza:before {
  content: "\F409"; }

.mdi-play:before {
  content: "\F40A"; }

.mdi-play-box-outline:before {
  content: "\F40B"; }

.mdi-play-circle:before {
  content: "\F40C"; }

.mdi-play-circle-outline:before {
  content: "\F40D"; }

.mdi-play-network:before {
  content: "\F88A"; }

.mdi-play-network-outline:before {
  content: "\FC93"; }

.mdi-play-pause:before {
  content: "\F40E"; }

.mdi-play-protected-content:before {
  content: "\F40F"; }

.mdi-play-speed:before {
  content: "\F8FE"; }

.mdi-playlist-check:before {
  content: "\F5C7"; }

.mdi-playlist-edit:before {
  content: "\F8FF"; }

.mdi-playlist-minus:before {
  content: "\F410"; }

.mdi-playlist-music:before {
  content: "\FC94"; }

.mdi-playlist-music-outline:before {
  content: "\FC95"; }

.mdi-playlist-play:before {
  content: "\F411"; }

.mdi-playlist-plus:before {
  content: "\F412"; }

.mdi-playlist-remove:before {
  content: "\F413"; }

.mdi-playlist-star:before {
  content: "\FDCE"; }

.mdi-playstation:before {
  content: "\F414"; }

.mdi-plex:before {
  content: "\F6B9"; }

.mdi-plus:before {
  content: "\F415"; }

.mdi-plus-box:before {
  content: "\F416"; }

.mdi-plus-box-outline:before {
  content: "\F703"; }

.mdi-plus-circle:before {
  content: "\F417"; }

.mdi-plus-circle-multiple-outline:before {
  content: "\F418"; }

.mdi-plus-circle-outline:before {
  content: "\F419"; }

.mdi-plus-minus:before {
  content: "\F991"; }

.mdi-plus-minus-box:before {
  content: "\F992"; }

.mdi-plus-network:before {
  content: "\F41A"; }

.mdi-plus-network-outline:before {
  content: "\FC96"; }

.mdi-plus-one:before {
  content: "\F41B"; }

.mdi-plus-outline:before {
  content: "\F704"; }

.mdi-pocket:before {
  content: "\F41C"; }

.mdi-podcast:before {
  content: "\F993"; }

.mdi-podium:before {
  content: "\FD01"; }

.mdi-podium-bronze:before {
  content: "\FD02"; }

.mdi-podium-gold:before {
  content: "\FD03"; }

.mdi-podium-silver:before {
  content: "\FD04"; }

.mdi-point-of-sale:before {
  content: "\FD6E"; }

.mdi-pokeball:before {
  content: "\F41D"; }

.mdi-pokemon-go:before {
  content: "\FA08"; }

.mdi-poker-chip:before {
  content: "\F82F"; }

.mdi-polaroid:before {
  content: "\F41E"; }

.mdi-poll:before {
  content: "\F41F"; }

.mdi-poll-box:before {
  content: "\F420"; }

.mdi-polymer:before {
  content: "\F421"; }

.mdi-pool:before {
  content: "\F606"; }

.mdi-popcorn:before {
  content: "\F422"; }

.mdi-postage-stamp:before {
  content: "\FC97"; }

.mdi-pot:before {
  content: "\F65A"; }

.mdi-pot-mix:before {
  content: "\F65B"; }

.mdi-pound:before {
  content: "\F423"; }

.mdi-pound-box:before {
  content: "\F424"; }

.mdi-power:before {
  content: "\F425"; }

.mdi-power-cycle:before {
  content: "\F900"; }

.mdi-power-off:before {
  content: "\F901"; }

.mdi-power-on:before {
  content: "\F902"; }

.mdi-power-plug:before {
  content: "\F6A4"; }

.mdi-power-plug-off:before {
  content: "\F6A5"; }

.mdi-power-settings:before {
  content: "\F426"; }

.mdi-power-sleep:before {
  content: "\F903"; }

.mdi-power-socket:before {
  content: "\F427"; }

.mdi-power-socket-au:before {
  content: "\F904"; }

.mdi-power-socket-eu:before {
  content: "\F7E6"; }

.mdi-power-socket-uk:before {
  content: "\F7E7"; }

.mdi-power-socket-us:before {
  content: "\F7E8"; }

.mdi-power-standby:before {
  content: "\F905"; }

.mdi-powershell:before {
  content: "\FA09"; }

.mdi-prescription:before {
  content: "\F705"; }

.mdi-presentation:before {
  content: "\F428"; }

.mdi-presentation-play:before {
  content: "\F429"; }

.mdi-printer:before {
  content: "\F42A"; }

.mdi-printer-3d:before {
  content: "\F42B"; }

.mdi-printer-3d-nozzle:before {
  content: "\FE3E"; }

.mdi-printer-3d-nozzle-outline:before {
  content: "\FE3F"; }

.mdi-printer-alert:before {
  content: "\F42C"; }

.mdi-printer-off:before {
  content: "\FE40"; }

.mdi-printer-settings:before {
  content: "\F706"; }

.mdi-printer-wireless:before {
  content: "\FA0A"; }

.mdi-priority-high:before {
  content: "\F603"; }

.mdi-priority-low:before {
  content: "\F604"; }

.mdi-professional-hexagon:before {
  content: "\F42D"; }

.mdi-progress-alert:before {
  content: "\FC98"; }

.mdi-progress-check:before {
  content: "\F994"; }

.mdi-progress-clock:before {
  content: "\F995"; }

.mdi-progress-download:before {
  content: "\F996"; }

.mdi-progress-upload:before {
  content: "\F997"; }

.mdi-progress-wrench:before {
  content: "\FC99"; }

.mdi-projector:before {
  content: "\F42E"; }

.mdi-projector-screen:before {
  content: "\F42F"; }

.mdi-publish:before {
  content: "\F6A6"; }

.mdi-pulse:before {
  content: "\F430"; }

.mdi-pumpkin:before {
  content: "\FB9B"; }

.mdi-puzzle:before {
  content: "\F431"; }

.mdi-puzzle-outline:before {
  content: "\FA65"; }

.mdi-qi:before {
  content: "\F998"; }

.mdi-qqchat:before {
  content: "\F605"; }

.mdi-qrcode:before {
  content: "\F432"; }

.mdi-qrcode-edit:before {
  content: "\F8B7"; }

.mdi-qrcode-scan:before {
  content: "\F433"; }

.mdi-quadcopter:before {
  content: "\F434"; }

.mdi-quality-high:before {
  content: "\F435"; }

.mdi-quality-low:before {
  content: "\FA0B"; }

.mdi-quality-medium:before {
  content: "\FA0C"; }

.mdi-quicktime:before {
  content: "\F436"; }

.mdi-quora:before {
  content: "\FD05"; }

.mdi-rabbit:before {
  content: "\F906"; }

.mdi-racing-helmet:before {
  content: "\FD6F"; }

.mdi-racquetball:before {
  content: "\FD70"; }

.mdi-radar:before {
  content: "\F437"; }

.mdi-radiator:before {
  content: "\F438"; }

.mdi-radiator-disabled:before {
  content: "\FAD6"; }

.mdi-radiator-off:before {
  content: "\FAD7"; }

.mdi-radio:before {
  content: "\F439"; }

.mdi-radio-am:before {
  content: "\FC9A"; }

.mdi-radio-fm:before {
  content: "\FC9B"; }

.mdi-radio-handheld:before {
  content: "\F43A"; }

.mdi-radio-tower:before {
  content: "\F43B"; }

.mdi-radioactive:before {
  content: "\F43C"; }

.mdi-radiobox-blank:before {
  content: "\F43D"; }

.mdi-radiobox-marked:before {
  content: "\F43E"; }

.mdi-radius:before {
  content: "\FC9C"; }

.mdi-radius-outline:before {
  content: "\FC9D"; }

.mdi-raspberry-pi:before {
  content: "\F43F"; }

.mdi-ray-end:before {
  content: "\F440"; }

.mdi-ray-end-arrow:before {
  content: "\F441"; }

.mdi-ray-start:before {
  content: "\F442"; }

.mdi-ray-start-arrow:before {
  content: "\F443"; }

.mdi-ray-start-end:before {
  content: "\F444"; }

.mdi-ray-vertex:before {
  content: "\F445"; }

.mdi-react:before {
  content: "\F707"; }

.mdi-read:before {
  content: "\F447"; }

.mdi-receipt:before {
  content: "\F449"; }

.mdi-record:before {
  content: "\F44A"; }

.mdi-record-player:before {
  content: "\F999"; }

.mdi-record-rec:before {
  content: "\F44B"; }

.mdi-rectangle:before {
  content: "\FE41"; }

.mdi-rectangle-outline:before {
  content: "\FE42"; }

.mdi-recycle:before {
  content: "\F44C"; }

.mdi-reddit:before {
  content: "\F44D"; }

.mdi-redo:before {
  content: "\F44E"; }

.mdi-redo-variant:before {
  content: "\F44F"; }

.mdi-reflect-horizontal:before {
  content: "\FA0D"; }

.mdi-reflect-vertical:before {
  content: "\FA0E"; }

.mdi-refresh:before {
  content: "\F450"; }

.mdi-regex:before {
  content: "\F451"; }

.mdi-registered-trademark:before {
  content: "\FA66"; }

.mdi-relative-scale:before {
  content: "\F452"; }

.mdi-reload:before {
  content: "\F453"; }

.mdi-reminder:before {
  content: "\F88B"; }

.mdi-remote:before {
  content: "\F454"; }

.mdi-remote-desktop:before {
  content: "\F8B8"; }

.mdi-rename-box:before {
  content: "\F455"; }

.mdi-reorder-horizontal:before {
  content: "\F687"; }

.mdi-reorder-vertical:before {
  content: "\F688"; }

.mdi-repeat:before {
  content: "\F456"; }

.mdi-repeat-off:before {
  content: "\F457"; }

.mdi-repeat-once:before {
  content: "\F458"; }

.mdi-replay:before {
  content: "\F459"; }

.mdi-reply:before {
  content: "\F45A"; }

.mdi-reply-all:before {
  content: "\F45B"; }

.mdi-reproduction:before {
  content: "\F45C"; }

.mdi-resistor:before {
  content: "\FB1F"; }

.mdi-resistor-nodes:before {
  content: "\FB20"; }

.mdi-resize:before {
  content: "\FA67"; }

.mdi-resize-bottom-right:before {
  content: "\F45D"; }

.mdi-responsive:before {
  content: "\F45E"; }

.mdi-restart:before {
  content: "\F708"; }

.mdi-restart-off:before {
  content: "\FD71"; }

.mdi-restore:before {
  content: "\F99A"; }

.mdi-restore-clock:before {
  content: "\F6A7"; }

.mdi-rewind:before {
  content: "\F45F"; }

.mdi-rewind-10:before {
  content: "\FD06"; }

.mdi-rewind-30:before {
  content: "\FD72"; }

.mdi-rewind-outline:before {
  content: "\F709"; }

.mdi-rhombus:before {
  content: "\F70A"; }

.mdi-rhombus-medium:before {
  content: "\FA0F"; }

.mdi-rhombus-outline:before {
  content: "\F70B"; }

.mdi-rhombus-split:before {
  content: "\FA10"; }

.mdi-ribbon:before {
  content: "\F460"; }

.mdi-rice:before {
  content: "\F7E9"; }

.mdi-ring:before {
  content: "\F7EA"; }

.mdi-rivet:before {
  content: "\FE43"; }

.mdi-road:before {
  content: "\F461"; }

.mdi-road-variant:before {
  content: "\F462"; }

.mdi-robot:before {
  content: "\F6A8"; }

.mdi-robot-industrial:before {
  content: "\FB21"; }

.mdi-robot-vacuum:before {
  content: "\F70C"; }

.mdi-robot-vacuum-variant:before {
  content: "\F907"; }

.mdi-rocket:before {
  content: "\F463"; }

.mdi-roller-skate:before {
  content: "\FD07"; }

.mdi-rollerblade:before {
  content: "\FD08"; }

.mdi-rollupjs:before {
  content: "\FB9C"; }

.mdi-room-service:before {
  content: "\F88C"; }

.mdi-room-service-outline:before {
  content: "\FD73"; }

.mdi-rotate-3d:before {
  content: "\F464"; }

.mdi-rotate-left:before {
  content: "\F465"; }

.mdi-rotate-left-variant:before {
  content: "\F466"; }

.mdi-rotate-orbit:before {
  content: "\FD74"; }

.mdi-rotate-right:before {
  content: "\F467"; }

.mdi-rotate-right-variant:before {
  content: "\F468"; }

.mdi-rounded-corner:before {
  content: "\F607"; }

.mdi-router-wireless:before {
  content: "\F469"; }

.mdi-router-wireless-settings:before {
  content: "\FA68"; }

.mdi-routes:before {
  content: "\F46A"; }

.mdi-rowing:before {
  content: "\F608"; }

.mdi-rss:before {
  content: "\F46B"; }

.mdi-rss-box:before {
  content: "\F46C"; }

.mdi-ruby:before {
  content: "\FD09"; }

.mdi-rugby:before {
  content: "\FD75"; }

.mdi-ruler:before {
  content: "\F46D"; }

.mdi-ruler-square:before {
  content: "\FC9E"; }

.mdi-run:before {
  content: "\F70D"; }

.mdi-run-fast:before {
  content: "\F46E"; }

.mdi-sack:before {
  content: "\FD0A"; }

.mdi-sack-percent:before {
  content: "\FD0B"; }

.mdi-safe:before {
  content: "\FA69"; }

.mdi-safety-goggles:before {
  content: "\FD0C"; }

.mdi-sale:before {
  content: "\F46F"; }

.mdi-salesforce:before {
  content: "\F88D"; }

.mdi-sass:before {
  content: "\F7EB"; }

.mdi-satellite:before {
  content: "\F470"; }

.mdi-satellite-uplink:before {
  content: "\F908"; }

.mdi-satellite-variant:before {
  content: "\F471"; }

.mdi-sausage:before {
  content: "\F8B9"; }

.mdi-saw-blade:before {
  content: "\FE44"; }

.mdi-saxophone:before {
  content: "\F609"; }

.mdi-scale:before {
  content: "\F472"; }

.mdi-scale-balance:before {
  content: "\F5D1"; }

.mdi-scale-bathroom:before {
  content: "\F473"; }

.mdi-scanner:before {
  content: "\F6AA"; }

.mdi-scanner-off:before {
  content: "\F909"; }

.mdi-school:before {
  content: "\F474"; }

.mdi-scissors-cutting:before {
  content: "\FA6A"; }

.mdi-screen-rotation:before {
  content: "\F475"; }

.mdi-screen-rotation-lock:before {
  content: "\F476"; }

.mdi-screw-flat-top:before {
  content: "\FDCF"; }

.mdi-screw-lag:before {
  content: "\FE54"; }

.mdi-screw-machine-flat-top:before {
  content: "\FE55"; }

.mdi-screw-machine-round-top:before {
  content: "\FE56"; }

.mdi-screw-round-top:before {
  content: "\FE57"; }

.mdi-screwdriver:before {
  content: "\F477"; }

.mdi-script:before {
  content: "\FB9D"; }

.mdi-script-outline:before {
  content: "\F478"; }

.mdi-script-text:before {
  content: "\FB9E"; }

.mdi-script-text-outline:before {
  content: "\FB9F"; }

.mdi-sd:before {
  content: "\F479"; }

.mdi-seal:before {
  content: "\F47A"; }

.mdi-search-web:before {
  content: "\F70E"; }

.mdi-seat:before {
  content: "\FC9F"; }

.mdi-seat-flat:before {
  content: "\F47B"; }

.mdi-seat-flat-angled:before {
  content: "\F47C"; }

.mdi-seat-individual-suite:before {
  content: "\F47D"; }

.mdi-seat-legroom-extra:before {
  content: "\F47E"; }

.mdi-seat-legroom-normal:before {
  content: "\F47F"; }

.mdi-seat-legroom-reduced:before {
  content: "\F480"; }

.mdi-seat-outline:before {
  content: "\FCA0"; }

.mdi-seat-recline-extra:before {
  content: "\F481"; }

.mdi-seat-recline-normal:before {
  content: "\F482"; }

.mdi-seatbelt:before {
  content: "\FCA1"; }

.mdi-security:before {
  content: "\F483"; }

.mdi-security-network:before {
  content: "\F484"; }

.mdi-seed:before {
  content: "\FE45"; }

.mdi-seed-outline:before {
  content: "\FE46"; }

.mdi-select:before {
  content: "\F485"; }

.mdi-select-all:before {
  content: "\F486"; }

.mdi-select-color:before {
  content: "\FD0D"; }

.mdi-select-compare:before {
  content: "\FAD8"; }

.mdi-select-drag:before {
  content: "\FA6B"; }

.mdi-select-inverse:before {
  content: "\F487"; }

.mdi-select-off:before {
  content: "\F488"; }

.mdi-selection:before {
  content: "\F489"; }

.mdi-selection-drag:before {
  content: "\FA6C"; }

.mdi-selection-ellipse:before {
  content: "\FD0E"; }

.mdi-selection-off:before {
  content: "\F776"; }

.mdi-send:before {
  content: "\F48A"; }

.mdi-send-circle:before {
  content: "\FE58"; }

.mdi-send-circle-outline:before {
  content: "\FE59"; }

.mdi-send-lock:before {
  content: "\F7EC"; }

.mdi-serial-port:before {
  content: "\F65C"; }

.mdi-server:before {
  content: "\F48B"; }

.mdi-server-minus:before {
  content: "\F48C"; }

.mdi-server-network:before {
  content: "\F48D"; }

.mdi-server-network-off:before {
  content: "\F48E"; }

.mdi-server-off:before {
  content: "\F48F"; }

.mdi-server-plus:before {
  content: "\F490"; }

.mdi-server-remove:before {
  content: "\F491"; }

.mdi-server-security:before {
  content: "\F492"; }

.mdi-set-all:before {
  content: "\F777"; }

.mdi-set-center:before {
  content: "\F778"; }

.mdi-set-center-right:before {
  content: "\F779"; }

.mdi-set-left:before {
  content: "\F77A"; }

.mdi-set-left-center:before {
  content: "\F77B"; }

.mdi-set-left-right:before {
  content: "\F77C"; }

.mdi-set-none:before {
  content: "\F77D"; }

.mdi-set-right:before {
  content: "\F77E"; }

.mdi-set-top-box:before {
  content: "\F99E"; }

.mdi-settings:before {
  content: "\F493"; }

.mdi-settings-box:before {
  content: "\F494"; }

.mdi-settings-helper:before {
  content: "\FA6D"; }

.mdi-settings-outline:before {
  content: "\F8BA"; }

.mdi-shape:before {
  content: "\F830"; }

.mdi-shape-circle-plus:before {
  content: "\F65D"; }

.mdi-shape-outline:before {
  content: "\F831"; }

.mdi-shape-plus:before {
  content: "\F495"; }

.mdi-shape-polygon-plus:before {
  content: "\F65E"; }

.mdi-shape-rectangle-plus:before {
  content: "\F65F"; }

.mdi-shape-square-plus:before {
  content: "\F660"; }

.mdi-share:before {
  content: "\F496"; }

.mdi-share-outline:before {
  content: "\F931"; }

.mdi-share-variant:before {
  content: "\F497"; }

.mdi-sheep:before {
  content: "\FCA2"; }

.mdi-shield:before {
  content: "\F498"; }

.mdi-shield-account:before {
  content: "\F88E"; }

.mdi-shield-account-outline:before {
  content: "\FA11"; }

.mdi-shield-airplane:before {
  content: "\F6BA"; }

.mdi-shield-airplane-outline:before {
  content: "\FCA3"; }

.mdi-shield-check:before {
  content: "\F565"; }

.mdi-shield-check-outline:before {
  content: "\FCA4"; }

.mdi-shield-cross:before {
  content: "\FCA5"; }

.mdi-shield-cross-outline:before {
  content: "\FCA6"; }

.mdi-shield-half-full:before {
  content: "\F77F"; }

.mdi-shield-home:before {
  content: "\F689"; }

.mdi-shield-home-outline:before {
  content: "\FCA7"; }

.mdi-shield-key:before {
  content: "\FBA0"; }

.mdi-shield-key-outline:before {
  content: "\FBA1"; }

.mdi-shield-link-variant:before {
  content: "\FD0F"; }

.mdi-shield-link-variant-outline:before {
  content: "\FD10"; }

.mdi-shield-lock:before {
  content: "\F99C"; }

.mdi-shield-lock-outline:before {
  content: "\FCA8"; }

.mdi-shield-off:before {
  content: "\F99D"; }

.mdi-shield-off-outline:before {
  content: "\F99B"; }

.mdi-shield-outline:before {
  content: "\F499"; }

.mdi-shield-plus:before {
  content: "\FAD9"; }

.mdi-shield-plus-outline:before {
  content: "\FADA"; }

.mdi-shield-remove:before {
  content: "\FADB"; }

.mdi-shield-remove-outline:before {
  content: "\FADC"; }

.mdi-shield-search:before {
  content: "\FD76"; }

.mdi-ship-wheel:before {
  content: "\F832"; }

.mdi-shoe-formal:before {
  content: "\FB22"; }

.mdi-shoe-heel:before {
  content: "\FB23"; }

.mdi-shoe-print:before {
  content: "\FE5A"; }

.mdi-shopify:before {
  content: "\FADD"; }

.mdi-shopping:before {
  content: "\F49A"; }

.mdi-shopping-music:before {
  content: "\F49B"; }

.mdi-shovel:before {
  content: "\F70F"; }

.mdi-shovel-off:before {
  content: "\F710"; }

.mdi-shower:before {
  content: "\F99F"; }

.mdi-shower-head:before {
  content: "\F9A0"; }

.mdi-shredder:before {
  content: "\F49C"; }

.mdi-shuffle:before {
  content: "\F49D"; }

.mdi-shuffle-disabled:before {
  content: "\F49E"; }

.mdi-shuffle-variant:before {
  content: "\F49F"; }

.mdi-sigma:before {
  content: "\F4A0"; }

.mdi-sigma-lower:before {
  content: "\F62B"; }

.mdi-sign-caution:before {
  content: "\F4A1"; }

.mdi-sign-direction:before {
  content: "\F780"; }

.mdi-sign-text:before {
  content: "\F781"; }

.mdi-signal:before {
  content: "\F4A2"; }

.mdi-signal-2g:before {
  content: "\F711"; }

.mdi-signal-3g:before {
  content: "\F712"; }

.mdi-signal-4g:before {
  content: "\F713"; }

.mdi-signal-5g:before {
  content: "\FA6E"; }

.mdi-signal-cellular-1:before {
  content: "\F8BB"; }

.mdi-signal-cellular-2:before {
  content: "\F8BC"; }

.mdi-signal-cellular-3:before {
  content: "\F8BD"; }

.mdi-signal-cellular-outline:before {
  content: "\F8BE"; }

.mdi-signal-distance-variant:before {
  content: "\FE47"; }

.mdi-signal-hspa:before {
  content: "\F714"; }

.mdi-signal-hspa-plus:before {
  content: "\F715"; }

.mdi-signal-off:before {
  content: "\F782"; }

.mdi-signal-variant:before {
  content: "\F60A"; }

.mdi-signature:before {
  content: "\FE5B"; }

.mdi-signature-freehand:before {
  content: "\FE5C"; }

.mdi-signature-image:before {
  content: "\FE5D"; }

.mdi-signature-text:before {
  content: "\FE5E"; }

.mdi-silo:before {
  content: "\FB24"; }

.mdi-silverware:before {
  content: "\F4A3"; }

.mdi-silverware-fork:before {
  content: "\F4A4"; }

.mdi-silverware-fork-knife:before {
  content: "\FA6F"; }

.mdi-silverware-spoon:before {
  content: "\F4A5"; }

.mdi-silverware-variant:before {
  content: "\F4A6"; }

.mdi-sim:before {
  content: "\F4A7"; }

.mdi-sim-alert:before {
  content: "\F4A8"; }

.mdi-sim-off:before {
  content: "\F4A9"; }

.mdi-sina-weibo:before {
  content: "\FADE"; }

.mdi-sitemap:before {
  content: "\F4AA"; }

.mdi-skate:before {
  content: "\FD11"; }

.mdi-skew-less:before {
  content: "\FD12"; }

.mdi-skew-more:before {
  content: "\FD13"; }

.mdi-skip-backward:before {
  content: "\F4AB"; }

.mdi-skip-forward:before {
  content: "\F4AC"; }

.mdi-skip-next:before {
  content: "\F4AD"; }

.mdi-skip-next-circle:before {
  content: "\F661"; }

.mdi-skip-next-circle-outline:before {
  content: "\F662"; }

.mdi-skip-previous:before {
  content: "\F4AE"; }

.mdi-skip-previous-circle:before {
  content: "\F663"; }

.mdi-skip-previous-circle-outline:before {
  content: "\F664"; }

.mdi-skull:before {
  content: "\F68B"; }

.mdi-skull-crossbones:before {
  content: "\FBA2"; }

.mdi-skull-crossbones-outline:before {
  content: "\FBA3"; }

.mdi-skull-outline:before {
  content: "\FBA4"; }

.mdi-skype:before {
  content: "\F4AF"; }

.mdi-skype-business:before {
  content: "\F4B0"; }

.mdi-slack:before {
  content: "\F4B1"; }

.mdi-slackware:before {
  content: "\F90A"; }

.mdi-sleep:before {
  content: "\F4B2"; }

.mdi-sleep-off:before {
  content: "\F4B3"; }

.mdi-slope-downhill:before {
  content: "\FE5F"; }

.mdi-slope-uphill:before {
  content: "\FE60"; }

.mdi-smog:before {
  content: "\FA70"; }

.mdi-smoke-detector:before {
  content: "\F392"; }

.mdi-smoking:before {
  content: "\F4B4"; }

.mdi-smoking-off:before {
  content: "\F4B5"; }

.mdi-snapchat:before {
  content: "\F4B6"; }

.mdi-snowflake:before {
  content: "\F716"; }

.mdi-snowman:before {
  content: "\F4B7"; }

.mdi-soccer:before {
  content: "\F4B8"; }

.mdi-soccer-field:before {
  content: "\F833"; }

.mdi-sofa:before {
  content: "\F4B9"; }

.mdi-solar-panel:before {
  content: "\FD77"; }

.mdi-solar-panel-large:before {
  content: "\FD78"; }

.mdi-solar-power:before {
  content: "\FA71"; }

.mdi-solid:before {
  content: "\F68C"; }

.mdi-sort:before {
  content: "\F4BA"; }

.mdi-sort-alphabetical:before {
  content: "\F4BB"; }

.mdi-sort-ascending:before {
  content: "\F4BC"; }

.mdi-sort-descending:before {
  content: "\F4BD"; }

.mdi-sort-numeric:before {
  content: "\F4BE"; }

.mdi-sort-variant:before {
  content: "\F4BF"; }

.mdi-sort-variant-lock:before {
  content: "\FCA9"; }

.mdi-sort-variant-lock-open:before {
  content: "\FCAA"; }

.mdi-soundcloud:before {
  content: "\F4C0"; }

.mdi-source-branch:before {
  content: "\F62C"; }

.mdi-source-commit:before {
  content: "\F717"; }

.mdi-source-commit-end:before {
  content: "\F718"; }

.mdi-source-commit-end-local:before {
  content: "\F719"; }

.mdi-source-commit-local:before {
  content: "\F71A"; }

.mdi-source-commit-next-local:before {
  content: "\F71B"; }

.mdi-source-commit-start:before {
  content: "\F71C"; }

.mdi-source-commit-start-next-local:before {
  content: "\F71D"; }

.mdi-source-fork:before {
  content: "\F4C1"; }

.mdi-source-merge:before {
  content: "\F62D"; }

.mdi-source-pull:before {
  content: "\F4C2"; }

.mdi-source-repository:before {
  content: "\FCAB"; }

.mdi-source-repository-multiple:before {
  content: "\FCAC"; }

.mdi-soy-sauce:before {
  content: "\F7ED"; }

.mdi-spa:before {
  content: "\FCAD"; }

.mdi-spa-outline:before {
  content: "\FCAE"; }

.mdi-space-invaders:before {
  content: "\FBA5"; }

.mdi-spade:before {
  content: "\FE48"; }

.mdi-speaker:before {
  content: "\F4C3"; }

.mdi-speaker-bluetooth:before {
  content: "\F9A1"; }

.mdi-speaker-multiple:before {
  content: "\FD14"; }

.mdi-speaker-off:before {
  content: "\F4C4"; }

.mdi-speaker-wireless:before {
  content: "\F71E"; }

.mdi-speedometer:before {
  content: "\F4C5"; }

.mdi-spellcheck:before {
  content: "\F4C6"; }

.mdi-spider-web:before {
  content: "\FBA6"; }

.mdi-spotify:before {
  content: "\F4C7"; }

.mdi-spotlight:before {
  content: "\F4C8"; }

.mdi-spotlight-beam:before {
  content: "\F4C9"; }

.mdi-spray:before {
  content: "\F665"; }

.mdi-spray-bottle:before {
  content: "\FADF"; }

.mdi-sprout:before {
  content: "\FE49"; }

.mdi-sprout-outline:before {
  content: "\FE4A"; }

.mdi-square:before {
  content: "\F763"; }

.mdi-square-edit-outline:before {
  content: "\F90B"; }

.mdi-square-inc:before {
  content: "\F4CA"; }

.mdi-square-inc-cash:before {
  content: "\F4CB"; }

.mdi-square-medium:before {
  content: "\FA12"; }

.mdi-square-medium-outline:before {
  content: "\FA13"; }

.mdi-square-outline:before {
  content: "\F762"; }

.mdi-square-root:before {
  content: "\F783"; }

.mdi-square-root-box:before {
  content: "\F9A2"; }

.mdi-square-small:before {
  content: "\FA14"; }

.mdi-squeegee:before {
  content: "\FAE0"; }

.mdi-ssh:before {
  content: "\F8BF"; }

.mdi-stack-exchange:before {
  content: "\F60B"; }

.mdi-stack-overflow:before {
  content: "\F4CC"; }

.mdi-stadium:before {
  content: "\F71F"; }

.mdi-stairs:before {
  content: "\F4CD"; }

.mdi-stamper:before {
  content: "\FD15"; }

.mdi-standard-definition:before {
  content: "\F7EE"; }

.mdi-star:before {
  content: "\F4CE"; }

.mdi-star-box:before {
  content: "\FA72"; }

.mdi-star-box-outline:before {
  content: "\FA73"; }

.mdi-star-circle:before {
  content: "\F4CF"; }

.mdi-star-circle-outline:before {
  content: "\F9A3"; }

.mdi-star-face:before {
  content: "\F9A4"; }

.mdi-star-four-points:before {
  content: "\FAE1"; }

.mdi-star-four-points-outline:before {
  content: "\FAE2"; }

.mdi-star-half:before {
  content: "\F4D0"; }

.mdi-star-off:before {
  content: "\F4D1"; }

.mdi-star-outline:before {
  content: "\F4D2"; }

.mdi-star-three-points:before {
  content: "\FAE3"; }

.mdi-star-three-points-outline:before {
  content: "\FAE4"; }

.mdi-steam:before {
  content: "\F4D3"; }

.mdi-steam-box:before {
  content: "\F90C"; }

.mdi-steering:before {
  content: "\F4D4"; }

.mdi-steering-off:before {
  content: "\F90D"; }

.mdi-step-backward:before {
  content: "\F4D5"; }

.mdi-step-backward-2:before {
  content: "\F4D6"; }

.mdi-step-forward:before {
  content: "\F4D7"; }

.mdi-step-forward-2:before {
  content: "\F4D8"; }

.mdi-stethoscope:before {
  content: "\F4D9"; }

.mdi-sticker:before {
  content: "\F5D0"; }

.mdi-sticker-emoji:before {
  content: "\F784"; }

.mdi-stocking:before {
  content: "\F4DA"; }

.mdi-stop:before {
  content: "\F4DB"; }

.mdi-stop-circle:before {
  content: "\F666"; }

.mdi-stop-circle-outline:before {
  content: "\F667"; }

.mdi-store:before {
  content: "\F4DC"; }

.mdi-store-24-hour:before {
  content: "\F4DD"; }

.mdi-stove:before {
  content: "\F4DE"; }

.mdi-strava:before {
  content: "\FB25"; }

.mdi-subdirectory-arrow-left:before {
  content: "\F60C"; }

.mdi-subdirectory-arrow-right:before {
  content: "\F60D"; }

.mdi-subtitles:before {
  content: "\FA15"; }

.mdi-subtitles-outline:before {
  content: "\FA16"; }

.mdi-subway:before {
  content: "\F6AB"; }

.mdi-subway-alert-variant:before {
  content: "\FD79"; }

.mdi-subway-variant:before {
  content: "\F4DF"; }

.mdi-summit:before {
  content: "\F785"; }

.mdi-sunglasses:before {
  content: "\F4E0"; }

.mdi-surround-sound:before {
  content: "\F5C5"; }

.mdi-surround-sound-2-0:before {
  content: "\F7EF"; }

.mdi-surround-sound-3-1:before {
  content: "\F7F0"; }

.mdi-surround-sound-5-1:before {
  content: "\F7F1"; }

.mdi-surround-sound-7-1:before {
  content: "\F7F2"; }

.mdi-svg:before {
  content: "\F720"; }

.mdi-swap-horizontal:before {
  content: "\F4E1"; }

.mdi-swap-horizontal-bold:before {
  content: "\FBA9"; }

.mdi-swap-horizontal-variant:before {
  content: "\F8C0"; }

.mdi-swap-vertical:before {
  content: "\F4E2"; }

.mdi-swap-vertical-bold:before {
  content: "\FBAA"; }

.mdi-swap-vertical-variant:before {
  content: "\F8C1"; }

.mdi-swim:before {
  content: "\F4E3"; }

.mdi-switch:before {
  content: "\F4E4"; }

.mdi-sword:before {
  content: "\F4E5"; }

.mdi-sword-cross:before {
  content: "\F786"; }

.mdi-symfony:before {
  content: "\FAE5"; }

.mdi-sync:before {
  content: "\F4E6"; }

.mdi-sync-alert:before {
  content: "\F4E7"; }

.mdi-sync-off:before {
  content: "\F4E8"; }

.mdi-tab:before {
  content: "\F4E9"; }

.mdi-tab-minus:before {
  content: "\FB26"; }

.mdi-tab-plus:before {
  content: "\F75B"; }

.mdi-tab-remove:before {
  content: "\FB27"; }

.mdi-tab-unselected:before {
  content: "\F4EA"; }

.mdi-table:before {
  content: "\F4EB"; }

.mdi-table-border:before {
  content: "\FA17"; }

.mdi-table-column:before {
  content: "\F834"; }

.mdi-table-column-plus-after:before {
  content: "\F4EC"; }

.mdi-table-column-plus-before:before {
  content: "\F4ED"; }

.mdi-table-column-remove:before {
  content: "\F4EE"; }

.mdi-table-column-width:before {
  content: "\F4EF"; }

.mdi-table-edit:before {
  content: "\F4F0"; }

.mdi-table-large:before {
  content: "\F4F1"; }

.mdi-table-merge-cells:before {
  content: "\F9A5"; }

.mdi-table-of-contents:before {
  content: "\F835"; }

.mdi-table-plus:before {
  content: "\FA74"; }

.mdi-table-remove:before {
  content: "\FA75"; }

.mdi-table-row:before {
  content: "\F836"; }

.mdi-table-row-height:before {
  content: "\F4F2"; }

.mdi-table-row-plus-after:before {
  content: "\F4F3"; }

.mdi-table-row-plus-before:before {
  content: "\F4F4"; }

.mdi-table-row-remove:before {
  content: "\F4F5"; }

.mdi-table-search:before {
  content: "\F90E"; }

.mdi-table-settings:before {
  content: "\F837"; }

.mdi-table-tennis:before {
  content: "\FE4B"; }

.mdi-tablet:before {
  content: "\F4F6"; }

.mdi-tablet-android:before {
  content: "\F4F7"; }

.mdi-tablet-cellphone:before {
  content: "\F9A6"; }

.mdi-tablet-ipad:before {
  content: "\F4F8"; }

.mdi-taco:before {
  content: "\F761"; }

.mdi-tag:before {
  content: "\F4F9"; }

.mdi-tag-faces:before {
  content: "\F4FA"; }

.mdi-tag-heart:before {
  content: "\F68A"; }

.mdi-tag-heart-outline:before {
  content: "\FBAB"; }

.mdi-tag-minus:before {
  content: "\F90F"; }

.mdi-tag-multiple:before {
  content: "\F4FB"; }

.mdi-tag-outline:before {
  content: "\F4FC"; }

.mdi-tag-plus:before {
  content: "\F721"; }

.mdi-tag-remove:before {
  content: "\F722"; }

.mdi-tag-text-outline:before {
  content: "\F4FD"; }

.mdi-tank:before {
  content: "\FD16"; }

.mdi-tape-measure:before {
  content: "\FB28"; }

.mdi-target:before {
  content: "\F4FE"; }

.mdi-target-account:before {
  content: "\FBAC"; }

.mdi-target-variant:before {
  content: "\FA76"; }

.mdi-taxi:before {
  content: "\F4FF"; }

.mdi-tea:before {
  content: "\FD7A"; }

.mdi-tea-outline:before {
  content: "\FD7B"; }

.mdi-teach:before {
  content: "\F88F"; }

.mdi-teamviewer:before {
  content: "\F500"; }

.mdi-telegram:before {
  content: "\F501"; }

.mdi-telescope:before {
  content: "\FB29"; }

.mdi-television:before {
  content: "\F502"; }

.mdi-television-box:before {
  content: "\F838"; }

.mdi-television-classic:before {
  content: "\F7F3"; }

.mdi-television-classic-off:before {
  content: "\F839"; }

.mdi-television-guide:before {
  content: "\F503"; }

.mdi-television-off:before {
  content: "\F83A"; }

.mdi-temperature-celsius:before {
  content: "\F504"; }

.mdi-temperature-fahrenheit:before {
  content: "\F505"; }

.mdi-temperature-kelvin:before {
  content: "\F506"; }

.mdi-tennis:before {
  content: "\FD7C"; }

.mdi-tennis-ball:before {
  content: "\F507"; }

.mdi-tent:before {
  content: "\F508"; }

.mdi-terrain:before {
  content: "\F509"; }

.mdi-test-tube:before {
  content: "\F668"; }

.mdi-test-tube-empty:before {
  content: "\F910"; }

.mdi-test-tube-off:before {
  content: "\F911"; }

.mdi-text:before {
  content: "\F9A7"; }

.mdi-text-shadow:before {
  content: "\F669"; }

.mdi-text-short:before {
  content: "\F9A8"; }

.mdi-text-subject:before {
  content: "\F9A9"; }

.mdi-text-to-speech:before {
  content: "\F50A"; }

.mdi-text-to-speech-off:before {
  content: "\F50B"; }

.mdi-textbox:before {
  content: "\F60E"; }

.mdi-textbox-password:before {
  content: "\F7F4"; }

.mdi-texture:before {
  content: "\F50C"; }

.mdi-theater:before {
  content: "\F50D"; }

.mdi-theme-light-dark:before {
  content: "\F50E"; }

.mdi-thermometer:before {
  content: "\F50F"; }

.mdi-thermometer-alert:before {
  content: "\FE61"; }

.mdi-thermometer-chevron-down:before {
  content: "\FE62"; }

.mdi-thermometer-chevron-up:before {
  content: "\FE63"; }

.mdi-thermometer-lines:before {
  content: "\F510"; }

.mdi-thermometer-minus:before {
  content: "\FE64"; }

.mdi-thermometer-plus:before {
  content: "\FE65"; }

.mdi-thermostat:before {
  content: "\F393"; }

.mdi-thermostat-box:before {
  content: "\F890"; }

.mdi-thought-bubble:before {
  content: "\F7F5"; }

.mdi-thought-bubble-outline:before {
  content: "\F7F6"; }

.mdi-thumb-down:before {
  content: "\F511"; }

.mdi-thumb-down-outline:before {
  content: "\F512"; }

.mdi-thumb-up:before {
  content: "\F513"; }

.mdi-thumb-up-outline:before {
  content: "\F514"; }

.mdi-thumbs-up-down:before {
  content: "\F515"; }

.mdi-ticket:before {
  content: "\F516"; }

.mdi-ticket-account:before {
  content: "\F517"; }

.mdi-ticket-confirmation:before {
  content: "\F518"; }

.mdi-ticket-outline:before {
  content: "\F912"; }

.mdi-ticket-percent:before {
  content: "\F723"; }

.mdi-tie:before {
  content: "\F519"; }

.mdi-tilde:before {
  content: "\F724"; }

.mdi-timelapse:before {
  content: "\F51A"; }

.mdi-timeline:before {
  content: "\FBAD"; }

.mdi-timeline-outline:before {
  content: "\FBAE"; }

.mdi-timeline-text:before {
  content: "\FBAF"; }

.mdi-timeline-text-outline:before {
  content: "\FBB0"; }

.mdi-timer:before {
  content: "\F51B"; }

.mdi-timer-10:before {
  content: "\F51C"; }

.mdi-timer-3:before {
  content: "\F51D"; }

.mdi-timer-off:before {
  content: "\F51E"; }

.mdi-timer-sand:before {
  content: "\F51F"; }

.mdi-timer-sand-empty:before {
  content: "\F6AC"; }

.mdi-timer-sand-full:before {
  content: "\F78B"; }

.mdi-timetable:before {
  content: "\F520"; }

.mdi-toaster-oven:before {
  content: "\FCAF"; }

.mdi-toggle-switch:before {
  content: "\F521"; }

.mdi-toggle-switch-off:before {
  content: "\F522"; }

.mdi-toggle-switch-off-outline:before {
  content: "\FA18"; }

.mdi-toggle-switch-outline:before {
  content: "\FA19"; }

.mdi-toilet:before {
  content: "\F9AA"; }

.mdi-toolbox:before {
  content: "\F9AB"; }

.mdi-toolbox-outline:before {
  content: "\F9AC"; }

.mdi-tooltip:before {
  content: "\F523"; }

.mdi-tooltip-account:before {
  content: "\F00C"; }

.mdi-tooltip-edit:before {
  content: "\F524"; }

.mdi-tooltip-image:before {
  content: "\F525"; }

.mdi-tooltip-image-outline:before {
  content: "\FBB1"; }

.mdi-tooltip-outline:before {
  content: "\F526"; }

.mdi-tooltip-plus:before {
  content: "\FBB2"; }

.mdi-tooltip-plus-outline:before {
  content: "\F527"; }

.mdi-tooltip-text:before {
  content: "\F528"; }

.mdi-tooltip-text-outline:before {
  content: "\FBB3"; }

.mdi-tooth:before {
  content: "\F8C2"; }

.mdi-tooth-outline:before {
  content: "\F529"; }

.mdi-tor:before {
  content: "\F52A"; }

.mdi-tortoise:before {
  content: "\FD17"; }

.mdi-tournament:before {
  content: "\F9AD"; }

.mdi-tower-beach:before {
  content: "\F680"; }

.mdi-tower-fire:before {
  content: "\F681"; }

.mdi-towing:before {
  content: "\F83B"; }

.mdi-track-light:before {
  content: "\F913"; }

.mdi-trackpad:before {
  content: "\F7F7"; }

.mdi-trackpad-lock:before {
  content: "\F932"; }

.mdi-tractor:before {
  content: "\F891"; }

.mdi-trademark:before {
  content: "\FA77"; }

.mdi-traffic-light:before {
  content: "\F52B"; }

.mdi-train:before {
  content: "\F52C"; }

.mdi-train-car:before {
  content: "\FBB4"; }

.mdi-train-variant:before {
  content: "\F8C3"; }

.mdi-tram:before {
  content: "\F52D"; }

.mdi-transcribe:before {
  content: "\F52E"; }

.mdi-transcribe-close:before {
  content: "\F52F"; }

.mdi-transfer-down:before {
  content: "\FD7D"; }

.mdi-transfer-left:before {
  content: "\FD7E"; }

.mdi-transfer-right:before {
  content: "\F530"; }

.mdi-transfer-up:before {
  content: "\FD7F"; }

.mdi-transit-connection:before {
  content: "\FD18"; }

.mdi-transit-connection-variant:before {
  content: "\FD19"; }

.mdi-transit-transfer:before {
  content: "\F6AD"; }

.mdi-transition:before {
  content: "\F914"; }

.mdi-transition-masked:before {
  content: "\F915"; }

.mdi-translate:before {
  content: "\F5CA"; }

.mdi-translate-off:before {
  content: "\FE66"; }

.mdi-transmission-tower:before {
  content: "\FD1A"; }

.mdi-trash-can:before {
  content: "\FA78"; }

.mdi-trash-can-outline:before {
  content: "\FA79"; }

.mdi-treasure-chest:before {
  content: "\F725"; }

.mdi-tree:before {
  content: "\F531"; }

.mdi-tree-outline:before {
  content: "\FE4C"; }

.mdi-trello:before {
  content: "\F532"; }

.mdi-trending-down:before {
  content: "\F533"; }

.mdi-trending-neutral:before {
  content: "\F534"; }

.mdi-trending-up:before {
  content: "\F535"; }

.mdi-triangle:before {
  content: "\F536"; }

.mdi-triangle-outline:before {
  content: "\F537"; }

.mdi-triforce:before {
  content: "\FBB5"; }

.mdi-trophy:before {
  content: "\F538"; }

.mdi-trophy-award:before {
  content: "\F539"; }

.mdi-trophy-broken:before {
  content: "\FD80"; }

.mdi-trophy-outline:before {
  content: "\F53A"; }

.mdi-trophy-variant:before {
  content: "\F53B"; }

.mdi-trophy-variant-outline:before {
  content: "\F53C"; }

.mdi-truck:before {
  content: "\F53D"; }

.mdi-truck-check:before {
  content: "\FCB0"; }

.mdi-truck-delivery:before {
  content: "\F53E"; }

.mdi-truck-fast:before {
  content: "\F787"; }

.mdi-truck-trailer:before {
  content: "\F726"; }

.mdi-tshirt-crew:before {
  content: "\FA7A"; }

.mdi-tshirt-crew-outline:before {
  content: "\F53F"; }

.mdi-tshirt-v:before {
  content: "\FA7B"; }

.mdi-tshirt-v-outline:before {
  content: "\F540"; }

.mdi-tumble-dryer:before {
  content: "\F916"; }

.mdi-tumblr:before {
  content: "\F541"; }

.mdi-tumblr-box:before {
  content: "\F917"; }

.mdi-tumblr-reblog:before {
  content: "\F542"; }

.mdi-tune:before {
  content: "\F62E"; }

.mdi-tune-vertical:before {
  content: "\F66A"; }

.mdi-turnstile:before {
  content: "\FCB1"; }

.mdi-turnstile-outline:before {
  content: "\FCB2"; }

.mdi-turtle:before {
  content: "\FCB3"; }

.mdi-twitch:before {
  content: "\F543"; }

.mdi-twitter:before {
  content: "\F544"; }

.mdi-twitter-box:before {
  content: "\F545"; }

.mdi-twitter-circle:before {
  content: "\F546"; }

.mdi-twitter-retweet:before {
  content: "\F547"; }

.mdi-two-factor-authentication:before {
  content: "\F9AE"; }

.mdi-uber:before {
  content: "\F748"; }

.mdi-ubisoft:before {
  content: "\FBB6"; }

.mdi-ubuntu:before {
  content: "\F548"; }

.mdi-ultra-high-definition:before {
  content: "\F7F8"; }

.mdi-umbraco:before {
  content: "\F549"; }

.mdi-umbrella:before {
  content: "\F54A"; }

.mdi-umbrella-closed:before {
  content: "\F9AF"; }

.mdi-umbrella-outline:before {
  content: "\F54B"; }

.mdi-undo:before {
  content: "\F54C"; }

.mdi-undo-variant:before {
  content: "\F54D"; }

.mdi-unfold-less-horizontal:before {
  content: "\F54E"; }

.mdi-unfold-less-vertical:before {
  content: "\F75F"; }

.mdi-unfold-more-horizontal:before {
  content: "\F54F"; }

.mdi-unfold-more-vertical:before {
  content: "\F760"; }

.mdi-ungroup:before {
  content: "\F550"; }

.mdi-unity:before {
  content: "\F6AE"; }

.mdi-unreal:before {
  content: "\F9B0"; }

.mdi-untappd:before {
  content: "\F551"; }

.mdi-update:before {
  content: "\F6AF"; }

.mdi-upload:before {
  content: "\F552"; }

.mdi-upload-multiple:before {
  content: "\F83C"; }

.mdi-upload-network:before {
  content: "\F6F5"; }

.mdi-upload-network-outline:before {
  content: "\FCB4"; }

.mdi-upload-outline:before {
  content: "\FE67"; }

.mdi-usb:before {
  content: "\F553"; }

.mdi-van-passenger:before {
  content: "\F7F9"; }

.mdi-van-utility:before {
  content: "\F7FA"; }

.mdi-vanish:before {
  content: "\F7FB"; }

.mdi-variable:before {
  content: "\FAE6"; }

.mdi-vector-arrange-above:before {
  content: "\F554"; }

.mdi-vector-arrange-below:before {
  content: "\F555"; }

.mdi-vector-bezier:before {
  content: "\FAE7"; }

.mdi-vector-circle:before {
  content: "\F556"; }

.mdi-vector-circle-variant:before {
  content: "\F557"; }

.mdi-vector-combine:before {
  content: "\F558"; }

.mdi-vector-curve:before {
  content: "\F559"; }

.mdi-vector-difference:before {
  content: "\F55A"; }

.mdi-vector-difference-ab:before {
  content: "\F55B"; }

.mdi-vector-difference-ba:before {
  content: "\F55C"; }

.mdi-vector-ellipse:before {
  content: "\F892"; }

.mdi-vector-intersection:before {
  content: "\F55D"; }

.mdi-vector-line:before {
  content: "\F55E"; }

.mdi-vector-point:before {
  content: "\F55F"; }

.mdi-vector-polygon:before {
  content: "\F560"; }

.mdi-vector-polyline:before {
  content: "\F561"; }

.mdi-vector-radius:before {
  content: "\F749"; }

.mdi-vector-rectangle:before {
  content: "\F5C6"; }

.mdi-vector-selection:before {
  content: "\F562"; }

.mdi-vector-square:before {
  content: "\F001"; }

.mdi-vector-triangle:before {
  content: "\F563"; }

.mdi-vector-union:before {
  content: "\F564"; }

.mdi-venmo:before {
  content: "\F578"; }

.mdi-vhs:before {
  content: "\FA1A"; }

.mdi-vibrate:before {
  content: "\F566"; }

.mdi-vibrate-off:before {
  content: "\FCB5"; }

.mdi-video:before {
  content: "\F567"; }

.mdi-video-3d:before {
  content: "\F7FC"; }

.mdi-video-4k-box:before {
  content: "\F83D"; }

.mdi-video-account:before {
  content: "\F918"; }

.mdi-video-image:before {
  content: "\F919"; }

.mdi-video-input-antenna:before {
  content: "\F83E"; }

.mdi-video-input-component:before {
  content: "\F83F"; }

.mdi-video-input-hdmi:before {
  content: "\F840"; }

.mdi-video-input-svideo:before {
  content: "\F841"; }

.mdi-video-minus:before {
  content: "\F9B1"; }

.mdi-video-off:before {
  content: "\F568"; }

.mdi-video-off-outline:before {
  content: "\FBB7"; }

.mdi-video-outline:before {
  content: "\FBB8"; }

.mdi-video-plus:before {
  content: "\F9B2"; }

.mdi-video-stabilization:before {
  content: "\F91A"; }

.mdi-video-switch:before {
  content: "\F569"; }

.mdi-video-vintage:before {
  content: "\FA1B"; }

.mdi-view-agenda:before {
  content: "\F56A"; }

.mdi-view-array:before {
  content: "\F56B"; }

.mdi-view-carousel:before {
  content: "\F56C"; }

.mdi-view-column:before {
  content: "\F56D"; }

.mdi-view-comfy:before {
  content: "\FE4D"; }

.mdi-view-compact:before {
  content: "\FE4E"; }

.mdi-view-compact-outline:before {
  content: "\FE4F"; }

.mdi-view-dashboard:before {
  content: "\F56E"; }

.mdi-view-dashboard-outline:before {
  content: "\FA1C"; }

.mdi-view-dashboard-variant:before {
  content: "\F842"; }

.mdi-view-day:before {
  content: "\F56F"; }

.mdi-view-grid:before {
  content: "\F570"; }

.mdi-view-headline:before {
  content: "\F571"; }

.mdi-view-list:before {
  content: "\F572"; }

.mdi-view-module:before {
  content: "\F573"; }

.mdi-view-parallel:before {
  content: "\F727"; }

.mdi-view-quilt:before {
  content: "\F574"; }

.mdi-view-sequential:before {
  content: "\F728"; }

.mdi-view-split-horizontal:before {
  content: "\FBA7"; }

.mdi-view-split-vertical:before {
  content: "\FBA8"; }

.mdi-view-stream:before {
  content: "\F575"; }

.mdi-view-week:before {
  content: "\F576"; }

.mdi-vimeo:before {
  content: "\F577"; }

.mdi-violin:before {
  content: "\F60F"; }

.mdi-virtual-reality:before {
  content: "\F893"; }

.mdi-visual-studio:before {
  content: "\F610"; }

.mdi-visual-studio-code:before {
  content: "\FA1D"; }

.mdi-vk:before {
  content: "\F579"; }

.mdi-vk-box:before {
  content: "\F57A"; }

.mdi-vk-circle:before {
  content: "\F57B"; }

.mdi-vlc:before {
  content: "\F57C"; }

.mdi-voice:before {
  content: "\F5CB"; }

.mdi-voicemail:before {
  content: "\F57D"; }

.mdi-volleyball:before {
  content: "\F9B3"; }

.mdi-volume-high:before {
  content: "\F57E"; }

.mdi-volume-low:before {
  content: "\F57F"; }

.mdi-volume-medium:before {
  content: "\F580"; }

.mdi-volume-minus:before {
  content: "\F75D"; }

.mdi-volume-mute:before {
  content: "\F75E"; }

.mdi-volume-off:before {
  content: "\F581"; }

.mdi-volume-plus:before {
  content: "\F75C"; }

.mdi-volume-variant-off:before {
  content: "\FE68"; }

.mdi-vote:before {
  content: "\FA1E"; }

.mdi-vote-outline:before {
  content: "\FA1F"; }

.mdi-vpn:before {
  content: "\F582"; }

.mdi-vuejs:before {
  content: "\F843"; }

.mdi-vuetify:before {
  content: "\FE50"; }

.mdi-walk:before {
  content: "\F583"; }

.mdi-wall:before {
  content: "\F7FD"; }

.mdi-wall-sconce:before {
  content: "\F91B"; }

.mdi-wall-sconce-flat:before {
  content: "\F91C"; }

.mdi-wall-sconce-variant:before {
  content: "\F91D"; }

.mdi-wallet:before {
  content: "\F584"; }

.mdi-wallet-giftcard:before {
  content: "\F585"; }

.mdi-wallet-membership:before {
  content: "\F586"; }

.mdi-wallet-outline:before {
  content: "\FBB9"; }

.mdi-wallet-travel:before {
  content: "\F587"; }

.mdi-wallpaper:before {
  content: "\FE69"; }

.mdi-wan:before {
  content: "\F588"; }

.mdi-washing-machine:before {
  content: "\F729"; }

.mdi-watch:before {
  content: "\F589"; }

.mdi-watch-export:before {
  content: "\F58A"; }

.mdi-watch-export-variant:before {
  content: "\F894"; }

.mdi-watch-import:before {
  content: "\F58B"; }

.mdi-watch-import-variant:before {
  content: "\F895"; }

.mdi-watch-variant:before {
  content: "\F896"; }

.mdi-watch-vibrate:before {
  content: "\F6B0"; }

.mdi-watch-vibrate-off:before {
  content: "\FCB6"; }

.mdi-water:before {
  content: "\F58C"; }

.mdi-water-off:before {
  content: "\F58D"; }

.mdi-water-outline:before {
  content: "\FE6A"; }

.mdi-water-percent:before {
  content: "\F58E"; }

.mdi-water-pump:before {
  content: "\F58F"; }

.mdi-watermark:before {
  content: "\F612"; }

.mdi-waves:before {
  content: "\F78C"; }

.mdi-waze:before {
  content: "\FBBA"; }

.mdi-weather-cloudy:before {
  content: "\F590"; }

.mdi-weather-cloudy-arrow-right:before {
  content: "\FE51"; }

.mdi-weather-fog:before {
  content: "\F591"; }

.mdi-weather-hail:before {
  content: "\F592"; }

.mdi-weather-hurricane:before {
  content: "\F897"; }

.mdi-weather-lightning:before {
  content: "\F593"; }

.mdi-weather-lightning-rainy:before {
  content: "\F67D"; }

.mdi-weather-night:before {
  content: "\F594"; }

.mdi-weather-partlycloudy:before {
  content: "\F595"; }

.mdi-weather-pouring:before {
  content: "\F596"; }

.mdi-weather-rainy:before {
  content: "\F597"; }

.mdi-weather-snowy:before {
  content: "\F598"; }

.mdi-weather-snowy-rainy:before {
  content: "\F67E"; }

.mdi-weather-sunny:before {
  content: "\F599"; }

.mdi-weather-sunset:before {
  content: "\F59A"; }

.mdi-weather-sunset-down:before {
  content: "\F59B"; }

.mdi-weather-sunset-up:before {
  content: "\F59C"; }

.mdi-weather-windy:before {
  content: "\F59D"; }

.mdi-weather-windy-variant:before {
  content: "\F59E"; }

.mdi-web:before {
  content: "\F59F"; }

.mdi-webcam:before {
  content: "\F5A0"; }

.mdi-webhook:before {
  content: "\F62F"; }

.mdi-webpack:before {
  content: "\F72A"; }

.mdi-wechat:before {
  content: "\F611"; }

.mdi-weight:before {
  content: "\F5A1"; }

.mdi-weight-gram:before {
  content: "\FD1B"; }

.mdi-weight-kilogram:before {
  content: "\F5A2"; }

.mdi-weight-pound:before {
  content: "\F9B4"; }

.mdi-whatsapp:before {
  content: "\F5A3"; }

.mdi-wheelchair-accessibility:before {
  content: "\F5A4"; }

.mdi-whistle:before {
  content: "\F9B5"; }

.mdi-white-balance-auto:before {
  content: "\F5A5"; }

.mdi-white-balance-incandescent:before {
  content: "\F5A6"; }

.mdi-white-balance-iridescent:before {
  content: "\F5A7"; }

.mdi-white-balance-sunny:before {
  content: "\F5A8"; }

.mdi-widgets:before {
  content: "\F72B"; }

.mdi-wifi:before {
  content: "\F5A9"; }

.mdi-wifi-off:before {
  content: "\F5AA"; }

.mdi-wifi-star:before {
  content: "\FE6B"; }

.mdi-wifi-strength-1:before {
  content: "\F91E"; }

.mdi-wifi-strength-1-alert:before {
  content: "\F91F"; }

.mdi-wifi-strength-1-lock:before {
  content: "\F920"; }

.mdi-wifi-strength-2:before {
  content: "\F921"; }

.mdi-wifi-strength-2-alert:before {
  content: "\F922"; }

.mdi-wifi-strength-2-lock:before {
  content: "\F923"; }

.mdi-wifi-strength-3:before {
  content: "\F924"; }

.mdi-wifi-strength-3-alert:before {
  content: "\F925"; }

.mdi-wifi-strength-3-lock:before {
  content: "\F926"; }

.mdi-wifi-strength-4:before {
  content: "\F927"; }

.mdi-wifi-strength-4-alert:before {
  content: "\F928"; }

.mdi-wifi-strength-4-lock:before {
  content: "\F929"; }

.mdi-wifi-strength-alert-outline:before {
  content: "\F92A"; }

.mdi-wifi-strength-lock-outline:before {
  content: "\F92B"; }

.mdi-wifi-strength-off:before {
  content: "\F92C"; }

.mdi-wifi-strength-off-outline:before {
  content: "\F92D"; }

.mdi-wifi-strength-outline:before {
  content: "\F92E"; }

.mdi-wii:before {
  content: "\F5AB"; }

.mdi-wiiu:before {
  content: "\F72C"; }

.mdi-wikipedia:before {
  content: "\F5AC"; }

.mdi-wind-turbine:before {
  content: "\FD81"; }

.mdi-window-close:before {
  content: "\F5AD"; }

.mdi-window-closed:before {
  content: "\F5AE"; }

.mdi-window-maximize:before {
  content: "\F5AF"; }

.mdi-window-minimize:before {
  content: "\F5B0"; }

.mdi-window-open:before {
  content: "\F5B1"; }

.mdi-window-restore:before {
  content: "\F5B2"; }

.mdi-windows:before {
  content: "\F5B3"; }

.mdi-windows-classic:before {
  content: "\FA20"; }

.mdi-wiper:before {
  content: "\FAE8"; }

.mdi-wiper-wash:before {
  content: "\FD82"; }

.mdi-wordpress:before {
  content: "\F5B4"; }

.mdi-worker:before {
  content: "\F5B5"; }

.mdi-wrap:before {
  content: "\F5B6"; }

.mdi-wrap-disabled:before {
  content: "\FBBB"; }

.mdi-wrench:before {
  content: "\F5B7"; }

.mdi-wrench-outline:before {
  content: "\FBBC"; }

.mdi-wunderlist:before {
  content: "\F5B8"; }

.mdi-xamarin:before {
  content: "\F844"; }

.mdi-xamarin-outline:before {
  content: "\F845"; }

.mdi-xaml:before {
  content: "\F673"; }

.mdi-xbox:before {
  content: "\F5B9"; }

.mdi-xbox-controller:before {
  content: "\F5BA"; }

.mdi-xbox-controller-battery-alert:before {
  content: "\F74A"; }

.mdi-xbox-controller-battery-charging:before {
  content: "\FA21"; }

.mdi-xbox-controller-battery-empty:before {
  content: "\F74B"; }

.mdi-xbox-controller-battery-full:before {
  content: "\F74C"; }

.mdi-xbox-controller-battery-low:before {
  content: "\F74D"; }

.mdi-xbox-controller-battery-medium:before {
  content: "\F74E"; }

.mdi-xbox-controller-battery-unknown:before {
  content: "\F74F"; }

.mdi-xbox-controller-menu:before {
  content: "\FE52"; }

.mdi-xbox-controller-off:before {
  content: "\F5BB"; }

.mdi-xbox-controller-view:before {
  content: "\FE53"; }

.mdi-xda:before {
  content: "\F5BC"; }

.mdi-xing:before {
  content: "\F5BD"; }

.mdi-xing-box:before {
  content: "\F5BE"; }

.mdi-xing-circle:before {
  content: "\F5BF"; }

.mdi-xml:before {
  content: "\F5C0"; }

.mdi-xmpp:before {
  content: "\F7FE"; }

.mdi-yahoo:before {
  content: "\FB2A"; }

.mdi-yammer:before {
  content: "\F788"; }

.mdi-yeast:before {
  content: "\F5C1"; }

.mdi-yelp:before {
  content: "\F5C2"; }

.mdi-yin-yang:before {
  content: "\F67F"; }

.mdi-youtube:before {
  content: "\F5C3"; }

.mdi-youtube-creator-studio:before {
  content: "\F846"; }

.mdi-youtube-gaming:before {
  content: "\F847"; }

.mdi-youtube-subscription:before {
  content: "\FD1C"; }

.mdi-youtube-tv:before {
  content: "\F448"; }

.mdi-z-wave:before {
  content: "\FAE9"; }

.mdi-zend:before {
  content: "\FAEA"; }

.mdi-zigbee:before {
  content: "\FD1D"; }

.mdi-zip-box:before {
  content: "\F5C4"; }

.mdi-zip-disk:before {
  content: "\FA22"; }

.mdi-zodiac-aquarius:before {
  content: "\FA7C"; }

.mdi-zodiac-aries:before {
  content: "\FA7D"; }

.mdi-zodiac-cancer:before {
  content: "\FA7E"; }

.mdi-zodiac-capricorn:before {
  content: "\FA7F"; }

.mdi-zodiac-gemini:before {
  content: "\FA80"; }

.mdi-zodiac-leo:before {
  content: "\FA81"; }

.mdi-zodiac-libra:before {
  content: "\FA82"; }

.mdi-zodiac-pisces:before {
  content: "\FA83"; }

.mdi-zodiac-sagittarius:before {
  content: "\FA84"; }

.mdi-zodiac-scorpio:before {
  content: "\FA85"; }

.mdi-zodiac-taurus:before {
  content: "\FA86"; }

.mdi-zodiac-virgo:before {
  content: "\FA87"; }

.mdi-blank:before {
  content: "\F68C";
  visibility: hidden; }

.mdi-18px.mdi-set, .mdi-18px.mdi:before {
  font-size: 18px; }

.mdi-24px.mdi-set, .mdi-24px.mdi:before {
  font-size: 24px; }

.mdi-36px.mdi-set, .mdi-36px.mdi:before {
  font-size: 36px; }

.mdi-48px.mdi-set, .mdi-48px.mdi:before {
  font-size: 48px; }

.mdi-dark:before {
  color: rgba(0, 0, 0, 0.54); }

.mdi-dark.mdi-inactive:before {
  color: rgba(0, 0, 0, 0.26); }

.mdi-light:before {
  color: white; }

.mdi-light.mdi-inactive:before {
  color: rgba(255, 255, 255, 0.3); }

.mdi-rotate-45 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(45deg);
      transform: scaleX(-1) rotate(45deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: scaleY(-1) rotate(45deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */ }

.mdi-rotate-45:before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

.mdi-rotate-90 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(90deg);
      transform: scaleX(-1) rotate(90deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: scaleY(-1) rotate(90deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */ }

.mdi-rotate-90:before {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg); }

.mdi-rotate-135 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(135deg);
      transform: scaleX(-1) rotate(135deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(135deg);
      -ms-transform: rotate(135deg);
      transform: scaleY(-1) rotate(135deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */ }

.mdi-rotate-135:before {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg); }

.mdi-rotate-180 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(180deg);
      transform: scaleX(-1) rotate(180deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: scaleY(-1) rotate(180deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */ }

.mdi-rotate-180:before {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg); }

.mdi-rotate-225 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(225deg);
      transform: scaleX(-1) rotate(225deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(225deg);
      -ms-transform: rotate(225deg);
      transform: scaleY(-1) rotate(225deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */ }

.mdi-rotate-225:before {
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg); }

.mdi-rotate-270 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(270deg);
      transform: scaleX(-1) rotate(270deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(270deg);
      -ms-transform: rotate(270deg);
      transform: scaleY(-1) rotate(270deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */ }

.mdi-rotate-270:before {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg); }

.mdi-rotate-315 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(315deg);
      transform: scaleX(-1) rotate(315deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(315deg);
      -ms-transform: rotate(315deg);
      transform: scaleY(-1) rotate(315deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */ }

.mdi-rotate-315:before {
  -webkit-transform: rotate(315deg);
  -ms-transform: rotate(315deg);
  transform: rotate(315deg); }

.mdi-flip-h:before {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH"; }

.mdi-flip-v:before {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipV;
  -ms-filter: "FlipV"; }

.mdi-spin:before {
  -webkit-animation: mdi-spin 2s infinite linear;
  animation: mdi-spin 2s infinite linear; }

@-webkit-keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

/*# sourceMappingURL=materialdesignicons.css.map */
/*-----------------------------------------------------------------------*/
/* Contains custom elements, components and general styles that
/* follows Google(TM) Material Design(TM) specs
/*
/* https://www.google.com/design/spec/material-design/introduction.html
/*-----------------------------------------------------------------------*/
/*----------------------------------------------------------------*/
/*  Avatars
/*----------------------------------------------------------------*/
.avatar {
  width: 40px;
  min-width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 0 8px 0 0;
  border-radius: 50%;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  color: #FFFFFF; }
  .avatar.square {
    border-radius: 0; }
  .avatar.small {
    width: 20px;
    min-width: 20px;
    height: 20px;
    line-height: 20px; }
  .avatar.big {
    width: 72px;
    min-width: 72px;
    height: 72px;
    line-height: 72px; }
  .avatar.huge {
    width: 96px;
    min-width: 96px;
    height: 96px;
    line-height: 96px; }

/*----------------------------------------------------------------*/
/*  Forms
/*----------------------------------------------------------------*/
.form-wrapper {
  background: #FFFFFF;
  padding: 16px; }
  .form-wrapper .form-title {
    font-size: 21px;
    padding: 8px 0; }

/*----------------------------------------------------------------*/
/*  Navigation - Simple
/*----------------------------------------------------------------*/
.navigation-simple .item {
  position: relative;
  cursor: pointer;
  text-align: left;
  margin: 0;
  padding: 0 24px;
  text-transform: none;
  line-height: 48px;
  max-height: 48px;
  height: 48px; }
  .navigation-simple .item md-icon {
    margin: 0 16px 0 0; }
  .navigation-simple .item .title {
    font-size: 13px;
    font-weight: 500;
    line-height: 1; }
  .navigation-simple .item.selected {
    background-color: rgba(0, 0, 0, 0.06); }

.navigation-simple .subheader {
  font-size: 13px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  font-weight: 500;
  margin-top: 8px; }
  .navigation-simple .subheader.light {
    border-top: 1px solid rgba(255, 255, 255, 0.12); }

.navigation-simple md-divider {
  margin: 8px 0; }

/*----------------------------------------------------------------*/
/*  Pagination
/*----------------------------------------------------------------*/
.simple-pagination {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  background-color: #FFFFFF;
  padding: 0 8px; }
  .simple-pagination .pagination-item {
    min-width: 48px;
    min-height: 56px;
    line-height: 56px;
    border-radius: 0;
    margin: 0;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.54); }
    .simple-pagination .pagination-item:hover {
      color: rgba(0, 0, 0, 0.87); }
    .simple-pagination .pagination-item.active {
      cursor: default;
      color: rgba(0, 0, 0, 0.87); }
    .simple-pagination .pagination-item.disabled {
      cursor: default;
      color: rgba(0, 0, 0, 0.26); }

/*----------------------------------------------------------------*/
/*  Price Tables
/*----------------------------------------------------------------*/
.price-tables .price-table {
  position: relative;
  background-color: #FFFFFF;
  width: 280px;
  border-radius: 2px;
  margin: 24px 24px 0 0;
  overflow: hidden; }
  .price-tables .price-table.style-1 .package-type {
    font-size: 17px;
    padding: 16px 24px; }
    .price-tables .price-table.style-1 .package-type .sale {
      font-size: 13px;
      font-weight: 600; }
  .price-tables .price-table.style-1 .price {
    padding: 32px 32px 16px 32px; }
    .price-tables .price-table.style-1 .price .currency {
      padding-right: 4px;
      font-size: 24px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.54); }
    .price-tables .price-table.style-1 .price .value {
      font-size: 72px;
      font-weight: 300;
      line-height: 1; }
    .price-tables .price-table.style-1 .price .period {
      padding: 0 0 5px 4px;
      font-size: 17px;
      color: rgba(0, 0, 0, 0.54); }
  .price-tables .price-table.style-1 md-divider {
    margin: 16px 32px; }
  .price-tables .price-table.style-1 .terms {
    padding: 16px 32px;
    font-size: 15px; }
  .price-tables .price-table.style-1 .cta-button {
    margin: 16px auto 32px auto;
    width: 128px; }
  .price-tables .price-table.style-2 .badge {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: 11px;
    font-weight: 500;
    padding: 6px 8px; }
  .price-tables .price-table.style-2 .package-type {
    padding: 48px 32px 24px 32px;
    font-size: 20px;
    font-weight: 500;
    text-align: center; }
  .price-tables .price-table.style-2 .price {
    padding: 0 32px 8px 32px; }
    .price-tables .price-table.style-2 .price .currency {
      padding-right: 4px;
      font-size: 24px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.54); }
    .price-tables .price-table.style-2 .price .value {
      font-size: 56px;
      font-weight: 300;
      line-height: 1; }
  .price-tables .price-table.style-2 .period {
    padding: 0 32px;
    font-size: 15px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.54);
    text-align: center; }
  .price-tables .price-table.style-2 .terms {
    padding: 32px;
    font-size: 15px; }
    .price-tables .price-table.style-2 .terms .term {
      padding-bottom: 8px; }
      .price-tables .price-table.style-2 .terms .term:last-child {
        padding-bottom: 0; }
  .price-tables .price-table.style-2 .cta-button {
    margin: 8px auto 32px auto;
    width: 128px; }
  .price-tables .price-table.style-3 .package-type {
    padding: 32px;
    text-align: center; }
    .price-tables .price-table.style-3 .package-type .title {
      font-size: 34px; }
    .price-tables .price-table.style-3 .package-type .subtitle {
      font-size: 17px;
      color: rgba(0, 0, 0, 0.54);
      font-weight: 500; }
  .price-tables .price-table.style-3 .price {
    padding: 16px 32px; }
    .price-tables .price-table.style-3 .price .currency {
      padding-right: 4px;
      font-size: 15px;
      font-weight: 500; }
    .price-tables .price-table.style-3 .price .value {
      font-size: 34px;
      font-weight: 300;
      line-height: 1; }
    .price-tables .price-table.style-3 .price .period {
      padding-left: 4px;
      text-align: center; }
  .price-tables .price-table.style-3 .terms {
    margin: 32px;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.54); }
    .price-tables .price-table.style-3 .terms .term {
      padding-bottom: 16px; }
      .price-tables .price-table.style-3 .terms .term:last-child {
        padding-bottom: 0; }
  .price-tables .price-table.style-3 .cta-button {
    margin: 8px 32px; }
  .price-tables .price-table.style-3 .note {
    padding: 8px 32px 16px 32px;
    text-align: center;
    color: rgba(0, 0, 0, 0.54); }

/*----------------------------------------------------------------*/
/*  Table - Simple
/*----------------------------------------------------------------*/
.simple-table-container {
  background: #FFFFFF; }
  .simple-table-container .table-title {
    font-size: 20px;
    padding: 24px; }

table.simple {
  width: 100%;
  border: none;
  border-spacing: 0;
  text-align: left; }
  table.simple thead tr th {
    padding: 16px 8px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.54);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    white-space: nowrap;
    min-width: 120px; }
    table.simple thead tr th:first-child {
      padding-left: 24px; }
    table.simple thead tr th:last-child {
      padding-right: 24px; }
  table.simple tbody tr td {
    padding: 16px 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
    table.simple tbody tr td:first-child {
      padding-left: 24px; }
    table.simple tbody tr td:last-child {
      padding-right: 24px; }
  table.simple tbody tr:last-child td {
    border-bottom: none; }
  table.simple.clickable tbody tr {
    cursor: pointer; }
    table.simple.clickable tbody tr:hover {
      background: rgba(0, 0, 0, 0.03); }

/*----------------------------------------------------------------*/
/*  Typography
/*----------------------------------------------------------------*/
html {
  font-size: 62.5%;
  font-family: "Roboto", "Helvetica Neue", "Arial", sans-serif;
  line-height: 1.4;
  letter-spacing: -0.1px; }

body {
  font-size: 1.3rem; }

html,
body {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto; }

form {
  font-size: 1.6rem; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: normal; }

h1, .h1 {
  font-size: 2.4rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.6rem; }

h4, .h4 {
  font-size: 1.4rem; }

h5, .h5 {
  font-size: 1.3rem; }

h6, .h6 {
  font-size: 1.2rem; }

a {
  text-decoration: none; }
  a:hover, a:active {
    text-decoration: underline; }

abbr {
  cursor: help;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.54); }

blockquote {
  border-left: 3px solid rgba(0, 0, 0, 0.12);
  font-style: italic;
  margin: 1em 0;
  padding-left: 16px; }
  blockquote footer {
    font-style: normal; }
    blockquote footer:before {
      content: '\2014 \00A0'; }
  blockquote.reverse {
    border-left: none;
    border-right: 3px solid rgba(0, 0, 0, 0.12);
    text-align: right;
    padding-left: 0;
    padding-right: 16px; }
    blockquote.reverse footer:before {
      content: ''; }
    blockquote.reverse footer:after {
      content: '\2014 \00A0'; }

code {
  font-family: "Monaco", "Menlo", "Consolas", "Ubuntu Mono", monospace; }
  code:not(.highlight) {
    background: rgba(0, 0, 0, 0.065);
    color: #106CC8;
    margin: 0 1px;
    padding: 2px 3px;
    border-radius: 2px; }

dl dt {
  font-weight: bold; }

dl dd {
  margin: 4px 0 16px 0; }

mark {
  background: #F7F49A; }

pre {
  line-height: 1.6;
  margin: 8px 16px;
  white-space: pre-wrap; }

small {
  font-size: 80%; }

table thead tr th {
  text-align: left; }

.text-italic {
  font-style: italic; }

.text-semibold {
  font-weight: 600; }

.text-bold, strong {
  font-weight: 700; }

.text-strike {
  text-decoration: line-through; }

.text-super {
  vertical-align: super; }

.text-sub {
  vertical-align: sub; }

.text-capitalize {
  text-transform: capitalize; }

.text-lowercase {
  text-transform: lowercase; }

.text-uppercase {
  text-transform: uppercase; }

.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.font-weight-100 {
  font-weight: 100; }

.font-weight-200 {
  font-weight: 200; }

.font-weight-300 {
  font-weight: 300; }

.font-weight-400 {
  font-weight: 400; }

.font-weight-500 {
  font-weight: 500; }

.font-weight-600 {
  font-weight: 600; }

.font-weight-700 {
  font-weight: 700; }

.font-weight-800 {
  font-weight: 800; }

.font-weight-900 {
  font-weight: 900; }

.font-size-2 {
  font-size: 2px; }

.font-size-4 {
  font-size: 4px; }

.font-size-6 {
  font-size: 6px; }

.font-size-8 {
  font-size: 8px; }

.font-size-10 {
  font-size: 10px; }

.font-size-12 {
  font-size: 12px; }

.font-size-14 {
  font-size: 14px; }

.font-size-16 {
  font-size: 16px; }

.font-size-18 {
  font-size: 18px; }

.font-size-20 {
  font-size: 20px; }

.font-size-22 {
  font-size: 22px; }

.font-size-24 {
  font-size: 24px; }

.font-size-26 {
  font-size: 26px; }

.font-size-28 {
  font-size: 28px; }

.font-size-30 {
  font-size: 30px; }

.font-size-32 {
  font-size: 32px; }

.font-size-34 {
  font-size: 34px; }

.font-size-36 {
  font-size: 36px; }

.font-size-38 {
  font-size: 38px; }

.font-size-40 {
  font-size: 40px; }

.font-size-42 {
  font-size: 42px; }

.font-size-44 {
  font-size: 44px; }

.font-size-46 {
  font-size: 46px; }

.font-size-48 {
  font-size: 48px; }

.font-size-50 {
  font-size: 50px; }

.font-size-52 {
  font-size: 52px; }

.font-size-54 {
  font-size: 54px; }

.font-size-56 {
  font-size: 56px; }

.font-size-58 {
  font-size: 58px; }

.font-size-60 {
  font-size: 60px; }

.font-size-62 {
  font-size: 62px; }

.font-size-64 {
  font-size: 64px; }

.font-size-66 {
  font-size: 66px; }

.font-size-68 {
  font-size: 68px; }

.font-size-70 {
  font-size: 70px; }

.font-size-72 {
  font-size: 72px; }

.font-size-74 {
  font-size: 74px; }

.font-size-76 {
  font-size: 76px; }

.font-size-78 {
  font-size: 78px; }

.font-size-80 {
  font-size: 80px; }

.font-size-82 {
  font-size: 82px; }

.font-size-84 {
  font-size: 84px; }

.font-size-86 {
  font-size: 86px; }

.font-size-88 {
  font-size: 88px; }

.font-size-90 {
  font-size: 90px; }

.font-size-92 {
  font-size: 92px; }

.font-size-94 {
  font-size: 94px; }

.font-size-96 {
  font-size: 96px; }

.font-size-98 {
  font-size: 98px; }

.font-size-100 {
  font-size: 100px; }

.font-size-102 {
  font-size: 102px; }

.font-size-104 {
  font-size: 104px; }

.font-size-106 {
  font-size: 106px; }

.font-size-108 {
  font-size: 108px; }

.font-size-110 {
  font-size: 110px; }

.font-size-112 {
  font-size: 112px; }

.font-size-114 {
  font-size: 114px; }

.font-size-116 {
  font-size: 116px; }

.font-size-118 {
  font-size: 118px; }

.font-size-120 {
  font-size: 120px; }

.line-height-2 {
  line-height: 2px; }

.line-height-4 {
  line-height: 4px; }

.line-height-6 {
  line-height: 6px; }

.line-height-8 {
  line-height: 8px; }

.line-height-10 {
  line-height: 10px; }

.line-height-12 {
  line-height: 12px; }

.line-height-14 {
  line-height: 14px; }

.line-height-16 {
  line-height: 16px; }

.line-height-18 {
  line-height: 18px; }

.line-height-20 {
  line-height: 20px; }

.line-height-22 {
  line-height: 22px; }

.line-height-24 {
  line-height: 24px; }

.line-height-26 {
  line-height: 26px; }

.line-height-28 {
  line-height: 28px; }

.line-height-30 {
  line-height: 30px; }

.line-height-32 {
  line-height: 32px; }

.line-height-34 {
  line-height: 34px; }

.line-height-36 {
  line-height: 36px; }

.line-height-38 {
  line-height: 38px; }

.line-height-40 {
  line-height: 40px; }

.line-height-42 {
  line-height: 42px; }

.line-height-44 {
  line-height: 44px; }

.line-height-46 {
  line-height: 46px; }

.line-height-48 {
  line-height: 48px; }

.line-height-50 {
  line-height: 50px; }

.line-height-52 {
  line-height: 52px; }

.line-height-54 {
  line-height: 54px; }

.line-height-56 {
  line-height: 56px; }

.line-height-58 {
  line-height: 58px; }

.line-height-60 {
  line-height: 60px; }

.line-height-62 {
  line-height: 62px; }

.line-height-64 {
  line-height: 64px; }

.line-height-66 {
  line-height: 66px; }

.line-height-68 {
  line-height: 68px; }

.line-height-70 {
  line-height: 70px; }

.line-height-72 {
  line-height: 72px; }

.line-height-74 {
  line-height: 74px; }

.line-height-76 {
  line-height: 76px; }

.line-height-78 {
  line-height: 78px; }

.line-height-80 {
  line-height: 80px; }

.line-height-82 {
  line-height: 82px; }

.line-height-84 {
  line-height: 84px; }

.line-height-86 {
  line-height: 86px; }

.line-height-88 {
  line-height: 88px; }

.line-height-90 {
  line-height: 90px; }

.line-height-92 {
  line-height: 92px; }

.line-height-94 {
  line-height: 94px; }

.line-height-96 {
  line-height: 96px; }

.line-height-98 {
  line-height: 98px; }

.line-height-100 {
  line-height: 100px; }

.line-height-102 {
  line-height: 102px; }

.line-height-104 {
  line-height: 104px; }

.line-height-106 {
  line-height: 106px; }

.line-height-108 {
  line-height: 108px; }

.line-height-110 {
  line-height: 110px; }

.line-height-112 {
  line-height: 112px; }

.line-height-114 {
  line-height: 114px; }

.line-height-116 {
  line-height: 116px; }

.line-height-118 {
  line-height: 118px; }

.line-height-120 {
  line-height: 120px; }

.text-boxed, .text-boxed-light {
  border-radius: 2px;
  padding: 4px 8px;
  margin: 0 8px;
  font-size: 11px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
  background-color: rgba(0, 0, 0, 0.12);
  white-space: nowrap; }

.text-boxed-light {
  background-color: rgba(255, 255, 255, 0.7); }

.text-truncate {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-nowrap {
  white-space: nowrap; }

.changelog .entry {
  background: white;
  margin-bottom: 24px;
  padding: 24px 32px; }
  .changelog .entry > .title {
    display: flex;
    align-items: center;
    margin-bottom: 24px; }
    .changelog .entry > .title .version {
      font-size: 24px; }
    .changelog .entry > .title .date {
      margin-left: 8px;
      font-size: 17px;
      opacity: 0.54; }
  .changelog .entry .groups div {
    margin-bottom: 32px; }
    .changelog .entry .groups div:last-child {
      margin-bottom: 0; }
  .changelog .entry .groups .title {
    display: inline-flex;
    font-size: 13px;
    color: white;
    letter-spacing: 0.015em;
    line-height: 1;
    padding: 5px 8px;
    border-radius: 2px; }
  .changelog .entry .groups .breaking-changes .title {
    background: #F44336; }
  .changelog .entry .groups .new .title {
    background: #43A047; }
  .changelog .entry .groups .improved .title {
    background: #673AB7; }
  .changelog .entry .groups .fixed .title {
    background: #2196F3; }
  .changelog .entry .groups ul {
    padding-left: 24px; }
    .changelog .entry .groups ul li {
      margin-bottom: 6px;
      letter-spacing: 0.015em; }

.angular-google-map-container {
  height: 400px; }

.moment-picker .moment-picker-container {
  width: 296px;
  border: none;
  border-radius: 2px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  margin-left: 0;
  padding: 8px; }
  .moment-picker .moment-picker-container th,
  .moment-picker .moment-picker-container td {
    min-width: 0;
    width: 40px;
    height: 40px;
    padding: 8px;
    text-shadow: none;
    border-radius: 35px; }
    .moment-picker .moment-picker-container th:hover,
    .moment-picker .moment-picker-container td:hover {
      background: #F5F5F5;
      background-image: none; }
  .moment-picker .moment-picker-container td.selected {
    background: #03A9F4;
    background-image: none; }
  .moment-picker .moment-picker-container .header-view th {
    border-radius: 0;
    white-space: nowrap;
    width: auto; }
    .moment-picker .moment-picker-container .header-view th:first-child, .moment-picker .moment-picker-container .header-view th:last-child {
      width: 20%; }

/*----------------------------------------------------------------*/
/*  @ Chart C3
/*----------------------------------------------------------------*/
.c3 {
  transition: all 300ms ease;
  /*-- Chart --*/
  /*-- Line --*/
  /*-- Point --*/
  /*-- Axis --*/
  /*-- Grid --*/
  /*-- Text on Chart --*/
  /*-- Bar --*/
  /*-- Focus --*/
  /*-- Region --*/
  /*-- Brush --*/
  /*-- Select - Drag --*/
  /*-- Legend --*/
  /*-- Tooltip --*/
  /*-- Area --*/
  /*-- Arc --*/ }
  .c3 svg {
    font: 12px RobotoDraft, Roboto, 'Helvetica Neue', sans-serif; }
  .c3 path, .c3 line {
    fill: none;
    stroke: #000; }
  .c3 text {
    font-family: "Roboto", "Helvetica Neue", "Arial", sans-serif;
    user-select: none; }
  .c3 .c3-legend-item-tile, .c3 .c3-xgrid-focus, .c3 .c3-ygrid, .c3 .c3-event-rect, .c3 .c3-bars path {
    shape-rendering: crispEdges; }
  .c3 .c3-chart-line {
    transition: all 300ms ease; }
  .c3 .c3-line {
    stroke-width: 1px; }
  .c3.stroke-3 .c3-line {
    stroke-width: 3px; }
  .c3.stroke-2 .c3-line {
    stroke-width: 2px; }
  .c3 .c3-circle {
    transition: all 300ms ease;
    stroke: white;
    stroke-width: 2; }
    .c3 .c3-circle._expanded_ {
      stroke-width: 3;
      stroke: white; }
  .c3 .c3-chart-arc path {
    stroke: #fff; }
  .c3 .c3-chart-arc text {
    fill: #fff;
    font-size: 13px; }
  .c3 .c3-grid line {
    stroke: rgba(0, 0, 0, 0.04);
    stroke-width: 1px;
    stroke-dasharray: 0; }
  .c3 .c3-grid text {
    fill: #aaa; }
  .c3 .c3-axis .tick {
    stroke-width: 0; }
  .c3 .c3-axis text {
    fill: rgba(0, 0, 0, 0.33); }
  .c3 .c3-axis line, .c3 .c3-axis path {
    stroke: rgba(0, 0, 0, 0.04); }
  .c3 .c3-xgrid, .c3 .c3-ygrid {
    stroke-dasharray: 0 0; }
  .c3 .c3-text.c3-empty {
    fill: #808080;
    font-size: 2em; }
  .c3 .c3-bar {
    stroke-width: 0; }
    .c3 .c3-bar._expanded_ {
      fill-opacity: 0.75; }
  .c3 .c3-target.c3-focused {
    opacity: 1; }
    .c3 .c3-target.c3-focused path.c3-line, .c3 .c3-target.c3-focused .c3-target.c3-focused path.c3-step {
      stroke-width: 3px;
      transition: all 300ms ease; }
  .c3 .c3-target.c3-defocused {
    opacity: 0.3 !important; }
  .c3 .c3-region {
    fill: steelblue;
    fill-opacity: 0.1; }
  .c3 .c3-brush .extent {
    fill-opacity: 0.1; }
  .c3 .c3-legend-item {
    font-size: 12px; }
  .c3 .c3-legend-item-hidden {
    opacity: 0.15; }
  .c3 .c3-legend-background {
    opacity: 0.75;
    fill: white;
    stroke: lightgray;
    stroke-width: 1; }
  .c3 .c3-tooltip-container {
    z-index: 10; }
  .c3 .c3-tooltip {
    border-collapse: collapse;
    border-spacing: 0;
    background-color: #fff;
    empty-cells: show;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.098), 0 1px 10px 0 rgba(0, 0, 0, 0.084);
    opacity: 1;
    border-radius: 2px;
    overflow: hidden;
    padding: 4px 0; }
    .c3 .c3-tooltip tr {
      border: none; }
    .c3 .c3-tooltip th {
      background-color: transparent;
      font-size: 14px;
      font-weight: 400;
      padding: 8px 12px;
      text-align: left;
      color: rgba(0, 0, 0, 0.54); }
    .c3 .c3-tooltip td {
      font-size: 13px;
      padding: 4px 12px !important;
      background-color: #fff;
      border-left: none;
      color: rgba(0, 0, 0, 0.84); }
      .c3 .c3-tooltip td > span {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 6px; }
      .c3 .c3-tooltip td.value {
        font-size: 14px !important;
        text-align: right;
        color: rgba(0, 0, 0, 0.84); }
  .c3 .c3-area {
    stroke-width: 0;
    opacity: 0.2; }
  .c3 .c3-chart-arcs-title {
    dominant-baseline: middle;
    font-size: 1.3em; }
  .c3 .c3-chart-arcs .c3-chart-arcs-background {
    fill: #e0e0e0;
    stroke: none; }
  .c3 .c3-chart-arcs .c3-chart-arcs-gauge-unit {
    fill: #000;
    font-size: 16px; }
  .c3 .c3-chart-arcs .c3-chart-arcs-gauge-max {
    fill: #777; }
  .c3 .c3-chart-arcs .c3-chart-arcs-gauge-min {
    fill: #777; }
  .c3 .c3-chart-arc .c3-gauge-value {
    fill: #000; }

.ct-label {
  font-size: 13px; }

.ct-chart-donut .ct-label,
.ct-chart-pie .ct-label {
  font-size: 13px; }

.chart-legend,
.bar-legend,
.line-legend,
.pie-legend,
.radar-legend,
.polararea-legend,
.doughnut-legend {
  margin-top: 15px; }

table.dataTable {
  width: 100%;
  margin: 0 auto !important;
  clear: both;
  border-spacing: 0; }
  table.dataTable thead tr {
    background: #FFFFFF; }
  table.dataTable thead th {
    text-align: left;
    vertical-align: middle;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 16px 8px; }
    table.dataTable thead th:first-child {
      padding-left: 24px; }
    table.dataTable thead th:last-child {
      border-right: none;
      padding-right: 24px; }
    table.dataTable thead th:active {
      outline: none; }
    table.dataTable thead th.dt-head-left {
      text-align: left; }
    table.dataTable thead th.dt-head-center {
      text-align: center; }
    table.dataTable thead th.dt-head-right {
      text-align: right; }
    table.dataTable thead th.dt-head-justify {
      text-align: justify; }
    table.dataTable thead th.dt-head-nowrap {
      white-space: nowrap; }
    table.dataTable thead th .table-header .column-title {
      display: block;
      font-weight: 600;
      white-space: nowrap; }
    table.dataTable thead th .table-header .selectize-control {
      margin-top: 15px; }
      table.dataTable thead th .table-header .selectize-control.single .selectize-input {
        padding: 7px 10px; }
        table.dataTable thead th .table-header .selectize-control.single .selectize-input:after {
          right: 10px;
          border-width: 5px 4px 0 4px;
          border-color: rgba(0, 0, 0, 0.12) transparent transparent transparent; }
    table.dataTable thead th .table-header .column-search-input {
      display: inline-block;
      margin-top: 15px;
      width: 100%;
      height: 33px; }
  table.dataTable thead td {
    padding: 15px;
    vertical-align: middle; }
    table.dataTable thead td:active {
      outline: none; }
    table.dataTable thead td.dt-head-left {
      text-align: left; }
    table.dataTable thead td.dt-head-center {
      text-align: center; }
    table.dataTable thead td.dt-head-right {
      text-align: right; }
    table.dataTable thead td.dt-head-justify {
      text-align: justify; }
    table.dataTable thead td.dt-head-nowrap {
      white-space: nowrap; }
  table.dataTable thead .sorting, table.dataTable thead .sorting_asc, table.dataTable thead .sorting_desc {
    background: none; }
    table.dataTable thead .sorting .table-header .column-title, table.dataTable thead .sorting_asc .table-header .column-title, table.dataTable thead .sorting_desc .table-header .column-title {
      cursor: pointer; }
      table.dataTable thead .sorting .table-header .column-title:after, table.dataTable thead .sorting_asc .table-header .column-title:after, table.dataTable thead .sorting_desc .table-header .column-title:after {
        position: relative;
        font-family: 'icomoon';
        font-weight: normal;
        margin-left: 10px;
        top: 2px;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.54); }
  table.dataTable thead .sorting .table-header .column-title:after {
    content: '\eaa8'; }
  table.dataTable thead .sorting_asc .table-header .column-title:after {
    content: '\e718'; }
  table.dataTable thead .sorting_desc .table-header .column-title:after {
    content: '\e715'; }
  table.dataTable tfoot th {
    font-weight: 600;
    text-align: left;
    padding: 10px 18px 6px 18px;
    border-top: 1px solid #111111; }
    table.dataTable tfoot th.dt-head-left {
      text-align: left; }
    table.dataTable tfoot th.dt-head-center {
      text-align: center; }
    table.dataTable tfoot th.dt-head-right {
      text-align: right; }
    table.dataTable tfoot th.dt-head-justify {
      text-align: justify; }
    table.dataTable tfoot th.dt-head-nowrap {
      white-space: nowrap; }
  table.dataTable tfoot td {
    padding: 10px 18px 6px 18px;
    border-top: 1px solid #111111; }
    table.dataTable tfoot td.dt-head-left {
      text-align: left; }
    table.dataTable tfoot td.dt-head-center {
      text-align: center; }
    table.dataTable tfoot td.dt-head-right {
      text-align: right; }
    table.dataTable tfoot td.dt-head-justify {
      text-align: justify; }
    table.dataTable tfoot td.dt-head-nowrap {
      white-space: nowrap; }
  table.dataTable tbody tr {
    background-color: #FFFFFF; }
    table.dataTable tbody tr.selected {
      background-color: #B0BED9; }
    table.dataTable tbody tr.details-open td.detail-column i {
      background: #FFFFFF; }
    table.dataTable tbody tr.details-row.odd tr {
      background: #FFFFFF; }
    table.dataTable tbody tr.details-row > td {
      padding: 0; }
  table.dataTable tbody th {
    padding: 14px 15px;
    vertical-align: middle; }
    table.dataTable tbody th.dt-body-left {
      text-align: left; }
    table.dataTable tbody th.dt-body-center {
      text-align: center; }
    table.dataTable tbody th.dt-body-right {
      text-align: right; }
    table.dataTable tbody th.dt-body-justify {
      text-align: justify; }
    table.dataTable tbody th.dt-body-nowrap {
      white-space: nowrap; }
  table.dataTable tbody td {
    padding: 16px 8px;
    vertical-align: middle; }
    table.dataTable tbody td:first-child {
      padding-left: 24px; }
    table.dataTable tbody td:last-child {
      padding-right: 24px; }
    table.dataTable tbody td.dt-body-left {
      text-align: left; }
    table.dataTable tbody td.dt-body-center {
      text-align: center; }
    table.dataTable tbody td.dt-body-right {
      text-align: right; }
    table.dataTable tbody td.dt-body-justify {
      text-align: justify; }
    table.dataTable tbody td.dt-body-nowrap {
      white-space: nowrap; }
  table.dataTable th.dt-left {
    text-align: left; }
  table.dataTable th.dt-center {
    text-align: center; }
  table.dataTable th.dt-right {
    text-align: right; }
  table.dataTable th.dt-justify {
    text-align: justify; }
  table.dataTable th.dt-nowrap {
    white-space: nowrap; }
  table.dataTable td.dt-left {
    text-align: left; }
  table.dataTable td.dt-center {
    text-align: center; }
  table.dataTable td.dataTables_empty {
    height: 51px;
    text-align: center; }
  table.dataTable td.dt-right {
    text-align: right; }
  table.dataTable td.dt-justify {
    text-align: justify; }
  table.dataTable td.dt-nowrap {
    white-space: nowrap; }
  table.dataTable.row-border thead {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
  table.dataTable.row-border tbody tr:first-child th {
    border-top: none; }
  table.dataTable.row-border tbody tr:first-child td {
    border-top: none; }
  table.dataTable.row-border tbody tr:last-child td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
  table.dataTable.row-border tbody th {
    border-top: 1px solid rgba(0, 0, 0, 0.12); }
  table.dataTable.row-border tbody td {
    border-top: 1px solid rgba(0, 0, 0, 0.12); }
  table.dataTable.cell-border tbody tr:first-child th {
    border-top: none; }
  table.dataTable.cell-border tbody tr:first-child td {
    border-top: none; }
  table.dataTable.cell-border tbody th {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-right: 1px solid rgba(0, 0, 0, 0.12); }
    table.dataTable.cell-border tbody th:last-child {
      border-right: none; }
  table.dataTable.cell-border tbody td {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-right: 1px solid rgba(0, 0, 0, 0.12); }
    table.dataTable.cell-border tbody td:last-child {
      border-right: none; }
  table.dataTable.stripe tbody tr.odd {
    background-color: #FFFFFF; }
    table.dataTable.stripe tbody tr.odd.selected {
      background-color: #ABB9D3; }
  table.dataTable.hover tbody tr:hover {
    background-color: whitesmoke; }
    table.dataTable.hover tbody tr:hover.selected {
      background-color: #A9B7D1; }
  table.dataTable.hover tbody tr.odd:hover {
    background-color: whitesmoke; }
    table.dataTable.hover tbody tr.odd:hover.selected {
      background-color: #A9B7D1; }
  table.dataTable.hover tbody tr.even:hover {
    background-color: whitesmoke; }
    table.dataTable.hover tbody tr.even:hover.selected {
      background-color: #A9B7D1; }
  table.dataTable.order-column tbody tr > .sorting_1 {
    background-color: #F9F9F9; }
  table.dataTable.order-column tbody tr > .sorting_2 {
    background-color: #F9F9F9; }
  table.dataTable.order-column tbody tr > .sorting_3 {
    background-color: #F9F9F9; }
  table.dataTable.order-column tbody tr.selected > .sorting_1 {
    background-color: #ACBAD4; }
  table.dataTable.order-column tbody tr.selected > .sorting_2 {
    background-color: #ACBAD4; }
  table.dataTable.order-column tbody tr.selected > .sorting_3 {
    background-color: #ACBAD4; }
  table.dataTable.order-column.stripe tbody tr.odd > .sorting_1 {
    background-color: #F1F1F1; }
  table.dataTable.order-column.stripe tbody tr.odd > .sorting_2 {
    background-color: #F3F3F3; }
  table.dataTable.order-column.stripe tbody tr.odd > .sorting_3 {
    background-color: whitesmoke; }
  table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_1 {
    background-color: #A6B3CD; }
  table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_2 {
    background-color: #A7B5CE; }
  table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_3 {
    background-color: #A9B6D0; }
  table.dataTable.order-column.stripe tbody tr.even > .sorting_1 {
    background-color: #F9F9F9; }
  table.dataTable.order-column.stripe tbody tr.even > .sorting_2 {
    background-color: #FBFBFB; }
  table.dataTable.order-column.stripe tbody tr.even > .sorting_3 {
    background-color: #FDFDFD; }
  table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_1 {
    background-color: #ACBAD4; }
  table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_2 {
    background-color: #ADBBD6; }
  table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_3 {
    background-color: #AFBDD8; }
  table.dataTable.order-column.hover tbody tr:hover > .sorting_1 {
    background-color: #EAEAEA; }
  table.dataTable.order-column.hover tbody tr:hover > .sorting_2 {
    background-color: #EBEBEB; }
  table.dataTable.order-column.hover tbody tr:hover > .sorting_3 {
    background-color: #EEEEEE; }
  table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_1 {
    background-color: #A1AEC7; }
  table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_2 {
    background-color: #A2AFC8; }
  table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_3 {
    background-color: #A4B2CB; }
  table.dataTable.order-column.hover tbody tr.odd:hover > .sorting_1 {
    background-color: #EAEAEA; }
  table.dataTable.order-column.hover tbody tr.odd:hover > .sorting_2 {
    background-color: #EBEBEB; }
  table.dataTable.order-column.hover tbody tr.odd:hover > .sorting_3 {
    background-color: #EEEEEE; }
  table.dataTable.order-column.hover tbody tr.odd:hover.selected > .sorting_1 {
    background-color: #A1AEC7; }
  table.dataTable.order-column.hover tbody tr.odd:hover.selected > .sorting_2 {
    background-color: #A2AFC8; }
  table.dataTable.order-column.hover tbody tr.odd:hover.selected > .sorting_3 {
    background-color: #A4B2CB; }
  table.dataTable.order-column.hover tbody tr.even:hover > .sorting_1 {
    background-color: #EAEAEA; }
  table.dataTable.order-column.hover tbody tr.even:hover > .sorting_2 {
    background-color: #EBEBEB; }
  table.dataTable.order-column.hover tbody tr.even:hover > .sorting_3 {
    background-color: #EEEEEE; }
  table.dataTable.order-column.hover tbody tr.even:hover.selected > .sorting_1 {
    background-color: #A1AEC7; }
  table.dataTable.order-column.hover tbody tr.even:hover.selected > .sorting_2 {
    background-color: #A2AFC8; }
  table.dataTable.order-column.hover tbody tr.even:hover.selected > .sorting_3 {
    background-color: #A4B2CB; }
  table.dataTable.no-footer {
    border-bottom: none; }
  table.dataTable.nowrap th {
    white-space: nowrap; }
  table.dataTable.nowrap td {
    white-space: nowrap; }
  table.dataTable.compact thead th {
    padding: 5px 9px; }
  table.dataTable.compact thead td {
    padding: 5px 9px; }
  table.dataTable.compact tfoot th {
    padding: 5px 9px 3px 9px; }
  table.dataTable.compact tfoot td {
    padding: 5px 9px 3px 9px; }
  table.dataTable.compact tbody th {
    padding: 4px 5px; }
  table.dataTable.compact tbody td {
    padding: 4px 5px; }

/*
 * Control feature layout
 */
.dataTables_wrapper {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  min-height: 0;
  padding: 0; }
  .dataTables_wrapper .top {
    padding: 16px 24px; }
  .dataTables_wrapper .bottom {
    display: flex;
    min-height: 64px;
    justify-content: space-between;
    padding: 16px 8px; }
    .dataTables_wrapper .bottom .left {
      display: flex;
      flex: 0 0 50%;
      max-width: 50%;
      align-self: center;
      padding: 0 8px; }
      .dataTables_wrapper .bottom .left .length {
        display: block; }
    .dataTables_wrapper .bottom .right {
      display: flex;
      flex: 0 0 50%;
      max-width: 50%;
      align-self: center;
      justify-content: flex-end;
      padding: 0 8px; }
      .dataTables_wrapper .bottom .right .info {
        display: block;
        align-self: center; }
        .dataTables_wrapper .bottom .right .info .dataTables_info {
          padding: 0 8px;
          font-weight: 600; }
      .dataTables_wrapper .bottom .right .pagination {
        display: block;
        align-self: center;
        white-space: nowrap; }
        .dataTables_wrapper .bottom .right .pagination .dataTables_paginate {
          padding: 0;
          margin: 0 !important; }
  .dataTables_wrapper .dataTables_filter {
    float: right; }
    .dataTables_wrapper .dataTables_filter input {
      margin-left: 0.5em;
      border: 1px solid rgba(0, 0, 0, 0.12);
      height: 24px;
      padding: 4px 8px; }
  .dataTables_wrapper .dataTables_paginate .paginate_button {
    background: #FFFFFF;
    color: rgba(0, 0, 0, 0.87);
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 9px 12px;
    border-radius: 2px;
    cursor: pointer;
    user-select: none;
    margin: 0 2px; }
    .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
      box-shadow: inset 999em 0 0 0 rgba(0, 0, 0, 0.05);
      color: rgba(0, 0, 0, 0.87) !important;
      border: 1px solid rgba(0, 0, 0, 0.12) !important;
      background: none; }
    .dataTables_wrapper .dataTables_paginate .paginate_button:active {
      box-shadow: inset 999em 0 0 0 rgba(0, 0, 0, 0.08), inset 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 0 0 rgba(255, 255, 255, 0.35); }
    .dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
      color: rgba(0, 0, 0, 0.54) !important;
      border-color: rgba(0, 0, 0, 0.12) !important;
      background: #FFFFFF !important;
      box-shadow: none !important;
      cursor: default; }
  .dataTables_wrapper .dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 40px;
    margin-left: -50%;
    margin-top: -25px;
    padding-top: 20px;
    text-align: center;
    font-size: 1.2em;
    background-color: white;
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(25%, rgba(255, 255, 255, 0.9)), color-stop(75%, rgba(255, 255, 255, 0.9)), color-stop(100%, rgba(255, 255, 255, 0)));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    /* Chrome10+,Safari5.1+ */
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    /* FF3.6+ */
    background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    /* IE10+ */
    background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    /* Opera 11.10+ */
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    /* W3C */ }
  .dataTables_wrapper .dataTables_scroll {
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    clear: both;
    min-height: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    /* Clear the table border & shadows */ }
    .dataTables_wrapper .dataTables_scroll table.dataTable {
      box-shadow: none; }
      .dataTables_wrapper .dataTables_scroll table.dataTable.row-border thead {
        border: none; }
    .dataTables_wrapper .dataTables_scroll .dataTables_scrollHead {
      display: flex;
      min-height: 56px;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.095), 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 2px 1px 0 rgba(0, 0, 0, 0.05);
      background: #FFFFFF;
      overflow: visible !important; }
      .dataTables_wrapper .dataTables_scroll .dataTables_scrollHead tr th:last-child .table-header .column-title {
        position: relative;
        left: 7px; }
    .dataTables_wrapper .dataTables_scroll .dataTables_scrollBody {
      display: flex;
      overflow-x: hidden !important;
      overflow-y: scroll !important;
      -webkit-overflow-scrolling: touch; }
      .dataTables_wrapper .dataTables_scroll .dataTables_scrollBody th > .dataTables_sizing {
        height: 0;
        overflow: hidden;
        margin: 0 !important;
        padding: 0 !important; }
      .dataTables_wrapper .dataTables_scroll .dataTables_scrollBody td > .dataTables_sizing {
        height: 0;
        overflow: hidden;
        margin: 0 !important;
        padding: 0 !important; }
  .dataTables_wrapper.no-footer .dataTables_scrollBody {
    border-bottom: none; }
  .dataTables_wrapper.no-footer .dataTables_scrollHead table,
  .dataTables_wrapper.no-footer .dataTables_scrollBody table {
    border-top: none;
    border-bottom: none;
    margin: 0; }
  .dataTables_wrapper:after {
    visibility: hidden;
    display: block;
    content: "";
    clear: both;
    height: 0; }

/* DataTable Specific Content Rules & Fixes */
table.dataTable .show-details {
  padding: 5px 10px;
  font-weight: bold; }

table.dataTable .action-buttons {
  display: flex;
  justify-content: center; }
  table.dataTable .action-buttons .button {
    padding: 8px; }

/* Fullpage datatable */
.fullpage-datatable .dataTables_wrapper {
  height: 100%;
  max-height: 100%;
  padding: 0; }

/* Fullpage datatable IE11 Fix */
.explorer-11 .fullpage-datatable .dataTables_scroll {
  flex: 1; }

/* Responsive datatable */
table.dataTable.dtr-inline.collapsed.dtr-inline.collapsed > tbody > tr > td:first-child,
table.dataTable.dtr-inline.collapsed.dtr-inline.collapsed > tbody > tr > th:first-child {
  padding-left: 40px; }
  table.dataTable.dtr-inline.collapsed.dtr-inline.collapsed > tbody > tr > td:first-child:before,
  table.dataTable.dtr-inline.collapsed.dtr-inline.collapsed > tbody > tr > th:first-child:before {
    content: '+';
    width: 15px;
    height: 15px;
    line-height: 15px;
    top: 50%;
    margin-top: -7px;
    left: 8px;
    color: rgba(0, 0, 0, 0.54);
    background: none;
    border: none;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.54);
    font-weight: bold; }

table.dataTable.dtr-inline.collapsed.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before,
table.dataTable.dtr-inline.collapsed.dtr-inline.collapsed > tbody > tr.parent > th:first-child:before {
  background: none; }

table.dataTable.dtr-inline.collapsed > tbody > tr.child ul {
  display: block; }
  table.dataTable.dtr-inline.collapsed > tbody > tr.child ul li {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 8px 0; }
    table.dataTable.dtr-inline.collapsed > tbody > tr.child ul li:first-child {
      padding-top: 0; }
    table.dataTable.dtr-inline.collapsed > tbody > tr.child ul li:last-child {
      border-bottom: none;
      padding-bottom: 0; }
    table.dataTable.dtr-inline.collapsed > tbody > tr.child ul li .dtr-title {
      margin-right: 8px; }

/*

github.com style (c) Vasily Polovnyov <vast@whiteants.net>

*/
hljs,
[hljs] {
  display: block;
  overflow-x: auto;
  color: #333;
  background: #f8f8f8;
  -webkit-text-size-adjust: none; }

.hljs-comment,
.diff .hljs-header {
  color: #998;
  font-style: italic; }

.hljs-keyword,
.css .rule .hljs-keyword,
.hljs-winutils,
.nginx .hljs-title,
.hljs-subst,
.hljs-request,
.hljs-status {
  color: #333;
  font-weight: bold; }

.hljs-number,
.hljs-hexcolor,
.ruby .hljs-constant {
  color: #008080; }

.hljs-string,
.hljs-tag .hljs-value,
.hljs-doctag,
.tex .hljs-formula {
  color: #d14; }

.hljs-title,
.hljs-id,
.scss .hljs-preprocessor {
  color: #900;
  font-weight: bold; }

.hljs-list .hljs-keyword,
.hljs-subst {
  font-weight: normal; }

.hljs-class .hljs-title,
.hljs-type,
.vhdl .hljs-literal,
.tex .hljs-command {
  color: #458;
  font-weight: bold; }

.hljs-tag,
.hljs-tag .hljs-title,
.hljs-rule .hljs-property,
.django .hljs-tag .hljs-keyword {
  color: #000080;
  font-weight: normal; }

.hljs-attribute,
.hljs-variable,
.lisp .hljs-body,
.hljs-name {
  color: #008080; }

.hljs-regexp {
  color: #009926; }

.hljs-symbol,
.ruby .hljs-symbol .hljs-string,
.lisp .hljs-keyword,
.clojure .hljs-keyword,
.scheme .hljs-keyword,
.tex .hljs-special,
.hljs-prompt {
  color: #990073; }

.hljs-built_in {
  color: #0086b3; }

.hljs-preprocessor,
.hljs-pragma,
.hljs-pi,
.hljs-doctype,
.hljs-shebang,
.hljs-cdata {
  color: #999;
  font-weight: bold; }

.hljs-deletion {
  background: #fdd; }

.hljs-addition {
  background: #dfd; }

.diff .hljs-change {
  background: #0086b3; }

.hljs-chunk {
  color: #aaa; }

nvd3 {
  display: block;
  width: 100%;
  height: 100%; }
  nvd3.remove-x-lines .nv-x .tick line {
    display: none; }
  nvd3.remove-y-lines .nv-y .tick line {
    display: none; }
  nvd3.remove-line-stroke .nv-groups path.nv-line {
    stroke-width: 0 !important; }
  nvd3.remove-opacity .nv-groups .nv-group {
    fill-opacity: 1 !important; }
  nvd3.show-line-points .nv-line .nv-scatter .nv-groups .nv-point {
    fill-opacity: 1 !important;
    stroke-opacity: 1 !important; }

.nvd3 text {
  font-family: "Roboto", "Helvetica Neue", "Arial", sans-serif; }

.nvd3 line.nv-guideline {
  stroke: rgba(0, 0, 0, 0.54); }

.nvd3 .nv-groups .nv-point.hover {
  stroke-width: 3px !important;
  fill-opacity: 1 !important;
  stroke-opacity: 1 !important; }

.nvtooltip {
  background: none;
  color: white;
  padding: 0;
  border: none; }
  .nvtooltip.gravity-n:after {
    display: block;
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    left: 50%;
    bottom: 100%;
    margin-left: -5px;
    border: 5px solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.87); }
  .nvtooltip.gravity-s:after {
    display: block;
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border: 5px solid transparent;
    border-top-color: rgba(0, 0, 0, 0.87); }
  .nvtooltip.gravity-e:after {
    display: block;
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    top: 50%;
    right: 0;
    margin-top: -6px;
    margin-right: -11px;
    border: 6px solid transparent;
    border-left-color: rgba(0, 0, 0, 0.87); }
  .nvtooltip.gravity-w:after {
    display: block;
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    top: 50%;
    margin-top: -6px;
    margin-left: -11px;
    border: 6px solid transparent;
    border-right-color: rgba(0, 0, 0, 0.87); }
  .nvtooltip table {
    background: rgba(0, 0, 0, 0.87);
    padding: 8px 12px;
    margin: 0;
    border-radius: 2px; }
    .nvtooltip table tbody tr td.legend-color-guide div {
      border: none; }
    .nvtooltip table tbody tr td:last-child {
      padding-right: 0; }

.ps-container:hover .ps-in-scrolling {
  pointer-events: auto; }

.ps-container .ps-in-scrolling {
  pointer-events: auto; }

.ps-container > .ps-scrollbar-x-rail {
  background: none !important;
  margin: 4px 0;
  height: 4px;
  z-index: 49; }
  .ps-container > .ps-scrollbar-x-rail > .ps-scrollbar-x {
    height: 4px; }

.ps-container > .ps-scrollbar-y-rail {
  background: none !important;
  margin: 4px 0;
  width: 4px;
  z-index: 49; }
  .ps-container > .ps-scrollbar-y-rail > .ps-scrollbar-y {
    width: 4px; }

.ta-root {
  display: block; }
  .ta-root .ta-scroll-window.form-control {
    border: 1px solid rgba(0, 0, 0, 0.12); }
  .ta-root.focussed > .ta-scroll-window.form-control {
    outline: 0px solid transparent;
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: none; }
  .ta-root textarea.ta-editor.ta-html {
    resize: vertical;
    width: 100% !important;
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 16px; }
  .ta-root .ta-toolbar {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: none;
    background: #F5F5F5; }
    .ta-root .ta-toolbar .ta-group {
      display: flex;
      align-items: center;
      align-content: center;
      flex-wrap: wrap; }
      .ta-root .ta-toolbar .ta-group #toolbarWC,
      .ta-root .ta-toolbar .ta-group #toolbarCC {
        display: inline-block !important;
        line-height: 24px; }
      .ta-root .ta-toolbar .ta-group .md-button {
        width: 40px;
        min-width: 40px;
        line-height: 14px;
        height: 36px;
        min-height: 36px;
        padding: 6px;
        margin: 4px 0; }
        .ta-root .ta-toolbar .ta-group .md-button i {
          font-size: 20px;
          width: 20px;
          height: 20px;
          line-height: 20px; }

/*----------------------------------------------------------------*/
/*  Helpers
/*----------------------------------------------------------------*/
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important; }

.no-padding {
  padding: 0 !important; }

.no-margin {
  margin: 0 !important; }

.no-radius {
  border-radius: 0; }

.divider-vertical {
  background: rgba(0, 0, 0, 0.12);
  height: 24px;
  width: 1px;
  margin: 0 8px; }

.hidden {
  visibility: hidden;
  opacity: 0; }

[fill-width] {
  min-width: 100%; }

[fill-height] {
  min-height: 100%; }

.full-height {
  height: 100% !important; }

a img {
  display: block;
  outline: none; }

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
  border: none; }

.scrollable {
  display: block;
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }
  .scrollable.only-x {
    overflow-x: auto;
    overflow-y: hidden; }
  .scrollable.only-y {
    overflow-x: hidden;
    overflow-y: auto; }

.flex-scrollable {
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }
  .flex-scrollable.only-x {
    overflow-x: auto;
    overflow-y: hidden; }
  .flex-scrollable.only-y {
    overflow-x: hidden;
    overflow-y: auto; }

.background-image-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1; }

.align-vertical:before {
  display: inline-block;
  height: 100%;
  content: '';
  vertical-align: middle; }

.align-vertical .align-vertical-top,
.align-vertical .align-vertical-middle,
.align-vertical .align-vertical-bottom {
  display: inline-block; }

.align-vertical .align-vertical-top {
  vertical-align: top; }

.align-vertical .align-vertical-middle {
  vertical-align: middle; }

.align-vertical .align-vertical-bottom {
  vertical-align: bottom; }

.padding-0,
.p-0 {
  padding: 0px; }

.padding-top-0,
.pt-0 {
  padding-top: 0px; }

.padding-right-0,
.pr-0 {
  padding-right: 0px; }

.padding-bottom-0,
.pb-0 {
  padding-bottom: 0px; }

.padding-left-0,
.pl-0 {
  padding-left: 0px; }

.padding-horizontal-0,
.ph-0 {
  padding-left: 0px;
  padding-right: 0px; }

.padding-vertical-0,
.pv-0 {
  padding-top: 0px;
  padding-bottom: 0px; }

.padding-5,
.p-5 {
  padding: 5px; }

.padding-top-5,
.pt-5 {
  padding-top: 5px; }

.padding-right-5,
.pr-5 {
  padding-right: 5px; }

.padding-bottom-5,
.pb-5 {
  padding-bottom: 5px; }

.padding-left-5,
.pl-5 {
  padding-left: 5px; }

.padding-horizontal-5,
.ph-5 {
  padding-left: 5px;
  padding-right: 5px; }

.padding-vertical-5,
.pv-5 {
  padding-top: 5px;
  padding-bottom: 5px; }

.padding-10,
.p-10 {
  padding: 10px; }

.padding-top-10,
.pt-10 {
  padding-top: 10px; }

.padding-right-10,
.pr-10 {
  padding-right: 10px; }

.padding-bottom-10,
.pb-10 {
  padding-bottom: 10px; }

.padding-left-10,
.pl-10 {
  padding-left: 10px; }

.padding-horizontal-10,
.ph-10 {
  padding-left: 10px;
  padding-right: 10px; }

.padding-vertical-10,
.pv-10 {
  padding-top: 10px;
  padding-bottom: 10px; }

.padding-15,
.p-15 {
  padding: 15px; }

.padding-top-15,
.pt-15 {
  padding-top: 15px; }

.padding-right-15,
.pr-15 {
  padding-right: 15px; }

.padding-bottom-15,
.pb-15 {
  padding-bottom: 15px; }

.padding-left-15,
.pl-15 {
  padding-left: 15px; }

.padding-horizontal-15,
.ph-15 {
  padding-left: 15px;
  padding-right: 15px; }

.padding-vertical-15,
.pv-15 {
  padding-top: 15px;
  padding-bottom: 15px; }

.padding-20,
.p-20 {
  padding: 20px; }

.padding-top-20,
.pt-20 {
  padding-top: 20px; }

.padding-right-20,
.pr-20 {
  padding-right: 20px; }

.padding-bottom-20,
.pb-20 {
  padding-bottom: 20px; }

.padding-left-20,
.pl-20 {
  padding-left: 20px; }

.padding-horizontal-20,
.ph-20 {
  padding-left: 20px;
  padding-right: 20px; }

.padding-vertical-20,
.pv-20 {
  padding-top: 20px;
  padding-bottom: 20px; }

.padding-25,
.p-25 {
  padding: 25px; }

.padding-top-25,
.pt-25 {
  padding-top: 25px; }

.padding-right-25,
.pr-25 {
  padding-right: 25px; }

.padding-bottom-25,
.pb-25 {
  padding-bottom: 25px; }

.padding-left-25,
.pl-25 {
  padding-left: 25px; }

.padding-horizontal-25,
.ph-25 {
  padding-left: 25px;
  padding-right: 25px; }

.padding-vertical-25,
.pv-25 {
  padding-top: 25px;
  padding-bottom: 25px; }

.padding-30,
.p-30 {
  padding: 30px; }

.padding-top-30,
.pt-30 {
  padding-top: 30px; }

.padding-right-30,
.pr-30 {
  padding-right: 30px; }

.padding-bottom-30,
.pb-30 {
  padding-bottom: 30px; }

.padding-left-30,
.pl-30 {
  padding-left: 30px; }

.padding-horizontal-30,
.ph-30 {
  padding-left: 30px;
  padding-right: 30px; }

.padding-vertical-30,
.pv-30 {
  padding-top: 30px;
  padding-bottom: 30px; }

.padding-35,
.p-35 {
  padding: 35px; }

.padding-top-35,
.pt-35 {
  padding-top: 35px; }

.padding-right-35,
.pr-35 {
  padding-right: 35px; }

.padding-bottom-35,
.pb-35 {
  padding-bottom: 35px; }

.padding-left-35,
.pl-35 {
  padding-left: 35px; }

.padding-horizontal-35,
.ph-35 {
  padding-left: 35px;
  padding-right: 35px; }

.padding-vertical-35,
.pv-35 {
  padding-top: 35px;
  padding-bottom: 35px; }

.padding-40,
.p-40 {
  padding: 40px; }

.padding-top-40,
.pt-40 {
  padding-top: 40px; }

.padding-right-40,
.pr-40 {
  padding-right: 40px; }

.padding-bottom-40,
.pb-40 {
  padding-bottom: 40px; }

.padding-left-40,
.pl-40 {
  padding-left: 40px; }

.padding-horizontal-40,
.ph-40 {
  padding-left: 40px;
  padding-right: 40px; }

.padding-vertical-40,
.pv-40 {
  padding-top: 40px;
  padding-bottom: 40px; }

.padding-45,
.p-45 {
  padding: 45px; }

.padding-top-45,
.pt-45 {
  padding-top: 45px; }

.padding-right-45,
.pr-45 {
  padding-right: 45px; }

.padding-bottom-45,
.pb-45 {
  padding-bottom: 45px; }

.padding-left-45,
.pl-45 {
  padding-left: 45px; }

.padding-horizontal-45,
.ph-45 {
  padding-left: 45px;
  padding-right: 45px; }

.padding-vertical-45,
.pv-45 {
  padding-top: 45px;
  padding-bottom: 45px; }

.padding-50,
.p-50 {
  padding: 50px; }

.padding-top-50,
.pt-50 {
  padding-top: 50px; }

.padding-right-50,
.pr-50 {
  padding-right: 50px; }

.padding-bottom-50,
.pb-50 {
  padding-bottom: 50px; }

.padding-left-50,
.pl-50 {
  padding-left: 50px; }

.padding-horizontal-50,
.ph-50 {
  padding-left: 50px;
  padding-right: 50px; }

.padding-vertical-50,
.pv-50 {
  padding-top: 50px;
  padding-bottom: 50px; }

.padding-55,
.p-55 {
  padding: 55px; }

.padding-top-55,
.pt-55 {
  padding-top: 55px; }

.padding-right-55,
.pr-55 {
  padding-right: 55px; }

.padding-bottom-55,
.pb-55 {
  padding-bottom: 55px; }

.padding-left-55,
.pl-55 {
  padding-left: 55px; }

.padding-horizontal-55,
.ph-55 {
  padding-left: 55px;
  padding-right: 55px; }

.padding-vertical-55,
.pv-55 {
  padding-top: 55px;
  padding-bottom: 55px; }

.padding-60,
.p-60 {
  padding: 60px; }

.padding-top-60,
.pt-60 {
  padding-top: 60px; }

.padding-right-60,
.pr-60 {
  padding-right: 60px; }

.padding-bottom-60,
.pb-60 {
  padding-bottom: 60px; }

.padding-left-60,
.pl-60 {
  padding-left: 60px; }

.padding-horizontal-60,
.ph-60 {
  padding-left: 60px;
  padding-right: 60px; }

.padding-vertical-60,
.pv-60 {
  padding-top: 60px;
  padding-bottom: 60px; }

.padding-65,
.p-65 {
  padding: 65px; }

.padding-top-65,
.pt-65 {
  padding-top: 65px; }

.padding-right-65,
.pr-65 {
  padding-right: 65px; }

.padding-bottom-65,
.pb-65 {
  padding-bottom: 65px; }

.padding-left-65,
.pl-65 {
  padding-left: 65px; }

.padding-horizontal-65,
.ph-65 {
  padding-left: 65px;
  padding-right: 65px; }

.padding-vertical-65,
.pv-65 {
  padding-top: 65px;
  padding-bottom: 65px; }

.padding-70,
.p-70 {
  padding: 70px; }

.padding-top-70,
.pt-70 {
  padding-top: 70px; }

.padding-right-70,
.pr-70 {
  padding-right: 70px; }

.padding-bottom-70,
.pb-70 {
  padding-bottom: 70px; }

.padding-left-70,
.pl-70 {
  padding-left: 70px; }

.padding-horizontal-70,
.ph-70 {
  padding-left: 70px;
  padding-right: 70px; }

.padding-vertical-70,
.pv-70 {
  padding-top: 70px;
  padding-bottom: 70px; }

.padding-75,
.p-75 {
  padding: 75px; }

.padding-top-75,
.pt-75 {
  padding-top: 75px; }

.padding-right-75,
.pr-75 {
  padding-right: 75px; }

.padding-bottom-75,
.pb-75 {
  padding-bottom: 75px; }

.padding-left-75,
.pl-75 {
  padding-left: 75px; }

.padding-horizontal-75,
.ph-75 {
  padding-left: 75px;
  padding-right: 75px; }

.padding-vertical-75,
.pv-75 {
  padding-top: 75px;
  padding-bottom: 75px; }

.padding-80,
.p-80 {
  padding: 80px; }

.padding-top-80,
.pt-80 {
  padding-top: 80px; }

.padding-right-80,
.pr-80 {
  padding-right: 80px; }

.padding-bottom-80,
.pb-80 {
  padding-bottom: 80px; }

.padding-left-80,
.pl-80 {
  padding-left: 80px; }

.padding-horizontal-80,
.ph-80 {
  padding-left: 80px;
  padding-right: 80px; }

.padding-vertical-80,
.pv-80 {
  padding-top: 80px;
  padding-bottom: 80px; }

.padding-85,
.p-85 {
  padding: 85px; }

.padding-top-85,
.pt-85 {
  padding-top: 85px; }

.padding-right-85,
.pr-85 {
  padding-right: 85px; }

.padding-bottom-85,
.pb-85 {
  padding-bottom: 85px; }

.padding-left-85,
.pl-85 {
  padding-left: 85px; }

.padding-horizontal-85,
.ph-85 {
  padding-left: 85px;
  padding-right: 85px; }

.padding-vertical-85,
.pv-85 {
  padding-top: 85px;
  padding-bottom: 85px; }

.padding-90,
.p-90 {
  padding: 90px; }

.padding-top-90,
.pt-90 {
  padding-top: 90px; }

.padding-right-90,
.pr-90 {
  padding-right: 90px; }

.padding-bottom-90,
.pb-90 {
  padding-bottom: 90px; }

.padding-left-90,
.pl-90 {
  padding-left: 90px; }

.padding-horizontal-90,
.ph-90 {
  padding-left: 90px;
  padding-right: 90px; }

.padding-vertical-90,
.pv-90 {
  padding-top: 90px;
  padding-bottom: 90px; }

.padding-95,
.p-95 {
  padding: 95px; }

.padding-top-95,
.pt-95 {
  padding-top: 95px; }

.padding-right-95,
.pr-95 {
  padding-right: 95px; }

.padding-bottom-95,
.pb-95 {
  padding-bottom: 95px; }

.padding-left-95,
.pl-95 {
  padding-left: 95px; }

.padding-horizontal-95,
.ph-95 {
  padding-left: 95px;
  padding-right: 95px; }

.padding-vertical-95,
.pv-95 {
  padding-top: 95px;
  padding-bottom: 95px; }

.padding-100,
.p-100 {
  padding: 100px; }

.padding-top-100,
.pt-100 {
  padding-top: 100px; }

.padding-right-100,
.pr-100 {
  padding-right: 100px; }

.padding-bottom-100,
.pb-100 {
  padding-bottom: 100px; }

.padding-left-100,
.pl-100 {
  padding-left: 100px; }

.padding-horizontal-100,
.ph-100 {
  padding-left: 100px;
  padding-right: 100px; }

.padding-vertical-100,
.pv-100 {
  padding-top: 100px;
  padding-bottom: 100px; }

.padding-8,
.p-8 {
  padding: 8px; }

.padding-top-8,
.pt-8 {
  padding-top: 8px; }

.padding-right-8,
.pr-8 {
  padding-right: 8px; }

.padding-bottom-8,
.pb-8 {
  padding-bottom: 8px; }

.padding-left-8,
.pl-8 {
  padding-left: 8px; }

.padding-horizontal-8,
.ph-8 {
  padding-left: 8px;
  padding-right: 8px; }

.padding-vertical-8,
.pv-8 {
  padding-top: 8px;
  padding-bottom: 8px; }

.padding-16,
.p-16 {
  padding: 16px; }

.padding-top-16,
.pt-16 {
  padding-top: 16px; }

.padding-right-16,
.pr-16 {
  padding-right: 16px; }

.padding-bottom-16,
.pb-16 {
  padding-bottom: 16px; }

.padding-left-16,
.pl-16 {
  padding-left: 16px; }

.padding-horizontal-16,
.ph-16 {
  padding-left: 16px;
  padding-right: 16px; }

.padding-vertical-16,
.pv-16 {
  padding-top: 16px;
  padding-bottom: 16px; }

.padding-24,
.p-24 {
  padding: 24px; }

.padding-top-24,
.pt-24 {
  padding-top: 24px; }

.padding-right-24,
.pr-24 {
  padding-right: 24px; }

.padding-bottom-24,
.pb-24 {
  padding-bottom: 24px; }

.padding-left-24,
.pl-24 {
  padding-left: 24px; }

.padding-horizontal-24,
.ph-24 {
  padding-left: 24px;
  padding-right: 24px; }

.padding-vertical-24,
.pv-24 {
  padding-top: 24px;
  padding-bottom: 24px; }

.padding-32,
.p-32 {
  padding: 32px; }

.padding-top-32,
.pt-32 {
  padding-top: 32px; }

.padding-right-32,
.pr-32 {
  padding-right: 32px; }

.padding-bottom-32,
.pb-32 {
  padding-bottom: 32px; }

.padding-left-32,
.pl-32 {
  padding-left: 32px; }

.padding-horizontal-32,
.ph-32 {
  padding-left: 32px;
  padding-right: 32px; }

.padding-vertical-32,
.pv-32 {
  padding-top: 32px;
  padding-bottom: 32px; }

.padding-48,
.p-48 {
  padding: 48px; }

.padding-top-48,
.pt-48 {
  padding-top: 48px; }

.padding-right-48,
.pr-48 {
  padding-right: 48px; }

.padding-bottom-48,
.pb-48 {
  padding-bottom: 48px; }

.padding-left-48,
.pl-48 {
  padding-left: 48px; }

.padding-horizontal-48,
.ph-48 {
  padding-left: 48px;
  padding-right: 48px; }

.padding-vertical-48,
.pv-48 {
  padding-top: 48px;
  padding-bottom: 48px; }

.padding-56,
.p-56 {
  padding: 56px; }

.padding-top-56,
.pt-56 {
  padding-top: 56px; }

.padding-right-56,
.pr-56 {
  padding-right: 56px; }

.padding-bottom-56,
.pb-56 {
  padding-bottom: 56px; }

.padding-left-56,
.pl-56 {
  padding-left: 56px; }

.padding-horizontal-56,
.ph-56 {
  padding-left: 56px;
  padding-right: 56px; }

.padding-vertical-56,
.pv-56 {
  padding-top: 56px;
  padding-bottom: 56px; }

.padding-64,
.p-64 {
  padding: 64px; }

.padding-top-64,
.pt-64 {
  padding-top: 64px; }

.padding-right-64,
.pr-64 {
  padding-right: 64px; }

.padding-bottom-64,
.pb-64 {
  padding-bottom: 64px; }

.padding-left-64,
.pl-64 {
  padding-left: 64px; }

.padding-horizontal-64,
.ph-64 {
  padding-left: 64px;
  padding-right: 64px; }

.padding-vertical-64,
.pv-64 {
  padding-top: 64px;
  padding-bottom: 64px; }

.padding-72,
.p-72 {
  padding: 72px; }

.padding-top-72,
.pt-72 {
  padding-top: 72px; }

.padding-right-72,
.pr-72 {
  padding-right: 72px; }

.padding-bottom-72,
.pb-72 {
  padding-bottom: 72px; }

.padding-left-72,
.pl-72 {
  padding-left: 72px; }

.padding-horizontal-72,
.ph-72 {
  padding-left: 72px;
  padding-right: 72px; }

.padding-vertical-72,
.pv-72 {
  padding-top: 72px;
  padding-bottom: 72px; }

.padding-88,
.p-88 {
  padding: 88px; }

.padding-top-88,
.pt-88 {
  padding-top: 88px; }

.padding-right-88,
.pr-88 {
  padding-right: 88px; }

.padding-bottom-88,
.pb-88 {
  padding-bottom: 88px; }

.padding-left-88,
.pl-88 {
  padding-left: 88px; }

.padding-horizontal-88,
.ph-88 {
  padding-left: 88px;
  padding-right: 88px; }

.padding-vertical-88,
.pv-88 {
  padding-top: 88px;
  padding-bottom: 88px; }

.padding-96,
.p-96 {
  padding: 96px; }

.padding-top-96,
.pt-96 {
  padding-top: 96px; }

.padding-right-96,
.pr-96 {
  padding-right: 96px; }

.padding-bottom-96,
.pb-96 {
  padding-bottom: 96px; }

.padding-left-96,
.pl-96 {
  padding-left: 96px; }

.padding-horizontal-96,
.ph-96 {
  padding-left: 96px;
  padding-right: 96px; }

.padding-vertical-96,
.pv-96 {
  padding-top: 96px;
  padding-bottom: 96px; }

.margin-0,
.m-0 {
  margin: 0px; }

.margin-top-0,
.mt-0 {
  margin-top: 0px; }

.margin-right-0,
.mr-0 {
  margin-right: 0px; }

.margin-bottom-0,
.mb-0 {
  margin-bottom: 0px; }

.margin-left-0,
.ml-0 {
  margin-left: 0px; }

.margin-horizontal-0,
.mh-0 {
  margin-left: 0px;
  margin-right: 0px; }

.margin-vertical-0,
.mv-0 {
  margin-top: 0px;
  margin-bottom: 0px; }

.margin-5,
.m-5 {
  margin: 5px; }

.margin-top-5,
.mt-5 {
  margin-top: 5px; }

.margin-right-5,
.mr-5 {
  margin-right: 5px; }

.margin-bottom-5,
.mb-5 {
  margin-bottom: 5px; }

.margin-left-5,
.ml-5 {
  margin-left: 5px; }

.margin-horizontal-5,
.mh-5 {
  margin-left: 5px;
  margin-right: 5px; }

.margin-vertical-5,
.mv-5 {
  margin-top: 5px;
  margin-bottom: 5px; }

.margin-10,
.m-10 {
  margin: 10px; }

.margin-top-10,
.mt-10 {
  margin-top: 10px; }

.margin-right-10,
.mr-10 {
  margin-right: 10px; }

.margin-bottom-10,
.mb-10 {
  margin-bottom: 10px; }

.margin-left-10,
.ml-10 {
  margin-left: 10px; }

.margin-horizontal-10,
.mh-10 {
  margin-left: 10px;
  margin-right: 10px; }

.margin-vertical-10,
.mv-10 {
  margin-top: 10px;
  margin-bottom: 10px; }

.margin-15,
.m-15 {
  margin: 15px; }

.margin-top-15,
.mt-15 {
  margin-top: 15px; }

.margin-right-15,
.mr-15 {
  margin-right: 15px; }

.margin-bottom-15,
.mb-15 {
  margin-bottom: 15px; }

.margin-left-15,
.ml-15 {
  margin-left: 15px; }

.margin-horizontal-15,
.mh-15 {
  margin-left: 15px;
  margin-right: 15px; }

.margin-vertical-15,
.mv-15 {
  margin-top: 15px;
  margin-bottom: 15px; }

.margin-20,
.m-20 {
  margin: 20px; }

.margin-top-20,
.mt-20 {
  margin-top: 20px; }

.margin-right-20,
.mr-20 {
  margin-right: 20px; }

.margin-bottom-20,
.mb-20 {
  margin-bottom: 20px; }

.margin-left-20,
.ml-20 {
  margin-left: 20px; }

.margin-horizontal-20,
.mh-20 {
  margin-left: 20px;
  margin-right: 20px; }

.margin-vertical-20,
.mv-20 {
  margin-top: 20px;
  margin-bottom: 20px; }

.margin-25,
.m-25 {
  margin: 25px; }

.margin-top-25,
.mt-25 {
  margin-top: 25px; }

.margin-right-25,
.mr-25 {
  margin-right: 25px; }

.margin-bottom-25,
.mb-25 {
  margin-bottom: 25px; }

.margin-left-25,
.ml-25 {
  margin-left: 25px; }

.margin-horizontal-25,
.mh-25 {
  margin-left: 25px;
  margin-right: 25px; }

.margin-vertical-25,
.mv-25 {
  margin-top: 25px;
  margin-bottom: 25px; }

.margin-30,
.m-30 {
  margin: 30px; }

.margin-top-30,
.mt-30 {
  margin-top: 30px; }

.margin-right-30,
.mr-30 {
  margin-right: 30px; }

.margin-bottom-30,
.mb-30 {
  margin-bottom: 30px; }

.margin-left-30,
.ml-30 {
  margin-left: 30px; }

.margin-horizontal-30,
.mh-30 {
  margin-left: 30px;
  margin-right: 30px; }

.margin-vertical-30,
.mv-30 {
  margin-top: 30px;
  margin-bottom: 30px; }

.margin-35,
.m-35 {
  margin: 35px; }

.margin-top-35,
.mt-35 {
  margin-top: 35px; }

.margin-right-35,
.mr-35 {
  margin-right: 35px; }

.margin-bottom-35,
.mb-35 {
  margin-bottom: 35px; }

.margin-left-35,
.ml-35 {
  margin-left: 35px; }

.margin-horizontal-35,
.mh-35 {
  margin-left: 35px;
  margin-right: 35px; }

.margin-vertical-35,
.mv-35 {
  margin-top: 35px;
  margin-bottom: 35px; }

.margin-40,
.m-40 {
  margin: 40px; }

.margin-top-40,
.mt-40 {
  margin-top: 40px; }

.margin-right-40,
.mr-40 {
  margin-right: 40px; }

.margin-bottom-40,
.mb-40 {
  margin-bottom: 40px; }

.margin-left-40,
.ml-40 {
  margin-left: 40px; }

.margin-horizontal-40,
.mh-40 {
  margin-left: 40px;
  margin-right: 40px; }

.margin-vertical-40,
.mv-40 {
  margin-top: 40px;
  margin-bottom: 40px; }

.margin-45,
.m-45 {
  margin: 45px; }

.margin-top-45,
.mt-45 {
  margin-top: 45px; }

.margin-right-45,
.mr-45 {
  margin-right: 45px; }

.margin-bottom-45,
.mb-45 {
  margin-bottom: 45px; }

.margin-left-45,
.ml-45 {
  margin-left: 45px; }

.margin-horizontal-45,
.mh-45 {
  margin-left: 45px;
  margin-right: 45px; }

.margin-vertical-45,
.mv-45 {
  margin-top: 45px;
  margin-bottom: 45px; }

.margin-50,
.m-50 {
  margin: 50px; }

.margin-top-50,
.mt-50 {
  margin-top: 50px; }

.margin-right-50,
.mr-50 {
  margin-right: 50px; }

.margin-bottom-50,
.mb-50 {
  margin-bottom: 50px; }

.margin-left-50,
.ml-50 {
  margin-left: 50px; }

.margin-horizontal-50,
.mh-50 {
  margin-left: 50px;
  margin-right: 50px; }

.margin-vertical-50,
.mv-50 {
  margin-top: 50px;
  margin-bottom: 50px; }

.margin-55,
.m-55 {
  margin: 55px; }

.margin-top-55,
.mt-55 {
  margin-top: 55px; }

.margin-right-55,
.mr-55 {
  margin-right: 55px; }

.margin-bottom-55,
.mb-55 {
  margin-bottom: 55px; }

.margin-left-55,
.ml-55 {
  margin-left: 55px; }

.margin-horizontal-55,
.mh-55 {
  margin-left: 55px;
  margin-right: 55px; }

.margin-vertical-55,
.mv-55 {
  margin-top: 55px;
  margin-bottom: 55px; }

.margin-60,
.m-60 {
  margin: 60px; }

.margin-top-60,
.mt-60 {
  margin-top: 60px; }

.margin-right-60,
.mr-60 {
  margin-right: 60px; }

.margin-bottom-60,
.mb-60 {
  margin-bottom: 60px; }

.margin-left-60,
.ml-60 {
  margin-left: 60px; }

.margin-horizontal-60,
.mh-60 {
  margin-left: 60px;
  margin-right: 60px; }

.margin-vertical-60,
.mv-60 {
  margin-top: 60px;
  margin-bottom: 60px; }

.margin-65,
.m-65 {
  margin: 65px; }

.margin-top-65,
.mt-65 {
  margin-top: 65px; }

.margin-right-65,
.mr-65 {
  margin-right: 65px; }

.margin-bottom-65,
.mb-65 {
  margin-bottom: 65px; }

.margin-left-65,
.ml-65 {
  margin-left: 65px; }

.margin-horizontal-65,
.mh-65 {
  margin-left: 65px;
  margin-right: 65px; }

.margin-vertical-65,
.mv-65 {
  margin-top: 65px;
  margin-bottom: 65px; }

.margin-70,
.m-70 {
  margin: 70px; }

.margin-top-70,
.mt-70 {
  margin-top: 70px; }

.margin-right-70,
.mr-70 {
  margin-right: 70px; }

.margin-bottom-70,
.mb-70 {
  margin-bottom: 70px; }

.margin-left-70,
.ml-70 {
  margin-left: 70px; }

.margin-horizontal-70,
.mh-70 {
  margin-left: 70px;
  margin-right: 70px; }

.margin-vertical-70,
.mv-70 {
  margin-top: 70px;
  margin-bottom: 70px; }

.margin-75,
.m-75 {
  margin: 75px; }

.margin-top-75,
.mt-75 {
  margin-top: 75px; }

.margin-right-75,
.mr-75 {
  margin-right: 75px; }

.margin-bottom-75,
.mb-75 {
  margin-bottom: 75px; }

.margin-left-75,
.ml-75 {
  margin-left: 75px; }

.margin-horizontal-75,
.mh-75 {
  margin-left: 75px;
  margin-right: 75px; }

.margin-vertical-75,
.mv-75 {
  margin-top: 75px;
  margin-bottom: 75px; }

.margin-80,
.m-80 {
  margin: 80px; }

.margin-top-80,
.mt-80 {
  margin-top: 80px; }

.margin-right-80,
.mr-80 {
  margin-right: 80px; }

.margin-bottom-80,
.mb-80 {
  margin-bottom: 80px; }

.margin-left-80,
.ml-80 {
  margin-left: 80px; }

.margin-horizontal-80,
.mh-80 {
  margin-left: 80px;
  margin-right: 80px; }

.margin-vertical-80,
.mv-80 {
  margin-top: 80px;
  margin-bottom: 80px; }

.margin-85,
.m-85 {
  margin: 85px; }

.margin-top-85,
.mt-85 {
  margin-top: 85px; }

.margin-right-85,
.mr-85 {
  margin-right: 85px; }

.margin-bottom-85,
.mb-85 {
  margin-bottom: 85px; }

.margin-left-85,
.ml-85 {
  margin-left: 85px; }

.margin-horizontal-85,
.mh-85 {
  margin-left: 85px;
  margin-right: 85px; }

.margin-vertical-85,
.mv-85 {
  margin-top: 85px;
  margin-bottom: 85px; }

.margin-90,
.m-90 {
  margin: 90px; }

.margin-top-90,
.mt-90 {
  margin-top: 90px; }

.margin-right-90,
.mr-90 {
  margin-right: 90px; }

.margin-bottom-90,
.mb-90 {
  margin-bottom: 90px; }

.margin-left-90,
.ml-90 {
  margin-left: 90px; }

.margin-horizontal-90,
.mh-90 {
  margin-left: 90px;
  margin-right: 90px; }

.margin-vertical-90,
.mv-90 {
  margin-top: 90px;
  margin-bottom: 90px; }

.margin-95,
.m-95 {
  margin: 95px; }

.margin-top-95,
.mt-95 {
  margin-top: 95px; }

.margin-right-95,
.mr-95 {
  margin-right: 95px; }

.margin-bottom-95,
.mb-95 {
  margin-bottom: 95px; }

.margin-left-95,
.ml-95 {
  margin-left: 95px; }

.margin-horizontal-95,
.mh-95 {
  margin-left: 95px;
  margin-right: 95px; }

.margin-vertical-95,
.mv-95 {
  margin-top: 95px;
  margin-bottom: 95px; }

.margin-100,
.m-100 {
  margin: 100px; }

.margin-top-100,
.mt-100 {
  margin-top: 100px; }

.margin-right-100,
.mr-100 {
  margin-right: 100px; }

.margin-bottom-100,
.mb-100 {
  margin-bottom: 100px; }

.margin-left-100,
.ml-100 {
  margin-left: 100px; }

.margin-horizontal-100,
.mh-100 {
  margin-left: 100px;
  margin-right: 100px; }

.margin-vertical-100,
.mv-100 {
  margin-top: 100px;
  margin-bottom: 100px; }

.margin-8,
.m-8 {
  margin: 8px; }

.margin-top-8,
.mt-8 {
  margin-top: 8px; }

.margin-right-8,
.mr-8 {
  margin-right: 8px; }

.margin-bottom-8,
.mb-8 {
  margin-bottom: 8px; }

.margin-left-8,
.ml-8 {
  margin-left: 8px; }

.margin-horizontal-8,
.mh-8 {
  margin-left: 8px;
  margin-right: 8px; }

.margin-vertical-8,
.mv-8 {
  margin-top: 8px;
  margin-bottom: 8px; }

.margin-16,
.m-16 {
  margin: 16px; }

.margin-top-16,
.mt-16 {
  margin-top: 16px; }

.margin-right-16,
.mr-16 {
  margin-right: 16px; }

.margin-bottom-16,
.mb-16 {
  margin-bottom: 16px; }

.margin-left-16,
.ml-16 {
  margin-left: 16px; }

.margin-horizontal-16,
.mh-16 {
  margin-left: 16px;
  margin-right: 16px; }

.margin-vertical-16,
.mv-16 {
  margin-top: 16px;
  margin-bottom: 16px; }

.margin-24,
.m-24 {
  margin: 24px; }

.margin-top-24,
.mt-24 {
  margin-top: 24px; }

.margin-right-24,
.mr-24 {
  margin-right: 24px; }

.margin-bottom-24,
.mb-24 {
  margin-bottom: 24px; }

.margin-left-24,
.ml-24 {
  margin-left: 24px; }

.margin-horizontal-24,
.mh-24 {
  margin-left: 24px;
  margin-right: 24px; }

.margin-vertical-24,
.mv-24 {
  margin-top: 24px;
  margin-bottom: 24px; }

.margin-32,
.m-32 {
  margin: 32px; }

.margin-top-32,
.mt-32 {
  margin-top: 32px; }

.margin-right-32,
.mr-32 {
  margin-right: 32px; }

.margin-bottom-32,
.mb-32 {
  margin-bottom: 32px; }

.margin-left-32,
.ml-32 {
  margin-left: 32px; }

.margin-horizontal-32,
.mh-32 {
  margin-left: 32px;
  margin-right: 32px; }

.margin-vertical-32,
.mv-32 {
  margin-top: 32px;
  margin-bottom: 32px; }

.margin-48,
.m-48 {
  margin: 48px; }

.margin-top-48,
.mt-48 {
  margin-top: 48px; }

.margin-right-48,
.mr-48 {
  margin-right: 48px; }

.margin-bottom-48,
.mb-48 {
  margin-bottom: 48px; }

.margin-left-48,
.ml-48 {
  margin-left: 48px; }

.margin-horizontal-48,
.mh-48 {
  margin-left: 48px;
  margin-right: 48px; }

.margin-vertical-48,
.mv-48 {
  margin-top: 48px;
  margin-bottom: 48px; }

.margin-56,
.m-56 {
  margin: 56px; }

.margin-top-56,
.mt-56 {
  margin-top: 56px; }

.margin-right-56,
.mr-56 {
  margin-right: 56px; }

.margin-bottom-56,
.mb-56 {
  margin-bottom: 56px; }

.margin-left-56,
.ml-56 {
  margin-left: 56px; }

.margin-horizontal-56,
.mh-56 {
  margin-left: 56px;
  margin-right: 56px; }

.margin-vertical-56,
.mv-56 {
  margin-top: 56px;
  margin-bottom: 56px; }

.margin-64,
.m-64 {
  margin: 64px; }

.margin-top-64,
.mt-64 {
  margin-top: 64px; }

.margin-right-64,
.mr-64 {
  margin-right: 64px; }

.margin-bottom-64,
.mb-64 {
  margin-bottom: 64px; }

.margin-left-64,
.ml-64 {
  margin-left: 64px; }

.margin-horizontal-64,
.mh-64 {
  margin-left: 64px;
  margin-right: 64px; }

.margin-vertical-64,
.mv-64 {
  margin-top: 64px;
  margin-bottom: 64px; }

.margin-72,
.m-72 {
  margin: 72px; }

.margin-top-72,
.mt-72 {
  margin-top: 72px; }

.margin-right-72,
.mr-72 {
  margin-right: 72px; }

.margin-bottom-72,
.mb-72 {
  margin-bottom: 72px; }

.margin-left-72,
.ml-72 {
  margin-left: 72px; }

.margin-horizontal-72,
.mh-72 {
  margin-left: 72px;
  margin-right: 72px; }

.margin-vertical-72,
.mv-72 {
  margin-top: 72px;
  margin-bottom: 72px; }

.margin-88,
.m-88 {
  margin: 88px; }

.margin-top-88,
.mt-88 {
  margin-top: 88px; }

.margin-right-88,
.mr-88 {
  margin-right: 88px; }

.margin-bottom-88,
.mb-88 {
  margin-bottom: 88px; }

.margin-left-88,
.ml-88 {
  margin-left: 88px; }

.margin-horizontal-88,
.mh-88 {
  margin-left: 88px;
  margin-right: 88px; }

.margin-vertical-88,
.mv-88 {
  margin-top: 88px;
  margin-bottom: 88px; }

.margin-96,
.m-96 {
  margin: 96px; }

.margin-top-96,
.mt-96 {
  margin-top: 96px; }

.margin-right-96,
.mr-96 {
  margin-right: 96px; }

.margin-bottom-96,
.mb-96 {
  margin-bottom: 96px; }

.margin-left-96,
.ml-96 {
  margin-left: 96px; }

.margin-horizontal-96,
.mh-96 {
  margin-left: 96px;
  margin-right: 96px; }

.margin-vertical-96,
.mv-96 {
  margin-top: 96px;
  margin-bottom: 96px; }

.height-0,
.h-0 {
  height: 0px !important;
  min-height: 0px !important;
  max-height: 0px !important; }

.width-0,
.w-0 {
  width: 0px !important;
  min-width: 0px !important;
  max-width: 0px !important; }

.height-5,
.h-5 {
  height: 5px !important;
  min-height: 5px !important;
  max-height: 5px !important; }

.width-5,
.w-5 {
  width: 5px !important;
  min-width: 5px !important;
  max-width: 5px !important; }

.height-10,
.h-10 {
  height: 10px !important;
  min-height: 10px !important;
  max-height: 10px !important; }

.width-10,
.w-10 {
  width: 10px !important;
  min-width: 10px !important;
  max-width: 10px !important; }

.height-15,
.h-15 {
  height: 15px !important;
  min-height: 15px !important;
  max-height: 15px !important; }

.width-15,
.w-15 {
  width: 15px !important;
  min-width: 15px !important;
  max-width: 15px !important; }

.height-20,
.h-20 {
  height: 20px !important;
  min-height: 20px !important;
  max-height: 20px !important; }

.width-20,
.w-20 {
  width: 20px !important;
  min-width: 20px !important;
  max-width: 20px !important; }

.height-25,
.h-25 {
  height: 25px !important;
  min-height: 25px !important;
  max-height: 25px !important; }

.width-25,
.w-25 {
  width: 25px !important;
  min-width: 25px !important;
  max-width: 25px !important; }

.height-30,
.h-30 {
  height: 30px !important;
  min-height: 30px !important;
  max-height: 30px !important; }

.width-30,
.w-30 {
  width: 30px !important;
  min-width: 30px !important;
  max-width: 30px !important; }

.height-35,
.h-35 {
  height: 35px !important;
  min-height: 35px !important;
  max-height: 35px !important; }

.width-35,
.w-35 {
  width: 35px !important;
  min-width: 35px !important;
  max-width: 35px !important; }

.height-40,
.h-40 {
  height: 40px !important;
  min-height: 40px !important;
  max-height: 40px !important; }

.width-40,
.w-40 {
  width: 40px !important;
  min-width: 40px !important;
  max-width: 40px !important; }

.height-45,
.h-45 {
  height: 45px !important;
  min-height: 45px !important;
  max-height: 45px !important; }

.width-45,
.w-45 {
  width: 45px !important;
  min-width: 45px !important;
  max-width: 45px !important; }

.height-50,
.h-50 {
  height: 50px !important;
  min-height: 50px !important;
  max-height: 50px !important; }

.width-50,
.w-50 {
  width: 50px !important;
  min-width: 50px !important;
  max-width: 50px !important; }

.height-55,
.h-55 {
  height: 55px !important;
  min-height: 55px !important;
  max-height: 55px !important; }

.width-55,
.w-55 {
  width: 55px !important;
  min-width: 55px !important;
  max-width: 55px !important; }

.height-60,
.h-60 {
  height: 60px !important;
  min-height: 60px !important;
  max-height: 60px !important; }

.width-60,
.w-60 {
  width: 60px !important;
  min-width: 60px !important;
  max-width: 60px !important; }

.height-65,
.h-65 {
  height: 65px !important;
  min-height: 65px !important;
  max-height: 65px !important; }

.width-65,
.w-65 {
  width: 65px !important;
  min-width: 65px !important;
  max-width: 65px !important; }

.height-70,
.h-70 {
  height: 70px !important;
  min-height: 70px !important;
  max-height: 70px !important; }

.width-70,
.w-70 {
  width: 70px !important;
  min-width: 70px !important;
  max-width: 70px !important; }

.height-75,
.h-75 {
  height: 75px !important;
  min-height: 75px !important;
  max-height: 75px !important; }

.width-75,
.w-75 {
  width: 75px !important;
  min-width: 75px !important;
  max-width: 75px !important; }

.height-80,
.h-80 {
  height: 80px !important;
  min-height: 80px !important;
  max-height: 80px !important; }

.width-80,
.w-80 {
  width: 80px !important;
  min-width: 80px !important;
  max-width: 80px !important; }

.height-85,
.h-85 {
  height: 85px !important;
  min-height: 85px !important;
  max-height: 85px !important; }

.width-85,
.w-85 {
  width: 85px !important;
  min-width: 85px !important;
  max-width: 85px !important; }

.height-90,
.h-90 {
  height: 90px !important;
  min-height: 90px !important;
  max-height: 90px !important; }

.width-90,
.w-90 {
  width: 90px !important;
  min-width: 90px !important;
  max-width: 90px !important; }

.height-95,
.h-95 {
  height: 95px !important;
  min-height: 95px !important;
  max-height: 95px !important; }

.width-95,
.w-95 {
  width: 95px !important;
  min-width: 95px !important;
  max-width: 95px !important; }

.height-100,
.h-100 {
  height: 100px !important;
  min-height: 100px !important;
  max-height: 100px !important; }

.width-100,
.w-100 {
  width: 100px !important;
  min-width: 100px !important;
  max-width: 100px !important; }

.height-105,
.h-105 {
  height: 105px !important;
  min-height: 105px !important;
  max-height: 105px !important; }

.width-105,
.w-105 {
  width: 105px !important;
  min-width: 105px !important;
  max-width: 105px !important; }

.height-110,
.h-110 {
  height: 110px !important;
  min-height: 110px !important;
  max-height: 110px !important; }

.width-110,
.w-110 {
  width: 110px !important;
  min-width: 110px !important;
  max-width: 110px !important; }

.height-115,
.h-115 {
  height: 115px !important;
  min-height: 115px !important;
  max-height: 115px !important; }

.width-115,
.w-115 {
  width: 115px !important;
  min-width: 115px !important;
  max-width: 115px !important; }

.height-120,
.h-120 {
  height: 120px !important;
  min-height: 120px !important;
  max-height: 120px !important; }

.width-120,
.w-120 {
  width: 120px !important;
  min-width: 120px !important;
  max-width: 120px !important; }

.height-125,
.h-125 {
  height: 125px !important;
  min-height: 125px !important;
  max-height: 125px !important; }

.width-125,
.w-125 {
  width: 125px !important;
  min-width: 125px !important;
  max-width: 125px !important; }

.height-130,
.h-130 {
  height: 130px !important;
  min-height: 130px !important;
  max-height: 130px !important; }

.width-130,
.w-130 {
  width: 130px !important;
  min-width: 130px !important;
  max-width: 130px !important; }

.height-135,
.h-135 {
  height: 135px !important;
  min-height: 135px !important;
  max-height: 135px !important; }

.width-135,
.w-135 {
  width: 135px !important;
  min-width: 135px !important;
  max-width: 135px !important; }

.height-140,
.h-140 {
  height: 140px !important;
  min-height: 140px !important;
  max-height: 140px !important; }

.width-140,
.w-140 {
  width: 140px !important;
  min-width: 140px !important;
  max-width: 140px !important; }

.height-145,
.h-145 {
  height: 145px !important;
  min-height: 145px !important;
  max-height: 145px !important; }

.width-145,
.w-145 {
  width: 145px !important;
  min-width: 145px !important;
  max-width: 145px !important; }

.height-150,
.h-150 {
  height: 150px !important;
  min-height: 150px !important;
  max-height: 150px !important; }

.width-150,
.w-150 {
  width: 150px !important;
  min-width: 150px !important;
  max-width: 150px !important; }

.height-155,
.h-155 {
  height: 155px !important;
  min-height: 155px !important;
  max-height: 155px !important; }

.width-155,
.w-155 {
  width: 155px !important;
  min-width: 155px !important;
  max-width: 155px !important; }

.height-160,
.h-160 {
  height: 160px !important;
  min-height: 160px !important;
  max-height: 160px !important; }

.width-160,
.w-160 {
  width: 160px !important;
  min-width: 160px !important;
  max-width: 160px !important; }

.height-165,
.h-165 {
  height: 165px !important;
  min-height: 165px !important;
  max-height: 165px !important; }

.width-165,
.w-165 {
  width: 165px !important;
  min-width: 165px !important;
  max-width: 165px !important; }

.height-170,
.h-170 {
  height: 170px !important;
  min-height: 170px !important;
  max-height: 170px !important; }

.width-170,
.w-170 {
  width: 170px !important;
  min-width: 170px !important;
  max-width: 170px !important; }

.height-175,
.h-175 {
  height: 175px !important;
  min-height: 175px !important;
  max-height: 175px !important; }

.width-175,
.w-175 {
  width: 175px !important;
  min-width: 175px !important;
  max-width: 175px !important; }

.height-180,
.h-180 {
  height: 180px !important;
  min-height: 180px !important;
  max-height: 180px !important; }

.width-180,
.w-180 {
  width: 180px !important;
  min-width: 180px !important;
  max-width: 180px !important; }

.height-185,
.h-185 {
  height: 185px !important;
  min-height: 185px !important;
  max-height: 185px !important; }

.width-185,
.w-185 {
  width: 185px !important;
  min-width: 185px !important;
  max-width: 185px !important; }

.height-190,
.h-190 {
  height: 190px !important;
  min-height: 190px !important;
  max-height: 190px !important; }

.width-190,
.w-190 {
  width: 190px !important;
  min-width: 190px !important;
  max-width: 190px !important; }

.height-195,
.h-195 {
  height: 195px !important;
  min-height: 195px !important;
  max-height: 195px !important; }

.width-195,
.w-195 {
  width: 195px !important;
  min-width: 195px !important;
  max-width: 195px !important; }

.height-200,
.h-200 {
  height: 200px !important;
  min-height: 200px !important;
  max-height: 200px !important; }

.width-200,
.w-200 {
  width: 200px !important;
  min-width: 200px !important;
  max-width: 200px !important; }

.height-205,
.h-205 {
  height: 205px !important;
  min-height: 205px !important;
  max-height: 205px !important; }

.width-205,
.w-205 {
  width: 205px !important;
  min-width: 205px !important;
  max-width: 205px !important; }

.height-210,
.h-210 {
  height: 210px !important;
  min-height: 210px !important;
  max-height: 210px !important; }

.width-210,
.w-210 {
  width: 210px !important;
  min-width: 210px !important;
  max-width: 210px !important; }

.height-215,
.h-215 {
  height: 215px !important;
  min-height: 215px !important;
  max-height: 215px !important; }

.width-215,
.w-215 {
  width: 215px !important;
  min-width: 215px !important;
  max-width: 215px !important; }

.height-220,
.h-220 {
  height: 220px !important;
  min-height: 220px !important;
  max-height: 220px !important; }

.width-220,
.w-220 {
  width: 220px !important;
  min-width: 220px !important;
  max-width: 220px !important; }

.height-225,
.h-225 {
  height: 225px !important;
  min-height: 225px !important;
  max-height: 225px !important; }

.width-225,
.w-225 {
  width: 225px !important;
  min-width: 225px !important;
  max-width: 225px !important; }

.height-230,
.h-230 {
  height: 230px !important;
  min-height: 230px !important;
  max-height: 230px !important; }

.width-230,
.w-230 {
  width: 230px !important;
  min-width: 230px !important;
  max-width: 230px !important; }

.height-235,
.h-235 {
  height: 235px !important;
  min-height: 235px !important;
  max-height: 235px !important; }

.width-235,
.w-235 {
  width: 235px !important;
  min-width: 235px !important;
  max-width: 235px !important; }

.height-240,
.h-240 {
  height: 240px !important;
  min-height: 240px !important;
  max-height: 240px !important; }

.width-240,
.w-240 {
  width: 240px !important;
  min-width: 240px !important;
  max-width: 240px !important; }

.height-245,
.h-245 {
  height: 245px !important;
  min-height: 245px !important;
  max-height: 245px !important; }

.width-245,
.w-245 {
  width: 245px !important;
  min-width: 245px !important;
  max-width: 245px !important; }

.height-250,
.h-250 {
  height: 250px !important;
  min-height: 250px !important;
  max-height: 250px !important; }

.width-250,
.w-250 {
  width: 250px !important;
  min-width: 250px !important;
  max-width: 250px !important; }

.height-255,
.h-255 {
  height: 255px !important;
  min-height: 255px !important;
  max-height: 255px !important; }

.width-255,
.w-255 {
  width: 255px !important;
  min-width: 255px !important;
  max-width: 255px !important; }

.height-260,
.h-260 {
  height: 260px !important;
  min-height: 260px !important;
  max-height: 260px !important; }

.width-260,
.w-260 {
  width: 260px !important;
  min-width: 260px !important;
  max-width: 260px !important; }

.height-265,
.h-265 {
  height: 265px !important;
  min-height: 265px !important;
  max-height: 265px !important; }

.width-265,
.w-265 {
  width: 265px !important;
  min-width: 265px !important;
  max-width: 265px !important; }

.height-270,
.h-270 {
  height: 270px !important;
  min-height: 270px !important;
  max-height: 270px !important; }

.width-270,
.w-270 {
  width: 270px !important;
  min-width: 270px !important;
  max-width: 270px !important; }

.height-275,
.h-275 {
  height: 275px !important;
  min-height: 275px !important;
  max-height: 275px !important; }

.width-275,
.w-275 {
  width: 275px !important;
  min-width: 275px !important;
  max-width: 275px !important; }

.height-280,
.h-280 {
  height: 280px !important;
  min-height: 280px !important;
  max-height: 280px !important; }

.width-280,
.w-280 {
  width: 280px !important;
  min-width: 280px !important;
  max-width: 280px !important; }

.height-285,
.h-285 {
  height: 285px !important;
  min-height: 285px !important;
  max-height: 285px !important; }

.width-285,
.w-285 {
  width: 285px !important;
  min-width: 285px !important;
  max-width: 285px !important; }

.height-290,
.h-290 {
  height: 290px !important;
  min-height: 290px !important;
  max-height: 290px !important; }

.width-290,
.w-290 {
  width: 290px !important;
  min-width: 290px !important;
  max-width: 290px !important; }

.height-295,
.h-295 {
  height: 295px !important;
  min-height: 295px !important;
  max-height: 295px !important; }

.width-295,
.w-295 {
  width: 295px !important;
  min-width: 295px !important;
  max-width: 295px !important; }

.height-300,
.h-300 {
  height: 300px !important;
  min-height: 300px !important;
  max-height: 300px !important; }

.width-300,
.w-300 {
  width: 300px !important;
  min-width: 300px !important;
  max-width: 300px !important; }

.height-305,
.h-305 {
  height: 305px !important;
  min-height: 305px !important;
  max-height: 305px !important; }

.width-305,
.w-305 {
  width: 305px !important;
  min-width: 305px !important;
  max-width: 305px !important; }

.height-310,
.h-310 {
  height: 310px !important;
  min-height: 310px !important;
  max-height: 310px !important; }

.width-310,
.w-310 {
  width: 310px !important;
  min-width: 310px !important;
  max-width: 310px !important; }

.height-315,
.h-315 {
  height: 315px !important;
  min-height: 315px !important;
  max-height: 315px !important; }

.width-315,
.w-315 {
  width: 315px !important;
  min-width: 315px !important;
  max-width: 315px !important; }

.height-320,
.h-320 {
  height: 320px !important;
  min-height: 320px !important;
  max-height: 320px !important; }

.width-320,
.w-320 {
  width: 320px !important;
  min-width: 320px !important;
  max-width: 320px !important; }

.height-325,
.h-325 {
  height: 325px !important;
  min-height: 325px !important;
  max-height: 325px !important; }

.width-325,
.w-325 {
  width: 325px !important;
  min-width: 325px !important;
  max-width: 325px !important; }

.height-330,
.h-330 {
  height: 330px !important;
  min-height: 330px !important;
  max-height: 330px !important; }

.width-330,
.w-330 {
  width: 330px !important;
  min-width: 330px !important;
  max-width: 330px !important; }

.height-335,
.h-335 {
  height: 335px !important;
  min-height: 335px !important;
  max-height: 335px !important; }

.width-335,
.w-335 {
  width: 335px !important;
  min-width: 335px !important;
  max-width: 335px !important; }

.height-340,
.h-340 {
  height: 340px !important;
  min-height: 340px !important;
  max-height: 340px !important; }

.width-340,
.w-340 {
  width: 340px !important;
  min-width: 340px !important;
  max-width: 340px !important; }

.height-345,
.h-345 {
  height: 345px !important;
  min-height: 345px !important;
  max-height: 345px !important; }

.width-345,
.w-345 {
  width: 345px !important;
  min-width: 345px !important;
  max-width: 345px !important; }

.height-350,
.h-350 {
  height: 350px !important;
  min-height: 350px !important;
  max-height: 350px !important; }

.width-350,
.w-350 {
  width: 350px !important;
  min-width: 350px !important;
  max-width: 350px !important; }

.height-355,
.h-355 {
  height: 355px !important;
  min-height: 355px !important;
  max-height: 355px !important; }

.width-355,
.w-355 {
  width: 355px !important;
  min-width: 355px !important;
  max-width: 355px !important; }

.height-360,
.h-360 {
  height: 360px !important;
  min-height: 360px !important;
  max-height: 360px !important; }

.width-360,
.w-360 {
  width: 360px !important;
  min-width: 360px !important;
  max-width: 360px !important; }

.height-365,
.h-365 {
  height: 365px !important;
  min-height: 365px !important;
  max-height: 365px !important; }

.width-365,
.w-365 {
  width: 365px !important;
  min-width: 365px !important;
  max-width: 365px !important; }

.height-370,
.h-370 {
  height: 370px !important;
  min-height: 370px !important;
  max-height: 370px !important; }

.width-370,
.w-370 {
  width: 370px !important;
  min-width: 370px !important;
  max-width: 370px !important; }

.height-375,
.h-375 {
  height: 375px !important;
  min-height: 375px !important;
  max-height: 375px !important; }

.width-375,
.w-375 {
  width: 375px !important;
  min-width: 375px !important;
  max-width: 375px !important; }

.height-380,
.h-380 {
  height: 380px !important;
  min-height: 380px !important;
  max-height: 380px !important; }

.width-380,
.w-380 {
  width: 380px !important;
  min-width: 380px !important;
  max-width: 380px !important; }

.height-385,
.h-385 {
  height: 385px !important;
  min-height: 385px !important;
  max-height: 385px !important; }

.width-385,
.w-385 {
  width: 385px !important;
  min-width: 385px !important;
  max-width: 385px !important; }

.height-390,
.h-390 {
  height: 390px !important;
  min-height: 390px !important;
  max-height: 390px !important; }

.width-390,
.w-390 {
  width: 390px !important;
  min-width: 390px !important;
  max-width: 390px !important; }

.height-395,
.h-395 {
  height: 395px !important;
  min-height: 395px !important;
  max-height: 395px !important; }

.width-395,
.w-395 {
  width: 395px !important;
  min-width: 395px !important;
  max-width: 395px !important; }

.height-400,
.h-400 {
  height: 400px !important;
  min-height: 400px !important;
  max-height: 400px !important; }

.width-400,
.w-400 {
  width: 400px !important;
  min-width: 400px !important;
  max-width: 400px !important; }

.height-405,
.h-405 {
  height: 405px !important;
  min-height: 405px !important;
  max-height: 405px !important; }

.width-405,
.w-405 {
  width: 405px !important;
  min-width: 405px !important;
  max-width: 405px !important; }

.height-410,
.h-410 {
  height: 410px !important;
  min-height: 410px !important;
  max-height: 410px !important; }

.width-410,
.w-410 {
  width: 410px !important;
  min-width: 410px !important;
  max-width: 410px !important; }

.height-415,
.h-415 {
  height: 415px !important;
  min-height: 415px !important;
  max-height: 415px !important; }

.width-415,
.w-415 {
  width: 415px !important;
  min-width: 415px !important;
  max-width: 415px !important; }

.height-420,
.h-420 {
  height: 420px !important;
  min-height: 420px !important;
  max-height: 420px !important; }

.width-420,
.w-420 {
  width: 420px !important;
  min-width: 420px !important;
  max-width: 420px !important; }

.height-425,
.h-425 {
  height: 425px !important;
  min-height: 425px !important;
  max-height: 425px !important; }

.width-425,
.w-425 {
  width: 425px !important;
  min-width: 425px !important;
  max-width: 425px !important; }

.height-430,
.h-430 {
  height: 430px !important;
  min-height: 430px !important;
  max-height: 430px !important; }

.width-430,
.w-430 {
  width: 430px !important;
  min-width: 430px !important;
  max-width: 430px !important; }

.height-435,
.h-435 {
  height: 435px !important;
  min-height: 435px !important;
  max-height: 435px !important; }

.width-435,
.w-435 {
  width: 435px !important;
  min-width: 435px !important;
  max-width: 435px !important; }

.height-440,
.h-440 {
  height: 440px !important;
  min-height: 440px !important;
  max-height: 440px !important; }

.width-440,
.w-440 {
  width: 440px !important;
  min-width: 440px !important;
  max-width: 440px !important; }

.height-445,
.h-445 {
  height: 445px !important;
  min-height: 445px !important;
  max-height: 445px !important; }

.width-445,
.w-445 {
  width: 445px !important;
  min-width: 445px !important;
  max-width: 445px !important; }

.height-450,
.h-450 {
  height: 450px !important;
  min-height: 450px !important;
  max-height: 450px !important; }

.width-450,
.w-450 {
  width: 450px !important;
  min-width: 450px !important;
  max-width: 450px !important; }

.height-455,
.h-455 {
  height: 455px !important;
  min-height: 455px !important;
  max-height: 455px !important; }

.width-455,
.w-455 {
  width: 455px !important;
  min-width: 455px !important;
  max-width: 455px !important; }

.height-460,
.h-460 {
  height: 460px !important;
  min-height: 460px !important;
  max-height: 460px !important; }

.width-460,
.w-460 {
  width: 460px !important;
  min-width: 460px !important;
  max-width: 460px !important; }

.height-465,
.h-465 {
  height: 465px !important;
  min-height: 465px !important;
  max-height: 465px !important; }

.width-465,
.w-465 {
  width: 465px !important;
  min-width: 465px !important;
  max-width: 465px !important; }

.height-470,
.h-470 {
  height: 470px !important;
  min-height: 470px !important;
  max-height: 470px !important; }

.width-470,
.w-470 {
  width: 470px !important;
  min-width: 470px !important;
  max-width: 470px !important; }

.height-475,
.h-475 {
  height: 475px !important;
  min-height: 475px !important;
  max-height: 475px !important; }

.width-475,
.w-475 {
  width: 475px !important;
  min-width: 475px !important;
  max-width: 475px !important; }

.height-480,
.h-480 {
  height: 480px !important;
  min-height: 480px !important;
  max-height: 480px !important; }

.width-480,
.w-480 {
  width: 480px !important;
  min-width: 480px !important;
  max-width: 480px !important; }

.height-485,
.h-485 {
  height: 485px !important;
  min-height: 485px !important;
  max-height: 485px !important; }

.width-485,
.w-485 {
  width: 485px !important;
  min-width: 485px !important;
  max-width: 485px !important; }

.height-490,
.h-490 {
  height: 490px !important;
  min-height: 490px !important;
  max-height: 490px !important; }

.width-490,
.w-490 {
  width: 490px !important;
  min-width: 490px !important;
  max-width: 490px !important; }

.height-495,
.h-495 {
  height: 495px !important;
  min-height: 495px !important;
  max-height: 495px !important; }

.width-495,
.w-495 {
  width: 495px !important;
  min-width: 495px !important;
  max-width: 495px !important; }

.height-500,
.h-500 {
  height: 500px !important;
  min-height: 500px !important;
  max-height: 500px !important; }

.width-500,
.w-500 {
  width: 500px !important;
  min-width: 500px !important;
  max-width: 500px !important; }

.height-8,
.h-8 {
  height: 8px !important;
  min-height: 8px !important;
  max-height: 8px !important; }

.width-8,
.w-8 {
  width: 8px !important;
  min-width: 8px !important;
  max-width: 8px !important; }

.height-16,
.h-16 {
  height: 16px !important;
  min-height: 16px !important;
  max-height: 16px !important; }

.width-16,
.w-16 {
  width: 16px !important;
  min-width: 16px !important;
  max-width: 16px !important; }

.height-24,
.h-24 {
  height: 24px !important;
  min-height: 24px !important;
  max-height: 24px !important; }

.width-24,
.w-24 {
  width: 24px !important;
  min-width: 24px !important;
  max-width: 24px !important; }

.height-32,
.h-32 {
  height: 32px !important;
  min-height: 32px !important;
  max-height: 32px !important; }

.width-32,
.w-32 {
  width: 32px !important;
  min-width: 32px !important;
  max-width: 32px !important; }

.height-48,
.h-48 {
  height: 48px !important;
  min-height: 48px !important;
  max-height: 48px !important; }

.width-48,
.w-48 {
  width: 48px !important;
  min-width: 48px !important;
  max-width: 48px !important; }

.height-56,
.h-56 {
  height: 56px !important;
  min-height: 56px !important;
  max-height: 56px !important; }

.width-56,
.w-56 {
  width: 56px !important;
  min-width: 56px !important;
  max-width: 56px !important; }

.height-64,
.h-64 {
  height: 64px !important;
  min-height: 64px !important;
  max-height: 64px !important; }

.width-64,
.w-64 {
  width: 64px !important;
  min-width: 64px !important;
  max-width: 64px !important; }

.height-72,
.h-72 {
  height: 72px !important;
  min-height: 72px !important;
  max-height: 72px !important; }

.width-72,
.w-72 {
  width: 72px !important;
  min-width: 72px !important;
  max-width: 72px !important; }

.height-88,
.h-88 {
  height: 88px !important;
  min-height: 88px !important;
  max-height: 88px !important; }

.width-88,
.w-88 {
  width: 88px !important;
  min-width: 88px !important;
  max-width: 88px !important; }

.height-96,
.h-96 {
  height: 96px !important;
  min-height: 96px !important;
  max-height: 96px !important; }

.width-96,
.w-96 {
  width: 96px !important;
  min-width: 96px !important;
  max-width: 96px !important; }

.height-104,
.h-104 {
  height: 104px !important;
  min-height: 104px !important;
  max-height: 104px !important; }

.width-104,
.w-104 {
  width: 104px !important;
  min-width: 104px !important;
  max-width: 104px !important; }

.height-112,
.h-112 {
  height: 112px !important;
  min-height: 112px !important;
  max-height: 112px !important; }

.width-112,
.w-112 {
  width: 112px !important;
  min-width: 112px !important;
  max-width: 112px !important; }

.height-128,
.h-128 {
  height: 128px !important;
  min-height: 128px !important;
  max-height: 128px !important; }

.width-128,
.w-128 {
  width: 128px !important;
  min-width: 128px !important;
  max-width: 128px !important; }

.height-136,
.h-136 {
  height: 136px !important;
  min-height: 136px !important;
  max-height: 136px !important; }

.width-136,
.w-136 {
  width: 136px !important;
  min-width: 136px !important;
  max-width: 136px !important; }

.height-144,
.h-144 {
  height: 144px !important;
  min-height: 144px !important;
  max-height: 144px !important; }

.width-144,
.w-144 {
  width: 144px !important;
  min-width: 144px !important;
  max-width: 144px !important; }

.height-152,
.h-152 {
  height: 152px !important;
  min-height: 152px !important;
  max-height: 152px !important; }

.width-152,
.w-152 {
  width: 152px !important;
  min-width: 152px !important;
  max-width: 152px !important; }

.height-168,
.h-168 {
  height: 168px !important;
  min-height: 168px !important;
  max-height: 168px !important; }

.width-168,
.w-168 {
  width: 168px !important;
  min-width: 168px !important;
  max-width: 168px !important; }

.height-176,
.h-176 {
  height: 176px !important;
  min-height: 176px !important;
  max-height: 176px !important; }

.width-176,
.w-176 {
  width: 176px !important;
  min-width: 176px !important;
  max-width: 176px !important; }

.height-184,
.h-184 {
  height: 184px !important;
  min-height: 184px !important;
  max-height: 184px !important; }

.width-184,
.w-184 {
  width: 184px !important;
  min-width: 184px !important;
  max-width: 184px !important; }

.height-192,
.h-192 {
  height: 192px !important;
  min-height: 192px !important;
  max-height: 192px !important; }

.width-192,
.w-192 {
  width: 192px !important;
  min-width: 192px !important;
  max-width: 192px !important; }

.height-208,
.h-208 {
  height: 208px !important;
  min-height: 208px !important;
  max-height: 208px !important; }

.width-208,
.w-208 {
  width: 208px !important;
  min-width: 208px !important;
  max-width: 208px !important; }

.height-216,
.h-216 {
  height: 216px !important;
  min-height: 216px !important;
  max-height: 216px !important; }

.width-216,
.w-216 {
  width: 216px !important;
  min-width: 216px !important;
  max-width: 216px !important; }

.height-224,
.h-224 {
  height: 224px !important;
  min-height: 224px !important;
  max-height: 224px !important; }

.width-224,
.w-224 {
  width: 224px !important;
  min-width: 224px !important;
  max-width: 224px !important; }

.height-232,
.h-232 {
  height: 232px !important;
  min-height: 232px !important;
  max-height: 232px !important; }

.width-232,
.w-232 {
  width: 232px !important;
  min-width: 232px !important;
  max-width: 232px !important; }

.height-248,
.h-248 {
  height: 248px !important;
  min-height: 248px !important;
  max-height: 248px !important; }

.width-248,
.w-248 {
  width: 248px !important;
  min-width: 248px !important;
  max-width: 248px !important; }

.height-256,
.h-256 {
  height: 256px !important;
  min-height: 256px !important;
  max-height: 256px !important; }

.width-256,
.w-256 {
  width: 256px !important;
  min-width: 256px !important;
  max-width: 256px !important; }

.height-264,
.h-264 {
  height: 264px !important;
  min-height: 264px !important;
  max-height: 264px !important; }

.width-264,
.w-264 {
  width: 264px !important;
  min-width: 264px !important;
  max-width: 264px !important; }

.height-272,
.h-272 {
  height: 272px !important;
  min-height: 272px !important;
  max-height: 272px !important; }

.width-272,
.w-272 {
  width: 272px !important;
  min-width: 272px !important;
  max-width: 272px !important; }

.height-288,
.h-288 {
  height: 288px !important;
  min-height: 288px !important;
  max-height: 288px !important; }

.width-288,
.w-288 {
  width: 288px !important;
  min-width: 288px !important;
  max-width: 288px !important; }

.height-296,
.h-296 {
  height: 296px !important;
  min-height: 296px !important;
  max-height: 296px !important; }

.width-296,
.w-296 {
  width: 296px !important;
  min-width: 296px !important;
  max-width: 296px !important; }

.height-304,
.h-304 {
  height: 304px !important;
  min-height: 304px !important;
  max-height: 304px !important; }

.width-304,
.w-304 {
  width: 304px !important;
  min-width: 304px !important;
  max-width: 304px !important; }

.height-312,
.h-312 {
  height: 312px !important;
  min-height: 312px !important;
  max-height: 312px !important; }

.width-312,
.w-312 {
  width: 312px !important;
  min-width: 312px !important;
  max-width: 312px !important; }

.height-328,
.h-328 {
  height: 328px !important;
  min-height: 328px !important;
  max-height: 328px !important; }

.width-328,
.w-328 {
  width: 328px !important;
  min-width: 328px !important;
  max-width: 328px !important; }

.height-336,
.h-336 {
  height: 336px !important;
  min-height: 336px !important;
  max-height: 336px !important; }

.width-336,
.w-336 {
  width: 336px !important;
  min-width: 336px !important;
  max-width: 336px !important; }

.height-344,
.h-344 {
  height: 344px !important;
  min-height: 344px !important;
  max-height: 344px !important; }

.width-344,
.w-344 {
  width: 344px !important;
  min-width: 344px !important;
  max-width: 344px !important; }

.height-352,
.h-352 {
  height: 352px !important;
  min-height: 352px !important;
  max-height: 352px !important; }

.width-352,
.w-352 {
  width: 352px !important;
  min-width: 352px !important;
  max-width: 352px !important; }

.height-368,
.h-368 {
  height: 368px !important;
  min-height: 368px !important;
  max-height: 368px !important; }

.width-368,
.w-368 {
  width: 368px !important;
  min-width: 368px !important;
  max-width: 368px !important; }

.height-376,
.h-376 {
  height: 376px !important;
  min-height: 376px !important;
  max-height: 376px !important; }

.width-376,
.w-376 {
  width: 376px !important;
  min-width: 376px !important;
  max-width: 376px !important; }

.height-384,
.h-384 {
  height: 384px !important;
  min-height: 384px !important;
  max-height: 384px !important; }

.width-384,
.w-384 {
  width: 384px !important;
  min-width: 384px !important;
  max-width: 384px !important; }

.height-392,
.h-392 {
  height: 392px !important;
  min-height: 392px !important;
  max-height: 392px !important; }

.width-392,
.w-392 {
  width: 392px !important;
  min-width: 392px !important;
  max-width: 392px !important; }

.height-408,
.h-408 {
  height: 408px !important;
  min-height: 408px !important;
  max-height: 408px !important; }

.width-408,
.w-408 {
  width: 408px !important;
  min-width: 408px !important;
  max-width: 408px !important; }

.height-416,
.h-416 {
  height: 416px !important;
  min-height: 416px !important;
  max-height: 416px !important; }

.width-416,
.w-416 {
  width: 416px !important;
  min-width: 416px !important;
  max-width: 416px !important; }

.height-424,
.h-424 {
  height: 424px !important;
  min-height: 424px !important;
  max-height: 424px !important; }

.width-424,
.w-424 {
  width: 424px !important;
  min-width: 424px !important;
  max-width: 424px !important; }

.height-432,
.h-432 {
  height: 432px !important;
  min-height: 432px !important;
  max-height: 432px !important; }

.width-432,
.w-432 {
  width: 432px !important;
  min-width: 432px !important;
  max-width: 432px !important; }

.height-448,
.h-448 {
  height: 448px !important;
  min-height: 448px !important;
  max-height: 448px !important; }

.width-448,
.w-448 {
  width: 448px !important;
  min-width: 448px !important;
  max-width: 448px !important; }

.height-456,
.h-456 {
  height: 456px !important;
  min-height: 456px !important;
  max-height: 456px !important; }

.width-456,
.w-456 {
  width: 456px !important;
  min-width: 456px !important;
  max-width: 456px !important; }

.height-464,
.h-464 {
  height: 464px !important;
  min-height: 464px !important;
  max-height: 464px !important; }

.width-464,
.w-464 {
  width: 464px !important;
  min-width: 464px !important;
  max-width: 464px !important; }

.height-472,
.h-472 {
  height: 472px !important;
  min-height: 472px !important;
  max-height: 472px !important; }

.width-472,
.w-472 {
  width: 472px !important;
  min-width: 472px !important;
  max-width: 472px !important; }

.height-488,
.h-488 {
  height: 488px !important;
  min-height: 488px !important;
  max-height: 488px !important; }

.width-488,
.w-488 {
  width: 488px !important;
  min-width: 488px !important;
  max-width: 488px !important; }

.height-496,
.h-496 {
  height: 496px !important;
  min-height: 496px !important;
  max-height: 496px !important; }

.width-496,
.w-496 {
  width: 496px !important;
  min-width: 496px !important;
  max-width: 496px !important; }

.height-504,
.h-504 {
  height: 504px !important;
  min-height: 504px !important;
  max-height: 504px !important; }

.width-504,
.w-504 {
  width: 504px !important;
  min-width: 504px !important;
  max-width: 504px !important; }

.height-512,
.h-512 {
  height: 512px !important;
  min-height: 512px !important;
  max-height: 512px !important; }

.width-512,
.w-512 {
  width: 512px !important;
  min-width: 512px !important;
  max-width: 512px !important; }

.height-528,
.h-528 {
  height: 528px !important;
  min-height: 528px !important;
  max-height: 528px !important; }

.width-528,
.w-528 {
  width: 528px !important;
  min-width: 528px !important;
  max-width: 528px !important; }

.height-536,
.h-536 {
  height: 536px !important;
  min-height: 536px !important;
  max-height: 536px !important; }

.width-536,
.w-536 {
  width: 536px !important;
  min-width: 536px !important;
  max-width: 536px !important; }

.height-544,
.h-544 {
  height: 544px !important;
  min-height: 544px !important;
  max-height: 544px !important; }

.width-544,
.w-544 {
  width: 544px !important;
  min-width: 544px !important;
  max-width: 544px !important; }

.height-552,
.h-552 {
  height: 552px !important;
  min-height: 552px !important;
  max-height: 552px !important; }

.width-552,
.w-552 {
  width: 552px !important;
  min-width: 552px !important;
  max-width: 552px !important; }

.height-568,
.h-568 {
  height: 568px !important;
  min-height: 568px !important;
  max-height: 568px !important; }

.width-568,
.w-568 {
  width: 568px !important;
  min-width: 568px !important;
  max-width: 568px !important; }

.height-576,
.h-576 {
  height: 576px !important;
  min-height: 576px !important;
  max-height: 576px !important; }

.width-576,
.w-576 {
  width: 576px !important;
  min-width: 576px !important;
  max-width: 576px !important; }

.height-584,
.h-584 {
  height: 584px !important;
  min-height: 584px !important;
  max-height: 584px !important; }

.width-584,
.w-584 {
  width: 584px !important;
  min-width: 584px !important;
  max-width: 584px !important; }

.height-592,
.h-592 {
  height: 592px !important;
  min-height: 592px !important;
  max-height: 592px !important; }

.width-592,
.w-592 {
  width: 592px !important;
  min-width: 592px !important;
  max-width: 592px !important; }

.height-608,
.h-608 {
  height: 608px !important;
  min-height: 608px !important;
  max-height: 608px !important; }

.width-608,
.w-608 {
  width: 608px !important;
  min-width: 608px !important;
  max-width: 608px !important; }

.height-616,
.h-616 {
  height: 616px !important;
  min-height: 616px !important;
  max-height: 616px !important; }

.width-616,
.w-616 {
  width: 616px !important;
  min-width: 616px !important;
  max-width: 616px !important; }

.height-624,
.h-624 {
  height: 624px !important;
  min-height: 624px !important;
  max-height: 624px !important; }

.width-624,
.w-624 {
  width: 624px !important;
  min-width: 624px !important;
  max-width: 624px !important; }

.height-632,
.h-632 {
  height: 632px !important;
  min-height: 632px !important;
  max-height: 632px !important; }

.width-632,
.w-632 {
  width: 632px !important;
  min-width: 632px !important;
  max-width: 632px !important; }

.height-648,
.h-648 {
  height: 648px !important;
  min-height: 648px !important;
  max-height: 648px !important; }

.width-648,
.w-648 {
  width: 648px !important;
  min-width: 648px !important;
  max-width: 648px !important; }

.height-656,
.h-656 {
  height: 656px !important;
  min-height: 656px !important;
  max-height: 656px !important; }

.width-656,
.w-656 {
  width: 656px !important;
  min-width: 656px !important;
  max-width: 656px !important; }

.height-664,
.h-664 {
  height: 664px !important;
  min-height: 664px !important;
  max-height: 664px !important; }

.width-664,
.w-664 {
  width: 664px !important;
  min-width: 664px !important;
  max-width: 664px !important; }

.height-672,
.h-672 {
  height: 672px !important;
  min-height: 672px !important;
  max-height: 672px !important; }

.width-672,
.w-672 {
  width: 672px !important;
  min-width: 672px !important;
  max-width: 672px !important; }

.height-688,
.h-688 {
  height: 688px !important;
  min-height: 688px !important;
  max-height: 688px !important; }

.width-688,
.w-688 {
  width: 688px !important;
  min-width: 688px !important;
  max-width: 688px !important; }

.height-696,
.h-696 {
  height: 696px !important;
  min-height: 696px !important;
  max-height: 696px !important; }

.width-696,
.w-696 {
  width: 696px !important;
  min-width: 696px !important;
  max-width: 696px !important; }

.height-704,
.h-704 {
  height: 704px !important;
  min-height: 704px !important;
  max-height: 704px !important; }

.width-704,
.w-704 {
  width: 704px !important;
  min-width: 704px !important;
  max-width: 704px !important; }

.height-712,
.h-712 {
  height: 712px !important;
  min-height: 712px !important;
  max-height: 712px !important; }

.width-712,
.w-712 {
  width: 712px !important;
  min-width: 712px !important;
  max-width: 712px !important; }

.height-728,
.h-728 {
  height: 728px !important;
  min-height: 728px !important;
  max-height: 728px !important; }

.width-728,
.w-728 {
  width: 728px !important;
  min-width: 728px !important;
  max-width: 728px !important; }

.height-736,
.h-736 {
  height: 736px !important;
  min-height: 736px !important;
  max-height: 736px !important; }

.width-736,
.w-736 {
  width: 736px !important;
  min-width: 736px !important;
  max-width: 736px !important; }

.height-744,
.h-744 {
  height: 744px !important;
  min-height: 744px !important;
  max-height: 744px !important; }

.width-744,
.w-744 {
  width: 744px !important;
  min-width: 744px !important;
  max-width: 744px !important; }

.height-752,
.h-752 {
  height: 752px !important;
  min-height: 752px !important;
  max-height: 752px !important; }

.width-752,
.w-752 {
  width: 752px !important;
  min-width: 752px !important;
  max-width: 752px !important; }

.height-768,
.h-768 {
  height: 768px !important;
  min-height: 768px !important;
  max-height: 768px !important; }

.width-768,
.w-768 {
  width: 768px !important;
  min-width: 768px !important;
  max-width: 768px !important; }

.height-776,
.h-776 {
  height: 776px !important;
  min-height: 776px !important;
  max-height: 776px !important; }

.width-776,
.w-776 {
  width: 776px !important;
  min-width: 776px !important;
  max-width: 776px !important; }

.height-784,
.h-784 {
  height: 784px !important;
  min-height: 784px !important;
  max-height: 784px !important; }

.width-784,
.w-784 {
  width: 784px !important;
  min-width: 784px !important;
  max-width: 784px !important; }

.height-792,
.h-792 {
  height: 792px !important;
  min-height: 792px !important;
  max-height: 792px !important; }

.width-792,
.w-792 {
  width: 792px !important;
  min-width: 792px !important;
  max-width: 792px !important; }

.border,
.b {
  border: 1px solid rgba(0, 0, 0, 0.12); }

.border-top,
.bt {
  border-top: 1px solid rgba(0, 0, 0, 0.12); }

.border-right,
.br {
  border-right: 1px solid rgba(0, 0, 0, 0.12); }

.border-bottom,
.bb {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }

.border-left,
.bl {
  border-left: 1px solid rgba(0, 0, 0, 0.12); }

.border-horizontal,
.bh {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12); }

.border-vertical,
.bv {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }

.brad-1 {
  border-radius: 1px; }

.brad-2 {
  border-radius: 2px; }

.brad-3 {
  border-radius: 3px; }

.brad-4 {
  border-radius: 4px; }

.brad-5 {
  border-radius: 5px; }

.brad-6 {
  border-radius: 6px; }

.brad-7 {
  border-radius: 7px; }

.brad-8 {
  border-radius: 8px; }

.brad-9 {
  border-radius: 9px; }

.brad-10 {
  border-radius: 10px; }

.brad-11 {
  border-radius: 11px; }

.brad-12 {
  border-radius: 12px; }

/*----------------------------------------------------------------*/
/*  Print
/*----------------------------------------------------------------*/
@media all {
  /* Never show page break in normal view */
  .page-break {
    display: none; } }

@media print {
  /* Page Styles */
  @page {}  /* Page break */
  .page-break {
    display: block;
    break-after: always;
    page-break-after: always; }
  /* General styles */
  #main #vertical-navigation {
    display: none; }
  #main #toolbar {
    display: none; }
  #main #quick-panel {
    display: none; }
  #main #content {
    position: relative !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    background: none !important; }
  ms-theme-options {
    display: none; }
  .ps-scrollbar-x-rail,
  .ps-scrollbar-y-rail {
    display: none !important; }
  /* Printable page specific styles */
  .printable {
    overflow: visible !important; }
    .printable .template-layout {
      display: inline !important; } }

.ms-card .template-1 .media .image {
  width: 100%;
  border-radius: 2px 2px 0 0; }

.ms-card .template-2 .header .avatar .image {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 20px; }

.ms-card .template-2 .media .image {
  width: 100%; }

.ms-card .template-5 .media {
  width: 80px;
  min-width: 80px;
  max-width: 80px; }

.ms-card .template-5 .info, .ms-card .template-5 .media {
  -ms-flex: 0 1 auto; }

.ms-card .template-10 .media {
  width: 80px;
  min-width: 80px;
  max-width: 80px; }

.ms-card .template-8 .media .image {
  width: 100%;
  border-radius: 2px 2px 0 0; }

.ms-card .template-4 .info {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.54); }

.ms-card .template-4 .media {
  z-index: 1; }
  .ms-card .template-4 .media .image {
    width: 100%; }

.ms-card .template-9 .header .avatar .image {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 20px; }

.ms-card .template-9 .media .image {
  width: 100%; }

.ms-stepper .ms-stepper-horizontal {
  background: #FFFFFF;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }
  .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation {
    height: 72px;
    min-height: 72px;
    max-height: 72px;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }
    .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      height: 72px;
      min-height: 72px;
      max-height: 72px;
      line-height: 24px;
      margin: 0;
      padding: 24px;
      border-radius: 0;
      cursor: pointer;
      font-size: 14px;
      text-transform: none; }
      .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item:before, .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        width: 24px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
      .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item:before {
        left: 0; }
      .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item:after {
        right: 0; }
      .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item:first-child:before {
        display: none; }
      .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item:last-child:after {
        display: none; }
      .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item.current .title {
        font-weight: 600;
        color: rgba(0, 0, 0, 0.87); }
      .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item.disabled {
        cursor: default; }
        .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item.disabled .step {
          background: rgba(0, 0, 0, 0.12); }
        .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item.disabled .title {
          color: rgba(0, 0, 0, 0.27); }
        .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item.disabled .subtitle {
          color: rgba(0, 0, 0, 0.27); }
      .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item.optional {
        padding: 16px 24px; }
      .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item .step {
        width: 24px;
        min-width: 24px;
        height: 24px;
        border-radius: 50%;
        margin: 0 8px 0 16px;
        color: #FFFFFF; }
        .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item .step .icon {
          color: #FFFFFF;
          font-weight: bold;
          line-height: 24px !important; }
      .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item .title {
        padding-right: 16px;
        color: rgba(0, 0, 0, 0.54); }
      .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item .subtitle {
        font-size: 12px;
        padding-right: 16px;
        color: rgba(0, 0, 0, 0.54);
        line-height: 1; }
  .ms-stepper .ms-stepper-horizontal .ms-stepper-steps {
    padding: 24px 16px; }
  .ms-stepper .ms-stepper-horizontal .ms-stepper-controls {
    padding: 0 16px 16px 16px; }
    .ms-stepper .ms-stepper-horizontal .ms-stepper-controls .ms-stepper-dots {
      display: none;
      align-items: center;
      justify-content: center;
      padding: 0 16px; }
      .ms-stepper .ms-stepper-horizontal .ms-stepper-controls .ms-stepper-dots span {
        width: 5px;
        height: 5px;
        margin: 0 4px;
        border-radius: 50%; }
        .ms-stepper .ms-stepper-horizontal .ms-stepper-controls .ms-stepper-dots span:not(.md-accent-bg) {
          background: rgba(0, 0, 0, 0.12); }
        .ms-stepper .ms-stepper-horizontal .ms-stepper-controls .ms-stepper-dots span.selected {
          width: 8px;
          height: 8px; }

@media screen and (max-width: 600px) {
  .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation {
    display: none; }
  .ms-stepper .ms-stepper-horizontal .ms-stepper-controls .ms-stepper-dots {
    display: flex; } }

.ms-stepper .ms-stepper-vertical {
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }
  .ms-stepper .ms-stepper-vertical .ms-stepper-vertical-line {
    display: block;
    position: absolute;
    width: 1px;
    top: 32px;
    bottom: 32px;
    left: 43px;
    border-left: 1px solid rgba(0, 0, 0, 0.12); }
  .ms-stepper .ms-stepper-vertical .ms-stepper-steps {
    padding: 8px 0; }
    .ms-stepper .ms-stepper-vertical .ms-stepper-steps .ms-stepper-step {
      display: block; }
      .ms-stepper .ms-stepper-vertical .ms-stepper-steps .ms-stepper-step .ms-stepper-navigation-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        width: 100%;
        height: 72px;
        min-height: 72px;
        max-height: 72px;
        line-height: 24px;
        margin: 0;
        padding: 0 24px;
        border-radius: 0;
        cursor: pointer;
        font-size: 14px;
        text-transform: none; }
        .ms-stepper .ms-stepper-vertical .ms-stepper-steps .ms-stepper-step .ms-stepper-navigation-item:first-child:before {
          display: none; }
        .ms-stepper .ms-stepper-vertical .ms-stepper-steps .ms-stepper-step .ms-stepper-navigation-item:last-child:after {
          display: none; }
        .ms-stepper .ms-stepper-vertical .ms-stepper-steps .ms-stepper-step .ms-stepper-navigation-item:hover:not(.disabled) .step {
          background-color: #ececec;
          border-color: #ececec; }
        .ms-stepper .ms-stepper-vertical .ms-stepper-steps .ms-stepper-step .ms-stepper-navigation-item.current .title {
          font-weight: 600;
          color: rgba(0, 0, 0, 0.87); }
        .ms-stepper .ms-stepper-vertical .ms-stepper-steps .ms-stepper-step .ms-stepper-navigation-item.disabled {
          cursor: default; }
          .ms-stepper .ms-stepper-vertical .ms-stepper-steps .ms-stepper-step .ms-stepper-navigation-item.disabled .title {
            color: rgba(0, 0, 0, 0.27); }
          .ms-stepper .ms-stepper-vertical .ms-stepper-steps .ms-stepper-step .ms-stepper-navigation-item.disabled .subtitle {
            color: rgba(0, 0, 0, 0.27); }
        .ms-stepper .ms-stepper-vertical .ms-stepper-steps .ms-stepper-step .ms-stepper-navigation-item.optional {
          padding: 16px 24px; }
        .ms-stepper .ms-stepper-vertical .ms-stepper-steps .ms-stepper-step .ms-stepper-navigation-item .step {
          width: 40px;
          min-width: 40px;
          height: 40px;
          margin: 0 16px 0 0;
          border-radius: 50%;
          background: #FFFFFF;
          border: 8px solid #FFFFFF;
          color: #FFFFFF;
          transition: border-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }
          .ms-stepper .ms-stepper-vertical .ms-stepper-steps .ms-stepper-step .ms-stepper-navigation-item .step .icon {
            font-weight: bold;
            line-height: 28px !important; }
        .ms-stepper .ms-stepper-vertical .ms-stepper-steps .ms-stepper-step .ms-stepper-navigation-item .title {
          padding-right: 16px;
          color: rgba(0, 0, 0, 0.54); }
        .ms-stepper .ms-stepper-vertical .ms-stepper-steps .ms-stepper-step .ms-stepper-navigation-item .subtitle {
          font-size: 12px;
          padding-right: 16px;
          color: rgba(0, 0, 0, 0.54);
          line-height: 1; }
      .ms-stepper .ms-stepper-vertical .ms-stepper-steps .ms-stepper-step .ms-stepper-step-content {
        padding: 0 24px 16px 64px; }
      .ms-stepper .ms-stepper-vertical .ms-stepper-steps .ms-stepper-step .ms-stepper-controls {
        padding: 0 16px 16px 56px; }

.ms-theme-options .ms-theme-options-button {
  position: absolute;
  top: 160px;
  right: 0;
  z-index: 50;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  width: 48px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  cursor: pointer;
  border-radius: 0;
  margin: 0;
  pointer-events: auto;
  opacity: 0.75; }
  .ms-theme-options .ms-theme-options-button:hover {
    opacity: 1; }

.ms-theme-options md-sidenav {
  width: 300px;
  min-width: 300px;
  max-width: 300px; }
  .ms-theme-options md-sidenav .ms-theme-options-list {
    background: #FFFFFF;
    z-index: 12;
    pointer-events: auto; }
    .ms-theme-options md-sidenav .ms-theme-options-list .theme-option {
      padding: 16px; }
      .ms-theme-options md-sidenav .ms-theme-options-list .theme-option.layout-options md-radio-button {
        width: 150px;
        max-width: 150px;
        margin: 8px 8px 16px 8px; }
        .ms-theme-options md-sidenav .ms-theme-options-list .theme-option.layout-options md-radio-button:hover ._md-label img, .ms-theme-options md-sidenav .ms-theme-options-list .theme-option.layout-options md-radio-button.md-checked ._md-label img {
          opacity: 1;
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }
        .ms-theme-options md-sidenav .ms-theme-options-list .theme-option.layout-options md-radio-button ._md-container {
          display: none; }
        .ms-theme-options md-sidenav .ms-theme-options-list .theme-option.layout-options md-radio-button ._md-label {
          margin-left: 0; }
          .ms-theme-options md-sidenav .ms-theme-options-list .theme-option.layout-options md-radio-button ._md-label img {
            opacity: 0.8;
            margin-bottom: 8px; }
      .ms-theme-options md-sidenav .ms-theme-options-list .theme-option.color-palettes md-menu-item {
        max-width: 192px; }
      .ms-theme-options md-sidenav .ms-theme-options-list .theme-option.color-palettes .theme-button {
        border-bottom: 16px solid;
        text-align: left;
        width: 75px;
        height: 65px;
        box-shadow: none;
        opacity: 0.8; }
        .ms-theme-options md-sidenav .ms-theme-options-list .theme-option.color-palettes .theme-button:hover, .ms-theme-options md-sidenav .ms-theme-options-list .theme-option.color-palettes .theme-button.active {
          opacity: 1;
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }
      .ms-theme-options md-sidenav .ms-theme-options-list .theme-option md-radio-button {
        margin: 8px; }
      .ms-theme-options md-sidenav .ms-theme-options-list .theme-option .option-title {
        font-size: 14px;
        font-weight: 500;
        padding: 0 8px 12px 8px; }

/*----------------------------------------------------------------*/
/*  ms-card
/*----------------------------------------------------------------*/
.ms-card {
  display: flex;
  flex-direction: row;
  padding: 8px; }
  .ms-card .ms-card-content-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 400px;
    border-radius: 2px;
    background: white;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.14), 0px 2px 2px 0px rgba(0, 0, 0, 0.098), 0px 1px 5px 0px rgba(0, 0, 0, 0.084); }

.ms-form-wizard {
  background: #FFFFFF;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }
  .ms-form-wizard md-tabs md-tabs-wrapper {
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }
    .ms-form-wizard md-tabs md-tabs-wrapper md-tabs-canvas {
      height: 72px; }
      .ms-form-wizard md-tabs md-tabs-wrapper md-tabs-canvas md-pagination-wrapper {
        height: 72px; }
        .ms-form-wizard md-tabs md-tabs-wrapper md-tabs-canvas md-pagination-wrapper md-tab-item {
          opacity: 1 !important;
          padding: 24px; }
          .ms-form-wizard md-tabs md-tabs-wrapper md-tabs-canvas md-pagination-wrapper md-tab-item:before {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            right: 50%;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            z-index: 8; }
          .ms-form-wizard md-tabs md-tabs-wrapper md-tabs-canvas md-pagination-wrapper md-tab-item:after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            right: 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            z-index: 8; }
          .ms-form-wizard md-tabs md-tabs-wrapper md-tabs-canvas md-pagination-wrapper md-tab-item:first-of-type:before {
            display: none; }
          .ms-form-wizard md-tabs md-tabs-wrapper md-tabs-canvas md-pagination-wrapper md-tab-item:last-of-type:after {
            display: none; }
          .ms-form-wizard md-tabs md-tabs-wrapper md-tabs-canvas md-pagination-wrapper md-tab-item.md-disabled .ms-form-wizard-step-label .ms-form-wizard-step-number {
            background: rgba(0, 0, 0, 0.12) !important; }
          .ms-form-wizard md-tabs md-tabs-wrapper md-tabs-canvas md-pagination-wrapper md-tab-item .md-ripple-container {
            z-index: 11; }
      .ms-form-wizard md-tabs md-tabs-wrapper md-tabs-canvas md-ink-bar {
        display: none !important; }
  .ms-form-wizard .ms-form-wizard-step-label {
    display: inline-block;
    position: relative;
    background: #FFFFFF;
    z-index: 10;
    padding: 0 16px;
    line-height: 24px; }
    .ms-form-wizard .ms-form-wizard-step-label .ms-form-wizard-step-number {
      display: inline-block;
      vertical-align: middle;
      width: 24px;
      height: 24px;
      line-height: 24px;
      font-size: 14px;
      margin-right: 12px;
      border-radius: 50%;
      color: #FFFFFF !important; }
      .ms-form-wizard .ms-form-wizard-step-label .ms-form-wizard-step-number i {
        line-height: 24px !important; }
    .ms-form-wizard .ms-form-wizard-step-label .ms-form-wizard-step-text {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      line-height: 24px; }
  .ms-form-wizard .ms-form-wizard-form {
    padding: 24px 16px; }
  .ms-form-wizard .navigation {
    padding: 16px; }
    .ms-form-wizard .navigation .steps {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 16px; }
      .ms-form-wizard .navigation .steps span {
        width: 5px;
        height: 5px;
        margin: 0 4px;
        border-radius: 50%; }
        .ms-form-wizard .navigation .steps span:not(.md-accent-bg) {
          background: rgba(0, 0, 0, 0.12); }
        .ms-form-wizard .navigation .steps span.selected {
          width: 8px;
          height: 8px; }

body.ms-info-bar-active #main {
  top: 64px; }

ms-info-bar {
  display: block;
  position: relative;
  width: 100%;
  height: 64px;
  background-color: #E91E63;
  z-index: 99998;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12); }
  ms-info-bar #ms-info-bar-message {
    padding: 8px 16px;
    line-height: 48px;
    font-size: 16px; }
    ms-info-bar #ms-info-bar-message a {
      overflow: hidden;
      height: 48px;
      color: #FFFFFF; }

ms-masonry {
  width: 100%;
  display: block; }
  ms-masonry ms-masonry-item,
  ms-masonry [ms-masonry-item] {
    transition: transform 300ms ease;
    position: absolute;
    transform: translate3d(50vw, 100vh, 0); }

ms-nav {
  display: block;
  position: relative;
  color: rgba(255, 255, 255, 0.7);
  user-select: none;
  z-index: 70; }
  ms-nav ms-nav-item {
    display: block;
    position: relative;
    line-height: 48px; }
    ms-nav ms-nav-item.ms-nav-title {
      line-height: 32px;
      font-size: 12px;
      font-weight: 500;
      margin-top: 8px;
      padding: 0 24px;
      width: 100%;
      color: rgba(255, 255, 255, 0.3);
      text-transform: uppercase;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    ms-nav ms-nav-item .ms-nav-button {
      display: flex;
      align-self: flex-start;
      align-items: center;
      position: relative;
      cursor: pointer;
      padding: 0 48px 0 24px;
      color: rgba(255, 255, 255, 0.7);
      text-decoration: none; }
      ms-nav ms-nav-item .ms-nav-button:not(.active):hover {
        background: rgba(0, 0, 0, 0.1);
        text-decoration: none; }
      ms-nav ms-nav-item .ms-nav-button .ms-nav-label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: opacity 0.2s ease-in-out 0.1s; }
      ms-nav ms-nav-item .ms-nav-button .arrow {
        position: absolute;
        top: 16px;
        right: 24px;
        margin: 0;
        transition: transform 0.3s ease-in-out, opacity 0.2s ease-in-out 0.1s; }
      ms-nav ms-nav-item .ms-nav-button .ms-nav-badge {
        position: absolute;
        top: 50%;
        right: 24px;
        min-width: 20px;
        height: 20px;
        line-height: 20px;
        padding: 0 7px;
        font-size: 11px;
        font-weight: bold;
        border-radius: 20px;
        transform: translateY(-50%);
        transition: opacity 0.2s ease-in-out 0.1s; }
      ms-nav ms-nav-item .ms-nav-button.active {
        color: #FFFFFF !important; }
        ms-nav ms-nav-item .ms-nav-button.active .ms-nav-badge {
          background: #FFFFFF !important;
          color: rgba(0, 0, 0, 0.87) !important; }
        ms-nav ms-nav-item .ms-nav-button.active i {
          color: #FFFFFF; }
    ms-nav ms-nav-item i {
      color: rgba(255, 255, 255, 0.7);
      margin: 0 16px 0 0; }
  ms-nav .ms-nav-toggle ms-nav-toggle-items {
    display: none;
    overflow: hidden; }
    ms-nav .ms-nav-toggle ms-nav-toggle-items.expand-animation {
      transition: height 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
    ms-nav .ms-nav-toggle ms-nav-toggle-items.collapse-animation {
      transition: height 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
    ms-nav .ms-nav-toggle ms-nav-toggle-items ms-nav-item {
      /* Level 1 */
      /* Level 2 */ }
      ms-nav .ms-nav-toggle ms-nav-toggle-items ms-nav-item .ms-nav-button {
        padding-left: 58px; }
      ms-nav .ms-nav-toggle ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item {
        /* Level 3 */ }
        ms-nav .ms-nav-toggle ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item .ms-nav-button {
          padding-left: 68px; }
        ms-nav .ms-nav-toggle ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item {
          /* Level 4 */ }
          ms-nav .ms-nav-toggle ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item .ms-nav-button {
            padding-left: 78px; }
          ms-nav .ms-nav-toggle ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item {
            /* Level 5 */ }
            ms-nav .ms-nav-toggle ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item .ms-nav-button {
              padding-left: 88px; }
            ms-nav .ms-nav-toggle ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item .ms-nav-button {
              padding-left: 98px; }
  ms-nav .ms-nav-toggle.active > .ms-nav-button {
    color: white; }
    ms-nav .ms-nav-toggle.active > .ms-nav-button a,
    ms-nav .ms-nav-toggle.active > .ms-nav-button md-icon {
      color: white; }
  ms-nav .ms-nav-toggle[collapsed="false"] > .ms-nav-button > .arrow {
    transform: rotate(90deg); }
  ms-nav > .ms-nav-toggle[collapsed="false"] {
    background-color: rgba(0, 0, 0, 0.12); }

@media only screen and (min-width: 1280px) {
  .ms-nav-folded #ms-nav-fold-close-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    z-index: 998; }
  .ms-nav-folded #ms-nav-fold-open-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999; }
  .ms-nav-folded:not(.ms-nav-folded-open) ms-nav ms-nav-item.ms-nav-title {
    height: 32px; }
    .ms-nav-folded:not(.ms-nav-folded-open) ms-nav ms-nav-item.ms-nav-title:before {
      content: '';
      display: block;
      position: relative;
      top: 12px;
      width: 16px;
      border-top: 1px solid rgba(255, 255, 255, 0.12); }
    .ms-nav-folded:not(.ms-nav-folded-open) ms-nav ms-nav-item.ms-nav-title span {
      display: none; }
  .ms-nav-folded:not(.ms-nav-folded-open) ms-nav ms-nav-item .ms-nav-button {
    height: 48px; }
    .ms-nav-folded:not(.ms-nav-folded-open) ms-nav ms-nav-item .ms-nav-button > .ms-nav-label,
    .ms-nav-folded:not(.ms-nav-folded-open) ms-nav ms-nav-item .ms-nav-button > .ms-nav-badge,
    .ms-nav-folded:not(.ms-nav-folded-open) ms-nav ms-nav-item .ms-nav-button > .arrow {
      opacity: 0;
      visibility: hidden; } }

/*----------------------------------------------------------------*/
/*  ms-material-color-picker
/*----------------------------------------------------------------*/
ms-material-color-picker md-menu .md-button {
  font-size: 12px; }

md-menu-content.ms-material-color-picker-menu-content {
  width: 208px;
  max-width: 208px;
  min-width: 208px;
  padding: 0;
  overflow: hidden; }
  md-menu-content.ms-material-color-picker-menu-content header {
    height: 48px;
    text-transform: uppercase; }
    md-menu-content.ms-material-color-picker-menu-content header .md-button {
      display: flex;
      margin: 0; }
    md-menu-content.ms-material-color-picker-menu-content header > span {
      line-height: 13px; }
  md-menu-content.ms-material-color-picker-menu-content .colors {
    position: relative;
    width: 208px;
    min-height: 258px;
    height: 258px;
    padding: 4px; }
    md-menu-content.ms-material-color-picker-menu-content .colors .color {
      position: relative;
      width: 46px;
      height: 46px;
      margin: 2px;
      border-radius: 0;
      cursor: pointer; }
      md-menu-content.ms-material-color-picker-menu-content .colors .color .label {
        padding: 2px;
        font-size: 10px; }
      md-menu-content.ms-material-color-picker-menu-content .colors .color .icon-check {
        position: absolute;
        top: 2px;
        right: 2px;
        font-size: 16px;
        opacity: 0.7; }

/*----------------------------------------------------------------*/
/*  ms-navigation
/*----------------------------------------------------------------*/
.ms-navigation {
  display: block;
  position: relative;
  color: rgba(255, 255, 255, 0.7);
  user-select: none; }
  .ms-navigation ul, .ms-navigation li {
    padding: 0;
    margin: 0; }
  .ms-navigation ul {
    display: block;
    overflow: hidden; }
    .ms-navigation ul li {
      position: relative; }
      .ms-navigation ul li.collapsed ul {
        display: none; }
  .ms-navigation .animate-height {
    transition: height 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
  .ms-navigation .ms-navigation-node .ms-navigation-item {
    display: block;
    position: relative;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.7); }
    .ms-navigation .ms-navigation-node .ms-navigation-item:hover {
      background: rgba(0, 0, 0, 0.1); }
    .ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button {
      display: flex;
      position: relative;
      width: 100%;
      height: 48px;
      line-height: 48px;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding-right: 16px;
      color: rgba(255, 255, 255, 0.7);
      text-decoration: none;
      white-space: nowrap;
      overflow: hidden; }
      .ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button .icon {
        color: rgba(255, 255, 255, 0.7);
        margin: 0 16px 0 0; }
      .ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button span {
        white-space: nowrap;
        overflow: hidden;
        opacity: 1;
        transition: opacity 0.25s ease-in-out 0.1s; }
      .ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button a {
        white-space: nowrap;
        overflow: hidden;
        opacity: 1;
        transition: opacity 0.25s ease-in-out 0.1s; }
      .ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button .arrow {
        margin: 0;
        opacity: 1;
        transition: transform 0.3s ease-in-out, opacity 0.25s ease-in-out 0.1s; }
      .ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button .badge {
        position: absolute;
        top: 50%;
        right: 16px;
        min-width: 20px;
        height: 20px;
        line-height: 20px;
        padding: 0 7px;
        font-size: 11px;
        font-weight: bold;
        border-radius: 20px;
        transform: translateY(-50%);
        transition: opacity 0.2s ease-in-out 0.1s; }
      .ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button.active {
        color: #FFFFFF; }
        .ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button.active .icon {
          color: #FFFFFF; }
        .ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button.active .badge {
          background: #FFFFFF !important;
          color: rgba(0, 0, 0, 0.87) !important; }
  .ms-navigation .ms-navigation-node:not(.collapsed) > .ms-navigation-item > .ms-navigation-button .arrow {
    transform: rotate(90deg); }
  .ms-navigation .ms-navigation-node.group > .ms-navigation-item {
    font-size: 12px;
    font-weight: 500;
    margin-top: 8px;
    cursor: auto;
    background: none !important;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    .ms-navigation .ms-navigation-node.group > .ms-navigation-item > .ms-navigation-button {
      height: 32px;
      line-height: 32px;
      color: rgba(255, 255, 255, 0.3); }
  .ms-navigation .ms-navigation-node:not(.group).has-children:not(.collapsed) {
    background-color: rgba(0, 0, 0, 0.12); }
  .ms-navigation .ms-navigation-node:not(.group).has-children.collapsing {
    background-color: rgba(0, 0, 0, 0.12); }
  .ms-navigation .ms-navigation-node:not(.group).has-children .has-children:not(.collapsed) {
    background: none; }
  .ms-navigation li .ms-navigation-button {
    padding-left: 24px; }
  .ms-navigation li li .ms-navigation-button {
    padding-left: 56px; }
  .ms-navigation li li li .ms-navigation-button {
    padding-left: 72px; }
  .ms-navigation li li li li .ms-navigation-button {
    padding-left: 88px; }
  .ms-navigation li li li li li .ms-navigation-button {
    padding-left: 104px; }
  .ms-navigation li.group .ms-navigation-button {
    padding-left: 24px; }
  .ms-navigation li.group li .ms-navigation-button {
    padding-left: 24px; }
  .ms-navigation li.group li li .ms-navigation-button {
    padding-left: 56px; }
  .ms-navigation li.group li li li .ms-navigation-button {
    padding-left: 72px; }
  .ms-navigation li.group li li li li .ms-navigation-button {
    padding-left: 88px; }

#horizontal-navigation {
  height: 64px;
  min-height: 64px;
  max-height: 64px;
  background: #FFFFFF;
  z-index: 54; }
  #horizontal-navigation .ms-navigation-horizontal {
    display: flex;
    position: relative;
    user-select: none; }
    #horizontal-navigation .ms-navigation-horizontal ul, #horizontal-navigation .ms-navigation-horizontal li {
      padding: 0;
      margin: 0;
      list-style: none; }
    #horizontal-navigation .ms-navigation-horizontal .horizontal {
      display: flex;
      flex: 1; }
      #horizontal-navigation .ms-navigation-horizontal .horizontal li {
        position: relative;
        cursor: pointer; }
        #horizontal-navigation .ms-navigation-horizontal .horizontal li:not(.active):hover {
          background: rgba(0, 0, 0, 0.12); }
      #horizontal-navigation .ms-navigation-horizontal .horizontal > li > .ms-navigation-horizontal-item > .ms-navigation-horizontal-button {
        display: block;
        float: left;
        padding: 12px 14px; }
        #horizontal-navigation .ms-navigation-horizontal .horizontal > li > .ms-navigation-horizontal-item > .ms-navigation-horizontal-button .arrow {
          display: none; }
        #horizontal-navigation .ms-navigation-horizontal .horizontal > li > .ms-navigation-horizontal-item > .ms-navigation-horizontal-button.active {
          background: none;
          border-bottom: 2px solid; }
      #horizontal-navigation .ms-navigation-horizontal .horizontal > li:last-child {
        margin-right: 0; }
      #horizontal-navigation .ms-navigation-horizontal .horizontal > li li .ms-navigation-horizontal-item .ms-navigation-horizontal-button.active {
        color: #FFFFFF !important; }
      #horizontal-navigation .ms-navigation-horizontal .horizontal .has-children ul {
        display: none;
        position: absolute;
        background: #FFFFFF;
        z-index: 999;
        min-width: 200px;
        box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
        top: 0;
        left: 100%; }
      #horizontal-navigation .ms-navigation-horizontal .horizontal .has-children:hover > ul {
        display: block; }
      #horizontal-navigation .ms-navigation-horizontal .horizontal > .has-children > ul {
        top: 100%;
        left: 0; }
      #horizontal-navigation .ms-navigation-horizontal .horizontal .ms-navigation-horizontal-item .ms-navigation-horizontal-button {
        display: flex;
        align-items: center;
        width: 100%;
        color: rgba(0, 0, 0, 0.87);
        white-space: nowrap;
        padding: 14px 16px;
        text-decoration: none; }
        #horizontal-navigation .ms-navigation-horizontal .horizontal .ms-navigation-horizontal-item .ms-navigation-horizontal-button .icon {
          color: rgba(0, 0, 0, 0.54);
          margin-right: 8px; }
        #horizontal-navigation .ms-navigation-horizontal .horizontal .ms-navigation-horizontal-item .ms-navigation-horizontal-button .badge {
          min-width: 20px;
          height: 20px;
          line-height: 20px;
          padding: 0 7px;
          font-size: 11px;
          font-weight: bold;
          border-radius: 20px;
          transition: opacity 0.2s ease-in-out 0.1s; }
        #horizontal-navigation .ms-navigation-horizontal .horizontal .ms-navigation-horizontal-item .ms-navigation-horizontal-button .arrow {
          margin-left: 8px; }
        #horizontal-navigation .ms-navigation-horizontal .horizontal .ms-navigation-horizontal-item .ms-navigation-horizontal-button.active .badge {
          background: #FFFFFF !important;
          color: rgba(0, 0, 0, 0.87) !important; }
        #horizontal-navigation .ms-navigation-horizontal .horizontal .ms-navigation-horizontal-item .ms-navigation-horizontal-button.active .icon {
          color: #FFFFFF; }

@media only screen and (min-width: 960px) {
  body.ms-navigation-folded:not(.ms-navigation-folded-open) .ms-navigation .animate-height {
    transition: none; }
  body.ms-navigation-folded:not(.ms-navigation-folded-open) .ms-navigation .ms-navigation-node .ms-navigation-item .ms-navigation-button .icon {
    margin: 0; }
  body.ms-navigation-folded:not(.ms-navigation-folded-open) .ms-navigation .ms-navigation-node .ms-navigation-item .ms-navigation-button .title,
  body.ms-navigation-folded:not(.ms-navigation-folded-open) .ms-navigation .ms-navigation-node .ms-navigation-item .ms-navigation-button .arrow,
  body.ms-navigation-folded:not(.ms-navigation-folded-open) .ms-navigation .ms-navigation-node .ms-navigation-item .ms-navigation-button .badge {
    opacity: 0;
    visibility: hidden; }
  body.ms-navigation-folded:not(.ms-navigation-folded-open) .ms-navigation .ms-navigation-node.group > .ms-navigation-item .ms-navigation-button:before {
    content: '';
    display: block;
    position: relative;
    width: 16px;
    border-top: 1px solid rgba(255, 255, 255, 0.12); }
  body.ms-navigation-folded:not(.ms-navigation-folded-open) #ms-navigation-fold-expander {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999; }
  body.ms-navigation-folded #ms-navigation-fold-collapser {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 59; } }

@media only screen and (max-width: 960px) {
  body.ms-navigation-horizontal-mobile-menu-active #horizontal-navigation .navigation-toggle {
    height: 64px;
    margin-right: 16px; }
    body.ms-navigation-horizontal-mobile-menu-active #horizontal-navigation .navigation-toggle .md-button {
      margin: 0;
      width: 64px;
      height: 64px; }
  body.ms-navigation-horizontal-mobile-menu-active #horizontal-navigation .ms-navigation-horizontal {
    display: block !important;
    background: #FFFFFF; }
  #horizontal-navigation {
    z-index: 60; }
    #horizontal-navigation .ms-navigation-horizontal {
      position: absolute;
      display: none;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 0 0 16px 0;
      z-index: 9998;
      overflow-x: hidden;
      overflow-y: scroll; }
      #horizontal-navigation .ms-navigation-horizontal .horizontal {
        flex-direction: column;
        min-height: 100%;
        width: 100%; }
        #horizontal-navigation .ms-navigation-horizontal .horizontal li:not(.active):hover {
          background: none; }
        #horizontal-navigation .ms-navigation-horizontal .horizontal li:not(.active) .ms-navigation-horizontal-item {
          margin: 4px; }
          #horizontal-navigation .ms-navigation-horizontal .horizontal li:not(.active) .ms-navigation-horizontal-item:hover {
            background: rgba(0, 0, 0, 0.12); }
        #horizontal-navigation .ms-navigation-horizontal .horizontal > li {
          padding: 0 12px; }
          #horizontal-navigation .ms-navigation-horizontal .horizontal > li > .ms-navigation-horizontal-item > .ms-navigation-horizontal-button {
            display: flex;
            float: none; }
            #horizontal-navigation .ms-navigation-horizontal .horizontal > li > .ms-navigation-horizontal-item > .ms-navigation-horizontal-button .arrow {
              display: block; }
        #horizontal-navigation .ms-navigation-horizontal .horizontal .has-children ul {
          position: relative;
          top: 0;
          left: 0;
          box-shadow: none; }
        #horizontal-navigation .ms-navigation-horizontal .horizontal .has-children:hover > ul {
          display: none; }
        #horizontal-navigation .ms-navigation-horizontal .horizontal .has-children .expanded > .ms-navigation-horizontal-button .arrow {
          transform: rotate(90deg); }
        #horizontal-navigation .ms-navigation-horizontal .horizontal .has-children .expanded + ul {
          display: block;
          margin-left: 16px; }
        #horizontal-navigation .ms-navigation-horizontal .horizontal .ms-navigation-horizontal-item {
          min-height: 48px;
          height: 48px; } }

/*----------------------------------------------------------------*/
/*  ms-responsive-table
/*----------------------------------------------------------------*/
.ms-responsive-table-wrapper {
  overflow-x: auto;
  overflow-y: hidden; }

/*----------------------------------------------------------------*/
/*  ms-scroll
/*----------------------------------------------------------------*/
.ms-scroll {
  overflow: hidden !important; }

/*----------------------------------------------------------------*/
/*  ms-shortcuts
/*----------------------------------------------------------------*/
.ms-shortcuts {
  height: 64px;
  font-size: 13px; }
  .ms-shortcuts .shortcuts-container {
    height: 64px;
    padding: 0 8px;
    max-width: 400px; }
    .ms-shortcuts .shortcuts-container .shortcuts {
      position: relative;
      overflow-x: scroll; }
      .ms-shortcuts .shortcuts-container .shortcuts > div {
        height: 64px;
        min-width: 44px; }
        .ms-shortcuts .shortcuts-container .shortcuts > div.dragging {
          cursor: grabbing; }
          .ms-shortcuts .shortcuts-container .shortcuts > div.dragging .md-ripple-container {
            display: none; }
        .ms-shortcuts .shortcuts-container .shortcuts > div.ghost .shortcut {
          opacity: 0.27; }
        .ms-shortcuts .shortcuts-container .shortcuts > div .shortcut {
          margin: 2px; }
          .ms-shortcuts .shortcuts-container .shortcuts > div .shortcut .first-letter {
            display: inline-block;
            vertical-align: middle;
            font-size: 20px;
            text-transform: uppercase;
            color: rgba(0, 0, 0, 0.54); }
    .ms-shortcuts .shortcuts-container .shortcut-menu {
      margin: 0; }
    .ms-shortcuts .shortcuts-container.mobile-bar-active {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #FFFFFF;
      z-index: 99;
      max-width: none !important; }
      .ms-shortcuts .shortcuts-container.mobile-bar-active .shortcuts,
      .ms-shortcuts .shortcuts-container.mobile-bar-active .shortcut-menu,
      .ms-shortcuts .shortcuts-container.mobile-bar-active .toggle-mobile-menu {
        display: flex !important; }
      .ms-shortcuts .shortcuts-container.mobile-bar-active .toggle-mobile-menu {
        margin-left: 14px;
        min-width: 40px; }
      .ms-shortcuts .shortcuts-container.mobile-bar-active > div {
        border-right: 1px solid rgba(0, 0, 0, 0.12); }

#ms-shortcut-add-menu {
  position: relative;
  width: 240px;
  min-width: 240px;
  max-width: 240px; }
  #ms-shortcut-add-menu .add-shortcut-button {
    width: 48px;
    height: 48px;
    padding: 0 12px;
    margin: 0; }
  #ms-shortcut-add-menu .shortcut-search {
    height: 45px;
    padding: 0 8px; }
    #ms-shortcut-add-menu .shortcut-search .search-icon {
      width: 40px;
      height: 40px;
      line-height: 48px; }
    #ms-shortcut-add-menu .shortcut-search .shortcut-loader {
      padding: 10px; }
    #ms-shortcut-add-menu .shortcut-search input {
      padding: 8px; }
  #ms-shortcut-add-menu .results {
    position: relative;
    overflow-y: scroll;
    max-height: 233px;
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px solid rgba(0, 0, 0, 0.12); }
    #ms-shortcut-add-menu .results .no-results {
      font-size: 18px;
      padding: 16px;
      color: rgba(0, 0, 0, 0.54); }
    #ms-shortcut-add-menu .results .result {
      position: relative;
      min-height: 56px;
      padding: 16px;
      cursor: pointer; }
      #ms-shortcut-add-menu .results .result.selected {
        background: rgba(0, 0, 0, 0.07); }
      #ms-shortcut-add-menu .results .result .icon-container {
        min-width: 32px;
        margin-right: 8px; }
        #ms-shortcut-add-menu .results .result .icon-container .square {
          width: 6px;
          height: 6px; }
      #ms-shortcut-add-menu .results .result .title {
        font-size: 16px; }
      #ms-shortcut-add-menu .results .result .description {
        padding-top: 6px;
        font-size: 14px; }

/*----------------------------------------------------------------*/
/*  ms-search-bar
/*----------------------------------------------------------------*/
.ms-search-bar {
  height: 64px;
  font-size: 13px; }
  .ms-search-bar .ms-search-bar-expander,
  .ms-search-bar .ms-search-bar-collapser {
    cursor: pointer;
    padding: 0 20px;
    margin: 0;
    width: 64px !important;
    height: 64px !important;
    line-height: 64px !important; }
  .ms-search-bar .ms-search-bar-loader {
    width: 64px !important;
    height: 64px !important;
    line-height: 64px !important; }
  .ms-search-bar .ms-search-bar-collapser {
    display: none; }
  .ms-search-bar #ms-search-bar-input {
    display: none;
    min-height: 64px;
    background-color: transparent;
    font-size: 16px; }
  .ms-search-bar .ms-search-bar-results {
    position: absolute;
    top: 64px;
    left: 0;
    right: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    background: #FFFFFF;
    max-height: 300px;
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12); }
    .ms-search-bar .ms-search-bar-results .no-results {
      font-size: 18px;
      padding: 16px;
      color: rgba(0, 0, 0, 0.54); }
    .ms-search-bar .ms-search-bar-results .result {
      position: relative;
      height: 56px;
      min-height: 56px;
      max-height: 56px;
      padding: 16px;
      cursor: pointer; }
      .ms-search-bar .ms-search-bar-results .result.selected {
        background: rgba(0, 0, 0, 0.07); }
      .ms-search-bar .ms-search-bar-results .result .icon-container {
        min-width: 32px;
        margin-right: 8px; }
        .ms-search-bar .ms-search-bar-results .result .icon-container .square {
          width: 6px;
          height: 6px; }
      .ms-search-bar .ms-search-bar-results .result .title {
        font-size: 16px; }
      .ms-search-bar .ms-search-bar-results .result .description {
        padding-top: 6px;
        font-size: 14px; }
  .ms-search-bar.expanded {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #FFFFFF;
    z-index: 10; }
    .ms-search-bar.expanded #ms-search-bar-input {
      display: block; }
    .ms-search-bar.expanded .ms-search-bar-collapser {
      display: block; }

body.ms-search-bar-expanded #toolbar {
  z-index: 999 !important; }

@media screen and (max-width: 960px) {
  .ms-search-bar-label {
    padding: 0 8px 0 12px; } }

#splash-screen {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #3C4252;
  z-index: 99999; }
  #splash-screen.ng-leave {
    opacity: 1;
    transition: all linear 200ms; }
  #splash-screen.ng-leave-active {
    opacity: 0; }
  #splash-screen .center {
    display: block;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
  #splash-screen .logo {
    width: 150px;
    height: 150px;
    line-height: 96px;
    margin: 0 auto;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 56px;
    text-align: center;
    border-radius: 2px;
    /*background-color: #039BE5;
        color: #FFFFFF;
        box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.22);*/ }
  #splash-screen .spinner-wrapper {
    display: block;
    position: relative;
    width: 100%;
    min-height: 100px;
    height: 100px; }
    #splash-screen .spinner-wrapper .spinner {
      position: absolute;
      overflow: hidden;
      left: 50%;
      margin-left: -50px;
      animation: outer-rotate 2.91667s linear infinite; }
      #splash-screen .spinner-wrapper .spinner .inner {
        width: 100px;
        height: 100px;
        position: relative;
        animation: sporadic-rotate 5.25s cubic-bezier(0.35, 0, 0.25, 1) infinite; }
        #splash-screen .spinner-wrapper .spinner .inner .gap {
          position: absolute;
          left: 49px;
          right: 49px;
          top: 0;
          bottom: 0;
          border-top: 10px solid;
          box-sizing: border-box; }
        #splash-screen .spinner-wrapper .spinner .inner .left,
        #splash-screen .spinner-wrapper .spinner .inner .right {
          position: absolute;
          top: 0;
          height: 100px;
          width: 50px;
          overflow: hidden; }
          #splash-screen .spinner-wrapper .spinner .inner .left .half-circle,
          #splash-screen .spinner-wrapper .spinner .inner .right .half-circle {
            position: absolute;
            top: 0;
            width: 100px;
            height: 100px;
            box-sizing: border-box;
            border: 10px solid #4285F4;
            border-bottom-color: transparent;
            border-radius: 50%; }
        #splash-screen .spinner-wrapper .spinner .inner .left {
          left: 0; }
          #splash-screen .spinner-wrapper .spinner .inner .left .half-circle {
            left: 0;
            border-right-color: transparent;
            animation: left-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
            -webkit-animation: left-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite; }
        #splash-screen .spinner-wrapper .spinner .inner .right {
          right: 0; }
          #splash-screen .spinner-wrapper .spinner .inner .right .half-circle {
            right: 0;
            border-left-color: transparent;
            animation: right-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
            -webkit-animation: right-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite; }

@keyframes outer-rotate {
  0% {
    transform: rotate(0deg) scale(0.5); }
  100% {
    transform: rotate(360deg) scale(0.5); } }

@keyframes left-wobble {
  0%, 100% {
    transform: rotate(130deg); }
  50% {
    transform: rotate(-5deg); } }

@keyframes right-wobble {
  0%, 100% {
    transform: rotate(-130deg); }
  50% {
    transform: rotate(5deg); } }

@keyframes sporadic-rotate {
  12.5% {
    transform: rotate(135deg); }
  25% {
    transform: rotate(270deg); }
  37.5% {
    transform: rotate(405deg); }
  50% {
    transform: rotate(540deg); }
  62.5% {
    transform: rotate(675deg); }
  75% {
    transform: rotate(810deg); }
  87.5% {
    transform: rotate(945deg); }
  100% {
    transform: rotate(1080deg); } }

.ms-timeline .ms-timeline-loader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  margin: 0 auto;
  background: #FFFFFF;
  font-weight: 500;
  opacity: 0;
  z-index: 11;
  border-radius: 50%; }
  .ms-timeline .ms-timeline-loader.show {
    opacity: 1; }
  .ms-timeline .ms-timeline-loader .spinner {
    width: 24px;
    height: 24px;
    border: 3px solid rgba(255, 255, 255, 0.35);
    border-top-color: #FFFFFF;
    border-radius: 50%; }

/*----------------------------------------------------------------*/
/*  ms-widget
/*----------------------------------------------------------------*/
.ms-widget .ms-widget-front {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }

.ms-widget .ms-widget-back {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }

.ms-widget.sidenav-widget .ms-widget-front,
.ms-widget.sidenav-widget .ms-widget-back {
  box-shadow: none; }

.ms-widget .flip-to-front {
  position: absolute;
  top: 0;
  right: 0; }

.ms-widget .c3 {
  height: 100%; }

.ms-widget .chart-fill-8 {
  margin: 0 -8px -8px -8px; }

.ms-widget .chart-fill-16 {
  margin: 0 -16px -16px -16px; }

.ms-widget .chart-fill-24 {
  margin: 0 -24px -24px -24px; }

.ms-widget .chart-fill-32 {
  margin: 0 -32px -32px -32px; }

.ms-widget .chart-fill-40 {
  margin: 0 -40px -40px -40px; }

.ms-widget .chart-fill-48 {
  margin: 0 -48px -48px -48px; }

.ms-widget .chart-fill-56 {
  margin: 0 -56px -56px -56px; }

.ms-widget .chart-fill-64 {
  margin: 0 -64px -64px -64px; }

.ms-widget .chart-fill-72 {
  margin: 0 -72px -72px -72px; }

.ms-widget .chart-fill-80 {
  margin: 0 -80px -80px -80px; }

.ms-widget {
  position: relative;
  font-size: 1.3rem;
  perspective: 3000px;
  padding: 12px; }
  .ms-widget .ms-widget-front {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    position: relative;
    overflow: hidden;
    visibility: visible;
    width: 100%;
    opacity: 1;
    z-index: 10;
    border-radius: 2px;
    transition: transform 0.5s ease-out 0s, visibility 0s ease-in 0.2s, opacity 0s ease-in 0.2s;
    transform: rotateY(0deg);
    backface-visibility: hidden; }
  .ms-widget .ms-widget-back {
    display: block;
    position: absolute;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    z-index: 10;
    top: 12px;
    right: 12px;
    bottom: 12px;
    left: 12px;
    border-radius: 2px;
    transition: transform 0.5s ease-out 0s, visibility 0s ease-in 0.2s, opacity 0s ease-in 0.2s;
    transform: rotateY(180deg);
    backface-visibility: hidden; }
  .ms-widget .md-button {
    margin: 0; }
    .ms-widget .md-button.md-icon-button {
      margin: 0; }
  .ms-widget.flipped .ms-widget-front {
    visibility: hidden;
    opacity: 0;
    transform: rotateY(180deg); }
  .ms-widget.flipped .ms-widget-back {
    display: block;
    visibility: visible;
    opacity: 1;
    transform: rotateY(360deg); }
  .ms-widget.sidenav-widget {
    padding: 0; }
  .ms-widget.ar-2-1 .widget {
    padding: 0;
    position: relative; }
    .ms-widget.ar-2-1 .widget:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: 50%; }
    .ms-widget.ar-2-1 .widget > .widget {
      position: absolute;
      top: 8px;
      left: 8px;
      right: 8px;
      bottom: 8px; }
  .ms-widget.ar-1-1 .widget {
    padding: 0;
    position: relative; }
    .ms-widget.ar-1-1 .widget:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: 100%; }
    .ms-widget.ar-1-1 .widget > .widget {
      position: absolute;
      top: 8px;
      left: 8px;
      right: 8px;
      bottom: 8px; }

#layout-vertical-navigation-fullwidth-toolbar-2 #vertical-navigation {
  width: 240px;
  min-width: 240px;
  max-width: 240px;
  overflow-x: hidden;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2), width 0.1s linear, min-width 0.1s linear, max-width 0.1s linear;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); }
  #layout-vertical-navigation-fullwidth-toolbar-2 #vertical-navigation .navigation-header {
    height: 64px;
    min-height: 64px;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 0 16px 0 24px; }
  #layout-vertical-navigation-fullwidth-toolbar-2 #vertical-navigation:not(._md-locked-open) {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }

@media only screen and (min-width: 960px) {
  .ms-navigation-folded #content-container {
    margin-left: 64px; }
  .ms-navigation-folded #layout-vertical-navigation-fullwidth-toolbar-2 #vertical-navigation {
    position: absolute; }
  .ms-navigation-folded:not(.ms-navigation-folded-open) #layout-vertical-navigation-fullwidth-toolbar-2 #vertical-navigation {
    position: absolute;
    width: 64px;
    min-width: 64px;
    max-width: 64px; }
    .ms-navigation-folded:not(.ms-navigation-folded-open) #layout-vertical-navigation-fullwidth-toolbar-2 #vertical-navigation .navigation-header {
      padding: 0 16px; }
      .ms-navigation-folded:not(.ms-navigation-folded-open) #layout-vertical-navigation-fullwidth-toolbar-2 #vertical-navigation .navigation-header .logo-text {
        display: none; } }

#vertical-navigation {
  width: 240px;
  min-width: 240px;
  max-width: 240px;
  overflow-x: hidden;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2), width 0.1s linear, min-width 0.1s linear, max-width 0.1s linear;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); }
  #vertical-navigation .navigation-header {
    height: 64px;
    min-height: 64px;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 0 16px 0 24px; }
    #vertical-navigation .navigation-header .logo .logo-image {
      display: block;
      background: #039BE5;
      width: 32px;
      min-width: 32px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      color: #FFFFFF;
      border-radius: 2px; }
    #vertical-navigation .navigation-header .logo .logo-text {
      color: #FFFFFF;
      margin-left: 16px;
      font-size: 16px; }
    #vertical-navigation .navigation-header .fold-toggle {
      transition: transform 0.3s ease-in-out 0.1s;
      transform: rotate(0deg);
      margin: 0;
      padding: 8px;
      width: 34px !important;
      height: 34px !important;
      cursor: pointer; }
  #vertical-navigation:not(._md-locked-open) {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }

@media only screen and (min-width: 960px) {
  .ms-navigation-folded #content-container {
    margin-left: 64px; }
  .ms-navigation-folded #vertical-navigation {
    position: absolute; }
    .ms-navigation-folded #vertical-navigation .navigation-header .fold-toggle {
      transform: rotate(180deg);
      opacity: 0; }
  .ms-navigation-folded.ms-navigation-folded-open #vertical-navigation .navigation-header .fold-toggle {
    transition: transform 0.3s ease-in-out 0.1s, opacity 0.3s ease-in-out 0.2s;
    opacity: 1; }
  .ms-navigation-folded:not(.ms-navigation-folded-open) #vertical-navigation {
    position: absolute;
    width: 64px;
    min-width: 64px;
    max-width: 64px; }
    .ms-navigation-folded:not(.ms-navigation-folded-open) #vertical-navigation .navigation-header {
      padding: 0 16px; }
      .ms-navigation-folded:not(.ms-navigation-folded-open) #vertical-navigation .navigation-header .logo-text {
        display: none; } }

#dashboard .header {
  height: 80px;
  max-height: 80px;
  min-height: 80px;
  padding: 15px; }

#quick-panel {
  width: 330px;
  min-width: 330px;
  max-width: 330px;
  z-index: 99; }
  #quick-panel md-content {
    height: 100%; }
    #quick-panel md-content md-tabs {
      height: 100%; }
      #quick-panel md-content md-tabs md-tabs-wrapper {
        background-color: rgba(0, 0, 0, 0.03);
        border-bottom: 1px solid rgba(0, 0, 0, 0.06); }
        #quick-panel md-content md-tabs md-tabs-wrapper .md-tab {
          max-width: none !important; }
      #quick-panel md-content md-tabs md-tabs-content-wrapper md-tab-content {
        height: 100%;
        overflow: hidden; }
        #quick-panel md-content md-tabs md-tabs-content-wrapper md-tab-content > div {
          overflow: hidden;
          height: 100%; }

@media screen and (max-width: 600px) {
  #quick-panel {
    width: 80%;
    min-width: 80%;
    max-width: 80%; } }

.tw-language-menu {
  flex: 1;
  text-align: center;
  color: currentColor;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  font-style: inherit;
  font-variant: inherit;
  font-family: inherit; }

#toolbar {
  height: 64px;
  min-height: 64px;
  max-height: 64px;
  z-index: 55 !important; }
  #toolbar #navigation-toggle {
    width: 64px;
    min-width: 64px;
    height: 64px;
    margin: 0;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 0; }
  #toolbar .isImpersonalized {
    background-color: rgba(140, 5, 5, 0.3); }
  #toolbar #user-menu {
    height: 64px;
    padding: 0; }
    #toolbar #user-menu md-menu-item .md-button {
      font-size: 15px; }
      #toolbar #user-menu md-menu-item .md-button md-icon {
        margin: auto 16px auto 0; }
    #toolbar #user-menu .user-button {
      margin: 0;
      padding: 0 16px;
      height: 64px;
      text-transform: none; }
      #toolbar #user-menu .user-button .avatar-wrapper {
        position: relative; }
        #toolbar #user-menu .user-button .avatar-wrapper .status {
          position: absolute;
          bottom: -3px;
          right: 5px; }
      #toolbar #user-menu .user-button .username {
        margin: 0 8px 0 0;
        height: 14px; }
  #toolbar #language-menu {
    height: 64px; }
    #toolbar #language-menu .language-button {
      min-width: 64px;
      height: 64px;
      margin: 0;
      padding: 0 16px;
      border-radius: 0; }
      #toolbar #language-menu .language-button .iso {
        text-transform: uppercase; }
      #toolbar #language-menu .language-button .flag {
        margin-right: 8px; }
  #toolbar #quick-panel-toggle {
    width: 64px;
    height: 64px;
    margin: 0;
    border-radius: 0; }
  #toolbar .toolbar-separator {
    height: 64px;
    width: 1px;
    background: rgba(0, 0, 0, 0.12); }

#language-menu-content md-menu-item .flag {
  margin-right: 16px; }

#language-message {
  min-height: 88px;
  max-width: 600px; }
  #language-message .md-toast-content {
    height: 90px; }

@media screen and (max-width: 600px) {
  #toolbar #user-menu .user-button {
    min-width: 64px;
    padding: 0 2px 0 10px; } }

#ressourcesHumaines md-list {
  margin: 0;
  padding: 0; }

#ressourcesHumaines md-divider {
  border-width: 1px;
  border-style: solid;
  color: gray; }

#ressourcesHumaines .small-input {
  margin: 0px;
  padding: 0px; }

#ressourcesHumaines .md-small-button {
  padding: 0px;
  margin: 0px;
  height: 35px;
  width: 35px;
  min-width: 35px !important; }

#ressourcesHumaines .md-medium-button {
  padding: 5px;
  margin: 5px;
  height: 50px;
  width: 50px;
  min-width: 50px !important;
  max-width: 50px; }

#ressourcesHumaines .search {
  position: relative;
  padding-left: 24px;
  max-width: 480px; }
  #ressourcesHumaines .search .search-icon {
    margin: 0 -24px 0 0; }
  #ressourcesHumaines .search .search-input {
    margin: 0 0 0 32px;
    background: none;
    font-size: 16px;
    line-height: 40px;
    height: 40px;
    color: #2d323e;
    border-bottom: 2px solid #2d323e;
    transition: border-color 300ms ease; }
    #ressourcesHumaines .search .search-input:focus {
      border-color: #2d323e; }
    #ressourcesHumaines .search .search-input::-webkit-input-placeholder {
      color: #2d323e; }

#ressourcesHumaines .center .header {
  min-height: 90px !important;
  max-height: 90px !important;
  padding: 24px 0; }

#ressourcesHumaines .center table#cedule {
  border-collapse: collapse;
  width: 100%; }
  #ressourcesHumaines .center table#cedule td, #ressourcesHumaines .center table#cedule th {
    border: 1px solid black;
    max-width: 30px !important;
    min-width: 30px !important; }

#ressourcesHumaines .center md-toolbar {
  min-height: 60px !important;
  max-height: 60px !important; }

#ressourcesHumaines .center .toolbar {
  min-height: 60px !important;
  max-height: 60px !important; }

#ressourcesHumaines .center .content {
  padding: 0; }

#ressourcesHumaines .strike {
  text-decoration: line-through; }

#ressourcesHumaines .action {
  margin: 0;
  padding: 6px; }

#resourcesHumaines-forms md-input-container, #resourcesHumaines-forms md-select {
  padding: 0 0 0 0;
  margin: 6px 12px 6px 0; }

#resourcesHumaines-forms div.value {
  border-bottom: 1px solid rgba(0, 0, 0, 0.117647);
  margin: 6px 0 24px 0;
  min-height: 25px; }

#ressourcesHumaines-dialog .label {
  display: inline-block;
  font-size: 11px;
  padding: 5px;
  border-radius: 5px;
  margin: 5px 5px 0 0;
  background-color: #aaaaaa;
  min-height: 25px; }

#hr-configurations md-list {
  margin: 0;
  padding: 0; }

#hr-configurations md-divider {
  border-width: 1px;
  border-style: solid;
  color: gray; }

#hr-configurations .small-input {
  margin: 0px;
  padding: 0px; }

#hr-configurations .md-small-button {
  padding: 0px;
  margin: 0px;
  height: 35px;
  width: 35px;
  min-width: 35px !important; }

#hr-configurations .md-medium-button {
  padding: 5px;
  margin: 5px;
  height: 50px;
  width: 50px;
  min-width: 50px !important;
  max-width: 50px; }

#hr-configurations .search {
  position: relative;
  padding-left: 24px;
  max-width: 480px; }
  #hr-configurations .search .search-icon {
    margin: 0 -24px 0 0; }
  #hr-configurations .search .search-input {
    margin: 0 0 0 32px;
    background: none;
    font-size: 16px;
    line-height: 40px;
    height: 40px;
    color: #2d323e;
    border-bottom: 2px solid #2d323e;
    transition: border-color 300ms ease; }
    #hr-configurations .search .search-input:focus {
      border-color: #2d323e; }
    #hr-configurations .search .search-input::-webkit-input-placeholder {
      color: #2d323e; }

#hr-configurations .center .header {
  min-height: 90px !important;
  max-height: 90px !important;
  padding: 24px 0; }

#hr-configurations .center table#cedule {
  border-collapse: collapse;
  width: 100%; }
  #hr-configurations .center table#cedule td, #hr-configurations .center table#cedule th {
    border: 1px solid black;
    max-width: 30px !important;
    min-width: 30px !important; }

#hr-configurations .center md-toolbar {
  min-height: 60px !important;
  max-height: 60px !important; }

#hr-configurations .center .toolbar {
  min-height: 60px !important;
  max-height: 60px !important; }

#hr-configurations .center .content {
  padding: 0; }

#hr-configurations .strike {
  text-decoration: line-through; }

#hr-configurations .action {
  margin: 0;
  padding: 6px; }

#resourcesHumaines-forms md-input-container, #resourcesHumaines-forms md-select {
  padding: 0 0 0 0;
  margin: 6px 12px 6px 0; }

#resourcesHumaines-forms div.value {
  border-bottom: 1px solid rgba(0, 0, 0, 0.117647);
  margin: 6px 0 24px 0;
  min-height: 25px; }

#ressourcesHumaines-dialog .label {
  display: inline-block;
  font-size: 11px;
  padding: 5px;
  border-radius: 5px;
  margin: 5px 5px 0 0;
  background-color: #aaaaaa;
  min-height: 25px; }

#ressourcesHumaines md-list {
  margin: 0;
  padding: 0; }

#ressourcesHumaines md-divider {
  border-width: 1px;
  border-style: solid;
  color: gray; }

#ressourcesHumaines .small-input {
  margin: 0px;
  padding: 0px; }

#ressourcesHumaines .md-small-button {
  padding: 0px;
  margin: 0px;
  height: 35px;
  width: 35px;
  min-width: 35px !important; }

#ressourcesHumaines .md-medium-button {
  padding: 5px;
  margin: 5px;
  height: 50px;
  width: 50px;
  min-width: 50px !important;
  max-width: 50px; }

#ressourcesHumaines .search {
  position: relative;
  padding-left: 24px;
  max-width: 480px; }
  #ressourcesHumaines .search .search-icon {
    margin: 0 -24px 0 0; }
  #ressourcesHumaines .search .search-input {
    margin: 0 0 0 32px;
    background: none;
    font-size: 16px;
    line-height: 40px;
    height: 40px;
    color: #2d323e;
    border-bottom: 2px solid #2d323e;
    transition: border-color 300ms ease; }
    #ressourcesHumaines .search .search-input:focus {
      border-color: #2d323e; }
    #ressourcesHumaines .search .search-input::-webkit-input-placeholder {
      color: #2d323e; }

#ressourcesHumaines .center .header {
  min-height: 90px !important;
  max-height: 90px !important;
  padding: 24px 0; }

#ressourcesHumaines .center table#cedule {
  border-collapse: collapse;
  width: 100%; }
  #ressourcesHumaines .center table#cedule td, #ressourcesHumaines .center table#cedule th {
    border: 1px solid black;
    max-width: 30px !important;
    min-width: 30px !important; }

#ressourcesHumaines .center md-toolbar {
  min-height: 60px !important;
  max-height: 60px !important; }

#ressourcesHumaines .center .toolbar {
  min-height: 60px !important;
  max-height: 60px !important; }

#ressourcesHumaines .center .content {
  padding: 0; }

#ressourcesHumaines .strike {
  text-decoration: line-through; }

#ressourcesHumaines .action {
  margin: 0;
  padding: 6px; }

#resourcesHumaines-forms md-input-container, #resourcesHumaines-forms md-select {
  padding: 0 0 0 0;
  margin: 6px 12px 6px 0; }

#resourcesHumaines-forms div.value {
  border-bottom: 1px solid rgba(0, 0, 0, 0.117647);
  margin: 6px 0 24px 0;
  min-height: 25px; }

#ressourcesHumaines-dialog .label {
  display: inline-block;
  font-size: 11px;
  padding: 5px;
  border-radius: 5px;
  margin: 5px 5px 0 0;
  background-color: #aaaaaa;
  min-height: 25px; }

#catalogs {
  /*.md-chips .md-chip-wraps md-chip {

    margin: 0px 8px 0px 0px;
  }*/ }
  #catalogs .md-small-button {
    padding: 0px;
    margin: 0px;
    height: 36px;
    width: 36px;
    min-width: 36px !important; }
  #catalogs .md-medium-button {
    padding: 5px;
    margin: 5px;
    height: 50px;
    width: 50px;
    min-width: 50px !important;
    max-width: 50px; }
  #catalogs md-list.make-scrollable {
    max-height: 100%;
    overflow-y: scroll; }
  #catalogs .center .header {
    height: 130px !important;
    min-height: 100px !important;
    max-height: 100px !important;
    padding: 24px 0; }
    #catalogs .center .header .h1 md-icon {
      margin-right: 12px; }
    #catalogs .center .header .product-count {
      margin: 6px 0 0 36px; }
    #catalogs .center .header .search {
      position: relative;
      padding-left: 24px;
      max-width: 480px; }
      #catalogs .center .header .search .search-icon {
        margin: 0 -24px 0 0; }
      #catalogs .center .header .search .search-input {
        margin: 0 0 0 32px;
        background: none;
        font-size: 16px;
        line-height: 40px;
        height: 40px;
        color: #2d323e;
        border-bottom: 2px solid #2d323e;
        transition: border-color 300ms ease; }
        #catalogs .center .header .search .search-input:focus {
          border-color: #2d323e; }
        #catalogs .center .header .search .search-input::-webkit-input-placeholder {
          color: #2d323e; }
  #catalogs .center .content-card .toolbar {
    padding: 8px; }
  #catalogs .center .content-card .content {
    padding: 0; }
    #catalogs .center .content-card .content .dataTables_wrapper .dataTables_scrollHead {
      min-height: 50px; }
      #catalogs .center .content-card .content .dataTables_wrapper .dataTables_scrollHead table th {
        padding-top: 15px;
        padding-bottom: 16px; }

#article-details .filesTitle {
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: #2d323e;
  padding: 8px;
  font-weight: bold;
  color: #FFFFFF; }

#article-details .thumbImg {
  margin-right: 10px;
  max-height: 50px;
  height: 50px;
  max-width: 50px;
  width: 50px; }

#article-details .articlePictures {
  width: 250px; }

#article-details .tab-body-large {
  height: 400px;
  padding: 10px 10px 10px 10px; }

#order .md-medium-button {
  padding: 5px;
  margin: 5px;
  height: 50px;
  width: 50px;
  min-width: 50px !important;
  max-width: 50px; }

#order md-list {
  margin: 0;
  padding: 0; }
  #order md-list .list-header {
    font-weight: bold;
    max-height: 35px;
    background-color: #808080; }
  #order md-list .cancelled {
    text-decoration: line-through;
    color: lightgray; }

#order .search {
  position: relative;
  padding-left: 24px;
  max-width: 480px; }
  #order .search .search-icon {
    margin: 0 -24px 0 0; }
  #order .search .search-input {
    margin: 0 0 0 32px;
    background: none;
    font-size: 16px;
    line-height: 40px;
    height: 40px;
    color: #2d323e;
    border-bottom: 2px solid #2d323e;
    transition: border-color 300ms ease; }
    #order .search .search-input:focus {
      border-color: #2d323e; }
    #order .search .search-input::-webkit-input-placeholder {
      color: #2d323e; }

#order .center .header {
  min-height: 90px !important;
  max-height: 90px !important;
  padding: 24px 0; }

#order .center md-toolbar {
  min-height: 60px !important;
  max-height: 60px !important; }

#order .center .toolbar {
  min-height: 60px !important;
  max-height: 60px !important; }

#order .center .content {
  padding: 0; }

#order .strike {
  text-decoration: line-through; }

#order .action {
  margin: 0;
  padding: 6px; }

#inventory-order-forms md-input-container, #inventory-order-forms md-select {
  padding: 0 0 0 0;
  margin: 6px 12px 6px 0; }

#inventory-order-forms div.value {
  border-bottom: 1px solid rgba(0, 0, 0, 0.117647);
  margin: 6px 0 24px 0;
  min-height: 25px; }

#pickinglists .center .header {
  height: 136px !important;
  min-height: 100px !important;
  max-height: 100px !important;
  padding: 24px 0; }
  #pickinglists .center .header .h1 md-icon {
    margin-right: 12px; }
  #pickinglists .center .header .search {
    position: relative;
    padding-left: 24px;
    max-width: 480px; }
    #pickinglists .center .header .search .search-icon {
      margin: 0 -24px 0 0; }
    #pickinglists .center .header .search .search-input {
      margin: 0 0 0 32px;
      background: none;
      font-size: 16px;
      line-height: 40px;
      height: 40px;
      color: #2d323e;
      border-bottom: 2px solid #2d323e;
      transition: border-color 300ms ease; }
      #pickinglists .center .header .search .search-input:focus {
        border-color: #2d323e; }
      #pickinglists .center .header .search .search-input::-webkit-input-placeholder {
        color: #2d323e; }

#pickinglists .center .content-card {
  padding: 0; }
  #pickinglists .center .content-card .tabs-button {
    max-height: 80px; }
  #pickinglists .center .content-card md-tabs .md-tab-button {
    padding: 12px 6px 12px 12px; }
  #pickinglists .center .content-card .md-small {
    margin: 0 6px;
    padding: 6px;
    line-height: 22px;
    min-height: 30px;
    height: 30px;
    min-width: 50px;
    font-size: 12px; }
  #pickinglists .center .content-card .tabs-icon-button {
    padding: 0;
    margin: 0;
    height: 24px;
    min-width: 40px; }
  #pickinglists .center .content-card .dataTables_wrapper .dataTables_scrollHead {
    min-height: 50px; }
    #pickinglists .center .content-card .dataTables_wrapper .dataTables_scrollHead table th {
      padding-top: 15px;
      padding-bottom: 16px; }
  #pickinglists .center .content-card .dataTables_wrapper .dataTables_scrollBody td {
    padding-top: 5px;
    padding-bottom: 5px;
    min-height: 40px;
    height: 40px; }
    #pickinglists .center .content-card .dataTables_wrapper .dataTables_scrollBody td .md-button {
      margin: 0 6px;
      padding: 6px;
      line-height: 22px;
      min-height: 30px;
      height: 30px;
      min-width: 50px;
      font-size: 12px; }

#pickinglists-dialog md-list md-list-item.selected {
  background-color: #ffab00; }

#pickinglists-dialog .md-small-button {
  padding: 0px !important;
  margin: 0px !important;
  height: 35px !important;
  width: 35px !important;
  min-width: 35px !important;
  margin-top: 20px !important; }

#pickinglists-readytopick {
  /*ADD CUSTOM CSS HERE*/ }
  #pickinglists-readytopick .center .header {
    height: 136px !important;
    min-height: 100px !important;
    max-height: 100px !important;
    padding: 24px 0; }
    #pickinglists-readytopick .center .header .h1 md-icon {
      margin-right: 12px; }
    #pickinglists-readytopick .center .header .search {
      position: relative;
      padding-left: 24px;
      max-width: 480px; }
      #pickinglists-readytopick .center .header .search .search-icon {
        margin: 0 -24px 0 0; }
      #pickinglists-readytopick .center .header .search .search-input {
        margin: 0 0 0 32px;
        background: none;
        font-size: 16px;
        line-height: 40px;
        height: 40px;
        color: #FFFFFF;
        border-bottom: 2px solid #2d323e;
        transition: border-color 300ms ease; }
        #pickinglists-readytopick .center .header .search .search-input:focus {
          border-color: #2d323e; }
        #pickinglists-readytopick .center .header .search .search-input::-webkit-input-placeholder {
          color: #2d323e; }
  #pickinglists-readytopick .center .toolbar {
    min-height: 60px !important;
    max-height: 60px !important; }
  #pickinglists-readytopick .center .content-card {
    padding: 0; }
    #pickinglists-readytopick .center .content-card .tabs-button {
      max-height: 80px; }
    #pickinglists-readytopick .center .content-card md-tabs .md-tab-button {
      padding: 12px 6px 12px 12px; }
    #pickinglists-readytopick .center .content-card .md-small {
      margin: 0 6px;
      padding: 6px;
      line-height: 22px;
      min-height: 30px;
      height: 30px;
      min-width: 50px;
      font-size: 12px; }
    #pickinglists-readytopick .center .content-card .tabs-icon-button {
      padding: 0;
      margin: 0;
      height: 24px;
      min-width: 40px; }
    #pickinglists-readytopick .center .content-card .dataTables_wrapper .dataTables_scrollHead {
      min-height: 50px; }
      #pickinglists-readytopick .center .content-card .dataTables_wrapper .dataTables_scrollHead table th {
        padding-top: 15px;
        padding-bottom: 16px; }
    #pickinglists-readytopick .center .content-card .dataTables_wrapper .dataTables_scrollBody td {
      padding-top: 5px;
      padding-bottom: 5px;
      min-height: 40px;
      height: 40px; }
      #pickinglists-readytopick .center .content-card .dataTables_wrapper .dataTables_scrollBody td .md-button {
        margin: 0 6px;
        padding: 6px;
        line-height: 22px;
        min-height: 30px;
        height: 30px;
        min-width: 50px;
        font-size: 12px; }

#releases .center .header {
  height: 100px !important;
  min-height: 100px !important;
  max-height: 100px !important;
  padding: 24px 0; }
  #releases .center .header .h1 md-icon {
    margin-right: 12px; }

#releases .center .content-card {
  padding: 0; }
  #releases .center .content-card md-toolbar {
    min-height: 60px !important;
    max-height: 60px !important; }
  #releases .center .content-card .toolbar {
    min-height: 60px !important;
    max-height: 60px !important; }

#releases .center .content {
  padding: 0; }

/**
 *  Do not remove this comments bellow. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
/**
 *  Do not remove this comments bellow. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */
.badge-grey {
  min-width: 20px;
  height: 20px;
  line-height: 20px;
  padding: 0 7px;
  font-size: 11px;
  font-weight: bold;
  border-radius: 15px;
  background-color: grey;
  color: white;
  margin: 1px; }

.badge-green {
  min-width: 20px;
  height: 20px;
  line-height: 20px;
  padding: 0 7px;
  font-size: 11px;
  font-weight: bold;
  border-radius: 15px;
  background-color: grey;
  background-color: darkgreen;
  color: white; }

.badge-red {
  min-width: 20px;
  height: 20px;
  line-height: 20px;
  padding: 0 7px;
  font-size: 11px;
  font-weight: bold;
  border-radius: 15px;
  background-color: grey;
  color: white; }

.highlight {
  color: #ffb300 !important;
  font-weight: bold !important; }

.fiedValue {
  min-height: 32px;
  height: 32px;
  padding: 8px 2px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }

.green {
  color: #1a7e00; }

.red {
  color: #ab0000; }

.FOGyellow_fg {
  color: #ffb300 !important; }

.FOGyellow_bg {
  background-color: #ffb300; }

.FOGgrey_fg {
  color: #2d323e; }

.FOGgrey_fg-title {
  color: #2d323e;
  font-size: 28px;
  font-weight: bold; }

.FOGgrey_fg-subtitle {
  color: #2d323e;
  font-size: 18px; }

.FOGgrey_bg {
  background-color: #2d323e; }

@media only screen and (max-width: 600px) {
  .FOGgrey_fg-title {
    color: #2d323e;
    font-size: 14px;
    font-weight: bold; }
  .FOGgrey_fg-subtitle {
    color: #2d323e;
    font-size: 12px; } }

table thead {
  background-color: #2d323e !important; }
  table thead tr {
    background-color: #2d323e !important; }

table thead tr th {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #2d323e !important;
  font-weight: normal !important; }
  table thead tr th div span {
    font-weight: normal !important; }
  table thead tr th div md-input-container md-select md-select-value {
    color: rgba(255, 255, 255, 0.87) !important;
    background-color: #2d323e !important;
    font-weight: normal !important;
    border-bottom-color: rgba(255, 255, 255, 0.87) !important; }

.row-success td {
  background-color: #d9e4be; }

.row-warning td {
  background-color: #f8ea9c; }

.row-danger td {
  background-color: #e49a9a; }

.dataTables_scrollHead {
  background-color: #2d323e !important; }

.sulTop {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #2d323e !important; }

.inList {
  color: #2d323e !important;
  background-color: white; }

.md-select-icon {
  color: rgba(255, 255, 255, 0.87) !important; }

form .tw-filedset {
  border-style: solid;
  border-color: gainsboro;
  border-width: 1px;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  margin: 10px;
  padding: 5px; }
  form .tw-filedset textarea {
    width: 100%;
    height: 100%;
    border-width: 2px;
    border-color: black;
    margin: 0px;
    flex: 1; }
  form .tw-filedset md-input-container {
    margin: 5px;
    margin-top: 15px;
    flex: 1; }
    form .tw-filedset md-input-container .md-errors-spacer {
      display: none; }

form .md-input[disabled] {
  color: rgba(0, 0, 0, 0.8) !important; }

form md-select[disabled] md-select-value {
  color: rgba(0, 0, 0, 0.8) !important; }

form .signature-container {
  margin: 30px 0 8px 0; }
  form .signature-container .signature-div {
    font-size: 11px;
    border-top: 1px solid #ccc !important;
    margin: 8px 8px 8px 0; }

form .formly-autocomplete md-input-container {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: -8px;
  margin: 8px 16px 8px 0; }

form .formly-autocomplete md-progress-linear {
  padding-right: 12px !important;
  top: 35px !important; }

.compact-form > md-input-container,
.compact-form > md-select {
  padding: 24px 0px;
  margin-bottom: 0 !important;
  margin-left: 0 !important;
  margin-top: 10px !important;
  max-height: 30px; }
  .compact-form > md-input-container label,
  .compact-form > md-select label {
    position: absolute;
    bottom: 45%;
    left: 0px;
    right: auto; }

.compact-form > md-checkbox {
  padding: 24px 0px;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  max-height: 24px; }
  .compact-form > md-checkbox .md-container {
    margin: 0px 5px !important; }
  .compact-form > md-checkbox label {
    position: absolute;
    bottom: 45%;
    left: 0;
    right: auto; }

.compact-form md-autocomplete > md-autocomplete-wrap > md-input-container {
  padding: 24px 5px;
  margin-bottom: 0 !important;
  margin-top: 10px !important;
  max-height: 30px; }
  .compact-form md-autocomplete > md-autocomplete-wrap > md-input-container label {
    position: absolute;
    bottom: 45%;
    left: 5px;
    right: auto; }

.compact-form md-list {
  padding: 5px 0px;
  margin-bottom: 0 !important;
  margin-top: 10px !important; }
  .compact-form md-list .md-subheader-inner {
    font-weight: normal;
    padding: 0px 3px;
    margin: 0px;
    font-size: 1.2rem; }
  .compact-form md-list md-list-item {
    padding: 0px 3px;
    margin: 0px;
    min-height: 38px;
    max-height: 38px; }

.autocompleteTmpl li {
  border-bottom: 1px solid #ccc;
  height: auto;
  padding-left: 2px;
  padding-top: 2px;
  padding-bottom: 2px;
  white-space: normal; }

.autocompleteTmpl li:last-child {
  border-bottom-width: 0; }

.autocompleteTmpl .item-title,
.autocompleteTmpl .item-metadata {
  display: inline;
  line-height: 1;
  padding: 2px; }

.autocompleteTmpl .item-title img {
  height: 32px;
  width: 32px;
  padding: 0; }

.highlight {
  color: #ffb300 !important;
  font-weight: bold !important; }

.fiedValue {
  min-height: 32px;
  height: 32px;
  padding: 8px 2px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }

.compact-list md-input-container,
.compact-list md-select,
.compact-list md-checkbox {
  padding: 2px 5px;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  max-height: 30px; }

.compact-list .md-button {
  margin: 0 !important;
  padding: 3px !important; }

.compact-list .listHeader {
  margin-top: 15px;
  border-bottom: 1px solid #ccc; }

.compact-list md-list-item {
  max-height: 35px !important;
  padding: 0; }

.compact-list form {
  max-height: 35px !important;
  padding: 0; }

.compact-list p {
  max-height: 35px !important;
  font-size: 11px;
  padding: 0;
  margin: 0; }

.compact-list md-list-item .header {
  padding: 0; }

.twHeader {
  padding: 8px 0;
  height: 100px;
  min-height: 100px; }
  .twHeader .twTitle {
    color: #2d323e;
    font-size: 24px;
    font-weight: bold; }
  .twHeader .twSubTitle {
    color: #2d323e;
    font-size: 18px; }

.twToolbar .md-button {
  padding: 8px;
  margin: 0;
  height: 70px;
  width: 70px; }
  .twToolbar .md-button md-icon {
    border-color: black;
    border-width: 1px;
    border-radius: 50%;
    height: 35px; }

md-list-item.list-header {
  font-weight: normal !important;
  font-size: 1.3rem;
  height: 50px !important;
  max-height: 50px;
  min-height: 50px;
  background-color: #2d323e !important;
  color: rgba(255, 255, 255, 0.87) !important; }

@media print {
  .header {
    display: none; }
  .white-bg {
    border: 1px solid #ccc; }
  md-tab-item {
    display: none; }
  md-tab-item.md-active {
    display: block; }
  .tw-widget {
    margin: 0px;
    padding: 0px; }
  .layout-row > .flex-gt-md-25, .layout-gt-md-row > .flex-gt-md-25 {
    max-width: 25%; }
  .layout-row > .flex-gt-md-50, .layout-gt-md-row > .flex-gt-md-50 {
    max-width: 50%; }
  .layout-row > .flex-gt-md-75, .layout-gt-md-row > .flex-gt-md-75 {
    max-width: 75%; }
  .layout-row > .flex-gt-md-100, .layout-gt-md-row > .flex-gt-md-100 {
    max-width: 100%; }
  .page-layout {
    overflow: visible !important; }
  md-content {
    overflow: visible !important; }
  .content {
    overflow: visible !important; }
  .ms-scroll {
    overflow: visible !important; }
  .print-full {
    page-break-inside: avoid; } }

treecontrol {
  /* prevent user selection */
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* default */
  font-family: Verdana, Helvetica, Arial, sans-serif;
  font-size: 12px;
  color: #555;
  text-decoration: none;
  width: 100%; }

treecontrol ul {
  margin: 0;
  padding: 0;
  list-style: none;
  border: none;
  overflow: hidden;
  width: 100%; }

treecontrol li {
  position: relative;
  padding: 0 0 0 15px;
  line-height: 20px;
  width: 100%; }

treecontrol li.tree-expanded i.tree-leaf-head, treecontrol li.tree-collapsed i.tree-leaf-head {
  display: none; }

treecontrol li.tree-expanded i.tree-branch-head, treecontrol li.tree-collapsed i.tree-branch-head {
  display: inline; }

treecontrol li.tree-leaf i.tree-branch-head {
  display: none; }

treecontrol li.tree-leaf i.tree-leaf-head {
  display: inline; }

treecontrol li i.tree-branch-head {
  cursor: pointer; }

treecontrol li .tree-label {
  cursor: pointer;
  padding: 5px 5px 5px 10px;
  display: inline; }

treecontrol li .tree-unselectable {
  cursor: not-allowed; }

treecontrol.tree-classic li.tree-expanded i.tree-branch-head {
  background: url("../assets/images/treeControl/node-opened-light.png") no-repeat;
  background-position-y: 10px; }

treecontrol.tree-classic li.tree-collapsed i.tree-branch-head {
  background: url("../assets/images/treeControl/node-closed-light.png") no-repeat;
  background-position-y: 10px; }

treecontrol.tree-classic li.tree-leaf i.tree-leaf-head {
  background: none;
  background-position-y: 10px; }

treecontrol.tree-classic li .tree-selected {
  background-color: #ffd800;
  font-weight: bold; }

treecontrol.tree-classic li .tree-unselectable {
  color: #ddd; }

.tw-page-content-form {
  font-size: 12px; }

.tw-flayinglayout-title {
  display: flex; }
  .tw-flayinglayout-title span {
    color: white;
    font-size: 18px; }

.tw-Sidenav-title {
  height: 30px;
  background-color: #E4E4E4;
  height: 100%;
  width: 50px;
  display: flex;
  position: relative; }

.tw-page-flyout-title-title {
  transform: rotate(-90deg) translateX(-100%);
  transform-origin: 0 0;
  margin: 22px 0 0 0;
  height: 100%;
  text-transform: uppercase;
  font-size: 32px;
  color: #7f7f7f;
  white-space: nowrap;
  flex: 1; }

.tw-page-shadow {
  height: 100%;
  width: 5px;
  background: linear-gradient(to right, #2d323e, rgba(245, 245, 245, 0)); }

.tw-boxshadow {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12); }

.tw-full-toolbar {
  background-color: white;
  height: 100%;
  width: 80px;
  padding-top: 5px;
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12); }

.tw-tooltip {
  background-color: #555;
  color: #fff;
  max-width: 200px;
  background-color: #555;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 2px;
  transition: opacity 0.3s;
  white-space: pre-wrap;
  font-size: 10px; }

.tw-mouse-selection {
  height: auto;
  padding: 5px;
  background-color: gainsboro;
  display: flex;
  flex-direction: column; }

.tw-inputcontainer {
  border-bottom-color: rgba(0, 0, 0, 0.12);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding: 2px 2px 1px;
  background-color: transparent; }
  .tw-inputcontainer input {
    border: none; }

.tw-label {
  color: rgba(0, 0, 0, 0.54);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  -webkit-box-ordinal-group: 2;
  order: 1;
  pointer-events: none;
  -webkit-font-smoothing: antialiased;
  padding-left: 3px;
  padding-right: 0;
  z-index: 1;
  max-width: 100%; }

#quick-panel .presences-tab #filterUser {
  padding: 8px;
  width: 100%;
  height: 35px;
  border-bottom: 1px solid #a7a7a7; }

#quick-panel .presences-tab .users .user {
  position: relative; }
  #quick-panel .presences-tab .users .user .avatar-wrapper {
    position: relative; }
    #quick-panel .presences-tab .users .user .avatar-wrapper .status {
      position: absolute;
      bottom: -3px;
      right: 5px; }

#tw-widget-activities table {
  width: 100%;
  border-spacing: 0;
  margin: 0; }
  #tw-widget-activities table th {
    margin: 0;
    padding: 5px 15px; }
  #tw-widget-activities table td {
    max-height: 25px;
    height: 25px;
    border-bottom: solid;
    border-bottom-width: 1px;
    border-bottom-color: lightgray;
    padding: 5px 15px; }
  #tw-widget-activities table tbody > tr {
    cursor: pointer; }
  #tw-widget-activities table tbody > tr:hover {
    background-color: #ffb300; }

/*----------------------------------------------------------------*/
/*  tw-widget
/*----------------------------------------------------------------*/
.tw-widget {
  height: 100%;
  padding: 0px; }
  .tw-widget .widgetDragHandle {
    cursor: pointer; }
  .tw-widget .tw-widget-front {
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }
  .tw-widget .tw-widget-back {
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }
  .tw-widget.sidenav-widget .tw-widget-front,
  .tw-widget.sidenav-widget .tw-widget-back {
    box-shadow: none; }
  .tw-widget .flip-to-front {
    position: absolute;
    top: 0;
    right: 0; }
  .tw-widget .c3 {
    height: 100%; }
  .tw-widget .chart-fill-8 {
    margin: 0 -8px -8px -8px; }
  .tw-widget .chart-fill-16 {
    margin: 0 -16px -16px -16px; }
  .tw-widget .chart-fill-24 {
    margin: 0 -24px -24px -24px; }
  .tw-widget .chart-fill-32 {
    margin: 0 -32px -32px -32px; }
  .tw-widget .chart-fill-40 {
    margin: 0 -40px -40px -40px; }
  .tw-widget .chart-fill-48 {
    margin: 0 -48px -48px -48px; }
  .tw-widget .chart-fill-56 {
    margin: 0 -56px -56px -56px; }
  .tw-widget .chart-fill-64 {
    margin: 0 -64px -64px -64px; }
  .tw-widget .chart-fill-72 {
    margin: 0 -72px -72px -72px; }
  .tw-widget .chart-fill-80 {
    margin: 0 -80px -80px -80px; }

.tw-widget {
  position: relative;
  font-size: 1.3rem;
  perspective: 3000px;
  padding: 12px; }
  .tw-widget .tw-widget-front {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    position: relative;
    overflow: hidden;
    visibility: visible;
    width: 100%;
    opacity: 1;
    z-index: 10;
    border-radius: 2px;
    transition: transform 0.5s ease-out 0s, visibility 0s ease-in 0.2s, opacity 0s ease-in 0.2s;
    transform: rotateY(0deg);
    backface-visibility: hidden; }
    .tw-widget .tw-widget-front .widget-title {
      height: 41px;
      max-height: 41px;
      min-height: 41px;
      background-color: #474747;
      color: #fff; }
      .tw-widget .tw-widget-front .widget-title md-icon {
        color: #fff; }
  .tw-widget .tw-widget-back {
    display: block;
    position: absolute;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    z-index: 10;
    top: 12px;
    right: 12px;
    bottom: 12px;
    left: 12px;
    border-radius: 2px;
    transition: transform 0.5s ease-out 0s, visibility 0s ease-in 0.2s, opacity 0s ease-in 0.2s;
    transform: rotateY(180deg);
    backface-visibility: hidden; }
  .tw-widget .md-button {
    margin: 0; }
    .tw-widget .md-button.md-icon-button {
      margin: 0; }
  .tw-widget.flipped .tw-widget-front {
    visibility: hidden;
    opacity: 0;
    transform: rotateY(180deg); }
  .tw-widget.flipped .tw-widget-back {
    display: block;
    visibility: visible;
    opacity: 1;
    transform: rotateY(360deg); }
  .tw-widget.sidenav-widget {
    padding: 0; }
  .tw-widget.ar-2-1 .widget {
    padding: 0;
    position: relative; }
    .tw-widget.ar-2-1 .widget:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: 50%; }
    .tw-widget.ar-2-1 .widget > .widget {
      position: absolute;
      top: 8px;
      left: 8px;
      right: 8px;
      bottom: 8px; }
  .tw-widget.ar-1-1 .widget {
    padding: 0;
    position: relative; }
    .tw-widget.ar-1-1 .widget:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: 100%; }
    .tw-widget.ar-1-1 .widget > .widget {
      position: absolute;
      top: 8px;
      left: 8px;
      right: 8px;
      bottom: 8px; }

#tw-widget-budget-orders .over {
  background-color: rgba(255, 0, 0, 0.57); }

#tw-widget-meters-total {
  background-color: #F4B700; }
  #tw-widget-meters-total .meters {
    font-size: 50px;
    font-stretch: condensed;
    line-height: 50px;
    font-weight: bold; }
  #tw-widget-meters-total .target {
    margin-top: 10px;
    font-weight: bold;
    font-size: 20px;
    font-stretch: condensed;
    line-height: 20px;
    color: #808080; }

#tw-widget-meters md-select {
  margin: 5px; }

#tw-widget-meters table {
  width: 100%;
  border-spacing: 0;
  margin: 0; }
  #tw-widget-meters table th {
    margin: 0;
    padding: 5px 15px; }
  #tw-widget-meters table td {
    max-height: 25px;
    height: 25px;
    border-bottom: solid;
    border-bottom-width: 1px;
    border-bottom-color: lightgray;
    padding: 5px 15px; }
  #tw-widget-meters table tbody > tr {
    cursor: pointer; }
  #tw-widget-meters table tbody > tr:hover {
    background-color: #ffb300; }

.tw-widget-model .content {
  align-items: center; }

#tw-widget-orders-distribution .over {
  background-color: rgba(255, 0, 0, 0.57); }

#tw-widget-orders-distribution #tw-widget-orders-distribution-list table.projects {
  width: 100%;
  border-spacing: 0;
  margin: 0; }
  #tw-widget-orders-distribution #tw-widget-orders-distribution-list table.projects th {
    margin: 0;
    padding: 5px 15px; }
  #tw-widget-orders-distribution #tw-widget-orders-distribution-list table.projects td {
    max-height: 25px;
    height: 25px;
    border-bottom: solid;
    border-bottom-width: 1px;
    border-bottom-color: lightgray;
    padding: 5px 15px; }
  #tw-widget-orders-distribution #tw-widget-orders-distribution-list table.projects tbody > tr {
    cursor: pointer; }
  #tw-widget-orders-distribution #tw-widget-orders-distribution-list table.projects tbody > tr:hover {
    background-color: #ffb300; }

#tw-widget-orders-distribution #tw-widget-orders-distribution-details .details-header {
  max-height: 45px;
  min-height: 45px;
  height: 45px; }

#tw-widget-orders-distribution #tw-widget-orders-distribution-details .details-content .details-content-body {
  overflow: scroll;
  overflow-x: hidden;
  display: block; }

#tw-widget-orders-distribution #tw-widget-orders-distribution-details table.categories {
  width: 100%;
  border-spacing: 0;
  margin: 0; }
  #tw-widget-orders-distribution #tw-widget-orders-distribution-details table.categories th {
    margin: 0;
    padding: 5px 15px; }
  #tw-widget-orders-distribution #tw-widget-orders-distribution-details table.categories td {
    max-height: 15px;
    height: 15px; }
  #tw-widget-orders-distribution #tw-widget-orders-distribution-details table.categories table.category {
    width: 100%;
    border-spacing: 0;
    margin: 0; }
    #tw-widget-orders-distribution #tw-widget-orders-distribution-details table.categories table.category tr.categoryHeader td {
      max-height: 15px;
      height: 15px;
      font-weight: bold;
      border-bottom: solid;
      border-bottom-width: 1px;
      border-bottom-color: lightgray;
      padding: 5px 15px;
      background-color: #ededed; }
    #tw-widget-orders-distribution #tw-widget-orders-distribution-details table.categories table.category tr.categoryBody td {
      font-size: 10px;
      max-height: 15px;
      height: 15px;
      border-bottom: solid;
      border-bottom-width: 1px;
      border-bottom-color: lightgray;
      padding: 5px 15px 5px 35px; }

#tw-widget-orders-sliced md-select {
  margin: 0;
  padding: 0; }

#tw-widget-orders-sliced table {
  width: 100%;
  border-spacing: 0;
  margin: 0; }
  #tw-widget-orders-sliced table th {
    margin: 0;
    padding: 5px 15px; }
  #tw-widget-orders-sliced table td {
    max-height: 25px;
    height: 25px;
    border-bottom: solid;
    border-bottom-width: 1px;
    border-bottom-color: lightgray;
    padding: 5px 15px; }
  #tw-widget-orders-sliced table tbody > tr {
    cursor: pointer; }
  #tw-widget-orders-sliced table tbody > tr.danger {
    background-color: #ffb300; }

#tw-widget-resume .segment .data {
  font-size: 25px;
  font-weight: bold;
  color: #F4B700; }

#tw-widget-resume .segment .danger {
  color: #af0000; }

#tw-widget-resume .segment .label {
  font-size: 12px;
  font-weight: bold;
  color: #808080; }

#tw-widget-today .day {
  font-size: 65px;
  font-stretch: condensed;
  line-height: 68px;
  font-weight: bold; }

#tw-widget-today .month {
  font-weight: bold; }

#folder-files .list-view > thead tr th:first-child {
  border-bottom: none;
  width: 106px; }

#folder-files .list-view > thead tr th.size {
  padding-right: 20px;
  text-align: right; }

#folder-files .list-view > tbody tr {
  cursor: pointer;
  outline: none;
  max-height: 20px; }
  #folder-files .list-view > tbody tr.selected {
    background: #ffd165; }
  #folder-files .list-view > tbody tr td {
    margin: 0;
    padding: 5px 8px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    min-height: 65px; }
    #folder-files .list-view > tbody tr td.file-icon {
      padding: 0;
      text-align: center; }
    #folder-files .list-view > tbody tr td.name {
      font-weight: 500; }
    #folder-files .list-view > tbody tr td.owner {
      text-transform: capitalize; }
    #folder-files .list-view > tbody tr td.size {
      width: 75px;
      padding-right: 20px;
      text-align: right; }
    #folder-files .list-view > tbody tr td.last-modified {
      width: 175px; }
    #folder-files .list-view > tbody tr td.show-details {
      text-align: center; }
    #folder-files .list-view > tbody tr td.more {
      text-align: center; }

.tw-bubble {
  position: relative;
  overflow: hidden;
  margin-left: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  background: #97C02F;
  display: flex;
  padding: 5px; }

.tw-bubble::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-width: 0 15px 15px 0;
  border-style: solid;
  border-color: #fff #fff #658E15 #658E15;
  background: #658E15;
  display: block;
  width: 0;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3), -1px 1px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3), -1px 1px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3), -1px 1px 1px rgba(0, 0, 0, 0.2);
  transition: all ease 0.5s; }

.tw-bubble:hover::before {
  border-width: 0 25px 25px 0;
  border-color: #fff #fff #658E15 #658E15; }

.tw-bubble-new {
  position: relative;
  overflow: hidden;
  margin-left: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: lightgray;
  display: flex;
  padding: 5px; }

.tw-bubble-new::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-width: 0 15px 15px 0;
  border-style: solid;
  border-color: #fff #fff lightgray lightgray;
  background: lightgray;
  display: block;
  width: 0;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3), -1px 1px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3), -1px 1px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3), -1px 1px 1px rgba(0, 0, 0, 0.2);
  transition: all ease 0.5s; }

.tw-bubble-new:hover::before {
  border-width: 0 25px 25px 0;
  border-color: #fff #fff lightgray lightgray; }

.tw-list-notes {
  padding: 10px; }

.tw-note-content {
  display: flex;
  margin-bottom: 10px; }

.tw-note-delete {
  width: 26px;
  background-color: transparent;
  position: relative;
  z-index: 3; }

.tw-note-delete:hover .tw-icon-note-clip {
  color: red;
  transform: rotate(0); }

.tw-icon-note-clip {
  position: absolute;
  transform: rotate(-90deg); }

.tw-note-new {
  width: 26px;
  background-color: transparent;
  position: relative;
  z-index: 3; }

.tw-autosize-textarea {
  position: absolute;
  height: 100%;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  min-height: 100px;
  overflow: hidden;
  /*overflow is set to auto at max height using JS */
  background-color: transparent;
  outline: none;
  font-family: 'Century Gothic';
  line-height: 15px;
  white-space: pre;
  font-size: 10.6px;
  min-height: 18px;
  padding: 5px;
  resize: none;
  white-space: pre-wrap; }

.tw-autosize-divtextarea {
  width: 100%;
  height: 100%;
  max-width: 100%;
  min-width: 100%;
  min-height: 100px;
  font-family: 'Century Gothic';
  line-height: 15px;
  white-space: pre;
  font-size: 10.6px;
  min-height: 18px;
  background: red;
  padding: 5px;
  visibility: hidden;
  white-space: pre-wrap; }

.tw-file {
  height: 150px;
  width: 150px;
  display: flex;
  margin-bottom: 10px;
  margin-right: 10px; }

.tw-file-large {
  height: 250px;
  width: 250px;
  display: flex;
  margin-bottom: 10px;
  margin-right: 10px; }

.tw-file-linkcontent-large {
  display: flex;
  width: 250px;
  height: 250px;
  background-color: gainsboro;
  position: relative; }

.tw-file-tumbnail-large {
  margin: auto;
  width: 230px;
  height: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  padding: 10px 0; }

.tw-filecontent {
  margin: 5px;
  overflow-y: auto;
  width: 100%;
  max-height: 100%;
  display: inline-flex;
  flex-wrap: wrap; }

.tw-file-linkcontent {
  display: flex;
  width: 150px;
  height: 150px;
  background-color: gainsboro;
  position: relative; }

.tw-file-delete {
  position: absolute;
  width: 25px;
  height: 25px;
  right: 0;
  top: 0;
  margin-right: 5px;
  margin-top: 5px; }
  .tw-file-delete md-icon {
    color: #404040; }
  .tw-file-delete md-icon:hover {
    color: red; }

.tw-file-download {
  position: absolute;
  width: 25px;
  height: 25px;
  left: 0;
  top: 0;
  margin-right: 5px;
  margin-top: 5px; }
  .tw-file-download md-icon {
    color: #404040; }
  .tw-file-download md-icon:hover {
    color: red; }

.tw-file-discription {
  padding: 0 1px;
  font-size: 12px;
  height: 35px;
  color: white;
  -webkit-text-fill-color: white;
  opacity: 0.7;
  background: #404040;
  overflow: hidden;
  width: 150px;
  text-overflow: ellipsis;
  line-height: 17px;
  word-wrap: break-word;
  position: absolute;
  bottom: 0;
  text-align: -webkit-center;
  text-align: center; }
  .tw-file-discription textarea {
    background: #404040;
    width: 100%;
    height: 100%;
    resize: none;
    margin: 0;
    padding: 0;
    text-align: center;
    border: none;
    overflow: hidden;
    overflow-y: auto; }
  .tw-file-discription textarea::-webkit-scrollbar {
    width: 5px;
    background-color: #404040; }
  .tw-file-discription textarea::-webkit-scrollbar-thumb {
    background-color: #DCDCDC; }
  .tw-file-discription input {
    background: #404040;
    width: 100%;
    height: 100%;
    resize: none;
    margin: 0;
    padding: 0;
    text-align: center;
    border: none; }

.tw-file-tumbnail {
  margin: auto;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  padding: 10px 0; }

.tw-contact-address-detail {
  display: flex;
  flex-direction: row; }

.tw-ligne-detail {
  display: flex;
  align-items: center;
  line-height: 20px; }

.tw-contact-address-line {
  padding: 2px 0; }

.tw-filters {
  max-width: 600px; }
  .tw-filters .date-button {
    min-height: 40px !important;
    max-height: 40px !important;
    min-width: 40px !important;
    max-width: 40px !important;
    margin: 0;
    padding: 8px;
    cursor: pointer; }
  .tw-filters .middle md-input-container {
    margin: 0;
    padding: 0; }
    .tw-filters .middle md-input-container .moment-picker {
      max-height: 25px; }

.tw-function-select {
  margin: 5px; }
  .tw-function-select md-autocomplete {
    background-color: transparent; }
  .tw-function-select md-autocomplete-wrap {
    background-color: transparent; }
  .tw-function-select input[type="search"] {
    background-color: transparent; }

.tw-group-info {
  position: absolute;
  z-index: 100;
  background-color: white;
  width: auto;
  border: solid;
  border-color: red;
  border-width: 2px;
  height: auto; }

.article-custom-template.md-autocomplete-suggestions {
  height: auto;
  max-height: 10px; }

.article-custom-template li {
  border-bottom: 1px solid #ccc;
  height: auto;
  padding-top: 8px;
  padding-bottom: 8px;
  white-space: normal; }

.article-custom-template li:last-child {
  border-bottom-width: 0; }

.article-custom-template .item-title,
.article-custom-template .item-metadata {
  display: block;
  line-height: 2; }

.article-custom-template .item-title md-icon {
  height: 18px;
  width: 18px; }

.tw-editable-control-list {
  width: 100%;
  height: 100%;
  padding-left: 3px;
  padding-right: 3px; }
  .tw-editable-control-list input {
    background-color: #E8E8E8;
    width: 100%;
    border: none;
    padding: 4px;
    height: 30px; }
  .tw-editable-control-list input[type="checkbox"] {
    background-color: red;
    margin-right: 4px;
    height: 20px; }
  .tw-editable-control-list select {
    background-color: #E8E8E8;
    border: none;
    padding: 4px;
    margin-right: 4px;
    height: 30px;
    width: 100%; }

.tw-handle-drag-drop {
  position: absolute;
  width: 10px;
  height: 100%;
  display: none; }

.tw-line-form-list {
  padding: 5px;
  display: flex;
  align-content: center;
  align-content: center;
  border-bottom: solid;
  border-bottom-color: lightgray;
  border-bottom-width: 1px;
  min-height: 30px;
  width: 100%;
  font-size: 12px;
  min-height: 35px; }

.tw-list-container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%; }

.tw-virtual-repete-container {
  position: relative;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll; }

.tw-virtual-repete-container::-webkit-scrollbar {
  width: 5px; }

/* Track */
.tw-virtual-repete-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5; }

/* Handle */
.tw-virtual-repete-container::-webkit-scrollbar-thumb {
  background-color: dimgrey; }

.tw-header-list {
  display: flex;
  flex-direction: row;
  padding: 5px;
  background-color: #2d323e !important;
  width: 100%;
  min-height: 50px;
  max-height: 50px;
  color: white;
  width: 100%; }

.tw-header-column-list {
  display: flex;
  align-items: center; }

.tw-line-list {
  width: 100%; }
  .tw-line-list form input:invalid {
    background-color: #FA8258; }

.tw-line-list.ng-move,
.tw-line-list.ng-enter,
.tw-line-list.ng-leave {
  transition: all linear 0.5s; }

.tw-line-list.ng-leave.ng-leave-active,
.tw-line-list.ng-move,
.tw-line-list.ng-enter {
  opacity: 0;
  max-height: 0; }

.tw-line-list.ng-leave,
.tw-line-list.ng-move.ng-move-active,
.tw-line-list.ng-enter.ng-enter-active {
  opacity: 1;
  max-height: 30px; }

.tw-line-list:hover {
  background-color: #F8F8F8; }
  .tw-line-list:hover .tw-handle-drag-drop {
    display: flex; }

.tw-list-case-content {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  padding-left: 2px; }

.tw-list-footer {
  width: 100%;
  display: flex; }

.tw-list-custum-footer {
  width: 100%;
  display: flex; }

.tw-list-button {
  background: transparent;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  transition: inherit;
  cursor: pointer;
  height: 30px;
  width: 90px;
  display: flex;
  flex-direction: row;
  align-items: center; }

.tw-list-button-Icon {
  width: 25px;
  margin: 0; }

.tw-list-button-IconImportant {
  width: 25px;
  margin: 0;
  color: red; }

.tw-border-g {
  margin: 0 5px;
  width: 30px;
  height: 30px;
  border: 3px solid;
  border-color: #FFD700;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  text-align: center;
  display: flex;
  position: relative;
  color: #FFD700;
  font-weight: bolder;
  font-size: 20px; }
  .tw-border-g .tw-border-g-description {
    color: #FFD700; }
  .tw-border-g .tw-border-g-comlete {
    position: absolute;
    background-color: #FFD700;
    width: 2px;
    height: 6px;
    top: 18px;
    left: 16px; }

.tw-logo {
  display: flex;
  align-items: center;
  height: 64px;
  padding: 10px; }

.tw-logo-description {
  font-family: unset;
  font-weight: bolder;
  font-size: 25px; }

.tw-datepiker-input {
  border: none;
  color: rgba(0, 0, 0, 0.87);
  border-color: rgba(0, 0, 0, 0.12);
  /* order: 2; */
  display: block;
  margin-top: 0;
  background: none;
  padding-top: 2px;
  padding-bottom: 1px;
  padding-left: 2px;
  padding-right: 2px;
  border-width: 0 0 1px 0;
  line-height: 26px;
  height: 30px;
  border-radius: 0;
  border-style: solid;
  width: 100%;
  box-sizing: border-box;
  float: left; }

/*#projects-autocomplete{
    .description{

    }
    
}

.projects-custom-template.md-autocomplete-suggestions {
    height: auto;
    max-height:10px;
}

.projects-custom-template li {
    border-bottom: 1px solid #ccc;
    height: auto;
    padding-top: 2px;
    padding-bottom: 2px;
    white-space: normal;
}

.projects-custom-template li:last-child {
    border-bottom-width: 0;
}

.projects-custom-template .item-title,
.projects-custom-template .item-metadata {
    display: block;
    line-height: 1.5;
}

.projects-custom-template .item-title md-icon {
    height: 18px;
    width: 18px;
}*/
/* overflowbutton */
.overflowButton {
  height: 28px;
  width: 25px;
  display: flex;
  margin-left: auto; }
  .overflowButton svg {
    fill: #fff; }
  .overflowButton.open svg {
    transform: scaleX(-1); }
  .overflowButton.open .overflowButtonContent {
    transform: translateX(calc(-100% + 25px)); }
  .overflowButton.open .overflowButtonAbsoluteAnchor {
    z-index: 3; }
  .overflowButton.open .overflowButtonPopup {
    visibility: visible; }

/*.overflowButton:hover {
  svg {
    transform: scaleX(-1);
  }

  .overflowButtonContent {
    transform: translateX(calc(-100% + 25px));
  }

  .overflowButtonAbsoluteAnchor {
    z-index: 3;
  }

  .overflowButtonPopup {
    visibility: visible;
  }
}*/
.overflowButtonAbsoluteAnchor {
  position: absolute; }

.overflowButtonContent {
  display: flex;
  overflow: hidden;
  flex: 0 0 auto; }

.overflowButtonToggleButton {
  width: 25px;
  height: 28px;
  min-width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6B6B6B;
  border-radius: 30px 0 0 30px;
  flex: 0 0 auto;
  cursor: pointer; }
  .overflowButtonToggleButton:hover {
    background-color: dampen(#6B6B6B, 20%);
    color: readable(dampen(#6B6B6B, 20%)); }
  .overflowButtonToggleButton:active {
    background-color: #2374BA;
    color: readable(#2374BA); }

.overflowButtonPopup {
  height: 28px;
  flex: 0 0 auto;
  background-color: #6B6B6B;
  display: flex;
  visibility: hidden; }
  .overflowButtonPopup > * {
    margin: 0 0 0 1px; }
  .overflowButtonPopup > *:first-child {
    margin: 0; }

.overflowButtonButtonItem {
  background-color: #404040;
  color: white;
  border-width: 0;
  padding: 2px 3px;
  display: flex;
  align-items: flex-end;
  font-size: 8px;
  -webkit-appearance: none;
  display: flex;
  flex-direction: column-reverse; }
  .overflowButtonButtonItem:hover {
    background-color: dampen(#404040, 40%);
    color: readable(dampen(#404040, 40%)); }
  .overflowButtonButtonItem:focus {
    background-color: dampen(#404040, 40%);
    color: readable(dampen(#404040, 40%));
    outline: 0; }
  .overflowButtonButtonItem:active {
    color: readable(#2374BA);
    background-color: #2374BA; }
  .overflowButtonButtonItem:disabled {
    color: #BFBFBF;
    border: solid 1px;
    border-color: #404040;
    background-color: inherit; }
  .overflowButtonButtonItem span {
    text-overflow: ellipsis;
    text-transform: uppercase; }

/*#projects-gp-autocomplete{
    .description{

    }
    
}

.projects-custom-template.md-autocomplete-suggestions {
    height: auto;
    max-height:10px;
}

.projects-custom-template li {
    border-bottom: 1px solid #ccc;
    height: auto;
    padding-top: 2px;
    padding-bottom: 2px;
    white-space: normal;
}

.projects-custom-template li:last-child {
    border-bottom-width: 0;
}

.projects-custom-template .item-title,
.projects-custom-template .item-metadata {
    display: block;
    line-height: 2;
}

.projects-custom-template .item-title md-icon {
    height: 18px;
    width: 18px;
}*/
.tw-navigation-button {
  padding: 0 16px 0 16px;
  background-color: #595959;
  border-left: solid 1px #404040;
  border-top: solid 1px #404040;
  line-height: 50px;
  height: 100%;
  display: flex;
  text-align: center;
  color: white;
  width: 100%;
  height: 50px; }

.tw-navigation-button-selected {
  padding: 0 16px 0 16px;
  background-color: #404040;
  border-left: solid 1px #404040;
  border-top: solid 1px #404040;
  width: 100%;
  height: 50px;
  display: flex;
  text-align: center;
  color: #EDB415;
  width: 100%;
  height: 50px; }

.tw-navigation-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 0 16px 0 16px;
  height: 64px;
  min-height: 64px; }

.tw-navigation-header-logo {
  padding: 5px;
  flex: 1;
  align-self: center; }

.tw-sidenav {
  transition-property: width;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  height: 100%; }

/*.tw-sidenav:hover {
  width: 240px;
}*/
/*#sites-autocomplete{
    .description{

    }
    
}

.sites-custom-template.md-autocomplete-suggestions {
    height: auto;
    max-height:10px;
}

.sites-custom-template li {
    border-bottom: 1px solid #ccc;
    height: auto;
    padding-top: 2px;
    padding-bottom: 2px;
    white-space: normal;
}

.sites-custom-template li:last-child {
    border-bottom-width: 0;
}

.sites-custom-template .item-title,
.sites-custom-template .item-metadata {
    display: block;
    line-height: 2;
}

.sites-custom-template .item-title md-icon {
    height: 18px;
    width: 18px;
}*/
/*#sites-gp-autocomplete{
    .description{

    }
    
}

.sites-custom-template.md-autocomplete-suggestions {
  height: auto;
  max-height: 10px;
}

.sites-custom-template li {
  border-bottom: 1px solid #ccc;
  height: auto;
  padding-top: 2px;
  padding-bottom: 2px;
  white-space: normal;
}

.sites-custom-template li:last-child {
  border-bottom-width: 0;
}

.sites-custom-template .item-title,
.sites-custom-template .item-metadata {
  display: block;
  line-height: 2;
}

.sites-custom-template .item-title md-icon {
  height: 18px;
  width: 18px;
}*/
#tw-states-menu {
  height: 100%; }
  #tw-states-menu .group {
    margin-top: 20px;
    margin-left: 20px; }
    #tw-states-menu .group .group-title {
      margin: 0 7px;
      font-weight: bold;
      font-size: 20px;
      color: rgba(128, 128, 128, 0.63);
      text-transform: capitalize;
      border-bottom: 2px dotted rgba(128, 128, 128, 0.63); }
    #tw-states-menu .group .group-content .md-button {
      min-height: 150px;
      min-width: 150px;
      border-radius: 15px;
      margin-left: 7px;
      margin-bottom: 7px;
      font-size: 10px; }

.tw-toolbar-app {
  display: flex; }

.tw-toolbar-app-option {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  width: 16px;
  height: 16px;
  top: 20px;
  right: -8px;
  border-radius: 50%;
  display: flex; }

.horizontal {
  position: absolute;
  background-color: white;
  width: 10px;
  height: 2px;
  top: calc(50% - 1px);
  left: calc(50% - 5px); }

.vertical {
  position: absolute;
  background-color: white;
  width: 2px;
  height: 10px;
  top: calc(50% - 5px);
  left: calc(50% - 1px); }

.tw-toolbar-app-button {
  background: transparent;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  transition: inherit;
  cursor: pointer;
  height: 70px;
  width: 70px;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .tw-toolbar-app-button:disabled .tw-toolbar-app-button-content .tw-toolbar-app-circle {
    color: dimgrey; }
    .tw-toolbar-app-button:disabled .tw-toolbar-app-button-content .tw-toolbar-app-circle .tw-toolbar-app-icon {
      color: dimgrey; }
    .tw-toolbar-app-button:disabled .tw-toolbar-app-button-content .tw-toolbar-app-circle .tw-toolbar-app-option {
      background: dimgrey; }
  .tw-toolbar-app-button:disabled .tw-toolbar-app-button-content .tw-toolbar-app-circle:hover {
    background: transparent; }
  .tw-toolbar-app-button:disabled .tw-toolbar-app-label {
    color: dimgrey; }
  .tw-toolbar-app-button:disabled:hover .tw-toolbar-app-circle {
    background: transparent; }
  .tw-toolbar-app-button:hover .tw-toolbar-app-circle {
    background: gray; }
  .tw-toolbar-app-button .tw-toolbar-app-button-content {
    width: 100%; }
    .tw-toolbar-app-button .tw-toolbar-app-button-content .tw-toolbar-app-circle {
      position: relative;
      border-color: rgba(0, 0, 0, 0.8);
      color: rgba(0, 0, 0, 0.8);
      border-width: 0.5px;
      border-radius: 50%;
      height: 35px;
      display: flex;
      margin: auto;
      border: solid;
      width: 35px; }
      .tw-toolbar-app-button .tw-toolbar-app-button-content .tw-toolbar-app-circle .tw-toolbar-app-icon {
        color: rgba(0, 0, 0, 0.8); }
    .tw-toolbar-app-button .tw-toolbar-app-button-content .tw-toolbar-app-circle:hover {
      background: gray; }

.tw-toolbar-app-label {
  font-size: 10px;
  flex: 1;
  width: 100%;
  white-space: normal;
  line-height: 10px;
  padding: 2px;
  padding-top: 5px;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 12px;
  color: rgba(0, 0, 0, 0.8); }

.tw-arrow-down {
  width: 0px;
  height: 0px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #f00; }

.tw-arrow-right {
  width: 0px;
  height: 0px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #f00; }

.tw-autocomplete {
  border-bottom: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0.12); }
  .tw-autocomplete md-autocomplete {
    height: auto; }
    .tw-autocomplete md-autocomplete md-autocomplete-wrap {
      box-shadow: none;
      height: auto; }

.employeeTable .search {
  position: relative;
  padding-left: 24px;
  max-width: 480px; }
  .employeeTable .search .search-icon {
    margin: 0 -24px 0 0; }
  .employeeTable .search .search-input {
    margin: 0 0 0 32px;
    background: none;
    font-size: 16px;
    line-height: 40px;
    height: 40px;
    color: #2d323e;
    border-bottom: 2px solid #2d323e;
    transition: border-color 300ms ease; }
    .employeeTable .search .search-input:focus {
      border-color: #2d323e; }
    .employeeTable .search .search-input::-webkit-input-placeholder {
      color: #2d323e; }

.employeeTable .dataTables_wrapper .dataTables_scrollHead {
  min-height: 50px; }
  .employeeTable .dataTables_wrapper .dataTables_scrollHead tbody tr {
    padding: 5px 16px; }
  .employeeTable .dataTables_wrapper .dataTables_scrollHead tbody td {
    padding-top: 0px;
    padding-bottom: 0px; }

.academy-preview-modules {
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-wrap: wrap; }

.academy-preview-singlemodule {
  width: 400px;
  height: 250px;
  display: flex;
  padding: 15px;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: white;
  margin: 5px;
  overflow: hidden;
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12); }

.academy-preview-singlemodule:hover .academy-preview-singlemodule-title {
  color: blue; }

.academy-xp {
  background: #e1f5fe;
  margin: 0px;
  padding: 5px;
  align-self: center;
  line-height: 20px; }

.academy-preview-singlemodule-title {
  width: 100%;
  height: 40px;
  font-size: 20px;
  font-weight: bold;
  padding: 15px;
  line-height: 1.3;
  text-decoration: none !important; }

.academy-preview-singlemodule-info {
  width: 100%;
  font-size: 15px;
  padding: 5px; }

.academy-preview-singlemodule-tags {
  flex: 1;
  overflow: hidden;
  padding: 5px;
  display: flex;
  flex-wrap: wrap; }

.academy-preview-detail-page {
  width: 900px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 5px;
  padding: 15px;
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12); }

.academy-unit-line {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px; }

.academy-unit-separator {
  width: 5px;
  background-color: #2d323e;
  height: 40px; }

.academy-unit-line:hover .academy-unit-separator {
  background-color: #ffb300; }

.step-container {
  display: flex;
  height: 80px;
  align-items: flex-start;
  position: relative;
  align-content: center; }
  .step-container .selected-step {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    max-width: 250px;
    background: #e1f5fe;
    color: rgba(0, 0, 0, 0.87); }
  .step-container .step {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    max-width: 250px;
    color: rgba(0, 0, 0, 0.54); }
  .step-container .steptitle {
    padding: 4px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.54);
    flex: 1; }
  .step-container .steptitle-completed {
    padding: 4px;
    font-weight: bold;
    flex: 1; }
  .step-container .round-step {
    width: 25px;
    height: 25px;
    line-height: 22px;
    border-radius: 25px;
    background-color: white;
    border: 2px solid gray;
    color: gray;
    z-index: 3;
    left: 0;
    text-align: center;
    font-size: 14px; }
  .step-container .round-step-completed {
    width: 25px;
    height: 25px;
    line-height: 22px;
    border-radius: 25px;
    background-color: white;
    border: 2px solid green;
    color: green;
    z-index: 3;
    left: 0;
    text-align: center;
    font-size: 14px; }
  .step-container .round-step::after {
    content: '';
    position: absolute;
    background: gray;
    display: block;
    width: 4px;
    height: 100%;
    top: 50px;
    left: -2px;
    z-index: 1;
    margin-left: 12px; }
  .step-container .round-step-completed::after {
    content: '';
    position: absolute;
    background: green;
    display: block;
    width: 4px;
    height: 100%;
    top: 50px;
    left: -2px;
    z-index: 1;
    margin-left: 12px; }
  .step-container:last-child .round-step::after {
    display: none; }
  .step-container:last-child .round-step-completed::after {
    display: none; }

.tw-comment {
  margin: 5px;
  display: flex;
  flex-direction: row;
  border: solid;
  border-color: gainsboro;
  border-width: 1px;
  border-radius: 5px;
  background-color: gainsboro;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  padding: 4px; }
  .tw-comment input {
    background-color: gainsboro; }

.tw-pointer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0px;
  height: 0px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 20px solid gainsboro;
  margin-left: 20px;
  margin-bottom: -20px; }

.tw-selectableship {
  min-width: 30px;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  font-size: 11px;
  font-weight: bold;
  border-radius: 20px;
  background-color: gainsboro;
  flex-wrap: nowrap;
  display: flex;
  margin: 1px; }

.tw-schedulegroup-container {
  display: flex; }
  .tw-schedulegroup-container md-select {
    margin: 0;
    margin-bottom: 4px; }
  .tw-schedulegroup-container md-input-container {
    margin: 0;
    padding: 0; }

.tw-schedule-date {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  overflow: hidden;
  border-bottom-width: 1px;
  border-left-width: 0px;
  border-right-width: 1px;
  border-top-width: 1px;
  border-style: solid;
  font-size: 12px;
  border-color: #909090; }

.tw-schedule-date-item {
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column; }

.tw-schedule-arrow-container {
  width: 20px;
  height: 100%;
  margin-left: 3px;
  display: flex;
  align-items: center; }

.tw-schedule-date-selected {
  background-color: #E8E8E8; }

.tw-schedule-closed {
  width: 100%;
  height: 100%;
  background-image: repeating-linear-gradient(-45deg, black, black 1px, transparent 1px, transparent 6px);
  background-size: 8px 8px; }

.tw-schedule-closed-hover {
  width: 100%;
  height: 100%;
  background-image: repeating-linear-gradient(-45deg, #1E90FF, #1E90FF 1px, transparent 1px, transparent 6px);
  background-size: 8px 8px; }

.tw-schedule-row {
  height: 30px;
  line-height: 30px;
  display: flex;
  flex-direction: column;
  border-bottom-width: 1px;
  border-left-width: 0px;
  border-right-width: 1px;
  border-top-width: 1px;
  border-style: solid;
  font-size: 12px;
  border-color: #909090; }

.tw-schedule-right {
  width: 200px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  border-bottom-width: 1px;
  border-left-width: 0px;
  border-right-width: 1px;
  border-top-width: 1px;
  border-style: solid;
  font-size: 12px;
  border-color: #909090; }

.tw-schedule-right-column {
  width: 200px;
  min-width: 200px;
  max-width: 200px;
  height: 30px;
  display: flex;
  flex-direction: row;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  border-style: solid;
  font-size: 12px;
  align-items: center;
  overflow: hidden;
  -webkit-user-select: none;
  /* Safari 3.1+ */
  -moz-user-select: none;
  /* Firefox 2+ */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  /* Standard syntax */ }

.tw-schedule-right-border {
  display: flex;
  align-items: center;
  height: 100%;
  flex: 1;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-width: 1px;
  border-style: solid;
  background-color: #E8E8E8;
  border-radius: 15px 0px 0px 15px;
  border-color: #909090;
  overflow: hidden; }

.overflow-menu {
  display: none; }

.tw-schedule-right-border:hover .overflow-menu {
  display: flex; }

.tw-schedule-shift-creation {
  /*border-color: black;
  border-style:groove;
  border-width:1px;
  border-right-width:0;
  border-radius:5px;*/ }
  .tw-schedule-shift-creation .tw-schedule-right-border {
    background-color: #F8F8F8; }
  .tw-schedule-shift-creation .tw-schedule-date {
    background-color: #F8F8F8; }

.tw-schedule-data-column {
  height: 30px;
  overflow: hidden;
  display: flex;
  flex: 1;
  background-color: #E8E8E8; }

.tw-schedule-line {
  display: flex;
  padding-bottom: 2px;
  padding-top: 2px; }

.tw-schedule-line-description {
  margin-left: 5px;
  flex: 1;
  text-overflow: ellipsis;
  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
  font-size: 11px; }

.tw-schedule-line-data-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto; }

.tw-schedule-icon-container {
  width: 25px; }

.tw-schedule-right-column-step0 {
  width: 5px;
  min-width: 5px; }

.tw-schedule-right-column-step {
  width: 15px;
  min-width: 15px; }

.tw-schedule-right-column-step1 {
  width: 25px;
  min-width: 25px; }

.tw-schedule-right-column-step2 {
  width: 35px;
  min-width: 35px; }

.tw-schedule-arrow-down {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid black;
  margin: auto; }

.tw-schedule-arrow-right {
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid black;
  margin: auto; }

.tw-schedule-column-data-content {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  line-height: 30px; }

.tw-schedule-column-status-ok {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  line-height: 30px;
  background-color: #32CD32;
  border-color: #1E90FF;
  border-top-style: solid;
  border-top-width: 2px;
  border-bottom-style: solid;
  border-bottom-width: 2px; }

.tw-schedule-column-start {
  border-left-style: solid;
  border-left-color: #1E90FF;
  border-left-width: 2px; }

.tw-schedule-column-end {
  border-right-style: solid;
  border-right-color: #1E90FF;
  border-right-width: 2px; }

.tw-schedule-column-status-no {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  line-height: 30px;
  background-color: #F08080; }

.tw-schedule-column-status-info-ok {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  line-height: 30px;
  background-color: rgba(50, 205, 50, 0.6); }

.tw-schedule-column-status-info-no {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  line-height: 30px;
  background-color: rgba(240, 128, 128, 0.6); }

.tw-schedule-date-item-data:hover {
  background-color: #C0C0C0; }

.tw-schedule-date-item-data {
  display: flex;
  cursor: cell;
  -webkit-user-select: none;
  /* Safari 3.1+ */
  -moz-user-select: none;
  /* Firefox 2+ */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  /* Standard syntax */ }

.tw-schedule-crosscase input {
  border: none;
  text-align: center;
  font-weight: bold;
  font-size: 15px; }

.tw-schedule-inselection {
  background-color: red; }

.tw-schedule-animate-repeat.ng-move,
.tw-schedule-animate-repeat.ng-enter,
.tw-schedule-animate-repeat.ng-leave {
  transition: all linear 0.5s; }

.tw-schedule-animate-repeat.ng-leave.ng-leave-active,
.tw-schedule-animate-repeat.ng-move,
.tw-schedule-animate-repeat.ng-enter {
  opacity: 0;
  max-height: 10px; }

.tw-schedule-animate-repeat.ng-leave,
.tw-schedule-animate-repeat.ng-move.ng-move-active,
.tw-schedule-animate-repeat.ng-enter.ng-enter-active {
  opacity: 1;
  max-height: 30px; }

.tw-schedule-case-selected {
  border-width: 3px;
  border-style: solid;
  border-color: gray; }

/*.tw-schedule-case-selected::after {
  content: " - Remember this";
}*/
.tw-tree-shipping-articles {
  margin: 5px;
  flex: 1;
  display: flex;
  flex-direction: column; }
  .tw-tree-shipping-articles .angular-ui-tree-dropzone {
    display: none; }
  .tw-tree-shipping-articles .angular-ui-tree-empty {
    display: none; }

.tw-shipping-article-content {
  background-color: gainsboro;
  border-radius: 10px;
  border-color: gainsboro;
  border-style: solid;
  border-width: 1px;
  margin: 4px; }

.tw-shipping-article-contentainer {
  background-color: gray;
  border-color: black;
  border-width: 1px;
  border-style: dashed;
  border-radius: 10px;
  margin: 5px; }

.tw-filedset {
  border-style: solid;
  border-color: gainsboro;
  border-width: 1px;
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  margin: 10px;
  padding: 5px; }
  .tw-filedset textarea {
    width: 100%;
    height: 100%;
    border-width: 2px;
    border-color: black;
    margin: 0px;
    flex: 1; }
  .tw-filedset md-input-container {
    margin: 5px;
    margin-top: 15px;
    flex: 1; }
    .tw-filedset md-input-container .md-errors-spacer {
      display: none; }

.tw-header-container {
  height: 100px;
  min-height: 100px;
  max-height: 100px;
  display: flex; }

.tw-header-content {
  height: 100%;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  margin-left: 0;
  margin-right: 0;
  flex: 1; }

.tw-header-additionalcontent {
  display: flex;
  background-color: white;
  align-items: center;
  flex: 1;
  height: 75px;
  padding: 10px;
  margin-left: 10px;
  height: 50px; }
  .tw-header-additionalcontent md-input-container {
    margin: 0;
    padding: 0;
    padding-right: 5px;
    height: 100%;
    max-width: 250px; }
  .tw-header-additionalcontent md-select {
    margin: 0;
    padding: 0;
    padding-right: 5px;
    height: 100%;
    max-width: 250px; }
  .tw-header-additionalcontent md-checkbox {
    margin: 0;
    padding: 0;
    padding-right: 5px;
    max-width: 250px;
    height: 100%; }

.tw-header-filtedbutton {
  height: 75px;
  display: flex;
  flex: 1; }

#feedback .dialog .case1 {
  width: 30%; }

#feedback .dialog .case2 {
  width: 75%; }
