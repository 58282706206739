form {

  .tw-filedset {
    border-style: solid;
    border-color: gainsboro;
    border-width: 1px;
    color: rgba(0,0,0,0.87);
    // background-color: rgb(255,255,255);
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    margin: 10px;
    padding: 5px;

    textarea {
      width: 100%;
      height: 100%;
      border-width: 2px;
      border-color: black;
      margin: 0px;
      flex: 1;
    }

    md-input-container {
      margin: 5px;
      margin-top: 15px;
      flex: 1;

      .md-errors-spacer {
        display: none;
      }
    }

  }

  .md-input[disabled] {
    color: rgba(0, 0, 0, 0.8) !important
  }

  md-select[disabled] {
    md-select-value {
      color: rgba(0, 0, 0, 0.8) !important
    }
  }

  .signature-container {
    margin: 30px 0 8px 0;

    .signature-div {
      font-size: 11px;
      border-top: 1px solid #ccc !important;
      margin: 8px 8px 8px 0;
    }
  }

  .formly-autocomplete md-input-container {
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: -8px;
    margin: 8px 16px 8px 0;
  }

  .formly-autocomplete md-progress-linear {
    padding-right: 12px !important;
    top: 35px !important;
  }
}

.compact-form {
  > md-input-container,
  > md-select {
    padding: 24px 0px;
    margin-bottom: 0 !important;
    margin-left: 0 !important;
    margin-top: 10px !important;
    max-height: 30px;
    

    label {
      position: absolute;
      bottom: 45%;
      left: 0px;
      right: auto;
    }
  }

  > md-checkbox {
    padding: 24px 0px;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    max-height: 24px;

    .md-container {
      margin: 0px 5px !important;
    }

    label {
      position: absolute;
      bottom: 45%;
      left: 0;
      right: auto;
    }
  }

  md-autocomplete > md-autocomplete-wrap > md-input-container {
    padding: 24px 5px;
    margin-bottom: 0 !important;
    margin-top: 10px !important;
    max-height: 30px;

    label {
      position: absolute;
      bottom: 45%;
      left: 5px;
      right: auto;
    }
  }

  md-list {
    padding: 5px 0px;
    margin-bottom: 0 !important;
    margin-top: 10px !important;

    .md-subheader-inner {
      font-weight: normal;
      padding: 0px 3px;
      margin: 0px;
      font-size: 1.2rem;
    }

    md-list-item {
      padding: 0px 3px;
      margin: 0px;
      min-height: 38px;
      max-height: 38px;
    }
  }
}


.autocompleteTmpl li {
  border-bottom: 1px solid #ccc;
  height: auto;
  padding-left: 2px;
  padding-top: 2px;
  padding-bottom: 2px;
  white-space: normal;
}

.autocompleteTmpl li:last-child {
  border-bottom-width: 0;
}

.autocompleteTmpl .item-title,
.autocompleteTmpl .item-metadata {
  display: inline;
  line-height: 1;
  padding: 2px;
}

.autocompleteTmpl .item-title img {
  height: 32px;
  width: 32px;
  padding: 0;
}

.highlight {
  color: rgb(255,179,0) !important;
  font-weight: bold !important;
}

.fiedValue {
  min-height: 32px;
  height: 32px;
  padding: 8px 2px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}



.compact-list md-input-container,
.compact-list md-select,
.compact-list md-checkbox {
  padding: 2px 5px;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  max-height: 30px;
}

.compact-list .md-button {
  margin: 0 !important;
  padding: 3px !important;
}

.compact-list {


  .listHeader {
    margin-top: 15px;
    border-bottom: 1px solid #ccc;
  }
}

.compact-list md-list-item {
  max-height: 35px !important;
  padding: 0;
}

.compact-list form {
  max-height: 35px !important;
  padding: 0;
}

.compact-list p {
  max-height: 35px !important;
  font-size: 11px;
  padding: 0;
  margin: 0;
}

.compact-list md-list-item .header {
  padding: 0;
}
