/*----------------------------------------------------------------*/
/*  tw-widget
/*----------------------------------------------------------------*/

// Global tw-widget styles
.tw-widget {
  height: 100%;
  padding:0px;

  .widgetDragHandle {
    cursor: pointer;
  }

  .tw-widget-front {
    box-shadow: $whiteframe-shadow-1dp;
  }

  .tw-widget-back {
    box-shadow: $whiteframe-shadow-1dp;
  }

  &.sidenav-widget {

    .tw-widget-front,
    .tw-widget-back {
      box-shadow: none;
    }
  }
  // Flip to front button
  .flip-to-front {
    position: absolute;
    top: 0;
    right: 0;
  }
  // ie fix
  .c3 {
    height: 100%;
  }
  // chart fill
  @for $i from 1 through 10 {

    .chart-fill-#{$i * 8} {
      margin: 0 #{(-$i * 8) + 'px'} #{(-$i * 8) + 'px'} #{(-$i * 8) + 'px'};
    }
  }
}

// tw-widget
$widgetPadding: 12px;

.tw-widget {
  position: relative;
  font-size: $font-size-base;
  perspective: 3000px;
  padding: $widgetPadding;

  .tw-widget-front {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    position: relative;
    overflow: hidden;
    visibility: visible;
    width: 100%;
    opacity: 1;
    z-index: 10;
    border-radius: 2px;
    transition: transform 0.5s ease-out 0s, visibility 0s ease-in 0.2s, opacity 0s ease-in 0.2s;
    transform: rotateY(0deg);
    backface-visibility: hidden;

    .widget-title {
      height: 41px;
      max-height: 41px;
      min-height: 41px;
      background-color: #474747;
      color: #fff;

      md-icon {
        color: #fff;
      }
    }
  }

  .tw-widget-back {
    display: block;
    position: absolute;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    z-index: 10;
    top: $widgetPadding;
    right: $widgetPadding;
    bottom: $widgetPadding;
    left: $widgetPadding;
    border-radius: 2px;
    transition: transform 0.5s ease-out 0s, visibility 0s ease-in 0.2s, opacity 0s ease-in 0.2s;
    transform: rotateY(180deg);
    backface-visibility: hidden;
  }

  .md-button {
    margin: 0;

    &.md-icon-button {
      margin: 0;
    }
  }

  &.flipped {

    .tw-widget-front {
      visibility: hidden;
      opacity: 0;
      transform: rotateY(180deg);
    }

    .tw-widget-back {
      display: block;
      visibility: visible;
      opacity: 1;
      transform: rotateY(360deg);
    }
  }

  &.sidenav-widget {
    padding: 0;
  }

  &.ar-2-1 {

    .widget {
      padding: 0;
      @include maintain-aspect-ratio(2, 1, 8px, widget);
    }
  }

  &.ar-1-1 {

    .widget {
      padding: 0;
      @include maintain-aspect-ratio(1, 1, 8px, widget);
    }
  }
}
