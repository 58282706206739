#tw-widget-meters {

  md-select {
    margin:5px;
  }
  table {
    width: 100%;
    border-spacing: 0;
    margin: 0;

    th {
      margin: 0;
      padding: 5px 15px;
    }

    td {
      max-height: 25px;
      height: 25px;
      border-bottom: solid;
      border-bottom-width: 1px;
      border-bottom-color: lightgray;
      padding: 5px 15px;
    }

    tbody > tr {
      cursor: pointer;
    }

    tbody > tr:hover {
      background-color: #ffb300;
    }
  }
}
