  .employeeTable {

    .search {
      position: relative;
      padding-left: 24px;
      max-width: 480px;

      .search-icon {
        margin: 0 -24px 0 0;
      }

      .search-input {
        margin: 0 0 0 32px;
        background: none;
        font-size: 16px;
        line-height: 40px;
        height: 40px;
        color: #2d323e;
        border-bottom: 2px solid #2d323e;
        transition: border-color 300ms ease;

        &:focus {
          border-color: #2d323e;
        }

        &::-webkit-input-placeholder {
          color: #2d323e;
        }
      }
    }

    .dataTables_wrapper {

      .dataTables_scrollHead {
        min-height: 50px;

        tbody {
          tr {
            padding: 5px 16px;
          }

          td {
            padding-top: 0px;
            padding-bottom: 0px;
          }
        }
      }

      .dataTables_scrollBody {
      }
    }
  }
