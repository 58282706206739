#tw-widget-meters-total {

  background-color:#F4B700;

  .meters {
    font-size: 50px;
    font-stretch: condensed;
    line-height: 50px;
    font-weight: bold;
  }


  .target {
    margin-top:10px;
    font-weight: bold;
    font-size: 20px;
    font-stretch: condensed;
    line-height: 20px;
    color:#808080;
  }
}
