#tw-widget-today {

  .weekday {
  }

  .day {
    font-size: 65px;
    font-stretch:condensed;
    line-height: 68px;
    font-weight: bold;
  }


  .month {
    font-weight: bold;
  }
}
