.tw-page-content-form{
  font-size:12px;
}
.tw-flayinglayout-title {
  display:flex;
  span{
    color:white;
    font-size:18px;
  }
} 

.tw-Sidenav-title {
  height: 30px;
  background-color: #E4E4E4;
  height: 100%;
  width: 50px;
  display: flex;
  position: relative;
}
.tw-page-flyout-title-title {
  transform: rotate(-90deg) translateX(-100%);
  transform-origin: 0 0;
  margin: 22px 0 0 0;
  height: 100%;
  text-transform: uppercase;
  font-size: 32px;
  color: #7f7f7f;
  white-space: nowrap;
  flex:1;
}
.tw-page-shadow {
  height: 100%;
  width: 5px;
  background: linear-gradient(to right, rgba(45,50,62,1),rgba(245,245,245,0));
}

.tw-boxshadow {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.tw-full-toolbar {
  background-color: white;
  height:100%;
  width: 80px;
  padding-top: 5px;
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.tw-tooltip {
  background-color: #555;
  color: #fff;
  max-width: 200px;
  background-color: #555;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 2px;
  transition: opacity 0.3s;
  white-space: pre-wrap;
  font-size:10px;
}

.tw-mouse-selection{
  //width:150px;
  height:auto;
  padding:5px;
  background-color:gainsboro;
  display:flex;
  flex-direction:column;
}
.tw-inputcontainer {
  border-bottom-color: rgba(0,0,0,0.12);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding: 2px 2px 1px;
  background-color: transparent;

  input {
    border: none;
  }
}

.tw-label {
  color: rgba(0,0,0,0.54);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  font-size: 12px;
  font-weight: bold;

  -webkit-box-ordinal-group: 2;
  order: 1;
  pointer-events: none;
  -webkit-font-smoothing: antialiased;
  padding-left: 3px;
  padding-right: 0;
  z-index: 1;

  max-width: 100%;

}
