.tw-function-select {
  margin:5px;
  md-autocomplete {
    background-color: transparent;
  }

  md-autocomplete-wrap {
    background-color: transparent;
  }

  input[type="search"] {
    background-color: transparent;
  }
}
