#tw-widget-orders-distribution {
  .over {
    background-color: rgba(255, 0, 0, 0.57)
  }

  #tw-widget-orders-distribution-list {

    table.projects {
      width: 100%;
      border-spacing: 0;
      margin: 0;

      th {
        margin: 0;
        padding: 5px 15px;
      }

      td {
        max-height: 25px;
        height: 25px;
        border-bottom: solid;
        border-bottom-width: 1px;
        border-bottom-color: lightgray;
        padding: 5px 15px;
      }

      tbody > tr {
        cursor: pointer;
      }

      tbody > tr:hover {
        background-color: #ffb300;
      }
    }
  }

  #tw-widget-orders-distribution-details {

    .details-header {
      max-height: 45px;
      min-height: 45px;
      height: 45px;
    }

    .details-content {

      .details-content-body {
        overflow: scroll;
        overflow-x: hidden;
        display: block;
      }
    }

    table.categories {
      width: 100%;
      border-spacing: 0;
      margin: 0;

      th {
        margin: 0;
        padding: 5px 15px;
      }

      td {
        max-height: 15px;
        height: 15px;
      }

      table.category {
        width: 100%;
        border-spacing: 0;
        margin: 0;

        tr.categoryHeader {
          td {
            max-height: 15px;
            height: 15px;
            font-weight: bold;
            border-bottom: solid;
            border-bottom-width: 1px;
            border-bottom-color: lightgray;
            padding: 5px 15px;
            background-color: #ededed;
          }
        }

        tr.categoryBody {

          td {
            font-size: 10px;
            max-height: 15px;
            height: 15px;
            border-bottom: solid;
            border-bottom-width: 1px;
            border-bottom-color: lightgray;
            padding: 5px 15px 5px 35px;
          }
        }
      }
    }
  }
}
