.green {
    color:#1a7e00;
}

.red {
    color:#ab0000;
}

.FOGyellow_fg {
  color: #ffb300 !important; 
}

.FOGyellow_bg {
  background-color: #ffb300;
}

.FOGgrey_fg {
  color: #2d323e;
}

.FOGgrey_fg-title {
  color: #2d323e;
  font-size:28px;
  font-weight:bold;
}

.FOGgrey_fg-subtitle {
  color: #2d323e;
  font-size: 18px;
}


.FOGgrey_bg {
  background-color: #2d323e;
}

@media only screen and (max-width: 600px) {
  .FOGgrey_fg-title {
    color: #2d323e;
    font-size: 14px;
    font-weight: bold;
  }

  .FOGgrey_fg-subtitle {
    color: #2d323e;
    font-size: 12px;
  }
}
