
#catalogs {
  /*.md-chips .md-chip-wraps md-chip {

    margin: 0px 8px 0px 0px;
  }*/
  .md-small-button {
    padding: 0px;
    margin: 0px;
    height: 36px;
    width: 36px;
    min-width: 36px !important;
  }

  .md-medium-button {
    padding: 5px;
    margin: 5px;
    height: 50px;
    width: 50px;
    min-width: 50px !important;
    max-width: 50px;
  }

  md-list.make-scrollable {
    //    height: 659px;
    max-height: 100%; //Or any other height you wish
    overflow-y: scroll;
  }

  .center {


    .header {
      height: 130px !important;
      min-height: 100px !important;
      max-height: 100px !important;
      padding: 24px 0;

      .h1 {
        md-icon {
          margin-right: 12px;
        }
      }

      .product-count {
        margin: 6px 0 0 36px;
      }

      .search {
        position: relative;
        padding-left: 24px;
        max-width: 480px;

        .search-icon {
          margin: 0 -24px 0 0;
        }

        .search-input {
          margin: 0 0 0 32px;
          background: none;
          font-size: 16px;
          line-height: 40px;
          height: 40px;
          color: #2d323e;
          border-bottom: 2px solid #2d323e;
          transition: border-color 300ms ease;

          &:focus {
            border-color: #2d323e;
          }

          &::-webkit-input-placeholder {
            color: #2d323e;
          }
        }
      }
    }

    .content-card {

      .toolbar {
        padding: 8px;
      }

      .content {
        padding: 0;

        .dataTables_wrapper {

          .dataTables_scrollHead {
            min-height: 50px;

            table {
              th {
                padding-top: 15px;
                padding-bottom: 16px;
              }
            }
          }

          .dataTables_scrollBody {
          }
        }
      }
    }
  }
}

#article-details {


  .filesTitle {
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: rgb(45,50,62);
    padding: 8px;
    font-weight: bold;
    color: #FFFFFF;
  }

  .thumbImg {
    margin-right: 10px;
    max-height: 50px;
    height: 50px;
    max-width: 50px;
    width: 50px;
  }

  .articlePictures {
    width: 250px;
  }

  .tab-body-large{
    height: 400px;
    padding: 10px 10px 10px 10px

  }
}
