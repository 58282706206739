.tw-comment {
  margin: 5px;
  display: flex;
  flex-direction: row;
  border: solid;
  border-color: gainsboro;
  border-width: 1px;
  border-radius: 5px;
  background-color: gainsboro;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  padding: 4px;

  input {
    background-color: gainsboro;
  }
}
.tw-pointer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0px;
  height: 0px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 20px solid gainsboro;
  margin-left: 20px;
  margin-bottom: -20px;
}
.tw-selectableship {
  min-width: 30px;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  font-size: 11px;
  font-weight: bold;
  border-radius: 20px;
  background-color: gainsboro;
  flex-wrap: nowrap;
  display: flex;
  margin: 1px;
}

