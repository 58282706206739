.tw-tree-shipping-articles {
  margin: 5px;
  flex: 1;
  display: flex;
  flex-direction: column;

  .angular-ui-tree-dropzone {
    display: none;
  } 

  .angular-ui-tree-empty {
    display: none;
  }
}
.tw-shipping-article-content {
  background-color: gainsboro;
  border-radius: 10px;
  border-color: gainsboro;
  border-style: solid;
  border-width: 1px;
  margin: 4px;
}
.tw-shipping-article-contentainer {
  background-color: gray;
  border-color: black;
  border-width: 1px;
  border-style: dashed;
  border-radius: 10px;
  margin: 5px;
}
.tw-filedset {
  border-style: solid;
  border-color: gainsboro;
  border-width: 1px;
  color: rgba(0,0,0,0.87);
  background-color: rgb(255,255,255);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  margin: 10px;
  padding: 5px;

  textarea {

    width: 100%;
    height: 100%;

    border-width: 2px;
    border-color: black;
    margin: 0px;
    flex: 1;
  }

  md-input-container {
    margin: 5px;
    margin-top: 15px;
    flex: 1;

    .md-errors-spacer {
      display: none;
    }
  }
}

