/**
 *  Do not remove this comments bellow. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
// bower:scss
// endbower

/**
 *  Do not remove this comments bellow. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */
// injector
// endinjector


///override highlight

.badge-grey {
    min-width: 20px;
    height: 20px;
    line-height: 20px;
    padding: 0 7px;
    font-size: 11px;
    font-weight: bold;
    border-radius: 15px;
    background-color: grey;
    color: white;
    margin:1px;
}

.badge-green {
    min-width: 20px;
    height: 20px;
    line-height: 20px;
    padding: 0 7px;
    font-size: 11px;
    font-weight: bold;
    border-radius: 15px;
    background-color: grey;
    background-color: darkgreen;
    color: white;
}

.badge-red {
    min-width: 20px;
    height: 20px;
    line-height: 20px;
    padding: 0 7px;
    font-size: 11px;
    font-weight: bold;
    border-radius: 15px;
    background-color: grey;
    color: white;
}

.highlight {
    color: rgb(255,179,0) !important;
    font-weight: bold !important;
}

.fiedValue {
    min-height: 32px;
    height: 32px;
    padding: 8px 2px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
