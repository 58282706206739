.tw-toolbar-app {
  display: flex;
}
.tw-toolbar-app-option {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  width: 16px;
  height: 16px;
  top: 20px;
  right:-8px;
  border-radius: 50%;
  display: flex;
}

.horizontal {
  position: absolute;
  background-color: white;
  width: 10px;
  height: 2px;
  top: calc(50% - 1px);
  left: calc(50% - 5px);
}
.vertical {
  position: absolute;
  background-color: white;
  width: 2px;
  height: 10px;
  top: calc(50% - 5px);
  left: calc(50% - 1px);
}


.tw-toolbar-app-button {
  background: transparent;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  transition: inherit;
  cursor: pointer;
  height: 70px;
  width: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:disabled {
    .tw-toolbar-app-button-content {
      .tw-toolbar-app-circle {
        color: dimgrey;

        .tw-toolbar-app-icon {
          color: dimgrey;
        }

        .tw-toolbar-app-option {
          background: dimgrey;
        }
      }

      .tw-toolbar-app-circle:hover {
        background: transparent;
      }
    }

    .tw-toolbar-app-label {
      color: dimgrey;
    }
  }

  &:disabled:hover {
    .tw-toolbar-app-circle {
      background: transparent;
    }
  }

  &:hover {
    .tw-toolbar-app-circle {
      background: gray;
    }
  }

  .tw-toolbar-app-button-content {
    width: 100%;



    .tw-toolbar-app-circle {
      position: relative;
      border-color: rgba(0, 0, 0, 0.8);
      color: rgba(0, 0, 0, 0.8);
      border-width: 0.5px;
      border-radius: 50%;
      height: 35px;
      display: flex;
      margin: auto;
      border: solid;
      width: 35px;

      .tw-toolbar-app-icon {
        color: rgba(0, 0, 0, 0.8);
      }
    }

    .tw-toolbar-app-circle:hover {
      background: gray;
    }
  }
}



.tw-toolbar-app-label {
  font-size: 10px;
  flex: 1;
  width: 100%;
  white-space: normal;
  line-height: 10px;
  padding: 2px;
  padding-top: 5px;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 12px;
  color: rgba(0, 0, 0, 0.8);
}
