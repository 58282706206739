.tw-file {
  height:150px;
  width :150px;
  display: flex;
  margin-bottom:10px;
  margin-right:10px;
}
.tw-file-large {
  height: 250px;
  width: 250px;
  display: flex;
  margin-bottom: 10px;
  margin-right: 10px;
}
.tw-file-linkcontent-large {
  display: flex;
  width: 250px;
  height: 250px;
  background-color: gainsboro;
  position: relative;
}
.tw-file-tumbnail-large {
  margin: auto;
  width: 230px;
  height: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  padding: 10px 0;
}
.tw-filecontent {
  margin: 5px;
  overflow-y: auto;
  width: 100%;
  max-height: 100%;
  display: inline-flex;
  flex-wrap: wrap;
}
.tw-file-linkcontent {
  display: flex;
  width: 150px;
  height: 150px;
  background-color: gainsboro;
  position:relative;
}
.tw-file-delete {
  position: absolute;
  width: 25px;
  height: 25px;
  right: 0;
  top: 0;
  margin-right: 5px;
  margin-top: 5px;

  md-icon {
    color: #404040;
  }

  md-icon:hover {
    color: red;
  }
}
.tw-file-download {
  position: absolute;
  width: 25px;
  height: 25px;
  left: 0;
  top: 0;
  margin-right: 5px;
  margin-top: 5px;

  md-icon {
    color: #404040;
  }

  md-icon:hover {
    color: red;
  }
}

.tw-file-discription {
  padding: 0 1px;
  font-size: 12px;
  height: 35px;
  color: white;
  -webkit-text-fill-color: white;
  opacity: 0.7;
  background: #404040;
  overflow: hidden;
  width: 150px;
  text-overflow: ellipsis;
  line-height: 17px;
  word-wrap: break-word;
  position: absolute;
  bottom: 0;
  text-align: -webkit-center;
  text-align: center;

  textarea {
    background: #404040;
    width: 100%;
    height: 100%;
    resize: none;
    margin: 0;
    padding: 0;
    text-align: center;
    border: none;
    overflow: hidden;
    overflow-y: auto;
  }

  textarea::-webkit-scrollbar {
    width: 5px;
    background-color: #404040;
  }

  textarea::-webkit-scrollbar-thumb {
    background-color: #DCDCDC;
  }

  input {
    background: #404040;
    width: 100%;
    height: 100%;
    resize: none;
    margin: 0;
    padding: 0;
    text-align: center;
    border: none;
  }
}
.tw-file-tumbnail {
  margin: auto;
  width:100px;
  height:100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  padding: 10px 0;
}
