#hr-configurations {



  md-list {
    margin: 0;
    padding: 0;
  }

  md-divider {
    border-width: 1px;
    border-style: solid;
    color: gray;
  }

  .small-input {
    margin: 0px;
    padding: 0px;
  }

  .md-small-button {
    padding: 0px;
    margin: 0px;
    height: 35px;
    width: 35px;
    min-width: 35px !important;
  }

  .md-medium-button {
    padding: 5px;
    margin: 5px;
    height: 50px;
    width: 50px;
    min-width: 50px !important;
    max-width: 50px;
  }

  .search {
    position: relative;
    padding-left: 24px;
    max-width: 480px;

    .search-icon {
      margin: 0 -24px 0 0;
    }

    .search-input {
      margin: 0 0 0 32px;
      background: none;
      font-size: 16px;
      line-height: 40px;
      height: 40px;
      color: #2d323e;
      border-bottom: 2px solid #2d323e;
      transition: border-color 300ms ease;

      &:focus {
        border-color: #2d323e;
      }

      &::-webkit-input-placeholder {
        color: #2d323e;
      }
    }
  }

  .center {
    .header {
      min-height: 90px !important;
      max-height: 90px !important;
      padding: 24px 0;
    }

    table#cedule {
      td, th {
        border: 1px solid black;
        max-width: 30px !important;
        min-width: 30px !important;
      }

      border-collapse: collapse;
      width: 100%;
    }

    md-toolbar {
      min-height: 60px !important;
      max-height: 60px !important;
    }

    .toolbar {
      min-height: 60px !important;
      max-height: 60px !important;
    }

    .content {
      padding: 0;
    }
  }

  .strike {
    text-decoration: line-through;
  }

  .action {
    margin: 0;
    padding: 6px;
  }
}

#resourcesHumaines-forms {

  md-input-container, md-select {
    padding: 0 0 0 0;
    margin: 6px 12px 6px 0;
  }

  div.value {
    border-bottom: 1px solid rgba(0, 0, 0, 0.117647);
    margin: 6px 0 24px 0;
    min-height: 25px;
  }
}

#ressourcesHumaines-dialog {
  .label {
    display: inline-block;
    font-size: 11px;
    padding: 5px;
    border-radius: 5px;
    margin: 5px 5px 0 0;
    background-color: #aaaaaa;
    min-height: 25px;
  }
}
