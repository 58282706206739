/* overflowbutton */

.overflowButton {
  height: 28px;  
  width: 25px;
  display: flex;
  margin-left: auto; 

  svg {
    fill: #fff;
  }

  &.open {
    svg {
      transform: scaleX(-1);
    }

    .overflowButtonContent {
      transform: translateX(calc(-100% + 25px));
    }

    .overflowButtonAbsoluteAnchor {
      z-index: 3;
    }

    .overflowButtonPopup {
      visibility: visible;
    }
  }
}

/*.overflowButton:hover {
  svg {
    transform: scaleX(-1);
  }

  .overflowButtonContent {
    transform: translateX(calc(-100% + 25px));
  }

  .overflowButtonAbsoluteAnchor {
    z-index: 3;
  }

  .overflowButtonPopup {
    visibility: visible;
  }
}*/

.overflowButtonAbsoluteAnchor {
  position: absolute;
}

.overflowButtonContent {
  display: flex;
  overflow: hidden;
  flex: 0 0 auto;
}

.overflowButtonToggleButton {
  width: 25px;
  height: 28px;
  min-width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6B6B6B;
  border-radius: 30px 0 0 30px;
  flex: 0 0 auto;
  cursor: pointer;

  &:hover {
    background-color: dampen(#6B6B6B, 20%);
    color: readable(dampen(#6B6B6B, 20%));
  }

  &:active {
    background-color: #2374BA;
    color: readable(#2374BA);
  }
}

.overflowButtonPopup {
  height: 28px;
  flex: 0 0 auto;
  background-color: #6B6B6B;
  display: flex;
  visibility: hidden;

  & > * {
    margin: 0 0 0 1px;
  }

  & > *:first-child {
    margin: 0;
  }
}

.overflowButtonButtonItem {
  background-color: #404040;
  color: white;
  border-width: 0;
  padding: 2px 3px;
  display: flex;
  align-items: flex-end;
  font-size: 8px;
  -webkit-appearance: none;
  display: flex;
  flex-direction: column-reverse;

  &:hover {
    background-color: dampen(#404040, 40%);
    color: readable(dampen(#404040, 40%));
  }

  &:focus {
    background-color: dampen(#404040, 40%);
    color: readable(dampen(#404040, 40%));
    outline: 0;
  }

  &:active {
    color: readable(#2374BA);
    background-color: #2374BA;
  }

  &:disabled {
    color: #BFBFBF;
    border: solid 1px;
    border-color: #404040;
    background-color: inherit;
  }

  span {
    text-overflow: ellipsis;
    text-transform: uppercase;
  }
}
