#folder-files {

  .list-view {

    tr {
      th, td {
      }
    }

    > thead {

      tr {

        th {

          &:first-child {
            border-bottom: none;
            width: 106px;
          }

          &.size {
            padding-right: 20px;
            text-align: right;
          }
        }
      }
    }

    > tbody {

      tr {
        cursor: pointer;
        outline: none;
        max-height: 20px;

        &.selected {
          background: #ffd165;
        }

        td {
          margin:0;
          padding: 5px 8px;
          border-bottom-style: solid;
          border-bottom-width: 1px;
          min-height:65px;

          &.file-icon {
            padding: 0;
            text-align: center;
          }

          &.name {
            font-weight: 500;
          }

          &.owner {
            text-transform: capitalize;
          }

          &.size {
            width: 75px;
            padding-right: 20px;
            text-align: right;
          }

          &.type {
          }


          &.last-modified {
            width: 175px;
          }

          &.show-details {
            text-align: center;
          }

          &.more {
            text-align: center;
          }
        }
      }
    }
  }
}
