#releases {

  .center {

    .header {
      height: 100px !important;
      min-height: 100px !important;
      max-height: 100px !important;
      padding: 24px 0;

      .h1 {
        md-icon {
          margin-right: 12px;
        }
      }
    }

    .content-card {
      padding: 0;

      md-toolbar {
        min-height: 60px !important;
        max-height: 60px !important;
      }

      .toolbar {
        min-height: 60px !important;
        max-height: 60px !important;
      }
    }

    .content {
      padding: 0;
    }
  }
}
