.tw-schedule-date {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  overflow: hidden;
  border-bottom-width: 1px;
  border-left-width: 0px;
  border-right-width: 1px;
  border-top-width: 1px;
  border-style: solid;
  font-size: 12px;
  border-color:   #909090; 
}
.tw-schedule-date-item {
  flex: 1;
  text-align:center;
  display:flex;
  flex-direction:column;
}
.tw-schedule-arrow-container {
  width: 20px;
  height: 100%;
  margin-left: 3px;
  display: flex;
  align-items: center;
}
.tw-schedule-date-selected { 
  background-color: #E8E8E8;
}
.tw-schedule-closed {
  width: 100%;
  height: 100%;
  background-image: repeating-linear-gradient(-45deg, rgb(0,0,0), rgb(0,0,0) 1px, transparent 1px, transparent 6px);
  background-size: 8px 8px;
}
.tw-schedule-closed-hover {
  width: 100%;
  height: 100%;
  background-image: repeating-linear-gradient(-45deg, #1E90FF, #1E90FF 1px, transparent 1px, transparent 6px);
  background-size: 8px 8px;
} 
.tw-schedule-row {
  height: 30px;
  line-height: 30px;
  display: flex;
  flex-direction: column;
  border-bottom-width: 1px;
  border-left-width: 0px;
  border-right-width: 1px;
  border-top-width: 1px;
  border-style: solid;
  font-size: 12px;
  border-color:   #909090;
}
.tw-schedule-right {
  width: 200px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  border-bottom-width: 1px;
  border-left-width: 0px;
  border-right-width: 1px;
  border-top-width: 1px;
  border-style: solid;
  font-size: 12px;
  border-color:   #909090;
}
.tw-schedule-right-column {
  width: 200px;
  min-width: 200px;
  max-width: 200px;
  height: 30px;
  display: flex;
  flex-direction: row;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  border-style: solid;
  font-size: 12px;
  align-items: center;
  overflow: hidden;
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Standard syntax */
}
.tw-schedule-right-border {
  display: flex;
  align-items: center;
  height: 100%;
  flex: 1;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-width: 1px;
  border-style: solid;
  background-color:  #E8E8E8;
  border-radius: 15px 0px 0px 15px;
  border-color:   #909090;
  overflow:hidden;
}
.overflow-menu {
  display: none
}
.tw-schedule-right-border:hover {
  .overflow-menu {
    display: flex
  }
}
.tw-schedule-shift-active {
 // background-color: rgba(0, 0, 0, 0.5);
}
.tw-schedule-shift-creation {
  /*border-color: black;
  border-style:groove;
  border-width:1px;
  border-right-width:0;
  border-radius:5px;*/
  .tw-schedule-right-border {
    background-color: #F8F8F8;
  }

  .tw-schedule-date {
    background-color: #F8F8F8;
  }
}
.tw-schedule-data-column {
  height: 30px;
  overflow: hidden;
  display: flex;
  flex: 1;
  background-color:  #E8E8E8;
}
.tw-schedule-line {
  display: flex;
  padding-bottom: 2px;
  padding-top: 2px;
}

.tw-schedule-line-description {
  margin-left: 5px;
  flex: 1;
  text-overflow: ellipsis;
  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
  font-size:11px;
}
.tw-schedule-line-data-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto
}
.tw-schedule-icon-container {
  width: 25px;
 // height:100%;

}

.tw-schedule-right-column-step0 {
  width: 5px;
  min-width: 5px;
}
.tw-schedule-right-column-step{
  width:15px;
  min-width:15px;
}
.tw-schedule-right-column-step1 {
  width: 25px;
  min-width: 25px;
}
.tw-schedule-right-column-step2 {
  width: 35px;
  min-width: 35px;
}
.tw-schedule-arrow-down {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid black;
  margin:auto;
}

.tw-schedule-arrow-right {
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid black;
  margin: auto;
}

.tw-schedule-column-data-content {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  line-height: 30px;
}
.tw-schedule-column-status-ok {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  line-height: 30px;
  background-color: #32CD32;
  border-color: #1E90FF;
  border-top-style: solid;
  border-top-width: 2px;
  border-bottom-style: solid;
  border-bottom-width: 2px;
}
.tw-schedule-column-start {
  border-left-style: solid;
  border-left-color: #1E90FF;
  border-left-width: 2px;
}
.tw-schedule-column-end {
  border-right-style: solid;
  border-right-color: #1E90FF;
  border-right-width: 2px;
}

.tw-schedule-column-status-no {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  line-height: 30px;
  background-color: #F08080;
}
.tw-schedule-column-status-info-ok {
  height: 100%;
  width: 100%;  
  display: flex;
  align-items: center;
  flex-direction: column;
  line-height: 30px;
  background-color: rgba(50,205,50,0.60);
}

.tw-schedule-column-status-info-no {
  height: 100%; 
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  line-height: 30px;
  background-color: rgba(240,128,128,0.60);

}
.tw-schedule-date-item-data:hover {
  background-color: #C0C0C0;
}
.tw-schedule-date-item-data {
  display: flex;
  cursor: cell;
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Standard syntax */
}

.tw-schedule-crosscase {
  input {
    border: none;
    text-align: center;
    font-weight: bold;
    font-size: 15px;
  }
}
.tw-schedule-inselection{
  background-color:red;
}


.tw-schedule-animate-container {

}
.tw-schedule-line-margin {

}
.tw-schedule-animate-repeat {

}

.tw-schedule-animate-repeat.ng-move,
.tw-schedule-animate-repeat.ng-enter,
.tw-schedule-animate-repeat.ng-leave {
  transition: all linear 0.5s;
}

.tw-schedule-animate-repeat.ng-leave.ng-leave-active,
.tw-schedule-animate-repeat.ng-move,
.tw-schedule-animate-repeat.ng-enter {
  opacity: 0;
  max-height: 10px;
}

.tw-schedule-animate-repeat.ng-leave,
.tw-schedule-animate-repeat.ng-move.ng-move-active,
.tw-schedule-animate-repeat.ng-enter.ng-enter-active {
  opacity: 1;
  max-height: 30px;
}
//tooltip

.tw-schedule-case-selected {
  border-width:3px;
  border-style:solid;
  border-color:gray;
  

}

/*.tw-schedule-case-selected::after {
  content: " - Remember this";
}*/
