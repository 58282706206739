/**
 *  Do not remove this comments bellow. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
// bower:scss
// endbower

/**
 *  Do not remove this comments bellow. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */
// injector
@import "core/scss/global.scss";
@import "core/directives/ms-card/templates/template-1/template-1.scss";
@import "core/directives/ms-card/templates/template-2/template-2.scss";
@import "core/directives/ms-card/templates/template-3/template-3.scss";
@import "core/directives/ms-card/templates/template-5/template-5.scss";
@import "core/directives/ms-card/templates/template-10/template-10.scss";
@import "core/directives/ms-card/templates/template-6/template-6.scss";
@import "core/directives/ms-card/templates/template-8/template-8.scss";
@import "core/directives/ms-card/templates/template-7/template-7.scss";
@import "core/directives/ms-card/templates/template-4/template-4.scss";
@import "core/directives/ms-card/templates/template-9/template-9.scss";
@import "core/directives/ms-stepper/templates/horizontal/horizontal.scss";
@import "core/directives/ms-stepper/templates/vertical/vertical.scss";
@import "core/theme-options/theme-options.scss";
@import "core/directives/ms-card/ms-card.scss";
@import "core/directives/ms-form-wizard/ms-form-wizard.scss";
@import "core/directives/ms-info-bar/ms-info-bar.scss";
@import "core/directives/ms-masonry/ms-masonry.scss";
@import "core/directives/ms-nav/ms-nav.scss";
@import "core/directives/ms-material-color-picker/ms-material-color-picker.scss";
@import "core/directives/ms-navigation/ms-navigation.scss";
@import "core/directives/ms-responsive-table/ms-responsive-table.scss";
@import "core/directives/ms-scroll/ms-scroll.scss";
@import "core/directives/ms-shortcuts/ms-shortcuts.scss";
@import "core/directives/ms-search-bar/ms-search-bar.scss";
@import "core/directives/ms-splash-screen/ms-splash-screen.scss";
@import "core/directives/ms-stepper/ms-stepper.scss";
@import "core/directives/ms-timeline/ms-timeline.scss";
@import "core/directives/ms-widget/ms-widget.scss";
@import "navigation/layouts/horizontal-navigation/navigation.scss";
@import "navigation/layouts/vertical-navigation-fullwidth-toolbar-2/navigation.scss";
@import "navigation/layouts/vertical-navigation/navigation.scss";
@import "teamwork/modules/GEN_Dashboard/dashboard.scss";
@import "teamwork/modules/GEN_QuickPanel/quickPanel.scss";
@import "teamwork/modules/GEN_Toolbar/toolbar.scss";
@import "teamwork/modules/HR/hr.scss";
@import "teamwork/modules/HR_Configurations/hrConfigurations.scss";
@import "teamwork/modules/HR_PPEs/hrPPEs.scss";
@import "teamwork/modules/INV_Catalogs/catalogs.scss";
@import "teamwork/modules/ORD_Order/order.scss";
@import "teamwork/modules/WAR_Pickings/pickings.scss";
@import "teamwork/modules/WAR_Releases/releases.scss";
@import "teamwork/shared/css/badges.scss";
@import "teamwork/shared/css/colors.scss";
@import "teamwork/shared/css/datatable.scss";
@import "teamwork/shared/css/forms.scss";
@import "teamwork/shared/css/layout.scss";
@import "teamwork/shared/css/lists.scss";
@import "teamwork/shared/css/print.scss";
@import "teamwork/shared/css/treecontrol.scss";
@import "teamwork/shared/layout/pagecontent.scss";
@import "teamwork/modules/GEN_QuickPanel/presences/presencesList.scss";
@import "teamwork/modules/GEN_Widgets/tw-widget-activities/twWidgetActivities.scss";
@import "teamwork/modules/GEN_Widgets/tw-widget/twWidget.scss";
@import "teamwork/modules/GEN_Widgets/tw-widget-budget-orders/twWidgetBudgetOrders.scss";
@import "teamwork/modules/GEN_Widgets/tw-widget-meters-total/twWidgetMetersTotal.scss";
@import "teamwork/modules/GEN_Widgets/tw-widget-meters/twWidgetMeters.scss";
@import "teamwork/modules/GEN_Widgets/tw-widget-model/twWidgetModel.scss";
@import "teamwork/modules/GEN_Widgets/tw-widget-orders-distribution/twWidgetOrdersDistribution.scss";
@import "teamwork/modules/GEN_Widgets/tw-widget-orderWithBo/twWidgetOrdersWithBO.scss";
@import "teamwork/modules/GEN_Widgets/tw-widget-orders-sliced/twWidgetOrdersSliced.scss";
@import "teamwork/modules/GEN_Widgets/tw-widget-periods/twWidgetPeriods.scss";
@import "teamwork/modules/GEN_Widgets/tw-widget-projects/twWidgetProjects.scss";
@import "teamwork/modules/GEN_Widgets/tw-widget-resume/twWidgetResume.scss";
@import "teamwork/modules/GEN_Widgets/tw-widget-shipping/twWidgetShipping.scss";
@import "teamwork/modules/GEN_Widgets/tw-widget-today/twWidgetToday.scss";
@import "teamwork/modules/HR_Folder/files/folderFiles.scss";
@import "teamwork/shared/directives/tw-Notes/notes.scss";
@import "teamwork/shared/directives/tw-files/files.scss";
@import "teamwork/shared/directives/tw-address/twaddress.scss";
@import "teamwork/shared/directives/tw-filters/twFilters.scss";
@import "teamwork/shared/directives/tw-functions/function.scss";
@import "teamwork/shared/directives/tw-groupinfo/groupinfo.scss";
@import "teamwork/shared/directives/tw-inventory-articles/articleAutocomplete.scss";
@import "teamwork/shared/directives/tw-list/twList.scss";
@import "teamwork/shared/directives/tw-logo/logo.scss";
@import "teamwork/shared/directives/tw-momentpiker/moment.scss";
@import "teamwork/shared/directives/tw-projects/projectsAutocomplete.scss";
@import "teamwork/shared/directives/tw-overflowButton/overflowButton.scss";
@import "teamwork/shared/directives/tw-show-groups/showgroup.scss";
@import "teamwork/shared/directives/tw-projects-gp/projectsGpAutocomplete.scss";
@import "teamwork/shared/directives/tw-sidenav/sidenav.scss";
@import "teamwork/shared/directives/tw-sites/sitesAutocomplete.scss";
@import "teamwork/shared/directives/tw-sites-gp/sitesGpAutocomplete.scss";
@import "teamwork/shared/directives/tw-state-menu/twStatesMenu.scss";
@import "teamwork/shared/directives/tw-toolbar/twtoolbar.scss";
@import "teamwork/shared/directives/tw-treecontrol/tree.scss";
@import "teamwork/shared/directives/tw-teams/teamautocomplete.scss";
@import "teamwork/modules/HR_PPEs/Orders/dialogs/newPpeOrder.scss";
@import "teamwork/modules/tw_Academy/list/views/academy.scss";
@import "teamwork/modules/tw_Employees/detail/views/interview.scss";
@import "teamwork/modules/tw_Schedule/flyingLayout/views/schedule.scss";
@import "teamwork/modules/tw_Schedule/list/views/schedule.scss";
@import "teamwork/modules/tw_Shippings/detail/views/articles.scss";
@import "teamwork/modules/tw_packingslips/list/views/layout.scss";
@import "teamwork/shared/services/feedBack/dialog/feedback.scss";
// endinjector