/*#sites-gp-autocomplete{
    .description{

    }
    
}

.sites-custom-template.md-autocomplete-suggestions {
  height: auto;
  max-height: 10px;
}

.sites-custom-template li {
  border-bottom: 1px solid #ccc;
  height: auto;
  padding-top: 2px;
  padding-bottom: 2px;
  white-space: normal;
}

.sites-custom-template li:last-child {
  border-bottom-width: 0;
}

.sites-custom-template .item-title,
.sites-custom-template .item-metadata {
  display: block;
  line-height: 2;
}

.sites-custom-template .item-title md-icon {
  height: 18px;
  width: 18px;
}*/
